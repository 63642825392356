<template>
  <apexchart type="radar" height="450" :options="chartOptions" :series="series" />
</template>

<script lang="ts" setup>
import i18n from "@/i18n";

interface Props {
  seriesValues: number[][];
  categoryNames: string[];
}

const props = defineProps<Props>();

const labelNames: string[] = [
  "",
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.labels.0"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.labels.1"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.labels.2"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.labels.3"),
];

const seriesNames: string[] = [
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.series.1"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.series.2"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.graph.series.3"),
];

const series = seriesNames.map((name, index) => ({
  name: name,
  data: props.seriesValues[index],
}));
const xAxisColors = props.categoryNames.map(() => "#1a1a1a");
const yAxisColors = props.categoryNames.map(() => "#666666");

const chartOptions = {
  chart: {
    height: 450,
    type: "radar",
    dropShadow: {
      enabled: true,
      blur: 1,
      left: 1,
      top: 1,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: 0,
  },
  fill: {
    opacity: 0.25,
  },
  markers: { size: 4, colors: ["#8D83DB", "#5AC3E5", "#E7AD6C"] },
  colors: ["#8D83DB", "#5AC3E5", "#E7AD6C"],
  xaxis: {
    categories: props.categoryNames,
    labels: {
      style: {
        colors: xAxisColors,
      },
    },
  },
  yaxis: {
    labels: {
      formatter: (val: number) => labelNames[val],
      style: {
        colors: yAxisColors,
      },
    },
  },
  legend: {
    itemMargin: {
      horizontal: 15,
    },
  },
};
</script>
