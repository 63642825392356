<template>
  <ChatBotGenericChannelInstructions channel-color="rgba(236, 178, 46, 0.07)" channel-name="slack" :logo-url="logoSlackUrl">
    <div v-if="slackData" class="slack-container">
      <a
        class="slack-add-button"
        :href="`${slackAuthApiUrl}?client_id=${slackData.slackClientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${slackData.azureChatBotName}`"
        rel="noopener noreferrer nofollow"
        target="_blank"
        :title="$t('pages.admin.company_settings.general_info.chatbot.slack.add')">
        <img :alt="$t('pages.admin.company_settings.general_info.chatbot.slack.add')" :src="logoSlackUrl" />
        <span class="text text--bold">{{ $t("pages.admin.company_settings.general_info.chatbot.slack.add") }}</span>
      </a>
    </div>
  </ChatBotGenericChannelInstructions>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import ChatBotGenericChannelInstructions from "@/views/admin/company-settings/components/chatbot/ChatBotGenericChannelInstructions.vue";
import logoSlackUrl from "@/assets/images/chatbot/slack.svg";
import useCompanySettingsService from "@/services/useCompanySettingsService";
import { AddToSlackInfo } from "@/models/slackInformation";

const { getSlackInformation } = useCompanySettingsService();

const slackAuthApiUrl = "https://slack.com/oauth/v2/authorize";
const scopes =
  "channels:history,channels:read,chat:write,dnd:read,emoji:read,files:read,files:write,groups:history,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,pins:read,pins:write,reactions:read,reactions:write,team:read,usergroups:read,users:read,users:write,users:read.email";
const redirectUri = "https://europe.slack.botframework.com/Home/auth/v2";
const slackData = ref<AddToSlackInfo>();

onMounted(async () => (slackData.value = await getSlackInformation()));
</script>
<style lang="scss" scoped>
.slack-container {
  display: grid;
  place-items: center;
}
.slack-add-button {
  display: flex;
  place-items: center;
  gap: 1rem;
  border: 1px solid var(--color-border);
  border-radius: var(--space-xs);
  width: fit-content;
  padding: 1rem;

  img {
    height: var(--size-l);
  }
  .text {
    color: var(--color-neutral-700);
  }
}
</style>
