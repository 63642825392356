<template>
  <div :class="{ 'course-mode-indicator': !inline }">
    <AppTag
      :main-text="modeDescription"
      type="training"
      training-type="access-mode"
      :access-mode-inline="inline"
      :title="modeDescription" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n";
import type { CourseBase } from "@/models/course";
import AppTag from "@/components/AppTag.vue";

interface Props {
  course: CourseBase;
  inline?: boolean;
}
const props = defineProps<Props>();

const modeDescription = computed<string>(() => i18n.global.t(`pages.learning.mode.${props.course.onLine ? "online" : "onsite"}`));
</script>
<style lang="scss" scoped>
.course-mode-indicator {
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
}
</style>
