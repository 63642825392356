import i18n from "@/i18n";

export type Language = {
  isoCode: LanguagesCodes;
  displayName: string;
};

enum LanguagesCodes {
  "en-US" = "english",
  "es-ES" = "spanish",
  "fr-FR" = "french",
  "de-DE" = "german",
  "it-IT" = "italian",
  "pt-PT" = "portuguese",
}

const languagesCodeMapped = new Map<string, string>();
const languageKeys = Object.keys(LanguagesCodes);
const languageValues = Object.values(LanguagesCodes);

for (let ixLanguage = 0; ixLanguage < languageKeys.length; ixLanguage++) {
  const isoCode = languageKeys[ixLanguage];
  const isoCodeTwoDigits = isoCode.split("-")[0];
  const languageTranslateKey = languageValues[ixLanguage];
  languagesCodeMapped.set(isoCode, languageTranslateKey);
  languagesCodeMapped.set(languageTranslateKey, languageTranslateKey);
  languagesCodeMapped.set(isoCodeTwoDigits, languageTranslateKey);
}

export const getLanguageName = (code: LanguagesCodes): string => decodeLanguage(code);
export const getLanguage = (code: LanguagesCodes): Language => ({
  isoCode: code,
  displayName: getLanguageName(code),
});
const getDecodeLanguageCode = (possibleCodeLanguage: string): string | undefined => {
  if (possibleCodeLanguage in LanguagesCodes) {
    return possibleCodeLanguage;
  }
  const codeLowerCase = possibleCodeLanguage.toLocaleLowerCase();
  if (possibleCodeLanguage in LanguagesCodes) {
    return codeLowerCase;
  }
  const isoTwoCharsCode = codeLowerCase.split("-")[0];
  if (isoTwoCharsCode in LanguagesCodes) {
    return isoTwoCharsCode;
  }
};
export const decodeLanguage = (possibleCodeLanguage: string): string => {
  const mappedKey = getDecodeLanguageCode(possibleCodeLanguage);
  if (mappedKey) {
    return i18n.global.t(`shared.languages.${languagesCodeMapped.get(mappedKey)}`);
  }
  return possibleCodeLanguage;
};
export const getLanguages = (): Language[] => languageKeys.map((code) => getLanguage(code as LanguagesCodes));
