<template>
  <AppPanel
    class="question-list"
    :padding-header="false"
    :padding-content="false"
    :padding-footer="false"
    data-cy="group-question-list-container">
    <template #header>
      <div class="question-list__header">
        <span class="text--bold">{{ $t("shared.company_groups_questions.subtitle") }}</span>
        <div id="group-question-list-navigation"></div>
      </div>
    </template>
    <GroupQuestionsTable
      v-if="!showGroupKPIs"
      :key="group.id"
      :questions="filteredQuestions"
      @updated-question="updatedQuestion"
      @remove-question="removeQuestion"
      @show-question-answers="showQuestionAnswers"
      @show-question-kpi="showQuestionKPI" />
    <GroupQuestionsKpis v-else :group-id="group.id" @remove-group-question-kpi="removeGroupKPI" />
    <QuestionAnswers
      v-if="currentQuestion && currentQuestionAnswers"
      :title="currentQuestion.title"
      :data="currentQuestionAnswers"
      @closed="closeQuestionAnswers" />
    <QuestionKpis
      v-if="currentQuestion && currentQuestionKPI"
      :question="currentQuestion"
      :data="currentQuestionKPI"
      @update-kpi-status="toggleInGroupDashboard"
      @closed="closeQuestionKPI" />
    <template #footer>
      <div class="question-list__footer">
        <AppButton
          v-if="!showGroupKPIs"
          :text="$t('shared.company_groups_questions.remove')"
          variant="secondary"
          size="m"
          :disabled="!selectedIds.length"
          data-cy="question-list-remove"
          @onclick="removeSelectedQuestionsFromGroup" />
        <AppButton
          :text="calculateButtonText()"
          variant="secondary"
          size="m"
          data-cy="question-list-view-kpis"
          :disabled="!hasActiveKpis && !showGroupKPIs"
          @onclick="showGroupKPIs = !showGroupKPIs" />
      </div>
    </template>
  </AppPanel>
</template>

<script setup lang="ts">
import { watch, ref, computed } from "vue";
import i18n from "@/i18n";
import GroupQuestionsTable from "@/views/shared/company-groups-questions/components/GroupQuestionsTable.vue";
import type { CompanyEmployeeGroupWithQuestions } from "@/models/companyEmployeeGroup";
import type { Question, QuestionSelectable } from "@/models/question";
import AppPanel from "@/components/AppPanel.vue";
import AppButton from "@/components/AppButton.vue";
import useModal from "@/components/modal/useModal";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import { containsInsensitive } from "@/utils/stringUtils";
import GroupQuestionsKpis from "@/views/admin/ai-assistant/components/kpis/GroupQuestionsKpis.vue";
import QuestionAnswers from "@/views/shared/questions/QuestionAnswers.vue";
import { QuestionWithAnswers } from "@/models/questionAnswers";
import { QuestionKpiInfo } from "@/models/questionKpi";
import QuestionKpis from "@/views/shared/questions/QuestionKpis.vue";

interface Props {
  group: CompanyEmployeeGroupWithQuestions;
  filter: string;
}
const props = defineProps<Props>();

interface Emits {
  (e: "updatedQuestion", question: QuestionSelectable): void;
  (e: "removeQuestions", groupId: number, questionIds: number[]): void;
  (e: "toggleInGroupDashboard", groupId: number, questionIds: number, showInDashboard: boolean): void;
}
const emit = defineEmits<Emits>();

const { openTextModal } = useModal();
const { geCompanyGroupQuestionAnswers, geCompanyGroupQuestionKpi } = useCompanyEmployeeGroupsService();

const selectedIds = ref<number[]>([]);
const currentQuestion = ref<Question | undefined>();
const currentQuestionAnswers = ref<QuestionWithAnswers>();
const currentQuestionKPI = ref<QuestionKpiInfo>();
const showGroupKPIs = ref<boolean>(false);
const hasActiveKpis = computed<boolean>(() => props.group.questions.some((q: QuestionSelectable) => q.showInDashboard));
const filteredQuestions = computed(() => props.group.questions.filter((q) => containsInsensitive(q.title, props.filter)));

const showQuestionAnswers = async (question: Question): Promise<void> => {
  currentQuestion.value = question;
  const response = await geCompanyGroupQuestionAnswers(props.group.id, question.id);

  if (response) {
    currentQuestionAnswers.value = response;
  }
};
const closeQuestionAnswers = () => {
  currentQuestion.value = undefined;
  currentQuestionAnswers.value = undefined;
};

const showQuestionKPI = async (question: Question): Promise<void> => {
  currentQuestion.value = question;
  const response = await geCompanyGroupQuestionKpi(props.group.id, question.id);

  if (response) {
    currentQuestionKPI.value = response;
  }
};
const closeQuestionKPI = () => {
  currentQuestion.value = undefined;
  currentQuestionKPI.value = undefined;
};

const updatedQuestion = (question: Question) => emit("updatedQuestion", question as QuestionSelectable);
const removeQuestion = (id: number) => removeQuestionsFromGroup([id]);
const removeSelectedQuestionsFromGroup = () => removeQuestionsFromGroup(selectedIds.value);
const toggleInGroupDashboard = (question: Question) => {
  emit("toggleInGroupDashboard", props.group.id, question.id, !question.showInDashboard);
  closeQuestionKPI();
};

const removeGroupKPI = (id: number) => {
  const question = props.group.questions.find((q) => q.id === id);
  if (question) {
    toggleInGroupDashboard(question);
  }
};

const removeQuestionsFromGroup = (ids: number[], clearSelection = false) => {
  if (!ids.length) {
    return;
  }

  const callback = () => {
    emit("removeQuestions", props.group.id, ids);
    if (clearSelection) {
      selectedIds.value = [];
    }
  };

  openTextModal({
    title: i18n.global.t("shared.are_you_sure"),
    contentText: "",
    showCancelToClose: true,
    confirmCallback: callback,
  });
};

const calculateButtonText = () =>
  showGroupKPIs.value
    ? i18n.global.t("shared.company_groups_questions.view_questions")
    : i18n.global.t("shared.company_groups_questions.view_kpis");

watch(
  () => props.group.questions,
  (value) => (selectedIds.value = value?.filter((question) => question.selected).map((question) => question.id) || []),
  { deep: true, immediate: true }
);

watch(
  () => props.group,
  () => (showGroupKPIs.value = false),
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.question-list {
  margin-top: var(--space-m);

  &__header {
    padding: var(--space-s);
    border-bottom: 1px solid var(--color-border);
    color: var(--color-primary-accent1);
  }

  &__footer {
    padding: var(--space-s);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--color-border);
    gap: var(--space-s);
  }
}
</style>
