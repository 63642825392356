import i18n from "@/i18n";
import type { DateSchema, AnyObject, StringSchema, MixedSchema, ObjectSchema } from "yup";
import { date as yupDate, mixed as yupMixed, string as yupString, object as yupObject } from "yup";
import type { FileAttachment } from "@/models/fileAttachment";
import { mediumTextLength } from "@/constants/restrictions";
import { dayAfter, removeTime, today } from "@/utils/dateUtils";
import { convertMIMEToExtension, convertToBytes } from "@/utils/fileUtils";
import { isValidUrl } from "@/utils/stringUtils";

type YupDateSchema = DateSchema<Date | undefined, AnyObject>;

export interface TodaySchemaOptions {
  dateSet?: Date;
  required?: boolean;
}
export interface EndDateSchemaOptions {
  startDateFieldName: string;
  canBeEqualToStartDate: boolean;
  required?: boolean;
}
export interface StringSchemaOptions {
  maxLength?: number;
  required?: boolean;
}

interface UseFormSchemaReturn {
  dateSchema: (required?: boolean) => YupDateSchema;
  startDateMinTodaySchema: (options?: TodaySchemaOptions) => YupDateSchema;
  endDateSchema: (options: EndDateSchemaOptions) => YupDateSchema;
  attachmentSchema: (extensions: string[], maxFileSizeInMB?: number) => MixedSchema<FileAttachment | undefined, AnyObject>;
  stringSchema: (options?: StringSchemaOptions) => StringSchema<string | undefined>;
  stringUrlSchema: (options?: StringSchemaOptions) => StringSchema<string | undefined>;
  inputRequiredText: () => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yupObject: (schema: any) => ObjectSchema<any>;
}

const inputRequiredText = (): string => i18n.global.t("inputs.required");

export default (): UseFormSchemaReturn => {
  const endDateSchema = (options: EndDateSchemaOptions) => {
    return dateSchema(options.required ?? true)
      .min(today(), i18n.global.t("inputs.date_today_or_later"))
      .when(options.startDateFieldName, ([start], schema) => {
        if (start) {
          const minDate = options.canBeEqualToStartDate ? start : dayAfter(start);
          return schema.min(
            minDate,
            i18n.global.t("inputs.a_date_has_to_be_after_another_date", {
              first: i18n.global.t("inputs.end_date"),
              second: i18n.global.t("inputs.start_date"),
            })
          );
        }
        return schema;
      });
  };
  const dateSchema = (required = true) => {
    const schema = yupDate();
    return required ? schema.required(inputRequiredText()) : schema;
  };
  const attachmentSchema = (extensions: string[], maxFileSizeInMB = 0) =>
    yupMixed<FileAttachment>()
      .test("attachment-extension", i18n.global.t("inputs.upload_document.invalid_extension"), (f) =>
        f
          ? extensions
              .map((type) => convertMIMEToExtension(type))
              .some((ext) =>
                Array.isArray(ext) ? ext.some((e) => f.fileName.toLowerCase().endsWith(e)) : f.fileName.toLowerCase().endsWith(ext)
              )
          : true
      )
      .test("attachment-max-size", i18n.global.t("inputs.upload_document.maximum_size_MB", { size: maxFileSizeInMB }), (f) =>
        maxFileSizeInMB && f?.size ? f?.size <= convertToBytes(maxFileSizeInMB) : true
      );
  const stringSchema = (options?: StringSchemaOptions) => {
    const maxLength = options?.maxLength ?? mediumTextLength;
    const required = options?.required !== undefined ? options.required : true;
    const schema = yupString().max(maxLength, i18n.global.t("inputs.max_length_exceeded", { maxLength })).trim();
    return required ? schema.required(inputRequiredText()) : schema;
  };
  const stringUrlSchema = (options?: StringSchemaOptions) => {
    return stringSchema(options).test(
      "link-url",
      i18n.global.t("inputs.url_not_valid"),
      (l) => l === undefined || l === "" || isValidUrl(l)
    );
  };

  const startDateMinTodaySchema = (options?: TodaySchemaOptions) => {
    const todayNow = today();
    const minDateApplicationAlreadySet = options?.dateSet && options.dateSet.getTime() < todayNow.getTime();
    return dateSchema(options?.required).min(
      minDateApplicationAlreadySet && options.dateSet ? removeTime(options.dateSet) : todayNow,
      minDateApplicationAlreadySet ? i18n.global.t("inputs.DateNotValid") : i18n.global.t("inputs.date_today_or_later")
    );
  };
  return {
    startDateMinTodaySchema,
    endDateSchema,
    attachmentSchema,
    stringSchema,
    dateSchema,
    inputRequiredText,
    stringUrlSchema,
    yupObject,
  };
};
