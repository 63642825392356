import type { Skill } from "@/models/skill";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/skills";
const { post, put, get, httpDelete, loading } = useHttpClient();

interface UseSkillServiceReturn {
  createSkill: (skill: Partial<Skill>) => Promise<void>;
  getSkills: () => Promise<Skill[]>;
  updateSkill: (skill: Partial<Skill>) => Promise<void>;
  deleteSkill: (skillId: number) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseSkillServiceReturn => {
  const createSkill = (skill: Partial<Skill>): Promise<void> => post(BASE_URL, skill);
  const getSkills = (): Promise<Skill[]> => get(BASE_URL);
  const updateSkill = async (skill: Partial<Skill>): Promise<void> => {
    const postData: Partial<Skill> = { ...skill };
    postData.id = undefined;
    await put(`${BASE_URL}/${skill.id}`, postData);
  };
  const deleteSkill = (skillId: number): Promise<void> => httpDelete(`${BASE_URL}/${skillId}`);

  return { createSkill, getSkills, updateSkill, deleteSkill, loading };
};
