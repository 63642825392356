<template>
  <LayoutBase :menu-options="menuOptions" root-url="/it-manager" />
</template>

<script setup lang="ts">
import LayoutBase from "@/layout/shared/LayoutBase.vue";
import { useLayout } from "@/layout/shared/useLayout";
import type { MenuOption } from "@/layout/models/menu-option";

const { getOption } = useLayout("it-manager");

const menuOptions: MenuOption[] = [getOption("settings", "company-settings", "pages.admin.company_settings.title")];
</script>
