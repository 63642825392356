<template>
  <div>
    <h3>{{ $t("pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.title") }}</h3>
    <div class="levels margin-t--s" data-cy="question-levels">
      <AppInputText
        v-model="lowLevelText"
        :label="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.low_level')"
        :placeholder="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.low_level')"
        :error="lowLevelTextError"
        :maxlength="mediumTextLength"
        class="levels__item"
        @update:model-value="emitUpdate" />
      <AppInputText
        v-model="mediumLevelText"
        :label="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.medium_level')"
        :placeholder="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.medium_level')"
        :error="mediumLevelTextError"
        :maxlength="mediumTextLength"
        class="levels__item"
        @update:model-value="emitUpdate" />
      <AppInputText
        v-model="highLevelText"
        :label="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.high_level')"
        :placeholder="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.high_level')"
        :error="highLevelTextError"
        :maxlength="mediumTextLength"
        class="levels__item"
        @update:model-value="emitUpdate" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { useField } from "vee-validate";
import AppInputText from "@/components/AppInputText.vue";
import { mediumTextLength } from "@/constants/restrictions";
import type { QuestionLevel } from "@/models/questionLevel";

interface Props {
  levels?: QuestionLevel;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "levelsUpdated", value: QuestionLevel) => void>();

const { value: lowLevelText, errorMessage: lowLevelTextError } = useField<string>("levels.lowLevelText");
const { value: mediumLevelText, errorMessage: mediumLevelTextError } = useField<string>("levels.mediumLevelText");
const { value: highLevelText, errorMessage: highLevelTextError } = useField<string>("levels.highLevelText");

onMounted(async () => {
  if (props.levels) {
    lowLevelText.value = props.levels.lowLevelText;
    mediumLevelText.value = props.levels.mediumLevelText;
    highLevelText.value = props.levels.highLevelText;
  }
});

const emitUpdate = () => {
  const value: QuestionLevel = {
    lowLevelText: lowLevelText.value,
    mediumLevelText: mediumLevelText.value,
    highLevelText: highLevelText.value,
  };
  emit("levelsUpdated", value);
};
</script>
<style scoped lang="scss">
.levels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--space-m);

  &__item {
    flex: 1;
    overflow: hidden;
  }
}
</style>
