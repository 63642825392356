<template>
  <div>
    <AppNoContentYet v-if="courses.length === 0" :text="$t('pages.learning.my_learning_path.no_suggestions')" />
    <ul v-else class="courses">
      <li v-for="course in courses" :key="course.id" class="courses__item">
        <MyLearningPathCourse :course="course" :information-direction="courseInformationDirection" @course-selected="selectCourse" />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import MyLearningPathCourse from "@/views/app/my-learning/components/MyLearningPathCourse.vue";
import type { EmployeeCourse } from "@/models/course";

interface Props {
  courses: EmployeeCourse[];
  courseInformationDirection: "asc" | "desc";
}

const emit = defineEmits<(e: "course-selected", course: EmployeeCourse) => void>();

const selectCourse = (course: EmployeeCourse) => emit("course-selected", course);

defineProps<Props>();
</script>

<style lang="scss" scoped>
.courses {
  &__item {
    border-bottom: 1px solid #dcdcdc;
    padding: var(--space-s) 0 var(--space-s) 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
