<template>
  <AppTab :tabs="tabs" :tab-id="$t('pages-my-profile.dashboard.tab')" />
</template>

<script setup lang="ts">
import AppTab, { type Tab } from "@/components/AppTab.vue";
import i18n from "@/i18n";
import router from "@/router/router";

interface Props {
  current: "Profile" | "Dashboard";
}

const props = defineProps<Props>();

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages-my-profile.profile_updating.tab"),
    action: () => router.push({ name: "my-profile-overview" }),
    isChecked: props.current === "Profile",
    dataCy: "nav-profile",
  },
  {
    text: i18n.global.t("pages-my-profile.dashboard.tab"),
    action: () => router.push({ name: "my-profile-dashboard" }),
    isChecked: props.current === "Dashboard",
    dataCy: "nav-dashboard",
  },
];
</script>
