<template>
  <div v-if="idealJob">
    <AppTip unique-name="app-my-ideal-job" :content="$t('entities.my_ideal_job.tip.content')" />
    <div class="grid--two-columns@medium">
      <div v-if="idealJob.title && idealJob.description">
        <h3 class="margin-b--m" data-cy="job-title">{{ idealJob.title }}</h3>
        <div class="margin-b--m" data-cy="job-description" v-html="toHtml(idealJob.description)"></div>
        <AppLinkButton data-cy="edit-job" :text="$t('entities.my_ideal_job.update_job')" @click="openEditor" />
      </div>
      <div v-else class="ideal-job-empty">
        <AppNoContentYet />
        <AppLinkButton data-cy="add-job" :text="`+ ${$t('entities.my_ideal_job.add_job')}`" @click="openEditor" />
      </div>
      <div v-if="idealJob.title && idealJob.description">
        <h3 class="margin-b--m">{{ $t("entities.my_ideal_job.general_skills") }}</h3>
        <AppNoContentYet v-if="skills.length === 0" class="margin-b--s" />
        <FloatingPanelSkillEditor
          :input="skills"
          :skills-type="SkillType.General"
          :skill-location="SkillLocationType.IdealJob"
          data-cy="job-general-skills"
          @updated="skillsUpdated" />
        <h3 class="margin-v--m">{{ $t("entities.my_ideal_job.languages") }}</h3>
        <AppNoContentYet v-if="languages.length === 0" class="margin-b--s" />
        <FloatingPanelSkillEditor
          :input="languages"
          :skills-type="SkillType.Language"
          :skill-location="SkillLocationType.IdealJob"
          data-cy="job-language-skills"
          @updated="languagesUpdated" />
      </div>
    </div>
    <AppPanel dark class="margin-v--m" :border="false">
      <AppToggle v-model="agreeShareIdealJob" :text="$t('shared.agree_share_information')" data-cy="ideal-job-agree-share-info" />
    </AppPanel>
    <AppModal :name="panelName" :disable-key-enter="true">
      <AppForm>
        <AppInputText
          v-model="title"
          data-cy="title"
          :label="$t('entities.my_ideal_job.fields.title')"
          :placeholder="$t('entities.my_ideal_job.fields.title.placeholder')"
          :maxlength="mediumTextLength"
          :error="titleError" />

        <AppInputTextArea
          v-model="description"
          data-cy="description"
          :label="$t('entities.my_ideal_job.fields.description')"
          :placeholder="$t('entities.my_ideal_job.fields.description.placeholder')"
          :maxlength="xLargeTextLength"
          :error="descriptionError" />
      </AppForm>
    </AppModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useField, useForm } from "vee-validate";
import i18n from "@/i18n";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppModal from "@/components/modal/AppModal.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppTip from "@/components/AppTip.vue";
import AppToggle from "@/components/AppToggle.vue";
import FloatingPanelSkillEditor from "@/views/shared/current-experience/FloatingPanelSkillEditor.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import AppForm from "@/components/AppForm.vue";
import { emptyIdealJob } from "@/services/modelServices/employeeIdealJobService";
import { getGeneralSkills, getLanguageSkills } from "@/utils/models";
import { mediumTextLength, xLargeTextLength } from "@/constants/restrictions";
import { SkillLocationType, SkillType } from "@/models/skillType";
import { toHtml } from "@/utils/stringUtils";
import type { IdealJob, IdealJobBase } from "@/models/idealJob";
import type { SkillWithProgression } from "@/models/skillWithProgression";
import useMeAgreementsService from "@/services/useMeAgreementsService";
import useMeIdealJobService from "@/services/useMeIdealJobService";
import useMeService from "@/services/useMeService";
import useModal from "@/components/modal/useModal";
import useFormValidator from "@/services/useFormValidator";
import useFormSchema from "@/services/useFormSchema";

const { getMyIdealJob, setIdealJob } = useMeIdealJobService();
const { getBasicInformation } = useMeService();
const { updateAgreeShareIdealJob } = useMeAgreementsService();
const { openComponentModal } = useModal();
const { getFormCallbackHandler } = useFormValidator();

const agreeShareIdealJob = ref<boolean>(false);
const idealJob = ref<IdealJob | undefined>();
const languages = ref<SkillWithProgression[]>([]);
const skills = ref<SkillWithProgression[]>([]);

const panelName = "editIdealJob";

const { yupObject, stringSchema } = useFormSchema();

const resourceSchema = yupObject({
  title: stringSchema(),
  description: stringSchema({ maxLength: xLargeTextLength }),
});

const form = useForm({ validationSchema: resourceSchema });
const { value: title, errorMessage: titleError } = useField<string>("title");
const { value: description, errorMessage: descriptionError } = useField<string>("description");

onMounted(async () => {
  const job = await getMyIdealJob();
  idealJob.value = job || emptyIdealJob();
  skills.value = getGeneralSkills(idealJob.value.skills);
  languages.value = getLanguageSkills(idealJob.value.skills);

  setBasicData();

  const employee = await getBasicInformation();
  agreeShareIdealJob.value = employee.agreeShareIdealJob;
});

const skillsUpdated = async (updatedSkills: SkillWithProgression[]): Promise<void> => {
  skills.value = updatedSkills;
};

const languagesUpdated = async (updatedSkills: SkillWithProgression[]): Promise<void> => {
  languages.value = updatedSkills;
};

const setBasicData = (): void => {
  if (idealJob.value === undefined) {
    return;
  }
  title.value = idealJob.value.title;
  description.value = idealJob.value.description;
};

const save = async () => {
  const job: IdealJobBase = {
    title: title.value,
    description: description.value,
  };

  await setIdealJob(job);

  idealJob.value = { ...job, skills: idealJob.value?.skills ?? [] };
};

const openEditor = (): void => {
  openComponentModal(panelName, {
    title: i18n.global.t("entities.my_ideal_job.title"),
    showCancelToClose: true,
    confirmText: i18n.global.t("buttons.save"),
    variant: "floating",
    confirmCallback: getFormCallbackHandler(form, save),
    closeCallback: setBasicData,
  });
};

watch([agreeShareIdealJob], async () => {
  await updateAgreeShareIdealJob(agreeShareIdealJob.value);
});
</script>
<style scoped lang="scss">
.ideal-job-empty {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
}
</style>
