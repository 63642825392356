<template>
  <div class="course-list-item" data-cy="course-list-item">
    <div class="course-list-item__image">
      <CourseImage :course="course" :list="true" />
    </div>
    <div class="course-list-item__information">
      <CourseCardHeader :course="course" @click="headerClick" />
      <CourseModeIndicator :course="course" />
      <pre class="course-list-item__description text text--xs text--line-clamp-4 margin-t--xs" v-html="toHtml(course.description)"></pre>
      <div class="course-list-item__duration margin-t--auto">
        <p class="text text--xs" data-cy="course-duration">
          {{ getDurationStringFromHours(course.durationHours) }}
        </p>
        <AppTag icon="star" :main-text="!course.averageRate ? '-' : formatNumber(course.averageRate)" type="like" />
      </div>
      <div class="margin-t--xs">
        <CourseGoToTrainingButton :course="course" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { EmployeeCourse } from "@/models/course";
import AppTag from "@/components/AppTag.vue";
import CourseImage from "@/views/shared/learning/CourseImage.vue";
import CourseCardHeader from "@/views/shared/learning/CourseCardHeader.vue";
import CourseModeIndicator from "@/views/shared/learning/CourseModeIndicator.vue";
import CourseGoToTrainingButton from "@/views/shared/learning/CourseGoToTrainingButton.vue";
import { getDurationStringFromHours } from "@/utils/timeUtils";
import { formatNumber } from "@/utils/numberUtils";
import { toHtml } from "@/utils/stringUtils";

interface Props {
  course: EmployeeCourse;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "click", value: EmployeeCourse) => void>();

const headerClick = () => emit("click", props.course);
</script>
<style lang="scss" scoped>
.course-list-item {
  position: relative;

  display: flex;
  border-radius: var(--default-border-radius);
  box-shadow: 0 8px 24px var(--color-card--box-shadow);

  &__information {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--size-s);
  }

  &__duration {
    display: flex;
    align-items: center;
    column-gap: var(--size-xs);
  }

  &__description {
    white-space: inherit;
  }
}
</style>
