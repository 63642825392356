import type { Ref } from "vue";
import type { CompanyGoal, CompanyGoalWithEmployees } from "@/models/companyGoal";
import type { CompanyGoalEmployee } from "@/models/companyGoalEmployee";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/company/goals";
const { post, get, put, loading, httpDelete, appBaseUrl } = useHttpClient();

interface UseCompanyGoalsServiceReturn {
  addEmployeeToCompanyGoal: (goalId: number, employeeId: number) => Promise<void>;
  createCompanyGoal: (goal: CompanyGoal) => Promise<number>;
  deleteCompanyGoal: (goalId: number) => Promise<void>;
  deleteEmployeeFromCompanyGoal: (goalId: number, employeeId: number) => Promise<void>;
  getCompanyGoal: (goalId: number) => Promise<CompanyGoalWithEmployees>;
  getCompanyGoalRecommendedEmployees: (goalId: number) => Promise<CompanyGoalEmployee[]>;
  getCompanyGoals: () => Promise<CompanyGoal[]>;
  getGoalPictureUrl: (goalId: number) => string;
  launchCompanyGoal: (goalId: number) => Promise<void>;
  updateCompanyGoal: (goal: Partial<CompanyGoal>) => Promise<number>;
  setSuccessCompanyGoalResult: (goalId: number) => Promise<void>;
  setFailureCompanyGoalResult: (goalId: number) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseCompanyGoalsServiceReturn => {
  const createCompanyGoal = (goal: CompanyGoal): Promise<number> => post(BASE_URL, goal);
  const updateCompanyGoal = (goal: Partial<CompanyGoal>): Promise<number> => put(`${BASE_URL}/${goal.id}`, goal);

  const getCompanyGoal = (goalId: number): Promise<CompanyGoalWithEmployees> => get(`${BASE_URL}/${goalId}`);
  const deleteCompanyGoal = (goalId: number): Promise<void> => httpDelete(`${BASE_URL}/${goalId}`);
  const getCompanyGoals = (): Promise<CompanyGoal[]> => get(BASE_URL);

  const deleteEmployeeFromCompanyGoal = (goalId: number, employeeId: number): Promise<void> =>
    httpDelete(`${BASE_URL}/${goalId}/employee/${employeeId}`);

  const getGoalPictureUrl = (goalId: number): string => `${appBaseUrl}${BASE_URL}/${goalId}/picture`;

  const addEmployeeToCompanyGoal = (goalId: number, employeeId: number): Promise<void> =>
    post(`${BASE_URL}/${goalId}/employee`, { employeeId });

  const getCompanyGoalRecommendedEmployees = (goalId: number): Promise<CompanyGoalEmployee[]> => get(`${BASE_URL}/${goalId}/recommended`);

  const launchCompanyGoal = (goalId: number): Promise<void> => post(`${BASE_URL}/${goalId}/launch`);

  const setSuccessCompanyGoalResult = (goalId: number): Promise<void> => post(`${BASE_URL}/${goalId}/success`);
  const setFailureCompanyGoalResult = (goalId: number): Promise<void> => post(`${BASE_URL}/${goalId}/failure`, {});

  return {
    createCompanyGoal,
    updateCompanyGoal,
    getCompanyGoal,
    deleteCompanyGoal,
    getCompanyGoals,
    deleteEmployeeFromCompanyGoal,
    getGoalPictureUrl,
    addEmployeeToCompanyGoal,
    getCompanyGoalRecommendedEmployees,
    launchCompanyGoal,
    setSuccessCompanyGoalResult,
    setFailureCompanyGoalResult,
    loading,
  };
};
