<template>
  <LayoutPage :title="$t('pages.my_interests.title')">
    <AppTip unique-name="app-my_interests" :content="$t('pages.my_interests.tip.content')" />
    <div class="grid--two-columns@medium">
      <div>
        <h2 class="margin-b--m">{{ $t("pages.my_interests.interests") }}</h2>
        <AppNoContentYet v-if="interests.length === 0" />
        <div v-for="interest in interests" :key="interest.id" class="margin-b--m" data-cy="interests">
          <AppMyInterestsInterestEditor form-mode="edit" :input="interest" @deleted="interestDeleted" @updated="interestUpdated" />
        </div>
        <AppMyInterestsInterestEditor class="margin-v--m" form-mode="create" @created="interestCreated" />
      </div>
      <div>
        <h2 class="margin-b--m">{{ $t("pages.my_interests.idealLearning") }}</h2>
        <AppNoContentYet v-if="idealLearnings.length === 0" />
        <div v-for="idealLearning in idealLearnings" :key="idealLearning.id" class="margin-b--m" data-cy="ideal-learnings">
          <AppMyInterestsIdealLearningEditor
            form-mode="edit"
            :input="idealLearning"
            @deleted="idealLearningDeleted"
            @updated="idealLearningUpdated" />
        </div>
        <AppMyInterestsIdealLearningEditor class="margin-v--m" form-mode="create" @created="idealLearningCreated" />
      </div>
    </div>
    <AppSeparator class="margin-v--m" />
    <div>
      <h2 class="margin-b--m">{{ $t("entities.my_interests.hobbies") }}</h2>
      <AppHobbiesPanel :editable="true" />
    </div>
    <div class="margin-t--m margin-b--m">
      <AppPanel :dark="true" :border="false">
        <AppToggle v-model="agreeShareInterests" :text="$t('shared.agree_share_information')" data-cy="interests-agree-share-info" />
      </AppPanel>
    </div>
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from "vue";
import AppPanel from "@/components/AppPanel.vue";
import AppToggle from "@/components/AppToggle.vue";
import AppMyInterestsIdealLearningEditor from "@/views/shared/my-interests/AppMyInterestsIdealLearningEditor.vue";
import AppMyInterestsInterestEditor from "@/views/shared/my-interests/AppMyInterestsInterestEditor.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppHobbiesPanel from "@/components/AppHobbiesPanel.vue";
import AppTip from "@/components/AppTip.vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import type { IdealLearning } from "@/models/idealLearning";
import type { Interest } from "@/models/interest";
import useMeAgreementsService from "@/services/useMeAgreementsService";
import useMeService from "@/services/useMeService";
import useMeInterestsService from "@/services/useMeInterestsService";
import useMeIdealLearningService from "@/services/useMeIdealLearningService";

const interests: Ref<Interest[]> = ref<Interest[]>([]);
const idealLearnings: Ref<IdealLearning[]> = ref<IdealLearning[]>([]);
const agreeShareInterests: Ref<boolean> = ref<boolean>(false);

const { getBasicInformation } = useMeService();
const { getInterests } = useMeInterestsService();
const { getIdealLearnings } = useMeIdealLearningService();
const { updateAgreeShareInterests } = useMeAgreementsService();

onMounted(async () => {
  interests.value = await getInterests();
  idealLearnings.value = await getIdealLearnings();
  const employee = await getBasicInformation();
  agreeShareInterests.value = employee.agreeShareInterests;
});

const idealLearningCreated = (idealLearning: IdealLearning) => {
  idealLearnings.value.push(idealLearning);
};

const idealLearningUpdated = (idealLearning: IdealLearning) => {
  idealLearnings.value = idealLearnings.value.filter((i) => i.id !== idealLearning.id);
  idealLearnings.value.push(idealLearning);
  idealLearnings.value = [...idealLearnings.value].sort((a, b) => a.id - b.id);
};

const idealLearningDeleted = (idealLearning: IdealLearning): void => {
  idealLearnings.value = idealLearnings.value.filter((i) => i.id !== idealLearning.id);
};

const interestCreated = (interest: Interest) => {
  interests.value.push(interest);
};

const interestUpdated = (interest: Interest) => {
  interests.value = interests.value.filter((i) => i.id !== interest.id);
  interests.value.push(interest);
  interests.value = [...interests.value].sort((a, b) => a.id - b.id);
};

const interestDeleted = (interest: Interest): void => {
  interests.value = interests.value.filter((i) => i.id !== interest.id);
};

watch([agreeShareInterests], async () => {
  await updateAgreeShareInterests(agreeShareInterests.value);
});
</script>
