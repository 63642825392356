<template>
  <Component :is="column.customRenderer" v-if="column.customRenderer" :item="item" :custom-props="column.customProperties" />
  <AppTableColumnText v-else-if="isTextColumn" :item="item" :column="column" />
  <AppTableColumnAction v-else-if="column.type === 'actions'" :column="column" :item="item" />
  <AppTableColumnSelect v-else-if="isSelectColumn" :column="column" :model-value="item" />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import AppTableColumnText from "@/components/table/AppTableColumnText.vue";
import AppTableColumnAction from "@/components/table/AppTableColumnAction.vue";
import AppTableColumnSelect from "@/components/table/AppTableColumnSelect.vue";
import type { Column } from "@/components/table/Column";
import type { TableItem } from "@/components/table/TableItem";

interface Props {
  column: Column;
  item: TableItem;
}
const props = defineProps<Props>();

const isTextColumn = computed(() => props.column.type === "long-text" || props.column.type === "text-with-ellipsis");
const isSelectColumn = computed(
  () => props.column.type === "select" && props.column.select && props.column.select.options && props.column.select.options.length > 0
);
</script>
