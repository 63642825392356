<template>
  <div class="admin-company-goals-actions">
    <div class="header">
      <p class="header__text text text--bold">
        {{
          goal.isPublic
            ? $t("pages.admin.company_goals.goal_detail.employees_applying")
            : $t("pages.admin.company_goals.goal_detail.employees_selected")
        }}
      </p>
      <p v-if="goal.isPublic">
        {{ `${$t("shared.lastUpdate")}, ${!props.goal.edited ? $t("shared.not_available") : parseDateToMonthLongDate(props.goal.edited)}` }}
      </p>
    </div>
    <AppSeparator />
    <div class="container">
      <div class="container__selector">
        <AppButton
          size="m"
          grouped="start"
          :variant="currentArea === 'employees' ? 'primary' : 'secondary'"
          :text="$t('pages.admin.company_goals.goal_detail.employees')"
          @click="currentArea = 'employees'" />
        <AppButton
          size="m"
          grouped="end"
          :variant="currentArea === 'team_skills' ? 'primary' : 'secondary'"
          :text="$t('pages.admin.company_goals.goal_detail.team_skills')"
          @click="currentArea = 'team_skills'" />
      </div>
      <AdminCompanyGoalsAssigned
        v-if="currentArea === 'employees'"
        :goal="goal"
        @applicants-click="emit('applicantsClick')"
        @delete-click="deleteEmployee" />
      <AdminCompanyGoalsSkills v-if="currentArea === 'team_skills'" :goal-id="goal.id" />
      <div class="container__actions actions">
        <AppButton
          v-if="canBeLaunched(goal)"
          size="m"
          data-cy="launch"
          :text="
            goal.isLaunched
              ? $t('pages.admin.company_goals.goal_detail.launched_goal')
              : $t('pages.admin.company_goals.goal_detail.launch_goal')
          "
          @onclick="emit('launchClick')" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { canBeLaunched } from "@/services/modelServices/companyGoalService";
import { parseDateToMonthLongDate } from "@/utils/dateUtils";
import { ref } from "vue";
import AdminCompanyGoalsAssigned from "@/views/admin/company-goals/components/AdminCompanyGoalsAssigned.vue";
import AdminCompanyGoalsSkills from "@/views/admin/company-goals/components/AdminCompanyGoalsSkills.vue";
import AppButton from "@/components/AppButton.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import i18n from "@/i18n";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import useModal from "@/components/modal/useModal";

const { openTextModal } = useModal();

interface Props {
  goal: CompanyGoalWithEmployees;
}
const props = defineProps<Props>();

interface Emits {
  (e: "applicantsClick"): void;
  (e: "deleteClick", employeeId: number): void;
  (e: "launchClick"): void;
}
const emit = defineEmits<Emits>();

type area = "employees" | "team_skills";

const currentArea = ref<area>("employees");

const deleteEmployee = (employeeId: number): void => {
  openTextModal({
    title: i18n.global.t("pages.admin.company_goals.goal_detail.employees.delete.title"),
    contentText: i18n.global.t("pages.admin.company_goals.goal_detail.employees.delete.content"),
    icon: { content: "warning", style: "error" },
    showCancelToClose: true,
    confirmCallback: () => emit("deleteClick", employeeId),
  });
};
</script>

<style scoped lang="scss">
.admin-company-goals-actions {
  background: var(--color-neutral-100);
  border-radius: var(--default-border-radius);
  box-shadow: 0 var(--size-xs) var(--size-m) var(--color-company-goals-employee-shadow);
}
.header {
  padding: var(--size-m);

  &__text {
    color: var(--color-primary-accent1);
  }
}

.container {
  padding: 0 var(--size-m) var(--size-m) var(--size-m);

  &__selector {
    display: flex;
    justify-content: center;
    padding: var(--size-m) 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: var(--size-m);
  }
}
</style>
