{
  "buttons.next": "Suivant",
  "buttons.previous": "Précédent",
  "buttons.edit": "Modifier",
  "buttons.answer": "Réponse",
  "buttons.save": "Enregistrer",
  "buttons.delete": "Supprimer",
  "buttons.add": "Ajouter",
  "buttons.create": "Nouveau",
  "buttons.cancel": "Annuler",
  "buttons.continue": "Continuer",
  "buttons.save_draft": "Enregistrer en tant que brouillon",
  "buttons.publish": "Publier",
  "buttons.download": "Download",
  "actions.saved": "Informations sauvegardées avec succès",
  "avatar.initials": "{first}{last}",
  "skill_range.skill_names.low": "Faible",
  "skill_range.skill_names.medium": "Medium",
  "skill_range.skill_names.high": "High",
  "roles.user": "Employé",
  "roles.groupmanager": "Dirigeant de groupe, chef de groupe",
  "roles.administrator": "Administrateur",
  "roles.itmanager": "Responsable informatique",
  "errors.DateShouldBeGreaterThanOrEqualToToday": "La date doit être aujourd’hui ou plus tard",
  "errors.BirthDateOutOfRange": "Date non valide",
  "errors.CompanyEmployeeGroupCourseAlreadyAssigned": "Le cours a déjà été attribué à ce groupe",
  "errors.CompanyEmployeeGroupEmployeeNotFound": "Employé introuvable. Veuillez actualiser la page",
  "errors.CompanyEmployeeGroupExistsWithName": "Un groupe portant ce nom existe déjà.",
  "errors.CompanyEmployeeGroupNotFound": "Le groupe d'employés est introuvable. Veuillez actualiser la page",
  "errors.CompanyEmployeeGroupsNotFound": "Certains groupes d'employés sont introuvables",
  "errors.CompanyNotFound": "La société n'a pas été trouvée",
  "errors.CourseNotFound": "Cours introuvable",
  "errors.EmployeeAlreadyAssigned": "L’employé sélectionné est déjà affecté à l’objectif",
  "errors.EmployeesCannotBeAddedToAnEndedGoal": "Les employés ne peuvent pas être affectés à un objectif terminé",
  "errors.EmployeesCannotBeRemovedFromAnEndedGoal": "Les employés ne peuvent pas être désaffectés d’un objectif terminé",
  "errors.ErrorWhileConnectingToAzure": "La connexion à Azure avec les données fournies n’a pas pu être établie. Veuillez confirmer que l’ID client et le secret sont corrects.",
  "errors.ExpectedLinkedinFilesWereNotFound": "Les fichiers LinkedIn attendus n’ont pas été trouvés. S’il vous plaît, assurez-vous que les fichiers 'Education', 'Langues', 'Postes', 'Profil' ou 'Compétences' existent",
  "errors.FieldRequired": "Champ obligatoire",
  "errors.FileExtensionNotSupported": "L’extension de fichier n’est pas prise en charge",
  "errors.FileSizeExceeded": "Taille du fichier dépassée",
  "errors.GoalCannotBeLaunchedAfterItStarted": "Veuillez mettre à jour la 'Date de début de l’objectif' avec une date égale ou future à la date actuelle",
  "errors.GoalCannotBeLaunchedWhileApplicationIsOpen": "La phase d’application est toujours ouverte. S’il vous plaît, attendez la fin de la phase de candidature avant de lancer l’objectif",
  "errors.GoalCannotBeLaunchedWithFewerPeopleThanNeeded": "Le nombre de personnes affectées est inférieur aux besoins. S’il vous plaît, affectez plus de personnes avant de lancer l’objectif",
  "errors.GoalPeopleCannotBeGreaterThanTheNumberOfPeopleNeeded": "Le nombre de personnes nécessaires ne peut être dépassé",
  "errors.GoalResultCannotBeSetUntilItEnds": "Le résultat de l’objectif ne peut pas être défini tant qu’il n’est pas terminé",
  "errors.GoalResultCannotBeUpdated": "Le résultat de l’objectif a déjà été défini et ne peut pas être mis à jour",
  "errors.GoalsFinishedCannotBeUpdated": "Les objectifs terminés ne peuvent pas être mis à jour",
  "errors.GoalStartDateNotValid": "Date de début invalide",
  "errors.GoalEndDateNotValid": "Date de fin invalide",
  "errors.HobbyAlreadyExistWithName": "Un passe-temps portant ce nom existe déjà.",
  "errors.IdealLearningAlreadyExistsWithTitle": "Une formation portant ce titre existe déjà",
  "errors.InterestAlreadyExistsWithTitle": "Un intérêt portant ce titre existe déjà",
  "errors.LearningStartDateNotValid": "La date de début n’est pas valide",
  "errors.QuestionIsAlreadyPublished": "La question est déjà publiée",
  "errors.QuestionNotFound": "La question n’a pas été trouvée",
  "errors.QuestionNotFoundCannotBeAnswered": "La question n’a pas été trouvée. S’il vous plaît, rafraîchissez la page",
  "errors.QuestionsWithAnswersCannotBeUpdated": "Les questions avec réponses ne peuvent pas être mises à jour",
  "errors.QuestionsNotFound": "Certaines questions introuvables",
  "errors.RequestNotFound": "La compétence demandée est introuvable. S’il vous plaît, rafraîchissez la page",
  "errors.SkillAlreadyBeingCreatedWithName": "Une compétence portant ce nom est en cours de création.",
  "errors.SkillAlreadyExistWithName": "Une compétence portant ce nom existe déjà.",
  "errors.UnexpectedFileFormat": "Un format de fichier inattendu a été trouvé",
  "errors.UnknownError": "Erreur inconnue",
  "errors.UsersInAzureAdExceedsPurchasedUsers": "Le nombre d'utilisateurs trouvés dans Azure Active Directory dépasse le nombre d'utilisateurs achetés",
  "errors.EmployeeNotFound": "Employé introuvable",
  "inputs.a_date_has_to_be_after_another_date": "'{first}' date doit être postérieure à '{second}'",
  "inputs.date_less_than_or_equal_to_today": "La date doit être antérieure ou égale à aujourd’hui",
  "inputs.date_today_or_later": "La date doit être aujourd’hui ou plus tard",
  "inputs.DateNotValid": "Date non valide",
  "inputs.educationMode.label": "Mode",
  "inputs.educationMode.online.label": "En ligne",
  "inputs.educationMode.presence.label": "Sur site",
  "inputs.end_date": "Fin",
  "inputs.max_length_exceeded": "Le champ doit comporter au maximum {maxLength} caractères",
  "inputs.max_number_items_exceeded": "Le champ doit être au maximum {maxItems} items",
  "inputs.max_required": "Le champ doit être au maximum {maximum}",
  "inputs.min_required": "Le champ doit être au moins {minimum}",
  "inputs.options.required": "L’option {option} est obligatoire",
  "inputs.required": "Champ obligatoire",
  "inputs.start_date": "Démarrer",
  "inputs.tags.placeholder": "Tapez ici pour ajouter...",
  "inputs.to_date_has_to_be_after_from_date": "La date 'à' doit être postérieure à la date 'De'",
  "inputs.upload_document.invalid_extension": "Le fichier téléchargé a une extension non valide",
  "inputs.upload_document.maximum_size_MB": "{size} MB max size",
  "inputs.upload_document.no_document_uploaded": "Aucun document téléchargé encore",
  "inputs.upload_document.prompt.delete.description": "Vous allez supprimer ce fichier. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "inputs.upload_document.prompt.delete.title": "Supprimer le fichier",
  "inputs.upload_document.prompt.file_to_big.title": "Fichier trop gros",
  "inputs.upload_document.prompt.file_to_big.description": "Le fichier que vous essayez de télécharger est trop volumineux.",
  "inputs.upload_document.supported_types": "Types pris en charge: {types}",
  "inputs.url_not_valid": "Le champ doit être une URL valide",
  "pages.ai_assistant.title": "AI Assistant",
  "pages.ai_assistant.header.title": "Questions aux employés",
  "pages.ai_assistant.header.subtitle": "Bienvenue dans votre assistant IA. Votre assistant IA vous posera des questions via le chatbot, vos réponses lui donneront des informations pour vous donner le meilleur parcours d’apprentissage et l’expérience dans Fleebe. Si vous avez besoin de modifier vos réponses, vous pouvez le faire ici à tout moment.",
  "pages.ai_assistant.question.level": "Développez votre réponse ou partagez vos commentaires (positifs ou constructifs)",
  "pages.ai_assistant.question.tags": "Tapez vos options (séparées par des virgules ou entrée)",
  "pages.ai_assistant.no.questions": "Votre administration n’a pas encore configuré les questions",
  "pages.header.role": "Rôle",
  "pages.header.goToAdministratorArea": "Aller à la zone administrateur",
  "pages.header.goToEmployeeArea": "Aller à l’espace employé",
  "pages.header.logout": "Déconnexion",
  "pages.header.edit": "Modifier",
  "pages.header.picture-uploader.label": "Choisissez une image pour votre avatar",
  "pages.header.profile-editor.personal-information.title": "Informations personnelles",
  "pages.header.profile-editor.personal-information.birthDate": "Date de naissance",
  "pages.header.profile-editor.app-settings.title": "Paramètres de l’application",
  "pages.header.profile-editor.app-settings.languages.title": "Langue",
  "pages.header.profile-editor.app-settings.languages.english": "English",
  "pages.header.profile-editor.app-settings.languages.en": "English",
  "pages.header.profile-editor.app-settings.languages.spanish": "Español",
  "pages.header.profile-editor.app-settings.languages.es": "Español",
  "pages.header.profile-editor.app-settings.languages.french": "Français",
  "pages.header.profile-editor.app-settings.languages.fr": "Français",
  "pages.header.profile-editor.app-settings.languages.german": "Deutsch",
  "pages.header.profile-editor.app-settings.languages.italian": "Italiano",
  "pages.header.profile-editor.app-settings.languages.portuguese": "Português",
  "pages.goals_challenges.title": "Objectifs & Défis",
  "pages.goals_challenges.search.placeholder": "Objectifs et défis de recherche",
  "pages.goals_challenges.featured.title": "Objectifs et défis en vedette",
  "pages.goals_challenges.featured.vacant_positions": "Uniquement {vacantPositions} postes vacants",
  "pages.goals_challenges.featured.days_left": "{days} jours restants",
  "pages.goals_challenges.featured.dates": "De {start} à {end}",
  "pages.goals_challenges.my.title": "Mes objectifs et défis",
  "pages.goals_challenges.my.progress": "Progrès",
  "pages.goals_challenges.my.progress_amount": "{progress}%",
  "pages.goals_challenges.no.goals": "Votre administration n’a pas encore configuré les objectifs",
  "pages.learning.title": "Mon apprentissage",
  "pages.learning.learning.tab": "Apprentissage",
  "pages.learning.attachment.label": "Choisissez un certificat à télécharger",
  "pages.learning.search": "Rechercher",
  "pages.learning.search.no_results": "Il n’y a pas de cours avec ce filtre",
  "pages.learning.search.no_copy": "Nous ne copions ™pas vos recherches. Il s’agit d’un espace privé.",
  "pages.learning.search.title": "Résultats de recherche pour {searchTerm}",
  "pages.learning.featured.title": "Apprentissage sélectionné pour vous",
  "pages.learning.filter.type.title": "Type",
  "pages.learning.filter.type.all": "Tous",
  "pages.learning.filter.type.CourseCoursera": "Coursera",
  "pages.learning.filter.type.CourseEdX": "edX",
  "pages.learning.filter.type.CourseLinkedInLearning": "LinkedIn",
  "pages.learning.filter.type.CourseIntefi": "Intefi",
  "pages.learning.filter.type.CourseCompany": "Formation interne",
  "pages.learning.mode.online": "en ligne",
  "pages.learning.mode.onsite": "sur site",
  "pages.learning.rateTitle": "Évaluez cette formation",
  "pages.learning.rateObservations.placeHolder": "Il est très important pour nous d’obtenir ™des commentaires de votre part pour personnaliser notre plateforme d’apprentissage avec les meilleurs cours adaptés à vous.",
  "pages.learning.trainingComplete": "Formation terminée",
  "pages.learning.sendTraining": "Envoyer la formation",
  "pages.learning.confirmSetTrainingAsComplete.title": "Définir la formation comme terminée",
  "pages.learning.confirmSetTrainingAsComplete.text": "Vous allez définir une formation comme terminée. Cette action ne peut pas être annulée, alors assurez-vous d’avoir raison.",
  "pages.learning.goToTheTraining": "Aller à la formation",
  "pages.learning.certificateReminder": "Si vous avez un certificat ou un diplôme qui accède à une formation terminée, n’oubliez pas de l’ajouter à votre expérience actuelle.",
  "pages.learning.siteInformation.title": "Informations de la formation sur site",
  "pages.learning.tip.content": "Plus vous fournissez d’informations sur Fleebe, CV, intérêt, Emploi idéal... , plus la recommandation de votre assistant IA sera précise pour vous guider vers vos aspirations futures.",
  "pages.learning.my_learning_path.title": "Mon parcours d’apprentissage",
  "pages.learning.my_learning_path.tab": "Mon parcours d’apprentissage",
  "pages.learning.my_learning_path.introduction.title": "Parcours de formation à l’IA vers mon emploi idéal",
  "pages.learning.my_learning_path.introduction.explanation": "En suivant les paramètres établis dans la configuration de votre position idéale, notre IA a créé un itinéraire d’entraînement qui peut vous aider à atteindre votre objectif. Rappelez-vous que cette aide n’est fournie qu’à titre indicatif et que vous décidez en fin de compte de ce qui vous convient le mieux.",
  "pages.learning.my_learning_path.areas.skills": "Compétences",
  "pages.learning.my_learning_path.areas.languages": "Langues",
  "pages.learning.my_learning_path.areas.financial": "Éducation financière",
  "pages.learning.my_learning_path.levels.beginner": "Débutant",
  "pages.learning.my_learning_path.levels.advanced": "Avancé",
  "pages.learning.my_learning_path.levels.expert": "Expert",
  "pages.learning.my_learning_path.additional_learning": "Apprentissage supplémentaire",
  "pages.learning.my_learning_path.no_suggestions": "Aucune suggestion dans ce domaine/niveau",
  "pages.learning.my_learning_path.recalculate.title": "Je veux que mon parcours d'apprentissage soit recalculé",
  "pages.learning.my_learning_path.recalculate.activate_explanation": "Le recalcul sera effectué sans surveillance pendant la nuit",
  "pages.learning.my_training.title": "Ma formation",
  "pages.learning.my_training.tab": "Ma formation",
  "pages.learning.my_training.tip.content": "Votre parcours d’apprentissage ne changera pas si vous ne changez pas votre emploi idéal.",
  "pages.learning.my_training.no_completed": "Aucune formation n’est encore terminée",
  "pages.my_interests.form.idealLearning.create": "Ajouter une nouvelle formation",
  "pages.my_interests.form.idealLearning.create.title": "Nouvelle formation",
  "pages.my_interests.form.idealLearning.delete.content": "Vous allez supprimer la formation sélectionnée. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "pages.my_interests.form.idealLearning.delete.title": "Supprimer la formation",
  "pages.my_interests.form.idealLearning.edit.title": "Modifier la formation",
  "pages.my_interests.form.idealLearning.fields.description.label": "Description de la formation",
  "pages.my_interests.form.idealLearning.fields.description.placeholder": "Entrez une brève description",
  "pages.my_interests.form.idealLearning.fields.title.label": "Titre de la formation",
  "pages.my_interests.form.idealLearning.fields.title.placeholder": "Entrez un titre",
  "pages.my_interests.form.interests.create": "Ajouter un nouvel intérêt",
  "pages.my_interests.form.interests.create.title": "Nouvel intérêt",
  "pages.my_interests.form.interests.delete.content": "Vous allez supprimer l’intérêt sélectionné. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "pages.my_interests.form.interests.delete.title": "Supprimer l’intérêt",
  "pages.my_interests.form.interests.edit.title": "Modifier l’intérêt",
  "pages.my_interests.form.interests.fields.description.label": "Description de l’intérêt",
  "pages.my_interests.form.interests.fields.description.placeholder": "Entrez une brève description",
  "pages.my_interests.form.interests.fields.title.label": "Titre de l’intérêt",
  "pages.my_interests.form.interests.fields.title.placeholder": "Entrez un titre",
  "pages.my_interests.idealLearning": "Apprentissage",
  "pages.my_interests.interests": "Intérêts",
  "pages.my_interests.tip.content": "Dans cette section, nous vous invitons à compléter vos intérêts et vos loisirs. Aussi les apprentissages que vous avez faits et que vous trouvez intéressants à réfléchir.",
  "pages.my_interests.title": "Mes intérêts",
  "pages.my_profile.dashboards.successful_skills.title": "Compétences",
  "pages.my_profile.dashboards.certificates_courses.label": "5 derniers certificats et cours",
  "pages.my_profile.dashboards.certificates_courses.type_learning.online.label": "En ligne",
  "pages.my_profile.dashboards.certificates_courses.type_learning.presence.label": "Présence",
  "pages.my_profile.dashboards.company_goals.last_update.subtitle": "Dernière mise à jour, {date}",
  "pages.my_profile.dashboards.company_goals.graph": "Objectifs atteints",
  "pages.my_profile.dashboards.successful_skills.general": "Général",
  "pages.my_profile.dashboards.successful_skills.language": "Langues",
  "pages-my_profile.dashboards.general_skills.title": "Vos compétences générales",
  "pages-my_profile.dashboards.tips.introduction": "Ne vous inquiétez pas si vous ne voyez toujours rien ici. En complétant votre profil et avec le flux d’emploi au jour le jour, beaucoup d’informations apparaîtront.",
  "pages-my_profile.dashboards.tips.no-content": "Remplissez votre emploi actuel et idéal pour compléter le tableau ci-dessous montrant les compétences qui sont pertinentes pour vous.",
  "pages-my_profile.dashboards.tips.content": "Pour ajouter une nouvelle compétence ou modifier ses niveaux, veuillez visiter les sections Mon emploi actuel ou Mon emploi idéal. Si vous souhaitez remplacer une compétence par une autre, vous pouvez le faire en cliquant sur le bouton Modifier ci-dessous.",
  "pages-my_profile.dashboards.performance_graph.no_content_yet": "Nous n’avons pas assez d’informations pour créer le graphique",
  "pages-my_profile.dashboards.performance_graph.edit": "Modifier le graphique de performance",
  "pages-my-profile.profile_updating.tab": "Mise à jour du profil",
  "pages-my-profile.profile_updating.welcome_guide.title": "Bienvenue à Fleebe",
  "pages-my-profile.profile_updating.welcome_guide.subtitle": "Votre nouvel outil de développement professionnel",
  "pages-my-profile.profile_updating.welcome_guide.tip": "Les débuts sont difficiles. Suivez donc les prochaines étapes afin de compléter votre profil de manière rapide et conviviale.",
  "pages-my-profile.profile_updating.welcome_guide.steps.title": "Tout d’abord...",
  "pages-my-profile.profile_updating.welcome_guide.steps.1": "Complétez votre profil personnel : nom, âge, photo personnelle, emploi actuel, etc. Vous n’avez qu’à survoler l’icône de l’avatar dans le coin supérieur droit de l’application.",
  "pages-my-profile.profile_updating.welcome_guide.steps.2": "Nous sommes assez audacieux et nous voulons visualiser dans un seul graphique non seulement votre degré d’adaptation à votre poste actuel mais aussi à une position idéale créée par vous. Pouvez-vous nous aider? Suivez les liens dans les cartes sur le côté droit.",
  "pages-my-profile.profile_updating.welcome_guide.steps.3": "Pour cela, nous avons besoin de deux choses: premièrement, énumérez les compétences qui décrivent le mieux votre emploi actuel; Et deuxièmement, faites la même chose mais avec les compétences de votre emploi idéal, celui que vous aimeriez avoir à l’avenir. Facile, non?",
  "pages-my-profile.profile_updating.welcome_guide.steps.4": "Si vous importez votre profil depuis Linkedin, la plupart de ces compétences seront chargées automatiquement et vous n’aurez qu’à choisir celles qui correspondent le mieux aux emplois que vous avez décrits. Mais, si vous préférez, vous pouvez le faire manuellement.",
  "pages-my-profile.profile_updating.welcome_guide.summary.title": "Et c’est tout",
  "pages-my-profile.profile_updating.welcome_guide.summary.content": "Quand vous avez terminé, vous devriez voir quelque chose comme ça. Une façon assez intuitive de visualiser à la fois votre adéquation à votre emploi actuel et à votre emploi idéal.",
  "pages-my-profile.profile_updating.welcome_guide.summary.see_example": "Voir un exemple",
  "pages-my-profile.profile_updating.steps_guide.title_1": "Commencez ici en créant votre profil de compétences complet",
  "pages-my-profile.profile_updating.steps_guide.title_2": "Ou complétez-le en plusieurs parties",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.title": "Profil de compétences",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.content": "Sélectionnez les 12 compétences les plus pertinentes parmi toutes les compétences que vous avez ou que vous voudriez.",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.select": "Sélectionner des compétences",
  "pages-my-profile.profile_updating.steps_guide.current_job.title": "Emploi actuel",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_1": "Sélectionnez les 6 compétences les plus pertinentes dans votre expérience professionnelle actuelle.",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_2": "Sélectionnez parmi toutes les compétences qui définissent votre poste actuel les 6 plus pertinentes pour vous.",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_3": "Et si vous voulez que ce soit plus rapide...",
  "pages-my-profile.profile_updating.steps_guide.current_job.select_current_job_skills": "Sélectionnez les compétences professionnelles actuelles",
  "pages-my-profile.profile_updating.steps_guide.current_job.get_linkedin_profile": "Obtenez votre profil LinkedIn",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.title": "Job idéal",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.content_1": "Sélectionnez les 6 compétences que vous souhaitez développer pour votre emploi idéal.",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.content_2": "Définissez votre position idéale et ajoutez toutes les compétences que vous jugez nécessaires pour l’exécuter. Ensuite, vous pouvez sélectionner les plus importants.",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.select_ideal_job_skills": "Sélectionnez les compétences professionnelles idéales",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.go_to_my_ideal_job": "Aller à mon emploi idéal",
  "pages-my-profile.profile_updating.performance_graph.example.title": "Exemple de profil marketing",
  "pages-my-profile.profile_updating.performance_graph.example.subtitle": "Ici, vous pouvez voir les compétences de Megan.",
  "pages-my-profile.profile_updating.performance_graph.example.steps.1": "Les 6 compétences qui correspondent à son emploi actuel (violet)",
  "pages-my-profile.profile_updating.performance_graph.example.steps.2": "Les 6 compétences qu’elle aimerait développer (bleu)",
  "pages-my-profile.profile_updating.performance_graph.example.steps.3": "Les 12 compétences qui correspondent à son expérience actuelle (orange)",
  "pages-my-profile.profile_updating.performance_graph.example.content_1": "Que vous les importiez depuis Linkedin ou que vous les créiez manuellement, vous pouvez ensuite sélectionner les 6 compétences qui définissent le mieux votre position actuelle et souhaitée pour l’avenir.",
  "pages-my-profile.profile_updating.performance_graph.example.content_2": "Avec ce graphique, vous pouvez, en un coup d’œil, comparer vos talents actuels et futurs pour gérer votre formation et votre plan de carrière en conséquence. Cela aide également les responsables des ressources humaines à vous offrir les conditions de travail qui vous conviennent le mieux ou à détecter, par exemple, les employés sous-évalués ou insatisfaits.",
  "pages-my-profile.profile_updating.performance_graph.example.content_3": "Aidez-nous à vous aider.",
  "pages-my-profile.profile_updating.performance_graph.example.profile.first_name": "Megan",
  "pages-my-profile.profile_updating.performance_graph.example.profile.last_name": "Barney",
  "pages-my-profile.profile_updating.performance_graph.example.profile.experience": "Responsable marketing digital",
  "pages-my-profile.profile_updating.performance_graph.example.close": "J’ai compris",
  "pages-my-profile.profile_updating.performance_graph.graph.series.1": "Compétences professionnelles actuelles",
  "pages-my-profile.profile_updating.performance_graph.graph.series.2": "Compétences professionnelles idéales",
  "pages-my-profile.profile_updating.performance_graph.graph.series.3": "Compétences d’expérience actuelles",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.0": "Aucun",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.1": "Faible",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.2": "Support",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.3": "High",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.1": "Prendre l’initiative",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.2": "Langues",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.3": "Analytique",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.4": "Créatif",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.5": "Résolution de problèmes",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.6": "Attitude positive",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.7": "Supporting",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.8": "Faire preuve d’empathie",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.9": "Médias sociaux",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.10": "Gestion",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.11": "Marketing",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.12": "Apprentissage",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction": "Introduction",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_1": "Pour remplir votre tableau de profil, vous devez remplir les 2 sections principales vous concernant: Expérience actuelle et Emploi idéal. Une fois ces sections terminées, vous aurez à votre disposition toutes les compétences dont vous avez besoin pour évaluer et construire votre visualisation d’araignées.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_2": "* Rappelez-vous que vous serez toujours en mesure de modifier vos compétences et de les réviser pendant tout votre processus de croissance et d’apprentissage.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills": "Sélectionnez mes compétences professionnelles actuelles",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills.info": "Sélectionnez un min. de 3 compétences et un max. de 6 compétences les plus importantes dans mon emploi actuel.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills": "Sélectionnez mes compétences professionnelles idéales",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills.info": "Sélectionnez un min. de 3 compétences et un max. de 6 compétences que vous souhaitez développer pour votre emploi idéal.",
  "pages-my-profile.profile_updating.performance_graph.configurer.errors.skills_count": "Il est nécessaire que vous choisissiez entre 3 et 6 compétences pour construire votre graphique de performance.",
  "pages-my-profile.profile_updating.performance_graph.configurer.search.placeholder": "Rechercher une compétence",
  "pages-my-profile.profile_updating.performance_graph.configurer.current_job_requirements": "Vous devez avoir un emploi actuel avec au moins 3 compétences pour pouvoir créer ce graphique.",
  "pages-my-profile.profile_updating.performance_graph.configurer.go_to_current_job": "Aller à l'emploi actuel",
  "pages-my-profile.profile_updating.performance_graph.configurer.ideal_job_requirements": "Vous devez avoir un Job idéal avec au moins 3 compétences pour pouvoir créer ce graphique.",
  "pages-my-profile.profile_updating.performance_graph.configurer.go_to_ideal_job": "Aller à l'emploi idéal",
  "pages-my-profile.dashboard.tab": "Tableau de bord",
  "pages.my_profile.title": "Mon profil",
  "pages.my_profile.employee_groups.title": "Groupes",
  "pages.my_profile.previous_experiences.title": "Expériences précédentes",
  "pages.current_experience.title": "Mon emploi actuel",
  "pages.current_experience.about_me": "À propos de moi",
  "pages.current_experience.experience": "Autre expérience",
  "pages.current_experience.education": "Education",
  "pages.current_experience.languages": "Langues",
  "pages.current_experience.languages.edit": "Modifier les langues",
  "pages.current_experience.current_experience.title": "Position actuelle",
  "pages.current_experience.current_experience.general_skills": "Compétences générales",
  "pages.current_experience.current_experience.language_skills": "Compétences linguistiques",
  "pages.current_experience.goals_challenges.title": "Mes objectifs et défis",
  "pages.current_experience.linkedin.import": "Importer mon profil LinkedIn",
  "pages.current_experience.linkedin.title": "Importez votre profil LinkedIn",
  "pages.current_experience.linkedin.content.instructions.title": "Suivez les instructions ci-dessous pour importer votre profil LinkedIn.",
  "pages.current_experience.linkedin.content.instructions.step.1": "<span class=\"text text--bold\">Cliquez sur ce lien</span> pour accéder à votre profil LinkedIn (connectez-vous si nécessaire): ",
  "pages.current_experience.linkedin.content.instructions.step.2": "<span class=\"text text--bold\">Configurez le contenu que vous souhaitez importer</span>. LinkedIn vous propose quelques alternatives.",
  "pages.current_experience.linkedin.content.instructions.step.3": "Cliquez sur le bouton <span class=\"text text-bold\">Request file</span> (le téléchargement sera prêt dans environ <span class=\"text text--bold\">24 hours</span>).",
  "pages.current_experience.linkedin.content.instructions.step.4": "Two <span class=\"text text--bold\">emails</span> vous enverra les instructions que vous devez suivre. Le <span class=\"text text--bold\">first one</span> contient les informations que vous devez télécharger ici. Le second concerne votre activité LinkedIn et l’historique de votre compte, et nous n’en avons pas besoin.",
  "pages.current_experience.linkedin.content.instructions.step.5": "<span class=\"text text--bold\">Download</span> le fichier.",
  "pages.current_experience.linkedin.content.instructions.step.6": "<span class=\"text text--bold\">Cliquez sur le bouton ci-dessous</span> et sélectionnez le fichier .zip téléchargé depuis votre ordinateur.",
  "pages.current_experience.linkedin.content.instructions.step.7": "<span class=\"text text--bold\">And that’s all</span>. Si tout se passe bien, toutes vos données seront facturées ici. Vérifiez si votre expérience actuelle est correcte. Sinon, vous pouvez le modifier dans LinkedIn et répéter l’opération (recommandé) ou le modifier dans cette application en cliquant sur le bouton Modifier, à côté de chaque expérience.",
  "pages.current_experience.linkedin.content.upload": "Parcourir pour télécharger le profil LinkedIn",
  "pages.current_experience.linkedin.successfully_imported_profile": "Profil importé avec succès",
  "pages.current_experience.linkedin.reminder.content": "Vérifiez les dates de vos postes et présentez le niveau de vos compétences, et enfin définissez si vos formations ont été en ligne ou sur place.",
  "pages.current_experience.tip.content": "Dans cette section, il est important que vous téléchargiez votre programme, l’expérience sera plus personnalisée si nous connaissons le point de départ. D’autre part, vous devez faire la différence entre les compétences que vous avez générées au cours de votre vie professionnelle et les compétences acquises dans votre poste actuel lorsque vous remplissez les champs.",
  "pages.current_experience.skills": "Compétences",
  "pages.current_experience.skills.management": "Compétences en gestion",
  "pages.current_experience.skills.general": "Généralités",
  "pages.current_experience.skills.edit": "Modifier les compétences générales",
  "pages.current_experience.language.edit": "Modifier les compétences linguistiques",
  "pages.current_experience.confirm-delete-on-graph.title": "Supprimer la compétence utilisée sur le graphique de performances",
  "pages.current_experience.confirm-delete-on-graph.message": "Vous essayez de supprimer une compétence qui est utilisée sur le graphique de performance. Si après avoir supprimé cette compétence il y a moins de 3 compétences le graphique ne sera pas créé.",
  "pages.current_experience.present": "Présent",
  "pages.current_experience.edit.about.me": "Dis-moi quelque chose sur toi",
  "pages.current_experience.form.about.me.fields.name.label": "À propos de vous",
  "pages.current_experience.form.about.me.fields.name.placeholder": "Dites-moi quelque chose sur vous",
  "pages.current_experience.form.curriculum.upload": "Téléchargez votre curriculum",
  "pages.current_experience.form.curriculum.delete.prompt.title": "Supprimer le curriculum",
  "pages.current_experience.form.curriculum.delete.prompt.content": "Vous allez supprimer le curriculum. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "pages.current_experience.form.experience.edit_current": "Modifier votre position actuelle",
  "pages.current_experience.form.experience.edit_other": "Modifier votre poste",
  "pages.current_experience.form.experience.create_current": "Ajouter la tâche actuelle",
  "pages.current_experience.form.experience.create_other": "Ajouter une nouvelle expérience",
  "pages.current_experience.form.experience.fields.title.label": "Titre",
  "pages.current_experience.form.experience.fields.title.placeholder": "Entrez le poste",
  "pages.current_experience.form.experience.fields.description.label": "Description du poste",
  "pages.current_experience.form.experience.fields.description.placeholder": "Entrez la description du poste",
  "pages.current_experience.form.experience.fields.company.label": "Société",
  "pages.current_experience.form.experience.fields.company.placeholder": "Entrez dans la société",
  "pages.current_experience.form.experience.fields.location.label": "Emplacement",
  "pages.current_experience.form.experience.fields.location.placeholder": "Entrez l’emplacement de la tâche",
  "pages.current_experience.form.experience.fields.fromDate.label": "Démarrer",
  "pages.current_experience.form.experience.fields.toDate.label": "Fin",
  "pages.current_experience.form.experience.fields.currentJob.label": "Travail actuel",
  "pages.current_experience.form.experience.delete.title": "Supprimer l’expérience",
  "pages.current_experience.form.experience.delete.content": "Vous allez supprimer l’expérience sélectionnée. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "pages.current_experience.form.education.edit": "Modifier votre éducation",
  "pages.current_experience.form.education.create": "Ajouter une nouvelle éducation",
  "pages.current_experience.form.education.fields.title.label": "Titre de l’éducation",
  "pages.current_experience.form.education.fields.title.placeholder": "Entrez un titre",
  "pages.current_experience.form.education.fields.institution.label": "Institution",
  "pages.current_experience.form.education.fields.institution.placeholder": "Entrer dans un établissement",
  "pages.current_experience.form.education.fields.description.label": "Description",
  "pages.current_experience.form.education.fields.description.placeholder": "Entrez une description",
  "pages.current_experience.form.education.fields.location.label": "Emplacement",
  "pages.current_experience.form.education.fields.location.placeholder": "Entrez un emplacement",
  "pages.current_experience.form.education.fields.fromDate.label": "De",
  "pages.current_experience.form.education.fields.toDate.label": "À",
  "pages.current_experience.form.education.fields.inProgressEducation.label": "En cours",
  "pages.current_experience.form.education.delete.title": "Supprimer l’éducation",
  "pages.current_experience.form.education.delete.content": "Vous allez supprimer l’éducation sélectionnée. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "pages.guest.create_company_from_azure_ad.information.title": "Guide d’inscription à partir d’Azure Active Directory (Ad)",
  "pages.guest.create_company_from_azure_ad.information.content": "<h3>Pour générer le principal de service, accédez à Azure Ad et suivez les étapes suivantes :</h3><ol class=\"margin-l--m\" style=\"list-style:inside;list-style-position:outside\"><li class=\"margin-v--xs\">Accédez à Azure Active Directory et créez une application dans App Registration.</li><li class=\"margin-v--xs\">Entrez un nom dans le champ 'Nom'.\n\n</li><li class=\"margin-v--xs\">Dans Types de comptes pris en charge, sélectionnez l’option 'Locataire unique' et cliquez sur le bouton 'Enregistrer'.</li><li class=\"margin-v--xs\">Lorsque votre application est déjà créée, allez dans la section 'Autorisations API' et ajoutez-en une nouvelle de type 'User.Read.All'.</li><li class=\"margin-v--xs\">Vous devez maintenant ajouter une clé secrète client,  Nous recommandons 24 mois de date d’expiration. Copiez maintenant la clé secrète client.</li></ol><h3>Steps pour vous connecter avec Fleebe:</h3><ol class=\"margin-l--m\" style=\"list-style:inside;list-style-position:outside\"><li class=\"margin-v--xs\">Copiez l’ID du client d’application de l’étape précédente dans le champ 'ID client principal du service'.</li><li class=\"margin-v--xs\">Copiez la 'Clé secrète client' dans le champ 'Clé secrète client principale de service'.</li> <li class=\"margin-v--xs\">Cliquez sur le bouton 'Valider' et vous devriez voir le nombre d’utilisateurs de votre AD.</li></ol>",
  "pages.guest.create_company_from_azure_ad.form.fields.tenant_id.label": "ID de locataire",
  "pages.guest.create_company_from_azure_ad.form.fields.purchaseSubscriptionId.label": "ID d’abonnement",
  "pages.guest.create_company_from_azure_ad.form.fields.client_id.label": "ID client principal de service",
  "pages.guest.create_company_from_azure_ad.form.fields.client_id.placeholder": "Entrez l’ID principal du service",
  "pages.guest.create_company_from_azure_ad.form.fields.client_secret.label": "Secret client principal de service",
  "pages.guest.create_company_from_azure_ad.form.fields.client_secret.placeholder": "Entrez le secret principal du service",
  "pages.guest.create_company_from_azure_ad.form.fields.total_users.label": "Le nombre suivant d’utilisateurs sera importé à partir d’Azure Active Directory après le processus d’inscription",
  "pages.guest.create_company_from_azure_ad.form.buttons.validate": "Valider",
  "pages.guest.create_company_from_azure_ad.form.buttons.signup": "Chantez",
  "pages.guest.create_company_from_azure_ad.error_modal.title": "L'abonnement n'a pas pu être complété",
  "pages.guest.create_company_from_azure_ad.error_modal.description": "Nous sommes désolés, l'abonnement n'a pas pu être complété car le nombre d'utilisateurs achetés ({purchasedUsers}) ne correspond pas au nombre d'utilisateurs trouvés ({totalUsers}) dans Azure Active Directory. Veuillez relancer le processus d'achat.",
  "pages.guest.create_company_from_azure_ad.error_modal.button": "Redémarrer l'achat dans Microsoft AppSource",
  "pages.accounts.logout.title": "Votre session s’est terminée",
  "pages.accounts.logout.description": "Vous avez terminé votre session. Pour revenir, cliquez sur le bouton.",
  "pages.accounts.logout.signin": "Se connecter à Fleebe",
  "pages.accounts.admin_required.title": "Pas assez d’autorisations",
  "pages.accounts.admin_required.description": "Vous devez être administrateur pour accéder à la page demandée. Pour continuer à utiliser le site, cliquez sur le bouton.",
  "pages.accounts.admin_required.continue": "Retour à Fleebe",
  "pages.accounts.tenant_unauthorized.title": "Locataire non autorisé",
  "pages.accounts.tenant_unauthorized.description": "Le locataire que vous utilisez pour vous connecter n’est pas autorisé dans l’application. Veuillez confirmer que vous êtes inscrit pour utiliser Fleebe et continuer.",
  "pages.accounts.tenant_unauthorized.continue": "Retour à Fleebe",
  "pages.accounts.user_unauthorized.title": "Utilisateur non autorisé",
  "pages.accounts.user_unauthorized.description": "Le compte que vous utilisez pour vous connecter n’est pas autorisé dans l’application. Veuillez confirmer que vous êtes inscrit pour utiliser Fleebe et continuer. Si vous avez plusieurs comptes, essayez de vous déconnecter et reconnectez-vous.",
  "pages.accounts.user_unauthorized.logout": "Déconnexion",
  "pages.admin.dashboard.current_job.title": "Emploi actuel",
  "pages.admin.dashboard.ideal_job.title": "Emploi idéal",
  "pages.admin.dashboard.title": "Tableau de bord",
  "pages.admin.dashboard.percentage_employee_answered": "% d’employés répondus",
  "pages.admin.dashboard.welcome": "Bienvenue à Fleebe",
  "pages.admin.dashboard.welcome.tip.title": "Commencez où vous voulez",
  "pages.admin.dashboard.welcome.tip.content": "Commencez l’expérience avec votre assistant IA quand vous le souhaitez en remplissant les quatre cartes générales sous ces infobox.",
  "pages.admin.dashboard.welcome.shortcuts.profile.title": "Complétez le profil de votre organisation",
  "pages.admin.dashboard.welcome.shortcuts.profile.content": "Remplissez toutes les données d’entreprise de votre entreprise avec ses principales caractéristiques: Quelle est la taille de votre organisation? où est-il situé?",
  "pages.admin.dashboard.welcome.shortcuts.profile.button": "Profil complet de l’entreprise",
  "pages.admin.dashboard.welcome.shortcuts.skill.title": "Créez les compétences dont votre organisation a besoin",
  "pages.admin.dashboard.welcome.shortcuts.skill.content": "Votre organisation est différente et unique, par conséquent, elle a des besoins spécifiques que vous savez avoir un administrateur. Créez-les pour commencer à chercher des talents.",
  "pages.admin.dashboard.welcome.shortcuts.skill.button": "Créer des compétences",
  "pages.admin.dashboard.welcome.shortcuts.question.title": "Créer un formulaire pour obtenir les commentaires des employés",
  "pages.admin.dashboard.welcome.shortcuts.question.content": "Vos employés sont talentueux, donc leur opinion est précieuse. Créez ici un formulaire pour obtenir vos commentaires.",
  "pages.admin.dashboard.welcome.shortcuts.question.button": "Créer un formulaire",
  "pages.admin.dashboard.welcome.shortcuts.goal.title": "Créez des objectifs et des défis pour votre organisation",
  "pages.admin.dashboard.welcome.shortcuts.goal.content": "Gardez vos équipes et vos employés motivés, créez des objectifs communs et des buts apportant de la valeur à l’entreprise.",
  "pages.admin.dashboard.welcome.shortcuts.goal.button": "Créer des objectifs et des défis",
  "pages.admin.dashboard.search.introduction": "La nouvelle assistance IA pour aider vos employés à apprendre à leurs propres préférences et style de vie",
  "pages.admin.dashboard.search.placeholder": "Recherche d’employés...",
  "pages.admin.dashboard.current_experience.title": "Expérience actuelle",
  "pages.admin.dashboard.current_experience.about_me.curriculum": "Curriculum",
  "pages.admin.dashboard.current_experience.about_me.no_content_yet": "L’employé n’a pas encore fourni d’informations",
  "pages.admin.dashboard.current_experience.experience.no_content_yet": "L’employé n’a pas encore fourni d’informations sur l’expérience",
  "pages.admin.dashboard.current_experience.education.no_content_yet": "L’employé n’a pas encore fourni d’informations sur la formation",
  "pages.admin.dashboard.current_experience.languages.no_content_yet": "L’employé n’a pas encore fourni de langues",
  "pages.admin.dashboard.current_experience.general_skills.no_content_yet": "L’employé n’a pas encore fourni de compétences générales",
  "pages.admin.dashboard.my_ideal_job.no_content_yet": "L’employé n’a pas encore fourni les informations idéales sur le poste",
  "pages.admin.dashboard.ai_assistant.title": "AI Assistant",
  "pages.admin.dashboard.ai_assistant.description": "Ce sont les réponses fournies par le salarié",
  "pages.admin.dashboard.interests.title": "Intérêts",
  "pages.admin.dashboard.interests.interests.no_content_yet": "L’employé n’a pas encore fourni d’informations d’intérêt",
  "pages.admin.dashboard.interests.idealLearning.no_content_yet": "L’employé n’a pas encore fourni d’informations d’apprentissage",
  "pages.admin.dashboard.interests.hobbies.no_content_yet": "L’employé n’a pas encore fourni de passe-temps",
  "pages.admin.ai_assistant.title": "AI Assistant",
  "pages.admin.ai_assistant.create_new_survey_form.title": "Créer un nouveau formulaire d’enquête",
  "pages.admin.ai_assistant.create_new_survey_form.description": "Configurez les questions pour votre entreprise et choisissez celles qui doivent être visibles par vos employés",
  "pages.admin.ai_assistant.create_new_survey_form.new_question": "Ajouter une nouvelle question",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.title": "Niveau",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.description": "Utilisez ce composant pour que l’employé sélectionne rapidement un niveau d’éducation, d’expérience ou de talent, etc.",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.guide": "Essayez de rendre la déclaration aussi simple que possible afin que les réponses ne soient pas ambiguës et que les informations fournies ajoutent vraiment de la valeur. Établissez des niveaux clairs et bien contrastés afin qu’il n’y ait aucun doute dans la réponse.",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.input.title": "Entrez l’énoncé de la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.title": "Sélection unique",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.description": "Utilisez ce composant pour sélectionner une seule option parmi plusieurs options disponibles.",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.guide": "Essayez de rendre la déclaration aussi simple que possible afin que les réponses ne soient pas ambiguës et que les informations fournies ajoutent vraiment de la valeur. Établissez des options claires et bien contrastées afin qu’il n’y ait aucun doute dans la réponse.",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.input.title": "Entrez l’énoncé de la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.title": "Sélection multiple",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.description": "Utilisez ce composant pour sélectionner plusieurs options correctes.",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.guide": "Essayez de rendre la déclaration aussi simple que possible afin que les réponses ne soient pas ambiguës et que les informations fournies ajoutent vraiment de la valeur. Établissez des options claires et bien contrastées afin qu’il n’y ait aucun doute dans la réponse.",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.input.title": "Entrez l’énoncé de la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.title": "Réponse longue ouverte",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.description": "Utilisez ce type de composants pour les réponses ouvertes où l’employé peut faire des descriptions plus longues.",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.guide": "Essayez de rendre la déclaration aussi claire et simple que possible afin que les réponses ne soient pas ambiguës et que les informations fournies apportent une réelle valeur ajoutée.",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.input.title": "Entrez l’énoncé de la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.title": "Réponse ouverte courte",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.description": "Utilisez ce composant pour les réponses courtes.",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.guide": "Essayez de rendre la déclaration aussi claire et simple que possible afin que les réponses ne soient pas ambiguës et que les informations fournies ajoutent vraiment de la valeur.",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.input.title": "Entrez l’énoncé de la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.title": "Tags",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.description": "Utilisez ce composant lorsque l’employé doit saisir des réponses sous forme d’étiquettes.",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.guide": "Essayez de rendre la déclaration aussi simple que possible afin que les réponses ne soient pas ambiguës et que les informations fournies ajoutent vraiment de la valeur. Établissez des options claires et bien contrastées afin qu’il n’y ait aucun doute dans la réponse.",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.input.title": "Entrez l’énoncé de la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.select_question_type": "Sélectionnez le type de question",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.configure_question": "Configurer la question",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.save_question": "Enregistrer la question",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.options.title": "Entrez les options de réponse",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.options.description": "Vous avez besoin d’au moins deux options mais vous pouvez ajouter toutes celles dont vous avez besoin.",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.option.title": "Option {optionIndex}",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.option.placeholder": "Titre de l’option",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.title": "Entrez les niveaux de réponse",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.low_level": "Niveau bas",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.medium_level": "Niveau moyen",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.high_level": "Haut niveau",
  "pages.admin.ai_assistant.create_new_survey_form.preview.title": "Aperçu",
  "pages.admin.ai_assistant.create_new_survey_form.preview.description": "La question comporte des erreurs de configuration. Passez à l’étape précédente et configurez-le correctement.",
  "pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample1": "balise 1",
  "pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample2": "balise 2",
  "pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample1": "option 1",
  "pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample2": "option 2",
  "pages.admin.ai_assistant.kpi_tip.title": "Créer des KPI",
  "pages.admin.ai_assistant.kpi_tip.subtitle": "Vous pouvez créer des KPI des questionnaires envoyés afin de visualiser l'éventail des réponses. Sélectionnez l'icône du graphique pour voir les résultats pour chaque question que vous recherchez.",
  "pages.admin.ai_assistant.questions.title": "Questions d’enquête",
  "pages.admin.ai_assistant.questions.seeAllQuestions": "Voir toutes les questions",
  "pages.admin.ai_assistant.questions.search": "Recherche dans les questions",
  "pages.admin.ai_assistant.questions.created_questions": "Questions créées",
  "pages.admin.ai_assistant.questions.sent_questions": "Questions envoyées",
  "pages.admin.ai_assistant.questions.send_questions": "Envoyer des questions",
  "pages.admin.ai_assistant.questions.do_not_send_questions": "N’envoyez pas de questions",
  "pages.admin.ai_assistant.questions.add_new_question": "Ajouter une nouvelle question",
  "pages.admin.ai_assistant.questions.preview": "Aperçu des questions",
  "pages.admin.ai_assistant.questions.grid.number": "#",
  "pages.admin.ai_assistant.questions.grid.answeredPercentage": "Participation(%)",
  "pages.admin.ai_assistant.questions.grid.groups": "Groupes",
  "pages.admin.ai_assistant.questions.grid.groups.tooltip.more_groups": "{n} de plus",
  "pages.admin.ai_assistant.questions.grid.groups.tooltip.groups": "{n} groupe | {n} groupes",
  "pages.admin.ai_assistant.questions.grid.question": "Question",
  "pages.admin.ai_assistant.questions.grid.date": "Date",
  "pages.admin.ai_assistant.questions.grid.no_results": "Il n’y a pas de questions avec ce filtre",
  "pages.admin.ai_assistant.questions.grid.delete.title": "Supprimer la question",
  "pages.admin.ai_assistant.questions.grid.delete.description": "Vous allez supprimer la question '{question}'. Êtes-vous sûr?",
  "pages.admin.ai_assistant.questions.grid.show_in_dashboard": "Afficher dans le tableau de bord",
  "pages.admin.ai_assistant.questions.grid.remove__from_dashboard": "Supprimer du tableau de bord",
  "pages.admin.ai_assistant.questions.grid.descend": "Descendre",
  "pages.admin.ai_assistant.questions.grid.ascend": "Ascender",
  "pages.admin.ai_assistant.questions.questions_with_no_answers": "Cette question n’a pas encore reçu de réponse",
  "pages.admin.ai_assistant.questions.employee_answered": "Participation",
  "pages.admin.ai_assistant.questions.othersSummary": "Autres",
  "pages.admin.ai_assistant.questions.noContentYet": "Pas encore de contenu",
  "pages.admin.ai_assistant.questions.allQuestionsPublished": "Toutes les questions définies sont publiques",
  "pages.admin.company_goals.application_closed": "Candidatures fermées",
  "pages.admin.company_goals.draft": "Draft",
  "pages.admin.company_goals.application_open": "Ouvrir à l’application",
  "pages.admin.company_goals.title": "Objectifs de l’entreprise",
  "pages.admin.company_goals.create.public": "Créer un objectif public pour les candidats",
  "pages.admin.company_goals.create.public.description": "Nous avons lancé ce nouvel objectif dans le but de développer le plein potentiel de chacun de nos employés tout en se concentrant sur leurs besoins et leurs capacités. Soyez celui qui élabore le plan de transformation en fonction de vos objectifs personnels et professionnels.",
  "pages.admin.company_goals.create.new_public_goal": "Créer un objectif public",
  "pages.admin.company_goals.create.private": "Créer un objectif privé et sélectionner une équipe",
  "pages.admin.company_goals.create.private.description": "Nous avons identifié les compétences dont vous avez besoin pour faire progresser votre carrière. Cet objectif proposé vous permettra de les développer ou de les renforcer. La transformation continue est essentielle à l’évolution de votre carrière professionnelle.",
  "pages.admin.company_goals.create.new_private_goal": "Créer un objectif privé",
  "pages.admin.company_goals.search.introduction": "Nouveaux objectifs et défis",
  "pages.admin.company_goals.search.placeholder": "Objectifs de recherche pour l’équipe",
  "pages.admin.company_goals.search.options.view_all": "Tous",
  "pages.admin.company_goals.search.options.view_visibility": "Visibilité",
  "pages.admin.company_goals.search.options.view_public": "Public",
  "pages.admin.company_goals.search.options.view_private": "Privé",
  "pages.admin.company_goals.search.options.view_application_phase": "Phase d’application",
  "pages.admin.company_goals.search.options.view_application_phase_open": "Ouvrir",
  "pages.admin.company_goals.search.options.view_application_phase_closed": "Fermé",
  "pages.admin.company_goals.search.options.view_finished": "Terminé",
  "pages.admin.company_goals.search.options.view_draft": "Brouillon",
  "pages.admin.company_goals.search.options.view_launched": "Lancé",
  "pages.admin.company_goals.search.options.view_published": "Publié",
  "pages.admin.company_goals.search.options.view_status": "Statut",
  "pages.admin.company_goals.search.order.start_date_asc": "Date de début (asc)",
  "pages.admin.company_goals.search.order.start_date_desc": "Date de début (desc)",
  "pages.admin.company_goals.search.order.end_date_asc": "Date de fin (asc)",
  "pages.admin.company_goals.search.order.end_date_desc": "Date de fin (desc)",
  "pages.admin.company_goals.search.order.title_asc": "Titre (asc)",
  "pages.admin.company_goals.search.order.title_desc": "Titre (desc)",
  "pages.admin.company_goals.new_goal": "Ajouter un nouvel objectif",
  "pages.admin.company_goals.last_updated": "Dernière mise à jour, {date}",
  "pages.admin.company_goals.goal_dates": "Dates des buts: {start} - {end}",
  "pages.admin.company_goals.goal": "Équipe de but",
  "pages.admin.company_goals.save": "Sauver l’équipe et le but",
  "pages.admin.company_goals.skills": "{number} compétences",
  "pages.admin.company_goals.education": "{number} education",
  "pages.admin.company_goals.languages": "{number} langues",
  "pages.admin.company_goals.delete_goal.title": "Supprimer l’objectif: {name}",
  "pages.admin.company_goals.delete_goal.description": "Vous allez supprimer l’objectif sélectionné <span class=\"text text--bold\">\"{name}\"</span>. Tous les progrès et les informations sur celui-ci disparaîtront et cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "pages.admin.company_goals.delete_goal.successfully_deleted": "L’objectif a été supprimé avec succès",
  "pages.admin.company_goals.add_new_goal.title": "Nouvel objectif",
  "pages.admin.company_goals.add_new_goal.fields.title.label": "Titre",
  "pages.admin.company_goals.add_new_goal.fields.title.placeholder": "Entrez le titre de l’objectif ou du défi",
  "pages.admin.company_goals.add_new_goal.fields.description.label": "Description de l’objectif",
  "pages.admin.company_goals.add_new_goal.fields.description.placeholder": "Tapez ici une brève description",
  "pages.admin.company_goals.add_new_goal.fields.link.label": "Lien avec plus d’informations",
  "pages.admin.company_goals.add_new_goal.fields.link.placeholder": "Ajouter un lien avec plus d’informations",
  "pages.admin.company_goals.add_new_goal.fields.number_of_people_needed.label": "Nombre de personnes nécessaires",
  "pages.admin.company_goals.add_new_goal.fields.country.label": "Pays",
  "pages.admin.company_goals.add_new_goal.fields.country.placeholder": "Entrez le pays",
  "pages.admin.company_goals.add_new_goal.fields.city.label": "Ville",
  "pages.admin.company_goals.add_new_goal.fields.city.placeholder": "Entrez la ville",
  "pages.admin.company_goals.add_new_goal.fields.application_closing.label": "Fermeture de l’application",
  "pages.admin.company_goals.add_new_goal.fields.is_open.label": "Objectif ouvert aux candidatures",
  "pages.admin.company_goals.add_new_goal.fields.start.label": "Démarrer",
  "pages.admin.company_goals.add_new_goal.fields.end.label": "Fin",
  "pages.admin.company_goals.add_new_goal.fields.status.label": "Objectif ouvert aux candidatures",
  "pages.admin.company_goals.add_new_goal.fields.picture.label": "Choisissez une image pour la couverture",
  "pages.admin.company_goals.add_new_goal.fields.picture.upload": "Télécharger l’image",
  "pages.admin.company_goals.add_new_goal.fields.skills_needed": "Compétences requises",
  "pages.admin.company_goals.add_new_goal.path.overview": "Vue d’ensemble",
  "pages.admin.company_goals.add_new_goal.path.general_skills": "Compétences générales",
  "pages.admin.company_goals.add_new_goal.path.general_skills.description": "Veuillez choisir les compétences générales requises pour l’objectif",
  "pages.admin.company_goals.add_new_goal.path.language_skills": "Compétences linguistiques",
  "pages.admin.company_goals.add_new_goal.path.language_skills.description": "Veuillez choisir les compétences linguistiques requises pour l’objectif",
  "pages.admin.company_goals.add_new_goal.save": "Publier",
  "pages.admin.company_goals.add_new_goal.successfully_created": "L’objectif a été créé avec succès",
  "pages.admin.company_goals.add_new_goal.successfully_updated": "L’objectif a été mis à jour avec succès",
  "pages.admin.company_goals.add_new_goal.confirm_publish.title": "Confirmer la publication",
  "pages.admin.company_goals.add_new_goal.confirm_publish.description": "Vous allez publier l’objectif. Après ce point, vous ne pourrez plus modifier certains champs. Veuillez continuer uniquement si vous êtes sûr que l’objectif est prêt.",
  "pages.admin.company_goals.edit_goal.fields.title.label": "Titre",
  "pages.admin.company_goals.edit_goal.fields.title.placeholder": "Entrez le titre de l’objectif ou du défi",
  "pages.admin.company_goals.edit_goal.fields.description.label": "Description de l’objectif",
  "pages.admin.company_goals.edit_goal.fields.description.placeholder": "Tapez ici une brève description",
  "pages.admin.company_goals.edit_goal.fields.number_of_people_needed.label": "Nombre de personnes nécessaires",
  "pages.admin.company_goals.edit_goal.fields.country.label": "Pays",
  "pages.admin.company_goals.edit_goal.fields.city.label": "Ville",
  "pages.admin.company_goals.edit_goal.fields.link.label": "Lien avec plus d’informations",
  "pages.admin.company_goals.edit_goal.fields.link.placeholder": "Ajouter un lien avec plus d’informations",
  "pages.admin.company_goals.edit_goal.fields.application_closing.label": "Fermeture de l’application",
  "pages.admin.company_goals.edit_goal.fields.is_open.label": "L’objectif est ouvert aux candidatures",
  "pages.admin.company_goals.edit_goal.fields.is_not_open.label": "L’objectif n’est pas ouvert aux applications",
  "pages.admin.company_goals.edit_goal.fields.start.label": "Démarrer",
  "pages.admin.company_goals.edit_goal.fields.end.label": "Fin",
  "pages.admin.company_goals.edit_goal.save": "Enregistrer",
  "pages.admin.company_goals.edit_goal.successfully_edited": "L’objectif a été modifié avec succès",
  "pages.admin.company_goals.detail_goals.more_information": "Plus d’informations",
  "pages.admin.company_goals.detail_goals.progress": "Progression de l’objectif de l’équipe",
  "pages.admin.company_goals.detail_goals.team_skills.title": "Pourcentage d’adaptation à l’objectif",
  "pages.admin.company_goals.detail_goals.team_skills.skills": "Compétences",
  "pages.admin.company_goals.detail_goals.team_skills.skills.description": "Plus de 80% des compétences sont considérées comme un bon ratio pour atteindre les objectifs proposés. En dessous de 50%, il est conseillé de recruter ou de remplacer des membres par d’autres personnes ayant les compétences spécifiques requises.",
  "pages.admin.company_goals.goal_detail.add_team_member": "Ajouter un membre de l’équipe à partir de la liste des candidats",
  "pages.admin.company_goals.goal_detail.ai_selected_candidate": "L’IA a choisi une équipe automatiquement mais vous pouvez la modifier manuellement. L’objectif ne commencera pas tant que vous n’aurez pas validé l’équipe.",
  "pages.admin.company_goals.goal_detail.ai_will_filter_candidates": "Vient de terminer la date de candidature, le moteur d’IA filtrera automatiquement les candidats pour sélectionner ceux qui sont le meilleur choix. Ainsi, vous ne verrez que le nombre de candidats nécessaires pour créer l’équipe pour l’objectif.",
  "pages.admin.company_goals.goal_detail.application_phase.tag.closed": "Fermé",
  "pages.admin.company_goals.goal_detail.application_phase.tag.draft": "Brouillon",
  "pages.admin.company_goals.goal_detail.application_phase.tag.open": "Toujours ouvert",
  "pages.admin.company_goals.goal_detail.city": "Ville",
  "pages.admin.company_goals.goal_detail.confirm_launch.description": "Vous allez lancer l’objectif. Veuillez continuer uniquement si vous êtes sûr que l’objectif est prêt à être lancé.",
  "pages.admin.company_goals.goal_detail.confirm_launch.title": "Confirmer le lancement",
  "pages.admin.company_goals.goal_detail.confirm_set_result.description": "Vous allez définir le résultat de l’objectif. S’il vous plaît, continuez seulement si vous êtes sûr que le résultat est le bon.",
  "pages.admin.company_goals.goal_detail.confirm_set_result.title": "Confirmer {result} result",
  "pages.admin.company_goals.goal_detail.country": "Pays",
  "pages.admin.company_goals.goal_detail.dates_candidates_application": "Dates de candidature des candidats",
  "pages.admin.company_goals.goal_detail.dates.application": "application",
  "pages.admin.company_goals.goal_detail.dates.end": "Date de fin de l’objectif {title}",
  "pages.admin.company_goals.goal_detail.dates.start": "Date de début de l’objectif {title}",
  "pages.admin.company_goals.goal_detail.dates.tag.closed": "Fermé",
  "pages.admin.company_goals.goal_detail.dates.tag.draft": "Brouillon",
  "pages.admin.company_goals.goal_detail.dates.tag.in_progress": "En cours",
  "pages.admin.company_goals.goal_detail.dates.tag.stand_by": "Standby",
  "pages.admin.company_goals.goal_detail.dates.tag.waiting_candidates": "En attente de candidats",
  "pages.admin.company_goals.goal_detail.dates": "Dates de réalisation des objectifs",
  "pages.admin.company_goals.goal_detail.description": "Description",
  "pages.admin.company_goals.goal_detail.edit_goal": "Modifier l’objectif",
  "pages.admin.company_goals.goal_detail.employee_full_name": "{firstName} {lastName}",
  "pages.admin.company_goals.goal_detail.employees_applying": "Candidats qui postulent",
  "pages.admin.company_goals.goal_detail.employees_modal.applicants": "{number} candidats",
  "pages.admin.company_goals.goal_detail.employees_modal.list_title.employee": "Employé",
  "pages.admin.company_goals.goal_detail.employees_modal.list_title.match_with_goal": "Correspondance avec l’objectif",
  "pages.admin.company_goals.goal_detail.employees_modal.search.placeholder": "Objectifs et défis de recherche",
  "pages.admin.company_goals.goal_detail.employees_modal.title": "Trouver des personnes pour l’objectif",
  "pages.admin.company_goals.goal_detail.employees_on_charge": "Employés en charge de cet objectif",
  "pages.admin.company_goals.goal_detail.employees_selected": "Employés sélectionnés",
  "pages.admin.company_goals.goal_detail.employees.delete.content": "Vous allez supprimer cet employé pour l’équipe de l’objectif. Vous pouvez le sélectionner pour un autre objectif ou l’ajouter à nouveau plus tard. Êtes-vous sûr de vouloir continuer?",
  "pages.admin.company_goals.goal_detail.employees.delete.title": "Supprimer l’employé",
  "pages.admin.company_goals.goal_detail.employees": "Employés",
  "pages.admin.company_goals.goal_detail.launch_goal": "Objectif de lancement",
  "pages.admin.company_goals.goal_detail.launched_goal": "Lancé",
  "pages.admin.company_goals.goal_detail.link": "Plus d’informations",
  "pages.admin.company_goals.goal_detail.no_assigned_yet": "Aucun employé encore affecté",
  "pages.admin.company_goals.goal_detail.open_for_applications": "Objectif ouvert aux candidatures",
  "pages.admin.company_goals.goal_detail.people_needed": "Nombre de personnes nécessaires",
  "pages.admin.company_goals.goal_detail.progress.label": "Progression de l’objectif de l’équipe",
  "pages.admin.company_goals.goal_detail.progress.no_progression.application_open": "La phase de candidature est toujours ouverte",
  "pages.admin.company_goals.goal_detail.progress.no_progression.draft": "L’objectif est encore en phase de brouillon",
  "pages.admin.company_goals.goal_detail.progress.no_progression.team_built": "L’équipe est construite mais l’objectif n’a pas encore commencé",
  "pages.admin.company_goals.goal_detail.progress.no_progression.team_not_built": "L’équipe n’a pas encore été construite",
  "pages.admin.company_goals.goal_detail.progress.title": "Progression",
  "pages.admin.company_goals.goal_detail.published_for_applicants": "Publié pour les candidats",
  "pages.admin.company_goals.goal_detail.result_failed": "L’objectif a été un échec",
  "pages.admin.company_goals.goal_detail.result_not_set": "L’objectif est terminé. Veuillez sélectionner l’évaluation globale qui correspond le mieux au résultat final de l’objectif.",
  "pages.admin.company_goals.goal_detail.result_succeeded": "L’objectif a été un succès",
  "pages.admin.company_goals.goal_detail.skills_needed.no_content_yet": "Vous n’avez pas encore fourni d’informations",
  "pages.admin.company_goals.goal_detail.skills_needed": "Compétences requises",
  "pages.admin.company_goals.goal_detail.successfully_launched": "L’objectif a été lancé avec succès",
  "pages.admin.company_goals.goal_detail.successfully_result_set": "Le résultat de l’objectif a été défini avec succès",
  "pages.admin.company_goals.goal_detail.team_skills": "Compétences d’équipe",
  "pages.admin.internal_promotions.title": "Promotions internes",
  "pages.admin.company_settings.title": "Paramètres de l’entreprise",
  "pages.admin.company_settings.save": "Enregistrer le profil",
  "pages.admin.company_settings.general_info.title": "Informations générales",
  "pages.admin.company_settings.general_info.form.name": "Nom",
  "pages.admin.company_settings.general_info.form.description": "Description",
  "pages.admin.company_settings.general_info.form.feedbackEmail": "E-mail de commentaires",
  "pages.admin.company_settings.general_info.successfully_updated": "Informations générales sur l’entreprise mises à jour avec succès",
  "pages.admin.company_settings.general_info.chatbot.step": "Pas",
  "pages.admin.company_settings.general_info.chatbot.teams.title": "M. Fleebe en MsTeams",
  "pages.admin.company_settings.general_info.chatbot.teams.download.label": "M. Chatbot",
  "pages.admin.company_settings.general_info.chatbot.teams.download.text": "Télécharger",
  "pages.admin.company_settings.general_info.chatbot.teams.step.1": "Accédez à <a href='https://admin.teams.microsoft.com' class='text--link' target='blank'>Administration des équipes</a>",
  "pages.admin.company_settings.general_info.chatbot.teams.step.2": "Télécharger l'application téléchargée",
  "pages.admin.company_settings.general_info.chatbot.teams.step.3": "attribuer des autorisations",
  "pages.admin.company_settings.general_info.chatbot.teams.step.4": "Allez-y doucement... Le chatbot a besoin de temps pour se rafraîchir et devenir disponible.",
  "pages.admin.company_settings.general_info.chatbot.slack.title": "M. Fleebe sur Slack",
  "pages.admin.company_settings.general_info.chatbot.slack.add": "Ajouter à Slack",
  "pages.admin.company_settings.general_info.chatbot.slack.step.1": "Cliquez sur le bouton 'Ajouter à Slack'",
  "pages.admin.company_settings.general_info.chatbot.slack.step.2": "Si la liste déroulante de l'espace de travail lâche s'affiche, sélectionnez celle que vous souhaitez",
  "pages.admin.company_settings.general_info.chatbot.slack.step.3": "Acceptez les conditions",
  "pages.admin.company_settings.azure_info.title": "Informations Azure",
  "pages.admin.company_settings.azure_info.form.service_principal_id": "ID principal de service",
  "pages.admin.company_settings.azure_info.form.service_principal_secret": "Secret principal de service",
  "pages.admin.company_settings.azure_info.form.service_principal_secret.placeholder": "**************************",
  "pages.admin.company_settings.azure_info.form.tenant_id": "ID de locataire",
  "pages.admin.company_settings.azure_info.form.purchase_subscription_id": "ID d’abonnement",
  "pages.admin.company_settings.azure_info.successfully_updated": "Informations sur la société Azure mises à jour avec succès",
  "pages.admin.skills.grid.name": "Nom",
  "pages.admin.skills.grid.description": "Description",
  "pages.admin.skills.grid.status": "Statut",
  "pages.admin.skills.grid.type": "Type",
  "pages.admin.skill_management.search_placeholder": "Compétences de recherche",
  "pages.admin.skill_management.new_skill": "Ajouter une nouvelle compétence",
  "pages.admin.skill_management.filters.status.all": "Tous",
  "pages.admin.skill_management.filters.status.Pending": "Modération en attente",
  "pages.admin.skill_management.filters.status.Approved": "Approuvé",
  "pages.admin.skill_management.filters.type.all": "Tous",
  "pages.admin.skill_management.filters.type.General": "Général",
  "pages.admin.skill_management.filters.type.Language": "Langue",
  "pages.admin.skill_management.search_show_all_skills": "Toutes",
  "pages.admin.skill_management.skill_requester_name": "Demandeur",
  "pages.admin.skill_management.skill_moderator_name": "modérateur",
  "pages.admin.skill_management.grid.no_results": "Il n’y a pas de compétences avec ce filtre",
  "pages.admin.skill_management.skill_status.Pending": "En attente de modération",
  "pages.admin.skill_management.skill_status.Approved": "Approuvé",
  "pages.admin.skill_management.edit_skill": "Modifier la compétence",
  "pages.admin.skill_management.delete": "Supprimer la compétence",
  "pages.admin.skill_management.moderate": "Modéré",
  "pages.admin.skill_management.confirm_to_delete": "Si vous supprimez la compétence, il sera supprimé la compétence et la demande de l’employé. Voulez-vous continuer?",
  "pages.admin.skill_management.validated_effects.title": "Compétence validée",
  "pages.admin.skill_management.validated_effects.text": "La compétence a été validée. Désormais, les employés peuvent le sélectionner pour compléter leur cursus",
  "pages.admin.skill_management.noContentYet": "Pas encore de contenu",
  "pages.admin.talent_detection.chart.no_content": "Nous n’avons pas assez d’informations pour créer le graphique",
  "pages.admin.talent_detection.chart.by_age": "Par âge",
  "pages.admin.talent_detection.chart.by_group": "Par groupe",
  "pages.admin.talent_detection.employee_motivation.search.placeholder": "Rechercher",
  "pages.admin.talent_detection.employee_motivation.title.employee": "Employé(e)",
  "pages.admin.talent_detection.employee_motivation.title.motivation": "Motivation",
  "pages.admin.talent_detection.employee_motivation.title": "Gamme motivationnelle des employés",
  "pages.admin.talent_detection.employees.no_content": "Il n’y a pas encore de contenu sélectionné",
  "pages.admin.talent_detection.employees_group.title": "{n} employé {group} dans {category} | {n} employés {group} dans {category}",
  "pages.admin.talent_detection.employees_age.title.age_from": "{n} employé dans {category} (depuis {from} ans) | {n} employés dans {category} (depuis {from} ans)",
  "pages.admin.talent_detection.employees_age.title.age_to": "{n} employé dans {category} (pour {to} ans) | {n} employés dans {category} (pour {to} ans)",
  "pages.admin.talent_detection.employees_age.title.age_range": "{n} employé dans {category} (depuis {from} to {to} ans) | {n} employés dans {category} (depuis {from} to {to} ans)",
  "pages.admin.talent_detection.employees_age.title.age_all": "{n} employé dans {category} | {n} employés dans {category}",
  "pages.admin.talent_detection.search": "Rechercher un passe-temps",
  "pages.admin.talent_detection.tip.content": "Lorsque le graphique est enfin construit, cliquez sur les zones colorées pour voir comment les employés se rapportent aux passe-temps sélectionnés.",
  "pages.admin.talent_detection.tip.title": "Principaux passe-temps cachés",
  "pages.admin.talent_detection.title": "Détection des talents",
  "pages.admin.talent_detection.view_motivation": "Voir tous les employés",
  "pages.admin.learning.title": "Apprendre dans l’entreprise",
  "pages.admin.learning.title.short": "Apprentissage",
  "pages.admin.learning.search_placeholder": "Rechercher",
  "pages.admin.learning.create_new_learning_form.title": "Créez la formation dont votre entreprise a besoin",
  "pages.admin.learning.create_new_learning_form.description": "Si nécessaire, vous pouvez également créer des cours adaptés aux besoins de votre entreprise.",
  "pages.admin.learning.create_new_learning_form.new_learning": "Ajouter une formation",
  "pages.admin.learning.create_new_learning_form.successfully_created": "La formation a été créée avec succès",
  "pages.admin.learning.form.create": "Ajouter une nouvelle formation",
  "pages.admin.learning.form.edit": "Modifier la formation",
  "pages.admin.learning.form.fields.title.label": "Titre",
  "pages.admin.learning.form.fields.title.placeholder": "Titre de la formation",
  "pages.admin.learning.form.fields.description.label": "Description",
  "pages.admin.learning.form.fields.description.placeholder": "Tapez ici",
  "pages.admin.learning.form.fields.startDate.label": "Date de début",
  "pages.admin.learning.form.fields.endDate.label": "Date de fin",
  "pages.admin.learning.form.fields.level.label": "Niveau",
  "pages.admin.learning.form.fields.duration.label": "Durée (heures)",
  "pages.admin.learning.form.fields.topics.label": "Sujets (séparés par des virgules)",
  "pages.admin.learning.form.fields.topics.placeholder": "Type topics",
  "pages.admin.learning.form.fields.onsiteInformation.label": "Informations sur site",
  "pages.admin.learning.form.fields.onsiteCountry.label": "Pays",
  "pages.admin.learning.form.fields.onsiteCountry.placeholder": "Entrez le pays",
  "pages.admin.learning.form.fields.onsiteCity.label": "Ville",
  "pages.admin.learning.form.fields.onsiteCity.placeholder": "Entrez la ville",
  "pages.admin.learning.form.fields.onsiteAddress.label": "Adresse",
  "pages.admin.learning.form.fields.onsiteAddress.placeholder": "Entrez l’adresse",
  "pages.admin.learning.form.fields.onsiteFloor.label": "Étage",
  "pages.admin.learning.form.fields.onsiteFloor.placeholder": "Enter floor",
  "pages.admin.learning.form.fields.onsiteRoom.label": "Salle",
  "pages.admin.learning.form.fields.onsiteRoom.placeholder": "Entrer dans la salle",
  "pages.admin.learning.form.fields.onsiteSchedule.label": "Planification",
  "pages.admin.learning.form.fields.onsiteSchedule.placeholder": "Entrer la planification",
  "pages.admin.learning.form.fields.onsiteStartTime.label": "Heure de début",
  "pages.admin.learning.form.fields.onsiteEndTime.label": "Heure de fin",
  "pages.admin.learning.form.fields.attachment.label": "Joindre la couverture de l’image",
  "pages.admin.learning.form.fields.courseUrl.title": "Tapez ici l’URL du cours",
  "pages.admin.learning.form.fields.courseUrl.description": "Contactez le fournisseur pour obtenir un lien valide pour le cours.",
  "pages.admin.learning.form.fields.courseUrl.label": "URL de la formation",
  "pages.admin.learning.form.fields.courseUrl.placeholder": "Par exemple https://www.training.com/course/name-of-the-course/",
  "pages.admin.learning.form.save": "Créer une formation",
  "pages.admin.learning.form.fields.language.label": "Langue",
  "pages.admin.learning.form.languages.english": "Anglais",
  "pages.admin.learning.form.languages.spanish": "espagnol",
  "pages.admin.learning.prompt.delete.title": "Supprimer le cours",
  "pages.admin.learning.prompt.delete.description": "Vous allez supprimer ce cours. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer?",
  "shared.close": "Fermer",
  "shared.languages.english": "Anglais",
  "shared.languages.en": "Anglais",
  "shared.languages.spanish": "Espagnol",
  "shared.languages.es": "Espagnol",
  "shared.languages.french": "Français",
  "shared.languages.fr": "Français",
  "shared.languages.german": "Allemand",
  "shared.languages.italian": "Italien",
  "shared.languages.portuguese": "Portugais",
  "shared.app_tag.no_content_yet": "Vous n’avez pas encore fourni d’informations",
  "shared.today": "Aujourd’hui",
  "shared.yesterday": "Hier",
  "shared.lastUpdate": "Dernière mise à jour",
  "shared.not_available": "N/A",
  "shared.yes": "Oui",
  "shared.no": "Non",
  "shared.back": "Retour",
  "shared.results": "{n} result | {n} résultats",
  "shared.resultsFor": "{n} Result For '{name}' | {n} Résultats pour '{name}'",
  "shared.applicants": "{n} demandeur | {n} demandeurs",
  "shared.assigned": "{n} employé affecté | {n} employés affectés",
  "shared.attachment.label": "Choisissez un fichier à télécharger",
  "shared.time.minutes": "{time} min",
  "shared.time.hours": "{time} h",
  "shared.are_you_sure": "Êtes-vous sûr de vouloir continuer?",
  "pages.admin.employee_roles.search_placeholder": "Recherche par nom, prénom et e-mail",
  "pages.admin.admin_roles.roles.grid.email": "Email",
  "pages.admin.admin_roles.roles.grid.name": "Nom",
  "pages.admin.admin_roles.roles.grid.surname": "Nom",
  "pages.admin.admin_roles.roles.grid.role": "Rôle",
  "pages.admin.admin_roles.roles.grid.role.Administrator": "Administrateur",
  "pages.admin.admin_roles.roles.grid.role.ITManager": "Responsable informatique",
  "pages.admin.admin_roles.roles.grid.role.User": "Utilisateur",
  "pages.admin.admin_roles.roles.grid.role.all": "Tous",
  "pages.goals_challenges.details.is_open.label": "Objectif ouvert aux candidatures",
  "pages.goals_challenges.details.people_needed.label": "Personnes recherchées",
  "pages.goals_challenges.details.country.label": "Pays",
  "pages.goals_challenges.details.city.label": "Ville",
  "pages.goals_challenges.details.start_date.label": "Date de début de l’objectif",
  "pages.goals_challenges.details.end_date.label": "Date de fin de but",
  "pages.goals_challenges.details.my_progress.label": "Progression de l’objectif",
  "pages.goals_challenges.details.coworkers_with_this_goal.label": "Collaborateur avec cet objectif | Collaborateurs avec cet objectif",
  "pages.goals_challenges.details.participate": "Participer",
  "pages.goals_challenges.details.reject": "Rejeter",
  "pages.goals_challenges.details.reject_application": "Rejeter ma demande",
  "pages.goals_challenges.details.colleagues_registered": "{n} collègue inscrit | {n} collègues inscrits",
  "pages.my_groups.title": "Mes groupes",
  "pages.my_groups.search.placeholder": "Recherche d'employés",
  "pages.my_groups.grid.actions.view_profile": "Voir le profil",
  "pages.my_groups.view_questions": "Afficher les questions",
  "shared.company_groups_questions.title": "Des questions",
  "shared.company_groups_questions.subtitle": "Questions de l'enquête",
  "shared.company_groups_questions.created_questions": "Questions créées",
  "shared.company_groups_questions.sent_questions": "Questions envoyées",
  "shared.company_groups_questions.search.placeholder": "Questions de recherche",
  "shared.company_groups_questions.add_new_question": "Ajouter une nouvelle question",
  "shared.company_groups_questions.remove": "Supprimer des questions",
  "shared.company_groups_questions.send": "Envoyer des questions",
  "shared.company_groups_questions.tip.title": "Nouvelles questions",
  "shared.company_groups_questions.tip.content": "Vos employés sont talentueux, donc leur avis est précieux. Créez ici un formulaire pour obtenir vos commentaires.",
  "shared.company_groups_questions.grid.field.question": "Question",
  "shared.company_groups_questions.grid.field.answeredPercentage": "Participation (%)",
  "shared.company_groups_questions.grid.field.send_date": "Envoyer la date",
  "shared.company_groups_questions.view_kpis": "Voir les KPI",
  "shared.company_groups_questions.view_questions": "Afficher les questions",
  "shared.company_groups_questions.grid.actions.remove": "Supprimer la question du groupe",
  "shared.company_groups_questions.grid.actions.preview": "Aperçu des questions",
  "shared.company_groups_questions.grid.actions.edit": "Modifier la question",
  "shared.company_groups_questions.send_to_groups.title": "Envoyer des questions aux groupes",
  "shared.company_groups_questions.send_to_groups.content": "Sélectionnez les destinataires auxquels vous souhaitez envoyer l'enquête. Seul un administrateur peut créer un groupe de destinataires. Si vous ne trouvez pas le groupe dont vous avez besoin, veuillez contacter votre administrateur des ressources humaines.",
  "shared.company_groups_questions.send_to_groups.search.placeholder": "Rechercher",
  "shared.company_groups_questions.send_to_groups.buttons.send": "Envoyer",
  "shared.company_groups_questions.send_to_groups.fields.group_name": "Nom de groupe",
  "shared.company_groups_questions.send_to_groups.fields.people": "Personnes",
  "shared.question_answers.title": "Réponses à '{text}'",
  "shared.question_answers.search.placeholder": "Recherche",
  "shared.question_answers.no_answer": "Pas encore de réponse",
  "shared.question_answers.action.show": "Afficher les réponses aux questions",
  "shared.question_answers.statistics.metric": "{count} sur {total}",
  "shared.question_answers.statistics.copy": "Les gens ont répondu",
  "shared.question_kpi.pin": "Épinglez ce graphique pour l'afficher dans le tableau de bord",
  "shared.question_kpi.unpin": "Retirer ce graphique du tableau de bord",
  "shared.question_kpi.action.show": "Afficher la question KPI",
  "shared.question_kpi.no_answers": "Cette question n’a pas encore reçu de réponse",
  "shared.employee.full_name": "{firstName} {lastName}",
  "shared.company-groups.grid.full_name": "Nom",
  "shared.company-groups.grid.email": "Email",
  "shared.agree_share_information": "J’accepte de partager mes informations avec l’entreprise",
  "shared.skillEditor.newSkill.skill.title": "Vous ne trouvez pas la compétence?",
  "shared.skillEditor.newSkill.skill.addNewButton": "Ajouter une nouvelle compétence",
  "shared.skillEditor.newSkill.language.title": "Vous ne trouvez pas la langue?",
  "shared.skillEditor.newSkill.language.addNewButton": "Ajouter une nouvelle langue",
  "shared.skillEditor.skill.search": "Rechercher une compétence",
  "shared.skillEditor.language.search": "Recherche d’une langue",
  "shared.skillEditor.skill.pendingArea": "En attente d’approbation compétences:",
  "shared.skillEditor.language.pendingArea": "En attente d’approbation langues:",
  "shared.skillCreator.skill.title": "Créer une nouvelle compétence",
  "shared.skillCreator.language.title": "Créer une nouvelle langue",
  "shared.skillCreator.skill.hrMessage": "La nouvelle compétence doit être validée par l’administrateur RH.",
  "shared.skillCreator.language.hrMessage": "La nouvelle langue doit être validée par l’administrateur RH.",
  "shared.skillCreator.form.skill_type_label": "Type de compétence",
  "shared.skillCreator.form.description": "Description",
  "shared.skillCreator.form.name": "Nom",
  "shared.skillCreator.form.skill.createButton": "Créer une compétence",
  "shared.skillCreator.form.language.createButton": "Créer une langue",
  "shared.table.rows_per_page": "Lignes par page",
  "shared.table.go_to_first_page": "Aller à la première page",
  "shared.table.go_to_previous_page": "Aller à la page précédente",
  "shared.table.go_to_next_page": "Aller à la page suivante",
  "shared.table.go_to_last_page": "Aller à la dernière page",
  "shared.tips.title": "Tip",
  "shared.tips.dismiss": "J’ai compris",
  "shared.reminder.title": "Gentil rappel",
  "shared.feedback-sender.feedback-button": "Feedback",
  "shared.feedback-sender.bug-button": "Bug",
  "shared.feedback-sender.feedback-message": "Veuillez écrire vos commentaires ici. Merci beaucoup.",
  "shared.feedback-sender.bug-message": "Veuillez préciser comment reproduire ce bogue ou inclure une capture d'écran.",
  "entities.goals_challenges.has_not_started": "L’objectif n’a pas encore commencé",
  "entities.goals_challenges.has_finished": "L’objectif est terminé",
  "entities.goals_challenges.no_goals_yet": "Vous n’avez pas encore d’objectifs",
  "entities.goals_challenges.employee_no_goals_yet": "L’employé n’a pas encore d’objectifs",
  "entities.learning.language": "Langue",
  "entities.learning.duration": "Durée",
  "entities.learning.level": "Niveau",
  "entities.learning.topics": "Topics",
  "entities.learning.more_information": "Plus d’informations",
  "entities.my_ideal_job.title": "Mon métier idéal",
  "entities.my_ideal_job.tab.learning_path": "Parcours d’apprentissage",
  "entities.my_ideal_job.tab.my_training": "Ma formation",
  "entities.my_ideal_job.tab.company_training": "Formation en entreprise",
  "entities.my_ideal_job.general_skills": "Compétences",
  "entities.my_ideal_job.languages": "Langues",
  "entities.my_ideal_job.tip.content": "Quel serait votre travail idéal en ce moment? Complétez-le ici pour être guidé dans l’atteinte de vos objectifs !",
  "entities.my_ideal_job.add_job": "Ajouter un emploi idéal",
  "entities.my_ideal_job.update_job": "Mettez à jour votre emploi idéal",
  "entities.my_ideal_job.fields.title": "Titre",
  "entities.my_ideal_job.fields.title.placeholder": "Ajoutez un titre pour votre emploi idéal",
  "entities.my_ideal_job.fields.description": "Description",
  "entities.my_ideal_job.fields.description.placeholder": "Ajouter une description pour votre emploi idéal",
  "entities.my_ideal_job.recommended_learning": "Apprentissage recommandé",
  "entities.my_interests.hobbies": "Hobbies",
  "entities.my_interests.add-hobbies": "Modifier les passe-temps",
  "entities.my_interests.hobbies.category": "Catégorie",
  "entities.my_interests.hobbies.edit-modal.title": "Loisirs",
  "entities.my_interests.hobbies.edit-modal.search": "Recherche d’un passe-temps",
  "entities.my_interests.hobbies.edit-modal.needNew": "Vous ne trouvez pas le passe-temps?",
  "entities.my_interests.hobbies.edit-modal.newButton": "Ajouter un nouveau passe-temps",
  "entities.my_interests.hobbies.hobbies-creator.title": "Créer un nouveau passe-temps",
  "entities.my_interests.hobbies.hobbies-creator.createButton": "Créer un passe-temps",
  "entities.my_interests.hobbies.hobbies-creator.form.name": "Nom",
  "entities.my_interests.hobbies.category.sport": "Sport",
  "entities.my_interests.hobbies.category.music": "Musique",
  "entities.my_interests.hobbies.category.culture": "Culture",
  "entities.my_interests.hobbies.category.technology": "Technologie",
  "entities.my_interests.hobbies.category.others": "Autre",
  "entities.my_interests.hobbies.no_hobbies": "Aucun passe-temps défini",
  "entities.my_interests.hobbies.age": "Âge",
  "entities.skills.Low": "Faible",
  "entities.skills.Medium": "Medium",
  "entities.skills.High": "High",
  "entities.courses.level.empty": "--",
  "entities.courses.level.1": "Principiante",
  "entities.courses.level.2": "Avanzado",
  "entities.courses.level.3": "Experto",
  "level_KPI.x.title": "Vote os",
  "pages.admin.company_settings.employee_groups.title": "Groupes d'employés",
  "pages.admin.company_settings.role_management.title": "Rôles",
  "pages.admin.company_settings.skill_management.title": "Compétences",
  "pages.admin.company_settings.chatbot.title": "Mr. Fleebe",
  "pages.admin.company_settings.employee_groups.search_placeholder": "Rechercher des groupes d'employés",
  "pages.admin.company_settings.employee_groups.new_group": "Ajouter un nouveau groupe",
  "pages.admin.company_settings.employee_groups.fields.name": "Nom",
  "pages.admin.company_settings.employee_groups.fields.description": "Description",
  "pages.admin.company_settings.employee_groups.fields.people": "Personnes",
  "pages.admin.company_settings.employee_groups.fields.courses": "Apprentissage",
  "pages.admin.company_settings.employee_groups.fields.employee-count": "{n} personnes",
  "pages.admin.company_settings.employee_groups.fields.course-count": "{n} cours",
  "pages.admin.company_settings.employee_groups.no_results": "Il n'y a aucun groupe avec ce filtre",
  "pages.admin.company_settings.employee_groups.delete.title": "Supprimer le groupe d'employés",
  "pages.admin.company_settings.employee_groups.delete.description": "Vous allez supprimer ce groupe d'employés. Cette action ne peut pas être annulée. Êtes-vous sûr de vouloir continuer ?",
  "pages.admin.company_settings.employee_groups.editor.title": "Modifier le groupe d'employés",
  "pages.admin.company_settings.employee_groups.editor.new_group": "Nouveau groupe",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.title": "Importer la liste des employés",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.title": "Comment importer un groupe.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.description": "Importer un document d'un format valide. La première colonne doit être 'Email' et les suivantes les emails des employés que vous voulez dans le groupe.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.no_email_column_error.title": "Aucune colonne nommée Email.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.no_email_column_error.description": "Il doit y avoir une colonne nommée 'Email'.<br>Pour nommer une colonne, la première ligne de la colonne doit être 'Email'. Veuillez télécharger un nouveau document avec ce nom de colonne.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.not_all_are_emails_error.title": "Toutes les valeurs ne sont pas des e-mails.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.not_all_are_emails_error.description": "Toutes les valeurs de la colonne 'E-mail' ne correspondent pas à une adresse e-mail valide : <br><br> {notValidEmails} <br><br>Veuillez vérifier le format.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.emails_not_matched.title": "E-mails introuvables.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.emails_not_matched.description": "Les e-mails suivants n'ont pas été trouvés entre les employés : <br><br> {notFoundEmails} <br><br>D'autres e-mails ont été ajoutés au groupe.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.all_emails_matched.title": "Employés ajoutés au groupe.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.all_emails_matched.description": "Tous les employés ont été ajoutés au groupe.",
  "pages.admin.company_settings.employee_groups.editor.search_placeholder": "Recherche d'employés",
  "pages.admin.company_settings.employee_groups.editor.selected_employees": "Employés sélectionnés {n} sur {count}",
  "pages.admin.company_settings.employee_groups.editor.grid.actions.manage": "Gérer",
  "pages.admin.company_settings.employee_groups.role.Manager": "Directeur",
  "pages.admin.company_settings.employee_groups.role.Viewer": "Téléspectateur",
  "pages.admin.company_settings.employee_groups.role.all": "Tous",
  "pages.admin.company_settings.employee_groups.tabs.employees": "Employés",
  "pages.admin.company_settings.employee_groups.tabs.questions": "Des questions",
  "pages.admin.company_settings.employee_groups.tabs.learning": "Apprentissage",
  "pages.admin.company_settings.employee_groups.manage.title.Employees": "Employés et rôles actuels du groupe",
  "pages.admin.company_settings.employee_groups.manage.title.Questions": "Gestion des questions",
  "pages.admin.company_settings.employee_groups.manage.title.Learning": "Apprentissage attribué à ce groupe",
  "pages.admin.company_settings.employee_groups.manage.learning.select_training": "Sélectionnez la formation",
  "pages.admin.company_settings.employee_groups.manage.learning.add_training": "Ajouter une nouvelle formation",
  "pages.admin.company_settings.employee_groups.manage.learning.no_content": "Pas encore de contenu",
  "pages.admin.company_settings.employee_groups.manage.learning.search.placeholder": "Rechercher des cours pour ce groupe",
  "pages.admin.company_settings.employees-with-groups.title": "Gestion des autorisations",
  "pages.admin.company_settings.employees-with-groups.search_placeholder": "Recherche d'employés",
  "pages.admin.company_settings.employees-with-groups.fields.first-name": "Nom",
  "pages.admin.company_settings.employees-with-groups.fields.last-name": "Nom de famille",
  "pages.admin.company_settings.employees-with-groups.fields.email": "E-mail",
  "pages.admin.company_settings.employees-with-groups.fields.group-count": "Groupes",
  "pages.admin.company_settings.employees-with-groups.group-count": "{n} groupe| {n} groupes",
  "pages.admin.company_settings.employees-with-groups.actions.edit": "Modifier le groupe",
  "pages.admin.company_settings.employees-with-groups.actions.manage": "Gérer le groupep"
}