<template>
  <div class="company-employee-group-current">
    <p data-cy="company-employee-group-current-counter">
      {{
        $t("pages.admin.company_settings.employee_groups.editor.selected_employees", {
          n: selectedEmployees.length,
          count: employeesCount,
        })
      }}
    </p>
    <div class="company-employee-group-current__tags">
      <AppTag
        v-for="employee of selectedEmployeesOrdered"
        :key="employee.id"
        class="margin-b--xs margin-r--xs"
        data-cy="company-employee-group-editor-tag"
        :main-text="getEmployeeName(employee)"
        type="employee"
        :deletable="true"
        @delete-tag="remove(employee)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";
import AppTag from "@/components/AppTag.vue";
import { compareStrings } from "@/utils/stringUtils";
import type { EmployeeInformation } from "@/models/employee/employeeInformation";

interface Props {
  selectedEmployees: EmployeeInformation[];
  employeesCount: number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "removeEmployee", employee: EmployeeInformation) => void>();

const selectedEmployeesOrdered = computed(() =>
  [...props.selectedEmployees].sort((a, b) => compareStrings(getEmployeeName(a), getEmployeeName(b), true))
);

const remove = (employee: EmployeeInformation) => emit("removeEmployee", employee);

const getEmployeeName = ({ firstName, lastName }: EmployeeInformation) =>
  i18n.global.t("shared.employee.full_name", { firstName, lastName });
</script>

<style lang="scss" scoped>
.company-employee-group-current {
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  min-height: 25rem;
  overflow-y: hidden;

  &__tags {
    border: 1px solid var(--color-input-border);
    height: 100%;
    margin-top: 10px;
    overflow-y: auto;
    padding: 9px 9px;
    resize: none;
  }
}
</style>
