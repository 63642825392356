<template>
  <KpiChart :chart-options="chartOptions" :chart-series="series" />
</template>

<script lang="ts" setup>
import type { ApexOptions } from "apexcharts";
import { PIE_DONUTS_COLORS } from "@/models/questionKpi";
import KpiChart from "@/views/admin/ai-assistant/components/kpis/KpiChart.vue";
import { formatNumber } from "@/utils/numberUtils";

interface Props {
  series: number[];
  seriesPercentage: number[];
  labels: string[];
}
const props = defineProps<Props>();

const chartOptions: ApexOptions = {
  labels: props.labels,
  chart: {
    type: "pie",
    selection: {
      enabled: false,
    },
  },
  stroke: {
    colors: ["#fff"],
  },
  states: {
    active: {
      filter: {
        type: "none",
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    normal: {
      filter: {
        type: "none",
      },
    },
  },
  colors: PIE_DONUTS_COLORS,
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 0.8,
  },
  legend: {
    show: true,
    position: "right",
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    marker: {
      show: false,
    },
    style: {
      fontSize: "11px",
    },
    y: {
      formatter: (_value, { seriesIndex }) => `${formatNumber(props.seriesPercentage[seriesIndex])} %`,
    },
  },
};
</script>
