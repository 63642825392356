<template>
  <div v-if="goalDetails" class="goal-details">
    <div class="goal-details__title text--xl text--bold" data-cy="goal-details-title">{{ goalDetails.title }}</div>
    <div class="goal-details__info">
      <div class="goal-details__info--item">
        <p class="text--bold">{{ $t("pages.goals_challenges.details.is_open.label") }}</p>
        <p>{{ booleanToYesNoString(goalDetails.isPublic) }}</p>
      </div>
      <div class="goal-details__info--item">
        <p class="text--bold">{{ $t("pages.goals_challenges.details.people_needed.label") }}</p>
        <p>{{ goalDetails.numberOfPeopleNeeded }}</p>
      </div>
      <div class="goal-details__info--item">
        <p class="text--bold">{{ $t("pages.goals_challenges.details.country.label") }}</p>
        <p>{{ goalDetails.country }}</p>
      </div>
      <div class="goal-details__info--item">
        <p class="text--bold">{{ $t("pages.goals_challenges.details.city.label") }}</p>
        <p>{{ goalDetails.city }}</p>
      </div>
    </div>
    <div class="goal-details__dates">
      <div class="goal-details__dates--item">
        <p class="text--l text--bold">{{ $t("pages.goals_challenges.details.start_date.label") }}</p>
        <p class="text--xl">{{ parseDateToMonthLongDate(goalDetails.start) }}</p>
      </div>
      <div class="goal-details__dates--item">
        <p class="text--l text--bold">{{ $t("pages.goals_challenges.details.end_date.label") }}</p>
        <p class="text--xl">{{ parseDateToMonthLongDate(goalDetails.end) }}</p>
      </div>
    </div>
    <div class="goal-details__description">
      <p v-html="toHtml(goalDetails.description)"></p>
    </div>
    <div v-if="isInProgress(goalDetails)" class="goal-details__progress">
      <div class="goal-details__progress-container">
        <div class="goal-details__progress--label">
          <p>{{ $t("pages.goals_challenges.details.my_progress.label") }}</p>
          <p class="text--bold">{{ $n((goalDetails.progression ?? 0) / 100, "percent") }}</p>
        </div>
        <ProgressBar :value="goalDetails.progression" />
      </div>
    </div>
    <div v-if="isNotStarted(goalDetails)" class="goal-details__progress">
      <span class="icon icon-calendar text text--xl"></span> {{ $t("entities.goals_challenges.has_not_started") }}
    </div>
    <div
      v-if="isFinished(goalDetails)"
      class="goal-details__progress"
      :class="{ 'goal-details__progress--success': isSuccessful(goalDetails), 'goal-details__progress--failure': isFailure(goalDetails) }">
      <div v-if="isSuccessful(goalDetails)">
        {{ $t("pages.admin.company_goals.goal_detail.result_succeeded") }}
      </div>
      <div v-else-if="isFailure(goalDetails)">
        {{ $t("pages.admin.company_goals.goal_detail.result_failed") }}
      </div>
      <div v-else>
        {{ $t("entities.goals_challenges.has_finished") }}
      </div>
    </div>
    <div v-if="goalDetails.coworkersWithThisGoal > 0" class="goal-details__coworkers">
      <div class="avatar-counter">
        <span class="text--bold" data-cy="coworkers">{{ goalDetails.coworkersWithThisGoal }}</span>
      </div>
      <p class="text--bold">{{ $t("pages.goals_challenges.details.coworkers_with_this_goal.label", goalDetails.coworkersWithThisGoal) }}</p>
    </div>
    <ParticipateButton
      v-if="goalDetails.canParticipate"
      v-model="value"
      :goal-id="goalDetails.id"
      variant="largeReject"
      data-cy="participate" />
  </div>
</template>

<script setup lang="ts">
import type { MeGoalDetails } from "@/models/meGoalDetails";
import { booleanToYesNoString } from "@/utils/booleanUtils";
import { parseDateToMonthLongDate } from "@/utils/dateUtils";
import { toHtml } from "@/utils/stringUtils";
import ProgressBar from "@/components/AppProgressBar.vue";
import useMeGoalsService from "@/services/useMeGoalsService";
import { onMounted, ref, computed } from "vue";
import ParticipateButton from "@/views/app/goals-challenges/components/ParticipateButton.vue";
import { isNotStarted, isFinished, isSuccessful, isFailure, isInProgress } from "@/services/modelServices/companyGoalService";

interface Props {
  goalId: number;
}

const props = defineProps<Props>();
const emit = defineEmits<(e: "update:participating", goalId: number, participating: boolean) => void>();

const value = computed({
  get: () => {
    return goalDetails.value?.alreadyParticipant;
  },
  set: (val) => {
    if (goalDetails.value) {
      goalDetails.value.alreadyParticipant = !!val;
      emit("update:participating", goalDetails.value.id, !!val);
    }
  },
});

const { getMyGoalDetails } = useMeGoalsService();
const goalDetails = ref<MeGoalDetails>();

onMounted(async () => {
  goalDetails.value = await getMyGoalDetails(props.goalId);
});
</script>

<style scoped lang="scss">
.goal-details {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-m);

  &__info {
    display: flex;
    justify-content: space-between;

    &--item {
      display: flex;
      flex-direction: column;
      row-gap: var(--size-xs);
    }
  }

  &__dates {
    display: flex;
    column-gap: var(--size-m);

    &--item {
      display: flex;
      flex-direction: column;
      row-gap: var(--size-xs);
      padding: var(--size-m);
      width: 100%;
      border-radius: var(--default-border-radius);
      background: var(--color-neutral-200);
    }
  }

  &__progress-container {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
    width: 100%;
  }

  &__progress {
    display: flex;
    gap: var(--space-s);
    align-items: center;
    padding: var(--size-s);
    border-radius: var(--default-border-radius);
    background-color: var(--color-neutral-200);

    &--success {
      background-color: var(--color-system-success);
      color: var(--color-neutral-100);
    }

    &--failure {
      background-color: var(--color-system-error);
      color: var(--color-neutral-100);
    }

    &--label {
      display: flex;
      justify-content: space-between;
    }
  }

  &__coworkers {
    display: flex;
    align-items: center;
    column-gap: var(--size-xs);
  }
}

.avatar {
  border: var(--space-xxs) solid var(--color-avatar-border);
}

.avatar-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-xl);
  height: var(--size-xl);
  border-radius: var(--space-s);
  background-color: var(--color-avatar-default);
  border: var(--space-xxs) solid var(--color-avatar-border);
}
</style>
