<template>
  <div>
    <h3 class="margin-t--l">{{ $t("pages.learning.my_training.title") }}</h3>
    <CourseCarousel v-if="courses.length" :courses="courses" data-cy="training-courses" @course-selected="cardClick" />
    <AppNoContentYet v-else class="margin-t--m" data-cy="no-training" :text="$t('pages.learning.my_training.no_completed')" />
    <CourseDetails
      v-if="selectedCourse"
      :course="selectedCourse"
      :allow-edit="true"
      @saved="loadCourses"
      @closed="selectedCourse = undefined" />
  </div>
</template>
<script setup lang="ts">
import CourseCarousel from "@/views/shared/learning/CourseCarousel.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import CourseDetails from "@/views/shared/learning/CourseDetails.vue";
import useMeCoursesService from "@/services/useMeCoursesService";
import { onMounted, ref, type Ref } from "vue";
import type { EmployeeCourse } from "@/models/course";

const { getAlreadyDoneCourses } = useMeCoursesService();
const courses: Ref<EmployeeCourse[]> = ref<EmployeeCourse[]>([]);

const selectedCourse: Ref<EmployeeCourse | undefined> = ref<EmployeeCourse | undefined>();

const cardClick = (course: EmployeeCourse): void => {
  selectedCourse.value = course;
};

const loadCourses = async () => {
  courses.value = await getAlreadyDoneCourses();
};

onMounted(() => loadCourses());
</script>
