<template>
  <AppPanel :padding-content="false">
    <div class="app-upload-document">
      <div class="header">
        <div class="text text--bold">{{ label }}</div>
        <div class="text text--secondary">
          {{ $t("inputs.upload_document.supported_types", { types: acceptString }) }}
          ({{ $t("inputs.upload_document.maximum_size_MB", { size: maximumSize }) }})
        </div>
        <AppButton size="m" :variant="buttonVariant" text="Upload file" @onclick="upload" />
      </div>
      <div v-if="!hideFile || error" class="file">
        <div v-if="!modelValue?.fileName" class="text">{{ $t("inputs.upload_document.no_document_uploaded") }}</div>
        <div v-if="modelValue?.fileName" class="container">
          <a
            v-if="downloadUrl"
            :href="downloadUrl"
            target="_blank"
            class="text text--ellipsis text--link"
            :title="$t('buttons.download')"
            >{{ modelValue.fileName }}</a
          >
          <span v-if="modelValue.fileName && !downloadUrl" class="text text--ellipsis">{{ modelValue.fileName }}</span>
          <AppIconOnlyButton icon="delete" :title="$t('buttons.delete')" size="xl" @click="deleteDocumentClick" />
        </div>
        <AppInputError v-if="error" :error="error" />
      </div>
      <input ref="fileInput" type="file" :accept="accept" class="not-displayed" @change="fileInputChanged" />
      <div v-if="closeButton" class="close"><AppLinkButton :text="$t('shared.close')" @click="$emit('close')" /></div>
    </div>
  </AppPanel>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import AppInputError from "@/components/AppInputError.vue";
import { convertMIMEToExtension } from "@/utils/fileUtils";
import type { FileAttachment } from "@/models/fileAttachment";
import { useUploadDocument } from "@/components/uploadDocument/useUploadDocument";

interface Props {
  accept: string;
  buttonVariant?: "primary" | "secondary";
  closeButton?: boolean;
  downloadUrl?: string;
  error?: string;
  label: string;
  maximumSize: number;
  modelValue?: FileAttachment;
  withoutModal?: boolean;
  hideFile?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", document: FileAttachment | undefined): void;
  (e: "close"): void;
}>();

const fileInput = ref<HTMLInputElement>();

const { upload, clearFileSelector, deleteDocument, getFileFromInputAndClear } = useUploadDocument(fileInput, props);

const resetFileSelector = () => {
  clearFileSelector();
  emit("update:modelValue", undefined);
};
const deleteDocumentClick = () => deleteDocument(resetFileSelector);

const fileInputChanged = async () => {
  const file = await getFileFromInputAndClear();
  if (file) {
    emit("update:modelValue", file);
  }
};

const acceptString = computed<string>(() => {
  return props.accept
    .split(",")
    .map((type) => convertMIMEToExtension(type))
    .join(", ");
});
</script>

<style scoped lang="scss">
.app-upload-document {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: var(--size-s);
  border-bottom: 1px solid var(--color-border);
  gap: var(--size-xs);
}
.file {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: var(--size-s);
}
.container {
  display: flex;
  justify-content: space-between;
  place-items: center;
}
.close {
  display: flex;
  justify-content: flex-end;
  padding: var(--size-s);
  border-top: 1px solid var(--color-border);
}
</style>
