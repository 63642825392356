<template>
  <span data-cy="table-cell-text" class="text" :class="textClass" :title="value">{{ value }}</span>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { Column } from "@/components/table/Column";
import type { TableItem } from "@/components/table/TableItem";
import { columnValueExtractor } from "@/components/table/ColumnValueExtractor";

interface Props {
  column: Column;
  item: TableItem;
}
const props = defineProps<Props>();

const value = computed<string>(() => columnValueExtractor(props.column, props.item));
const textClass = computed<string>(() => (props.column.type === "long-text" ? "text--line-clamp-3" : "text--ellipsis"));
</script>
