<template>
  <GuestPage>
    <AppLoading v-if="loading" />
    <div class="create-company">
      <AppPanel class="width--50">
        <div class="grid--two-columns">
          <div>
            <h1 class="text text--xxl text--bold margin-b--m" data-cy="title">
              {{ $t("pages.guest.create_company_from_azure_ad.information.title") }}
            </h1>
            <div v-html="$t('pages.guest.create_company_from_azure_ad.information.content')"></div>
          </div>
          <div class="container">
            <AppLabel :value="tenantId" :label="$t('pages.guest.create_company_from_azure_ad.form.fields.tenant_id.label')" />
            <AppLabel
              class="margin-v--m"
              :value="purchaseSubscriptionId"
              :label="$t('pages.guest.create_company_from_azure_ad.form.fields.purchaseSubscriptionId.label')" />
            <AppForm>
              <AppInputText
                v-model="servicePrincipalId"
                data-cy="servicePrincipalId"
                :label="$t('pages.guest.create_company_from_azure_ad.form.fields.client_id.label')"
                :placeholder="$t('pages.guest.create_company_from_azure_ad.form.fields.client_id.placeholder')"
                :error="servicePrincipalIdKeyError"
                :maxlength="guidTextLength" />

              <AppInputText
                v-model="servicePrincipalSecret"
                data-cy="servicePrincipalSecret"
                type="password"
                :label="$t('pages.guest.create_company_from_azure_ad.form.fields.client_secret.label')"
                :placeholder="$t('pages.guest.create_company_from_azure_ad.form.fields.client_secret.placeholder')"
                :error="servicePrincipalSecretKeyError"
                :maxlength="mediumTextLength" />
            </AppForm>

            <AppInputText
              v-model="totalUsersTenant"
              :label="$t('pages.guest.create_company_from_azure_ad.form.fields.total_users.label')"
              :disabled="true" />

            <AppButton
              v-if="isServicePrincipalValidated"
              data-cy="signUpButton"
              :text="$t('pages.guest.create_company_from_azure_ad.form.buttons.signup')"
              @onclick="signUp()" />
            <AppButton
              v-else
              data-cy="validateButton"
              :text="$t('pages.guest.create_company_from_azure_ad.form.buttons.validate')"
              @onclick="validateServicePrincipal()" />
          </div>
        </div>
      </AppPanel>
    </div>
    <ErrorModal
      v-if="showErrorModal"
      :total-users="totalUsers"
      :purchased-users="purchasedUsers"
      @closed="() => (showErrorModal = false)" />
  </GuestPage>
</template>

<script setup lang="ts">
import { guidTextLength, mediumTextLength } from "@/constants/restrictions";
import { onMounted, ref } from "vue";
import { redirectToLogin } from "@/utils/redirectUtil";
import { useField, useForm } from "vee-validate";
import { useRoute } from "vue-router";
import AppButton from "@/components/AppButton.vue";
import AppForm from "@/components/AppForm.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppLabel from "@/components/AppLabel.vue";
import AppLoading from "@/components/AppLoading.vue";
import AppPanel from "@/components/AppPanel.vue";
import ErrorModal from "@/views/guest/create-company-from-azuread/components/GuestCreateCompanyFromAzureAdErrorModal.vue";
import GuestPage from "@/layout/guest/GuestPage.vue";
import type { AzureAdServicePrincipal } from "@/models/azureAd";
import type { CompanyAdSubscription } from "@/models/response/companyAdSubscription";
import useCompanyService from "@/services/useCompanyService";
import useFormSchema from "@/services/useFormSchema";

const { getAzureAdCompany, validateServicePrincipalAndGetNumberOfUsers, signUpAzureAdCompany, loading } = useCompanyService();
const { yupObject, stringSchema } = useFormSchema();

const resourceSchema = yupObject({
  clientId: stringSchema({ maxLength: guidTextLength }).min(guidTextLength),
  clientSecret: stringSchema(),
});

const form = useForm({ validationSchema: resourceSchema });

const route = useRoute();
const tenantId = ref<string>("");
const purchaseSubscriptionId = ref<string>("");
const totalUsersTenant = ref<string>("");
const userToken = ref<string>("");
const isServicePrincipalValidated = ref<boolean>(false);
const purchasedUsers = ref<number>(0);
const totalUsers = ref<number>(0);
const showErrorModal = ref<boolean>(false);

const { value: servicePrincipalId, errorMessage: servicePrincipalIdKeyError } = useField<string>("clientId");
const { value: servicePrincipalSecret, errorMessage: servicePrincipalSecretKeyError } = useField<string>("clientSecret");

onMounted(async () => {
  userToken.value = route.query["token"] as string;
  const result = await getAzureAdCompany(userToken.value);
  purchaseSubscriptionId.value = result.purchaseSubscriptionId;
  tenantId.value = result.tenantId;
});

const validateServicePrincipal = async () => {
  const validationResult = await form.validate();
  if (!validationResult.valid) {
    return;
  }
  const azureAdServicePrincipal: AzureAdServicePrincipal = {
    servicePrincipalId: servicePrincipalId.value,
    servicePrincipalSecret: servicePrincipalSecret.value,
  };
  const response = await validateServicePrincipalAndGetNumberOfUsers(userToken.value, azureAdServicePrincipal);
  if (!response.isSuccessful) {
    showError(response);
    return;
  }
  totalUsersTenant.value = response.totalUsers.toString();
  isServicePrincipalValidated.value = true;
};

const signUp = async () => {
  const validationResult = await form.validate();
  if (!validationResult.valid) {
    return;
  }
  const azureAdServicePrincipal: AzureAdServicePrincipal = {
    servicePrincipalId: servicePrincipalId.value,
    servicePrincipalSecret: servicePrincipalSecret.value,
  };
  const response = await signUpAzureAdCompany(userToken.value, azureAdServicePrincipal);
  if (!response.isSuccessful) {
    showError(response);
    return;
  }
  redirectToLogin("");
};
const showError = (subscriptionResponse: CompanyAdSubscription): void => {
  totalUsers.value = subscriptionResponse.totalUsers;
  purchasedUsers.value = subscriptionResponse.purchasedUsers;
  showErrorModal.value = true;
};
</script>

<style scoped lang="scss">
.create-company {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  padding: var(--space-xs);
}
</style>
