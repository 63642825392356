<template>
  <div>
    <div class="grid--two-columns@medium">
      <div>
        <h2 class="margin-b--m">{{ $t("pages.my_interests.interests") }}</h2>
        <AppNoContentYet
          v-if="interests?.length === 0"
          class="margin-b--m"
          :text="$t('pages.admin.dashboard.interests.interests.no_content_yet')" />
        <div v-for="interest in interests" :key="interest.id" class="margin-b--m" data-cy="dashboard-interests">
          <AppMyInterestsInterestEditor form-mode="view" :input="interest" />
        </div>
      </div>
      <div>
        <h2 class="margin-b--m">{{ $t("pages.my_interests.idealLearning") }}</h2>
        <AppNoContentYet
          v-if="idealLearnings?.length === 0"
          class="margin-b--m"
          :text="$t('pages.admin.dashboard.interests.idealLearning.no_content_yet')" />
        <div v-for="idealLearning in idealLearnings" :key="idealLearning.id" class="margin-b--m" data-cy="dashboard-ideal-learnings">
          <AppMyInterestsIdealLearningEditor form-mode="view" :input="idealLearning" />
        </div>
      </div>
    </div>
    <AppSeparator class="margin-v--m" />
    <div>
      <h2 class="margin-b--m">{{ $t("entities.my_interests.hobbies") }}</h2>
      <AppHobbiesPanel :editable="false" :impersonated-as="employeeId" form-mode="view" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppMyInterestsIdealLearningEditor from "@/views/shared/my-interests/AppMyInterestsIdealLearningEditor.vue";
import AppMyInterestsInterestEditor from "@/views/shared/my-interests/AppMyInterestsInterestEditor.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppHobbiesPanel from "@/components/AppHobbiesPanel.vue";
import type { IdealLearning } from "@/models/idealLearning";
import type { Interest } from "@/models/interest";
import useEmployeeService from "@/services/useEmployeeService";

interface Props {
  employeeId: number;
}
const props = defineProps<Props>();

const { getInterests, getIdealLearnings } = useEmployeeService();

const interests = ref<Interest[]>();
const idealLearnings = ref<IdealLearning[]>();

onMounted(async () => {
  interests.value = await getInterests(props.employeeId);
  idealLearnings.value = await getIdealLearnings(props.employeeId);
});
</script>
