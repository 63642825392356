<template>
  <span :data-cy="`icon-button-${icon}`" class="text icon" :class="otherClasses" :title="title" @click="handleClick"></span>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  size?: "m" | "l" | "xl" | "xxl";
  title?: string;
  icon: string;
  disabled?: boolean;
  asLink?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  asLink: true,
});

const emit = defineEmits<(e: "click") => void>();

const handleClick = () => {
  if (!props.disabled) {
    emit("click");
  }
};

const otherClasses = computed(() => ({
  disabled: props.disabled,
  [`text--${props.size}`]: props.size && props.size !== "m",
  [`icon-${props.icon}`]: true,
  "text--link": props.asLink,
}));
</script>

<style scoped lang="scss">
.icon {
  cursor: pointer;
  display: flex;
  place-content: center;
  &.disabled {
    pointer-events: none;
    opacity: 0.2;
    cursor: default;
  }
}
</style>
