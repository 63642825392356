<template>
  <div class="no-content">
    <AppNoContentYet
      :text="
        hasQuestionsDefined
          ? $t('pages.admin.ai_assistant.questions.allQuestionsPublished')
          : $t('pages.admin.ai_assistant.questions.noContentYet')
      " />
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import { QuestionStatus } from "@/models/questionStatus";
import useQuestionService from "@/services/useQuestionService";

interface Props {
  questionStatus: QuestionStatus;
}
const props = defineProps<Props>();

const { getPublishedQuestions } = useQuestionService();

const hasQuestionsDefined = ref<boolean>(true);

const checkQuestionsDefined = async () => {
  if (props.questionStatus === QuestionStatus.Unpublished) {
    hasQuestionsDefined.value = (await getPublishedQuestions(1)).length > 0;
  }
};

onMounted(checkQuestionsDefined);
</script>

<style scoped lang="scss">
.no-content {
  display: flex;
  justify-content: center;
  padding: var(--space-m);
}
</style>
