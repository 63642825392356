import AdminAIAssistantOverviewView from "@/views/admin/ai-assistant/AdminAIAssistantOverviewView.vue";
import AdminAIAssistantQuestionsView from "@/views/admin/ai-assistant/AdminAIAssistantQuestionsView.vue";
import AdminAIAssistantView from "@/views/admin/ai-assistant/AdminAIAssistantView.vue";
import AdminCompanyGoalsDetailsView from "@/views/admin/company-goals/AdminCompanyGoalsDetailsView.vue";
import AdminCompanyGoalsOverviewView from "@/views/admin/company-goals/AdminCompanyGoalsOverviewView.vue";
import AdminCompanyGoalsView from "@/views/admin/company-goals/AdminCompanyGoalsView.vue";
import AdminCompanySettingsView from "@/views/admin/company-settings/AdminCompanySettingsView.vue";
import AdminDashboardOverviewView from "@/views/admin/dashboard/AdminDashboardOverviewView.vue";
import AdminDashboardView from "@/views/admin/dashboard/AdminDashboardView.vue";
import AdminInternalPromotionsView from "@/views/admin/internal-promotions/AdminInternalPromotionsView.vue";
import AdminLayout from "@/layout/admin/AdminLayout.vue";
import AdminLearningView from "@/views/admin/learning/AdminLearningView.vue";
import AdminTalentDetectionView from "@/views/admin/talent-detection/AdminTalentDetectionView.vue";
import AdminCompanyEmployeeGroupEditorView from "@/views/admin/company-settings/company-employee-groups/AdminCompanyEmployeeGroupEditorView.vue";
import AdminCompanyEmployeeGroupManageView from "@/views/admin/company-settings/company-employee-groups/AdminCompanyEmployeeGroupManageView.vue";
import EmployeeProfileView from "@/views/shared/employee/EmployeeProfileView.vue";
import AdminCompanySettingsOverview from "@/views/admin/company-settings/AdminCompanySettingsOverview.vue";

export default [
  {
    path: "/admin",
    component: AdminLayout,
    children: [
      {
        path: "",
        name: "default-admin",
        redirect: "/admin/dashboard/overview",
      },
      {
        path: "dashboard",
        name: "dashboard-admin",
        component: AdminDashboardView,
        children: [
          {
            path: "overview",
            name: "dashboard-admin-overview",
            component: AdminDashboardOverviewView,
          },
          {
            path: "employees/:employeeId",
            name: "dashboard-admin-employee",
            props: {
              backRoute: "dashboard-admin-overview",
            },
            component: EmployeeProfileView,
          },
          {
            path: "",
            name: "dashboard-admin-default",
            redirect: "/admin/dashboard/overview",
          },
        ],
      },
      {
        path: "ai-assistant",
        name: "ai-assistant-admin",
        component: AdminAIAssistantView,
        children: [
          {
            path: "overview",
            name: "ai-assistant-admin-overview",
            component: AdminAIAssistantOverviewView,
          },
          {
            path: "questions",
            name: "ai-assistant-admin-questions",
            component: AdminAIAssistantQuestionsView,
          },
          {
            path: "",
            name: "default-ai-assistant-admin",
            redirect: "/admin/ai-assistant/overview",
          },
        ],
      },
      {
        path: "corporate-goals",
        name: "corporate-goals-admin",
        component: AdminCompanyGoalsView,
        children: [
          {
            path: "",
            name: "corporate-goals-admin-overview",
            component: AdminCompanyGoalsOverviewView,
          },
          {
            path: "goal/:goalId",
            name: "corporate-goals-admin-goal",
            component: AdminCompanyGoalsDetailsView,
          },
        ],
      },
      {
        path: "internal-promotions",
        name: "internal-promotions-admin",
        component: AdminInternalPromotionsView,
      },
      {
        path: "talent-detection",
        name: "talent-detection-admin",
        component: AdminTalentDetectionView,
      },
      {
        path: "skills-management",
        name: "skills-admin",
        redirect: "/admin/company-settings/overview/SkillsManagement",
      },
      {
        path: "learning",
        name: "learning-admin",
        component: AdminLearningView,
      },
      {
        path: "company-settings",
        name: "company-settings-admin",
        component: AdminCompanySettingsView,
        children: [
          {
            path: "overview/:tabId?",
            name: "company-settings-overview",
            component: AdminCompanySettingsOverview,
          },
          {
            path: "company-employee-groups/editor/:backTabId/:groupId?",
            name: "company-settings-company-employee-groups-editor",
            component: AdminCompanyEmployeeGroupEditorView,
          },
          {
            path: "company-employee-groups/manage/:backTabId/:groupId?",
            name: "company-settings-company-employee-groups-group-manage",
            component: AdminCompanyEmployeeGroupManageView,
          },
          {
            path: "",
            name: "default-company-settings",
            redirect: "/admin/company-settings/overview",
          },
        ],
      },
    ],
    meta: {
      requiresAuth: true,
      requiresAdministrator: true,
      isAdministratorArea: true,
    },
  },
];
