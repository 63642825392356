<template>
  <label class="app-basic-text" :class="keepMargin ? 'app-basic-text--keep-margin' : ''">
    <span v-if="label" class="text text--secondary">{{ label }}</span>
    <span v-if="icon" class="input-icon input-icon--left icon text text--xxl" :class="`icon-${icon}`"></span>
    <input
      v-app-focus="autofocus"
      :type="type || 'text'"
      :placeholder="placeholder"
      class="app-basic-text__input text"
      :class="inputClasses"
      :value="modelValue"
      :maxlength="maxlength"
      :disabled="disabled"
      @input="inputHandle"
      @keyup="(args) => emit('keyup', args)"
      @keydown="(args) => emit('keydown', args)"
      @click="emit('click')"
      @focusin="emit('focusin')"
      @blur="emit('blur')" />
    <AppIconOnlyButton
      v-if="showCleanOption"
      v-show="modelValue !== ''"
      class="input-icon input-icon--right"
      size="xxl"
      :as-link="false"
      icon="close-circle-outline"
      :title="$t('buttons.delete')"
      @click="handleCleanClicked" />
  </label>
</template>

<script setup lang="ts">
import { watch, computed } from "vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";

interface Props {
  modelValue?: string;
  label?: string;
  type?: "text" | "password";
  placeholder?: string;
  maxlength?: number;
  icon?: string;
  showCleanOption?: boolean;
  disabled?: boolean;
  autofocus?: boolean;
  keepMargin?: boolean;
  bgTransparent?: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "keyup", args: KeyboardEvent): void;
  (e: "keydown", args: KeyboardEvent): void;
  (e: "changed", newValue: string): void;
  (e: "click"): void;
  (e: "focusin"): void;
  (e: "blur"): void;
  (e: "cleaned"): void;
}>();

const inputHandle = (event: Event): void => {
  const input: HTMLInputElement = event.target as HTMLInputElement;
  emit("update:modelValue", input.value);
};

const handleCleanClicked = (): void => {
  emit("update:modelValue", "");
  emit("cleaned");
};

const inputClasses = computed(() => ({
  "app-basic-text__input--with-icon": !!props.icon,
  "app-basic-text__input--with-clean": props.showCleanOption,
  "app-basic-text__input--keep-margin": props.keepMargin,
  "app-basic-text__input--bg-transparent": !!props.bgTransparent,
}));

watch(
  () => props.modelValue,
  () => emit("changed", props.modelValue ?? "")
);
</script>

<style scoped lang="scss">
.app-basic-text {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;

  &--keep-margin {
    gap: 0;
  }

  &__input {
    padding: 0.9rem;
    border: 1px solid var(--color-input-border);
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--color-input-border--focus);
    }
    &--with-icon,
    &--with-clean {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
    }

    &--with-icon {
      padding-left: 4rem;
    }

    &--keep-margin {
      margin: 10px 0 4px 0;
    }

    &--bg-transparent {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &--left {
    left: 0.5rem;
  }
  &--right {
    right: 0.5rem;
  }
}
</style>
