import type { Ref } from "vue";
import type { IdealLearning } from "@/models/idealLearning";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me/ideal-learnings";
const { get, put, post, httpDelete, loading } = useHttpClient();

interface UseMeIdealLearningServiceReturn {
  getIdealLearnings: () => Promise<IdealLearning[]>;
  createIdealLearning: (idealLearning: IdealLearning) => Promise<IdealLearning>;
  updateIdealLearning: (idealLearning: IdealLearning) => Promise<IdealLearning>;
  deleteIdealLearning: (idealLearningId: number) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeIdealLearningServiceReturn => {
  const getIdealLearnings = (): Promise<IdealLearning[]> => get(BASE_URL);
  const createIdealLearning = (idealLearning: IdealLearning): Promise<IdealLearning> => post(BASE_URL, idealLearning);
  const updateIdealLearning = (idealLearning: IdealLearning): Promise<IdealLearning> =>
    put(`${BASE_URL}/${idealLearning.id}`, idealLearning);

  const deleteIdealLearning = (idealLearningId: number): Promise<void> => httpDelete(`${BASE_URL}/${idealLearningId}`);

  return { getIdealLearnings, createIdealLearning, updateIdealLearning, deleteIdealLearning, loading };
};
