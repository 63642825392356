import i18n from "@/i18n";
import { toCapitalize } from "@/utils/stringUtils";
import { compareNumbers } from "@/utils/numberUtils";

const millisecondsInDay = 1000 * 3600 * 24;
const minutesInHour = 60;

export const parseDateToString = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }
  if (isToday(date)) {
    return i18n.global.t("shared.today");
  }
  if (isYesterday(date)) {
    return i18n.global.t("shared.yesterday");
  }
  return date.toLocaleDateString();
};

export const parseDateToMonthLongDate = (date: Date): string => {
  // request a month long date: March 17, 2022
  const opt: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(i18n.global.locale.value || "en", opt);
};

export const isToday = (date: Date): boolean => isSameDay(date, new Date());
export const today = (): Date => removeTime(new Date());
export const removeTime = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const isSameDay = (date1: Date, date2: Date): boolean => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const isYesterday = (date: Date): boolean => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return isSameDay(date, yesterday);
};

export const getMonthName = (month: number): string => toCapitalize(getMonth(month, "onlyMonth"));
export const getMonthShortName = (month: number): string => toCapitalize(getMonth(month, "onlyMonth"));

const getMonth = (month: number, format: string): string => {
  const date = new Date();
  date.setMonth(month);
  return i18n.global.d(date, format);
};

export const compareDates = (date1: Date, date2: Date, ascending: boolean): number =>
  compareNumbers(date1.getTime(), date2.getTime(), ascending);

export const dayAfter = (date: Date): Date => new Date(date.getTime() + millisecondsInDay);

export const daysBetweenDates = (start: Date | undefined, end: Date | undefined): number => {
  if (!start || !end) {
    return 0;
  }
  const difference = end.getTime() - start.getTime();
  return Math.ceil(difference / millisecondsInDay);
};

export const isBiggerThanToday = (date: Date): boolean => daysBetweenDates(new Date(), date) > 0;
export const isEqualOrBiggerThanToday = (date: Date): boolean => daysBetweenDates(new Date(), date) >= 0;
export const isSmallerThanToday = (date: Date): boolean => daysBetweenDates(new Date(), date) < 0;
export const isEqualOrSmallerThanToday = (date: Date): boolean => daysBetweenDates(new Date(), date) <= 0;
export const isInRange = (startDate: Date, endDate: Date, theDate: Date): boolean => theDate >= startDate && theDate <= endDate;
export const convertDateOnlyToLocale = (date: Date): Date => {
  const offsetHours = -(date.getTimezoneOffset() / minutesInHour);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), offsetHours);
};
export const toIsoDate = (date: Date): string => date.toISOString().split("T")[0];
