<template>
  <AppLabel :compress="true" :label="$t('entities.learning.level')" :value="convertLevelToDisplay(level)" transformation="capitalize" />
</template>
<script setup lang="ts">
import i18n from "@/i18n";
import AppLabel from "@/components/AppLabel.vue";
import { CourseLevel } from "@/models/course";

interface Props {
  level?: string;
}
defineProps<Props>();

const convertLevelToDisplay = (level?: string) => {
  if (!level) {
    return i18n.global.t(`entities.courses.level.empty`);
  }
  const courseLevel = CourseLevel[level as keyof typeof CourseLevel];
  return i18n.global.t(`entities.courses.level.${courseLevel}`);
};
</script>
