<template>
  <div class="feedback margin-b--xs">
    <AppButton :text="$t('shared.feedback-sender.feedback-button')" size="full-width" variant="secondary" @onclick="sendFeedback" />
    <AppButton :text="$t('shared.feedback-sender.bug-button')" size="full-width" variant="primary" @onclick="sendBug" />
  </div>
</template>
<script setup lang="ts">
import i18n from "@/i18n";
import AppButton from "@/components/AppButton.vue";

interface Props {
  feedbackEmail: string;
}
const props = defineProps<Props>();

const sendFeedback = () => {
  window.location.href = `mailto:${props.feedbackEmail}?subject=Feedback&body=${encodeURIComponent(
    i18n.global.t("shared.feedback-sender.feedback-message")
  )}`;
};
const sendBug = () => {
  window.location.href = `mailto:${props.feedbackEmail}?subject=Bug&body=${encodeURIComponent(
    i18n.global.t("shared.feedback-sender.bug-message")
  )}`;
};
</script>
<style lang="scss" scoped>
.feedback {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
</style>
