<template>
  <div>
    <h2 class="margin-v--m">{{ $t("pages.current_experience.goals_challenges.title") }}</h2>
    <AppCarousel v-if="orderedGoals.length" :number-of-items="orderedGoals.length" data-cy="goals">
      <template v-for="(goal, index) in orderedGoals" :key="index" #[`item_${index+1}`]>
        <AppGoalsChallengeCurrentCard
          :goal="goal"
          :class-name="index ? 'margin-l--0' : 'margin-l--s'"
          data-cy="goal"
          @click="goalClick(goal.id)" />
      </template>
    </AppCarousel>
    <AppNoContentYet v-else :text="noGoalsText" data-cy="goals" />
    <AppModal name="goal-detail">
      <AppGoalDetails v-if="selectedGoalId" :goal-id="selectedGoalId" />
    </AppModal>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref } from "vue";
import i18n from "@/i18n";

import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppGoalsChallengeCurrentCard from "@/views/shared/challenges-goals/AppGoalsChallengeCurrentCard.vue";
import AppModal from "@/components/modal/AppModal.vue";
import AppGoalDetails from "@/views/app/goals-challenges/AppGoalDetails.vue";
import AppCarousel from "@/components/carousel/AppCarousel.vue";

import type { CompanyGoal } from "@/models/companyGoal";
import useModal from "@/components/modal/useModal";
import { goalsSorter } from "@/services/modelServices/companyGoalService";

interface Props {
  goals?: CompanyGoal[];
  noGoalsText: string;
}

const props = withDefaults(defineProps<Props>(), { goals: () => [] as CompanyGoal[] });

const selectedGoalId: Ref<number | undefined> = ref<number | undefined>();
const orderedGoals: CompanyGoal[] = [...props.goals].sort(goalsSorter);
const { openComponentModal } = useModal();

const goalClick = (goalId: number): void => {
  selectedGoalId.value = goalId;
  openComponentModal("goal-detail", {
    title: i18n.global.t("pages.goals_challenges.my.title"),
    variant: "floating",
    showCancelToClose: true,
    closeCallback: () => {
      selectedGoalId.value = undefined;
    },
  });
};
</script>
