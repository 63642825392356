<template>
  <AIAssistantEditorBase
    :question="question"
    :disable-key-enter="true"
    :form="form"
    :get-current-answer="getAnswer"
    @closed="closed"
    @edited="edited">
    <AppInputTags v-model="answers" :max-tag-length="200" :label="$t('pages.ai_assistant.question.tags')" :error="answerError" />
  </AIAssistantEditorBase>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { onMounted } from "vue";
import { object as yupObject, string as yupString, array as yupArray } from "yup";
import AppInputTags from "@/components/AppInputTags.vue";
import AIAssistantEditorBase from "@/views/app/ai-assistant/components/AIAssistantEditorBase.vue";
import { maxNumberOfTags } from "@/constants/restrictions";
import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import { useField, useForm } from "vee-validate";
import useFormSchema from "@/services/useFormSchema";

interface Props {
  question: Question;
}
const props = defineProps<Props>();

interface Emits {
  (e: "edited", newQuestionState: Question): void;
  (e: "closed"): void;
}
const emit = defineEmits<Emits>();

const { inputRequiredText } = useFormSchema();

const resourceSchema = yupObject({
  answers: yupArray(yupString())
    .min(1, inputRequiredText())
    .max(maxNumberOfTags, i18n.global.t("inputs.max_number_items_exceeded", { maxItems: maxNumberOfTags })),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: answers, errorMessage: answerError } = useField<string[]>("answers");

onMounted(async () => setFormValues());
const setFormValues = (): void => {
  if (props.question.tags && props.question.tags.length > 0) {
    answers.value = [...props.question.tags];
    return;
  }
  form.resetForm();
};

const getAnswer = (): Answer => ({ tags: answers.value });
const closed = () => emit("closed");
const edited = (newQuestionState: Question) => emit("edited", newQuestionState);
</script>
