<template>
  <AppModal
    :name="componentId"
    :disable-key-enter="disableKeyEnter"
    :disable-key-scape="disableKeyScape"
    :state="state"
    :overflow="overflow">
    <slot></slot>
    <template v-if="hasCustomActions" #actions>
      <slot name="actions"></slot>
    </template>
  </AppModal>
</template>

<script setup lang="ts">
import AppModal from "@/components/modal/AppModal.vue";
import type { ComponentModal } from "@/components/modal/models";
import useModal from "@/components/modal/useModal";
import { getRandomId } from "@/utils/randomUtils";
import { computed, getCurrentInstance, onMounted } from "vue";

const { openComponentModal } = useModal();

interface Props {
  modalConfig: ComponentModal;
  disableKeyScape?: boolean;
  disableKeyEnter?: boolean;
  state?: string;
  overflow?: boolean;
}
const props = withDefaults(defineProps<Props>(), { overflow: true });

const componentId: string = getRandomId();
onMounted(() => openComponentModal(componentId, props.modalConfig));
const hasCustomActions = computed<boolean>(() => !!getCurrentInstance()?.slots["actions"]);
</script>
