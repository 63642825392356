export const maxNumberOfTags = 20;
export const maximumNumberOfOptionsInQuestion = 5;

export const guidTextLength = 36;
export const smallTextLength = 16;
export const mediumTextLength = 200;
export const largeTextLength = 2000;
export const xLargeTextLength = 4000;

export const minLevelAnswer = 1;
export const maxLevelAnswer = 10;

export const MAX_AUTOCOMPLETE_ITEMS_TO_SHOW = 5;
