<template>
  <div class="actions">
    <div
      v-for="page in pages"
      :key="page"
      class="actions__item"
      :class="{ 'actions__item--active': currentPage === page }"
      :data-cy="`carousel-action-${page}`"
      @click="pageClick(page)"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { createArray } from "@/utils/numberUtils";

interface Props {
  currentPage: number;
  pageCount: number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "pageClick", pageIndex: number) => void>();

const pages = computed(() => createArray(props.pageCount));
const pageClick = (pageIndex: number) => emit("pageClick", pageIndex);
</script>
<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.actions {
  display: flex;
  justify-content: center;
  gap: var(--space-xs);

  @media only screen and (max-width: $medium-screen-breakpoint) {
    margin-left: var(--size-xxl);
  }
  &__item {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 1px solid var(--color-primary-accent1);
    cursor: pointer;

    &--active,
    &:hover {
      background-color: var(--color-primary-accent1);
    }
  }
}
</style>
