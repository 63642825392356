<template>
  <LayoutPage :title="$t('pages.learning.title')">
    <MyLearningNavigation class="margin-b--m" current="Learning" />
    <AppTip unique-name="app-learning" :content="$t('pages.learning.tip.content')" />
    <div class="learning-view">
      <CoursesListSearcher :event-emitter="eventEmitter" @course-selected="cardClick" />
      <CoursesFeatured :title="$t('pages.learning.featured.title')" :event-emitter="eventEmitter" @course-selected="cardClick" />
    </div>
    <CourseDetails
      v-if="selectedCourse"
      :course="selectedCourse"
      :allow-edit="true"
      @saved="saveHandler"
      @closed="selectedCourse = undefined" />
  </LayoutPage>
</template>

<script setup lang="ts">
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import MyLearningNavigation from "@/views/app/my-learning/components/shared/MyLearningNavigation.vue";
import { ref } from "vue";
import type { CourseBase } from "@/models/course";
import CourseDetails from "@/views/shared/learning/CourseDetails.vue";
import AppTip from "@/components/AppTip.vue";
import CoursesFeatured from "@/views/shared/learning/CoursesFeatured.vue";
import CoursesListSearcher from "@/views/app/my-learning/components/CoursesListSearcher.vue";
import useEventEmitterService from "@/services/useEventEmitterService";

const eventEmitter = useEventEmitterService();
const selectedCourse = ref<CourseBase | undefined>();

const cardClick = (course: CourseBase) => (selectedCourse.value = course);
const saveHandler = (courseId: number) => eventEmitter.trigger("courseSaved", courseId);
</script>
<style lang="scss" scoped>
.learning-view {
  display: flex;
  flex-direction: column;
  gap: var(--size-l);
}
</style>
