<template>
  <div>
    <UserInfoItem
      v-if="formMode === 'edit' || formMode === 'view'"
      data-cy="current-experience-experiences"
      :title="titleRead"
      :subtitle="parseJobSubtitle(companyRead, locationRead, fromDateRead, toDateRead)"
      :content="descriptionRead"
      :attachment-url="attachmentRead && input ? getJobExperienceAttachmentUrl(employeeId, input.id) : undefined"
      class="margin-b--xs"
      :truncate-text="truncateText" />

    <AppLinkButton v-if="formMode === 'create'" data-cy="create-job-experience" :text="addCurrentJobText" @click="openModal(panelName)" />
    <div v-if="formMode === 'edit'" class="job-editor">
      <AppLinkButton :text="$t('buttons.edit')" @click="openModal(panelName)" />
      <AppLinkButton
        v-if="jobExperienceType === JobExperienceType.JobExperience"
        :bold="false"
        :text="$t('buttons.delete')"
        @click="remove()" />
    </div>

    <AppModal :name="panelName" :disable-key-enter="true">
      <AppForm>
        <AppInputText
          v-model="title"
          data-cy="title"
          :label="$t('pages.current_experience.form.experience.fields.title.label')"
          :placeholder="$t('pages.current_experience.form.experience.fields.title.placeholder')"
          :maxlength="mediumTextLength"
          :error="titleError" />
        <div class="grid--two-columns">
          <AppInputText
            v-model="company"
            data-cy="company"
            :label="$t('pages.current_experience.form.experience.fields.company.label')"
            :placeholder="$t('pages.current_experience.form.experience.fields.company.placeholder')"
            :maxlength="mediumTextLength"
            :error="companyError" />
          <AppInputText
            v-model="location"
            data-cy="location"
            :label="$t('pages.current_experience.form.experience.fields.location.label')"
            :placeholder="$t('pages.current_experience.form.experience.fields.location.placeholder')"
            :maxlength="mediumTextLength"
            :error="locationError" />
        </div>
        <div class="grid--two-columns margin-b--xs">
          <AppInputDate
            v-model="fromDate"
            data-cy="fromDate"
            :label="$t('pages.current_experience.form.experience.fields.fromDate.label')"
            :error="fromDateError" />
          <AppInputDate
            v-if="jobExperienceType === JobExperienceType.JobExperience"
            v-model="toDate"
            data-cy="toDate"
            :label="$t('pages.current_experience.form.experience.fields.toDate.label')"
            :error="toDateError"
            :disabled="currentJob" />
        </div>

        <AppInputTextArea
          v-model="description"
          data-cy="description"
          :label="$t('pages.current_experience.form.experience.fields.description.label')"
          :maxlength="xLargeTextLength"
          :error="descriptionError" />
        <AppUploadDocument
          v-model="attachment"
          data-cy="attachment"
          :label="$t('shared.attachment.label')"
          :download-url="formMode === 'create' || input === undefined ? undefined : getJobExperienceAttachmentUrl(employeeId, input.id)"
          :name="input?.attachment"
          button-variant="secondary"
          :accept="allowedExtensions.join()"
          :maximum-size="maxFileSizeInMB"
          :error="attachmentError" />
      </AppForm>
    </AppModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useField, useForm } from "vee-validate";
import { object as yupObject, date as yupDate } from "yup";
import i18n from "@/i18n";
import AppModal from "@/components/modal/AppModal.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import { mediumTextLength, xLargeTextLength } from "@/constants/restrictions";
import { type JobExperience, JobExperienceType } from "@/models/jobExperience";
import useModal from "@/components/modal/useModal";
import type { FileAttachment } from "@/models/fileAttachment";
import { allowedExtensions, maxFileSizeInMB } from "@/utils/fileUtils";
import type { FormMode } from "@/models/formMode";
import UserInfoItem from "@/views/shared/current-experience/UserInfoItem.vue";
import useFormSchema from "@/services/useFormSchema";
import AppForm from "@/components/AppForm.vue";
import useFormValidator from "@/services/useFormValidator";
import useMeJobExperienceService from "@/services/useMeJobExperienceService";
import useEmployeeService from "@/services/useEmployeeService";
import AppLinkButton from "@/components/AppLinkButton.vue";

const { deleteJobExperience, updateJobExperience, createJobExperience, setCurrentJobExperience } = useMeJobExperienceService();
const { getJobExperienceAttachmentUrl } = useEmployeeService();
const { openTextModal, openComponentModal } = useModal();
const { stringSchema, inputRequiredText, attachmentSchema } = useFormSchema();
const { getFormCallbackHandler } = useFormValidator();

interface Props {
  employeeId: number;
  input?: JobExperience;
  formMode?: FormMode;
  jobExperienceType?: JobExperienceType;
  truncateText?: boolean;
}
const props = withDefaults(defineProps<Props>(), { formMode: "edit", jobExperienceType: JobExperienceType.JobExperience });

const emit = defineEmits<{
  (e: "deleted", item: JobExperience): void;
  (e: "created", item: JobExperience): void;
  (e: "updated", item: JobExperience): void;
}>();

const panelName = `JobExperience_${props.jobExperienceType}_${props.input?.id ?? "Create"}`;

const addCurrentJobText = computed(() => {
  const translateKeySuffix = props.jobExperienceType === JobExperienceType.CurrentJobExperience ? "create_current" : "create_other";
  const translateKey = `pages.current_experience.form.experience.${translateKeySuffix}`;
  return `+ ${i18n.global.t(translateKey)}`;
});

const titleRead = ref<string | undefined>(props.input?.title);
const descriptionRead = ref<string | undefined>(props.input?.description);
const companyRead = ref<string | undefined>(props.input?.company);
const locationRead = ref<string | undefined>(props.input?.location);
const fromDateRead = ref<Date | undefined>(props.input?.fromDate);
const toDateRead = ref<Date | undefined>(props.input?.toDate);
const attachmentRead = ref<FileAttachment | undefined>(props.input?.attachment ? { fileName: props.input.attachment.fileName } : undefined);
const currentJob = ref<boolean>(false);

const toDateSchema =
  props.jobExperienceType === JobExperienceType.CurrentJobExperience
    ? yupDate().optional()
    : yupDate()
        .required(inputRequiredText())
        .max(new Date(), i18n.global.t("inputs.date_less_than_or_equal_to_today"))
        .when("fromDate", ([fromDate], schema) => {
          if (fromDate) {
            const millisecondsInDay = 86400000;
            const dayAfter = new Date(fromDate.getTime() + millisecondsInDay);

            return schema.min(dayAfter, i18n.global.t("inputs.to_date_has_to_be_after_from_date"));
          }

          return schema;
        });

const resourceSchema = yupObject({
  title: stringSchema(),
  description: stringSchema({ maxLength: xLargeTextLength }),
  company: stringSchema(),
  location: stringSchema(),
  fromDate: yupDate().required(inputRequiredText()).max(new Date(), i18n.global.t("inputs.date_less_than_or_equal_to_today")),
  toDate: toDateSchema,
  attachment: attachmentSchema(allowedExtensions, maxFileSizeInMB),
});

const form = useForm({ validationSchema: resourceSchema });
const { value: title, errorMessage: titleError } = useField<string>("title");
const { value: description, errorMessage: descriptionError } = useField<string>("description");
const { value: company, errorMessage: companyError } = useField<string>("company");
const { value: location, errorMessage: locationError } = useField<string>("location");
const { value: fromDate, errorMessage: fromDateError } = useField<Date>("fromDate");
const { value: toDate, errorMessage: toDateError } = useField<Date | undefined>("toDate");
const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");

const parseJobSubtitle = (company?: string, location?: string, fromDate?: Date, toDate?: Date): string => {
  const dates = `${fromDate?.getFullYear()} - ${
    toDate === undefined ? i18n.global.t("pages.current_experience.present") : toDate.getFullYear()
  }`;

  return (location && `${company} | ${location} | ${dates}`) || `${company} | ${dates}`;
};

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (props.input === undefined) {
    return;
  }

  title.value = props.input.title;
  description.value = props.input.description;
  company.value = props.input.company;
  location.value = props.input.location;
  fromDate.value = props.input.fromDate;
  toDate.value = props.input.toDate;
  attachment.value = props.input.attachment ? { fileName: props.input.attachment.fileName } : undefined;
  currentJob.value = props.formMode === "create" ? false : !props.input.toDate;
};

const save = async () => {
  const jobExperience: JobExperience = {
    id: props.input?.id ?? 0,
    title: title.value,
    description: description.value,
    company: company.value,
    location: location.value,
    fromDate: fromDate.value,
    toDate: toDate.value,
    attachment: attachment.value,
  };
  let result: JobExperience;

  if (props.jobExperienceType === JobExperienceType.CurrentJobExperience) {
    result = await setCurrentJobExperience(jobExperience);
  } else if (props.formMode === "create") {
    result = await createJobExperience(jobExperience);
  } else {
    result = await updateJobExperience(jobExperience);
  }

  titleRead.value = result.title;
  descriptionRead.value = result.description;
  companyRead.value = result.company;
  locationRead.value = result.location;
  fromDateRead.value = result.fromDate;
  toDateRead.value = result.toDate;
  attachmentRead.value = attachment.value;

  if (props.formMode === "create") {
    jobExperience.id = result.id;
    emit("created", jobExperience);
    form.resetForm();
  } else {
    emit("updated", jobExperience);
  }
};

const remove = (): void => {
  openTextModal({
    title: i18n.global.t("pages.current_experience.form.experience.delete.title"),
    contentText: i18n.global.t("pages.current_experience.form.experience.delete.content"),
    showCancelToClose: true,
    confirmCallback: async () => {
      if (props.input === undefined) {
        return false;
      }
      await deleteJobExperience(props.input.id);
      emit("deleted", props.input);
      return true;
    },
  });
};

const openModal = (componentId: string): void => {
  const title =
    props.jobExperienceType === JobExperienceType.CurrentJobExperience
      ? i18n.global.t(`pages.current_experience.form.experience.${props.formMode}_current`)
      : i18n.global.t(`pages.current_experience.form.experience.${props.formMode}_other`);

  openComponentModal(componentId, {
    title,
    showCancelToClose: true,
    confirmText: i18n.global.t("buttons.save"),
    variant: "floating",
    confirmCallback: getFormCallbackHandler(form, save),
    closeCallback: () => {
      if (props.formMode === "create") {
        form.resetForm();
      } else {
        setFormValues();
      }
    },
  });
};
</script>
<style scoped lang="scss">
.job-editor {
  display: flex;
  flex-direction: row;
  gap: var(--space-m);
}
</style>
