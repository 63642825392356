import useAuthenticationService from "@/services/useAuthenticationService";
import type { RouteLocationNormalized, Router } from "vue-router";
import { redirectToLogin } from "@/utils/redirectUtil";
import { UserRole } from "@/models/userRole";

export const authenticationGuard = (router: Router): void => {
  const { getUser } = useAuthenticationService();

  router.beforeResolve(async (to: RouteLocationNormalized) => {
    if (!to.meta.requiresAuth) {
      return true;
    }
    const user = await getUser();
    const isUserAuthenticated = !!user;

    if (!isUserAuthenticated) {
      redirectToLogin(to.fullPath);
      return false;
    }

    if (to.meta.requiresGroupManager && !user.isGroupManager) {
      return { name: "default" };
    }
    if (to.meta.requiresAdministrator && user.role !== UserRole.Administrator) {
      return { name: "accounts-admin-required" };
    }
    if (to.meta.requiresItManager && user.role !== UserRole.ITManager) {
      return { name: "accounts-admin-required" };
    }

    return true;
  });
};
