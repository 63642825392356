<template>
  <TransitionGroup name="fade">
    <div class="questions-overview questions-overview--with-kpis">
      <QuestionKpi v-for="kpi in kpis" :key="kpi.id" :kpi-info="kpi" @remove="removeKpi(kpi)" />
    </div>
  </TransitionGroup>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import QuestionKpi from "@/views/admin/ai-assistant/components/kpis/QuestionKpi.vue";
import type { QuestionKpiInfo } from "@/models/questionKpi";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";

const { getGroupKpis } = useCompanyEmployeeGroupsService();

interface Props {
  groupId: number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "removeGroupQuestionKpi", questionId: number) => void>();

const kpis = ref<QuestionKpiInfo[]>([]);

const loadKpis = async () => (kpis.value = await getGroupKpis(props.groupId));
const removeKpi = (kpiInfo: QuestionKpiInfo) => {
  removeKpiById(kpiInfo.id);
  emit("removeGroupQuestionKpi", kpiInfo.id);
};
const removeKpiById = (kpiId: number) => {
  const kpiIndex = kpis.value.findIndex((kpi) => kpi.id === kpiId);
  kpis.value.splice(kpiIndex, 1);
};

onMounted(loadKpis);
</script>

<style scoped lang="scss">
.questions-overview {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-m);
}
</style>
