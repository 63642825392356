<template>
  <label>
    <span v-if="label" class="text text--secondary">{{ label }}</span>
    <div class="input">
      <div v-for="(tag, index) in tags" :key="tag" class="input__done text--xs text--bold" :title="tag" @click="remove(index)">
        <span class="text--ellipsis text">{{ tag }}</span>
        <span class="close-button icon-close margin-l--xs" data-cy="remove-tag"></span>
      </div>
      <input
        v-model="newTag"
        v-app-focus
        type="text"
        class="input__new"
        :maxlength="maxTagLength"
        :readonly="readonly"
        :placeholder="$t('inputs.tags.placeholder')"
        @keypress="keypress" />
    </div>
    <AppInputError :error="error" />
  </label>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppInputError from "@/components/AppInputError.vue";

interface Props {
  modelValue?: string[];
  label?: string;
  maxTagLength?: number;
  error?: string;
  readonly?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
});

const tags = ref<string[]>([]);
const newTag = ref<string>("");

onMounted(async () => (tags.value = props.modelValue));

const emit = defineEmits<(e: "update:modelValue", value: string[]) => void>();

const keypress = (event: KeyboardEvent): void => {
  if (event.code === "Comma" || event.code === "Enter") {
    addTag(event);
  }
};
const addTag = (event: KeyboardEvent) => {
  event.preventDefault();
  const newTagValue = newTag.value.trim();
  if (newTagValue === "") {
    return;
  }
  tags.value.push(newTagValue);
  newTag.value = "";
  emit("update:modelValue", tags.value);
};

const remove = (index: number): void => {
  tags.value.splice(index, 1);
  emit("update:modelValue", tags.value);
};
</script>

<style scoped lang="scss">
.input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--size-xs);
  padding: 9px 9px;
  margin: 10px 0 4px 0;
  border: 1px solid var(--color-input-border);

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--color-input-border--focus);
  }

  &__done {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 12px;
    cursor: pointer;
    background: var(--color-tags-skills-background-primary);
    border-radius: 16px;
    .text {
      max-width: 15rem;
    }
  }

  &__new {
    flex-grow: 1;
    padding: var(--space-xxs);
    max-width: 100%;
    border: none;

    &:hover,
    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }
}
</style>
