<template>
  <AppBox :title="$t(`pages.admin.dashboard.welcome.shortcuts.${prefix}.title`)">
    <div class="box-content">
      <img :src="imageUrl" :alt="$t(`pages.admin.dashboard.welcome.shortcuts.${prefix}.title`)" />
      <div>
        <p class="text margin-b--m">
          {{ $t(`pages.admin.dashboard.welcome.shortcuts.${prefix}.content`) }}
        </p>
        <AppButton
          :data-cy="`shortcut-${prefix}`"
          :text="$t(`pages.admin.dashboard.welcome.shortcuts.${prefix}.button`)"
          size="m"
          @onclick="click" />
      </div>
    </div>
  </AppBox>
</template>

<script setup lang="ts">
import AppBox from "@/components/AppBox.vue";
import AppButton from "@/components/AppButton.vue";
import router from "@/router/router";

interface Props {
  prefix: string;
  route: string;
  imageUrl: string;
}
const props = defineProps<Props>();

const click = () => router.push({ name: props.route });
</script>

<style scoped lang="scss">
.box-content {
  display: flex;
  padding: var(--size-m);
  align-items: center;
  column-gap: var(--size-m);
}
</style>
