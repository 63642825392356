import type { CompanyGoal, CompanyGoalWithEmployees } from "@/models/companyGoal";
import {
  isBiggerThanToday,
  isEqualOrSmallerThanToday,
  compareDates,
  isEqualOrBiggerThanToday,
  isSmallerThanToday,
} from "@/utils/dateUtils";

export const goalsSorter = (a: CompanyGoal, b: CompanyGoal): number => {
  // 1. The goals that are in progress, the one that has the nearest end first
  if (isInProgress(a) && isInProgress(b)) {
    return 2 * compareDates(a.end, b.end, true);
  }
  if (isInProgress(a)) {
    return -1;
  }
  if (isInProgress(b)) {
    return 1;
  }
  // 2. The goals that are not started
  if (isNotStarted(a)) {
    return -1;
  }
  if (isNotStarted(b)) {
    return 1;
  }
  // 3. The goals that are finished, the one that has the nearest end first
  return compareDates(a.end, b.end, false);
};

export const hasResult = (goal: CompanyGoal): boolean => {
  return goal.isSuccessful !== undefined;
};

export const meetsVisibilityFilter = (goal: CompanyGoal, selectedOption: string | undefined): boolean => {
  if (selectedOption === "all") {
    return true;
  }

  if (selectedOption === "private") {
    return isPrivate(goal);
  }

  if (selectedOption === "public") {
    return isPublic(goal);
  }

  return false;
};

export const meetsApplicationPhaseFilter = (goal: CompanyGoal, selectedOption: string | undefined): boolean => {
  if (selectedOption === "all") {
    return true;
  }

  if (selectedOption === "open") {
    return isApplicationPhaseOpen(goal);
  }

  if (selectedOption === "closed") {
    return isApplicationPhaseClosed(goal);
  }

  return false;
};

export const meetsStatusFilter = (goal: CompanyGoal, selectedOption: string | undefined): boolean => {
  if (selectedOption === "all") {
    return true;
  }

  if (selectedOption === "draft") {
    return isDraft(goal);
  }

  if (selectedOption === "published") {
    return isPublished(goal);
  }

  if (selectedOption === "finished") {
    return isFinished(goal);
  }

  if (selectedOption === "launched") {
    return goal.isLaunched;
  }

  return false;
};

export const isPublished = (goal: CompanyGoal): boolean => {
  return goal.isPublished;
};

export const isFinished = (goal: CompanyGoal): boolean => {
  return goal.isLaunched && isSmallerThanToday(goal.end);
};

export const isNotStarted = (goal: CompanyGoal): boolean => {
  return !goal.isLaunched || (goal.isLaunched && isBiggerThanToday(goal.start));
};

export const isDraft = (goal: CompanyGoal): boolean => {
  return !goal.isPublished;
};

export const isApplicationPhaseOpen = (goal: CompanyGoal): boolean => {
  return goal.isPublished && goal.isPublic && goal.applicationClosing !== undefined && isBiggerThanToday(goal.applicationClosing);
};

export const isApplicationPhaseClosed = (goal: CompanyGoal): boolean => {
  return goal.isPublished && goal.isPublic && goal.applicationClosing !== undefined && !isBiggerThanToday(goal.applicationClosing);
};

export const isPublic = (goal: CompanyGoal): boolean => {
  return goal.isPublic;
};

export const isPrivate = (goal: CompanyGoal): boolean => {
  return !goal.isPublic;
};

export const isEditable = (goal: CompanyGoal): boolean => {
  return isBiggerThanToday(goal.end) || !goal.isLaunched;
};

export const isStartDateEditable = (goal: CompanyGoal): boolean => !goal.isLaunched || isBiggerThanToday(goal.start);

export const isApplicationClosingDateEditable = (goal: CompanyGoal): boolean =>
  goal.isPublic && (!goal.isLaunched || (goal.applicationClosing !== undefined && isBiggerThanToday(goal.applicationClosing)));

export const isDeletable = (goal: CompanyGoal): boolean => {
  return goal !== undefined;
};

export const isInProgress = (goal: CompanyGoal): boolean => {
  return goal.isLaunched && isEqualOrBiggerThanToday(goal.end) && isEqualOrSmallerThanToday(goal.start);
};

export const isSuccessful = (goal: CompanyGoal): boolean => {
  return isFinished(goal) && hasResult(goal) && goal.isSuccessful === true;
};

export const isFailure = (goal: CompanyGoal): boolean => {
  return isFinished(goal) && hasResult(goal) && goal.isSuccessful !== true;
};

export const canBeLaunched = (goal: CompanyGoal): boolean => {
  return goal.isPublished && !goal.isLaunched && (isPrivate(goal) || (isPublic(goal) && isApplicationPhaseClosed(goal)));
};

export const canAddEmployees = (goal: CompanyGoalWithEmployees): boolean => {
  if (isDraft(goal) || isFinished(goal) || isApplicationPhaseOpen(goal)) {
    return false;
  }
  return goal.numberOfPeopleNeeded > goal.assignedEmployees.length;
};

export const isTeamBuilt = (goal: CompanyGoalWithEmployees): boolean => {
  return goal.assignedEmployees.length === goal.numberOfPeopleNeeded;
};
