<template>
  <label class="app-checkbox">
    <input class="app-checkbox__input" type="checkbox" :checked="modelValue" @change="change" @focus="focus" @focusout="focusOut" />
    <span
      class="app-checkbox__box"
      :class="{ 'icon icon-check': modelValue, 'app-checkbox__box--checked': modelValue, 'app-checkbox__box--focus': hasFocus }">
    </span>
    <span v-if="text" class="app-checkbox__text text" :class="{ 'text--bold': highlighted }" :title="text">{{ text }}</span>
  </label>
</template>

<script setup lang="ts">
import { ref } from "vue";

interface Props {
  modelValue?: boolean;
  text?: string;
  highlighted?: boolean;
}
withDefaults(defineProps<Props>(), { modelValue: false, highlighted: false });

const hasFocus = ref<boolean>(false);

const emit = defineEmits<(e: "update:modelValue", checked: boolean) => void>();

const change = (event: Event): void => {
  const checkbox: HTMLInputElement = event.target as HTMLInputElement;
  emit("update:modelValue", checkbox.checked);
};

const focus = (): void => {
  hasFocus.value = true;
};

const focusOut = (): void => {
  hasFocus.value = false;
};
</script>

<style scoped lang="scss">
.app-checkbox {
  display: flex;
  align-items: center;

  &__input {
    display: none;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }

  &__box {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    overflow: hidden;

    background-color: var(--color-checkbox-background);
    border: 1px solid var(--color-checkbox-border);

    &--checked {
      font-size: 1.4rem;
      color: var(--color-checkbox--checked);
      background-color: var(--color-checkbox-background--checked);
    }
  }
}
</style>
