import { readonly, ref, type Ref } from "vue";
import type { SpaConfiguration } from "@/models/spaConfiguration";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/spa-configuration";
const { get, loading } = useHttpClient();

interface UseSpaConfigurationServiceReturn {
  getSpaConfiguration: () => Promise<Readonly<SpaConfiguration>>;
  getFeatureFlags: () => Readonly<Record<string, boolean> | undefined>;
  loading: Ref<boolean>;
}

const spaConfig: Ref<SpaConfiguration> = ref<SpaConfiguration>({});
export default (): UseSpaConfigurationServiceReturn => {
  const fetchConfiguration = async (): Promise<void> => {
    spaConfig.value = await get(BASE_URL);
  };

  const getSpaConfiguration = async (): Promise<Readonly<SpaConfiguration>> => {
    if (!spaConfig.value.instrumentationKey || !spaConfig.value.featureManagementOptions) {
      await fetchConfiguration();
    }
    return readonly(spaConfig.value);
  };

  const getFeatureFlags = (): Readonly<Record<string, boolean> | undefined> => spaConfig.value.featureManagementOptions;
  return { getSpaConfiguration, getFeatureFlags, loading };
};
