<template>
  <LayoutPage :title="$t('pages.admin.ai_assistant.title')">
    <AppGenericFilter :placeholder="$t('pages.admin.ai_assistant.questions.search')" class="margin-b--m" @filter-changed="filterChanged" />
    <AppPanel :padding-content="false" :padding-footer="false" :padding-header="false">
      <template #header>
        <div>
          <p class="text text--bold margin--s">{{ $t("pages.admin.ai_assistant.questions.title") }}</p>
          <div class="navigation">
            <AppTab :tabs="tabs" :tab-id="$t('pages.admin.dashboard.welcome')" />
            <div>
              <span v-if="questionStatus === QuestionStatus.Unpublished" class="text text--bold text--link" @click="addNewQuestion">
                <span class="icon icon-plus"></span>
                {{ $t("pages.admin.ai_assistant.questions.add_new_question") }}
              </span>
            </div>
            <AppButton
              v-if="questionStatus === QuestionStatus.Published"
              :text="$t('pages.admin.ai_assistant.questions.do_not_send_questions')"
              :disabled="selectedQuestionsCount === 0 || working"
              variant="secondary"
              size="m"
              data-cy="unpublish"
              @onclick="updateSelectedQuestionsStatus(QuestionStatus.Unpublished)" />
          </div>
        </div>
      </template>
      <template #default>
        <QuestionsTable
          :questions="questionsInView"
          :allow-select="true"
          :allow-sort="true"
          :allow-add-to-dashboard="questionStatus === QuestionStatus.Published"
          :question-status="questionStatus"
          :maximum-order="maximumOrder"
          :minimum-order="minimumOrder"
          :order="order"
          @question-ascended="questionAscendedHandler"
          @question-descended="questionDescendedHandler"
          @question-deleted="questionDeletedHandler"
          @question-edited="questionEditedHandler" />
      </template>
      <template #footer>
        <div class="assistant__footer">
          <AppButton
            v-if="questionStatus === QuestionStatus.Unpublished"
            :text="$t('pages.admin.ai_assistant.questions.send_questions')"
            :disabled="selectedQuestionsCount === 0 || working"
            variant="secondary"
            size="m"
            data-cy="publish"
            @onclick="updateSelectedQuestionsStatus(QuestionStatus.Published)" />
          <AppButton
            v-if="questionStatus === QuestionStatus.Published"
            :text="$t('pages.admin.ai_assistant.questions.do_not_send_questions')"
            :disabled="selectedQuestionsCount === 0 || working"
            variant="secondary"
            size="m"
            data-cy="unpublish"
            @onclick="updateSelectedQuestionsStatus(QuestionStatus.Unpublished)" />
        </div>
      </template>
    </AppPanel>
    <QuestionWizard v-if="creating" mode="create" @close="creating = false" @saved="questionAdded" />
    <AppModal name="question-preview">
      <QuestionPreview v-if="selectedQuestion" :item="selectedQuestion" />
    </AppModal>
  </LayoutPage>
</template>

<script setup lang="ts">
import AppButton from "@/components/AppButton.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppModal from "@/components/modal/AppModal.vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import type { Question, QuestionSelectable } from "@/models/question";
import { QuestionStatus } from "@/models/questionStatus";
import useQuestionService from "@/services/useQuestionService";
import QuestionPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionPreview.vue";
import QuestionWizard from "@/views/admin/ai-assistant/components/wizard/QuestionWizard.vue";
import { computed, onMounted, ref } from "vue";
import QuestionsTable from "@/views/admin/ai-assistant/components/grid/QuestionsTable.vue";
import type { Order } from "@/components/table/Order";
import useModal from "@/components/modal/useModal";
import i18n from "@/i18n";

const working = ref<boolean>(false);
const questionStatus = ref<QuestionStatus>(QuestionStatus.Unpublished);
const selectedAll = ref<boolean>(false);
const questionsInView = ref<QuestionSelectable[]>([]);
const selectedQuestion = ref<Question | undefined>();
const searchText = ref<string>("");
const minimumOrder = ref<number>(0);
const maximumOrder = ref<number>(0);
const questions = ref<Question[]>([]);
const creating = ref<boolean>(false);
const order = ref<Order>({ direction: "asc", id: 2 });
const { getPublishedQuestions, getUnpublishedQuestions, publishQuestions, unpublishQuestions } = useQuestionService();
const { openTextModal } = useModal();

const addNewQuestion = (): void => {
  creating.value = true;
};

const questionAdded = async (): Promise<void> => {
  creating.value = false;
  await loadQuestions();
};

const updateSelectedQuestionsStatus = async (newStatus: QuestionStatus): Promise<void> => {
  const publishing = newStatus === QuestionStatus.Published;
  const messageKey = publishing ? "send_questions" : "do_not_send_questions";

  openTextModal({
    title: i18n.global.t(`pages.admin.ai_assistant.questions.${messageKey}`),
    contentText: i18n.global.t("shared.are_you_sure"),
    icon: publishing ? undefined : { content: "warning", style: "error" },
    showCancelToClose: true,
    confirmCallback: async () => {
      working.value = true;
      try {
        await changePublishedStatusForSelectedQuestions(newStatus);
      } finally {
        working.value = false;
        selectedAll.value = false;
        await loadQuestions();
      }
    },
  });
};

const changePublishedStatusForSelectedQuestions = async (newStatus: QuestionStatus): Promise<void> => {
  const selectedQuestions = questionsInView.value.filter((question) => question.selected);
  const questionHandle = newStatus === QuestionStatus.Published ? publishQuestions : unpublishQuestions;
  await questionHandle(selectedQuestions);
};
const questionAscendedHandler = (question: Question): void => {
  const newOrder = (question.order ?? 0) - 1;
  const questionToSwap = questions.value.find((q) => q.order === newOrder);

  if (!questionToSwap) {
    return;
  }

  question.order = newOrder;
  questionToSwap.order = newOrder + 1;

  configureGrid();
};
const questionDescendedHandler = (question: Question): void => {
  const newOrder = (question.order ?? 0) + 1;
  const questionToSwap = questions.value.find((q) => q.order === newOrder);

  if (!questionToSwap) {
    return;
  }

  question.order = newOrder;
  questionToSwap.order = newOrder - 1;

  configureGrid();
};
const questionEditedHandler = (question: Question): void => {
  question.edited = new Date();
  questions.value = [...questions.value.filter((q) => q.id !== question.id), { ...question }];

  configureGrid();
};
const questionDeletedHandler = (question: Question): void => {
  questions.value = questions.value.filter((q) => q.id !== question.id);

  const nextQuestions = questions.value.filter((q) => q.order && question.order && q.order > question.order);

  for (const question of nextQuestions) {
    question.order = (question.order ?? 0) - 1;
  }

  configureGrid();
};

const selectedQuestionsCount = computed(() => questionsInView.value.filter((q) => q.selected).length);

const loadQuestions = async (): Promise<void> => {
  const caller = questionStatus.value === QuestionStatus.Published ? getPublishedQuestions : getUnpublishedQuestions;
  questions.value = await caller();
  configureGrid();
};

onMounted(loadQuestions);

const filterChanged = (newSearch: string) => {
  searchText.value = newSearch;
  configureGrid();
};

const changeQuestionStatus = async (newQuestionStatus: QuestionStatus): Promise<void> => {
  if (questionStatus.value === newQuestionStatus) {
    return;
  }
  questionStatus.value = newQuestionStatus;
  order.value = {
    direction: "asc",
    id: questionStatus.value === QuestionStatus.Published ? 1 : 2,
  };
  await loadQuestions();
};

const configureGrid = (): void => {
  minimumOrder.value = Math.min(...questions.value.map((q) => q.order ?? 0));
  maximumOrder.value = Math.max(...questions.value.map((q) => q.order ?? 0));
  questionsInView.value =
    questions.value
      .filter((q) => q.title.toLocaleUpperCase().indexOf(searchText.value.toLocaleUpperCase()) > -1)
      .map((q) => q as QuestionSelectable) || [];
};

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages.admin.ai_assistant.questions.created_questions"),
    action: () => changeQuestionStatus(QuestionStatus.Unpublished),
    dataCy: "created",
  },
  {
    text: i18n.global.t("pages.admin.ai_assistant.questions.sent_questions"),
    action: () => changeQuestionStatus(QuestionStatus.Published),
    dataCy: "sent",
  },
];
</script>

<style scoped lang="scss">
.navigation {
  padding: 0 var(--space-s) var(--space-s) var(--space-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.assistant__footer {
  padding: var(--space-m);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
