<template>
  <div class="education-editor">
    <UserInfoItem
      v-if="formMode === 'edit' || formMode === 'view'"
      data-cy="current-experience-education"
      :title="titleRead"
      :subtitle="parseEducationSubtitle(institutionRead, locationRead, fromDateRead, toDateRead)"
      :content="descriptionRead"
      :attachment-url="attachmentRead && input ? getEducationAttachmentUrl(employeeId, input.id) : undefined"
      :truncate-text="truncateText" />

    <AppLinkButton v-if="formMode === 'create'" :text="` + ${$t('pages.current_experience.form.education.create')}`" @click="openPanel()" />
    <div v-if="formMode === 'edit'" class="education-editor__actions">
      <AppLinkButton :text="$t('buttons.edit')" @click="openPanel()" />
      <AppLinkButton :bold="false" :text="$t('buttons.delete')" @click="remove()" />
    </div>

    <AppModal :name="panelName" :disable-key-enter="true">
      <AppForm>
        <AppInputText
          v-model="title"
          :label="$t('pages.current_experience.form.education.fields.title.label')"
          :placeholder="$t('pages.current_experience.form.education.fields.title.placeholder')"
          :maxlength="mediumTextLength"
          :error="nameError" />
        <AppInputText
          v-model="institution"
          :label="$t('pages.current_experience.form.education.fields.institution.label')"
          :placeholder="$t('pages.current_experience.form.education.fields.institution.placeholder')"
          :maxlength="mediumTextLength"
          :error="institutionError" />
        <AppEducationMode v-model="selectedModeOption" class="margin-b--s" in-row />
        <AppInputText
          v-if="selectedModeOption === EducationMode.Presence"
          v-model="location"
          :label="$t('pages.current_experience.form.education.fields.location.label')"
          :placeholder="$t('pages.current_experience.form.education.fields.location.placeholder')"
          :maxlength="mediumTextLength"
          :error="locationError" />
        <div class="grid grid--two-columns">
          <AppInputDate
            v-model="fromDate"
            :label="$t('pages.current_experience.form.education.fields.fromDate.label')"
            :error="fromDateError" />
          <AppInputDate
            v-model="toDate"
            :label="$t('pages.current_experience.form.education.fields.toDate.label')"
            :error="toDateError"
            :disabled="inProgressEducation" />
        </div>
        <AppCheckbox
          v-model="inProgressEducation"
          class="margin-b--m"
          :text="$t('pages.current_experience.form.education.fields.inProgressEducation.label')"
          @change="inProgressEducationHandler" />
        <AppInputTextArea
          v-model="description"
          :label="$t('pages.current_experience.form.education.fields.description.label')"
          :placeholder="$t('pages.current_experience.form.education.fields.description.placeholder')"
          :maxlength="xLargeTextLength"
          :error="descriptionError" />
        <AppUploadDocument
          v-model="attachment"
          :label="$t('shared.attachment.label')"
          :download-url="formMode === 'create' || input === undefined ? undefined : getEducationAttachmentUrl(employeeId, input.id)"
          :name="input?.attachment"
          :accept="allowedExtensions.join()"
          :error="attachmentError"
          :maximum-size="maxFileSizeInMB" />
      </AppForm>
    </AppModal>
  </div>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { ref, onMounted, watch } from "vue";
import { useField, useForm } from "vee-validate";
import { mixed as yupMixed, string as yupString, date as yupDate, boolean as yupBoolean } from "yup";
import AppCheckbox from "@/components/AppCheckbox.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppModal from "@/components/modal/AppModal.vue";
import AppEducationMode from "@/components/AppEducationMode.vue";
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import AppForm from "@/components/AppForm.vue";
import UserInfoItem from "@/views/shared/current-experience/UserInfoItem.vue";
import type { Education } from "@/models/education";
import type { FileAttachment } from "@/models/fileAttachment";
import type { FormMode } from "@/models/formMode";
import useModal from "@/components/modal/useModal";
import { allowedExtensions, maxFileSizeInMB } from "@/utils/fileUtils";
import { EducationMode } from "@/models/educationMode";
import { mediumTextLength, xLargeTextLength } from "@/constants/restrictions";
import useFormSchema from "@/services/useFormSchema";
import useFormValidator from "@/services/useFormValidator";
import useMeEducationService from "@/services/useMeEducationService";
import useEmployeeService from "@/services/useEmployeeService";

const { createEducation, updateEducation, deleteEducation } = useMeEducationService();
const { getEducationAttachmentUrl } = useEmployeeService();
const { openTextModal, openComponentModal } = useModal();
const { yupObject, stringSchema, attachmentSchema, inputRequiredText } = useFormSchema();
const { getFormCallbackHandler } = useFormValidator();

interface Props {
  employeeId: number;
  input?: Education;
  formMode?: FormMode;
  truncateText?: boolean;
}
const props = withDefaults(defineProps<Props>(), { formMode: "edit" });

const emit = defineEmits<{
  (e: "deleted", item: Education): void;
  (e: "created", item: Education): void;
  (e: "updated", item: Education): void;
}>();

const panelName = "Education" + (props.input?.id ?? "Create");

const titleRead = ref<string | undefined>(props.input?.title);
const institutionRead = ref<string | undefined>(props.input?.institution);
const descriptionRead = ref<string | undefined>(props.input?.description);
const locationRead = ref<string | undefined>(props.input?.location);
const fromDateRead = ref<Date | undefined>(props.input?.fromDate);
const toDateRead = ref<Date | undefined>(props.input?.toDate);
const attachmentRead = ref<FileAttachment | undefined>(props.input?.attachment ? { fileName: props.input.attachment.fileName } : undefined);

const resourceSchema = yupObject({
  title: stringSchema(),
  institution: stringSchema(),
  selectedModeOption: yupMixed<EducationMode>().oneOf(Object.values(EducationMode)),
  description: yupString().optional().max(xLargeTextLength),
  location: yupString()
    .max(mediumTextLength)
    .when("selectedModeOption", {
      is: EducationMode.Presence,
      then: (schema) => schema.required(inputRequiredText()),
    }),
  fromDate: yupDate().required(inputRequiredText()).max(new Date(), i18n.global.t("inputs.date_less_than_or_equal_to_today")),
  toDate: yupDate()
    .optional()
    .max(new Date(), i18n.global.t("inputs.date_less_than_or_equal_to_today"))
    .when("fromDate", ([fromDate], schema) => {
      if (fromDate) {
        const millisecondsInDay = 86400000;
        const dayAfter = new Date(fromDate.getTime() + millisecondsInDay);
        return schema.min(dayAfter, i18n.global.t("inputs.to_date_has_to_be_after_from_date"));
      }

      return schema;
    }),
  attachment: attachmentSchema(allowedExtensions, maxFileSizeInMB),
  inProgressEducation: yupBoolean().optional(),
});

const form = useForm({ validationSchema: resourceSchema, initialValues: { selectedModeOption: EducationMode.Online } });
const { value: title, errorMessage: nameError } = useField<string>("title");
const { value: institution, errorMessage: institutionError } = useField<string>("institution");
const { value: description, errorMessage: descriptionError } = useField<string | undefined>("description");
const { value: location, errorMessage: locationError } = useField<string | undefined>("location");
const { value: fromDate, errorMessage: fromDateError } = useField<Date>("fromDate");
const { value: toDate, errorMessage: toDateError } = useField<Date | undefined>("toDate");
const { value: selectedModeOption } = useField<EducationMode>("selectedModeOption");
const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");
const { value: inProgressEducation } = useField<boolean>("inProgressEducation");

const parseEducationSubtitle = (institution?: string, location?: string, fromDate?: Date, toDate?: Date): string => {
  const fromDateFormatted = fromDate?.getFullYear();
  const toDateFormatted = toDate === undefined ? i18n.global.t("pages.current_experience.present") : toDate.getFullYear();
  return location !== undefined
    ? `${institution} | ${location} | ${fromDateFormatted} - ${toDateFormatted}`
    : `${institution} | ${fromDateFormatted} - ${toDateFormatted}`;
};

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (props.input === undefined) {
    return;
  }

  title.value = props.input.title;
  institution.value = props.input.institution;
  description.value = props.input.description;
  institution.value = props.input.institution;
  location.value = props.input.location;
  fromDate.value = props.input.fromDate;
  toDate.value = props.input.toDate;
  attachment.value = props.input.attachment ? { fileName: props.input.attachment.fileName } : undefined;
  inProgressEducation.value = props.formMode === "create" ? false : !props.input.toDate;
  selectedModeOption.value = props.input.mode;
};

const save = async () => {
  const education: Education = {
    id: props.input?.id ?? 0,
    title: title.value,
    institution: institution.value,
    description: description.value,
    location: location.value,
    fromDate: fromDate.value,
    toDate: toDate.value,
    mode: selectedModeOption.value,
    attachment: attachment.value,
  };

  const result = props.formMode === "create" ? await createEducation(education) : await updateEducation(education);

  titleRead.value = result.title;
  institutionRead.value = result.institution;
  descriptionRead.value = result.description;
  locationRead.value = result.location;
  fromDateRead.value = result.fromDate;
  toDateRead.value = result.toDate;
  attachmentRead.value = attachment.value;

  if (props.formMode === "create") {
    education.id = result.id;
    emit("created", education);
    form.resetForm();
  } else {
    emit("updated", education);
  }
};

const remove = (): void => {
  openTextModal({
    title: i18n.global.t("pages.current_experience.form.education.delete.title"),
    contentText: i18n.global.t("pages.current_experience.form.education.delete.content"),
    showCancelToClose: true,
    confirmCallback: async () => {
      if (props.input === undefined) {
        return false;
      }
      await deleteEducation(props.input.id);
      emit("deleted", props.input);
      return true;
    },
  });
};

const openPanel = (): void => {
  openComponentModal(panelName, {
    title: i18n.global.t(`pages.current_experience.form.education.${props.formMode}`),
    confirmText: i18n.global.t("buttons.save"),
    showCancelToClose: true,
    variant: "floating",
    confirmCallback: getFormCallbackHandler(form, save),
    closeCallback: () => {
      if (props.formMode === "create") {
        form.resetForm();
      } else {
        setFormValues();
      }
    },
  });
};

const inProgressEducationHandler = (event: Event): void => {
  const input: HTMLInputElement = event.target as HTMLInputElement;
  if (input.value) {
    toDate.value = undefined;
  }
};

watch([selectedModeOption], () => {
  if (selectedModeOption.value === EducationMode.Online) {
    location.value = undefined;
  }
});
</script>
<style scoped lang="scss">
.education-editor {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  &__actions {
    display: flex;
    flex-direction: row;
    gap: var(--space-m);
  }
}
</style>
