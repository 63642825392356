import type { JobExperience } from "@/models/jobExperience";
import type { SkillUpdateRequest } from "@/models/requests/skillUpdateRequest";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/me/job-experience";
const { put, post, httpDelete, loading } = useHttpClient();

interface UseMeJobExperienceServiceReturn {
  createJobExperience: (jobExperience: JobExperience) => Promise<JobExperience>;
  updateJobExperience: (jobExperience: JobExperience) => Promise<JobExperience>;
  deleteJobExperience: (jobExperienceId: number) => Promise<void>;
  setCurrentJobExperience: (jobExperience: JobExperience) => Promise<JobExperience>;
  updateCurrentJobExperienceSkills: (skillUpdateRequest: SkillUpdateRequest) => Promise<void>;
  loading: Ref<boolean>;
}
export default (): UseMeJobExperienceServiceReturn => {
  const createJobExperience = (jobExperience: JobExperience): Promise<JobExperience> => post(BASE_URL, jobExperience);
  const updateJobExperience = (jobExperience: JobExperience): Promise<JobExperience> =>
    put(`${BASE_URL}/${jobExperience.id}`, jobExperience);
  const deleteJobExperience = (jobExperienceId: number): Promise<void> => httpDelete(`${BASE_URL}/${jobExperienceId}`);
  const setCurrentJobExperience = (jobExperience: JobExperience): Promise<JobExperience> => post(`${BASE_URL}/current`, jobExperience);
  const updateCurrentJobExperienceSkills = (skillUpdateRequest: SkillUpdateRequest): Promise<void> =>
    put(`${BASE_URL}/current/skills`, skillUpdateRequest);

  return {
    createJobExperience,
    deleteJobExperience,
    setCurrentJobExperience,
    updateCurrentJobExperienceSkills,
    updateJobExperience,
    loading,
  };
};
