<template>
  <GuestPage>
    <div class="tenant-unauthorized">
      <AppPanel>
        <div class="panel">
          <div><img class="panel__image" src="@/assets/images/logo.svg" alt="" /></div>
          <div>
            <h1 class="text text--xxl text--bold" data-cy="title">{{ $t("pages.accounts.tenant_unauthorized.title") }}</h1>
            <p class="margin-v--m">{{ $t("pages.accounts.tenant_unauthorized.description") }}</p>
          </div>
          <div class="panel__actions">
            <AppButton :text="$t('pages.accounts.tenant_unauthorized.continue')" data-cy="goToFleebe" @onclick="goBackToFleebe()" />
          </div>
        </div>
      </AppPanel>
    </div>
  </GuestPage>
</template>

<script setup lang="ts">
import AppPanel from "@/components/AppPanel.vue";
import AppButton from "@/components/AppButton.vue";
import GuestPage from "@/layout/guest/GuestPage.vue";
import router from "@/router/router";

const goBackToFleebe = () => router.push({ name: "default" });
</script>

<style scoped lang="scss">
.tenant-unauthorized {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.panel {
  display: grid;
  grid-column-gap: var(--space-l);
  grid-template-columns: 250px 1fr 200px;

  &__image {
    width: 250px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
