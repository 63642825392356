<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :disable-key-enter="true">
    <div class="wizard" data-cy="wizard">
      <GoalWizardPath :selected-step="currentStep" @step-click="stepClick" />
      <div v-if="currentStep === 1" data-cy="step-1">
        <AppForm class="margin-t--m">
          <AppPanel v-if="isOpen || isOpen === undefined" :dark="true">
            <AppToggle v-model="isPublic" :text="$t('pages.admin.company_goals.add_new_goal.fields.is_open.label')" data-cy="isPublic" />
          </AppPanel>
          <div class="margin-v--m">
            <AppUploadDocument
              v-model="picture"
              :label="$t('shared.attachment.label')"
              :download-url="goal === undefined ? undefined : getGoalPictureUrl(goal.id)"
              :name="picture?.fileName"
              :accept="allowedImageExtensions.join()"
              :maximum-size="maxFileSizeInMB"
              :error="pictureError"
              button-variant="secondary"
              data-cy="picture" />
          </div>

          <AppInputText
            v-model="title"
            :label="$t('pages.admin.company_goals.add_new_goal.fields.title.label')"
            :placeholder="$t('pages.admin.company_goals.add_new_goal.fields.title.placeholder')"
            :maxlength="4000"
            :error="titleError"
            data-cy="title" />
          <AppInputTextArea
            v-model="description"
            :label="$t('pages.admin.company_goals.add_new_goal.fields.description.label')"
            :placeholder="$t('pages.admin.company_goals.add_new_goal.fields.description.placeholder')"
            :maxlength="2000"
            :rows="6"
            :error="descriptionError"
            data-cy="description" />
          <AppInputText
            v-model="link"
            :label="$t('pages.admin.company_goals.add_new_goal.fields.link.label')"
            :placeholder="$t('pages.admin.company_goals.add_new_goal.fields.link.placeholder')"
            :maxlength="200"
            :error="linkError"
            data-cy="link" />
          <div class="grid grid--two-columns">
            <AppInputNumber
              v-model="numberOfPeopleNeeded"
              :label="$t('pages.admin.company_goals.add_new_goal.fields.number_of_people_needed.label')"
              :error="numberOfPeopleNeededError"
              data-cy="numberOfPeopleNeeded" />
            <AppInputDate
              v-if="isPublic"
              v-model="applicationClosing"
              :label="$t('pages.admin.company_goals.add_new_goal.fields.application_closing.label')"
              :error="applicationClosingError"
              :min-date="new Date()"
              data-cy="applicationClosing" />
          </div>
          <div class="grid grid--two-columns">
            <AppInputText
              v-model="country"
              :label="$t('pages.admin.company_goals.add_new_goal.fields.country.label')"
              :placeholder="$t('pages.admin.company_goals.add_new_goal.fields.country.placeholder')"
              :maxlength="200"
              :error="countryError"
              data-cy="country" />
            <AppInputText
              v-model="city"
              :label="$t('pages.admin.company_goals.add_new_goal.fields.city.label')"
              :placeholder="$t('pages.admin.company_goals.add_new_goal.fields.city.placeholder')"
              :maxlength="200"
              :error="cityError"
              data-cy="city" />
          </div>
          <div class="grid grid--two-columns">
            <AppInputDate
              v-model="start"
              :label="$t('pages.admin.company_goals.add_new_goal.fields.start.label')"
              :error="startError"
              :min-date="new Date()"
              data-cy="start" />
            <AppInputDate
              v-model="end"
              :label="$t('pages.admin.company_goals.add_new_goal.fields.end.label')"
              :error="endError"
              :min-date="new Date()"
              data-cy="end" />
          </div>
        </AppForm>
      </div>
      <div v-else-if="currentStep === 2" data-cy="step-2">
        <p class="margin-v--m">{{ $t("pages.admin.company_goals.add_new_goal.path.general_skills.description") }}</p>
        <AppSkillEditor
          :input="skillsGeneralNeeded"
          :skills-type="SkillType.General"
          data-cy="skillsGeneralNeeded"
          @updated="skillsGeneralNeededUpdated" />
      </div>
      <div v-else-if="currentStep === 3" data-cy="step-3">
        <p class="margin-v--m">{{ $t("pages.admin.company_goals.add_new_goal.path.language_skills.description") }}</p>
        <AppSkillEditor
          :input="skillsLanguageNeeded"
          :skills-type="SkillType.Language"
          data-cy="skillsLanguageNeeded"
          @updated="skillsLanguageNeededUpdated" />
      </div>
    </div>
    <template #actions>
      <div class="wizard__actions">
        <div>
          <AppButton
            :text="$t('buttons.previous')"
            variant="secondary"
            size="m"
            :disabled="currentStep === 1"
            data-cy="prev"
            @onclick="prev()" />
        </div>
        <div>
          <AppButton
            v-if="currentStep !== lastStep"
            :text="$t('buttons.next')"
            variant="secondary"
            size="m"
            data-cy="next"
            @onclick="next()" />
          <AppButton
            v-if="currentStep === lastStep"
            :text="$t('buttons.save_draft')"
            variant="secondary"
            size="m"
            data-cy="draft"
            @onclick="saveAsDraft()" />
          <AppButton
            v-if="currentStep === lastStep"
            :text="$t('buttons.publish')"
            class="margin-l--m"
            variant="primary"
            size="m"
            data-cy="save"
            @onclick="publish()" />
        </div>
      </div>
    </template>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { object as yupObject, date as yupDate, number as yupNumber, bool as yupBool } from "yup";
import { allowedImageExtensions, maxFileSizeInMB } from "@/utils/fileUtils";
import { convertDateOnlyToLocale } from "@/utils/dateUtils";
import { largeTextLength, mediumTextLength } from "@/constants/restrictions";
import { onMounted, ref } from "vue";
import { SkillType } from "@/models/skillType";
import { useField, useForm } from "vee-validate";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppButton from "@/components/AppButton.vue";
import AppForm from "@/components/AppForm.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppInputNumber from "@/components/AppInputNumber.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppSkillEditor from "@/components/AppSkillEditor.vue";
import AppToggle from "@/components/AppToggle.vue";
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import GoalWizardPath from "@/views/admin/company-goals/components/wizard/GoalWizardPath.vue";
import type { CompanyGoal } from "@/models/companyGoal";
import type { FileAttachment } from "@/models/fileAttachment";
import type { ModalBase } from "@/components/modal/models";
import type { SkillWithProgression } from "@/models/skillWithProgression";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";
import useFormSchema from "@/services/useFormSchema";
import useModal from "@/components/modal/useModal";
import useToaster from "@/components/toaster/useToaster";
import useFormValidator from "@/services/useFormValidator";

const { openTextModal } = useModal();
const { createCompanyGoal, updateCompanyGoal, getGoalPictureUrl } = useCompanyGoalsService();
const { openToaster } = useToaster();
const { startDateMinTodaySchema, endDateSchema, attachmentSchema, inputRequiredText, stringSchema, stringUrlSchema } = useFormSchema();
const { getObjectFromForm } = useFormValidator();

interface Props {
  goal?: CompanyGoal;
  isOpen?: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: "close"): void;
  (e: "goal-created", goal: CompanyGoal): void;
  (e: "goal-edited", goal: CompanyGoal): void;
}
const emit = defineEmits<Emits>();

const lastStep = 3;
const currentStep = ref<number>(1);
const skillsGeneralNeeded = ref<SkillWithProgression[]>([]);
const skillsLanguageNeeded = ref<SkillWithProgression[]>([]);
const applicationClosingSchemaNonPublic = startDateMinTodaySchema({ dateSet: props.goal?.applicationClosing });

const applicationClosingSchema = yupDate()
  .when("isPublic", ([isPublic], schema) => (!isPublic ? schema : applicationClosingSchemaNonPublic))
  .test({
    name: "max",
    exclusive: false,
    params: {},
    message: i18n.global.t("inputs.a_date_has_to_be_after_another_date", {
      first: i18n.global.t("pages.admin.company_goals.add_new_goal.fields.start.label"),
      second: i18n.global.t("pages.admin.company_goals.add_new_goal.fields.application_closing.label"),
    }),
    test: (value, context) => value === undefined || value < context.parent.start,
  });

const editionSchema = yupObject({
  id: yupNumber(),
  isPublic: yupBool().required().default(false),
  title: stringSchema({ required: true, maxLength: mediumTextLength }),
  description: stringSchema({ required: true, maxLength: largeTextLength }).min(1, inputRequiredText()),
  country: stringSchema({ required: true, maxLength: mediumTextLength }),
  city: stringSchema({ required: true, maxLength: mediumTextLength }),
  link: stringUrlSchema({ required: false }),
  numberOfPeopleNeeded: yupNumber()
    .required(inputRequiredText())
    .min(1, i18n.global.t("inputs.min_required", { minimum: 1 }))
    .max(6, i18n.global.t("inputs.max_required", { maximum: 6 })),
  applicationClosing: applicationClosingSchema,
  start: startDateMinTodaySchema({ dateSet: props.goal?.start }),
  end: endDateSchema({ startDateFieldName: "start", canBeEqualToStartDate: false }),
  picture: attachmentSchema(allowedImageExtensions, maxFileSizeInMB),
});

const form = useForm({ validationSchema: editionSchema });
const { value: isPublic } = useField<boolean>("isPublic");
isPublic.value = props.isOpen ?? false;
const { value: title, errorMessage: titleError } = useField<string>("title");
const { value: description, errorMessage: descriptionError } = useField<string>("description");
const { value: numberOfPeopleNeeded, errorMessage: numberOfPeopleNeededError } = useField<number>("numberOfPeopleNeeded");
const { value: country, errorMessage: countryError } = useField<string>("country");
const { value: city, errorMessage: cityError } = useField<string>("city");
const { value: link, errorMessage: linkError } = useField<string | undefined>("link");
const { value: applicationClosing, errorMessage: applicationClosingError } = useField<Date | undefined>("applicationClosing");
const { value: start, errorMessage: startError } = useField<Date>("start");
const { value: end, errorMessage: endError } = useField<Date>("end");
const { value: picture, errorMessage: pictureError } = useField<FileAttachment | undefined>("picture");

onMounted(() => {
  if (props.goal === undefined) {
    return;
  }

  isPublic.value = props.goal.isPublic;
  title.value = props.goal.title;
  description.value = props.goal.description;
  numberOfPeopleNeeded.value = props.goal.numberOfPeopleNeeded;
  country.value = props.goal.country;
  city.value = props.goal.city;
  link.value = props.goal.link;
  applicationClosing.value = props.goal.applicationClosing ? convertDateOnlyToLocale(props.goal.applicationClosing) : undefined;
  start.value = convertDateOnlyToLocale(props.goal.start);
  end.value = convertDateOnlyToLocale(props.goal.end);
  picture.value = props.goal.picture ? { fileName: props.goal.picture.fileName } : undefined;
  skillsGeneralNeeded.value = props.goal.skillsNeeded.filter((s) => s.type === SkillType.General);
  skillsLanguageNeeded.value = props.goal.skillsNeeded.filter((s) => s.type === SkillType.Language);
});

const modalConfig: ModalBase = {
  title: props.goal ? title : i18n.global.t("pages.admin.company_goals.add_new_goal.title"),
  variant: "large",
  closeCallback: () => emit("close"),
};

const skillsGeneralNeededUpdated = (updatedSkills: SkillWithProgression[] | undefined): void => {
  skillsGeneralNeeded.value = updatedSkills ?? [];
};
const skillsLanguageNeededUpdated = (updatedSkills: SkillWithProgression[] | undefined): void => {
  skillsLanguageNeeded.value = updatedSkills ?? [];
};

const next = async () => {
  if (currentStep.value === 1) {
    await form.validate();
  }
  if (currentStep.value < lastStep) {
    currentStep.value++;
  }
};
const prev = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};
const stepClick = (step: number) => (currentStep.value = step);
const saveAsDraft = () => save(false);

const publish = () => {
  openTextModal({
    title: i18n.global.t("pages.admin.company_goals.add_new_goal.confirm_publish.title"),
    contentText: i18n.global.t("pages.admin.company_goals.add_new_goal.confirm_publish.description"),
    showCancelToClose: true,
    confirmCallback: async () => {
      await save(true);
    },
  });
};
const save = async (publish: boolean): Promise<boolean> => {
  const validationResult = await form.validate();
  if (!validationResult.valid) {
    currentStep.value = 1;
    return false;
  }

  const goalFormForm = getObjectFromForm<CompanyGoal>(form);
  const goal: CompanyGoal = {
    ...goalFormForm,
    id: props.goal?.id ?? 0,
    isPublished: publish,
    isLaunched: false,
    applicationClosing: isPublic.value ? applicationClosing.value : undefined,
    skillsNeeded: [...skillsLanguageNeeded.value, ...skillsGeneralNeeded.value],
    created: new Date(),
    edited: new Date(),
  } as CompanyGoal;

  if (props.goal) {
    await updateCompanyGoal(goal);
    openToaster("success", i18n.global.t("pages.admin.company_goals.add_new_goal.successfully_updated"));
    emit("goal-edited", goal);
  } else {
    goal.id = await createCompanyGoal(goal);
    openToaster("success", i18n.global.t("pages.admin.company_goals.add_new_goal.successfully_created"));
    emit("goal-created", goal);
  }
  form.resetForm();

  emit("close");

  return true;
};
</script>

<style scoped lang="scss">
.wizard {
  &__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
