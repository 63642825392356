<template>
  <img
    :key="imageKey"
    class="course-image"
    :class="{ 'course-image--list': list }"
    :src="imageSrc"
    :alt="course.title"
    :title="course.title" />
</template>

<script lang="ts" setup>
import type { CourseBase } from "@/models/course";
import useCompanyCoursesService from "@/services/useCompanyCoursesService";
import { imageDefaultForCourses } from "@/constants/urls";
import { computed } from "vue";

const { getLearningImageCoverUrl } = useCompanyCoursesService();

interface Props {
  course: CourseBase;
  list?: boolean;
}

const props = withDefaults(defineProps<Props>(), { list: false });

const imageKey = computed<string>(
  () => `${props.course.id}-${props.course.companyCourseInformation?.imageCoverName ?? props.course.imageUrl ?? imageDefaultForCourses}`
);

const imageSrc = computed<string>(() => {
  if (props.course.imageUrl) {
    return props.course.imageUrl;
  }
  if (props.course.companyCourseInformation?.imageCoverName) {
    return getLearningImageCoverUrl(props.course.id, imageKey.value);
  }

  return imageDefaultForCourses;
});
</script>

<style lang="scss" scoped>
.course-image {
  object-fit: cover;
  height: 25rem;
  width: 100%;

  &--list {
    height: 100%;
    width: unset;
    max-width: 28rem;
    border-radius: 6px 0 0 6px;
  }
}
</style>
