<template>
  <div class="carousel__navigation">
    <span
      class="carousel__navigation__action icon icon-arrow-forward"
      :class="{ 'carousel__navigation__action--disabled': currentPage >= pageCount }"
      data-cy="next"
      @click="nextPage"></span>
    <span
      class="carousel__navigation__action icon icon-arrow-back"
      :class="{ 'carousel__navigation__action--disabled': currentPage === 1 }"
      data-cy="previous"
      @click="previousPage"></span>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  currentPage: number;
  pageCount: number;
}
defineProps<Props>();

const emit = defineEmits<{
  (e: "nextPage"): void;
  (e: "previousPage"): void;
}>();

const nextPage = () => emit("nextPage");
const previousPage = () => emit("previousPage");
</script>
<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.carousel__navigation {
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: var(--size-l);

  &__action {
    border-radius: 50%;
    height: var(--size-xl);
    width: var(--size-xl);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--size-m);
    cursor: pointer;

    color: var(--color-button-secondary-text);
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid var(--color-button-secondary-text);

    &:hover {
      background-color: var(--color-button-secondary--hover);
    }

    &:focus-visible,
    &:active {
      background-color: var(--color-button-secondary--pressed);
    }

    &--disabled {
      color: var(--color-button-secondary-text--disabled);
      border-color: var(--color-button-secondary-text--disabled);

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  @media only screen and (max-width: $medium-screen-breakpoint) {
    flex-direction: row-reverse;
    align-items: unset;
    justify-content: space-between;
    width: 100%;
    margin-top: calc(var(--size-l) * -1);
  }
}
</style>
