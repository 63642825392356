<template>
  <LayoutPage :title="name || group?.name || $t('pages.admin.company_settings.employee_groups.editor.new_group')">
    <Teleport v-if="loaded" to="#header-button-container">
      <AppIconButton :text="$t('shared.back')" size="l" icon="arrow-back" @onclick="backToList" />
    </Teleport>
    <ImportCompanyEmployeeGroupFromExcel
      :employees="employees"
      data-cy="import-company-employee-group-from-excel"
      @employees-selected="handleEmployeesSelectedFromExcel" />
    <div class="company-employee-group-editor" data-cy="company-employee-group-editor">
      <div class="company-employee-group-editor__header margin-b--m">
        <AppGenericFilter
          :placeholder="$t('pages.admin.company_settings.employee_groups.editor.search_placeholder')"
          @filter-changed="(newSearch) => (searchText = newSearch)" />
      </div>
      <div class="company-employee-group-editor__content">
        <div class="company-employee-group-editor__employee-list">
          <CompanyEmployeeGroupEditorEmployeesTable
            :employees="employees"
            :search-text="searchText"
            @select-item="selectItem"
            @select-all="selectAll" />
        </div>
        <div class="company-employee-group-editor__form">
          <AppForm>
            <AppInputText
              v-model="name"
              :maxlength="mediumTextLength"
              :label="$t('pages.admin.company_settings.employee_groups.fields.name')"
              :error="nameError"
              data-cy="group-name" />
            <AppInputTextArea
              v-model="description"
              :label="$t('pages.admin.company_settings.employee_groups.fields.description')"
              :rows="2"
              :maxlength="largeTextLength"
              :error="descriptionError"
              data-cy="group-description" />
            <CompanyEmployeeGroupEditorCurrentEmployees
              :selected-employees="selectedEmployees"
              :employees-count="employees?.length || 0"
              @remove-employee="removeEmployee" />
            <div class="company-employee-group-editor__actions margin-t--s">
              <AppButton data-cy="cancel-group" :text="$t('buttons.cancel')" size="m" variant="secondary" @onclick="backToList" />
              <AppButton data-cy="save-group" :text="$t('buttons.save')" size="m" @onclick="saveAndGoBack" />
            </div>
          </AppForm>
        </div>
      </div>
    </div>
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useField, useForm } from "vee-validate";
import { useRoute } from "vue-router";
import AppForm from "@/components/AppForm.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppButton from "@/components/AppButton.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppIconButton from "@/components/AppIconButton.vue";
import CompanyEmployeeGroupEditorEmployeesTable from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeeGroupEditorEmployeesTable.vue";
import CompanyEmployeeGroupEditorCurrentEmployees from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeeGroupEditorCurrentEmployees.vue";
import { largeTextLength, mediumTextLength } from "@/constants/restrictions";
import type { CompanyEmployeeGroup } from "@/models/companyEmployeeGroup";
import type { EmployeeInformation } from "@/models/employee/employeeInformation";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import useFormSchema from "@/services/useFormSchema";
import useEmployeeService from "@/services/useEmployeeService";
import type { TableItemSelectable } from "@/components/table/TableItem";
import { backToList } from "@/views/admin/company-settings/company-employee-groups/utils/companyEmployeeGroupsUtils";
import ImportCompanyEmployeeGroupFromExcel from "@/views/admin/company-settings/company-employee-groups/components/ImportCompanyEmployeeGroupFromExcel.vue";

const route = useRoute();
const { stringSchema, yupObject } = useFormSchema();

const resourceSchema = yupObject({
  name: stringSchema(),
  description: stringSchema({ maxLength: largeTextLength, required: false }),
});

const groupId: number = parseInt(route.params.groupId as string) || 0;
const group = ref<CompanyEmployeeGroup>();
const employees = ref<EmployeeInformation[]>([]);
const loaded = ref<boolean>(false);
const employeeIds = ref<Set<number>>(new Set<number>());
const selectedEmployees = ref<EmployeeInformation[]>([]);
const searchText = ref<string>("");

const form = useForm({ validationSchema: resourceSchema });
const { value: name, errorMessage: nameError } = useField<string>("name");
const { value: description, errorMessage: descriptionError } = useField<string>("description");

const { getCompanyEmployeeGroup, createCompanyEmployeeGroup, updateCompanyEmployeeGroup } = useCompanyEmployeeGroupsService();
const { getEmployees } = useEmployeeService();

const saveAndGoBack = async () => {
  const validationResult = await form.validate();

  if (!validationResult.valid) {
    return;
  }

  await saveGroup();
  await backToList();
};
const saveGroup = (): Promise<void> => {
  const handler = groupId ? updateCompanyEmployeeGroup : createCompanyEmployeeGroup;
  const group = getUpdatedGroup();

  return handler(group);
};

const getUpdatedGroup = (): CompanyEmployeeGroup => {
  return {
    id: groupId,
    name: name.value,
    description: description.value,
    employeeIds: [...employeeIds.value],
    employeeCount: employeeIds.value.size,
    selected: false,
  };
};

const selectAll = (selected: boolean, filtered: boolean, filteredIds: number[]) => {
  if (filtered) {
    filteredIds.forEach((id) => (selected ? employeeIds.value.add(id) : employeeIds.value.delete(id)));
    return;
  }
  employeeIds.value.clear();
  if (selected) {
    employees.value.forEach(({ id }) => employeeIds.value.add(id));
  }
};

const selectItem = (item: TableItemSelectable) => {
  if (item.selected) {
    employeeIds.value.add(item.id);
  } else {
    employeeIds.value.delete(item.id);
  }
};

const removeEmployee = (item: TableItemSelectable) => {
  item.selected = false;
  selectItem(item);
};

watch(
  employeeIds.value,
  (value) => {
    selectedEmployees.value = employees.value.filter((employee) => value.has(employee.id));
  },
  { immediate: true }
);

const handleEmployeesSelectedFromExcel = (selectedEmployeeIds: Set<number>) => {
  employeeIds.value.clear();
  selectedEmployeeIds.forEach(employeeIds.value.add, employeeIds.value);
  selectedEmployees.value = employees.value.filter((employee) => employeeIds.value.has(employee.id));
  employees.value = employees.value.map((e) => ({ ...e, selected: employeeIds.value.has(e.id) }));
};

const fetchData = async () => {
  const tasks: Promise<unknown>[] = [getEmployees()];
  if (groupId) {
    tasks.push(getCompanyEmployeeGroup(groupId));
  }

  const [employeesResponse, groupResponse] = await Promise.all(tasks);
  if (groupResponse) {
    group.value = groupResponse as CompanyEmployeeGroup;
    group.value.employeeIds.forEach((id) => employeeIds.value.add(id));
  }

  employees.value = (employeesResponse as EmployeeInformation[]).map((employee) => {
    return {
      ...employee,
      selected: employeeIds.value.has(employee.id),
    };
  });
};

onMounted(async () => {
  await fetchData();
  setFormValues();
  loaded.value = true;
});

const setFormValues = (): void => {
  if (!group.value) {
    return;
  }
  name.value = group.value.name;
  description.value = group.value.description ?? "";
};
</script>

<style lang="scss" scoped>
.company-employee-group-editor {
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0;
    border-radius: var(--default-border-radius);
    background-color: var(--color-card);
    border: 1px solid var(--color-border);
  }

  &__form {
    padding: 32px;
    border-left: 1px solid var(--color-border);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--space-s);
  }
}
</style>
