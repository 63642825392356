<template>
  <AppPanel dark class="goals-result" :class="resultModifierClass">
    <div class="goals-result__content">
      <span class="goals-result__icon icon" :class="iconClass"></span>
      {{ text }}
    </div>
  </AppPanel>
</template>

<script setup lang="ts">
import { hasResult, isFailure, isSuccessful } from "@/services/modelServices/companyGoalService";
import AppPanel from "@/components/AppPanel.vue";
import type { CompanyGoal } from "@/models/companyGoal";
import { computed } from "vue";
import i18n from "@/i18n";

interface Props {
  goal: CompanyGoal;
}

const props = defineProps<Props>();

const resultModifierClass = computed(() => ({
  "goals-result--success": isSuccessful(props.goal),
  "goals-result--failure": isFailure(props.goal),
}));

const iconClass = computed(() => ({
  "icon-verified": !hasResult(props.goal),
  "icon-success": isSuccessful(props.goal),
  "icon-close-circle-outline": isFailure(props.goal),
}));

const text = computed(() => {
  if (isSuccessful(props.goal)) {
    return i18n.global.t("pages.admin.company_goals.goal_detail.result_succeeded");
  }

  if (isFailure(props.goal)) {
    return i18n.global.t("pages.admin.company_goals.goal_detail.result_failed");
  }

  return i18n.global.t("pages.admin.company_goals.goal_detail.result_not_set");
});
</script>
<style scoped lang="scss">
.goals-result {
  border: none;

  &--success {
    color: var(--color-neutral-100);
    background-color: var(--color-system-success);
  }

  &--failure {
    color: var(--color-neutral-100);
    background-color: var(--color-system-error);
  }

  &__content {
    align-items: center;
    display: flex;
    gap: var(--size-s);
    justify-content: flex-start;
  }

  &__icon {
    font-size: 1.8rem;
  }
}
</style>
