<template>
  <div class="certificate-reminder" data-cy="certificate-reminder">
    <span class="icon icon-verified text--xl"></span>
    <div class="certificate-reminder__texts">
      <div class="text text--l text--bold certificate-reminder__texts__header">
        {{ $t("pages.learning.trainingComplete") }}
      </div>
      <div class="text">
        {{ $t("pages.learning.certificateReminder") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.certificate-reminder {
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: var(--color-company-goals-start-background);
  padding: var(--space-m);
  column-gap: 1rem;
  border-radius: var(--default-border-radius);

  &__texts {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    &__header {
      color: var(--color-neutral-800);
    }
  }
}
</style>
