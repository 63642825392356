<template>
  <div class="hobbies-category">
    <span class="text text--l text--bold">{{ $t(`entities.my_interests.hobbies.category.${category.toLowerCase()}`) }}</span>
    <div class="hobbies-category__items">
      <div v-if="!hobbiesOrdered.length">
        <AppTag data-cy="no-hobbies-in-category" type="presence" :main-text="$t('entities.my_interests.hobbies.no_hobbies')" />
      </div>
      <AppTag
        v-for="hobby of hobbiesOrdered"
        v-else
        :key="hobby.name"
        class="margin-b--xs margin-r--xs"
        data-cy="hobby-in-category"
        :main-text="hobby.name"
        type="skill" />
    </div>
  </div>
</template>
<script setup lang="ts">
import AppTag from "@/components/AppTag.vue";
import type { Hobby } from "@/models/hobby";
import type { HobbyCategory } from "@/models/hobbyCategory";
import { computed } from "vue";

interface Props {
  category: HobbyCategory;
  hobbies: Hobby[];
}
const props = defineProps<Props>();
const hobbiesOrdered = computed<Hobby[]>(() => {
  return props.hobbies
    .filter((h) => h.category && h.category === props.category)
    .sort((h1: Hobby, h2: Hobby) => h1.name.localeCompare(h2.name));
});
</script>
<style lang="scss" scoped>
.hobbies-category {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
</style>
