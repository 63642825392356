<template>
  <div v-show="pages > 1" class="pagination-footer">
    <span
      class="pagination-footer__action icon icon-arrow-back"
      :class="{ 'pagination-footer__action--disabled': currentIndexPage === 0 }"
      data-cy="previous"
      @click="previousPage"></span>

    <div v-if="singleMode" class="pagination-footer__numbers">
      <PaginationPageNumbers :index-page="currentIndexPage" :render-numbers="pages" @number-click="numberClick" />
    </div>
    <div v-else class="pagination-footer__numbers">
      <PaginationPageNumbers :index-page="currentIndexPage" :render-numbers="begin" @number-click="numberClick" />
      <span>...</span>
      <PaginationPageNumbers v-if="middle.length > 0" :index-page="currentIndexPage" :render-numbers="middle" @number-click="numberClick" />
      <span v-if="middle.length > 0">...</span>
      <PaginationPageNumbers :index-page="currentIndexPage" :render-numbers="end" @number-click="numberClick" />
    </div>

    <span
      class="pagination-footer__action icon icon-arrow-forward"
      :class="{ 'pagination-footer__action--disabled': currentIndexPage >= pages - 1 }"
      data-cy="next"
      @click="nextPage"></span>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import PaginationPageNumbers from "@/components/paginator/PaginationPageNumbers.vue";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";

interface Props {
  numberOfItems: number;
  eventEmitter: UseEventEmitterServiceReturn;
  pageSize?: number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "pageClick", value: number) => void>();

const defaultPageSize = 10;
// display group number like <- 1 2 3 4 ... 5 6 7 ... 8 ->
// or like <- 1 2 3 ... 5 6 ... 8 ->
const displayGroupsOf = 3;
// less than this, paginator will be displayed in single mode like <- 1 2 3 4 ->
const defaultSingleLineSizePages = displayGroupsOf + 2;

const currentPageSize = props.pageSize ?? defaultPageSize;
const currentIndexPage = ref<number>(0);

const pages = computed(() => Math.ceil(props.numberOfItems / currentPageSize));
const singleMode = computed(() => pages.value < defaultSingleLineSizePages);

// groups of pages <- 1 2 3 4 ... 5 6 7 ... 8 ->
// groups of pages <- [begin] ... [middle] ... [end] ->
const begin = ref<number[]>([]);
const middle = ref<number[]>([]);
const end = ref<number[]>([]);

const nextPage = () => {
  if (currentIndexPage.value < pages.value - 1) {
    currentIndexPage.value += 1;
    emit("pageClick", currentIndexPage.value);
    updatePageNumbers();
  }
};
const previousPage = () => {
  if (currentIndexPage.value > 0) {
    currentIndexPage.value -= 1;
    emit("pageClick", currentIndexPage.value);
    updatePageNumbers();
  }
};
const numberClick = (pageNumber: number) => {
  if (pageNumber === currentIndexPage.value + 1) {
    return;
  }
  currentIndexPage.value = pageNumber - 1;
  emit("pageClick", currentIndexPage.value);
  updatePageNumbers();
};

const updatePageNumbers = () => {
  if (currentIndexPage.value < displayGroupsOf) {
    const group = Array.from({ length: displayGroupsOf + 1 }, (_x, i) => i + 1);
    begin.value = group;
    middle.value = [];
    end.value = [pages.value];
    return;
  }
  if (currentIndexPage.value + 1 > pages.value - displayGroupsOf) {
    const group = Array.from({ length: displayGroupsOf + 1 }, (_x, i) => i + pages.value - displayGroupsOf);
    begin.value = [1];
    middle.value = [];
    end.value = group;
    return;
  }
  const group = Array.from({ length: displayGroupsOf }, (_x, i) => i + currentIndexPage.value + 1);
  begin.value = [1];
  middle.value = group;
  end.value = [pages.value];
};

const sourceChanged = () => {
  currentIndexPage.value = 0;
  updatePageNumbers();
};

onMounted(() => {
  props.eventEmitter.on("sourceChanged", sourceChanged);
  updatePageNumbers();
});
</script>
<style scope lang="scss">
.pagination-footer {
  align-self: center;
  display: flex;
  align-items: center;
  row-gap: var(--size-l);

  &__action {
    border-radius: 50%;
    height: var(--size-xl);
    width: var(--size-xl);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--size-m);
    cursor: pointer;
    color: var(--color-button-secondary-text);
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid var(--color-button-secondary-text);

    &:focus-visible {
      background-color: var(color-button-secondary--pressed);
    }

    &:hover {
      background-color: var(--color-button-secondary--hover);
    }

    &:active {
      background-color: var(--color-button-secondary--pressed);
    }

    &--disabled {
      color: var(--color-button-secondary-text--disabled);
      border-color: var(--color-button-secondary-text--disabled);

      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  &__numbers {
    display: flex;
    flex-direction: row;
    padding: 0 var(--size-s) 0 var(--size-s);
    gap: var(--size-s);
  }
}
</style>
