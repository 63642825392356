<template>
  <div>
    <div class="answer__value">
      <AppTag v-if="question.value" type="value" :main-text="`${question.value} / 10`" :limited-size="true" />
    </div>
    <AIAssistantGenericTextAnswer :text="question.text" class="answer__text" />
    <AIAssistantAnswerActions v-if="answerEnabled" :question="question" :editor="AIAssistantAnswerLevelEditor" @edited="edited" />
  </div>
</template>

<script setup lang="ts">
import AppTag from "@/components/AppTag.vue";
import AIAssistantAnswerActions from "@/views/app/ai-assistant/components/AIAssistantAnswerActions.vue";
import AIAssistantAnswerLevelEditor from "@/views/app/ai-assistant/components/AIAssistantAnswerLevelEditor.vue";
import AIAssistantGenericTextAnswer from "@/views/app/ai-assistant/components/AIAssistantGenericTextAnswer.vue";
import type { Question } from "@/models/question";

interface Props {
  question: Question;
  answerEnabled: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const edited = (newQuestionState: Question): void => emit("edited", newQuestionState);
</script>
<style lang="scss" scoped>
.answer {
  &__value {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50px;
  }

  &__text {
    flex-grow: 1;
    flex-shrink: 0;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
