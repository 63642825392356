import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import { QuestionWithAnswers } from "@/models/questionAnswers";
import type { QuestionKpiInfo } from "@/models/questionKpi";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/questions";
const { get, post, put, httpDelete, loading } = useHttpClient();

interface UseQuestionServiceReturn {
  getPublishedQuestions: (numberOfItems?: number) => Promise<Question[]>;
  getUnpublishedQuestions: (numberOfItems?: number) => Promise<Question[]>;
  saveQuestionAnswer: (questionId: number, answer: Answer) => Promise<void>;
  createQuestion: (question: Question) => Promise<Question>;
  updateQuestion: (question: Question) => Promise<Question>;
  publishQuestions: (questions: Question[]) => Promise<void>;
  unpublishQuestions: (questions: Question[]) => Promise<void>;
  descendQuestionOrder: (question: Question) => Promise<void>;
  ascendQuestionOrder: (question: Question) => Promise<void>;
  deleteQuestion: (questionId: number) => Promise<void>;
  deleteQuestions: (questionIds: number[]) => Promise<void>;
  toggleQuestionInDashboard: (questionId: number, showInDashboard: boolean) => Promise<void>;
  getKpis: () => Promise<QuestionKpiInfo[]>;
  getAnswers: (questionId: number) => Promise<QuestionWithAnswers>;
  getQuestionKpi: (questionId: number) => Promise<QuestionKpiInfo>;
  loading: Ref<boolean>;
}

export default (): UseQuestionServiceReturn => {
  const getPublishedQuestions = (numberOfItems?: number): Promise<Question[]> => {
    const url = numberOfItems ? `${BASE_URL}/published?numberOfItems=${numberOfItems}` : `${BASE_URL}/published`;
    return get(url);
  };
  const getUnpublishedQuestions = (numberOfItems?: number): Promise<Question[]> => {
    const url = numberOfItems ? `${BASE_URL}/unpublished?numberOfItems=${numberOfItems}` : `${BASE_URL}/unpublished`;
    return get(url);
  };
  const getKpis = async (): Promise<QuestionKpiInfo[]> => get<QuestionKpiInfo[]>(`${BASE_URL}/kpis`);
  const updateQuestion = (question: Question): Promise<Question> => put(`${BASE_URL}/${question.id}`, question);
  const publishQuestions = (questions: Question[]): Promise<void> =>
    post(
      `${BASE_URL}/publish`,
      questions.map((q) => q.id)
    );
  const descendQuestionOrder = (question: Question): Promise<void> => post(`${BASE_URL}/${question.id}/descend`, question);
  const ascendQuestionOrder = (question: Question): Promise<void> => post(`${BASE_URL}/${question.id}/ascend`, question);
  const saveQuestionAnswer = (questionId: number, answer: Answer): Promise<void> => post(`${BASE_URL}/${questionId}/answers`, answer);
  const createQuestion = (question: Question): Promise<Question> => post(BASE_URL, question);
  const unpublishQuestions = (questions: Question[]): Promise<void> =>
    post(
      `${BASE_URL}/unpublish`,
      questions.map((q) => q.id)
    );
  const toggleQuestionInDashboard = (questionId: number, showInDashboard: boolean): Promise<void> =>
    post(`${BASE_URL}/${questionId}/dashboard/${showInDashboard}`);
  const deleteQuestion = (questionId: number): Promise<void> => httpDelete(`${BASE_URL}/${questionId}`);
  const deleteQuestions = (questionIds: number[]): Promise<void> => httpDelete(BASE_URL, questionIds);
  const getAnswers = (questionId: number): Promise<QuestionWithAnswers> => get(`${BASE_URL}/${questionId}/answers`);
  const getQuestionKpi = async (questionId: number): Promise<QuestionKpiInfo> => get<QuestionKpiInfo>(`${BASE_URL}/${questionId}/kpi`);

  return {
    getPublishedQuestions,
    getUnpublishedQuestions,
    saveQuestionAnswer,
    createQuestion,
    updateQuestion,
    publishQuestions,
    unpublishQuestions,
    descendQuestionOrder,
    ascendQuestionOrder,
    deleteQuestion,
    deleteQuestions,
    toggleQuestionInDashboard,
    getKpis,
    getAnswers,
    getQuestionKpi,
    loading,
  };
};
