import { onMounted, onUnmounted, watch, type ComputedRef } from "vue";

interface KeyBind {
  keyCode:
    | "Escape"
    | "Enter"
    | " "
    | "ArrowUp"
    | "ArrowDown"
    | "ArrowLeft"
    | "ArrowRight"
    | "Tab"
    | "Backspace"
    | "Delete"
    | "Home"
    | "End";
  preventDefault?: boolean;
  success: (() => unknown) | undefined;
}

interface KeypressOptions {
  keyEvent: "keydown" | "keypress" | "keyup";
  keyBinds: KeyBind[];
  isActive?: ComputedRef<boolean>;
}
const eventHandler = (keyBinds: KeyBind[]): ((event: KeyboardEvent) => boolean | null) => {
  return (event: KeyboardEvent) => {
    const keyBind = keyBinds.find((keyBind) => keyBind.keyCode === event.key);
    if (!keyBind) {
      return null;
    }
    if (keyBind.preventDefault) {
      event.preventDefault();
    }
    // SUCCESS -> the correct key was pressed if we got to this point
    if (keyBind.success) {
      keyBind.success();
    }
    return !keyBind.preventDefault;
  };
};

export const useKeyEvent = ({ keyEvent, keyBinds, isActive: isListenerActiveRef }: KeypressOptions): void => {
  let eventListener: (event: KeyboardEvent) => boolean | null;

  const addListener = () => {
    if (!eventListener) {
      eventListener = eventHandler(keyBinds);
    }
    window.addEventListener(keyEvent, eventListener);
  };

  const removeListener = () => {
    if (!eventListener) {
      return;
    }
    window.removeEventListener(keyEvent, eventListener);
  };

  if (isListenerActiveRef) {
    if (isListenerActiveRef.value) {
      addListener();
    }
    watch(isListenerActiveRef, (active) => (active ? addListener() : removeListener()));
  } else {
    onMounted(addListener);
  }

  onUnmounted(removeListener);
};
