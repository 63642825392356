<template>
  <AppInputGeneric :label="label" :error="error">
    <input v-model="value" type="number" class="input text" :min="min" :step="step" :disabled="disabled" />
  </AppInputGeneric>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppInputGeneric from "@/components/AppInputGeneric.vue";

interface Props {
  modelValue?: number;
  step?: number;
  min?: number;
  label?: string;
  disabled?: boolean;
  error?: string;
}
const props = withDefaults(defineProps<Props>(), {
  step: 1,
  disabled: false,
  error: undefined,
});

const emit = defineEmits<(e: "update:modelValue", value: number | undefined) => void>();

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", (val as unknown as string) === "" ? undefined : val),
});
</script>
