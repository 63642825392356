<template>
  <AppUploadDocument
    v-model="attachment"
    :maximum-size="fileSize1MB"
    :label="title"
    :accept="excelAllowedExtensions.join(', ')"
    :hide-file="true"
    :error="attachmentError"
    @update:model-value="attachmentChanged" />
</template>

<script lang="ts" setup>
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import type { FileAttachment } from "@/models/fileAttachment";
import { read, utils } from "xlsx";
import type { ParsedExcelRow } from "@/models/parsedExcelRow";
import { fileSize1MB, excelAllowedExtensions } from "@/utils/fileUtils";
import { useField, useForm } from "vee-validate";
import useFormSchema from "@/services/useFormSchema";

interface Props {
  title: string;
}
defineProps<Props>();

const emit = defineEmits<(e: "excelParsed", parsedExcel: ParsedExcelRow[]) => void>();

const { yupObject, attachmentSchema } = useFormSchema();

const resourceSchema = yupObject({
  attachment: attachmentSchema(excelAllowedExtensions, fileSize1MB),
});
const form = useForm({ validationSchema: resourceSchema });

const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");

const attachmentChanged = async () => {
  if (!attachment.value) {
    return;
  }
  const formValid = await form.validate();
  if (!formValid.valid) {
    return;
  }
  const wb = read(attachment.value?.fileBase64Contents, { type: "base64" });
  emit("excelParsed", utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { blankrows: false }));
};
</script>
