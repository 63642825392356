const ISODateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

const isISODateTimeString = (value: unknown) => !!value && typeof value === "string" && ISODateTimeFormat.test(value);
const parseISO = (date: string) => new Date(date);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const interceptIsoDateTimes = (body: any): void => {
  if (!body || typeof body !== "object") {
    return;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isISODateTimeString(value)) {
      body[key] = parseISO(value);
    }
    interceptIsoDateTimes(value);
  }
};
