<template>
  <LayoutPage :title="$t('pages.current_experience.title')">
    <AppTip unique-name="app-current-experience" :content="$t('pages.current_experience.tip.content')" />
    <div v-if="loaded && profileInfo">
      <h2 class="margin-b--m" data-cy="current-experience-title">
        {{ $t("pages.current_experience.about_me") }}
      </h2>
      <div class="grid--two-columns@medium">
        <AboutMeEditor :key="aboutMeKey" :input="profileInfo.aboutMe" truncate-text />
        <EmployeeCurriculumUploader :profile-info="profileInfo" @curriculum-set="curriculumSet" />
      </div>
      <AppSeparator class="margin-v--m" />
      <div class="current-job">
        <h2 class="margin-b--m">{{ $t("pages.current_experience.current_experience.title") }}</h2>
        <div class="grid--two-columns@medium">
          <div>
            <AppNoContentYet v-if="!currentJobExperience" class="margin-b--m" />
            <JobExperienceEditor
              v-if="currentJobExperience"
              :key="JSON.stringify(currentJobExperience)"
              :employee-id="profileInfo.id"
              form-mode="edit"
              :job-experience-type="JobExperienceType.CurrentJobExperience"
              :input="currentJobExperience"
              truncate-text
              @updated="currentJobExperienceUpdated" />
            <JobExperienceEditor
              v-else
              form-mode="create"
              :employee-id="profileInfo.id"
              :job-experience-type="JobExperienceType.CurrentJobExperience"
              :input="currentJobExperience"
              @created="currentJobExperienceUpdated" />
          </div>
          <div v-if="currentJobExperience">
            <h3 class="margin-b--m">{{ $t("pages.current_experience.current_experience.general_skills") }}</h3>
            <SkillEditor
              :input="currentJobExperienceGeneralSkills"
              :skills-type="SkillType.General"
              :skill-location="SkillLocationType.CurrentJob"
              @updated="currentJobExperienceGeneralSkillsUpdated" />
            <h3 class="margin-v--m">{{ $t("pages.current_experience.current_experience.language_skills") }}</h3>
            <SkillEditor
              :input="currentJobExperienceLanguageSkills"
              :skills-type="SkillType.Language"
              :skill-location="SkillLocationType.CurrentJob"
              @updated="currentJobExperienceLanguageSkillsUpdated" />
          </div>
        </div>
      </div>
      <AppSeparator class="margin-v--m" />
      <div class="grid--two-columns@medium">
        <div>
          <h2 class="margin-b--m">{{ $t("pages.current_experience.experience") }}</h2>
          <AppNoContentYet v-if="jobExperiences.length === 0" />
          <div v-for="jobExperience in jobExperiences" :key="jobExperience.id" class="margin-b--m">
            <JobExperienceEditor
              :employee-id="profileInfo.id"
              form-mode="edit"
              truncate-text
              :input="jobExperience"
              @deleted="jobExperienceDeleted"
              @updated="jobExperienceUpdated" />
          </div>
          <JobExperienceEditor :employee-id="profileInfo.id" class="margin-v--m" form-mode="create" @created="jobExperienceCreated" />
        </div>
        <div>
          <h2 class="margin-b--m">{{ $t("pages.current_experience.education") }}</h2>
          <AppNoContentYet v-if="educations.length === 0" />
          <div v-for="education in educations" :key="education.id" class="margin-b--m">
            <EducationEditor
              :employee-id="profileInfo.id"
              form-mode="edit"
              truncate-text
              :input="education"
              @deleted="educationDeleted"
              @updated="educationUpdated" />
          </div>
          <EducationEditor :employee-id="profileInfo.id" class="margin-v--m" form-mode="create" @created="educationCreated" />
        </div>
      </div>
      <AppSeparator class="margin-v--m" />
      <h2 class="margin-b--m">{{ $t("pages.current_experience.skills") }}</h2>
      <div class="grid--two-columns@medium">
        <div>
          <h5 class="margin-b--m">{{ $t("pages.current_experience.skills.general") }}</h5>
          <AppNoContentYet v-if="generalSkills.length === 0" class="margin-b--xs" data-cy="current-experience-general-skills-no-content" />
          <SkillEditor :input="generalSkills" :skills-type="SkillType.General" @updated="generalSkillsUpdated" />
        </div>
        <div>
          <h5 class="margin-b--m">{{ $t("pages.current_experience.languages") }}</h5>
          <AppNoContentYet v-if="languages.length === 0" class="margin-b--xs" />
          <SkillEditor :input="languages" :skills-type="SkillType.Language" @updated="languagesUpdated" />
        </div>
      </div>
      <div class="margin-t--m margin-b--m">
        <AppPanel :dark="true" :border="false">
          <AppToggle
            v-model="profileInfo.agreeShareExperience"
            :text="$t('shared.agree_share_information')"
            data-cy="current-experience-agree-share-experience"
            @update:model-value="agreeShareExperienceUpdated" />
        </AppPanel>
      </div>
      <AppEmployeeCurrentExperienceGoals v-if="!loading" :goals="myGoals" :no-goals-text="$t('entities.goals_challenges.no_goals_yet')" />
      <AppCurrentExperienceProfileImporter v-if="showProfileImporter" @closed="showProfileImporter = false" @imported="profileImported" />
    </div>
    <template #element>
      <AppButton
        :text="$t('pages.current_experience.linkedin.import')"
        size="m"
        variant="primary"
        data-cy="import-profile"
        @onclick="showProfileImporter = true" />
    </template>
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { compareDates } from "@/utils/dateUtils";
import { getLanguageSkills, getGeneralSkills } from "@/utils/models";
import { SkillType, SkillLocationType } from "@/models/skillType";
import { type CurrentJobExperience, type JobExperience, JobExperienceType } from "@/models/jobExperience";
import AboutMeEditor from "@/views/shared/current-experience/AboutMeEditor.vue";
import AppButton from "@/components/AppButton.vue";
import AppCurrentExperienceProfileImporter from "@/views/app/current-experience/components/AppCurrentExperienceProfileImporter.vue";
import AppEmployeeCurrentExperienceGoals from "@/views/shared/current-experience/AppEmployeeCurrentExperienceGoals.vue";
import EmployeeCurriculumUploader from "@/views/app/current-experience/components/EmployeeCurriculumUploader.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppTip from "@/components/AppTip.vue";
import AppToggle from "@/components/AppToggle.vue";
import EducationEditor from "@/views/shared/current-experience/EducationEditor.vue";
import JobExperienceEditor from "@/views/shared/current-experience/JobExperienceEditor.vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import SkillEditor from "@/views/shared/current-experience/FloatingPanelSkillEditor.vue";
import type { CompanyGoal } from "@/models/companyGoal";
import type { Education } from "@/models/education";
import type { ProfileInformation } from "@/models/profileInformation";
import type { SkillWithProgression } from "@/models/skillWithProgression";
import useMeAgreementsService from "@/services/useMeAgreementsService";
import useMeGoalsService from "@/services/useMeGoalsService";
import useMeService from "@/services/useMeService";
import { sortByProgressionName } from "@/services/modelServices/skillService";

const { getProfileInformation } = useMeService();
const { updateAgreeShareExperience } = useMeAgreementsService();
const { getMyGoals } = useMeGoalsService();

const profileInfo = ref<ProfileInformation>();
const loaded = ref<boolean>(false);
const generalSkills = ref<SkillWithProgression[]>([]);
const languages = ref<SkillWithProgression[]>([]);
const currentJobExperience = ref<CurrentJobExperience | undefined>();
const currentJobExperienceGeneralSkills = ref<SkillWithProgression[]>([]);
const currentJobExperienceLanguageSkills = ref<SkillWithProgression[]>([]);
const jobExperiences = ref<JobExperience[]>([]);
const educations = ref<Education[]>([]);
const myGoals = ref<CompanyGoal[]>([]);
const loading = ref<boolean>(true);
const showProfileImporter = ref<boolean>(false);
const aboutMeKey = ref<number>(0);

onMounted(async () => {
  await getCurrentExperience();
  myGoals.value = await getMyGoals();
  loading.value = false;
  loaded.value = true;
});

const agreeShareExperienceUpdated = async () => {
  if (profileInfo.value) {
    await updateAgreeShareExperience(profileInfo.value.agreeShareExperience);
  }
};

const getCurrentExperience = async () => {
  const result = await getProfileInformation();
  if (result) {
    profileInfo.value = result;
    languages.value = getLanguageSkills([...profileInfo.value.skills]).sort(sortByProgressionName);
    filterSkills(profileInfo.value.skills);
    jobExperiences.value = [...profileInfo.value.jobExperiences].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));
    currentJobExperience.value = profileInfo.value.currentJobExperience;
    currentJobExperienceGeneralSkills.value = [
      ...(profileInfo.value.currentJobExperience?.skills.filter((s) => s.type === SkillType.General) ?? []),
    ];
    currentJobExperienceLanguageSkills.value = [
      ...(profileInfo.value.currentJobExperience?.skills.filter((s) => s.type === SkillType.Language) ?? []),
    ];
    educations.value = [...profileInfo.value.educations].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));
    aboutMeKey.value = aboutMeKey.value + 1;
  }
};

const filterSkills = (updatedSkills: SkillWithProgression[]): void => {
  const skills = [...updatedSkills].sort(sortByProgressionName);
  generalSkillsUpdated(getGeneralSkills(skills));
};

const generalSkillsUpdated = (updatedSkills: SkillWithProgression[]): void => {
  generalSkills.value = [...updatedSkills].sort(sortByProgressionName);
};

const currentJobExperienceGeneralSkillsUpdated = (updatedSkills: SkillWithProgression[]): void => {
  currentJobExperienceGeneralSkills.value = updatedSkills;
};

const currentJobExperienceLanguageSkillsUpdated = (updatedSkills: SkillWithProgression[]): void => {
  currentJobExperienceLanguageSkills.value = updatedSkills;
};

const languagesUpdated = (updatedLanguages: SkillWithProgression[]): void => {
  languages.value = [...updatedLanguages].sort(sortByProgressionName);
};

const jobExperienceDeleted = (jobExperience: JobExperience): void => {
  jobExperiences.value = jobExperiences.value.filter((j) => j.id !== jobExperience.id);
};

const jobExperienceCreated = (jobExperience: JobExperience): void => {
  jobExperiences.value.push(jobExperience);
};

const jobExperienceUpdated = (jobExperience: JobExperience): void => {
  jobExperiences.value = jobExperiences.value.filter((j) => j.id !== jobExperience.id);
  jobExperiences.value.push(jobExperience);
  jobExperiences.value = [...jobExperiences.value].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));
};

const currentJobExperienceUpdated = (jobExperience: JobExperience): void => {
  currentJobExperience.value = { ...jobExperience, skills: currentJobExperience.value?.skills ?? [] };
};

const educationDeleted = (education: Education): void => {
  educations.value = educations.value.filter((j) => j.id !== education.id);
};

const educationCreated = (education: Education): void => {
  educations.value.push(education);
  educations.value = [...educations.value].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));
};

const educationUpdated = (education: Education): void => {
  educations.value = educations.value.filter((j) => j.id !== education.id);
  educations.value.push(education);
  educations.value = [...educations.value].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));
};

const profileImported = async (): Promise<void> => {
  loading.value = true;
  await getCurrentExperience();
  showProfileImporter.value = false;
  loading.value = false;
};

const curriculumSet = (newFileName: string | null) => {
  if (profileInfo.value) {
    profileInfo.value.curriculumName = newFileName;
  }
};
</script>

<style lang="scss" scoped>
.current-job {
  border-radius: var(--size-xs);
  background-color: var(--color-neutral-100);
  box-shadow: 0 var(--space-xs) var(--space-m) rgba(26, 26, 26, 0.04);
  padding: var(--space-m);
}
</style>
