<template>
  <AppAutomaticModalOpener :modal-config="modalConfig">
    {{ description }}
  </AppAutomaticModalOpener>
</template>
<script setup lang="ts">
import type { ModalBase } from "@/components/modal/models";
import i18n from "@/i18n";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";

interface Props {
  totalUsers: number;
  purchasedUsers: number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "closed") => void>();

const description = i18n.global.t("pages.guest.create_company_from_azure_ad.error_modal.description", {
  totalUsers: props.totalUsers,
  purchasedUsers: props.purchasedUsers,
});

const modalConfig: ModalBase = {
  icon: { content: "warning", style: "error" },
  title: i18n.global.t("pages.guest.create_company_from_azure_ad.error_modal.title"),
  variant: "small",
  confirmText: i18n.global.t("pages.guest.create_company_from_azure_ad.error_modal.button"),
  confirmCallback: () => redirectToPurchasePortal(),
  onClosed: () => emit("closed"),
};

const purchaseUrl =
  "https://appsource.microsoft.com/en-us/product/web-apps/substrateartificialinteligencesa1650445591189.fleebe_v1?exp=kyyw";

const redirectToPurchasePortal = () => {
  window.location.href = purchaseUrl;
};
</script>
