<template>
  <div class="admin-talent-detection__chart">
    <NoContentChart v-if="showNoContent" />
    <div v-else id="chart" data-cy="talent-chart-hobby-by-group">
      <apexchart :options="options" :series="series" @click="getSelection" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { getSeriesLabel, hobbiesColorMap } from "@/views/admin/talent-detection/utils";
import { HobbyCategory } from "@/models/hobbyCategory";
import { computed, onMounted, ref } from "vue";
import NoContentChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionNoContentChart.vue";
import type { ChartSeriesData } from "@/models/talent-detection/seriesData";
import type { Group } from "@/models/talent-detection/group";
import type { GroupChartSelection } from "@/models/talent-detection/chartSelections";
import type { Hobby } from "@/models/hobby";
import useCompanyTalentDetectionService from "@/services/useCompanyTalentDetectionService";

const { getSeriesDataByGroupAndHobby } = useCompanyTalentDetectionService();

interface Props {
  hobby: Hobby;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "clicked", value: GroupChartSelection | undefined) => void>();

const groups = ref<Group[]>([]);
const series = ref<ChartSeriesData[]>([]);
const hobbyColor = hobbiesColorMap[props.hobby.category ?? HobbyCategory.Sport];
const requested = ref<boolean>(false);
const showNoContent = computed(() => requested.value && !series.value.length);
const options = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  colors: [hobbyColor],
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    max: 100,
    categories: [""],
    labels: {
      formatter: (percentage: string) => `${percentage}%`,
    },
  },
  tooltip: {
    y: {
      formatter: (percentage: string) => `${percentage}%`,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    showForSingleSeries: true,
    offsetY: 30,
    itemMargin: {
      horizontal: 20,
    },
  },
  title: {
    align: "center",
    text: props.hobby?.name ?? "",
    style: {
      fontSize: "20px",
      fontWeight: "700",
      fontFamily: undefined,
    },
  },
};

onMounted(async () => {
  await fetchData();
  requested.value = true;
});

const fetchData = async (): Promise<void> => {
  const response = await getSeriesDataByGroupAndHobby(props.hobby.id);
  const groupsWithData = response.groups.filter((_, index) => response.data[index] !== 0);
  const data = response.data.filter((value) => value !== 0);

  if (!groupsWithData.length) {
    return;
  }

  groups.value = [...groupsWithData];
  options.xaxis.categories.splice(0, 1, ...groups.value.map((g) => g.name));
  series.value.push({ name: getSeriesLabel(response.category), data: [...data] });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSelection = (_event: any, _chartContext: any, config: any): void => {
  if (config.seriesIndex < 0 || config.dataPointIndex < 0) {
    return;
  }

  const selectedDataPointsLength = config.globals.selectedDataPoints.length;
  const selectedDataPoint = config.globals.selectedDataPoints[selectedDataPointsLength - 1];

  if (!selectedDataPoint?.length) {
    emit("clicked", undefined);
    return;
  }

  const groupName = options.xaxis.categories[config.dataPointIndex];
  const group = groups.value.find((g) => g.name === groupName);
  if (group === undefined) {
    return;
  }

  emit("clicked", {
    group,
    hobbiesCategory: props.hobby.category,
    hobby: props.hobby,
  });
};
</script>
