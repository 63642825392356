<template>
  <div class="birth-date-selector">
    <AppInputDate
      v-model="birthDateField"
      data-cy="birthDate-selector"
      :error="birthDateFieldError"
      :label="$t('pages.header.profile-editor.personal-information.birthDate')"
      :min-date="minDate"
      :max-date="maxDate"
      @update:model-value="changed = true" />
    <AppLinkButton v-if="changed" :text="$t('buttons.save')" data-cy="birthDate-save" @click="handleSave" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useField, useForm } from "vee-validate";
import i18n from "@/i18n";
import AppInputDate from "@/components/AppInputDate.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import useMeService from "@/services/useMeService";
import useFormSchema from "@/services/useFormSchema";
import { convertDateOnlyToLocale } from "@/utils/dateUtils";

interface Props {
  birthDate?: Date;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "updated", newDate: Date) => void>();

const { updateBirthDate } = useMeService();
const { yupObject, dateSchema } = useFormSchema();

const changed = ref<boolean>();

const minDate: Date = new Date(1920, 0, 1);
const maxDate: Date = convertDateOnlyToLocale(new Date(new Date().getFullYear() - 17, 11, 31));

const outOfRangeError = i18n.global.t("errors.BirthDateOutOfRange");
const resourceSchema = yupObject({
  birthDateField: dateSchema(true).min(minDate, outOfRangeError).max(maxDate, outOfRangeError),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: birthDateField, errorMessage: birthDateFieldError } = useField<Date | undefined>("birthDateField");

onMounted(() => (birthDateField.value = props.birthDate));

const handleSave = async () => {
  const result = await form.validate();
  if (!result.valid || !birthDateField.value) {
    return;
  }
  await updateBirthDate(birthDateField.value);
  changed.value = false;
  emit("updated", birthDateField.value);
};
</script>

<style scoped lang="scss">
.birth-date-selector {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  align-items: center;
}
</style>
