<template>
  <div class="layout-menu">
    <div class="logo">
      <router-link :to="rootUrl">
        <img src="@/assets/images/logo.svg" alt="" data-cy="logo" />
      </router-link>
    </div>
    <nav class="nav">
      <div v-for="menuOption in menuOptionsToShow" :key="menuOption.url">
        <router-link :to="menuOption.url" class="nav__link" active-class="nav__link--active">
          <AppMenuItem :icon="menuOption.icon" :text="menuOption.text" />
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import type { MenuOption } from "@/layout/models/menu-option";
import AppMenuItem from "@/layout/shared/LayoutMenuItem.vue";
import { computed } from "vue";
import useFeatureFlags from "@/services/useFeatureFlags";
interface Props {
  menuOptions: MenuOption[];
  rootUrl: string;
}
const props = defineProps<Props>();
const { checkFeatureFlag } = useFeatureFlags();
const menuOptionsToShow = computed<MenuOption[]>(() =>
  props.menuOptions.filter((option) => !option.hide && checkFeatureFlag(option.featureFlag))
);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.layout-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 0 var(--space-s);
  overflow-y: auto;

  &__link {
    display: flex;
    align-items: center;
    height: 64px;
    opacity: 0.6;
    transition: background 0.3s ease;

    &:hover {
      opacity: 1;
    }

    &--active {
      background: var(--color-sidebar-menu--active);
      border-radius: var(--default-border-radius);
      opacity: 1;
    }
  }
}

.logo {
  padding: 50px 10px;

  img {
    width: 210px;
  }
}

@media only screen and (max-width: $large-screen-breakpoint) {
  .nav {
    padding: 0;

    &__link {
      padding-right: 0;
      border-radius: 0;
    }
  }

  .logo {
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: $medium-screen-breakpoint) {
  .layout-menu {
    padding: 0;
    .logo {
      display: none;
    }
    .nav {
      flex-direction: row;
      &__link {
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        width: 64px;
      }
    }
  }
}
</style>
