<template>
  <div>
    <p class="text text--bold margin-b--m">{{ $t("pages-my-profile.profile_updating.steps_guide.title_1") }}</p>

    <AppBox :title="$t('pages-my-profile.profile_updating.steps_guide.skills_profile.title')">
      <div>
        <p class="margin-b--s text text--bold">{{ $t("pages-my-profile.profile_updating.steps_guide.skills_profile.content") }}</p>
        <AppButton
          :text="$t('pages-my-profile.profile_updating.steps_guide.skills_profile.select')"
          size="m"
          data-cy="select-employee-skills"
          @onclick="selectEmployeeSkills" />
      </div>
    </AppBox>

    <p class="text text--bold margin-v--m">{{ $t("pages-my-profile.profile_updating.steps_guide.title_2") }}</p>

    <AppBox :title="$t('pages-my-profile.profile_updating.steps_guide.current_job.title')">
      <div>
        <p class="margin-b--s text text--bold">{{ $t("pages-my-profile.profile_updating.steps_guide.current_job.content_1") }}</p>
        <AppButton
          :text="$t('pages-my-profile.profile_updating.steps_guide.current_job.select_current_job_skills')"
          size="m"
          data-cy="select-current-job-skills"
          @onclick="selectCurrentJobSkills" />
        <p class="margin-v--s">{{ $t("pages-my-profile.profile_updating.steps_guide.current_job.content_2") }}</p>
        <p class="margin-b--s">{{ $t("pages-my-profile.profile_updating.steps_guide.current_job.content_3") }}</p>
        <AppButton
          :text="$t('pages-my-profile.profile_updating.steps_guide.current_job.get_linkedin_profile')"
          size="m"
          variant="secondary"
          data-cy="go-to-current-experience"
          @onclick="goToMyCurrentExperience" />
      </div>
    </AppBox>

    <AppBox :title="$t('pages-my-profile.profile_updating.steps_guide.ideal_job.title')" class="margin-t--m">
      <div>
        <p class="margin-b--s text text--bold">{{ $t("pages-my-profile.profile_updating.steps_guide.ideal_job.content_1") }}</p>
        <AppButton
          :text="$t('pages-my-profile.profile_updating.steps_guide.ideal_job.select_ideal_job_skills')"
          size="m"
          data-cy="select-ideal-job-skills"
          @onclick="selectIdealJobSkills" />
        <p class="margin-v--s">
          {{ $t("pages-my-profile.profile_updating.steps_guide.ideal_job.content_2") }}
        </p>
        <AppButton
          :text="$t('pages-my-profile.profile_updating.steps_guide.ideal_job.go_to_my_ideal_job')"
          size="m"
          variant="secondary"
          data-cy="go-to-my-ideal-job"
          @onclick="goToMyIdealJob" />
      </div>
    </AppBox>
  </div>
</template>

<script lang="ts" setup>
import AppBox from "@/components/AppBox.vue";
import AppButton from "@/components/AppButton.vue";
import router from "@/router/router";

const emit = defineEmits<{
  (e: "select-employee-skills"): void;
  (e: "select-current-job-skills"): void;
  (e: "select-ideal-job-skills"): void;
}>();

const goToMyCurrentExperience = () => router.push({ name: "my-current-job" });

const goToMyIdealJob = () => router.push({ name: "my-ideal-job-learning" });

const selectEmployeeSkills = (): void => {
  emit("select-employee-skills");
};

const selectIdealJobSkills = (): void => {
  emit("select-ideal-job-skills");
};

const selectCurrentJobSkills = (): void => {
  emit("select-current-job-skills");
};
</script>
