import type { Router, RouteLocationNormalized } from "vue-router";
import useFeatureFlags from "@/services/useFeatureFlags";
import { redirectTo } from "@/utils/redirectUtil";

export const featureGuard = (router: Router): void => {
  const { checkFeatureFlag } = useFeatureFlags();

  router.beforeResolve((to: RouteLocationNormalized) => {
    if (to.meta.featureFlag && !checkFeatureFlag(to.meta.featureFlag as string)) {
      redirectTo("/");
      return false;
    }
    return true;
  });
};
