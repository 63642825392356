import type { Ref } from "vue";
import type { SkillType } from "@/models/skillType";
import type { SuccessFullSkills } from "@/models/successFullSkills";
import type { SkillUpdateRequest } from "@/models/requests/skillUpdateRequest";
import type { Skill } from "@/models/skill";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me/skills";
const { get, put, loading } = useHttpClient();

interface UseMeSkillsServiceReturn {
  getAvailableSkills: (skillType: SkillType) => Promise<Skill[]>;
  getSuccessfulSkills: () => Promise<SuccessFullSkills>;
  updateSkills: (skillUpdateRequest: SkillUpdateRequest) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeSkillsServiceReturn => {
  const getAvailableSkills = (skillType: SkillType): Promise<Skill[]> => get(`${BASE_URL}/available/${skillType}`);
  const getSuccessfulSkills = (): Promise<SuccessFullSkills> => get(`${BASE_URL}/successful`);
  const updateSkills = (skillUpdateRequest: SkillUpdateRequest): Promise<void> => put(BASE_URL, skillUpdateRequest);

  return { getAvailableSkills, getSuccessfulSkills, updateSkills, loading };
};
