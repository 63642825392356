{
  "buttons.next": "Next",
  "buttons.previous": "Prev",
  "buttons.edit": "Edit",
  "buttons.answer": "Answer",
  "buttons.save": "Save",
  "buttons.delete": "Delete",
  "buttons.add": "Add",
  "buttons.create": "New",
  "buttons.cancel": "Cancel",
  "buttons.continue": "Continue",
  "buttons.save_draft": "Save as draft",
  "buttons.publish": "Publish",
  "buttons.download": "Download",
  "actions.saved": "Information saved successfully",
  "avatar.initials": "{first}{last}",
  "skill_range.skill_names.low": "Low",
  "skill_range.skill_names.medium": "Medium",
  "skill_range.skill_names.high": "High",
  "roles.user": "Employee",
  "roles.administrator": "Administrator",
  "roles.groupmanager": "Group Manager",
  "roles.itmanager": "IT Manager",
  "errors.DateShouldBeGreaterThanOrEqualToToday": "Date must be today or later",
  "errors.BirthDateOutOfRange": "Date not valid",
  "errors.CompanyEmployeeGroupCourseAlreadyAssigned": "Course has been already assigned to this group",
  "errors.CompanyEmployeeGroupEmployeeNotFound": "Employee was not found. Please, refresh the page",
  "errors.CompanyEmployeeGroupExistsWithName": "A Group with that name already exists.",
  "errors.CompanyEmployeeGroupNotFound": "Employee Group was not found. Please, refresh the page",
  "errors.CompanyEmployeeGroupsNotFound": "Some employee groups not found",
  "errors.CompanyNotFound": "Company was not found",
  "errors.CourseNotFound": "Course not found",
  "errors.EmployeeAlreadyAssigned": "Selected employee is already assigned to the goal",
  "errors.EmployeesCannotBeAddedToAnEndedGoal": "Employees cannot be assigned to an ended goal",
  "errors.EmployeesCannotBeRemovedFromAnEndedGoal": "Employees cannot be unassigned from an ended goal",
  "errors.ErrorWhileConnectingToAzure": "Connection to Azure with the provided data could not be done. Please confirm Client ID and secret are correct.",
  "errors.ExpectedLinkedinFilesWereNotFound": "Expected LinkedIn files were not found. Please, make sure that 'Education', 'Languages', 'Positions', 'Profile' or 'Skills' files exist",
  "errors.FieldRequired": "Required field",
  "errors.FileExtensionNotSupported": "File extension is not supported",
  "errors.FileSizeExceeded": "File size exceeded",
  "errors.GoalCannotBeLaunchedAfterItStarted": "Please, update the 'Goal start date' with a date equal to or future than the current one",
  "errors.GoalCannotBeLaunchedWhileApplicationIsOpen": "Application phase is still open. Please, wait for the application phase to finish before launching the goal",
  "errors.GoalCannotBeLaunchedWithFewerPeopleThanNeeded": "The number of people assigned is less than needed. Please, assign more people before launching the goal",
  "errors.GoalPeopleCannotBeGreaterThanTheNumberOfPeopleNeeded": "The number of people needed cannot be exceeded",
  "errors.GoalResultCannotBeSetUntilItEnds": "The result of the goal cannot be set until it ends",
  "errors.GoalResultCannotBeUpdated": "The goal result have been already set and cannot be updated",
  "errors.GoalsFinishedCannotBeUpdated": "Finished goals cannot be updated",
  "errors.GoalStartDateNotValid": "Start date not valid",
  "errors.GoalEndDateNotValid": "End date not valid",
  "errors.HobbyAlreadyExistWithName": "A hobby with that name already exists.",
  "errors.IdealLearningAlreadyExistsWithTitle": "A training with that title already exists",
  "errors.InterestAlreadyExistsWithTitle": "An interest with that title already exists",
  "errors.LearningStartDateNotValid": "Start date is not valid",
  "errors.QuestionIsAlreadyPublished": "Question is already published",
  "errors.QuestionNotFound": "Question not found",
  "errors.QuestionNotFoundCannotBeAnswered": "Question was not found. Please, refresh the page",
  "errors.QuestionsWithAnswersCannotBeUpdated": "Questions with answers can't be updated",
  "errors.QuestionsNotFound": "Some questions not found",
  "errors.RequestNotFound": "Skill requested was not found. Please, refresh the page",
  "errors.SkillAlreadyBeingCreatedWithName": "A skill with that name is being created.",
  "errors.SkillAlreadyExistWithName": "A skill with that name already exists.",
  "errors.UnexpectedFileFormat": "Unexpected file format was found",
  "errors.UnknownError": "Unknown error",
  "errors.UsersInAzureAdExceedsPurchasedUsers": "The number of users found in the Azure Active Directory exceeds the number of purchased users",
  "errors.EmployeeNotFound": "Employee not found",
  "inputs.a_date_has_to_be_after_another_date": "'{first}' date must be after '{second}' date",
  "inputs.date_less_than_or_equal_to_today": "Date must be earlier than or equal to today",
  "inputs.date_today_or_later": "Date must be today or later",
  "inputs.DateNotValid": "Date not valid",
  "inputs.educationMode.label": "Mode",
  "inputs.educationMode.online.label": "Online",
  "inputs.educationMode.presence.label": "On-site",
  "inputs.end_date": "End",
  "inputs.max_length_exceeded": "Field must be at most {maxLength} characters",
  "inputs.max_number_items_exceeded": "Field must be at most {maxItems} items",
  "inputs.max_required": "Field must be at most {maximum}",
  "inputs.min_required": "Field must be at least {minimum}",
  "inputs.options.required": "Option {option} is required",
  "inputs.required": "Required field",
  "inputs.start_date": "Start",
  "inputs.tags.placeholder": "Type here to add...",
  "inputs.to_date_has_to_be_after_from_date": "'To' date must be after 'From' date",
  "inputs.upload_document.invalid_extension": "Uploaded file has an invalid extension",
  "inputs.upload_document.maximum_size_MB": "{size} MB max size",
  "inputs.upload_document.no_document_uploaded": "No document uploaded yet",
  "inputs.upload_document.prompt.delete.description": "You are going to delete this file. This action cannot be undone. Are your sure you want to continue?",
  "inputs.upload_document.prompt.delete.title": "Delete file",
  "inputs.upload_document.prompt.file_to_big.title": "File too Big",
  "inputs.upload_document.prompt.file_to_big.description": "The file you are trying to upload is too big.",
  "inputs.upload_document.supported_types": "Supported types: {types}",
  "inputs.url_not_valid": "Field must be a valid URL",
  "pages.ai_assistant.title": "AI Assistant",
  "pages.ai_assistant.header.title": "Questions to Employees",
  "pages.ai_assistant.header.subtitle": "Welcome to your AI Assistant. Your AI Assistant will ask you questions through the chatbot, your answers will give it information to give you the best learning path and experience in Fleebe. If you need to modify your answers, you can do it here at any time.",
  "pages.ai_assistant.question.level": "Expand on your answer or share your feedback (whether positive or constructive)",
  "pages.ai_assistant.question.tags": "Type your options (separated by commas or enter)",
  "pages.ai_assistant.no.questions": "Your administration has not configured questions yet",
  "pages.header.role": "Role",
  "pages.header.goToAdministratorArea": "Go to administrator area",
  "pages.header.goToEmployeeArea": "Go to employee area",
  "pages.header.logout": "Log out",
  "pages.header.edit": "Edit",
  "pages.header.picture-uploader.label": "Choose a picture for your avatar",
  "pages.header.profile-editor.personal-information.title": "Personal Information",
  "pages.header.profile-editor.personal-information.birthDate": "Birth Date",
  "pages.header.profile-editor.app-settings.title": "App Settings",
  "pages.header.profile-editor.app-settings.languages.title": "Language",
  "pages.header.profile-editor.app-settings.languages.english": "English",
  "pages.header.profile-editor.app-settings.languages.en": "English",
  "pages.header.profile-editor.app-settings.languages.spanish": "Español",
  "pages.header.profile-editor.app-settings.languages.es": "Español",
  "pages.header.profile-editor.app-settings.languages.french": "Français",
  "pages.header.profile-editor.app-settings.languages.fr": "Français",
  "pages.header.profile-editor.app-settings.languages.german": "Deutsch",
  "pages.header.profile-editor.app-settings.languages.italian": "Italiano",
  "pages.header.profile-editor.app-settings.languages.portuguese": "Português",
  "pages.goals_challenges.title": "Goals & Challenges",
  "pages.goals_challenges.search.placeholder": "Search goals and challenges",
  "pages.goals_challenges.featured.title": "Featured Goals & Challenges",
  "pages.goals_challenges.featured.vacant_positions": "Only {vacantPositions} vacant positions",
  "pages.goals_challenges.featured.days_left": "{days} days left",
  "pages.goals_challenges.featured.dates": "From {start} to {end}",
  "pages.goals_challenges.my.title": "My Goals & Challenges",
  "pages.goals_challenges.my.progress": "Progress",
  "pages.goals_challenges.my.progress_amount": "{progress}%",
  "pages.goals_challenges.no.goals": "Your administration has not configured goals yet",
  "pages.learning.title": "My Learning",
  "pages.learning.learning.tab": "Learning",
  "pages.learning.attachment.label": "Choose a certificate to upload",
  "pages.learning.search": "Search",
  "pages.learning.search.no_results": "There are no courses with this filter",
  "pages.learning.search.no_copy": "We don’t copy your searches. This is a private area.",
  "pages.learning.search.title": "Search results for {searchTerm}",
  "pages.learning.featured.title": "Featured Learning for You",
  "pages.learning.filter.type.title": "Type",
  "pages.learning.filter.type.all": "All",
  "pages.learning.filter.type.CourseCoursera": "Coursera",
  "pages.learning.filter.type.CourseEdX": "edX",
  "pages.learning.filter.type.CourseLinkedInLearning": "LinkedIn",
  "pages.learning.filter.type.CourseIntefi": "Intefi",
  "pages.learning.filter.type.CourseCompany": "Internal training",
  "pages.learning.mode.online": "online",
  "pages.learning.mode.onsite": "on-site",
  "pages.learning.rateTitle": "Rate this training",
  "pages.learning.rateObservations.placeHolder": "It’s very important for us to get feedback from you to customize our learning platform with the best courses adapted to you.",
  "pages.learning.sendTraining": "Send training",
  "pages.learning.trainingComplete": "Training completed",
  "pages.learning.confirmSetTrainingAsComplete.title": "Set training as completed",
  "pages.learning.confirmSetTrainingAsComplete.text": "You are going to set a training as completed. This action cannot be undone so be sure to be right.",
  "pages.learning.goToTheTraining": "Go to the training",
  "pages.learning.certificateReminder": "If you have some certificate or diploma that accredits a finished training, please remember to add it to your Current Experience.",
  "pages.learning.siteInformation.title": "Information of the on-site training",
  "pages.learning.tip.content": "The more information you provide on Fleebe, CV, interest, ideal job... , more accurate your AI Assistant's recommendation will be to guide you to your future aspirations.",
  "pages.learning.my_learning_path.title": "My learning path",
  "pages.learning.my_learning_path.tab": "My learning path",
  "pages.learning.my_learning_path.introduction.title": "AI Training Path to My Ideal Job",
  "pages.learning.my_learning_path.introduction.explanation": "Following the parameters established in the configuration of your ideal position, our AI has created a training itinerary that can help you achieve your goal. Remember that this help is for guidance only and that you ultimately decide what is best for you.",
  "pages.learning.my_learning_path.areas.skills": "Skills",
  "pages.learning.my_learning_path.areas.languages": "Languages",
  "pages.learning.my_learning_path.areas.financial": "Financial education",
  "pages.learning.my_learning_path.levels.beginner": "Beginner",
  "pages.learning.my_learning_path.levels.advanced": "Advanced",
  "pages.learning.my_learning_path.levels.expert": "Expert",
  "pages.learning.my_learning_path.additional_learning": "Additional learning",
  "pages.learning.my_learning_path.no_suggestions": "No suggestions in this area/level",
  "pages.learning.my_learning_path.recalculate.title": "I want my learning path to be recalculated",
  "pages.learning.my_learning_path.recalculate.activate_explanation": "The recalculation will be performed unattended overnight",
  "pages.learning.my_training.title": "My training",
  "pages.learning.my_training.tab": "My training",
  "pages.learning.my_training.tip.content": "Your learning path will not change if you do not change your ideal job.",
  "pages.learning.my_training.no_completed": "You don't have any training completed yet",
  "pages.my_interests.form.idealLearning.create": "Add new training",
  "pages.my_interests.form.idealLearning.create.title": "New training",
  "pages.my_interests.form.idealLearning.delete.content": "You are going to delete the selected training. This action cannot be undone. Are you sure you want to continue?",
  "pages.my_interests.form.idealLearning.delete.title": "Delete training",
  "pages.my_interests.form.idealLearning.edit.title": "Edit training",
  "pages.my_interests.form.idealLearning.fields.description.label": "Description of the training",
  "pages.my_interests.form.idealLearning.fields.description.placeholder": "Enter a brief description",
  "pages.my_interests.form.idealLearning.fields.title.label": "Title of the training",
  "pages.my_interests.form.idealLearning.fields.title.placeholder": "Enter a title",
  "pages.my_interests.form.interests.create": "Add new interest",
  "pages.my_interests.form.interests.create.title": "New interest",
  "pages.my_interests.form.interests.delete.content": "You are going to delete the selected interest. This action cannot be undone. Are you sure you want to continue?",
  "pages.my_interests.form.interests.delete.title": "Delete interest",
  "pages.my_interests.form.interests.edit.title": "Edit interest",
  "pages.my_interests.form.interests.fields.description.label": "Description of the interest",
  "pages.my_interests.form.interests.fields.description.placeholder": "Enter a brief description",
  "pages.my_interests.form.interests.fields.title.label": "Title of the interest",
  "pages.my_interests.form.interests.fields.title.placeholder": "Enter a title",
  "pages.my_interests.idealLearning": "Learning",
  "pages.my_interests.interests": "Interests",
  "pages.my_interests.tip.content": "In this section we invite you to fill in your interests, and your hobbies. Also the learnings you have made and that you find interesting to reflect.",
  "pages.my_interests.title": "My Interests",
  "pages.my_profile.dashboards.successful_skills.title": "Current skills",
  "pages.my_profile.dashboards.certificates_courses.label": "Last 5 certificates and courses",
  "pages.my_profile.dashboards.certificates_courses.type_learning.online.label": "Online",
  "pages.my_profile.dashboards.certificates_courses.type_learning.presence.label": "Presence",
  "pages.my_profile.dashboards.company_goals.last_update.subtitle": "Last update, {date}",
  "pages.my_profile.dashboards.company_goals.graph": "Goals Achieved",
  "pages.my_profile.dashboards.successful_skills.general": "General",
  "pages.my_profile.dashboards.successful_skills.language": "Languages",
  "pages-my_profile.dashboards.general_skills.title": "Your general skills",
  "pages-my_profile.dashboards.tips.introduction": "Don't worry if you still don't see anything here. By completing your profile and with the day by day job flow a lot of information will appear.",
  "pages-my_profile.dashboards.tips.no-content": "Fill in your current and ideal job to complete the chart below showing the skills that are relevant for you.",
  "pages-my_profile.dashboards.tips.content": "To add a new skill or modify their levels, please visit My Current Job or My Ideal Job sections. If you want to replace a skill for another one you can do it clicking the Edit button below.",
  "pages-my_profile.dashboards.performance_graph.no_content_yet": "We don't have enough information to create the chart",
  "pages-my_profile.dashboards.performance_graph.edit": "Edit performance graph",
  "pages-my-profile.profile_updating.tab": "Profile updating",
  "pages-my-profile.profile_updating.welcome_guide.title": "Welcome to Fleebe",
  "pages-my-profile.profile_updating.welcome_guide.subtitle": "Your new professional development tool",
  "pages-my-profile.profile_updating.welcome_guide.tip": "Beginnings are tough. So follow the next steps in order to complete your profile in a fast and friendly way.",
  "pages-my-profile.profile_updating.welcome_guide.steps.title": "First of all...",
  "pages-my-profile.profile_updating.welcome_guide.steps.1": "Complete your personal profile. You know, name, age, personal picture, current job, etc. You only have to hover on the avatar icon on application right upper corner.",
  "pages-my-profile.profile_updating.welcome_guide.steps.2": "We are quite daring and we want to visualize in a single graph not only your degree of adaptation to your current position but also to an ideal position created by you. Can you help us? Follow the links in the cards on the right side.",
  "pages-my-profile.profile_updating.welcome_guide.steps.3": "For this we need two things: first, list the skills that best describe your current job; and second, do the same thing but with the skills of your ideal job, the one that you would like to have in the future. Easy, right?",
  "pages-my-profile.profile_updating.welcome_guide.steps.4": "If you import your profile from Linkedin, most of those skills will be loaded automatically and you only have to choose which ot them fit better in the jobs described by you. But, if you prefer it, you can do this manually.",
  "pages-my-profile.profile_updating.welcome_guide.summary.title": "And that's all",
  "pages-my-profile.profile_updating.welcome_guide.summary.content": "When you finish, you should see something like this. A quite intuitive way of visualize both your fit to your current job and to ideal one.",
  "pages-my-profile.profile_updating.welcome_guide.summary.see_example": "See an example",
  "pages-my-profile.profile_updating.steps_guide.title_1": "Start here creating your full skills profile",
  "pages-my-profile.profile_updating.steps_guide.title_2": "Or complete it in parts",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.title": "Skills Profile",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.content": "Select the 12 skills most relevant among all the skills you have or you would want to.",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.select": "Select skills",
  "pages-my-profile.profile_updating.steps_guide.current_job.title": "Current Job",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_1": "Select the 6 skills most relevant in your current professional experience.",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_2": "Select from among all the skills that define your current position the 6 most relevant to you.",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_3": "And if you want it to be faster...",
  "pages-my-profile.profile_updating.steps_guide.current_job.select_current_job_skills": "Select current job skills",
  "pages-my-profile.profile_updating.steps_guide.current_job.get_linkedin_profile": "Get your LinkedIn profile",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.title": "Ideal Job",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.content_1": "Select the 6 skills you would like to develop for your ideal job.",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.content_2": "Define your ideal position and add all the skills you think are necessary to perform it. Then you can select the most important ones.",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.select_ideal_job_skills": "Select ideal job skills",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.go_to_my_ideal_job": "Go to My Ideal Job",
  "pages-my-profile.profile_updating.performance_graph.example.title": "Marketing profile example",
  "pages-my-profile.profile_updating.performance_graph.example.subtitle": "Here you can see Megan's skills.",
  "pages-my-profile.profile_updating.performance_graph.example.steps.1": "The 6 skills that match her current job (purple)",
  "pages-my-profile.profile_updating.performance_graph.example.steps.2": "The 6 skills she would like to develop (blue)",
  "pages-my-profile.profile_updating.performance_graph.example.steps.3": "The 12 skills that match her current experience (orange)",
  "pages-my-profile.profile_updating.performance_graph.example.content_1": "Whether you import them from Linkedin or create them manually, you can then select the 6 skills that best define your current and desired position for the future.",
  "pages-my-profile.profile_updating.performance_graph.example.content_2": "With this graph you can, at a glance, compare your current and future talent to manage your training and career plan accordingly. This also helps Human Resources managers to offer you the working conditions that best suit you or to detect, for example, undervalued or dissatisfied employees.",
  "pages-my-profile.profile_updating.performance_graph.example.content_3": "Help us to help you.",
  "pages-my-profile.profile_updating.performance_graph.example.profile.first_name": "Megan",
  "pages-my-profile.profile_updating.performance_graph.example.profile.last_name": "Barney",
  "pages-my-profile.profile_updating.performance_graph.example.profile.experience": "Digital marketing manager",
  "pages-my-profile.profile_updating.performance_graph.example.close": "Got it",
  "pages-my-profile.profile_updating.performance_graph.graph.series.1": "Current job skills",
  "pages-my-profile.profile_updating.performance_graph.graph.series.2": "Ideal job skills",
  "pages-my-profile.profile_updating.performance_graph.graph.series.3": "Current experience skills",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.0": "None",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.1": "Low",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.2": "Medium",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.3": "High",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.1": "Taking initiative",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.2": "Languages",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.3": "Analytical",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.4": "Creative",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.5": "Problem solving",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.6": "Positive attitude",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.7": "Supporting",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.8": "Empathizing",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.9": "Social Media",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.10": "Management",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.11": "Marketing",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.12": "Learning",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction": "Introduction",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_1": "To fill in your profile chart, you need to complete the 2 principal sections about yourself: Current Experience and Ideal Job. Once these sections are completed, you will have at your disposal all the skills you need to rate and build your spider visualization.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_2": "* Remember that you will always be able to edit your skills and revise them during all your growth and learning process.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills": "Select my Current Job Skills",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills.info": "Select a min. of 3 skills and a max. of 6 skills most important in my current job.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills": "Select my Ideal Job Skills",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills.info": "Select a min. of 3 skills and a max. of 6 skills you would like to develop for your ideal job.",
  "pages-my-profile.profile_updating.performance_graph.configurer.errors.skills_count": "It is required that you choose between 3 and 6 skills to build your performance graph.",
  "pages-my-profile.profile_updating.performance_graph.configurer.search.placeholder": "Search a skill",
  "pages-my-profile.profile_updating.performance_graph.configurer.current_job_requirements": "You must have a Current Job with at least 3 skills to be able to create this graph.",
  "pages-my-profile.profile_updating.performance_graph.configurer.go_to_current_job": "Go to Current Job",
  "pages-my-profile.profile_updating.performance_graph.configurer.ideal_job_requirements": "You must have an Ideal Job with at least 3 skills to be able to create this graph.",
  "pages-my-profile.profile_updating.performance_graph.configurer.go_to_ideal_job": "Go to Ideal Job",
  "pages-my-profile.dashboard.tab": "Dashboard",
  "pages.my_profile.title": "My Profile",
  "pages.my_profile.employee_groups.title": "Groups",
  "pages.my_profile.previous_experiences.title": "Previous Experiences",
  "pages.current_experience.title": "My Current Job",
  "pages.current_experience.about_me": "About Me",
  "pages.current_experience.experience": "Other Experience",
  "pages.current_experience.education": "Education",
  "pages.current_experience.languages": "Languages",
  "pages.current_experience.languages.edit": "Edit languages",
  "pages.current_experience.current_experience.title": "Current Position",
  "pages.current_experience.current_experience.general_skills": "General Skills",
  "pages.current_experience.current_experience.language_skills": "Language Skills",
  "pages.current_experience.goals_challenges.title": "My Goals & Challenges",
  "pages.current_experience.linkedin.import": "Import my LinkedIn profile",
  "pages.current_experience.linkedin.title": "Import your LinkedIn profile",
  "pages.current_experience.linkedin.content.instructions.title": "Follow the instructions below to import your LinkedIn profile.",
  "pages.current_experience.linkedin.content.instructions.step.1": "<span class=\"text text--bold\">Click this link</span> to access to your LinkedIn profile (log in if it's necessary): ",
  "pages.current_experience.linkedin.content.instructions.step.2": "<span class=\"text text--bold\">Configure the content you want to import</span>. LinkedIn offers you some alternatives.",
  "pages.current_experience.linkedin.content.instructions.step.3": "Click the button <span class=\"text text--bold\">Request file</span> (the download will be ready in about <span class=\"text text--bold\">24 hours</span>).",
  "pages.current_experience.linkedin.content.instructions.step.4": "Two <span class=\"text text--bold\">emails</span> will send to you with the instructions you have to follow. The <span class=\"text text--bold\">first one</span> contents the information you need to upload here. The second one is about your LinkedIn activity and account history, and we don't need it.",
  "pages.current_experience.linkedin.content.instructions.step.5": "<span class=\"text text--bold\">Download</span> the file.",
  "pages.current_experience.linkedin.content.instructions.step.6": "<span class=\"text text--bold\">Click the button bellow</span> and select the .zip file downloaded from your computer.",
  "pages.current_experience.linkedin.content.instructions.step.7": "<span class=\"text text--bold\">And that's all</span>. If everything goes well, all your data will be charged here. Check if your current experience is ok. If not, you can edit it in LinkedIn and repeat the operation (recommended) or edit it inside this application by click in the button Edit, next to each experience.",
  "pages.current_experience.linkedin.content.upload": "Browse to upload the LinkedIn profile",
  "pages.current_experience.linkedin.successfully_imported_profile": "Profile successfully imported",
  "pages.current_experience.linkedin.reminder.content": "Check your positions dates and introduce the level of your skills, and lastly define if your educations have been online or on site.",
  "pages.current_experience.tip.content": "In this section it is important that you upload your curriculum, the experience will be more personalized if we know the starting point. On the other hand, you must differentiate between the skills you have generated during your professional life and the skills learned in your current position when filling in the fields.",
  "pages.current_experience.skills": "Skills",
  "pages.current_experience.skills.management": "Management Skills",
  "pages.current_experience.skills.general": "General",
  "pages.current_experience.skills.edit": "Edit general skills",
  "pages.current_experience.language.edit": "Edit language skills",
  "pages.current_experience.confirm-delete-on-graph.title": "Delete Skill being used on Performance Graph",
  "pages.current_experience.confirm-delete-on-graph.message": "You are trying to delete a skill that is being used on the Performance Graph. If after deleting this skill there are less than 3 skills the graph will not be created.",
  "pages.current_experience.present": "Present",
  "pages.current_experience.edit.about.me": "Tell me something about you",
  "pages.current_experience.form.about.me.fields.name.label": "About you",
  "pages.current_experience.form.about.me.fields.name.placeholder": "Tell me something about you",
  "pages.current_experience.form.curriculum.upload": "Upload your curriculum",
  "pages.current_experience.form.curriculum.delete.prompt.title": "Delete curriculum",
  "pages.current_experience.form.curriculum.delete.prompt.content": "You are going to delete curriculum. This action cannot be undone. Are you sure you want to continue?",
  "pages.current_experience.form.experience.edit_current": "Edit your current position",
  "pages.current_experience.form.experience.edit_other": "Edit your job position",
  "pages.current_experience.form.experience.create_current": "Add current job",
  "pages.current_experience.form.experience.create_other": "Add new experience",
  "pages.current_experience.form.experience.fields.title.label": "Title",
  "pages.current_experience.form.experience.fields.title.placeholder": "Enter the job position",
  "pages.current_experience.form.experience.fields.description.label": "Description of the job",
  "pages.current_experience.form.experience.fields.description.placeholder": "Enter the job description",
  "pages.current_experience.form.experience.fields.company.label": "Company",
  "pages.current_experience.form.experience.fields.company.placeholder": "Enter the company",
  "pages.current_experience.form.experience.fields.location.label": "Location",
  "pages.current_experience.form.experience.fields.location.placeholder": "Enter the job location",
  "pages.current_experience.form.experience.fields.fromDate.label": "Start",
  "pages.current_experience.form.experience.fields.toDate.label": "End",
  "pages.current_experience.form.experience.fields.currentJob.label": "Current Job",
  "pages.current_experience.form.experience.delete.title": "Delete experience",
  "pages.current_experience.form.experience.delete.content": "You are going to delete the selected experience. This action cannot be undone. Are you sure you want to continue?",
  "pages.current_experience.form.education.edit": "Edit your education",
  "pages.current_experience.form.education.create": "Add new education",
  "pages.current_experience.form.education.fields.title.label": "Education title",
  "pages.current_experience.form.education.fields.title.placeholder": "Enter a title",
  "pages.current_experience.form.education.fields.institution.label": "Institution",
  "pages.current_experience.form.education.fields.institution.placeholder": "Enter an institution",
  "pages.current_experience.form.education.fields.description.label": "Description",
  "pages.current_experience.form.education.fields.description.placeholder": "Enter a description",
  "pages.current_experience.form.education.fields.location.label": "Location",
  "pages.current_experience.form.education.fields.location.placeholder": "Enter a location",
  "pages.current_experience.form.education.fields.fromDate.label": "From",
  "pages.current_experience.form.education.fields.toDate.label": "To",
  "pages.current_experience.form.education.fields.inProgressEducation.label": "In progress",
  "pages.current_experience.form.education.delete.title": "Delete education",
  "pages.current_experience.form.education.delete.content": "You are going to delete the selected education. This action cannot be undone. Are you sure you want to continue?",
  "pages.guest.create_company_from_azure_ad.information.title": "Registration from Azure Active Directory (Ad) Guide",
  "pages.guest.create_company_from_azure_ad.information.content": "<h3>To generate the Service Principal, please go to Azure Ad, and follow next steps:</h3><ol class=\"margin-l--m\" style=\"list-style:inside;list-style-position:outside\"><li class=\"margin-v--xs\">Go to Azure Active Directory and create a new Application in App Registration.</li><li class=\"margin-v--xs\">Enter a name at the 'Name' field.\n\n</li><li class=\"margin-v--xs\">In Supported account types select the 'Single Tenant' option and click the 'Register' button.</li><li class=\"margin-v--xs\">When your App is already created, go to 'API Permissions' section and add a new one of type 'User.Read.All'.</li><li class=\"margin-v--xs\">Now you need to add a Client Secret, we recommend 24 months of expiration date. Now copy the Client Secret.</li></ol><h3>Steps to connect with Fleebe:</h3><ol class=\"margin-l--m\" style=\"list-style:inside;list-style-position:outside\"><li class=\"margin-v--xs\">Copy the 'Application Client ID' from the previous step to the 'Service Principal Client Id' field.</li><li class=\"margin-v--xs\">Copy the 'Client Secret' to the 'Service Principal Client Secret' field.</li><li class=\"margin-v--xs\">Click the 'Validate' button, and you should see the number of users of your AD.</li></ol>",
  "pages.guest.create_company_from_azure_ad.form.fields.tenant_id.label": "Tenant Id",
  "pages.guest.create_company_from_azure_ad.form.fields.purchaseSubscriptionId.label": "Purchase Subscription ID",
  "pages.guest.create_company_from_azure_ad.form.fields.client_id.label": "Service principal client Id",
  "pages.guest.create_company_from_azure_ad.form.fields.client_id.placeholder": "Enter the service principal Id",
  "pages.guest.create_company_from_azure_ad.form.fields.client_secret.label": "Service principal client Secret",
  "pages.guest.create_company_from_azure_ad.form.fields.client_secret.placeholder": "Enter the service principal secret",
  "pages.guest.create_company_from_azure_ad.form.fields.total_users.label": "The following amount of users will be imported from Azure Active Directory after the sign up process",
  "pages.guest.create_company_from_azure_ad.form.buttons.validate": "Validate",
  "pages.guest.create_company_from_azure_ad.form.buttons.signup": "Sign up",
  "pages.guest.create_company_from_azure_ad.error_modal.title": "Subscription could not be completed",
  "pages.guest.create_company_from_azure_ad.error_modal.description": "We are sorry, the subscription could not be completed because the number of purchased users ({purchasedUsers}) does not match the number of users found ({totalUsers}) in Azure Active Directory. Please restart the purchase process.",
  "pages.guest.create_company_from_azure_ad.error_modal.button": "Restart purchase in Microsoft AppSource",
  "pages.accounts.logout.title": "Your session ended",
  "pages.accounts.logout.description": "You finished your session. To come back again, click on the button.",
  "pages.accounts.logout.signin": "Sign in Fleebe",
  "pages.accounts.admin_required.title": "Not enough permissions",
  "pages.accounts.admin_required.description": "You need to be administrator to enter the requested page. To continue using the site, click on the button.",
  "pages.accounts.admin_required.continue": "Go back to Fleebe",
  "pages.accounts.tenant_unauthorized.title": "Tenant unauthorized",
  "pages.accounts.tenant_unauthorized.description": "The tenant you are using to log in is not allowed in the application. Please confirm you are registered to use Fleebe and continue.",
  "pages.accounts.tenant_unauthorized.continue": "Go back to Fleebe",
  "pages.accounts.user_unauthorized.title": "User unauthorized",
  "pages.accounts.user_unauthorized.description": "The account you are using to log in is not allowed in the application. Please confirm you are registered to use Fleebe and continue. If you have multiple accounts, try logging out and sign in again.",
  "pages.accounts.user_unauthorized.logout": "Log out",
  "pages.admin.dashboard.current_job.title": "Current Job",
  "pages.admin.dashboard.ideal_job.title": "Ideal Job",
  "pages.admin.dashboard.title": "Dashboard",
  "pages.admin.dashboard.percentage_employee_answered": "% employee answered",
  "pages.admin.dashboard.welcome": "Welcome to Fleebe",
  "pages.admin.dashboard.welcome.tip.title": "Start wherever you want",
  "pages.admin.dashboard.welcome.tip.content": "Start the experience with your AI assistant whenever you want by filling out the four general cards below these infobox.",
  "pages.admin.dashboard.welcome.shortcuts.profile.title": "Complete your organization profile",
  "pages.admin.dashboard.welcome.shortcuts.profile.content": "Fill in all your company`s corporate data with its main characteristics: How big your organization is? where it's located?",
  "pages.admin.dashboard.welcome.shortcuts.profile.button": "Complete company profile",
  "pages.admin.dashboard.welcome.shortcuts.skill.title": "Create the skills your organization needs",
  "pages.admin.dashboard.welcome.shortcuts.skill.content": "Your organization is different and unique, therefore, it has specific needs that you know as administrator. Create them to start looking for talent.",
  "pages.admin.dashboard.welcome.shortcuts.skill.button": "Create skills",
  "pages.admin.dashboard.welcome.shortcuts.question.title": "Create a form to get feedback from employees",
  "pages.admin.dashboard.welcome.shortcuts.question.content": "Your employees are talented, therefore their opinion is valuable. Create here a form to get your feedback.",
  "pages.admin.dashboard.welcome.shortcuts.question.button": "Create form",
  "pages.admin.dashboard.welcome.shortcuts.goal.title": "Create goals and challenges for your organization",
  "pages.admin.dashboard.welcome.shortcuts.goal.content": "Keep your teams and employees motivated, create common objectives and goals bringing value to the company.",
  "pages.admin.dashboard.welcome.shortcuts.goal.button": "Create goals & challenges",
  "pages.admin.dashboard.search.introduction": "The new AI assistance to help your employees learning path to their own preferences and lifestyle",
  "pages.admin.dashboard.search.placeholder": "Search for employees",
  "pages.admin.dashboard.current_experience.title": "Current Experience",
  "pages.admin.dashboard.current_experience.about_me.curriculum": "Curriculum",
  "pages.admin.dashboard.current_experience.about_me.no_content_yet": "Employee has not provided information yet",
  "pages.admin.dashboard.current_experience.experience.no_content_yet": "Employee has not provided experience information yet",
  "pages.admin.dashboard.current_experience.education.no_content_yet": "Employee has not provided education information yet",
  "pages.admin.dashboard.current_experience.languages.no_content_yet": "Employee has not provided languages yet",
  "pages.admin.dashboard.current_experience.general_skills.no_content_yet": "Employee has not provided general skills yet",
  "pages.admin.dashboard.my_ideal_job.no_content_yet": "Employee has not provided the ideal job information yet",
  "pages.admin.dashboard.ai_assistant.title": "AI Assistant",
  "pages.admin.dashboard.ai_assistant.description": "These are the responses provided by the employee",
  "pages.admin.dashboard.interests.title": "Interests",
  "pages.admin.dashboard.interests.interests.no_content_yet": "Employee has not provided interest information yet",
  "pages.admin.dashboard.interests.idealLearning.no_content_yet": "Employee has not provided learning information yet",
  "pages.admin.dashboard.interests.hobbies.no_content_yet": "Employee has not provided hobbies yet",
  "pages.admin.ai_assistant.title": "AI Assistant",
  "pages.admin.ai_assistant.create_new_survey_form.title": "Create new survey form",
  "pages.admin.ai_assistant.create_new_survey_form.description": "Configure the questions for your company and choose which of them should be visible to your employees",
  "pages.admin.ai_assistant.create_new_survey_form.new_question": "Add new question",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.title": "Level",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.description": "Use this component for the employee to select a level of education, experience or talent, etc. quickly.",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.guide": "Try to make the statement as simple as possible so that the answers are not ambiguous and the information provided really adds value. Establish clear and well contrasted levels so that there are no doubts in the answer.",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.input.title": "Enter the question statement",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.title": "Single Selection",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.description": "Use this component to select a single option from several available ones.",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.guide": "Try to make the statement as simple as possible so that the answers are not ambiguous and the information provided really adds value. Establish clear and well contrasted options so that there are no doubts in the answer.",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.input.title": "Enter the question statement",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.title": "Multiple Selection",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.description": "Use this component to select multiple correct options.",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.guide": "Try to make the statement as simple as possible so that the answers are not ambiguous and the information provided really adds value. Establish clear and well contrasted options so that there are no doubts in the answer.",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.input.title": "Enter the question statement",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.title": "Long Open Answer",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.description": "Use this type of components for open answers where the employee can make longer descriptions.",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.guide": "Try to make the statement as clear and simple as possible so that the answers are not ambiguous and the information provided really adds value.",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.input.title": "Enter the question statement",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.title": "Short Open Answer",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.description": "Use this component for short answers.",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.guide": "Try to make the statement as clear and simple as possible so that the answers are not ambiguous and the information provided really adds value.",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.input.title": "Enter the question statement",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.title": "Tags",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.description": "Use this component when the employee has to enter answers in the form of labels.",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.guide": "Try to make the statement as simple as possible so that the answers are not ambiguous and the information provided really adds value. Establish clear and well contrasted options so that there are no doubts in the answer.",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.input.title": "Enter the question statement",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.select_question_type": "Select question type",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.configure_question": "Configure question",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.save_question": "Save question",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.options.title": "Enter the answer options",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.options.description": "You need at least two options but you can add all the ones you need.",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.option.title": "Option {optionIndex}",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.option.placeholder": "Option title",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.title": "Enter the answer levels",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.low_level": "Low level",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.medium_level": "Medium level",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.high_level": "High level",
  "pages.admin.ai_assistant.create_new_survey_form.preview.title": "Preview",
  "pages.admin.ai_assistant.create_new_survey_form.preview.description": "Question has errors in configuration. Go to previous step and configure it properly.",
  "pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample1": "tag 1",
  "pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample2": "tag 2",
  "pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample1": "option 1",
  "pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample2": "option 2",
  "pages.admin.ai_assistant.kpi_tip.title": "Create KPIs",
  "pages.admin.ai_assistant.kpi_tip.subtitle": "You can create KPIs of the sent questionnaires in order to visualize the range of responses. Select the chart icon to see the results for each questions you are looking for.",
  "pages.admin.ai_assistant.questions.title": "Survey questions",
  "pages.admin.ai_assistant.questions.seeAllQuestions": "See all questions",
  "pages.admin.ai_assistant.questions.search": "Search in questions",
  "pages.admin.ai_assistant.questions.created_questions": "Created questions",
  "pages.admin.ai_assistant.questions.sent_questions": "Sent questions",
  "pages.admin.ai_assistant.questions.send_questions": "Send questions",
  "pages.admin.ai_assistant.questions.do_not_send_questions": "Don't send questions",
  "pages.admin.ai_assistant.questions.add_new_question": "Add new question",
  "pages.admin.ai_assistant.questions.preview": "Question preview",
  "pages.admin.ai_assistant.questions.grid.number": "#",
  "pages.admin.ai_assistant.questions.grid.answeredPercentage": "Participation(%)",
  "pages.admin.ai_assistant.questions.grid.groups": "Groups",
  "pages.admin.ai_assistant.questions.grid.groups.tooltip.more_groups": "{n} more",
  "pages.admin.ai_assistant.questions.grid.groups.tooltip.groups": "{n} group | {n} groups",
  "pages.admin.ai_assistant.questions.grid.question": "Question",
  "pages.admin.ai_assistant.questions.grid.date": "Date",
  "pages.admin.ai_assistant.questions.grid.no_results": "There are no questions with this filter",
  "pages.admin.ai_assistant.questions.grid.delete.title": "Delete question",
  "pages.admin.ai_assistant.questions.grid.delete.description": "Your are going to delete the question '{question}'. Are you sure?",
  "pages.admin.ai_assistant.questions.grid.show_in_dashboard": "Show in Dashboard",
  "pages.admin.ai_assistant.questions.grid.remove__from_dashboard": "Remove from Dashboard",
  "pages.admin.ai_assistant.questions.grid.descend": "Descend",
  "pages.admin.ai_assistant.questions.grid.ascend": "Ascend",
  "pages.admin.ai_assistant.questions.questions_with_no_answers": "This question has not been answered yet",
  "pages.admin.ai_assistant.questions.employee_answered": "Participation",
  "pages.admin.ai_assistant.questions.othersSummary": "Others",
  "pages.admin.ai_assistant.questions.noContentYet": "No content yet",
  "pages.admin.ai_assistant.questions.allQuestionsPublished": "All questions have already been published.",
  "pages.admin.company_goals.application_closed": "Applications closed",
  "pages.admin.company_goals.draft": "Draft",
  "pages.admin.company_goals.application_open": "Open for application",
  "pages.admin.company_goals.title": "Corporate Goals",
  "pages.admin.company_goals.create.public": "Create a public goal to applicants",
  "pages.admin.company_goals.create.public.description": "We launched this new goal with the aim of developing the full potential of each of our employees while focusing on their needs and abilities. Be the one who draws up the transformation plan according to your personal and professional goals.",
  "pages.admin.company_goals.create.new_public_goal": "Create a public goal",
  "pages.admin.company_goals.create.private": "Create a private goal and select a team",
  "pages.admin.company_goals.create.private.description": "We've identified the skills you need to advance your career. This proposed goal will allow you to develop or strengthen them. Continuous transformation is essential for the evolution of your professional career.",
  "pages.admin.company_goals.create.new_private_goal": "Create a private goal",
  "pages.admin.company_goals.search.introduction": "New goals and challenges",
  "pages.admin.company_goals.search.placeholder": "Search goals for team",
  "pages.admin.company_goals.search.options.view_all": "All",
  "pages.admin.company_goals.search.options.view_visibility": "Visibility",
  "pages.admin.company_goals.search.options.view_public": "Public",
  "pages.admin.company_goals.search.options.view_private": "Private",
  "pages.admin.company_goals.search.options.view_application_phase": "Application phase",
  "pages.admin.company_goals.search.options.view_application_phase_open": "Open",
  "pages.admin.company_goals.search.options.view_application_phase_closed": "Closed",
  "pages.admin.company_goals.search.options.view_finished": "Finished",
  "pages.admin.company_goals.search.options.view_draft": "Draft",
  "pages.admin.company_goals.search.options.view_launched": "Launched",
  "pages.admin.company_goals.search.options.view_published": "Published",
  "pages.admin.company_goals.search.options.view_status": "Status",
  "pages.admin.company_goals.search.order.start_date_asc": "Start date (asc)",
  "pages.admin.company_goals.search.order.start_date_desc": "Start date (desc)",
  "pages.admin.company_goals.search.order.end_date_asc": "End date (asc)",
  "pages.admin.company_goals.search.order.end_date_desc": "End date (desc)",
  "pages.admin.company_goals.search.order.title_asc": "Title (asc)",
  "pages.admin.company_goals.search.order.title_desc": "Title (desc)",
  "pages.admin.company_goals.new_goal": "Add new goal",
  "pages.admin.company_goals.last_updated": "Last update, {date}",
  "pages.admin.company_goals.goal_dates": "Goal dates: {start} - {end}",
  "pages.admin.company_goals.goal": "Goal team",
  "pages.admin.company_goals.save": "Save team and goal",
  "pages.admin.company_goals.skills": "{number} skills",
  "pages.admin.company_goals.education": "{number} education",
  "pages.admin.company_goals.languages": "{number} languages",
  "pages.admin.company_goals.delete_goal.title": "Delete goal: {name}",
  "pages.admin.company_goals.delete_goal.description": "You are going to delete the selected goal <span class=\"text text--bold\">\"{name}\"</span>. All the progress and information about this one will disappear and this action cannot be undone. Are you sure you want to continue?",
  "pages.admin.company_goals.delete_goal.successfully_deleted": "Goal was successfully deleted",
  "pages.admin.company_goals.add_new_goal.title": "New goal",
  "pages.admin.company_goals.add_new_goal.fields.title.label": "Title",
  "pages.admin.company_goals.add_new_goal.fields.title.placeholder": "Enter the title of the goal or challenge",
  "pages.admin.company_goals.add_new_goal.fields.description.label": "Description of the goal",
  "pages.admin.company_goals.add_new_goal.fields.description.placeholder": "Type here a brief description",
  "pages.admin.company_goals.add_new_goal.fields.link.label": "Link with more information",
  "pages.admin.company_goals.add_new_goal.fields.link.placeholder": "Add a link with more information",
  "pages.admin.company_goals.add_new_goal.fields.number_of_people_needed.label": "Number of people needed",
  "pages.admin.company_goals.add_new_goal.fields.country.label": "Country",
  "pages.admin.company_goals.add_new_goal.fields.country.placeholder": "Enter country",
  "pages.admin.company_goals.add_new_goal.fields.city.label": "City",
  "pages.admin.company_goals.add_new_goal.fields.city.placeholder": "Enter city",
  "pages.admin.company_goals.add_new_goal.fields.application_closing.label": "Application closing",
  "pages.admin.company_goals.add_new_goal.fields.is_open.label": "Goal open for applications",
  "pages.admin.company_goals.add_new_goal.fields.start.label": "Start",
  "pages.admin.company_goals.add_new_goal.fields.end.label": "End",
  "pages.admin.company_goals.add_new_goal.fields.status.label": "Goal open for applications",
  "pages.admin.company_goals.add_new_goal.fields.picture.label": "Choose a picture for the cover",
  "pages.admin.company_goals.add_new_goal.fields.picture.upload": "Upload picture",
  "pages.admin.company_goals.add_new_goal.fields.skills_needed": "Skills needed",
  "pages.admin.company_goals.add_new_goal.path.overview": "Overview",
  "pages.admin.company_goals.add_new_goal.path.general_skills": "General skills",
  "pages.admin.company_goals.add_new_goal.path.general_skills.description": "Please choose the General Skills required for the goal",
  "pages.admin.company_goals.add_new_goal.path.language_skills": "Language skills",
  "pages.admin.company_goals.add_new_goal.path.language_skills.description": "Please choose the Language Skills required for the goal",
  "pages.admin.company_goals.add_new_goal.save": "Publish",
  "pages.admin.company_goals.add_new_goal.successfully_created": "Goal has been successfully created",
  "pages.admin.company_goals.add_new_goal.successfully_updated": "Goal has been successfully updated",
  "pages.admin.company_goals.add_new_goal.confirm_publish.title": "Confirm publishing",
  "pages.admin.company_goals.add_new_goal.confirm_publish.description": "You are going to publish the goal. After this point, you won't be able to edit some of the fields. Please continue only if you are sure goal is ready.",
  "pages.admin.company_goals.edit_goal.fields.title.label": "Title",
  "pages.admin.company_goals.edit_goal.fields.title.placeholder": "Enter the title of the goal or challenge",
  "pages.admin.company_goals.edit_goal.fields.description.label": "Description of the goal",
  "pages.admin.company_goals.edit_goal.fields.description.placeholder": "Type here a brief description",
  "pages.admin.company_goals.edit_goal.fields.number_of_people_needed.label": "Number of people needed",
  "pages.admin.company_goals.edit_goal.fields.country.label": "Country",
  "pages.admin.company_goals.edit_goal.fields.city.label": "City",
  "pages.admin.company_goals.edit_goal.fields.link.label": "Link with more information",
  "pages.admin.company_goals.edit_goal.fields.link.placeholder": "Add a link with more information",
  "pages.admin.company_goals.edit_goal.fields.application_closing.label": "Application closing",
  "pages.admin.company_goals.edit_goal.fields.is_open.label": "Goal is open for applications",
  "pages.admin.company_goals.edit_goal.fields.is_not_open.label": "Goal is not open for applications",
  "pages.admin.company_goals.edit_goal.fields.start.label": "Start",
  "pages.admin.company_goals.edit_goal.fields.end.label": "End",
  "pages.admin.company_goals.edit_goal.save": "Save",
  "pages.admin.company_goals.edit_goal.successfully_edited": "Goal has been successfully edited",
  "pages.admin.company_goals.detail_goals.more_information": "More information",
  "pages.admin.company_goals.detail_goals.progress": "Team's goal progress",
  "pages.admin.company_goals.detail_goals.team_skills.title": "Percentage of adaptation to the objective",
  "pages.admin.company_goals.detail_goals.team_skills.skills": "Skills",
  "pages.admin.company_goals.detail_goals.team_skills.skills.description": "More than 80% of skills is considered a good ratio to achieve the proposed objectives. Below 50% it is advisable to recruit or replace members with others with the specific skills needed.",
  "pages.admin.company_goals.goal_detail.add_team_member": "Add a team member from the applicants list",
  "pages.admin.company_goals.goal_detail.ai_selected_candidate": "The AI have chosen a team automatically but you can edit it manually. The goal won't start until you validate the team.",
  "pages.admin.company_goals.goal_detail.ai_will_filter_candidates": "Just finished the application date, the AI engine will filter the applicants automatically to select the ones who are the best choice. So then, you will see only the applicants number needed to create the team for the goal.",
  "pages.admin.company_goals.goal_detail.application_phase.tag.closed": "Closed",
  "pages.admin.company_goals.goal_detail.application_phase.tag.draft": "Draft",
  "pages.admin.company_goals.goal_detail.application_phase.tag.open": "Still open",
  "pages.admin.company_goals.goal_detail.city": "City",
  "pages.admin.company_goals.goal_detail.confirm_launch.description": "You are going to launch the goal. Please continue only if you are sure the goal is ready to be launched.",
  "pages.admin.company_goals.goal_detail.confirm_launch.title": "Confirm launching",
  "pages.admin.company_goals.goal_detail.confirm_set_result.description": "You are going to set the goal result. Please, continue only if you are sure the result is the correct one.",
  "pages.admin.company_goals.goal_detail.confirm_set_result.title": "Confirm {result} result",
  "pages.admin.company_goals.goal_detail.country": "Country",
  "pages.admin.company_goals.goal_detail.dates_candidates_application": "Dates for candidates application",
  "pages.admin.company_goals.goal_detail.dates.application": "application",
  "pages.admin.company_goals.goal_detail.dates.end": "Goal {title} end date",
  "pages.admin.company_goals.goal_detail.dates.start": "Goal {title} start date",
  "pages.admin.company_goals.goal_detail.dates.tag.closed": "Closed",
  "pages.admin.company_goals.goal_detail.dates.tag.draft": "Draft",
  "pages.admin.company_goals.goal_detail.dates.tag.in_progress": "In progress",
  "pages.admin.company_goals.goal_detail.dates.tag.stand_by": "Stand by",
  "pages.admin.company_goals.goal_detail.dates.tag.waiting_candidates": "Waiting for candidates",
  "pages.admin.company_goals.goal_detail.dates": "Dates for goal achievement",
  "pages.admin.company_goals.goal_detail.description": "Description",
  "pages.admin.company_goals.goal_detail.edit_goal": "Edit goal",
  "pages.admin.company_goals.goal_detail.employee_full_name": "{firstName} {lastName}",
  "pages.admin.company_goals.goal_detail.employees_applying": "Candidates who are applying",
  "pages.admin.company_goals.goal_detail.employees_modal.applicants": "{number} applicants",
  "pages.admin.company_goals.goal_detail.employees_modal.list_title.employee": "Employee",
  "pages.admin.company_goals.goal_detail.employees_modal.list_title.match_with_goal": "Matching with the goal",
  "pages.admin.company_goals.goal_detail.employees_modal.search.placeholder": "Search an employee",
  "pages.admin.company_goals.goal_detail.employees_modal.title": "Find people for the goal",
  "pages.admin.company_goals.goal_detail.employees_on_charge": "Employees on charge of this goal",
  "pages.admin.company_goals.goal_detail.employees_selected": "Selected employees",
  "pages.admin.company_goals.goal_detail.employees.delete.content": "You are going to remove this employee for the goal's team. You can select it for another goal or add it again later. Are you sure you want to continue?",
  "pages.admin.company_goals.goal_detail.employees.delete.title": "Remove employee",
  "pages.admin.company_goals.goal_detail.employees": "Employees",
  "pages.admin.company_goals.goal_detail.launch_goal": "Launch goal",
  "pages.admin.company_goals.goal_detail.launched_goal": "Launched",
  "pages.admin.company_goals.goal_detail.link": "More information",
  "pages.admin.company_goals.goal_detail.no_assigned_yet": "No employees assigned yet",
  "pages.admin.company_goals.goal_detail.open_for_applications": "Goal open for applications",
  "pages.admin.company_goals.goal_detail.people_needed": "Number of people needed",
  "pages.admin.company_goals.goal_detail.progress.label": "Team's goal progress",
  "pages.admin.company_goals.goal_detail.progress.no_progression.application_open": "The candidate application phase is still open",
  "pages.admin.company_goals.goal_detail.progress.no_progression.draft": "Goal is still in draft phase",
  "pages.admin.company_goals.goal_detail.progress.no_progression.team_built": "The team is built but goal has not started yet",
  "pages.admin.company_goals.goal_detail.progress.no_progression.team_not_built": "The team has not been built yet",
  "pages.admin.company_goals.goal_detail.progress.title": "Progress",
  "pages.admin.company_goals.goal_detail.published_for_applicants": "Published for applicants",
  "pages.admin.company_goals.goal_detail.result_failed": "The goal was a failure",
  "pages.admin.company_goals.goal_detail.result_not_set": "The goal has ended. Please select the global evaluation that best fits the final result of the goal.",
  "pages.admin.company_goals.goal_detail.result_succeeded": "The goal was a success",
  "pages.admin.company_goals.goal_detail.skills_needed.no_content_yet": "You have not provided information yet",
  "pages.admin.company_goals.goal_detail.skills_needed": "Skills needed",
  "pages.admin.company_goals.goal_detail.successfully_launched": "Goal has been successfully launched",
  "pages.admin.company_goals.goal_detail.successfully_result_set": "Goal result has been set successfully",
  "pages.admin.company_goals.goal_detail.team_skills": "Team skills",
  "pages.admin.internal_promotions.title": "Internal Promotions",
  "pages.admin.company_settings.title": "Company Settings",
  "pages.admin.company_settings.save": "Save profile",
  "pages.admin.company_settings.general_info.title": "General Information",
  "pages.admin.company_settings.general_info.form.name": "Name",
  "pages.admin.company_settings.general_info.form.description": "Description",
  "pages.admin.company_settings.general_info.form.feedbackEmail": "Feedback email",
  "pages.admin.company_settings.general_info.successfully_updated": "General company information successfully updated",
  "pages.admin.company_settings.general_info.chatbot.step": "Step",
  "pages.admin.company_settings.general_info.chatbot.teams.title": "Mr. Fleebe for Teams",
  "pages.admin.company_settings.general_info.chatbot.teams.download.label": "Mr. Chatbot",
  "pages.admin.company_settings.general_info.chatbot.teams.download.text": "Download",
  "pages.admin.company_settings.general_info.chatbot.teams.step.1": "Go to <a href='https://admin.teams.microsoft.com' class='text--link' target='blank'>Teams Administration</a>",
  "pages.admin.company_settings.general_info.chatbot.teams.step.2": "Upload the downloaded application.",
  "pages.admin.company_settings.general_info.chatbot.teams.step.3": "Assign permissions",
  "pages.admin.company_settings.general_info.chatbot.teams.step.4": "Take it easy... The chatbot needs time to refresh and become avaiable.",
  "pages.admin.company_settings.general_info.chatbot.slack.title": "Mr. Fleebe for Slack",
  "pages.admin.company_settings.general_info.chatbot.slack.add": "Add to Slack",
  "pages.admin.company_settings.general_info.chatbot.slack.step.1": "Click on the 'Add to Slack' button.",
  "pages.admin.company_settings.general_info.chatbot.slack.step.2": "If the slack workspace dropdown is displayed, select the desired one.",
  "pages.admin.company_settings.general_info.chatbot.slack.step.3": "Accept the conditions",
  "pages.admin.company_settings.azure_info.title": "Azure Information",
  "pages.admin.company_settings.azure_info.form.service_principal_id": "Service Principal Id",
  "pages.admin.company_settings.azure_info.form.service_principal_secret": "Service Principal Secret",
  "pages.admin.company_settings.azure_info.form.service_principal_secret.placeholder": "**************************",
  "pages.admin.company_settings.azure_info.form.tenant_id": "Tenant Id",
  "pages.admin.company_settings.azure_info.form.purchase_subscription_id": "Purchase Subscription Id",
  "pages.admin.company_settings.azure_info.successfully_updated": "Azure company information successfully updated",
  "pages.admin.skills.grid.name": "Name",
  "pages.admin.skills.grid.description": "Description",
  "pages.admin.skills.grid.status": "Status",
  "pages.admin.skills.grid.type": "Type",
  "pages.admin.skill_management.search_placeholder": "Search skills",
  "pages.admin.skill_management.new_skill": "Add new skill",
  "pages.admin.skill_management.filters.status.all": "All",
  "pages.admin.skill_management.filters.status.Pending": "Moderation pending",
  "pages.admin.skill_management.filters.status.Approved": "Approved",
  "pages.admin.skill_management.filters.type.all": "All",
  "pages.admin.skill_management.filters.type.General": "General",
  "pages.admin.skill_management.filters.type.Language": "Language",
  "pages.admin.skill_management.search_show_all_skills": "All",
  "pages.admin.skill_management.skill_requester_name": "Requester",
  "pages.admin.skill_management.skill_moderator_name": "Moderator",
  "pages.admin.skill_management.grid.no_results": "There are no skills with this filter",
  "pages.admin.skill_management.skill_status.Pending": "Pending Moderation",
  "pages.admin.skill_management.skill_status.Approved": "Approved",
  "pages.admin.skill_management.edit_skill": "Edit Skill",
  "pages.admin.skill_management.delete": "Delete skill",
  "pages.admin.skill_management.moderate": "Moderate",
  "pages.admin.skill_management.confirm_to_delete": "If you delete the skill, it will be deleted the skill and the employee request. Do you want to proceed?",
  "pages.admin.skill_management.validated_effects.title": "Skill validated",
  "pages.admin.skill_management.validated_effects.text": "The skill has been validated. From now, employees can select it to complete their curriculum",
  "pages.admin.skill_management.noContentYet": "No content yet",
  "pages.admin.talent_detection.chart.no_content": "We don't have enough information to create the chart",
  "pages.admin.talent_detection.chart.by_age": "By age",
  "pages.admin.talent_detection.chart.by_group": "By group",
  "pages.admin.talent_detection.employee_motivation.search.placeholder": "Search",
  "pages.admin.talent_detection.employee_motivation.title.employee": "Employee",
  "pages.admin.talent_detection.employee_motivation.title.motivation": "Motivation",
  "pages.admin.talent_detection.employee_motivation.title": "Employees motivational range",
  "pages.admin.talent_detection.employees.no_content": "There's no selected content yet",
  "pages.admin.talent_detection.employees_group.title": "{n} employee {group} in {category} | {n} employees {group} in {category}",
  "pages.admin.talent_detection.employees_age.title.age_from": "{n} employee in {category} (from {from} years old) | {n} employees in {category} (from {from} years old)",
  "pages.admin.talent_detection.employees_age.title.age_to": "{n} employee in {category} (to {to} years old) | {n} employees in {category} (to {to} years old)",
  "pages.admin.talent_detection.employees_age.title.age_range": "{n} employee in {category} (from {from} to {to} years old) | {n} employees in {category} (from {from} to {to} years old)",
  "pages.admin.talent_detection.employees_age.title.age_all": "{n} employee in {category} | {n} employees in {category}",
  "pages.admin.talent_detection.search": "Search a hobby",
  "pages.admin.talent_detection.tip.content": "When chart is finally built, click on the colored areas to see how employees relate to the selected hobbies.",
  "pages.admin.talent_detection.tip.title": "Main hidden hobbies",
  "pages.admin.talent_detection.title": "Talent Detection",
  "pages.admin.talent_detection.view_motivation": "View all employees",
  "pages.admin.learning.title": "Learning in the Company",
  "pages.admin.learning.title.short": "Learning",
  "pages.admin.learning.search_placeholder": "Search trainings",
  "pages.admin.learning.create_new_learning_form.title": "Create the training your company needs",
  "pages.admin.learning.create_new_learning_form.description": "If it's necessary, you can also create courses that suit your company's needs.",
  "pages.admin.learning.create_new_learning_form.new_learning": "Add training",
  "pages.admin.learning.create_new_learning_form.successfully_created": "The training has been successfully created",
  "pages.admin.learning.form.create": "Add new training",
  "pages.admin.learning.form.edit": "Edit training",
  "pages.admin.learning.form.fields.title.label": "Title",
  "pages.admin.learning.form.fields.title.placeholder": "Title of the training",
  "pages.admin.learning.form.fields.description.label": "Description",
  "pages.admin.learning.form.fields.description.placeholder": "Type here",
  "pages.admin.learning.form.fields.startDate.label": "Start date",
  "pages.admin.learning.form.fields.endDate.label": "End date",
  "pages.admin.learning.form.fields.level.label": "Level",
  "pages.admin.learning.form.fields.duration.label": "Duration (hours)",
  "pages.admin.learning.form.fields.topics.label": "Topics (separated by commas)",
  "pages.admin.learning.form.fields.topics.placeholder": "Type topics",
  "pages.admin.learning.form.fields.onsiteInformation.label": "On-site information",
  "pages.admin.learning.form.fields.onsiteCountry.label": "Country",
  "pages.admin.learning.form.fields.onsiteCountry.placeholder": "Enter country",
  "pages.admin.learning.form.fields.onsiteCity.label": "City",
  "pages.admin.learning.form.fields.onsiteCity.placeholder": "Enter city",
  "pages.admin.learning.form.fields.onsiteAddress.label": "Address",
  "pages.admin.learning.form.fields.onsiteAddress.placeholder": "Enter address",
  "pages.admin.learning.form.fields.onsiteFloor.label": "Floor",
  "pages.admin.learning.form.fields.onsiteFloor.placeholder": "Enter floor",
  "pages.admin.learning.form.fields.onsiteRoom.label": "Room",
  "pages.admin.learning.form.fields.onsiteRoom.placeholder": "Enter room",
  "pages.admin.learning.form.fields.onsiteSchedule.label": "Schedule",
  "pages.admin.learning.form.fields.onsiteSchedule.placeholder": "Enter schedule",
  "pages.admin.learning.form.fields.onsiteStartTime.label": "Start time",
  "pages.admin.learning.form.fields.onsiteEndTime.label": "End time",
  "pages.admin.learning.form.fields.attachment.label": "Attach image cover",
  "pages.admin.learning.form.fields.courseUrl.title": "Type here the URL of the course",
  "pages.admin.learning.form.fields.courseUrl.description": "Contact the provider to obtain a valid link for the course.",
  "pages.admin.learning.form.fields.courseUrl.label": "Url of the training",
  "pages.admin.learning.form.fields.courseUrl.placeholder": "E.g. https://www.training.com/course/name-of-the-course/",
  "pages.admin.learning.form.save": "Create training",
  "pages.admin.learning.form.fields.language.label": "Language",
  "pages.admin.learning.form.languages.english": "English",
  "pages.admin.learning.form.languages.spanish": "Spanish",
  "pages.admin.learning.prompt.delete.title": "Delete course",
  "pages.admin.learning.prompt.delete.description": "You are going to delete this course. This action cannot be undone. Are your sure you want to continue?",
  "shared.close": "Close",
  "shared.languages.english": "English",
  "shared.languages.en": "English",
  "shared.languages.spanish": "Spanish",
  "shared.languages.es": "Spanish",
  "shared.languages.fr": "French",
  "shared.languages.french": "French",
  "shared.languages.german": "German",
  "shared.languages.italian": "Italian",
  "shared.languages.portuguese": "Portuguese",
  "shared.app_tag.no_content_yet": "You have not provided information yet",
  "shared.today": "Today",
  "shared.yesterday": "Yesterday",
  "shared.lastUpdate": "Last update",
  "shared.not_available": "N/A",
  "shared.yes": "Yes",
  "shared.no": "No",
  "shared.back": "Back",
  "shared.results": "{n} result | {n} results",
  "shared.resultsFor": "{n} Result For '{name}' | {n} Results For '{name}'",
  "shared.applicants": "{n} applicant | {n} applicants",
  "shared.assigned": "{n} employee assigned | {n} employees assigned",
  "shared.attachment.label": "Choose a file to upload",
  "shared.time.minutes": "{time} min",
  "shared.time.hours": "{time} h",
  "shared.are_you_sure": "Are your sure you want to continue?",
  "pages.admin.employee_roles.search_placeholder": "Search by name, surname or email",
  "pages.admin.admin_roles.roles.grid.email": "Email",
  "pages.admin.admin_roles.roles.grid.name": "Name",
  "pages.admin.admin_roles.roles.grid.surname": "Surname",
  "pages.admin.admin_roles.roles.grid.role": "Role",
  "pages.admin.admin_roles.roles.grid.role.Administrator": "Administrator",
  "pages.admin.admin_roles.roles.grid.role.ITManager": "IT Manager",
  "pages.admin.admin_roles.roles.grid.role.User": "User",
  "pages.admin.admin_roles.roles.grid.role.all": "All",
  "pages.goals_challenges.details.is_open.label": "Goal open for applications",
  "pages.goals_challenges.details.people_needed.label": "People needed",
  "pages.goals_challenges.details.country.label": "Country",
  "pages.goals_challenges.details.city.label": "City",
  "pages.goals_challenges.details.start_date.label": "Goal start date",
  "pages.goals_challenges.details.end_date.label": "Goal end date",
  "pages.goals_challenges.details.my_progress.label": "Goal progress",
  "pages.goals_challenges.details.coworkers_with_this_goal.label": "Coworker with this goal | Coworkers with this goal",
  "pages.goals_challenges.details.participate": "Participate",
  "pages.goals_challenges.details.reject": "Reject",
  "pages.goals_challenges.details.reject_application": "Reject my application",
  "pages.goals_challenges.details.colleagues_registered": "{n} colleague registered | {n} colleagues registered",
  "pages.my_groups.title": "My Groups",
  "pages.my_groups.search.placeholder": "Search for employees",
  "pages.my_groups.grid.actions.view_profile": "View profile",
  "pages.my_groups.view_questions": "View questions",
  "shared.company_groups_questions.title": "Questions",
  "shared.company_groups_questions.subtitle": "Survey Questions",
  "shared.company_groups_questions.created_questions": "Created questions",
  "shared.company_groups_questions.sent_questions": "Sent questions",
  "shared.company_groups_questions.search.placeholder": "Search questions",
  "shared.company_groups_questions.add_new_question": "Add new question",
  "shared.company_groups_questions.remove": "Delete questions",
  "shared.company_groups_questions.send": "Send questions",
  "shared.company_groups_questions.view_kpis": "View KPIs",
  "shared.company_groups_questions.view_questions": "View Questions",
  "shared.company_groups_questions.tip.title": "New questions",
  "shared.company_groups_questions.tip.content": "Your employees are talented, therefore their opinion is valuable. Create here a form to get your feedback.",
  "shared.company_groups_questions.grid.field.question": "Question",
  "shared.company_groups_questions.grid.field.answeredPercentage": "Participation (%)",
  "shared.company_groups_questions.grid.field.send_date": "Send date",
  "shared.company_groups_questions.grid.actions.remove": "Remove question from group",
  "shared.company_groups_questions.grid.actions.preview": "Question preview",
  "shared.company_groups_questions.grid.actions.edit": "Edit question",
  "shared.company_groups_questions.send_to_groups.title": "Send questions to groups",
  "shared.company_groups_questions.send_to_groups.content": "Select the recipients you want to send the survey. Only an admin can create a recipient group. If you can't find the group you need, please contact with your Human Resources administrator.",
  "shared.company_groups_questions.send_to_groups.search.placeholder": "Search",
  "shared.company_groups_questions.send_to_groups.buttons.send": "Send",
  "shared.company_groups_questions.send_to_groups.fields.group_name": "Group name",
  "shared.company_groups_questions.send_to_groups.fields.people": "People",
  "shared.question_answers.title": "Answers to '{text}'",
  "shared.question_answers.search.placeholder": "Search",
  "shared.question_answers.no_answer": "No answer yet",
  "shared.question_answers.action.show": "Show question answers",
  "shared.question_answers.statistics.metric": "{count} of {total}",
  "shared.question_answers.statistics.copy": "People have answered",
  "shared.question_kpi.pin": "Pin this chart to view it in the dashboard",
  "shared.question_kpi.unpin": "Unpin this chart from the dashboard",
  "shared.question_kpi.action.show": "Show question KPI",
  "shared.question_kpi.no_answers": "This question has not been answered yet",
  "shared.employee.full_name": "{firstName} {lastName}",
  "shared.company-groups.grid.full_name": "Name",
  "shared.company-groups.grid.email": "Email",
  "shared.agree_share_information": "I agree to share my information with the company",
  "shared.skillEditor.newSkill.skill.title": "Can't find the skill?",
  "shared.skillEditor.newSkill.skill.addNewButton": "Add new skill",
  "shared.skillEditor.newSkill.language.title": "Can't find the language?",
  "shared.skillEditor.newSkill.language.addNewButton": "Add new language",
  "shared.skillEditor.skill.search": "Search for a skill",
  "shared.skillEditor.language.search": "Search for a language",
  "shared.skillEditor.skill.pendingArea": "Pending for approval skills:",
  "shared.skillEditor.language.pendingArea": "Pending for approval languages:",
  "shared.skillCreator.skill.title": "Create new skill",
  "shared.skillCreator.language.title": "Create new language",
  "shared.skillCreator.skill.hrMessage": "The new skill needs to be validated by HR admin.",
  "shared.skillCreator.language.hrMessage": "The new language needs to be validated by HR admin.",
  "shared.skillCreator.form.skill_type_label": "Skill type",
  "shared.skillCreator.form.description": "Description",
  "shared.skillCreator.form.name": "Name",
  "shared.skillCreator.form.skill.createButton": "Create Skill",
  "shared.skillCreator.form.language.createButton": "Create Language",
  "shared.table.rows_per_page": "Rows per page ",
  "shared.table.go_to_first_page": "Go to first page",
  "shared.table.go_to_previous_page": "Go to previous page",
  "shared.table.go_to_next_page": "Go to next page",
  "shared.table.go_to_last_page": "Go to last page",
  "shared.tips.title": "Tip",
  "shared.tips.dismiss": "Got it",
  "shared.reminder.title": "Kind Reminder",
  "shared.feedback-sender.feedback-button": "Feedback",
  "shared.feedback-sender.bug-button": "Bug",
  "shared.feedback-sender.feedback-message": "Please write your feedback here. Thank you very much.",
  "shared.feedback-sender.bug-message": "Please specify how to reproduce this bug or include a screenshot.",
  "entities.goals_challenges.has_not_started": "The goal hasn't started yet",
  "entities.goals_challenges.has_finished": "The goal is finished",
  "entities.goals_challenges.no_goals_yet": "You don't have any goals yet",
  "entities.goals_challenges.employee_no_goals_yet": "Employee doesn't have any goals yet",
  "entities.learning.language": "Language",
  "entities.learning.duration": "Duration",
  "entities.learning.level": "Level",
  "entities.learning.topics": "Topics",
  "entities.learning.more_information": "More information",
  "entities.my_ideal_job.title": "My Ideal Job",
  "entities.my_ideal_job.tab.learning_path": "Learning path",
  "entities.my_ideal_job.tab.my_training": "My training",
  "entities.my_ideal_job.tab.company_training": "Company training",
  "entities.my_ideal_job.general_skills": "Skills",
  "entities.my_ideal_job.languages": "Languages",
  "entities.my_ideal_job.tip.content": "What would be your ideal job right now? Complete it here to be guided in achieving your goals!",
  "entities.my_ideal_job.add_job": "Add ideal job",
  "entities.my_ideal_job.update_job": "Update your ideal job",
  "entities.my_ideal_job.fields.title": "Title",
  "entities.my_ideal_job.fields.title.placeholder": "Add a title for your ideal job",
  "entities.my_ideal_job.fields.description": "Description",
  "entities.my_ideal_job.fields.description.placeholder": "Add a description for your ideal job",
  "entities.my_ideal_job.recommended_learning": "Recommended Learning",
  "entities.my_interests.hobbies": "Hobbies",
  "entities.my_interests.add-hobbies": "Edit hobbies",
  "entities.my_interests.hobbies.category": "Category",
  "entities.my_interests.hobbies.edit-modal.title": "Hobbies",
  "entities.my_interests.hobbies.edit-modal.search": "Search for a hobby",
  "entities.my_interests.hobbies.edit-modal.needNew": "Can't find the hobby?",
  "entities.my_interests.hobbies.edit-modal.newButton": "Add new hobby",
  "entities.my_interests.hobbies.hobbies-creator.title": "Create new hobby",
  "entities.my_interests.hobbies.hobbies-creator.createButton": "Create Hobby",
  "entities.my_interests.hobbies.hobbies-creator.form.name": "Name",
  "entities.my_interests.hobbies.category.sport": "Sport",
  "entities.my_interests.hobbies.category.music": "Music",
  "entities.my_interests.hobbies.category.culture": "Culture",
  "entities.my_interests.hobbies.category.technology": "Technology",
  "entities.my_interests.hobbies.category.others": "Other",
  "entities.my_interests.hobbies.no_hobbies": "No hobbies defined",
  "entities.my_interests.hobbies.age": "Age",
  "entities.skills.Low": "Low",
  "entities.skills.Medium": "Medium",
  "entities.skills.High": "High",
  "entities.courses.level.empty": "--",
  "entities.courses.level.1": "Beginner",
  "entities.courses.level.2": "Advanced",
  "entities.courses.level.3": "Expert",
  "level_KPI.x.title": "Votes",
  "pages.admin.company_settings.employee_groups.title": "Employee Groups",
  "pages.admin.company_settings.role_management.title": "Roles",
  "pages.admin.company_settings.skill_management.title": "Skills",
  "pages.admin.company_settings.chatbot.title": "Mr. Fleebe",
  "pages.admin.company_settings.employee_groups.search_placeholder": "Search Employee Groups",
  "pages.admin.company_settings.employee_groups.new_group": "Add new Group",
  "pages.admin.company_settings.employee_groups.fields.name": "Name",
  "pages.admin.company_settings.employee_groups.fields.description": "Description",
  "pages.admin.company_settings.employee_groups.fields.people": "People",
  "pages.admin.company_settings.employee_groups.fields.courses": "Learning",
  "pages.admin.company_settings.employee_groups.fields.employee-count": "no people | {n} people | {n} people",
  "pages.admin.company_settings.employee_groups.fields.course-count": "no learning | {n} learning | {n} learning",
  "pages.admin.company_settings.employee_groups.no_results": "There are no groups with this filter",
  "pages.admin.company_settings.employee_groups.delete.title": "Delete Employee Group",
  "pages.admin.company_settings.employee_groups.delete.description": "You are going to delete this employee group. This action cannot be undone. Are your sure you want to continue?",
  "pages.admin.company_settings.employee_groups.editor.title": "Edit Employee Group",
  "pages.admin.company_settings.employee_groups.editor.new_group": "New group",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.title": "Import employee list",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.title": "How to import a group.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.description": "Import a document of a valid format. The first column must be 'Email' and should contain the emails of the employees you want in the group.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.no_email_column_error.title": "No Column Named Email.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.no_email_column_error.description": "There must be a column named 'Email'.<br>To name a column, the first row in the column must be 'Email'. Please upload a new document with this column name.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.not_all_are_emails_error.title": "All Values Are Not Emails.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.not_all_are_emails_error.description": "Not all the values under the 'Email' column are a valid email: <br><br> {notValidEmails} <br><br>Please check the format.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.emails_not_matched.title": "Emails Not Found.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.emails_not_matched.description": "The following emails where not found between the employees: <br><br> {notFoundEmails} <br><br>Other emails where added to the group.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.all_emails_matched.title": "Employees Added to Group.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.all_emails_matched.description": "All the employees were added to the group.",
  "pages.admin.company_settings.employee_groups.editor.search_placeholder": "Search for employees",
  "pages.admin.company_settings.employee_groups.editor.selected_employees": "Employees selected {n} of {count}",
  "pages.admin.company_settings.employee_groups.editor.grid.actions.manage": "Manage",
  "pages.admin.company_settings.employee_groups.role.Manager": "Manager",
  "pages.admin.company_settings.employee_groups.role.Viewer": "Viewer",
  "pages.admin.company_settings.employee_groups.role.all": "All",
  "pages.admin.company_settings.employee_groups.tabs.employees": "Employees",
  "pages.admin.company_settings.employee_groups.tabs.questions": "Questions",
  "pages.admin.company_settings.employee_groups.tabs.learning": "Learning",
  "pages.admin.company_settings.employee_groups.manage.title.Employees": "Current Group Employees and Roles",
  "pages.admin.company_settings.employee_groups.manage.title.Questions": "Questions Management",
  "pages.admin.company_settings.employee_groups.manage.title.Learning": "Learning Assigned to This Group",
  "pages.admin.company_settings.employee_groups.manage.learning.select_training": "Select training",
  "pages.admin.company_settings.employee_groups.manage.learning.add_training": "Add new training",
  "pages.admin.company_settings.employee_groups.manage.learning.no_content": "No content yet",
  "pages.admin.company_settings.employee_groups.manage.learning.search.placeholder": "Search courses for this group",
  "pages.admin.company_settings.employees-with-groups.title": "Permissions Management",
  "pages.admin.company_settings.employees-with-groups.search_placeholder": "Search for employees",
  "pages.admin.company_settings.employees-with-groups.fields.first-name": "Name",
  "pages.admin.company_settings.employees-with-groups.fields.last-name": "Surname",
  "pages.admin.company_settings.employees-with-groups.fields.email": "Email",
  "pages.admin.company_settings.employees-with-groups.fields.group-count": "Groups",
  "pages.admin.company_settings.employees-with-groups.group-count": "{n} group| {n} groups",
  "pages.admin.company_settings.employees-with-groups.actions.edit": "Edit group",
  "pages.admin.company_settings.employees-with-groups.actions.manage": "Manage group"
}