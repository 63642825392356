<template>
  <div v-show="shouldBeShown" class="layout">
    <label
      v-for="(tab, index) in tabsAllowed"
      :key="tab.dataCy"
      class="nav"
      :class="{ 'nav--checked': tab.isChecked ?? index === 0 }"
      :data-cy="tab.dataCy">
      <input :name="tabId" type="radio" class="nav home-radio" :checked="tab.isChecked ?? index === 0" @click="tab.action" />
      <span class="text text--bold text--line-clamp-2"> {{ tab.text }} </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import useFeatureFlags from "@/services/useFeatureFlags";

export interface Tab {
  text: string;
  action?: () => void;
  isChecked?: boolean;
  dataCy: string;
  featureFlag?: string;
}

interface Props {
  tabs: Tab[];
  tabId: string;
  showIfOnlyOneAvailable?: boolean;
}
const props = withDefaults(defineProps<Props>(), { showIfOnlyOneAvailable: true });

const { checkFeatureFlag } = useFeatureFlags();
const tabsAllowed = computed(() => props.tabs.filter((tab) => checkFeatureFlag(tab.featureFlag)));
const shouldBeShown = computed(
  () => tabsAllowed.value.length === props.tabs.length || tabsAllowed.value.length !== 1 || props.showIfOnlyOneAvailable
);
</script>

<style scoped lang="scss">
.layout {
  display: inline-flex;
  min-height: var(--space-xl);
  overflow: hidden;
  border-color: var(--color-app-tab-border);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--default-border-radius);
}

input[type="radio"] {
  display: none;
}
label {
  width: inherit;
}

label.nav {
  flex-grow: 1;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  transition: background-color 0.4s, padding-left 0.2s;

  span {
    display: flex;
    place-items: center;
    text-align: center;
    height: 100%;
    padding: var(--space-xs) var(--space-s);
    transition: color 0.4s;
  }

  &:hover {
    background: var(--color-sidebar-menu--hover);
    span {
      color: var(--color-primary-accent1);
    }
  }

  & + & {
    border-left: 1px solid var(--color-app-tab-border);
  }

  input[type="radio"]:checked + span {
    background: var(--color-app-tab-background--checked);
    color: var(--color-app-tab-text);
  }

  &:has(input[type="radio"]:checked) {
    pointer-events: none;
  }
}
</style>
