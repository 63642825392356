import useToaster from "@/components/toaster/useToaster";
import { handleNonAuthentication } from "@/utils/redirectUtil";
import i18n from "@/i18n";
import axios, { type AxiosResponse } from "axios";

type HostProblemDetailsItem = {
  field: string;
  fieldValue?: string;
  errorCode: string;
};
type HostProblemDetails = {
  status: number;
  title: string;
  detail: string;
  type: string;
  items: HostProblemDetailsItem[];
};

export const showErrorIfNeeded = (value: AxiosResponse | undefined): void => {
  if (value && value.status === 403) {
    handleNonAuthentication();
    return;
  }
  if (value === undefined || value.status >= 300) {
    const { openToaster } = useToaster();
    openToaster("error", value?.data.details || "Unknown error");
  }
};
export const showError = (exception: unknown): void => {
  const axiosError = axios.isAxiosError(exception) ? exception : undefined;
  if (axiosError && (axiosError.code === "ERR_NETWORK" || axiosError.response?.status === 403)) {
    handleNonAuthentication();
    return;
  }
  const problemDetails = axiosError?.response?.data as HostProblemDetails;

  if (problemDetails) {
    showErrorProblemDetails(problemDetails);
    return;
  }
  showUnknownError();
};
const showErrorProblemDetails = (problemDetails: HostProblemDetails) => {
  const { openToaster } = useToaster();
  let errorMessages = "";

  if (problemDetails.items) {
    problemDetails.items.forEach((i) => {
      const errorMessage = i18n.global.t(`errors.${i.errorCode}`, { field: i.field, value: i.fieldValue });
      errorMessages += errorMessage;
    });
  } else {
    errorMessages = problemDetails.detail;
  }

  openToaster("error", errorMessages);
};
const showUnknownError = () => {
  const { openToaster } = useToaster();
  const errorMessage = i18n.global.t("errors.UnknownError");
  openToaster("error", errorMessage);
};
