<template>
  <AppInputGeneric :label="label" :omit-error-if-no-error="true" data-cy="app-select" class="app-input-select">
    <select v-model="value" class="input select" :disabled="disabled" :class="selectClasses">
      <option v-for="item in items" :key="item.value" :value="item.value">{{ item.text }}</option>
    </select>
  </AppInputGeneric>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppInputGeneric from "@/components/AppInputGeneric.vue";
import type { SelectOption } from "@/components/table/Column";

interface Props {
  modelValue: string;
  items: SelectOption[];
  disabled?: boolean;
  label?: string;
  keepMargin?: boolean;
  inline?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  keepMargin: true,
});

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

const selectClasses = computed(() => ({
  "select--keep-margin": props.keepMargin,
  "select--inline": props.inline,
}));
</script>

<style scoped lang="scss">
.app-input-select {
  .select {
    padding: 10px 10px;
    margin: 0 0 4px 0;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    &--keep-margin {
      margin-top: 10px;
    }

    &--inline {
      padding: var(--space-xs);
      margin: 0;
      max-width: 20rem;
    }
  }
}
</style>
