<template>
  <LayoutPage :title="$t('pages.goals_challenges.title')">
    <AppGenericFilter :placeholder="$t('pages.goals_challenges.search.placeholder')" class="margin-b--m" @filter-changed="updateSearch" />
    <div v-if="displaySearchCarousel" class="search-carousel" data-cy="search-carousel">
      <span class="search-carousel__results text--l" data-cy="searched-results">{{ $t("shared.results", allGoals.length) }}</span>
      <AppCarousel v-if="allGoals.length > 0" :number-of-items="allGoals.length" data-cy="searched-goals">
        <template v-for="(goal, index) in allGoals" :key="index" #[`item_${index+1}`]>
          <AppGoalsFeaturedCard :goal="goal" data-cy="searched-goal" @title-click="goalClick(goal.id)" />
        </template>
      </AppCarousel>
    </div>
    <h2 class="margin-v--m">{{ $t("pages.goals_challenges.featured.title") }}</h2>
    <AppCarousel v-if="featuredGoals.length" :number-of-items="featuredGoals.length" :number-of-items-visible="2" data-cy="featured-goals">
      <template v-for="(goal, index) in featuredGoals" :key="index" #[`item_${index+1}`]>
        <AppGoalsChallengeCard
          :goal="goal"
          data-cy="featured-goal"
          @title-click="goalClick(goal.id)"
          @update:participating="updateParticipatingHandler" />
      </template>
    </AppCarousel>
    <AppNoContentYet v-else :text="$t('pages.goals_challenges.no.goals')" />
    <h2 class="margin-v--m">{{ $t("pages.goals_challenges.my.title") }}</h2>
    <AppCarousel v-if="myGoals.length" :number-of-items="myGoals.length" data-cy="my-goals">
      <template v-for="(goal, index) in myGoals" :key="index" #[`item_${index+1}`]>
        <AppGoalsChallengeCurrentCard :goal="goal" data-cy="my-goal" @click="goalClick(goal.id)" />
      </template>
    </AppCarousel>
    <AppNoContentYet v-else />

    <AppModal name="goal-detail">
      <AppGoalDetails v-if="selectedGoalId" :goal-id="selectedGoalId" @update:participating="updateParticipatingHandler" />
    </AppModal>
  </LayoutPage>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { type Ref, ref, onMounted, computed } from "vue";
import AppCarousel from "@/components/carousel/AppCarousel.vue";
import AppGoalDetails from "@/views/app/goals-challenges/AppGoalDetails.vue";
import AppGoalsChallengeCard from "@/views/app/goals-challenges/components/AppGoalsChallengeCard.vue";
import AppGoalsChallengeCurrentCard from "@/views/shared/challenges-goals/AppGoalsChallengeCurrentCard.vue";
import AppGoalsFeaturedCard from "@/views/app/goals-challenges/components/AppGoalsFeaturedCard.vue";
import AppModal from "@/components/modal/AppModal.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import type { CompanyGoal, CompanyGoalWithParticipation } from "@/models/companyGoal";
import useMeGoalsService from "@/services/useMeGoalsService";
import useModal from "@/components/modal/useModal";
import { compareDates } from "@/utils/dateUtils";
import { isBlank } from "@/utils/stringUtils";

const { getFeaturedGoals, getMyCurrentGoals, searchCompanyGoals } = useMeGoalsService();
const { openComponentModal } = useModal();

const selectedGoalId: Ref<number | undefined> = ref<number | undefined>();

const featuredGoals: Ref<CompanyGoalWithParticipation[]> = ref<CompanyGoalWithParticipation[]>([]);
const myGoals: Ref<CompanyGoal[]> = ref<CompanyGoal[]>([]);

const searchText: Ref<string> = ref<string>("");
const displaySearchCarousel = computed(() => searchText.value.length && !isBlank(searchText.value));
const allGoals: Ref<CompanyGoalWithParticipation[]> = ref<CompanyGoalWithParticipation[]>([]);

onMounted(async () => {
  const goals = await getFeaturedGoals();
  goals.sort((a, b) => compareDates(a.start, b.start, true));
  featuredGoals.value = goals;
  myGoals.value = await getMyCurrentGoals();
});

const goalClick = (goalId: number): void => {
  selectedGoalId.value = goalId;
  openComponentModal("goal-detail", {
    title: i18n.global.t("pages.goals_challenges.my.title"),
    variant: "floating",
    showCancelToClose: true,
    closeCallback: () => {
      selectedGoalId.value = undefined;
    },
  });
};

const updateParticipatingHandler = (goalId: number, alreadyParticipant: boolean): void => {
  const goalToUpdate = featuredGoals.value.find((g) => g.id === goalId);

  if (!goalToUpdate) {
    return;
  }
  goalToUpdate.participating = alreadyParticipant;
};

const updateSearch = async (searchTerm: string) => {
  searchText.value = searchTerm;
  allGoals.value = await searchCompanyGoals(searchTerm);
};
</script>

<style lang="scss" scoped>
.search-carousel {
  &__results {
    display: inline-block;
    padding-bottom: var(--size-m);
  }
}
</style>
