<template>
  <LayoutPage :title="$t('pages.admin.learning.title')">
    <div class="learning-view">
      <CompanyCourses
        :event-emitter="eventEmitter"
        @course-selected="cardClick"
        @edit-course="editClick"
        @courses-loaded="coursesLoaded"
        @creating="editing = true" />
      <AppBox v-if="!hasCoursesDefined" :title="$t(`pages.admin.learning.create_new_learning_form.title`)">
        <div class="box-content">
          <img src="@/assets/images/training.png" :alt="$t(`pages.admin.learning.create_new_learning_form.title`)" />
          <div>
            <p class="text margin-b--m">
              {{ $t("pages.admin.learning.create_new_learning_form.description") }}
            </p>
            <AppButton
              data-cy="new-learning"
              :text="$t('pages.admin.learning.create_new_learning_form.new_learning')"
              size="m"
              @onclick="editing = true" />
          </div>
        </div>
      </AppBox>
      <CourseDetails v-if="!editing && selectedCourse" :course="selectedCourse" @closed="selectedCourse = undefined" />
    </div>
    <CourseEditor v-if="editing" :course="selectedCourse" :event-emitter="eventEmitter" @closed="editorCloseHandler" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { ref } from "vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppBox from "@/components/AppBox.vue";
import AppButton from "@/components/AppButton.vue";
import CourseDetails from "@/views/shared/learning/CourseDetails.vue";
import CourseEditor from "@/views/admin/learning/components/CourseEditor.vue";
import CompanyCourses from "@/views/admin/learning/components/CompanyCourses.vue";
import useEventEmitterService from "@/services/useEventEmitterService";
import type { CourseBase } from "@/models/course";

const eventEmitter = useEventEmitterService();

const selectedCourse = ref<CourseBase | undefined>();
const hasCoursesDefined = ref<boolean>(true);
const editing = ref<boolean>(false);

const coursesLoaded = (hasCourses: boolean) => (hasCoursesDefined.value = hasCourses);
const cardClick = (course: CourseBase) => (selectedCourse.value = course);
const editClick = (course: CourseBase) => {
  editing.value = true;
  selectedCourse.value = course;
};
const editorCloseHandler = () => {
  editing.value = false;
  selectedCourse.value = undefined;
};
</script>
<style scoped lang="scss">
.box-content {
  display: flex;
  padding: var(--size-m);
  align-items: center;
  column-gap: var(--size-m);
}

.onsite {
  padding: var(--size-m);
  background-color: var(--color-system-info--light);
  border-radius: var(--default-border-radius);
}

.course-url {
  padding: var(--size-m);
  display: flex;
  gap: var(--size-s);
  background-color: var(--color-company-course-url-background);
  border-radius: var(--default-border-radius);

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--size-m);
  }
}

.learning-view {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
