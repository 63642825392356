<template>
  <Transition name="fade">
    <div v-if="isVisible" class="tip" :class="`margin-b--${noMargin ? 0 : 'm'}`">
      <span v-if="!noIcon" class="icon text" :class="`icon-${icon}`"></span>
      <div class="tip__container">
        <div class="tip__title text text--bold margin-b--s">{{ title }}</div>
        <p class="tip__content">{{ content }}</p>
        <div v-if="uniqueName" class="tip__dismiss">
          <AppLinkButton :text="$t('shared.tips.dismiss')" :data-cy="`${uniqueName}__dismiss`" @click="setNotVisible" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import i18n from "@/i18n";
import AppLinkButton from "@/components/AppLinkButton.vue";
import { STORES, useStorageService } from "@/services/infrastructure/storageService";

const { getFromStore, store } = useStorageService<string[]>(STORES.tips_key);

interface Props {
  content: string;
  uniqueName?: string;
  title?: string;
  icon?: string;
  noMargin?: boolean;
  noIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: "bulb",
});

let tipsHidden = getFromStore();
const defaultVisible: boolean = props.uniqueName && tipsHidden ? !tipsHidden.includes(props.uniqueName) : true;
const isVisible = ref<boolean>(defaultVisible);
const title = computed(() => props.title ?? i18n.global.t("shared.tips.title"));

const setNotVisible = () => {
  if (!props.uniqueName) {
    return;
  }
  if (tipsHidden === undefined) {
    tipsHidden = [];
  }
  if (!tipsHidden.includes(props.uniqueName)) {
    tipsHidden.push(props.uniqueName);
  }
  store(tipsHidden);
  isVisible.value = false;
};
</script>
<style lang="scss" scoped>
.tip {
  background-color: var(--color-tip-panel-background);
  border-radius: var(--default-border-radius);
  display: flex;
  flex-direction: row;
  gap: var(--default-gap);
  overflow: hidden;
  padding: var(--space-m);
  transition: all 1s linear;

  &__container {
    flex-grow: 1;
  }
  &__title {
    color: var(--color-tip-title-text);
  }
  &__content {
    text-align: justify;
    text-justify: inter-word;
  }
  &__dismiss {
    text-align: right;
    margin-top: var(--space-m);
  }
  .icon {
    font-size: var(--size-m);
  }
}
</style>
