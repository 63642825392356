<template>
  <AppInputGeneric :label="label" :error="error" :omit-error-if-no-error="true" class="app-input-range">
    <input type="range" class="text" :value="modelValueString" :max="max" :min="min" @input="change" />
    <div class="range">
      <div v-for="availableValue in availableValues" :key="availableValue" class="text text--xs">
        {{ availableValue }}
      </div>
    </div>
  </AppInputGeneric>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppInputGeneric from "@/components/AppInputGeneric.vue";

interface Props {
  modelValue?: number;
  availableValues: string[];
  label?: string;
  error?: string;
  max: number;
  min: number;
}
const props = withDefaults(defineProps<Props>(), { modelValue: 0 });

const emit = defineEmits<(e: "update:modelValue", value: number | undefined) => void>();

const modelValueString = computed<string | undefined>(() => {
  if (!props.modelValue) {
    return undefined;
  }
  return props.modelValue.toString();
});

const backgroundSize = computed(() => {
  return props.modelValue ? ((props.modelValue - props.min) * 100) / (props.max - props.min) + "% 100%" : "0";
});

const change = (event: Event): void => {
  const input: HTMLInputElement = event.target as HTMLInputElement;
  const number = parseInt(input.value);
  const isEmpty = isNaN(number);
  emit("update:modelValue", isEmpty ? undefined : number);
};
</script>

<style scoped lang="scss">
.app-input-range {
  gap: 10px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 5px;
  background-size: v-bind(backgroundSize);
  background-color: var(--color-input-border);
  background-image: linear-gradient(to right, var(--color-input-background), var(--color-input-background));
  background-repeat: no-repeat;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;

  &::-webkit-slider-thumb,
  &::-ms-thumb,
  &::-moz-range-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: var(--color-input-slider-background);
    border-radius: 50%;
    box-shadow: 0 0 2px 0 #555;
    transition: background-color 0.3s ease-in-out;
  }

  &::-webkit-slider-thumb:hover,
  &::-moz-range-thumb:hover,
  &::-ms-thumb:hover {
    background: var(--color-input-slider-background--hover);
    border: 3px solid var(--color-input-slider-border--hover);
  }

  &::-webkit-slider-runnable-track,
  &::-moz-range-track {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    box-shadow: none;
  }
}

.range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 3px;
  padding-left: 10px;
}
</style>
