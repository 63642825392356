<template>
  <div data-cy="level-preview">
    <h2 class="margin-b--m text--l">{{ item.title }}</h2>
    <AppInputRange v-model="sampleValue" :available-values="levels" :min="1" :max="3" />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import AppInputRange from "@/components/AppInputRange.vue";
import type { Question } from "@/models/question";

interface Props {
  item: Question;
}
const props = defineProps<Props>();

const sampleValue = ref<number>(2);
const levels: string[] = props.item.levels
  ? [props.item.levels.lowLevelText, props.item.levels.mediumLevelText, props.item.levels.highLevelText]
  : [];
</script>
