<template>
  <AppAutomaticModalOpener :modal-config="modalConfig">
    <AppInformationMessage :message="$t('shared.company_groups_questions.send_to_groups.content')" class="margin-b--m" />
    <AppGenericFilter
      class="margin-b--m"
      :placeholder="$t('shared.company_groups_questions.send_to_groups.search.placeholder')"
      @filter-changed="filterChanged" />
    <AppPanel :border="true" :padding-content="false">
      <AppTable
        data-cy="group-selector-list"
        :columns="columns"
        :items="filteredGroups"
        :paginated="true"
        :allow-select="true"
        :default-order="defaultOrder"
        :no-border="true" />
    </AppPanel>
    <template #actions>
      <AppButton :text="$t('buttons.cancel')" variant="secondary" size="m" data-cy="cancel" @onclick="close" />
      <AppButton
        :text="$t('shared.company_groups_questions.send_to_groups.buttons.send')"
        variant="primary"
        size="m"
        :disabled="!selectedIds.length"
        data-cy="send"
        @onclick="send" />
    </template>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { watch, ref, computed } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";
import type { CompanyEmployeeGroupBase } from "@/models/companyEmployeeGroup";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import type { ModalBase } from "@/components/modal/models";
import AppButton from "@/components/AppButton.vue";
import AppInformationMessage from "@/components/AppInformationMessage.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppPanel from "@/components/AppPanel.vue";
import { containsInsensitive } from "@/utils/stringUtils";

interface Emits {
  (e: "closed"): void;
  (e: "sendToGroups", ids: number[]): void;
}
const emit = defineEmits<Emits>();

interface Props {
  groups: CompanyEmployeeGroupBase[];
}
const props = defineProps<Props>();

const selectedIds = ref<number[]>([]);
const searchText = ref<string>("");

const filteredGroups = computed(() => props.groups.filter((g) => containsInsensitive(g.name, searchText.value)));

const close = () => emit("closed");

const filterChanged = (newSearch: string) => (searchText.value = newSearch);

const send = () => {
  if (!selectedIds.value.length) {
    return;
  }
  emit("sendToGroups", selectedIds.value);
};

const modalConfig: ModalBase = {
  title: i18n.global.t("shared.company_groups_questions.send_to_groups.title"),
  variant: "large",
  onClosed: close,
};

const defaultOrder: Order = { direction: "asc", id: 1 };

const columns: Column<CompanyEmployeeGroupBase>[] = [
  {
    id: 1,
    headerText: i18n.global.t("shared.company_groups_questions.send_to_groups.fields.group_name"),
    cssWidth: "10fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "name",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("shared.company_groups_questions.send_to_groups.fields.people"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    type: "text-with-ellipsis",
    fieldName: "employeeCount",
  },
];

watch(
  () => props.groups,
  (value) => (selectedIds.value = value?.filter(({ selected }) => selected).map(({ id }) => id) || []),
  { deep: true, immediate: true }
);
</script>
