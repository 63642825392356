<template>
  <div class="admin-company-goals-assigned">
    <div class="comments">
      <div class="comments__content">
        <p>
          {{
            goal.isPublic && goalApplicationNotClosed
              ? $t("pages.admin.company_goals.goal_detail.ai_will_filter_candidates")
              : $t("pages.admin.company_goals.goal_detail.ai_selected_candidate")
          }}
        </p>
      </div>
    </div>
    <div class="assigned-counter">
      <AppNoContentYet
        v-if="employeesOrdered.length === 0"
        class="text--xs text--bold"
        :text="$t('pages.admin.company_goals.goal_detail.no_assigned_yet')" />
      <span v-else class="text text--bold">{{ $t("shared.assigned", employeesOrdered.length) }}</span>
    </div>
    <div class="assigned">
      <div v-if="employeesOrdered.length > 0" class="assigned__list">
        <AppEmployeeGoalProfile
          v-for="employee in employeesOrdered"
          :key="employee.employeeId"
          :employee="employee"
          :display-delete="!isFinished(goal)"
          data-cy="applicant"
          @delete-click="deleteEmployee"
          @details-click="viewEmployee" />
      </div>
      <AppButton
        v-if="canAddEmployees(goal)"
        size="m"
        variant="secondary"
        data-cy="open-recommender"
        :text="$t('pages.admin.company_goals.goal_detail.add_team_member')"
        @onclick="emit('applicantsClick')" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import AppButton from "@/components/AppButton.vue";
import AppEmployeeGoalProfile from "@/components/AppEmployeeGoalProfile.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import router from "@/router/router";
import { canAddEmployees, isFinished } from "@/services/modelServices/companyGoalService";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import { compareNumbers } from "@/utils/numberUtils";
import { isBiggerThanToday } from "@/utils/dateUtils";

interface Props {
  goal: CompanyGoalWithEmployees;
}
const props = defineProps<Props>();

interface Emits {
  (e: "applicantsClick"): void;
  (e: "deleteClick", employeeId: number): void;
}
const emit = defineEmits<Emits>();

const employeesOrdered = computed(() =>
  [...props.goal.assignedEmployees].sort((a, b) => compareNumbers(a.goalTargetFit, b.goalTargetFit, false))
);

const goalApplicationNotClosed = props.goal.applicationClosing && isBiggerThanToday(props.goal.applicationClosing);

const viewEmployee = (employeeId: number) =>
  router.push({
    name: "dashboard-admin-employee",
    params: { employeeId },
    query: { backUrl: `/admin/corporate-goals/goal/${props.goal.id}` },
  });

const deleteEmployee = (employeeId: number): void => {
  emit("deleteClick", employeeId);
};
</script>

<style scoped lang="scss">
.admin-company-goals-assigned {
  border-radius: var(--default-border-radius);
  background: var(--color-neutral-100);
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-xs);
  padding: var(--size-s) var(--size-m);

  &__text {
    color: var(--color-primary-accent1);
  }
}

.selector {
  display: flex;
  justify-content: center;
  padding: var(--size-m) 0 var(--size-m) 0;
}

.comments {
  &__content {
    padding: var(--size-m);
    border-radius: var(--default-border-radius);
    background: var(--color-company-goals-employee-background);
  }
}
.assigned-counter {
  padding: var(--size-s) 0;
}

.assigned {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-m);
  max-height: 940px;
  overflow-y: auto;

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--size-m);
  }
}
</style>
