import App from "@/App.vue";
import { loadDirectives } from "@/directives/DirectivesLoader";
import i18n from "@/i18n";
import router from "@/router/router";
import "@/styles/main.scss";
import { createApp } from "vue";
import VueApexCharts from "vue3-apexcharts";
import useAppInsights from "@/services/shared/useAppInsights";

const initApp = (): void => {
  const app = createApp(App);
  loadDirectives(app);
  app.use(i18n).use(router).use(VueApexCharts).mount("#app");

  if (import.meta.env.PROD) {
    const { logException } = useAppInsights();
    app.config.errorHandler = (err, _vm, _info) => logException(err as Error);
  }
};

initApp();
