import { ApplicationInsights } from "@microsoft/applicationinsights-web";

interface UseApplicationInsightsReturn {
  logException(exception: Error, severityLevel?: number): void;
}

const instrumentationKey = import.meta.env.VITE_ApplicationInsights__InstrumentationKey ?? "";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    disableCookiesUsage: true,
  },
});

if (instrumentationKey) {
  appInsights.loadAppInsights();
}

const logException = (exception: Error, severityLevel?: number): void => {
  appInsights.trackException({ exception: exception, severityLevel: severityLevel });
};

export default (): UseApplicationInsightsReturn => {
  return { logException };
};
