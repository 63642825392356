<template>
  <AIAssistantEditorBase
    :question="question"
    :disable-key-enter="true"
    :form="form"
    :get-current-answer="getAnswer"
    @closed="closed"
    @edited="edited">
    <AppInputTextArea v-model="answer" :maxlength="largeTextLength" :error="answerError" :autofocus="true" />
  </AIAssistantEditorBase>
</template>

<script setup lang="ts">
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import { largeTextLength } from "@/constants/restrictions";
import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import AIAssistantEditorBase from "@/views/app/ai-assistant/components/AIAssistantEditorBase.vue";
import { useField, useForm } from "vee-validate";
import { onMounted } from "vue";
import useFormSchema from "@/services/useFormSchema";

interface Props {
  question: Question;
}
const props = defineProps<Props>();

interface Emits {
  (e: "edited", newQuestionState: Question): void;
  (e: "closed"): void;
}
const emit = defineEmits<Emits>();

const { yupObject, stringSchema } = useFormSchema();

const resourceSchema = yupObject({
  answer: stringSchema({ maxLength: largeTextLength }),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: answer, errorMessage: answerError } = useField<string>("answer");

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (props.question.text) {
    answer.value = props.question.text ?? "";
    return;
  }
  form.resetForm();
};
const getAnswer = (): Answer => ({ text: answer.value });
const closed = () => emit("closed");
const edited = (newQuestionState: Question) => emit("edited", newQuestionState);
</script>
