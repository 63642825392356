<template>
  <span class="text text--error error" :class="classes" data-cy="input-error">{{ error }}</span>
</template>
<script setup lang="ts">
import { computed } from "vue";

interface Props {
  error?: string;
}
const props = defineProps<Props>();

const classes = computed(() => (!props.error ? "hidden" : ""));
</script>
<style scoped lang="scss">
.error {
  height: 2rem;
}
</style>
