import type { CompanyEmployeeGroupWithQuestions } from "@/models/companyEmployeeGroup";
import type { QuestionSelectable } from "@/models/question";

export const tryUpdateQuestionData = (
  question: QuestionSelectable,
  availableQuestions: QuestionSelectable[],
  source: CompanyEmployeeGroupWithQuestions | CompanyEmployeeGroupWithQuestions[]
): [QuestionSelectable[], CompanyEmployeeGroupWithQuestions | CompanyEmployeeGroupWithQuestions[]] => {
  question.edited = new Date();

  const previousQuestion = availableQuestions.find((q) => q.id === question.id);
  if (previousQuestion) {
    question.employeeGroups = previousQuestion.employeeGroups;
  }
  const updatedAvailableQuestions = [...availableQuestions.filter((q) => q.id !== question.id), { ...question }];

  (Array.isArray(source) ? source : [source]).forEach((g) => {
    const target = g.questions.find((q) => q.id === question.id);
    if (target) {
      question.sendDate = target.sendDate;
      g.questions = [...g.questions.filter((q) => q.id !== question.id), { ...question }];
    }
  });

  return [updatedAvailableQuestions, source];
};
