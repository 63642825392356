<template>
  <AppSelect
    :model-value="selectedLanguage"
    :items="availableLocales"
    :label="$t('pages.header.profile-editor.app-settings.languages.title')"
    @change="changed" />
</template>

<script lang="ts" setup>
import i18n from "@/i18n";
import AppSelect from "@/components/AppSelect.vue";
import { computed } from "vue";

interface Props {
  modelValue: string;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();

const selectedLanguage = computed<string>(() => props.modelValue);

const availableLocales = computed<{ value: string; text: string }[]>(() => {
  return i18n.global.availableLocales
    .map((l) => ({ value: l, text: i18n.global.t(`pages.header.profile-editor.app-settings.languages.${l}`) }))
    .sort((a, b): number => a.text.localeCompare(b.text));
});

const changed = (event: Event) => {
  const el = event.target as HTMLInputElement;
  emit("update:modelValue", el.value);
};
</script>
