<template>
  <div>
    <AppNoContentYet v-if="courses.length === 0" :text="$t('pages.learning.my_learning_path.no_suggestions')" />
    <div v-else class="courses-carousel">
      <div class="courses-carousel__container">
        <div v-for="(course, index) in courses" :key="course.id" class="courses-carousel__item">
          <MyLearningPathCourse
            v-if="index + 1 === currentPage"
            :course="course"
            :information-direction="courseInformationDirection"
            @course-selected="emit('course-selected', course)" />
        </div>
      </div>
      <div class="navigation">
        <AppIconOnlyButton
          v-if="courses.length > 1"
          class="navigation__button"
          :disabled="currentPage === 1"
          size="xxl"
          icon="arrow-back"
          data-cy="back"
          @click="previousPage" />
        <AppIconOnlyButton
          v-if="courses.length > 1"
          class="navigation__button"
          :disabled="currentPage === courses.length"
          size="xxl"
          icon="arrow-forward"
          data-cy="next"
          @click="nextPage" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import type { EmployeeCourse } from "@/models/course";
import MyLearningPathCourse from "@/views/app/my-learning/components/MyLearningPathCourse.vue";
import { ref, type Ref } from "vue";

interface Props {
  courses: EmployeeCourse[];
  courseInformationDirection: "asc" | "desc";
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "course-selected", course: EmployeeCourse) => void>();

const currentPage: Ref<number> = ref(1);

const previousPage = () => {
  if (currentPage.value === 1) {
    return;
  }
  currentPage.value--;
};

const nextPage = () => {
  if (currentPage.value === props.courses.length) {
    return;
  }
  currentPage.value++;
};
</script>

<style lang="scss" scoped>
.courses-carousel {
  &__item {
    width: 100%;
  }
}

.navigation {
  display: flex;
  gap: var(--space-m);
  margin-top: var(--space-xs);

  &__button {
    border: 1px solid var(--color-primary-accent1);
    border-radius: 50% 50%;
    width: var(--space-l);
    height: var(--space-l);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      filter: brightness(50%);
    }
  }
}
</style>
