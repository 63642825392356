import { SkillProgression } from "@/models/skillProgression";
import type { SkillWithProgression } from "@/models/skillWithProgression";

export const sortByProgressionName = (a: SkillWithProgression, b: SkillWithProgression): number => {
  if (a.progression === b.progression) {
    return a.name.localeCompare(b.name);
  }
  if (a.progression === SkillProgression.High) {
    return -1;
  }
  if (b.progression === SkillProgression.High) {
    return 1;
  }
  if (a.progression === SkillProgression.Medium) {
    return -1;
  }
  if (b.progression === SkillProgression.Medium) {
    return 1;
  }
  return 0;
};
