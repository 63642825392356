<template>
  <div class="card">
    <GoalImage :goal="goal" />
    <div class="information">
      <div class="information__details">
        <div>
          <p class="text text--l text--link text--bold margin-v--s" data-cy="card-title" @click="titleClick">{{ goal.title }}</p>
          <p class="text margin-b--s information__description text--line-clamp-3" v-html="toHtml(goal.description)"></p>
        </div>
        <div class="text text--bold">
          {{ $t("pages.goals_challenges.featured.dates", { start: parseDateToString(goal.start), end: parseDateToString(goal.end) }) }}
        </div>
      </div>
      <div v-if="goal.canParticipate" class="information__actions">
        <ParticipateButton v-model="participating" :goal-id="goal.id" />
        <AppTag
          :main-text="$t('pages.goals_challenges.featured.vacant_positions', { vacantPositions: goal.numberOfPeopleNeeded })"
          type="skill" />
        <p class="text">
          {{ $t("pages.goals_challenges.featured.days_left", { days: daysBetweenDates(new Date(), goal.applicationClosing) }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import AppTag from "@/components/AppTag.vue";
import ParticipateButton from "@/views/app/goals-challenges/components/ParticipateButton.vue";
import GoalImage from "@/views/app/goals-challenges/components/GoalImage.vue";
import type { CompanyGoal, CompanyGoalWithParticipation } from "@/models/companyGoal";
import { daysBetweenDates, parseDateToString } from "@/utils/dateUtils";
import { toHtml } from "@/utils/stringUtils";

interface Props {
  goal: CompanyGoalWithParticipation;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "titleClick", value: CompanyGoal): void;
  (e: "update:participating", goalId: number, participating: boolean): void;
}>();

const participating = computed({
  get: () => props.goal.participating,
  set: (val) => emit("update:participating", props.goal.id, !!val),
});

const titleClick = () => emit("titleClick", props.goal);
</script>
<style lang="scss" scoped>
.card {
  height: calc(100% - 40px);
  margin: var(--space-s) 0 var(--space-s) var(--space-s);
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-100);
  border-radius: 12px;
  box-shadow: var(--size-xs) var(--size-xs) var(--size-s) rgb(26 26 26 / 0.06);
}

.information {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--space-s);
  padding: var(--space-s);
  height: 100%;

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__description {
    min-height: 60px;
  }

  &__actions {
    flex: 0 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--space-s) 0 var(--space-s) 0;
  }
}
</style>
