<template>
  <AppBox :title="$t(`pages.admin.ai_assistant.create_new_survey_form.title`)">
    <div class="box-content">
      <div class="box-content__main">
        <img src="@/assets/images/questions.png" alt="" />
        <div>
          <p class="text margin-b--m">
            {{ $t("pages.admin.ai_assistant.create_new_survey_form.description") }}
          </p>
          <AppButton
            data-cy="new-question"
            :text="$t('pages.admin.ai_assistant.create_new_survey_form.new_question')"
            size="m"
            @onclick="creating = true" />
        </div>
      </div>
      <AppTip
        icon="kpi"
        :title="$t('pages.ai_assistant.header.title')"
        :content="$t('pages.ai_assistant.header.subtitle')"
        no-margin
        data-cy="ai-assistant-tip" />
      <QuestionWizard v-if="creating" mode="create" @close="creating = false" @saved="$emit('questionCreated')" />
    </div>
  </AppBox>
</template>
<script setup lang="ts">
import { ref } from "vue";
import AppBox from "@/components/AppBox.vue";
import AppButton from "@/components/AppButton.vue";
import AppTip from "@/components/AppTip.vue";
import QuestionWizard from "@/views/admin/ai-assistant/components/wizard/QuestionWizard.vue";

defineEmits<(e: "questionCreated") => void>();

const creating = ref<boolean>(false);
</script>

<style scoped lang="scss">
.box-content {
  display: flex;
  padding: 2.4rem;
  align-items: center;
  column-gap: 2.4rem;
  flex-direction: column;
  &__main {
    display: flex;
    align-items: center;
    column-gap: 2.4rem;
  }
}
</style>
