<template>
  <AppPercentageChartTwoLines v-if="kpiInfo" :kpi-info="kpiInfo" />
</template>
<script lang="ts" setup>
import { onMounted, ref, type Ref } from "vue";
import { calculatePercent } from "@/utils/mathUtils";
import { SkillProgression } from "@/models/skillProgression";
import AppPercentageChartTwoLines from "@/components/AppPercentageChartTwoLines.vue";
import type { GenericKpiInfo } from "@/models/genericKpiInfo";
import i18n from "@/i18n";
import { SkillType } from "@/models/skillType";
import useMeSkillsService from "@/services/useMeSkillsService";
const { getSuccessfulSkills } = useMeSkillsService();

const kpiInfo: Ref<GenericKpiInfo | undefined> = ref<GenericKpiInfo | undefined>();

onMounted(async () => retrieveSuccessfulSkills());

const progressionValue: Record<SkillProgression, number> = {
  Low: 1,
  Medium: 2,
  High: 3,
};

const calculateUserTotalPoints = (skillProgressions: SkillProgression[]): number => {
  return skillProgressions.reduce((total, value) => total + progressionValue[value], 0);
};

const retrieveSuccessfulSkills = async (): Promise<void> => {
  const result = await getSuccessfulSkills();
  const maxPointsLanguages = result.totalLanguagesSkills.length * Object.keys(SkillProgression).length;
  const maxPointsGeneral = result.totalGeneralSkills.length * Object.keys(SkillProgression).length;

  const userPointsLanguages = calculateUserTotalPoints(result.employeeLanguagesSkills.map((s) => s.progression));
  const userPointsGeneral = calculateUserTotalPoints(result.employeeGeneralSkills.map((s) => s.progression));

  const languagesPercentage = calculatePercent(maxPointsLanguages, userPointsLanguages);
  const generalPercentage = calculatePercent(maxPointsGeneral, userPointsGeneral);
  const totalPercentage = Math.round((languagesPercentage + generalPercentage) / Object.keys(SkillType).length);

  kpiInfo.value = {
    mainPercentage: totalPercentage,
    title: i18n.global.t("pages.my_profile.dashboards.successful_skills.title"),
    percentages: [
      {
        id: 1,
        percentage: generalPercentage,
        title: i18n.global.t("pages.my_profile.dashboards.successful_skills.general"),
      },
      {
        id: 2,
        percentage: languagesPercentage,
        title: i18n.global.t("pages.my_profile.dashboards.successful_skills.language"),
      },
    ],
  };
};
</script>
