import { ref, type Ref } from "vue";
import { networkStateStore } from "@/services/shared/networkStateStore";
import { DEBOUNCE_GET_AS_LOADING } from "@/constants/debounce";

interface UseLoadingReturn {
  startLoading: (delayed: boolean, loading: Ref<boolean>) => () => void;
}

export default (): UseLoadingReturn => {
  const setLoading = (loadingInService: Ref<boolean>, loadingInCall: Ref<boolean>, callFinished: Ref<boolean>) => {
    if (callFinished.value) {
      return;
    }
    loadingInService.value = true;
    loadingInCall.value = true;
    networkStateStore.incrementCallCount();
  };
  const endLoading = (
    timeOut: number | void,
    loadingInService: Ref<boolean>,
    loadingInCall: Ref<boolean>,
    callFinished: Ref<boolean>
  ): void => {
    callFinished.value = true;
    if (timeOut) {
      window.clearTimeout(timeOut);
    }
    loadingInService.value = false;
    if (!loadingInCall.value) {
      return;
    }
    loadingInCall.value = false;
    networkStateStore.decrementCallCount();
  };
  const startLoading = (delayed: boolean, loadingInService: Ref<boolean>) => {
    const loadingInCall = ref<boolean>(false);
    const callFinished = ref<boolean>(false);
    const setLoadingCaller = () => setLoading(loadingInService, loadingInCall, callFinished);
    const timeOut = delayed ? window.setTimeout(setLoadingCaller, DEBOUNCE_GET_AS_LOADING) : setLoadingCaller();

    return () => endLoading(timeOut, loadingInService, loadingInCall, callFinished);
  };

  return { startLoading };
};
