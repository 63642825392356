import type { Ref } from "vue";
import type { CompanyGoal } from "@/models/companyGoal";
import type { EmployeeBasicInformation } from "@/models/employeeBasicInformation";
import type { EmployeeInformation } from "@/models/employee/employeeInformation";
import type { EmployeeRoleRequest } from "@/models/requests/employeeRoleRequest";
import type { IdealJob } from "@/models/idealJob";
import type { IdealLearning } from "@/models/idealLearning";
import type { Interest } from "@/models/interest";
import type { ProfileInformation } from "@/models/profileInformation";
import type { Question } from "@/models/question";
import type { Hobby } from "@/models/hobby";
import type { CompanyEmployeeGroupWithEmployees } from "@/models/companyEmployeeGroup";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/employees";
const { get, put, loading, appBaseUrl } = useHttpClient();

interface UseEmployeeServiceReturn {
  search: (searchTerm: string, numberOfItems: number) => Promise<ProfileInformation[]>;
  getProfileInformation: (employeeId: number) => Promise<ProfileInformation>;
  getQuestions: (employeeId: number) => Promise<Question[]>;
  getIdealJob: (employeeId: number) => Promise<IdealJob>;
  getCurriculumUrl: (employeeId: number) => string;
  getJobExperienceAttachmentUrl: (employeeId: number, jobExperienceId: number) => string;
  getBasicInformation: (employeeId: number) => Promise<EmployeeBasicInformation>;
  getEducationAttachmentUrl: (employeeId: number, educationId: number) => string;
  getEmployees: () => Promise<EmployeeInformation[]>;
  putEmployeeRole: (request: EmployeeRoleRequest) => Promise<void>;
  putEmployeeLanguage: (language: string) => Promise<void>;
  getEmployeeGoals: (employeeId: number) => Promise<CompanyGoal[]>;
  getEmployeeHobbies: (employeeId: number) => Promise<Hobby[]>;
  getInterests: (employeeId: number) => Promise<Interest[] | undefined>;
  getIdealLearnings: (employeeId: number) => Promise<IdealLearning[] | undefined>;
  getEmployeePictureUrl: (employeeId: number) => string;
  getEmployeeManagedGroups: () => Promise<CompanyEmployeeGroupWithEmployees[]>;
  loading: Ref<boolean>;
}

export default (): UseEmployeeServiceReturn => {
  const search = (searchTerm: string, numberOfItems: number): Promise<ProfileInformation[]> =>
    get(`${BASE_URL}/search?searchTerm=${searchTerm}&numberOfItems=${numberOfItems}`);

  const getProfileInformation = (employeeId: number): Promise<ProfileInformation> => get(`${BASE_URL}/${employeeId}/profile`);
  const getQuestions = (employeeId: number): Promise<Question[]> => get(`${BASE_URL}/${employeeId}/questions`);
  const getIdealJob = (employeeId: number): Promise<IdealJob> => get(`${BASE_URL}/${employeeId}/my-ideal-job`);
  const getEmployees = (): Promise<EmployeeInformation[]> => get(BASE_URL);
  const getEmployeeGoals = (employeeId: number): Promise<CompanyGoal[]> => get(`${BASE_URL}/${employeeId}/goals`);
  const getEmployeeHobbies = (employeeId: number): Promise<Hobby[]> => get(`${BASE_URL}/${employeeId}/hobbies`);
  const getEmployeePictureUrl = (employeeId: number): string => `${appBaseUrl}${BASE_URL}/${employeeId}/picture`;
  const getInterests = (employeeId: number): Promise<Interest[] | undefined> => get(`${BASE_URL}/${employeeId}/interests`);
  const getBasicInformation = (employeeId: number): Promise<EmployeeBasicInformation> => get(`${BASE_URL}/${employeeId}/basic-information`);

  const putEmployeeRole = (request: EmployeeRoleRequest): Promise<void> => put(`${BASE_URL}/role`, request);
  const putEmployeeLanguage = (language: string): Promise<void> => put(`${BASE_URL}/language`, { language: language });

  const getIdealLearnings = (employeeId: number): Promise<IdealLearning[] | undefined> => get(`${BASE_URL}/${employeeId}/ideal-learnings`);

  const getCurriculumUrl = (employeeId: number): string => `${appBaseUrl}${BASE_URL}/${employeeId}/curriculum`;
  const getJobExperienceAttachmentUrl = (employeeId: number, jobExperienceId: number): string =>
    `${appBaseUrl}${BASE_URL}/${employeeId}/job-experience/${jobExperienceId}/attachment`;
  const getEducationAttachmentUrl = (employeeId: number, educationId: number): string =>
    `${appBaseUrl}${BASE_URL}/${employeeId}/education/${educationId}/attachment`;
  const getEmployeeManagedGroups = (): Promise<CompanyEmployeeGroupWithEmployees[]> => get(`${BASE_URL}/managed-groups`);

  return {
    search,
    getProfileInformation,
    getQuestions,
    getIdealJob,
    getBasicInformation,
    getCurriculumUrl,
    getJobExperienceAttachmentUrl,
    getEducationAttachmentUrl,
    getEmployees,
    putEmployeeRole,
    putEmployeeLanguage,
    getEmployeeGoals,
    getEmployeeHobbies,
    getEmployeePictureUrl,
    getInterests,
    getIdealLearnings,
    getEmployeeManagedGroups,
    loading,
  };
};
