<template>
  <div>
    <AppGenericFilter
      :placeholder="$t('pages.admin.employee_roles.search_placeholder')"
      class="margin-b--xs"
      @filter-changed="(newSearch) => (searchText = newSearch)" />
    <AppPanel v-if="employees.length > 0" :padding-content="false">
      <template #default>
        <AppTable :columns="columns" :items="employeesInView" :paginated="true" :filters="filters" />
      </template>
    </AppPanel>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import AppPanel from "@/components/AppPanel.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import UseAuthenticationService from "@/services/useAuthenticationService";
import UseEmployeeServiceResponse from "@/services/useEmployeeService";

import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import i18n from "@/i18n";
import type { EmployeeInformation } from "@/models/employee/employeeInformation";
import type { User } from "@/models/user";
import { UserRole } from "@/models/userRole";
import useTableHelper from "@/components/table/useTableHelper";
import type { Filter } from "@/components/table/TableFilter";

const { getEmployees, putEmployeeRole } = UseEmployeeServiceResponse();
const { getUser } = UseAuthenticationService();
const { getTypeFilter, filterByText, getSelectOptions } = useTableHelper();

const changeRole = async (employeeInformation: EmployeeInformation): Promise<void> => {
  await putEmployeeRole({
    employeeId: employeeInformation.id,
    employeeRole: employeeInformation.role,
  });
};

const columns: Column<EmployeeInformation>[] = [
  {
    id: 1,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.name"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "firstName",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.surname"),
    cssWidth: "0.5fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "lastName",
    type: "text-with-ellipsis",
  },
  {
    id: 3,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.email"),
    cssWidth: "2fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "email",
    type: "text-with-ellipsis",
  },
  {
    id: 4,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.role"),
    cssWidth: "1fr",
    align: "left",
    sortable: false,
    searchable: false,
    type: "select",
    fieldName: "role",
    select: {
      disabled: (item: EmployeeInformation) => !!currentUser.value && currentUser.value.id === item.id,
      options: getSelectOptions(UserRole, "pages.admin.admin_roles.roles.grid.role"),
      onUpdate: changeRole,
    },
  },
];
const roleFilter = getTypeFilter<EmployeeInformation>(
  "role",
  i18n.global.t("pages.admin.admin_roles.roles.grid.role"),
  "pages.admin.admin_roles.roles.grid.role",
  Object.keys(UserRole)
);
const filters: Filter<EmployeeInformation>[] = [roleFilter];

const employees = ref<EmployeeInformation[]>([]);
const currentUser = ref<User | undefined>();
const searchText = ref<string>("");

const fetchData = async (): Promise<void> => {
  currentUser.value = await getUser();
  employees.value = await getEmployees();
};

const employeesInView = computed<EmployeeInformation[]>(() => filterByText(employees.value, searchText.value, columns));

onMounted(fetchData);
</script>
