export enum SkillType {
  General = "General",
  Language = "Language",
}

export enum SkillLocationType {
  Employee = "Employee",
  CurrentJob = "CurrentJob",
  IdealJob = "IdealJob",
}
