<template>
  <div>
    <AnswerOptions :options="tagsAsOptions" />
    <AIAssistantAnswerActions v-if="answerEnabled" :question="question" :editor="AIAssistantAnswerTagsEditor" @edited="edited" />
  </div>
</template>

<script setup lang="ts">
import type { Question } from "@/models/question";
import AIAssistantAnswerActions from "@/views/app/ai-assistant/components/AIAssistantAnswerActions.vue";
import AIAssistantAnswerTagsEditor from "@/views/app/ai-assistant/components/AIAssistantAnswerTagsEditor.vue";
import AnswerOptions from "@/views/app/ai-assistant/components/AnswerOptions.vue";
import type { AnswerOption } from "@/views/app/ai-assistant/utils/models";
import { computed } from "vue";

interface Props {
  question: Question;
  answerEnabled: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const edited = (newQuestionState: Question): void => emit("edited", newQuestionState);

const tagsAsOptions = computed(() => {
  if (!props.question.tags) {
    return [];
  }
  return props.question.tags.map(
    (answer) =>
      ({
        optionId: answer,
        title: answer,
      } as AnswerOption)
  );
});
</script>

<style lang="scss" scoped>
.answer {
  &__actions {
    flex-shrink: 0;
    width: 50px;
    text-align: right;
  }
}
</style>
