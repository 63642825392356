<template>
  <div>
    <h3 data-cy="company-employee-groups-title" class="margin-b--l">{{ $t("pages.admin.company_settings.employee_groups.title") }}</h3>
    <AppGenericFilter
      :placeholder="$t('pages.admin.company_settings.employees-with-groups.search_placeholder')"
      class="margin-b--xs"
      @filter-changed="filterChanged" />
    <CompanyEmployeesWithGroupsTable :search-text="searchTerm" :employees="employees" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import CompanyEmployeesWithGroupsTable from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeesWithGroupsTable.vue";
import type { EmployeeWithGroups } from "@/models/companyEmployeeGroup";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";

const { getCompanyEmployeesWithGroups } = useCompanyEmployeeGroupsService();

const employees = ref<EmployeeWithGroups[]>([]);
const searchTerm = ref<string>();

const filterChanged = (newSearchTerm: string) => (searchTerm.value = newSearchTerm);

onMounted(async () => (employees.value = await getCompanyEmployeesWithGroups()));
</script>
