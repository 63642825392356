import type { FileAttachment } from "@/models/fileAttachment";

export const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64AndTypeContent: string = reader.result as string;
      const base64Content: string = base64AndTypeContent.substring(base64AndTypeContent.indexOf(",") + 1);
      resolve(base64Content);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const exportFilesToBase64 = async (fileList: FileList): Promise<FileAttachment[]> => {
  const base64FileList: FileAttachment[] = [];
  const files = Array.from(fileList);

  for (const file of files) {
    base64FileList.push({
      fileName: file.name,
      fileBase64Contents: await getBase64(file),
      size: file.size,
    });
  }

  return base64FileList;
};

export const downloadFile = (fileName: string, fileBase64: string): void => {
  const a = document.createElement("a");
  a.href = fileBase64;
  a.download = fileName;
  a.click();
};

export const convertToBytes = (sizeInMB: number): number => sizeInMB * 1024 * 1024;

export const allowedExtensions: string[] = [".txt", ".doc", ".docx", ".pdf"];
export const basicAllowedImageExtensions: string[] = [".jpg", ".png"];
export const allowedImageExtensions: string[] = [...basicAllowedImageExtensions, ".jpeg", ".gif"];

export const excelAllowedExtensions: string[] = [
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.oasis.opendocument.spreadsheet",
];

const LONG_ACCEPTED_TYPES = new Map<string, string>([
  [excelAllowedExtensions[1], ".xlsx"],
  [excelAllowedExtensions[2], ".xls, .xlt, .xla"],
  [excelAllowedExtensions[3], ".ods"],
]);

export const maxFileSizeInMB = 3;
export const fileSize1MB = 1;

export const convertMIMEToExtension = (mime: string): string | string[] => {
  if (!LONG_ACCEPTED_TYPES.has(mime.trim())) {
    return mime;
  }
  const extension = LONG_ACCEPTED_TYPES.get(mime.trim()) as string;
  if (extension.indexOf(", ") > 0) {
    return extension.split(", ");
  }
  return extension;
};
