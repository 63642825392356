<template>
  <div class="profile-editor margin-b--s margin-t--s">
    <span class="text text--bold margin-b--s">
      {{ $t("pages.header.profile-editor.personal-information.title") }}
    </span>
    <AppDateBirthSelector :birth-date="birthDate" @updated="updatedBirthDate" />
    <span class="text text--bold margin-b--s">
      {{ $t("pages.header.profile-editor.app-settings.title") }}
    </span>
    <AppLanguageSelector />
  </div>
</template>

<script setup lang="ts">
import AppLanguageSelector from "@/components/AppLanguageSelector.vue";
import AppDateBirthSelector from "@/components/AppBirthDateSelector.vue";

interface Props {
  birthDate?: Date;
}
defineProps<Props>();

const emit = defineEmits<(e: "updatedBirthDate", newDate: Date) => void>();

const updatedBirthDate = (newBirthDate: Date) => emit("updatedBirthDate", newBirthDate);
</script>

<style scoped lang="scss">
.profile-editor {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;

  &__item {
    display: flex;
    flex-direction: column;
  }
}
</style>
