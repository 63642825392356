<template>
  <AppInputGeneric :label="label" :error="error">
    <input v-model="value" type="time" class="input text" :disabled="disabled" />
  </AppInputGeneric>
</template>
<script setup lang="ts">
import { computed } from "vue";
import AppInputGeneric from "@/components/AppInputGeneric.vue";

interface Props {
  modelValue?: string;
  label?: string;
  disabled?: boolean;
  error?: string;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", value: string | undefined) => void>();

const value = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});
</script>
