<template>
  <div data-cy="single-selection-preview">
    <h2 class="margin-b--m text--l">{{ item.title }}</h2>
    <AppRadioButtonList v-model="sampleOption" :options="item.options" :no-gap="true" />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import AppRadioButtonList from "@/components/AppRadioButtonList.vue";
import type { Question } from "@/models/question";
import type { QuestionOption } from "@/models/questionOption";

interface Props {
  item: Question;
}
defineProps<Props>();

const sampleOption = ref<QuestionOption>();
</script>
