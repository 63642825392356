import type { Component } from "vue";
import type { Question } from "@/models/question";
import { QuestionType } from "@/models/questionType";
import AIAssistantAnswerLevel from "@/views/app/ai-assistant/components/AIAssistantAnswerLevel.vue";
import AIAssistantAnswerLongText from "@/views/app/ai-assistant/components/AIAssistantAnswerLongText.vue";
import AIAssistantAnswerMultipleSelection from "@/views/app/ai-assistant/components/AIAssistantAnswerMultipleSelection.vue";
import AIAssistantAnswerShortText from "@/views/app/ai-assistant/components/AIAssistantAnswerShortText.vue";
import AIAssistantAnswerSingleSelection from "@/views/app/ai-assistant/components/AIAssistantAnswerSingleSelection.vue";
import AIAssistantAnswerTags from "@/views/app/ai-assistant/components/AIAssistantAnswerTags.vue";
import SingleChoiceKPI from "@/views/admin/ai-assistant/components/kpis/SingleChoiceKPI.vue";
import MultipleChoiceKPI from "@/views/admin/ai-assistant/components/kpis/MultipleChoiceKPI.vue";
import LevelKPI from "@/views/admin/ai-assistant/components/kpis/LevelKPI.vue";
import TagsKPI from "@/views/admin/ai-assistant/components/kpis/TagsKPI.vue";
import QuestionShortTextPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionShortTextPreview.vue";
import QuestionLongTextPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionLongTextPreview.vue";
import QuestionTagsPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionTagsPreview.vue";
import QuestionLevelPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionLevelPreview.vue";
import QuestionMultipleSelectionPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionMultipleSelectionPreview.vue";
import QuestionSingleSelectionPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionSingleSelectionPreview.vue";

const editors: Map<QuestionType, Component> = new Map<QuestionType, Component>([
  [QuestionType.Level, AIAssistantAnswerLevel],
  [QuestionType.LongText, AIAssistantAnswerLongText],
  [QuestionType.MultipleSelection, AIAssistantAnswerMultipleSelection],
  [QuestionType.ShortText, AIAssistantAnswerShortText],
  [QuestionType.SingleSelection, AIAssistantAnswerSingleSelection],
  [QuestionType.Tags, AIAssistantAnswerTags],
]);
export const getQuestionComponentFor = (question: Question): Component => editors.get(question.type) ?? AIAssistantAnswerShortText;

const kpiComponents = new Map<QuestionType, Component>([
  [QuestionType.Level, LevelKPI],
  [QuestionType.MultipleSelection, MultipleChoiceKPI],
  [QuestionType.SingleSelection, SingleChoiceKPI],
  [QuestionType.Tags, TagsKPI],
]);

export const getQuestionKPIComponentFor = (questionType: QuestionType): Component => kpiComponents.get(questionType) ?? LevelKPI;

const previewers: Map<QuestionType, Component> = new Map<QuestionType, Component>([
  [QuestionType.Level, QuestionLevelPreview],
  [QuestionType.LongText, QuestionLongTextPreview],
  [QuestionType.MultipleSelection, QuestionMultipleSelectionPreview],
  [QuestionType.ShortText, QuestionShortTextPreview],
  [QuestionType.SingleSelection, QuestionSingleSelectionPreview],
  [QuestionType.Tags, QuestionTagsPreview],
]);
export const getPreviewerComponentFor = (questionType: QuestionType): Component => previewers.get(questionType) ?? QuestionShortTextPreview;
