import i18n from "@/i18n";
import { HobbyCategory } from "@/models/hobbyCategory";

export const ageRanges = ["< 35", "35 - 50", "50 <"];

export const hobbiesColorMap = {
  [HobbyCategory.Sport]: "#5AC3E6",
  [HobbyCategory.Music]: "#8D83DB",
  [HobbyCategory.Culture]: "#E7AD6C",
  [HobbyCategory.Technology]: "#DF7BB2",
  [HobbyCategory.Others]: "#B2AEF2",
};

const hobbyCategories: { [index: number]: HobbyCategory } = {
  0: HobbyCategory.Sport,
  1: HobbyCategory.Music,
  2: HobbyCategory.Culture,
  3: HobbyCategory.Technology,
  4: HobbyCategory.Others,
};

export const getHobbyCategoryFromIndex = (index: number): HobbyCategory => hobbyCategories[index];

export const getSeriesLabel = (category: HobbyCategory): string =>
  i18n.global.t(`entities.my_interests.hobbies.category.${category.toLowerCase()}`);
