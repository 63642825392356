<template>
  <div class="nav-container margin-b--m">
    <AppTab :tabs="itAdminTabs" :tab-id="$t('pages.admin.company_settings.title')" />
  </div>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import { CompanyType } from "@/models/companyType";
import type { Company } from "@/models/company";

export type AllowedSettingsTabs = "GeneralInfo" | "RolesManagement" | "ChatBotInstructions" | "AzureInfo";

interface Props {
  company: Company;
  current: AllowedSettingsTabs;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "tabChanged", newTab: AllowedSettingsTabs) => void>();

const isAzureCompany: boolean = props.company.type === CompanyType.CompanyAzureAd;

const getTab = (tabId: AllowedSettingsTabs, titleKey: string): Tab => {
  return {
    text: i18n.global.t(`pages.admin.company_settings.${titleKey}.title`),
    action: () => emit("tabChanged", tabId),
    isChecked: props.current === tabId,
    dataCy: `nav-${tabId}`,
  };
};

const itAdminTabs: Tab[] = [
  getTab("GeneralInfo", "general_info"),
  getTab("RolesManagement", "role_management"),
  getTab("ChatBotInstructions", "chatbot"),
];
if (isAzureCompany) {
  itAdminTabs.push(getTab("AzureInfo", "azure_info"));
}
</script>
