<template>
  <div class="admin-talent-detection__chart">
    <NoContentChart v-if="showNoContent" />
    <div v-else id="chart" data-cy="talent-chart-category-by-age">
      <apexchart :options="chartOptions" :series="chartSeriesData" @click="getSelection" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import i18n from "@/i18n";
import NoContentChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionNoContentChart.vue";
import { getHobbyCategoryFromIndex, getSeriesLabel, hobbiesColorMap } from "@/views/admin/talent-detection/utils";
import type { AgeChartSelection } from "@/models/talent-detection/chartSelections";
import type { ChartSeriesData, ServiceSeriesData } from "@/models/talent-detection/seriesData";
import useCompanyTalentDetectionService from "@/services/useCompanyTalentDetectionService";

const { getSeriesDataByAge } = useCompanyTalentDetectionService();
const serviceData = ref<ServiceSeriesData[]>();
const chartSeriesData = ref<ChartSeriesData[]>([]);
const requested = ref<boolean>(false);
const showNoContent = computed(() => requested.value && !chartSeriesData.value.length);

interface XaxisRange {
  from?: number;
  to?: number;
}

const xaxisRanges: { [key: string]: XaxisRange } = {
  "< 35": { to: 34 },
  "35 - 50": { from: 35, to: 50 },
  "50 <": { from: 51 },
};

const seriesColors = [
  hobbiesColorMap.Sport,
  hobbiesColorMap.Music,
  hobbiesColorMap.Culture,
  hobbiesColorMap.Technology,
  hobbiesColorMap.Others,
];

const xaxisLabels = Object.keys(xaxisRanges);
const xaxisText = i18n.global.t("entities.my_interests.hobbies.age");

const emit = defineEmits<(e: "clicked", value: AgeChartSelection | undefined) => void>();

onMounted(async () => {
  await getTalentDetectionSeriesData();
  requested.value = true;
});

const getTalentDetectionSeriesData = async (): Promise<void> => {
  serviceData.value = await getSeriesDataByAge();
  serviceData.value.forEach((c) => {
    chartSeriesData.value.push({ name: getSeriesLabel(c.category), data: [...c.data] });
  });
};

const chartOptions = {
  chart: {
    type: "bar",
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    type: "category",
    categories: xaxisLabels,
  },
  colors: seriesColors,
  fill: {
    opacity: 1,
  },
  plotOptions: {
    bar: {
      columnWidth: "100%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    followCursor: true,
    x: {
      formatter: function (val: string) {
        return `${xaxisText} ` + val;
      },
    },
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    offsetY: 30,
    itemMargin: {
      horizontal: 20,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSelection = (_event: any, _chartContext: any, config: any): void => {
  if (config.seriesIndex < 0 || config.dataPointIndex < 0) {
    return;
  }

  const selectedDataPointsLength = config.globals.selectedDataPoints.length;
  const selectedDataPoint = config.globals.selectedDataPoints[selectedDataPointsLength - 1];

  if (!selectedDataPoint?.length) {
    emit("clicked", undefined);
    return;
  }

  const hobbyCategory = getHobbyCategoryFromIndex(config.seriesIndex);
  const xaxisLabel = chartOptions.xaxis.categories[config.dataPointIndex];
  const xaxisRange = xaxisRanges[xaxisLabel];

  const selection: AgeChartSelection = {
    ageRange: xaxisRange,
    hobbiesCategory: hobbyCategory,
  };

  emit("clicked", selection);
};
</script>
