import { ref, type Ref, onUnmounted, onMounted } from "vue";
import { mediaQueries } from "@/utils/responsiveUtils";
import { AppCarouselItemsVisible } from "@/components/carousel/appCarouselItemsVisible";

interface UseCarouselReturn {
  numberOfItemsToShow: Ref<AppCarouselItemsVisible>;
}

export default (numberOfItemsVisible: AppCarouselItemsVisible | undefined): UseCarouselReturn => {
  const numberOfItemsToShow = ref<AppCarouselItemsVisible>(numberOfItemsVisible ?? 4);

  const setNumberOfItems = () => {
    if (window.innerWidth < mediaQueries.medium) {
      numberOfItemsToShow.value = 1;
      return;
    }
    if (window.innerWidth < mediaQueries.big) {
      numberOfItemsToShow.value = 2;
      return;
    }
    if (window.innerWidth < mediaQueries.large) {
      numberOfItemsToShow.value = 3;
      return;
    }
    numberOfItemsToShow.value = 4;
  };
  onMounted(() => {
    if (numberOfItemsVisible) {
      return;
    }
    window.addEventListener("resize", setNumberOfItems);
    setNumberOfItems();
  });
  onUnmounted(() => {
    window.removeEventListener("resize", setNumberOfItems);
  });
  return { numberOfItemsToShow };
};
