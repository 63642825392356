<template>
  <div>
    <AnswerOptions :options="question.selectedOptions" />
    <AIAssistantAnswerActions
      v-if="answerEnabled"
      :question="question"
      :editor="AIAssistantAnswerMultipleSelectionEditor"
      @edited="edited" />
  </div>
</template>

<script setup lang="ts">
import type { Question } from "@/models/question";
import AIAssistantAnswerActions from "@/views/app/ai-assistant/components/AIAssistantAnswerActions.vue";
import AIAssistantAnswerMultipleSelectionEditor from "@/views/app/ai-assistant/components/AIAssistantAnswerMultipleSelectionEditor.vue";
import AnswerOptions from "@/views/app/ai-assistant/components/AnswerOptions.vue";

interface Props {
  question: Question;
  answerEnabled: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const edited = (newQuestionState: Question): void => emit("edited", newQuestionState);
</script>

<style lang="scss" scoped>
.answer {
  &__actions {
    flex-shrink: 0;
    width: 50px;
    text-align: right;
  }
}
</style>
