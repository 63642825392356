<template>
  <div class="grid--two-columns@medium">
    <div>
      <h3 data-cy="general-info-title" class="margin-b--l">{{ $t("pages.admin.company_settings.general_info.title") }}</h3>
      <AppForm v-if="company" class="width--50">
        <AppInputText
          v-model="name"
          data-cy="name"
          :label="$t('pages.admin.company_settings.general_info.form.name')"
          :error="nameError"
          :maxlength="mediumTextLength" />
        <AppInputText
          v-model="feedbackEmail"
          data-cy="feedbackEmail"
          :label="$t('pages.admin.company_settings.general_info.form.feedbackEmail')"
          :error="feedbackEmailError"
          :maxlength="mediumTextLength" />
        <AppInputTextArea
          v-model="description"
          data-cy="description"
          :label="$t('pages.admin.company_settings.general_info.form.description')"
          :error="descriptionError"
          :maxlength="largeTextLength" />
        <AppCompanyLanguageSelector v-model="language" data-cy="language" class="margin-b--l" />
        <AppSeparator class="margin-t--l margin-b--l" />
        <div class="btn-container">
          <AppButton
            data-cy="general-info-btn-save"
            size="m"
            variant="primary"
            :text="$t('pages.admin.company_settings.save')"
            @onclick="save" />
        </div>
      </AppForm>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import i18n from "@/i18n";
import { useField, useForm } from "vee-validate";
import { string as yupString } from "yup";
import AppButton from "@/components/AppButton.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppForm from "@/components/AppForm.vue";
import AppCompanyLanguageSelector from "@/components/AppCompanyLanguageSelector.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import { mediumTextLength, largeTextLength } from "@/constants/restrictions";
import useCompanySettingsService from "@/services/useCompanySettingsService";
import useToaster from "@/components/toaster/useToaster";
import useFormSchema from "@/services/useFormSchema";
import type { Company } from "@/models/company";

const { stringSchema, yupObject } = useFormSchema();

const validationSchema = yupObject({
  name: stringSchema(),
  description: stringSchema({ required: false, maxLength: largeTextLength }),
  language: stringSchema(),
  feedbackEmail: yupString().email(),
});

const chatbotUrl = ref<string>("");
const form = useForm({ validationSchema });
const { value: name, errorMessage: nameError } = useField<string>("name");
const { value: description, errorMessage: descriptionError } = useField<string | undefined>("description");
const { value: feedbackEmail, errorMessage: feedbackEmailError } = useField<string | undefined>("feedbackEmail");
const { value: language } = useField<string>("language");

const { getCompany, updateCompany, downloadChatBotUrl } = useCompanySettingsService();
const { openToaster } = useToaster();

const company = ref<Company>();

onMounted(async () => {
  company.value = await getCompany();
  name.value = company.value.name;
  description.value = company.value.description;
  feedbackEmail.value = company.value.feedbackEmail;
  language.value = company.value.language.split("-")[0].toLocaleLowerCase();
  chatbotUrl.value = downloadChatBotUrl();
});

const save = async () => {
  const formValidation = await form.validate();
  if (!formValidation.valid) {
    return;
  }

  await updateCompany(name.value, language.value, description.value, feedbackEmail.value);
  openToaster("success", i18n.global.t("pages.admin.company_settings.general_info.successfully_updated"));
};
</script>

<style lang="scss" scoped>
.nav-container {
  display: flex;
}
.btn-container {
  display: flex;
  justify-content: end;
}
</style>
