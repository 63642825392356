<template>
  <div class="table_column_actions" :class="calculatedClass">
    <AppIconOnlyButton
      v-for="action in visibleActions"
      :key="`${item.id}-${column.id}-${action.name}`"
      :icon="action.icon"
      size="xl"
      :title="(action.customTitle ? action.customTitle(item) : action.title) ?? action.title"
      :class="(action.customClass ? action.customClass(item) : action.class) ?? action.class"
      :disabled="action.disabled && action.disabled(item)"
      @click="action.callback(item)" />
  </div>
</template>

<script lang="ts" setup>
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import type { Column } from "@/components/table/Column";
import type { TableItem } from "@/components/table/TableItem";
import { computed } from "vue";

interface Props {
  column: Column;
  item: TableItem;
}
const props = defineProps<Props>();

const visibleActions = computed(() => props.column.actions?.filter((a) => !a.condition || a.condition(props.item)));
const calculatedClass = `table_column_actions--${props.column.actionsAlignment ?? "end"}`;
</script>

<style lang="scss" scoped>
.table_column_actions {
  display: flex;
  flex-direction: row;
  width: 100%;

  &--start {
    justify-content: start;
  }
  &--end {
    justify-content: end;
  }

  & > * {
    padding: 0 calc(var(--space-xs) / 2);
  }
}
</style>
