import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";
import type { EmployeeCourse, CourseType, EmployeeCourseList } from "@/models/course";
import type { CourseRate } from "@/models/courseRate";
import type { CourseEmployeeRate } from "@/models/courseEmployeeRate";
import type { LearningPath } from "@/models/learningPath";

const BASE_URL = "/api/me/courses";
const { get, put, loading } = useHttpClient();

interface UseMeCoursesServiceReturn {
  searchCourses: (searchTerm: string, pageIndex: number, courseType: CourseType | undefined) => Promise<EmployeeCourseList>;
  getFeaturedCourses: () => Promise<EmployeeCourse[]>;
  getCourseRate: (courseId: number) => Promise<CourseRate>;
  getEmployeeCourseInfo: (courseId: number) => Promise<CourseEmployeeRate>;
  setEmployeeCourseInfo: (courseId: number, rateInfo: CourseEmployeeRate) => Promise<void>;
  getEmployeeLearningPath: () => Promise<LearningPath>;
  getAlreadyDoneCourses: () => Promise<EmployeeCourse[]>;
  loading: Ref<boolean>;
}

const getCourseRouteSegment = (courseType: CourseType | undefined): string => (courseType ? `/${courseType}` : "");

export default (): UseMeCoursesServiceReturn => {
  const getFeaturedCourses = (): Promise<EmployeeCourse[]> => get<EmployeeCourse[]>(`${BASE_URL}/featured`);
  const getEmployeeCourseInfo = (courseId: number): Promise<CourseEmployeeRate> =>
    get<CourseEmployeeRate>(`${BASE_URL}/${courseId}/employee-info`);
  const setEmployeeCourseInfo = (courseId: number, rateInfo: CourseEmployeeRate): Promise<void> =>
    put(`${BASE_URL}/${courseId}/employee-info`, rateInfo);
  const searchCourses = (searchTerm: string, pageIndex: number, courseType: CourseType | undefined): Promise<EmployeeCourseList> =>
    get(`${BASE_URL}/search${getCourseRouteSegment(courseType)}?searchTerm=${searchTerm}&pageIndex=${pageIndex}`);
  const getCourseRate = (courseId: number): Promise<CourseRate> => get<CourseRate>(`${BASE_URL}/${courseId}/rate`);
  const getEmployeeLearningPath = (): Promise<LearningPath> => get<LearningPath>(`${BASE_URL}/learning-path`);
  const getAlreadyDoneCourses = (): Promise<EmployeeCourse[]> => get<EmployeeCourse[]>(`${BASE_URL}/already-done`);

  return {
    getFeaturedCourses,
    searchCourses,
    getEmployeeCourseInfo,
    setEmployeeCourseInfo,
    getCourseRate,
    getEmployeeLearningPath,
    getAlreadyDoneCourses,
    loading,
  };
};
