<template>
  <div data-cy="tags-preview">
    <AppInputTags v-model="sampleTags" :readonly="true" :label="item.title" />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n";

import type { Question } from "@/models/question";
import AppInputTags from "@/components/AppInputTags.vue";

interface Props {
  item: Question;
}
defineProps<Props>();

const sampleTags = ref<string[]>([
  i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample1"),
  i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample2"),
]);
</script>
