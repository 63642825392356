<template>
  <LayoutPage :title="$t('pages.my_profile.title')">
    <MyProfileNavigation current="Profile" />
    <h2 class="margin-v--s">{{ $t("pages-my-profile.profile_updating.welcome_guide.title") }}</h2>
    <p class="text text--bold margin-b--s">{{ $t("pages-my-profile.profile_updating.welcome_guide.subtitle") }}</p>
    <div class="grid--two-columns@large">
      <ProfileSteps @show-example="showExample" />
      <ProfileLinks
        @select-employee-skills="showPerformanceGraphWizard(1)"
        @select-current-job-skills="showPerformanceGraphWizard(2)"
        @select-ideal-job-skills="showPerformanceGraphWizard(3)" />
    </div>
    <AppModal :name="performanceExamplePanelName">
      <ProfilePerformanceExample />
    </AppModal>
    <ProfilePerformanceChartConfigurerWizard
      v-if="configuringPerformanceGraph"
      :initial-step="performanceGraphWizardStep"
      @updated="performanceGraphUpdated"
      @close="configuringPerformanceGraph = false" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { type Ref, ref } from "vue";
import i18n from "@/i18n";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import MyProfileNavigation from "@/views/app/my-profile/components/shared/MyProfileNavigation.vue";
import ProfilePerformanceExample from "@/views/app/my-profile/components/overview/ProfilePerformanceExample.vue";
import ProfileLinks from "@/views/app/my-profile/components/overview/ProfileLinks.vue";
import ProfileSteps from "@/views/app/my-profile/components/overview/ProfileSteps.vue";
import ProfilePerformanceChartConfigurerWizard from "@/views/app/my-profile/components/overview/performance-chart-configurer/ProfilePerformanceChartConfigurerWizard.vue";
import AppModal from "@/components/modal/AppModal.vue";
import useModal from "@/components/modal/useModal";
import router from "@/router/router";

const { openComponentModal } = useModal();

const performanceExamplePanelName = "performanceExample";
const performanceGraphWizardStep: Ref<number> = ref(1);
const configuringPerformanceGraph: Ref<boolean> = ref(false);

const showExample = (): void => {
  openComponentModal(performanceExamplePanelName, {
    title: i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.title"),
    variant: "floating",
    showCancelToClose: false,
    confirmText: i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.close"),
    confirmCallback: () => true,
  });
};
const showPerformanceGraphWizard = (step: number): void => {
  performanceGraphWizardStep.value = step;
  configuringPerformanceGraph.value = true;
};
const performanceGraphUpdated = async () => {
  configuringPerformanceGraph.value = false;
  await router.push({ name: "my-profile-dashboard" });
};
</script>
