<template>
  <div class="carousel">
    <div class="carousel__container" data-cy="carousel-container" @wheel="wheelHandle">
      <div class="wrapper">
        <div v-for="item in numberOfItems" :key="item" class="wrapper__item">
          <slot :name="`item_${item}`"> </slot>
        </div>
      </div>
      <AppCarouselActions :current-page="currentPage" :page-count="pageCount" @page-click="setPage" />
    </div>
    <AppCarouselNavigation :current-page="currentPage" :page-count="pageCount" @next-page="nextPage" @previous-page="previousPage" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import AppCarouselNavigation from "@/components/carousel/AppCarouselNavigation.vue";
import AppCarouselActions from "@/components/carousel/AppCarouselActions.vue";
import { useDebounce } from "@/services/useDebounce";
import useCarousel from "@/components/carousel/useCarousel";
import type { AppCarouselItemsVisible } from "@/components/carousel/appCarouselItemsVisible";

interface Props {
  numberOfItemsVisible?: AppCarouselItemsVisible;
  numberOfItems: number;
}
const props = defineProps<Props>();

const { after } = useDebounce();
const { numberOfItemsToShow } = useCarousel(props.numberOfItemsVisible);

const currentPage = ref<number>(1);
const wheeling = ref<boolean>(false);
const itemWidthAdjustment = computed<number>(() => (numberOfItemsToShow.value === 4 ? 16 : 0));
const wheelHandle = (eventArgs: WheelEvent) => {
  if (wheeling.value || !eventArgs.deltaX || eventArgs.deltaY || eventArgs.deltaZ) {
    return;
  }
  wheeling.value = true;
  eventArgs.preventDefault();

  eventArgs.deltaX < 0 ? nextPage() : previousPage();
  after(150, () => (wheeling.value = false));
};
watch(
  () => numberOfItemsToShow.value,
  () => setPage(1)
);

const itemPercentageWidth = computed(() => 100 / props.numberOfItems);
const pageCount = computed(() => Math.ceil(props.numberOfItems / numberOfItemsToShow.value));
const itemWidthStyle = computed(() => `calc(${itemPercentageWidth.value}% - var(--carousel-gap-half) - ${itemWidthAdjustment.value}px)`);
const carouselWidth = computed(() => Math.ceil((props.numberOfItems * 100) / numberOfItemsToShow.value) + "%");
const carouselTranslate = computed(() => -(currentPage.value - 1) * (100 / props.numberOfItems) * numberOfItemsToShow.value + "%");

const nextPage = () => {
  if (currentPage.value >= pageCount.value) {
    return;
  }
  currentPage.value++;
};
const previousPage = () => {
  if (currentPage.value === 1) {
    return;
  }
  currentPage.value--;
};
const setPage = (pageIndex: number) => (currentPage.value = pageIndex);
</script>
<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.carousel {
  --carousel-gap: var(--size-l);
  --carousel-gap-half: calc(var(--carousel-gap) / 2);

  width: 100%;
  display: flex;
  gap: var(--size-xs);

  @media only screen and (max-width: $medium-screen-breakpoint) {
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    overflow-x: hidden;
    transform: translateX(calc(var(--size-l) * -1));
    @media only screen and (max-width: $medium-screen-breakpoint) {
      margin-left: var(--space-xs);
    }
  }
}

.wrapper {
  display: flex;
  position: relative;
  height: 100%;
  width: v-bind(carouselWidth);
  overflow: hidden;
  transform: translateX(v-bind(carouselTranslate));
  transition: transform 1s ease-in-out;

  &__item {
    height: 100%;
    margin-right: var(--carousel-gap-half);
    margin-left: var(--carousel-gap-half);
    width: v-bind(itemWidthStyle);

    @media only screen and (max-width: $medium-screen-breakpoint) {
      margin-left: var(--size-xxl);
    }
  }
}
</style>
