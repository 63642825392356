<template>
  <AppPanel :border="false" :shadow="true">
    <div class="container">
      <div class="channel-logo">
        <img :src="logoUrl" :alt="title" />
        <span class="text text--xl text--bold">{{ title }}</span>
      </div>
      <slot></slot>
      <ChatBotInstructionsSteps :channel-name="channelName" />
    </div>
  </AppPanel>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n";
import AppPanel from "@/components/AppPanel.vue";
import ChatBotInstructionsSteps from "@/views/admin/company-settings/components/chatbot/ChatBotInstructionsSteps.vue";

interface Props {
  channelName: "teams" | "slack";
  logoUrl: string;
  channelColor: string;
}
const props = defineProps<Props>();

const title = computed(() => i18n.global.t(`pages.admin.company_settings.general_info.chatbot.${props.channelName}.title`));
</script>
<style lang="scss" scoped>
.channel-logo {
  background-color: v-bind(channelColor);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  place-items: center;
  padding: var(--space-m);
  border-radius: var(--space-xs);
}
.container {
  display: grid;
  row-gap: var(--size-m);
  grid-template-columns: repeat(auto-fill, minmax(20rem, 40rem));

  &__download {
    border: 1px solid var(--color-border);
    border-radius: var(--space-xs);
    padding: var(--size-s);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--size-xs);
  }

  &__button {
    display: flex;
    justify-content: end;
  }
  span.text--bold {
    color: var(--color-neutral-700);
  }
}
</style>
