<template>
  <AppSelect
    :model-value="value"
    :disabled="column.select?.disabled && column.select?.disabled(item)"
    :items="column.select?.options ?? []"
    :keep-margin="false"
    :inline="true"
    @update:model-value="handleChange" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import type { Column } from "@/components/table/Column";
import type { TableItem } from "@/components/table/TableItem";
import AppSelect from "@/components/AppSelect.vue";

interface Props {
  column: Column;
  modelValue: TableItem;
}
const props = defineProps<Props>();
const value = computed<string>(() => {
  const fieldName = props.column.fieldName as keyof typeof item.value;
  const itemValue = item.value[fieldName];
  return itemValue ? itemValue.toString() : "";
});
const handleChange = async (newValue: string) => {
  const fieldName = props.column.fieldName as keyof typeof item.value;
  (item.value as unknown as { [name: string]: string })[fieldName] = newValue;
  await props.column.select?.onUpdate(item.value);
};

const item = ref<TableItem>(props.modelValue);
</script>
