import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";
import type { IdealJob, IdealJobBase } from "@/models/idealJob";
import type { SkillUpdateRequest } from "@/models/requests/skillUpdateRequest";

const BASE_URL = "/api/me/ideal-job";
const { get, post, loading } = useHttpClient();

interface UseMeIdealJobServiceReturn {
  getMyIdealJob: () => Promise<IdealJob>;
  setIdealJob: (idealJob: IdealJobBase) => Promise<void>;
  setIdealJobSkills: (request: SkillUpdateRequest) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeIdealJobServiceReturn => {
  const getMyIdealJob = (): Promise<IdealJob> => get(BASE_URL);
  const setIdealJob = (idealJob: IdealJobBase) => post<void>(BASE_URL, idealJob);
  const setIdealJobSkills = (request: SkillUpdateRequest) => post<void>(`${BASE_URL}/skills`, request);

  return { getMyIdealJob, setIdealJob, setIdealJobSkills, loading };
};
