import useSpaConfigurationService from "@/services/useSpaConfigurationService";

interface UseFeatureFlagsReturn {
  checkFeatureFlag: (featureFlagName: string | undefined) => boolean;
}

export default (): UseFeatureFlagsReturn => {
  const { getFeatureFlags } = useSpaConfigurationService();
  const checkFeatureFlag = (featureFlagName: string | undefined): boolean => {
    if (!featureFlagName) {
      return true;
    }
    const featureManagementOptions = getFeatureFlags();
    return !featureManagementOptions || !!featureManagementOptions[featureFlagName];
  };

  return { checkFeatureFlag };
};
