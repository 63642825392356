<template>
  <div
    class="question-wizard-type-item"
    :class="{
      'question-wizard-type-item--selected': selected,
      'question-wizard-type-item--disabled': disabled,
    }"
    :data-cy="`question-option-${item.resultingType}`">
    <div class="header">{{ item.title }}</div>
    <div class="content text">
      {{ item.description }}
    </div>
    <div class="preview">
      <AppSkillRange v-if="item.resultingType === QuestionType.Level" />
      <AppRadioButtonList
        v-if="item.resultingType === QuestionType.SingleSelection"
        v-model="sampleOptions[0]"
        :options="sampleOptions"
        :no-gap="true" />
      <div v-if="item.resultingType === QuestionType.MultipleSelection">
        <AppCheckbox
          v-for="option in sampleOptions"
          :key="option.optionId"
          v-model="sampleSelection"
          :text="option.title"
          class="margin-b--xs" />
      </div>
      <AppInputTextArea v-if="item.resultingType === QuestionType.LongText" label="Long answer" />
      <AppInputText v-if="item.resultingType === QuestionType.ShortText" label="Short answer" />
      <AppInputTags v-if="item.resultingType === QuestionType.Tags" v-model="sampleTags" :readonly="true" label="Cities" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { Ref } from "vue";
import i18n from "@/i18n";
import AppSkillRange from "@/components/AppSkillRange.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppInputTags from "@/components/AppInputTags.vue";
import AppRadioButtonList from "@/components/AppRadioButtonList.vue";
import AppCheckbox from "@/components/AppCheckbox.vue";
import { QuestionType } from "@/models/questionType";
import type { QuestionTypeItem } from "@/views/admin/ai-assistant/models/questionTypeItem";

interface Props {
  item: QuestionTypeItem;
  selected: boolean;
  disabled: boolean;
}
defineProps<Props>();

const sampleOptions = ref([
  { optionId: 1, title: i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample1") },
  { optionId: 2, title: i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample2") },
]);

const sampleTags: Ref<string[]> = ref([i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample1")]);
const sampleSelection: Ref<boolean> = ref(true);
</script>
<style lang="scss" scoped>
.question-wizard-type-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: var(--color-card);
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(26, 26, 26, 0.04);

  &--selected {
    outline: 3px solid var(--color-primary-accent1);
    border-color: transparent;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.header {
  padding: var(--size-xs) var(--size-s);
  font-size: 1.4rem;
  font-weight: var(--font-bold);
  background-color: var(--color-card--accent1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: var(--color-neutral-700);
}

.content {
  padding: var(--size-s) var(--size-s) 0 var(--size-s);
  flex: 1;
}

.preview {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: var(--size-s);
  pointer-events: none;
  background: var(--color-card--dark);
  border-radius: var(--default-border-radius);
  padding: var(--size-xs) var(--size-s);
  filter: grayscale(100%);
  align-items: center;
  min-height: 13rem;
}
</style>
