<template>
  <LayoutPage :title="group?.name">
    <Teleport v-if="loaded" to="#header-button-container">
      <AppIconButton :text="$t('shared.back')" size="l" icon="arrow-back" @onclick="backToList" />
    </Teleport>
    <div class="padding-b--m">
      <AppTab :tabs="tabs" tab-id="group-manage" />
    </div>
    <AppGenericSubHeader class="margin-b--m" :text="$t(`pages.admin.company_settings.employee_groups.manage.title.${currentTab}`)" />
    <CompanyEmployeeGroupRolesTable v-if="currentTab === 'Employees' && group" :group-id="groupId" />
    <CompanyEmployeeGroupQuestions v-if="currentTab === 'Questions' && group" :group-id="groupId" />
    <CompanyEmployeeGroupLearning v-if="currentTab === 'Learning' && group" :group-id="groupId" />
  </LayoutPage>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppIconButton from "@/components/AppIconButton.vue";
import AppGenericSubHeader from "@/components/AppGenericSubHeader.vue";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import { backToList } from "@/views/admin/company-settings/company-employee-groups/utils/companyEmployeeGroupsUtils";
import CompanyEmployeeGroupRolesTable from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeeGroupRolesTable.vue";
import CompanyEmployeeGroupLearning from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeeGroupLearning.vue";
import CompanyEmployeeGroupQuestions from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeeGroupQuestions.vue";
import type { CompanyEmployeeGroup } from "@/models/companyEmployeeGroup";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";

type TabIds = "Employees" | "Questions" | "Learning";

const route = useRoute();

const groupId = parseInt(route.params.groupId as string) || 0;
const group = ref<CompanyEmployeeGroup>();
const loaded = ref<boolean>(false);
const currentTab = ref<TabIds>("Employees");

const { getCompanyEmployeeGroup } = useCompanyEmployeeGroupsService();

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages.admin.company_settings.employee_groups.tabs.employees"),
    action: () => (currentTab.value = "Employees"),
    isChecked: true,
    dataCy: "employee-roles-tab",
  },
  {
    text: i18n.global.t("pages.admin.company_settings.employee_groups.tabs.questions"),
    action: () => (currentTab.value = "Questions"),
    dataCy: "questions-tab",
  },
  {
    text: i18n.global.t("pages.admin.company_settings.employee_groups.tabs.learning"),
    action: () => (currentTab.value = "Learning"),
    dataCy: "learning-tab",
  },
];

const fetchData = async () => {
  if (!groupId) {
    return;
  }
  group.value = await getCompanyEmployeeGroup(groupId);
};

onMounted(async () => {
  await fetchData();
  loaded.value = true;
});
</script>
