<template>
  <div class="layout-menu-item" :title="text" :data-cy="icon">
    <div class="icon-container">
      <span class="icon" :class="iconClass"></span>
    </div>
    <div class="text-container text text--l">{{ text }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { MenuOptionIcon } from "@/layout/models/menu-option";

interface Props {
  icon: MenuOptionIcon;
  text: string;
}

const props = defineProps<Props>();

const iconClass = computed(() => `icon-${props.icon}`);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.layout-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.icon-container {
  flex: 0 0 70px;
  font-size: 3.2rem;
  text-align: center;
}

.text-container {
  padding: 0 var(--space-s);
}

@media only screen and (max-width: $large-screen-breakpoint) {
  .text-container {
    display: none;
  }
}

@media only screen and (max-width: $medium-screen-breakpoint) {
  .layout-menu-item {
    display: block;
  }
}
</style>
