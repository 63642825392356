<template>
  <div class="skills-list">
    <div>
      <AppTag
        v-for="skill of input"
        :key="skill.name"
        class="margin-b--xs margin-r--xs"
        data-cy="current-experience-skills"
        :main-text="skill.name"
        :secondary-text="$t(`skill_range.skill_names.${skill.progression.toLowerCase()}`)"
        type="skill" />
    </div>
    <AppLinkButton
      v-if="formMode !== 'view'"
      data-cy="skill-editor-edit-skill-button"
      :text="$t(calculateButtonText)"
      @click="creatingSkill = true" />

    <AppAutomaticModalOpener v-if="creatingSkill" :modal-config="modalConfig">
      <AppSkillEditor :input="input" :skills-type="skillsType" @updated="skillsUpdated" />
    </AppAutomaticModalOpener>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, type Ref } from "vue";
import i18n from "@/i18n";
import AppTag from "@/components/AppTag.vue";
import type { SkillWithProgression } from "@/models/skillWithProgression";
import AppSkillEditor from "@/components/AppSkillEditor.vue";
import { SkillLocationType, SkillType } from "@/models/skillType";
import type { SkillUpdateRequest } from "@/models/requests/skillUpdateRequest";
import type { FormMode } from "@/models/formMode";
import useMeIdealJobService from "@/services/useMeIdealJobService";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import type { ModalBase } from "@/components/modal/models";
import useModal from "@/components/modal/useModal";
import useMeJobExperienceService from "@/services/useMeJobExperienceService";
import useMeSkillsService from "@/services/useMeSkillsService";
import AppLinkButton from "@/components/AppLinkButton.vue";

interface Props {
  input: SkillWithProgression[];
  skillsType: SkillType;
  formMode?: FormMode;
  skillLocation?: SkillLocationType;
}
const props = withDefaults(defineProps<Props>(), { formMode: "edit", skillLocation: SkillLocationType.Employee });

const emit = defineEmits<(e: "updated", skills: SkillWithProgression[]) => void>();

const { updateSkills } = useMeSkillsService();
const { updateCurrentJobExperienceSkills } = useMeJobExperienceService();
const { setIdealJobSkills } = useMeIdealJobService();
const { openTextModal } = useModal();

const skills: Ref<SkillWithProgression[]> = ref<SkillWithProgression[]>([]);
const creatingSkill: Ref<boolean> = ref<boolean>(false);
const needsToNotify = ref<boolean>(false);

const skillsUpdated = (updatedSkills: SkillWithProgression[] | undefined, needsToNotifyParam: boolean): void => {
  skills.value = updatedSkills ? [...updatedSkills].sort(skillsSorter) : [];
  needsToNotify.value = needsToNotifyParam;
};

const skillsSorter = (a: SkillWithProgression, b: SkillWithProgression) => a.name.localeCompare(b.name);

const modalConfig: ModalBase = {
  title: i18n.global.t(`pages.my_profile.dashboards.successful_skills.${props.skillsType.toLowerCase()}`),
  variant: "floating",
  confirmText: i18n.global.t("buttons.save"),
  showCancelToClose: true,
  confirmCallback: () => createSkill(),
  onClosed: () => (creatingSkill.value = false),
};

const updateSelectedSkills = async () => {
  const request: SkillUpdateRequest = {
    type: props.skillsType,
    skills: skills.value,
  };
  const saveMethods: Record<SkillLocationType, (request: SkillUpdateRequest) => Promise<void>> = {
    CurrentJob: updateCurrentJobExperienceSkills,
    IdealJob: setIdealJobSkills,
    Employee: updateSkills,
  };
  await saveMethods[props.skillLocation](request);
  emit("updated", skills.value);
};

const createSkill = async (): Promise<void | boolean> => {
  if (!needsToNotify.value) {
    return updateSelectedSkills();
  }
  return new Promise((resolve, _reject) => {
    openTextModal({
      title: i18n.global.t("pages.current_experience.confirm-delete-on-graph.title"),
      contentText: i18n.global.t("pages.current_experience.confirm-delete-on-graph.message"),
      showCancelToClose: true,
      confirmCallback: async () => {
        await updateSelectedSkills();
        resolve(true);
      },
      cancelCallback: () => resolve(false),
    });
  });
};

const calculateButtonText = computed(() =>
  props.skillsType === SkillType.Language ? "pages.current_experience.language.edit" : "pages.current_experience.skills.edit"
);
</script>

<style scoped lang="scss">
.skills-list {
  display: flex;
  flex-direction: column;
  gap: var(--size-xs);
}
</style>
