import { string as yupString, array as yupArray } from "yup";
import type { Question } from "@/models/question";
import { QuestionType } from "@/models/questionType";
import useFormSchema from "@/services/useFormSchema";
import type { FormValidator } from "@/models/formValidator";
import { useForm } from "vee-validate";

const { yupObject, stringSchema, inputRequiredText } = useFormSchema();

interface UseQuestionFormReturn {
  getQuestionForm: (question: Question) => FormValidator;
}

export default (): UseQuestionFormReturn => {
  const getQuestionForm = (question: Question): FormValidator => {
    const levelTextSchema = () =>
      yupString().test("required", inputRequiredText(), (value) => question.type !== QuestionType.Level || stringSchema().isValid(value));

    const formScheme = yupObject({
      title: stringSchema(),
      options: yupArray().of(
        yupObject({
          title: stringSchema(),
        })
      ),
      levels: yupObject({
        lowLevelText: levelTextSchema(),
        mediumLevelText: levelTextSchema(),
        highLevelText: levelTextSchema(),
      }),
    });
    return useForm({ validationSchema: formScheme });
  };

  return { getQuestionForm };
};
