<template>
  <LayoutPage :title="$t('pages.my_groups.title')">
    <EmployeeSearch :placeholder="$t('pages.my_groups.search.placeholder')" class="margin-b--m" @select-item="selectEmployee" />
    <AppBox v-if="currentGroup" :title="$t('pages.my_groups.title')" :full-space="true" data-cy="group-list-container">
      <AppTab :tabs="tabs" :tab-id="`group-${currentGroup.id}`" class="margin--s margin-b--0" data-cy="group-list-nav" />
      <MyGroupsTable :employees="currentGroup.employees" @select-item="selectEmployee" />
    </AppBox>
    <div class="btn-container">
      <AppButton
        data-cy="my-groups-questions-btn"
        size="m"
        variant="secondary"
        :text="$t('pages.my_groups.view_questions')"
        @onclick="goToQuestions" />
    </div>
  </LayoutPage>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router/router";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import EmployeeSearch from "@/views/shared/employee/components/EmployeeSearch.vue";
import AppBox from "@/components/AppBox.vue";
import useEmployeeService from "@/services/useEmployeeService";
import MyGroupsTable from "@/views/app/my-groups/components/MyGroupsTable.vue";
import type { CompanyEmployeeGroupWithEmployees } from "@/models/companyEmployeeGroup";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import AppButton from "@/components/AppButton.vue";

const route = useRoute();
const { getEmployeeManagedGroups } = useEmployeeService();

const groups = ref<CompanyEmployeeGroupWithEmployees[]>([]);
const currentGroup = ref<CompanyEmployeeGroupWithEmployees>();
const tabs = ref<Tab[]>([]);

const { groupId } = route.query;

const selectEmployee = (employeeId: number) =>
  router.push({ name: "my-groups-employee", params: { employeeId }, query: { groupId: currentGroup.value?.id } });

const goToQuestions = () => router.push({ name: "my-groups-questions" });

const selectGroup = (group: CompanyEmployeeGroupWithEmployees) => (currentGroup.value = group);

const createTabs = (source: CompanyEmployeeGroupWithEmployees[]): void => {
  tabs.value = source.map((group) => ({
    text: group.name,
    action: () => selectGroup(group),
    isChecked: currentGroup.value?.id === group.id,
    dataCy: `group-${group.id}`,
  }));
};

const fetchData = async () => {
  groups.value = await getEmployeeManagedGroups();

  if (groups.value.length) {
    currentGroup.value = (groupId && groups.value.find((g) => g.id === +groupId)) || groups.value[0];
    createTabs(groups.value);
  }
};

onMounted(fetchData);
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: end;
  margin-top: var(--space-m);
}
</style>
