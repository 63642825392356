<template>
  <apexchart :options="chartOptions" :series="series" :width="width" :height="height" />
</template>

<script lang="ts" setup>
import { formatNumber } from "@/utils/numberUtils";
import { computed } from "vue";
const calculateOptions = (): { barWidth: string; startAngle: number; endAngle: number; fontSize: number } => {
  if (props.shape === "half") {
    return {
      barWidth: "68%",
      startAngle: -90,
      endAngle: 90,
      fontSize: 20,
    };
  }
  if (props.shape === "fullMedium") {
    return {
      barWidth: "80%",
      startAngle: 0,
      endAngle: 360,
      fontSize: 16,
    };
  }

  return {
    barWidth: "85%",
    startAngle: 0,
    endAngle: 360,
    fontSize: props.shape === "full" ? 30 : 14,
  };
};
interface Props {
  percentage: number;
  label?: string;
  shape?: "full" | "half" | "fullSmall" | "fullMedium";
  width?: number;
  height?: number;
  color?: string;
}

const props = withDefaults(defineProps<Props>(), { shape: "full", label: "", color: "#1E9AC6" });

const options = calculateOptions();

const series = computed(() => [props.percentage, 100 - props.percentage]);

const chartOptions = {
  chart: {
    type: "donut",
    selection: {
      enabled: false,
    },
  },
  states: {
    active: {
      filter: {
        type: "none",
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    normal: {
      filter: {
        type: "none",
      },
    },
  },
  colors: [props.color, "rgba(26, 26, 26, .06)"],
  plotOptions: {
    pie: {
      donut: {
        background: "transparent",
        size: options.barWidth,
        labels: {
          show: true,
          name: {
            show: true,
            offsetY: 25,
          },
          value: {
            show: true,
            offsetY: -12,
            fontSize: options.fontSize,
            fontFamily: "Inter",
            fontWeight: 700,
          },
          total: {
            show: true,
            showAlways: true,
            label: props.label,
            fontSize: options.fontSize / 2,
            color: "#666666",
            formatter: (w: { globals: { seriesTotals: [] } }): string => {
              const result = w.globals.seriesTotals.reduce((a: number, b: number, index: number) => (index === 0 ? a + b : a), 0);
              return `${formatNumber(result)} %`;
            },
          },
        },
      },
      startAngle: options.startAngle,
      endAngle: options.endAngle,
      expandOnClick: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: false,
  },
  fill: {
    type: "solid",
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 160,
        },
      },
    },
  ],
};
</script>
