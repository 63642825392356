<template>
  <div class="app-label" :class="{ 'app-label--compress': compress }">
    <label v-if="label" :class="labelClasses" class="text text--secondary">{{ label }}</label>
    <label v-if="value && !link" class="text text--bold" :class="textClasses" :title="compress ? text : ''">{{ text }}</label>
    <a v-if="value && link" class="text text--link" :href="text" target="_blank">{{ text }}</a>
  </div>
</template>

<script setup lang="ts">
import { parseDateToString } from "@/utils/dateUtils";
import { formatNumber } from "@/utils/numberUtils";
import { computed } from "vue";

interface Props {
  label?: string;
  value?: string | number | Date;
  formatting?: "normal" | "bold" | "italic";
  link?: boolean;
  transformation?: "capitalize" | "uppercase";
  compress?: boolean;
}
const props = withDefaults(defineProps<Props>(), { formatting: "normal", link: false });

const labelClasses = computed(() => ({
  [`text--${props.formatting}`]: props.formatting !== "normal",
}));
const textClasses = computed(() => ({
  [`text--${props.transformation}`]: props.transformation,
  [`text--ellipsis`]: props.compress,
}));

const text = computed(() => {
  if (!props.value) {
    return "";
  }
  if (typeof props.value === "number") {
    return formatNumber(props.value);
  }
  if (props.value instanceof Date) {
    return parseDateToString(props.value);
  }
  return props.value;
});
</script>

<style scoped lang="scss">
.app-label {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-s);

  &--compress {
    row-gap: var(--space-xs);
  }
}
</style>
