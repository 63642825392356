import i18n from "@/i18n";
import type { MenuOption, MenuOptionIcon } from "@/layout/models/menu-option";

interface UseLayoutReturn {
  getOption: (optionId: MenuOptionIcon, urlSuffix?: string, textKey?: string) => MenuOption;
}

type LayoutType = "admin" | "app" | "it-manager";

export const useLayout = (type: LayoutType): UseLayoutReturn => {
  const getOption = (icon: MenuOptionIcon, urlSuffix?: string, textKey?: string): MenuOption => {
    urlSuffix = urlSuffix ?? icon;
    textKey = textKey ?? getTitleKey(icon);
    return {
      url: `/${type}/${urlSuffix}`,
      icon: icon,
      text: i18n.global.t(textKey),
    };
  };

  const getTitleKey = (icon: MenuOptionIcon) => {
    const pageKey = type === "admin" ? `${type}.` : "";
    return `pages.${pageKey}${icon.replace("-", "_")}.title`;
  };

  return { getOption };
};
