<template>
  <div class="app-icon-button" :data-cy="`app-icon-button-${icon}`" @click="click">
    <div class="icon-container">
      <span class="text text--link icon" :class="iconClasses"></span>
    </div>
    <div v-if="text" class="text-container text text--link" :class="{ 'text--xl': size === 'l' }">{{ text }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  size?: "m" | "l";
  text?: string;
  icon: string;
}
const props = withDefaults(defineProps<Props>(), {
  size: "l",
});

const emit = defineEmits<(e: "onclick") => void>();

const iconClasses = computed(() => ({
  [`icon-${props.icon}`]: true,
  "text--xl": props.size === "l",
}));
const click = () => emit("onclick");
</script>

<style scoped lang="scss">
.app-icon-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-s);
  cursor: pointer;

  &:hover {
    filter: brightness(50%);
  }
}

.icon-container {
  border: 1px solid var(--color-link);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
