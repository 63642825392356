<template>
  <button class="app-button text text--bold" :class="buttonClasses" :disabled="disabled" @click.prevent="click()">
    <span v-if="icon" class="icon" :class="`icon-${props.icon}`"></span>{{ text }}
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  variant?: "primary" | "secondary";
  size?: "m" | "l" | "full-width";
  disabled?: boolean;
  text: string;
  grouped?: "start" | "end" | "middle";
  icon?: string;
}
const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: "primary",
  size: "l",
});

const emit = defineEmits<(e: "onclick") => void>();

const click = (): void => emit("onclick");

const buttonClasses = computed(() => [
  `app-button--${props.variant}`,
  `app-button--size-${props.size}`,
  props.size === "l" ? "text--l" : "",
  props.grouped ? `app-button--grouped-${props.grouped}` : "",
]);
</script>

<style scoped lang="scss">
.app-button {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  border: 0;
  border-radius: 4px;

  &:disabled {
    cursor: not-allowed;
  }

  &--grouped-start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--grouped-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &--grouped-middle {
    border-radius: 0;
  }

  &--primary {
    color: var(--color-button-primary-text);
    background-color: var(--color-button-primary-background);
    border: 1px solid var(--color-button-primary-background);

    &:focus-visible {
      background-color: var(--color-button-primary--pressed);
      border-color: var(--color-button-primary--pressed);
    }

    &:hover {
      background-color: var(--color-button-primary--hover);
      border-color: var(--color-button-primary--hover);
    }

    &:active {
      background-color: var(--color-button-primary--pressed);
      border-color: var(--color-button-primary--pressed);
    }

    &:disabled {
      color: var(--color-button-primary-text--disabled);
      background-color: var(--color-button-primary-background--disabled);
      border-color: var(--color-button-primary-text--disabled);
    }
  }

  &--size-full-width {
    padding: 11px 0;
    width: 100%;
  }

  &--size-m {
    padding: 10px 13px;
  }

  &--size-l {
    padding: 14px 13px;
  }

  &--secondary {
    color: var(--color-button-secondary-text);
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid var(--color-button-secondary-text);

    &:focus-visible {
      background-color: var(color-button-secondary--pressed);
    }

    &:hover {
      background-color: var(--color-button-secondary--hover);
    }

    &:active {
      background-color: var(--color-button-secondary--pressed);
    }

    &:disabled {
      color: var(--color-button-secondary-text--disabled);
      background-color: var(--color-button-secondary-background--disabled);
      border-color: var(--color-button-secondary-text--disabled);
    }
  }

  .icon {
    padding-right: 10px;
  }
}
</style>
