<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :disable-key-enter="true">
    <AppForm>
      <AppInputText
        v-model="name"
        :maxlength="mediumTextLength"
        :label="$t('shared.skillCreator.form.name')"
        :error="nameError"
        data-cy="skill-editor-name" />
      <AppInputTextArea
        v-model="description"
        :label="$t('shared.skillCreator.form.description')"
        :rows="10"
        :maxlength="largeTextLength"
        :error="descriptionError"
        data-cy="skill-editor-description" />
    </AppForm>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import i18n from "@/i18n";
import { largeTextLength, mediumTextLength } from "@/constants/restrictions";
import { SkillStatus } from "@/models/skillStatus";
import { useField, useForm } from "vee-validate";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppForm from "@/components/AppForm.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import type { ModalBase } from "@/components/modal/models";
import type { Skill } from "@/models/skill";
import useFormValidator from "@/services/useFormValidator";
import useFormSchema from "@/services/useFormSchema";
import useSkillService from "@/services/useSkillService";

interface Props {
  skill: Skill;
}
const props: Props = defineProps<Props>();

interface Emits {
  (e: "closed"): void;
  (e: "saved"): void;
}
const emit = defineEmits<Emits>();

const { yupObject, stringSchema } = useFormSchema();

const isPendingRequest: boolean = props.skill.status === SkillStatus.Pending;

const resourceSchema = yupObject({
  name: stringSchema(),
  description: stringSchema({ maxLength: largeTextLength }),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: name, errorMessage: nameError } = useField<string>("name");
const { value: description, errorMessage: descriptionError } = useField<string>("description");

const { updateSkill } = useSkillService();
const { getFormCallbackHandler } = useFormValidator();

const save = async () => {
  await updateSkill(getSkillModified());
  emit("saved");
};

const modalConfig: ModalBase = {
  title: i18n.global.t("pages.admin.skill_management.edit_skill"),
  confirmText: isPendingRequest ? i18n.global.t("pages.admin.skill_management.moderate") : i18n.global.t("buttons.save"),
  variant: "floating",
  confirmCallback: getFormCallbackHandler(form, save),
  onClosed: () => emit("closed"),
  showCancelToClose: true,
};

const getSkillModified = (): Partial<Skill> => {
  return {
    description: description.value,
    name: name.value,
    id: props.skill.id,
  } as Partial<Skill>;
};

onMounted(() => setFormValues());

const setFormValues = (): void => {
  name.value = props.skill.name;
  description.value = props.skill.description ?? "";
};
</script>
