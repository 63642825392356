<template>
  <div class="accordion-panel" :class="classes">
    <div class="accordion-panel__header" @click="toggle">
      <div class="accordion-panel__title text--m text--bold text--ellipsis">{{ title }}</div>
      <div v-if="!disabled" class="accordion-panel__expander">
        <span class="text icon text--xxl" :class="`icon-${icon}`"></span>
      </div>
      <slot v-else name="disabled-info"></slot>
    </div>
    <div v-if="expanded" class="accordion-panel__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

interface Props {
  title: string;
  disabled?: boolean;
  icon?: string;
}
const props = withDefaults(defineProps<Props>(), { icon: "chevron-down" });

const expanded = ref<boolean>(false);
const classes = computed(() => ({
  expanded: expanded.value,
  disabled: props.disabled,
}));

const toggle = () => !props.disabled && (expanded.value = !expanded.value);
</script>

<style lang="scss" scoped>
.accordion-panel {
  margin-bottom: var(--space-xs);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-xs) var(--space-s);
    border-radius: 4px;
    height: 2.875em;
    cursor: pointer;
    background-color: var(--color-accordion-panel-header-background);

    .expanded & {
      background-color: var(--color-accordion-panel-header-background--expanded);
    }

    .disabled & {
      cursor: default;
    }
  }

  &__expander {
    rotate: 0deg;
    transition: rotate 0.3s ease-in-out;

    span {
      color: var(--color-accordion-panel-expander);
    }

    .expanded & {
      rotate: 180deg;
    }
  }

  &__title {
    color: var(--color-accordion-panel-title);

    .disabled & {
      color: var(--color-accordion-panel-title--disabled);
    }
  }

  &__content {
    margin-top: var(--space-xs);
    padding: var(--space-s);
    display: flex;
    flex-direction: row;
    gap: var(--space-s);
  }
}
</style>
