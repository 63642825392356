<template>
  <LayoutPage :title="$t('pages.admin.company_goals.title')">
    <div v-if="goals.length > 0" class="admin-company-goals">
      <div class="admin-company-goals__filters">
        <AppInputText
          v-model="searchText"
          :placeholder="$t('pages.admin.company_goals.search.placeholder')"
          :maxlength="200"
          icon="magnify"
          :keep-margin="false"
          :bg-transparent="true"
          data-cy="search"
          @update:model-value="updateSearch" />
        <AppSelect v-model="order" :items="allowedOrders" :keep-margin="false" />
      </div>
      <div class="admin-company-goals__new-goal">
        <AppButton
          :text="$t('pages.admin.company_goals.new_goal')"
          size="m"
          variant="primary"
          data-cy="new-goal"
          @onclick="addingNewGoal = true" />
      </div>
      <div class="admin-company-goals__filter-options">
        <AppTableFilters v-model="filters" data-cy="filter-options" />
      </div>
    </div>
    <div v-else class="grid--two-columns@medium">
      <AppBox :title="$t(`pages.admin.company_goals.create.public`)">
        <div class="box-content">
          <img src="@/assets/images/goals/public_goal_characters.png" alt="" />
          <div>
            <p class="text margin-b--m">
              {{ $t("pages.admin.company_goals.create.public.description") }}
            </p>
            <AppButton
              data-cy="new-public-goal"
              :text="$t('pages.admin.company_goals.create.new_public_goal')"
              size="m"
              @onclick="createPublicGoal" />
          </div>
        </div>
      </AppBox>
      <AppBox :title="$t(`pages.admin.company_goals.create.private`)">
        <div class="box-content">
          <img src="@/assets/images/goals/private_goal_characters.png" alt="" />
          <div>
            <p class="text margin-b--m">
              {{ $t("pages.admin.company_goals.create.private.description") }}
            </p>
            <AppButton
              data-cy="new-private-goal"
              :text="$t('pages.admin.company_goals.create.new_private_goal')"
              size="m"
              @onclick="createPrivateGoal" />
          </div>
        </div>
      </AppBox>
    </div>
    <div class="grid--four-columns@large grid--two-columns@medium grid--one-column" data-cy="goal-list">
      <GoalCompanyGoalsView v-for="goal in searchedGoals" :key="goal.id" :goal="goal" @goal-deleted="deleteGoal" @goal-editing="editGoal" />
    </div>

    <AdminCompanyGoalsFullEditor v-if="addingNewGoal" :is-open="goalToAddOpen" @close="addingNewGoal = false" @goal-created="addGoal" />
    <Component
      :is="editingGoal.isPublished ? AdminCompanyGoalsSimpleEditor : AdminCompanyGoalsFullEditor"
      v-if="editingGoal"
      :goal="editingGoal"
      @close="editingGoal = undefined"
      @goal-edited="updateGoal" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { compareDates } from "@/utils/dateUtils";
import { compareStrings, containsInsensitive } from "@/utils/stringUtils";
import { meetsApplicationPhaseFilter, meetsStatusFilter, meetsVisibilityFilter } from "@/services/modelServices/companyGoalService";
import { onMounted, ref, computed } from "vue";
import { useDebounce } from "@/services/useDebounce";
import AdminCompanyGoalsFullEditor from "@/views/admin/company-goals/components/AdminCompanyGoalsFullEditor.vue";
import AdminCompanyGoalsSimpleEditor from "@/views/admin/company-goals/components/AdminCompanyGoalsSimpleEditor.vue";
import AppBox from "@/components/AppBox.vue";
import AppButton from "@/components/AppButton.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppTableFilters from "@/components/table/AppTableFilters.vue";
import GoalCompanyGoalsView from "@/views/admin/company-goals/components/GoalCompanyGoalsView.vue";
import i18n from "@/i18n";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import type { CompanyGoal } from "@/models/companyGoal";
import type { Filter } from "@/components/table/TableFilter";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";
import { DEBOUNCE_SEARCH_TIMEOUT } from "@/constants/debounce";

const { getCompanyGoal, getCompanyGoals } = useCompanyGoalsService();
const { after } = useDebounce();

type Orders = "start_date_desc" | "start_date_asc" | "end_date_desc" | "end_date_asc" | "title_desc" | "title_asc";

const allowedOrders: {
  value: Orders;
  text: string;
}[] = [
  { value: "start_date_desc", text: i18n.global.t("pages.admin.company_goals.search.order.start_date_desc") },
  { value: "start_date_asc", text: i18n.global.t("pages.admin.company_goals.search.order.start_date_asc") },
  { value: "end_date_desc", text: i18n.global.t("pages.admin.company_goals.search.order.end_date_desc") },
  { value: "end_date_asc", text: i18n.global.t("pages.admin.company_goals.search.order.end_date_asc") },
  { value: "title_desc", text: i18n.global.t("pages.admin.company_goals.search.order.title_desc") },
  { value: "title_asc", text: i18n.global.t("pages.admin.company_goals.search.order.title_asc") },
];

const searchText = ref<string>("");
const searchTextDebounced = ref<string>("");
const goals = ref<CompanyGoal[]>([]);
const order = ref<Orders>(allowedOrders[0].value);
const filters = ref<Filter[]>([
  {
    title: i18n.global.t("pages.admin.company_goals.search.options.view_visibility"),
    fieldName: "visibility",
    filterOptions: [
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_all"), value: "all" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_public"), value: "public" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_private"), value: "private" },
    ],
    selectedOption: "all",
  },
  {
    title: i18n.global.t("pages.admin.company_goals.search.options.view_application_phase"),
    fieldName: "applicationPhase",
    filterOptions: [
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_all"), value: "all" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_application_phase_open"), value: "open" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_application_phase_closed"), value: "closed" },
    ],
    selectedOption: "all",
  },
  {
    title: i18n.global.t("pages.admin.company_goals.search.options.view_status"),
    fieldName: "status",
    filterOptions: [
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_all"), value: "all" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_draft"), value: "draft" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_published"), value: "published" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_launched"), value: "launched" },
      { label: i18n.global.t("pages.admin.company_goals.search.options.view_finished"), value: "finished" },
    ],
    selectedOption: "all",
  },
]);

const addingNewGoal = ref<boolean>(false);
const goalToAddOpen = ref<boolean>();
const editingGoal = ref<CompanyGoal>();

const orderingMethods: Record<Orders, (g1: CompanyGoal, g2: CompanyGoal) => number> = {
  start_date_asc: (g1, g2): number => compareDates(g1.start, g2.start, true),
  start_date_desc: (g1, g2): number => compareDates(g1.start, g2.start, false),
  end_date_asc: (g1, g2): number => compareDates(g1.end, g2.end, true),
  end_date_desc: (g1, g2): number => compareDates(g1.end, g2.end, false),
  title_asc: (g1, g2): number => compareStrings(g1.title, g2.title, true),
  title_desc: (g1, g2): number => compareStrings(g1.title, g2.title, false),
};

const searchedGoals = computed(() => {
  let filteredGoals = goals.value.filter((g) => containsInsensitive(g.title, searchTextDebounced.value));

  const visibilityFilter = filters.value.find((f) => f.fieldName === "visibility");
  const applicationPhaseFilter = filters.value.find((f) => f.fieldName === "applicationPhase");
  const statusFilter = filters.value.find((f) => f.fieldName === "status");

  filteredGoals = filteredGoals.filter((g) => meetsVisibilityFilter(g, visibilityFilter?.selectedOption));
  filteredGoals = filteredGoals.filter((g) => meetsApplicationPhaseFilter(g, applicationPhaseFilter?.selectedOption));
  filteredGoals = filteredGoals.filter((g) => meetsStatusFilter(g, statusFilter?.selectedOption));
  return filteredGoals.sort(orderingMethods[order.value]);
});

onMounted(() => fetchInitData());

const fetchInitData = async () => (goals.value = await getCompanyGoals());

const createPublicGoal = () => {
  addingNewGoal.value = goalToAddOpen.value = true;
};

const createPrivateGoal = () => {
  addingNewGoal.value = true;
  goalToAddOpen.value = false;
};

const addGoal = (goal: CompanyGoal) => {
  goals.value.push(goal);
  goalToAddOpen.value = undefined;
};

const updateGoal = (goal: CompanyGoal) => {
  goals.value = goals.value.filter((g) => g.id !== goal.id);
  goals.value.push(goal);
};

const deleteGoal = (goalId: number) => {
  goals.value = goals.value.filter((g) => g.id !== goalId);
};

const editGoal = async (goalId: number) => {
  const goal = await getCompanyGoal(goalId);
  editingGoal.value = goal as CompanyGoal;
};

const updateSearch = (searchText: string) => after(DEBOUNCE_SEARCH_TIMEOUT, () => (searchTextDebounced.value = searchText));
</script>

<style scoped lang="scss">
@import "@/styles/utils/responsive";
.admin-company-goals {
  --items-gap: 1rem;

  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto 1fr;
  column-gap: var(--items-gap);
  row-gap: 0;

  &__filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    column-gap: var(--space-m);
  }

  &__new-goal {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__filter-options {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: var(--space-s);
  }
}
@media only screen and (max-width: $medium-screen-breakpoint) {
  .admin-company-goals {
    display: block;
    &__filters {
      display: block;
    }
    &__new-goal {
      padding-top: var(--size-m);
    }
  }
}

.box-content {
  display: flex;
  padding: 2.4rem;
  align-items: center;
  column-gap: 2.4rem;
}
</style>
