<template>
  <div data-cy="education-mode">
    <p class="margin-b--xs">{{ $t("inputs.educationMode.label") }}</p>
    <AppRadioButtonList v-model="value" :options="modeOptions" :in-row="inRow" />
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";
import AppRadioButtonList, { type RadioButtonOption } from "@/components/AppRadioButtonList.vue";
import { EducationMode } from "@/models/educationMode";

interface Props {
  modelValue: EducationMode;
  inRow?: boolean;
}
const props = defineProps<Props>();

const modeOptions: RadioButtonOption[] = Object.keys(EducationMode).map((mode, index) => ({
  optionId: index,
  title: i18n.global.t(`inputs.educationMode.${mode.toLocaleLowerCase()}.label`),
}));

const emit = defineEmits<(e: "update:modelValue", option: EducationMode | undefined) => void>();

const value = computed({
  get: () => getModeOption(props.modelValue),
  set: (val) => emit("update:modelValue", Object.keys(EducationMode)[val?.optionId] as EducationMode),
});

const getModeOption = (mode: EducationMode): RadioButtonOption => {
  if (!mode) {
    return modeOptions[0];
  }
  const indexOfE = Object.values(EducationMode).indexOf(mode);
  return modeOptions[indexOfE];
};
</script>
