<template>
  <div class="table__row" data-cy="app-table-row">
    <AppCheckbox
      v-if="allowSelect"
      :model-value="(item as TableItemSelectable).selected"
      data-cy="app-table-checkbox"
      @change="toggleSelect" />
    <AppIconOnlyButton
      v-if="allowExpand"
      icon="chevron-down"
      class="table__row__expander"
      :class="{ 'table__row__expander--expanded': expanded }"
      title="Detail"
      @click="toggleExpand" />
    <AppTableCell
      v-for="column in columns"
      :key="column.id"
      :column="column"
      :item="item"
      :class="{ 'table__cell--right': column.align === 'right', 'table__cell--center': column.align === 'center' }" />
  </div>
</template>

<script lang="ts" setup>
import AppTableCell from "@/components/table/AppTableCell.vue";
import AppCheckbox from "@/components/AppCheckbox.vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import type { Column } from "@/components/table/Column";
import type { TableItem, TableItemSelectable } from "@/components/table/TableItem";

interface Props {
  allowSelect?: boolean;
  columns: Column[];
  item: TableItem;
  allowExpand: boolean;
  expanded?: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: "toggleSelectItem", item: TableItemSelectable): void;
  (e: "toggleExpandedItem", item: TableItem): void;
}
const emit = defineEmits<Emits>();

const toggleExpand = () => emit("toggleExpandedItem", props.item);
const toggleSelect = () => emit("toggleSelectItem", props.item as TableItemSelectable);
</script>

<style lang="scss" scoped>
.table {
  &__row {
    &:hover {
      background-color: #ededed;
    }
    &__expander {
      rotate: 0deg;
      transition: rotate 0.3s ease-in-out;
      &--expanded {
        rotate: 180deg;
      }
    }
  }
  &__cell--right {
    justify-self: right;
  }
  &__cell--center {
    justify-self: center;
  }
}
</style>
