import type { Directive, DirectiveBinding } from "vue";

const scrollToTop = (el: HTMLElement): void => el.scrollTo(0, 0);

export const AppScrollTop: Directive = {
  mounted: (el: HTMLElement, _binding: DirectiveBinding, _vnode): void => {
    scrollToTop(el);
  },
  updated: (el: HTMLElement, binding: DirectiveBinding): void => {
    if (binding.oldValue !== binding.value) {
      scrollToTop(el);
    }
  },
};
