<template>
  <div class="learning-path__header" :class="headerTypeClass" :data-cy="`header-${area}`">
    <span class="icon text text--xxl" :class="`icon-${icon}`"></span>
    <span class="text text--xl text--bold">{{ $t(`pages.learning.my_learning_path.areas.${area}`) }}</span>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { LearningPathCourseArea } from "@/views/app/my-learning/components/learning-path/learningPathModels";

interface Props {
  area: LearningPathCourseArea;
  icon: string;
}
const props = defineProps<Props>();

const headerTypeClass = computed(() => `learning-path__header--${props.area}`);
const gridArea = computed(() => `header-${props.area}`);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
@import "@/styles/theme";

.learning-path {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--space-m);
    grid-area: v-bind(gridArea);
  }
}

@media only screen and (max-width: $big-screen-breakpoint) {
  .learning-path {
    &__header {
      align-items: center;
      border-top: 1px solid $color-border;

      &:first-child {
        border: 0;
      }
    }
  }
}
@media only screen and (max-width: $medium-screen-breakpoint) {
  .learning-path {
    &__header {
      text-align: center;
    }
  }
}
</style>
