<template>
  <div class="featured-card">
    <GoalImage :goal="goal" :mini-size="true" />
    <p class="text text--m text--bold text--ellipsis text--link margin-v--s" data-cy="featured-card-title" @click="titleClick">
      {{ goal.title }}
    </p>
    <div class="featured-card__description text--line-clamp-3">
      <p class="text text--s margin-b--s">{{ goal.description }}</p>
    </div>
    <div class="featured-card__registered">
      <p class="text text--s">{{ $t("pages.goals_challenges.details.colleagues_registered", goal.colleaguesRegistered ?? 0) }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CompanyGoal } from "@/models/companyGoal";
import GoalImage from "@/views/app/goals-challenges/components/GoalImage.vue";

interface Props {
  goal: CompanyGoal;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "titleClick", value: CompanyGoal) => void>();

const titleClick = () => emit("titleClick", props.goal);
</script>
<style lang="scss" scoped>
.featured-card {
  position: relative;
  height: 424px;
  margin: var(--space-s) 0 var(--space-s) var(--space-s);
  padding: var(--space-s);
  background-color: var(--color-neutral-100);
  border-radius: 12px;
  box-shadow: var(--size-xs) var(--size-xs) var(--size-s) rgb(26 26 26 / 0.06);

  &__description {
    min-height: 60px;
  }

  &__registered > p {
    position: absolute;
    bottom: var(--size-s);
    color: var(--color-neutral-500);
  }
}
</style>
