import type { DirectiveBinding, Directive } from "vue";

let clickOutsideEvent: (event: Event) => void;
export const clickOutside: Directive = {
  beforeMount: (el: HTMLElement, binding: DirectiveBinding) => {
    clickOutsideEvent = (event: Event) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value();
      }
    };
    document.addEventListener("click", clickOutsideEvent);
  },
  unmounted: (_el: HTMLElement) => {
    document.removeEventListener("click", clickOutsideEvent);
  },
};
