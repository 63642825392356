<template>
  <AppLoading />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import router from "@/router/router";
import useMePerformanceGraphService from "@/services/useMePerformanceGraphService";
import AppLoading from "@/components/AppLoading.vue";

const { getPerformanceGraph } = useMePerformanceGraphService();

onMounted(async () => {
  const performanceGraph = await getPerformanceGraph();
  const hasPerformanceGraphConfigured = performanceGraph.skillNames.length > 0;
  const destinationView = hasPerformanceGraphConfigured ? "my-profile-dashboard" : "my-profile-overview";
  await router.push({ name: destinationView });
});
</script>
