<template>
  <div>
    <p class="text margin-b--m">{{ $t("pages-my-profile.profile_updating.performance_graph.example.subtitle") }}</p>
    <AppProfileSummaryInformation :profile-information="exampleProfileInformation" size="m" />
    <ul class="example-points">
      <li class="text example-points__item">{{ $t("pages-my-profile.profile_updating.performance_graph.example.steps.1") }}</li>
      <li class="text example-points__item">{{ $t("pages-my-profile.profile_updating.performance_graph.example.steps.2") }}</li>
      <li class="text example-points__item">{{ $t("pages-my-profile.profile_updating.performance_graph.example.steps.3") }}</li>
    </ul>
    <ProfilePerformanceExampleChart />
    <p class="text margin-v--m">{{ $t("pages-my-profile.profile_updating.performance_graph.example.content_1") }}</p>
    <p class="text margin-b--m">{{ $t("pages-my-profile.profile_updating.performance_graph.example.content_2") }}</p>
    <p class="text text--bold">{{ $t("pages-my-profile.profile_updating.performance_graph.example.content_3") }}</p>
  </div>
</template>

<script lang="ts" setup>
import i18n from "@/i18n";
import type { ProfileSummaryInformation } from "@/models/profileSummaryInformation";

import ProfilePerformanceExampleChart from "@/views/app/my-profile/components/overview/ProfilePerformanceExampleChart.vue";
import AppProfileSummaryInformation from "@/views/app/my-profile/components/shared/ProfileSummaryInformation.vue";

const exampleProfileInformation: ProfileSummaryInformation = {
  employeeId: 1,
  firstName: i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.profile.first_name"),
  lastName: i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.profile.last_name"),
  jobExperiences: [i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.profile.experience")],
};
</script>

<style lang="scss" scoped>
.example-points {
  list-style: disc;
  margin: var(--space-s) 0 0 var(--space-m);

  &__item {
    margin-bottom: var(--space-xs);
  }
}
</style>
