<template>
  <div class="user-info-item">
    <div>
      <h3 :class="{ 'text--ellipsis': truncateText }">{{ title }}</h3>
      <a
        v-if="attachmentUrl && !subtitle"
        :href="attachmentUrl"
        target="_blank"
        class="icon icon-attachment margin-h--s text text--link"
        :title="$t('buttons.download')"></a>
    </div>

    <div v-if="subtitle" class="subtitle-container">
      <span class="text text--secondary">{{ subtitle }}</span>
      <a
        v-if="attachmentUrl"
        data-cy="user-info-item-attachment"
        :href="attachmentUrl"
        target="_blank"
        class="icon icon-attachment margin-h--s text text--link"
        :title="$t('buttons.download')"></a>
    </div>
    <div
      class="text text--secondary"
      :class="{ 'text--line-clamp-3': truncatedContent }"
      :title="titleHtml"
      @click="toggle"
      v-html="contentHtml"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { toHtml, toTitleHtml } from "@/utils/stringUtils";

interface Props {
  title?: string;
  subtitle?: string;
  attachmentUrl?: string;
  content?: string;
  truncateText?: boolean;
}
const props = defineProps<Props>();

const titleHtml = computed(() => toTitleHtml(props.content));
const contentHtml = computed(() => toHtml(props.content));
const truncatedContent = ref<boolean>(props.truncateText);

const toggle = () => {
  if (!props.truncateText) {
    return;
  }

  truncatedContent.value = !truncatedContent.value;
};
</script>

<style scoped lang="scss">
.user-info-item {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}
</style>
