<template>
  <LayoutPage class="admin-talent-detection" :title="$t('pages.admin.talent_detection.title')" data-cy="talent-detection-title">
    <div class="grid grid--two-thirds-one-third-columns@medium">
      <div class="admin-talent-detection__main main">
        <AppAutocomplete
          :items="items"
          :placeholder="$t('entities.my_interests.hobbies.edit-modal.search')"
          :maxlength="mediumTextLength"
          :self-filtering="true"
          :keep-selection="true"
          @cleaned="onSearchClean"
          @click="onHobbyClick" />
        <AppTip
          class="margin-t--m"
          unique-name="talent-detection"
          :title="$t('pages.admin.talent_detection.tip.title')"
          :content="$t('pages.admin.talent_detection.tip.content')" />
        <AppTab class="margin-v--m" :tabs="tabs" tab-id="talent-detection" :show-if-only-one-available="false" />
        <div v-if="currentView === CurrentView.ByAge">
          <HobbyByAgeChart v-if="selectedHobby" :hobby="selectedHobby" data-cy="hobby-chart" @clicked="setHobbyAgeSelection" />
          <CategoryByAgeChart v-else data-cy="category-chart" @clicked="setCategoryAgeSelection" />
        </div>
        <div v-if="currentView === CurrentView.ByGroup">
          <HobbyByGroupChart v-if="selectedHobby" :hobby="selectedHobby" @clicked="setHobbyGroupSelection" />
          <CategoryByGroupChart v-else @clicked="setCategoryGroupSelection" />
        </div>
      </div>
      <div class="admin-talent-detection__aside">
        <EmployeeByAgeList v-if="currentView === CurrentView.ByAge" :selection="ageChartSelection" />
        <EmployeeByGroupList v-else :selection="groupChartSelection" />
      </div>
    </div>
  </LayoutPage>
</template>

<script setup lang="ts">
import { mediumTextLength } from "@/constants/restrictions";
import { onMounted, ref } from "vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import AppTip from "@/components/AppTip.vue";
import CategoryByAgeChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionCategoryByAgeChart.vue";
import CategoryByGroupChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionCategoryByGroupChart.vue";
import EmployeeByAgeList from "@/views/admin/talent-detection/components/employee-lists/AdminTalentDetectionEmployeeByAgeList.vue";
import EmployeeByGroupList from "@/views/admin/talent-detection/components/employee-lists/AdminTalentDetectionEmployeeByGroupList.vue";
import HobbyByAgeChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionHobbyByAgeChart.vue";
import HobbyByGroupChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionHobbyByGroupChart.vue";
import i18n from "@/i18n";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import type { AgeChartSelection, GroupChartSelection } from "@/models/talent-detection/chartSelections";
import type { Hobby } from "@/models/hobby";
import type { HobbyAutocompleteItem } from "@/models/autocompleteItem";
import useHobbiesService from "@/services/useHobbiesService";

const { getHobbies } = useHobbiesService();

const ageChartSelection = ref<AgeChartSelection>();
const groupChartSelection = ref<GroupChartSelection>();

enum CurrentView {
  ByAge = "byAge",
  ByGroup = "byGroup",
}
const currentView = ref<CurrentView>(CurrentView.ByAge);

const items = ref<HobbyAutocompleteItem[]>([]);
const selectedHobby = ref<Hobby>();

onMounted(async () => {
  const hobbies = await getHobbies();
  items.value = hobbies.map((e) => ({
    id: e.id,
    text: e.name,
    category: e.category,
  }));
});

const onHobbyClick = (item: HobbyAutocompleteItem) => {
  selectedHobby.value = { ...item, name: item.text };
  return currentView.value === CurrentView.ByAge ? setHobbyAgeSelection() : setHobbyGroupSelection();
};

const onSearchClean = () => {
  selectedHobby.value = undefined;
  resetSelection();
};
const resetSelection = () => {
  ageChartSelection.value = undefined;
  groupChartSelection.value = undefined;
};
const setCategoryAgeSelection = (selection?: AgeChartSelection) => (ageChartSelection.value = selection ?? undefined);
const setCategoryGroupSelection = (selection?: GroupChartSelection) => (groupChartSelection.value = selection ?? undefined);

const setHobbyAgeSelection = (selection?: AgeChartSelection) => {
  const { ageRange } = selection ?? {};
  ageChartSelection.value = {
    hobbiesCategory: selectedHobby.value?.category,
    hobby: selectedHobby.value,
    ageRange: ageRange ?? undefined,
  };
};

const setHobbyGroupSelection = (selection?: GroupChartSelection) => {
  const { group } = selection ?? {};
  groupChartSelection.value = {
    hobbiesCategory: selectedHobby.value?.category,
    hobby: selectedHobby.value,
    group: group ?? undefined,
  };
};

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages.admin.talent_detection.chart.by_age"),
    action: () => {
      currentView.value = CurrentView.ByAge;
      resetSelection();
    },
    dataCy: "chart-by-age",
  },
  {
    text: i18n.global.t("pages.admin.talent_detection.chart.by_group"),
    action: () => {
      currentView.value = CurrentView.ByGroup;
      resetSelection();
    },
    dataCy: "chart-by-group",
  },
];
</script>
<style lang="scss" scoped>
.admin-talent-detection {
  &__aside {
    display: flex;
    flex-direction: column;
  }
}
</style>
