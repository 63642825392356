<template>
  <div class="table-pagination">
    <span class="text--s"> {{ $t("shared.table.rows_per_page") }}</span>
    <select
      v-model="selectedPageSize"
      class="table-pagination__page-size-select"
      data-cy="pagination-pageSize-selector"
      @change="pageSizeChanged">
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
    </select>
    <span class="text--s">{{ ` ${firstItemShownNumber} - ${lastItemShownNumber} of ${modelValue.totalElements}` }}</span>
    <AppIconOnlyButton
      icon="back"
      size="m"
      :title="$t('shared.table.go_to_first_page')"
      :disabled="modelValue.totalElements === 0 || modelValue.currentPage === 1"
      data-cy="pagination-go-to-first"
      @click="goToFirstPage" />
    <AppIconOnlyButton
      icon="arrow-back"
      size="m"
      :title="$t('shared.table.go_to_previous_page')"
      :disabled="modelValue.totalElements === 0 || modelValue.currentPage === 1"
      data-cy="pagination-go-to-prev"
      @click="goToPreviousPage" />
    <AppIconOnlyButton
      icon="arrow-forward"
      size="m"
      :title="$t('shared.table.go_to_next_page')"
      :disabled="modelValue.totalElements === 0 || modelValue.currentPage === lastPage"
      data-cy="pagination-go-to-next"
      @click="goToPNextPage" />
    <AppIconOnlyButton
      icon="forward"
      size="m"
      :title="$t('shared.table.go_to_last_page')"
      :disabled="modelValue.totalElements === 0 || modelValue.currentPage === lastPage"
      data-cy="pagination-go-to-last"
      @click="goToLastPage" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import type { PaginationProperties } from "@/components/table/PaginationProperties";

interface Props {
  modelValue: PaginationProperties;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", modelValue: PaginationProperties) => void>();

const selectedPageSize = ref<number>(props.modelValue.pageSize);
const options = [
  { value: 5, text: 5 },
  { value: 10, text: 10 },
  { value: 50, text: 50 },
  { value: 100, text: 100 },
];

const firstItemShownNumber = computed<number>(() => {
  if (!props.modelValue.totalElements) {
    return 0;
  }
  return props.modelValue.currentPage === 1 ? 1 : (props.modelValue.currentPage - 1) * props.modelValue.pageSize + 1;
});
const lastItemShownNumber = computed<number>(() =>
  Math.min(props.modelValue.currentPage * props.modelValue.pageSize, props.modelValue.totalElements)
);

const lastPage = computed<number>(() => Math.ceil(props.modelValue.totalElements / props.modelValue.pageSize) || 0);

const goToFirstPage = () => changeCurrentPage(1);
const goToPreviousPage = () => changeCurrentPage(props.modelValue.currentPage - 1);
const goToPNextPage = () => changeCurrentPage(props.modelValue.currentPage + 1);
const goToLastPage = () => changeCurrentPage(lastPage.value);
const pageSizeChanged = (): void => {
  emit("update:modelValue", { ...props.modelValue, pageSize: selectedPageSize.value });
};
const changeCurrentPage = (newCurrentPage: number) => emit("update:modelValue", { ...props.modelValue, currentPage: newCurrentPage });
</script>

<style scoped lang="scss">
.table-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--space-s);
  padding: var(--space-s) var(--space-m);
  color: var(--color-neutral-500);

  &__page-size-select {
    border: none;
    background-color: var(--color-checkbox-background);

    &:focus {
      border: none;
    }
  }
}
</style>
