<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :disable-key-enter="true">
    <div class="course-details">
      <CourseDetailsMainData :course="course" />
      <CourseOnSiteInformation
        v-if="!course.onLine && course.companyCourseInformation"
        :course="course"
        :information="course.companyCourseInformation" />
      <div v-if="allowEdit" class="course-editor">
        <CourseGoToTrainingButton v-if="(course as EmployeeCourse).done === false" :course="(course as EmployeeCourse)" />
        <AppPanel v-if="(course as EmployeeCourse).done === false" :dark="true" :border="false">
          <AppToggle v-model="courseDone" :text="$t('pages.learning.trainingComplete')" data-cy="input-courseDone" />
        </AppPanel>
        <AppUploadDocument
          v-if="(course as EmployeeCourse).done === false && courseDone"
          v-model="attachment"
          :label="$t('pages.learning.attachment.label')"
          :accept="allowedExtensions.join()"
          :error="attachmentError"
          :maximum-size="maxFileSizeInMB" />
        <CertificateReminder v-if="(course as EmployeeCourse).done" />
        <AppInputRating v-model="rateValue" :label="$t('pages.learning.rateTitle')" :bold="true" />
        <AppLabel :label="$t('pages.learning.rateObservations.placeHolder')" />
      </div>
    </div>
    <template v-if="!allowEdit" #actions>
      <AppButton :text="$t('shared.close')" size="m" variant="secondary" data-cy="confirm-modal" @onclick="emit('closed')" />
      <AppButton
        v-if="allowAssign"
        :text="$t('pages.learning.sendTraining')"
        size="m"
        variant="primary"
        data-cy="assign-training"
        @onclick="assignCourse" />
    </template>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { ref, onMounted } from "vue";
import AppLabel from "@/components/AppLabel.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import CourseDetailsMainData from "@/views/shared/learning/CourseDetailsMainData.vue";
import CourseGoToTrainingButton from "@/views/shared/learning/CourseGoToTrainingButton.vue";
import CertificateReminder from "@/views/shared/learning/CertificateReminder.vue";
import CourseOnSiteInformation from "@/views/shared/learning/CourseOnSiteInformation.vue";
import AppInputRating from "@/components/rateInput/AppInputRating.vue";
import AppToggle from "@/components/AppToggle.vue";
import AppButton from "@/components/AppButton.vue";
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import AppPanel from "@/components/AppPanel.vue";
import useMeCoursesService from "@/services/useMeCoursesService";
import type { EmployeeCourse, CourseBase } from "@/models/course";
import type { CourseEmployeeRate } from "@/models/courseEmployeeRate";
import useModal from "@/components/modal/useModal";
import type { ModalBase } from "@/components/modal/models";
import type { FileAttachment } from "@/models/fileAttachment";
import { useField, useForm } from "vee-validate";
import useFormSchema from "@/services/useFormSchema";
import useFormValidator from "@/services/useFormValidator";
import { allowedExtensions, maxFileSizeInMB } from "@/utils/fileUtils";

interface Props {
  course: CourseBase;
  allowEdit?: boolean;
  allowAssign?: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: "closed"): void;
  (e: "saved", courseId: number): void;
  (e: "assign", course: CourseBase): void;
}
const emit = defineEmits<Emits>();

const { getEmployeeCourseInfo, setEmployeeCourseInfo } = useMeCoursesService();
const { openTextModal } = useModal();
const { attachmentSchema, yupObject } = useFormSchema();
const { getFormCallbackHandler } = useFormValidator();

const courseDone = ref<boolean>((props.course as EmployeeCourse).done);
const rateValue = ref<number>(0);
const resourceSchema = yupObject({
  attachment: attachmentSchema(allowedExtensions, maxFileSizeInMB),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");

const initRate = async (): Promise<void> => {
  if (!props.allowEdit) {
    return;
  }
  const courseRate = await getEmployeeCourseInfo(props.course.id);
  rateValue.value = courseRate.rateValue;
};

const confirmCallback = async (): Promise<boolean> => {
  if (isCourseDoneChange()) {
    const confirmed = await confirmSetAsDone();
    if (!confirmed) {
      return false;
    }
  }
  await saveData();
  emit("saved", props.course.id);

  return true;
};

const saveData = () => {
  const rateInfo: CourseEmployeeRate = {
    rateValue: rateValue.value,
    done: (props.course as EmployeeCourse).done || courseDone.value,
    attachment: attachment.value,
  };
  return setEmployeeCourseInfo(props.course.id, rateInfo);
};

const confirmSetAsDone = (): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    openTextModal({
      icon: { content: "verified", style: "" },
      title: i18n.global.t("pages.learning.confirmSetTrainingAsComplete.title"),
      contentText: i18n.global.t("pages.learning.confirmSetTrainingAsComplete.text"),
      showCancelToClose: true,
      confirmCallback: () => resolve(true),
      closeCallback: () => resolve(false),
    });
  });
};
const isCourseDoneChange = (): boolean => (props.course as EmployeeCourse).done !== courseDone.value;

const modalConfig: ModalBase = {
  title: props.course.title,
  variant: "floating",
  showCancelToClose: true,
  confirmText: i18n.global.t("buttons.save"),
  confirmCallback: props.allowEdit ? getFormCallbackHandler(form, confirmCallback) : undefined,
  onClosed: () => emit("closed"),
};

const assignCourse = () => {
  emit("closed");
  emit("assign", props.course);
};

onMounted(() => initRate());
</script>

<style scoped lang="scss">
.course-details,
.course-editor {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-s);
  height: 100%;
}
.course-details {
  &__done {
    background-color: var(--color-sidebar-menu-shadow);
  }
}
</style>
