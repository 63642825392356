<template>
  <div v-if="profileInformation" class="employee-current-experience">
    <h2 data-cy="current-experience-title">
      {{ $t("pages.current_experience.about_me") }}
    </h2>
    <div class="grid--two-columns@medium">
      <AppNoContentYet v-if="!profileInformation.aboutMe" :text="$t('pages.admin.dashboard.current_experience.about_me.no_content_yet')" />
      <AboutMeEditor v-if="profileInformation.aboutMe" :input="profileInformation.aboutMe" form-mode="view" />
      <AppUploadDocumentInline
        v-if="profileInformation.curriculumName"
        v-model="curriculum"
        :label="$t('pages.admin.dashboard.current_experience.about_me.curriculum')"
        :download-url="getCurriculumUrl(profileInformation.id)"
        :accept="allowedExtensions.join()"
        button-variant="secondary"
        form-mode="view" />
    </div>
    <AppSeparator />
    <div class="current-job employee-current-experience-item">
      <h2>{{ $t("pages.current_experience.current_experience.title") }}</h2>
      <div class="grid--two-columns@medium">
        <div class="employee-current-experience-item">
          <AppNoContentYet v-if="!currentJobExperience" />
          <JobExperienceEditor
            v-else
            form-mode="view"
            :employee-id="profileInformation.id"
            :job-experience-type="JobExperienceType.CurrentJobExperience"
            truncate-text
            :input="currentJobExperience" />
          <GroupsOfEmployee :groups="profileInformation.groups" />
        </div>
        <div v-if="currentJobExperience" class="employee-current-experience-item">
          <h3>{{ $t("pages.current_experience.current_experience.general_skills") }}</h3>
          <AppNoContentYet
            v-if="currentJobExperienceGeneralSkills.length === 0"
            :text="$t('pages.admin.dashboard.current_experience.general_skills.no_content_yet')" />
          <SkillEditor :input="currentJobExperienceGeneralSkills" :skills-type="SkillType.General" form-mode="view" />
          <h3>{{ $t("pages.current_experience.current_experience.language_skills") }}</h3>
          <AppNoContentYet
            v-if="currentJobExperienceLanguageSkills.length === 0"
            :text="$t('pages.admin.dashboard.current_experience.languages.no_content_yet')" />
          <SkillEditor :input="currentJobExperienceLanguageSkills" :skills-type="SkillType.Language" form-mode="view" />
        </div>
      </div>
    </div>
    <AppSeparator />
    <div class="grid--two-columns@medium">
      <div class="employee-current-experience-item">
        <h2>{{ $t("pages.current_experience.experience") }}</h2>
        <AppNoContentYet
          v-if="jobExperiences.length === 0"
          :text="$t('pages.admin.dashboard.current_experience.experience.no_content_yet')" />
        <JobExperienceEditor
          v-for="jobExperience in jobExperiences"
          :key="jobExperience.id"
          :employee-id="profileInformation.id"
          form-mode="view"
          truncate-text
          :input="jobExperience" />
      </div>
      <div class="employee-current-experience-item">
        <h2>{{ $t("pages.current_experience.education") }}</h2>
        <AppNoContentYet v-if="educations.length === 0" :text="$t('pages.admin.dashboard.current_experience.education.no_content_yet')" />
        <EducationEditor
          v-for="education in educations"
          :key="education.id"
          :employee-id="profileInformation.id"
          form-mode="view"
          truncate-text
          :input="education" />
      </div>
    </div>
    <AppSeparator />
    <h2>{{ $t("pages.current_experience.skills") }}</h2>
    <div class="grid--two-columns@medium">
      <div class="employee-current-experience-item">
        <h5>{{ $t("pages.current_experience.skills.general") }}</h5>
        <AppNoContentYet
          v-if="generalSkills.length === 0"
          :text="$t('pages.admin.dashboard.current_experience.general_skills.no_content_yet')" />
        <SkillEditor :input="generalSkills" :skills-type="SkillType.General" form-mode="view" />
      </div>
      <div class="employee-current-experience-item">
        <h5>{{ $t("pages.current_experience.languages") }}</h5>
        <AppNoContentYet v-if="languages.length === 0" :text="$t('pages.admin.dashboard.current_experience.languages.no_content_yet')" />
        <SkillEditor :input="languages" form-mode="view" :skills-type="SkillType.Language" />
      </div>
    </div>
    <AppSeparator />
    <AppEmployeeCurrentExperienceGoals
      v-if="!loading"
      :goals="goals"
      :no-goals-text="$t('entities.goals_challenges.employee_no_goals_yet')" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AboutMeEditor from "@/views/shared/current-experience/AboutMeEditor.vue";
import JobExperienceEditor from "@/views/shared/current-experience/JobExperienceEditor.vue";
import EducationEditor from "@/views/shared/current-experience/EducationEditor.vue";
import SkillEditor from "@/views/shared/current-experience/FloatingPanelSkillEditor.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppUploadDocumentInline from "@/components/uploadDocument/AppUploadDocumentInline.vue";
import GroupsOfEmployee from "@/views/shared/employee/components/GroupsOfEmployee.vue";
import AppEmployeeCurrentExperienceGoals from "@/views/shared/current-experience/AppEmployeeCurrentExperienceGoals.vue";
import useEmployeeService from "@/services/useEmployeeService";
import type { ProfileInformation } from "@/models/profileInformation";
import type { SkillWithProgression } from "@/models/skillWithProgression";
import { type CurrentJobExperience, type JobExperience, JobExperienceType } from "@/models/jobExperience";
import type { Education } from "@/models/education";
import { compareDates } from "@/utils/dateUtils";
import { SkillType } from "@/models/skillType";
import type { FileAttachment } from "@/models/fileAttachment";
import { getLanguageSkills } from "@/utils/models";
import type { CompanyGoal } from "@/models/companyGoal";
import { sortByProgressionName } from "@/services/modelServices/skillService";
import { allowedExtensions } from "@/utils/fileUtils";

const { getCurriculumUrl, getEmployeeGoals } = useEmployeeService();

interface Props {
  profileInformation: ProfileInformation;
}
const props = defineProps<Props>();

const generalSkills = ref<SkillWithProgression[]>([]);
const languages = ref<SkillWithProgression[]>([]);
const jobExperiences = ref<JobExperience[]>([]);
const currentJobExperience = ref<CurrentJobExperience>();
const currentJobExperienceGeneralSkills = ref<SkillWithProgression[]>([]);
const currentJobExperienceLanguageSkills = ref<SkillWithProgression[]>([]);
const educations = ref<Education[]>([]);
const curriculum = ref<FileAttachment>();
const goals = ref<CompanyGoal[]>([]);
const loading = ref<boolean>(true);

onMounted(async () => {
  curriculum.value = props.profileInformation.curriculumName ? { fileName: props.profileInformation.curriculumName } : undefined;
  languages.value = getLanguageSkills([...props.profileInformation.skills]).sort(sortByProgressionName);
  jobExperiences.value = [...props.profileInformation.jobExperiences].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));
  currentJobExperience.value = props.profileInformation.currentJobExperience;
  currentJobExperienceGeneralSkills.value = [
    ...(props.profileInformation.currentJobExperience?.skills.filter((s) => s.type === SkillType.General) ?? []),
  ];
  currentJobExperienceLanguageSkills.value = [
    ...(props.profileInformation.currentJobExperience?.skills.filter((s) => s.type === SkillType.Language) ?? []),
  ];
  educations.value = [...props.profileInformation.educations].sort((a, b) => compareDates(a.fromDate, b.fromDate, false));

  const skills = [...props.profileInformation.skills].sort(sortByProgressionName);
  generalSkills.value = skills.filter((skill) => skill.type === SkillType.General);

  goals.value = await getEmployeeGoals(props.profileInformation.id);
  loading.value = false;
});
</script>

<style scoped lang="scss">
.employee-current-experience,
.employee-current-experience-item {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
}
.current-job {
  border-radius: var(--size-xs);
  background-color: var(--color-neutral-100);
  box-shadow: 0 var(--space-xs) var(--space-m) rgba(26, 26, 26, 0.04);
  padding: var(--space-m);
}
</style>
