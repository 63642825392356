<template>
  <div class="grid--two-columns@medium">
    <div>
      <h3 data-cy="azure-info-title" class="margin-b--l">{{ $t("pages.admin.company_settings.azure_info.title") }}</h3>
      <div>
        <AppForm v-if="azure">
          <AppInputText
            v-model="servicePrincipalId"
            data-cy="service-principal-id"
            :label="$t('pages.admin.company_settings.azure_info.form.service_principal_id')"
            :error="servicePrincipalIdError"
            :maxlength="guidTextLength" />
          <AppInputText
            v-model="servicePrincipalSecret"
            data-cy="service-principal-secret"
            type="password"
            :label="$t('pages.admin.company_settings.azure_info.form.service_principal_secret')"
            :error="servicePrincipalSecretError"
            :maxlength="mediumTextLength"
            :placeholder="secretPlaceholder" />
          <AppInputText
            v-model="azure.tenantId"
            data-cy="tenant-id"
            :label="$t('pages.admin.company_settings.azure_info.form.tenant_id')"
            disabled />
          <AppInputText
            v-model="azure.purchaseSubscriptionId"
            data-cy="purchase-subscription-id"
            :label="$t('pages.admin.company_settings.azure_info.form.purchase_subscription_id')"
            disabled />
        </AppForm>
      </div>
      <AppSeparator class="margin-b--l" />
      <div class="btn-container">
        <AppButton
          data-cy="azure-info-btn-save"
          size="m"
          variant="primary"
          :text="$t('pages.admin.company_settings.save')"
          @onclick="save" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { guidTextLength, mediumTextLength } from "@/constants/restrictions";
import { onMounted, ref, computed } from "vue";
import { useField, useForm } from "vee-validate";
import AppButton from "@/components/AppButton.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import i18n from "@/i18n";
import type { AzureAd, AzureAdServicePrincipal } from "@/models/azureAd";
import useCompanySettingsService from "@/services/useCompanySettingsService";
import useToaster from "@/components/toaster/useToaster";
import AppForm from "@/components/AppForm.vue";
import useFormSchema from "@/services/useFormSchema";

const { stringSchema, yupObject } = useFormSchema();

const validationSchema = yupObject({
  servicePrincipalId: stringSchema({ maxLength: guidTextLength })
    .label(i18n.global.t("pages.admin.company_settings.azure_info.form.service_principal_id"))
    .length(guidTextLength),
  servicePrincipalSecret: stringSchema().label(i18n.global.t("pages.admin.company_settings.azure_info.form.service_principal_secret")),
});

const { getAzureInformation, updateAzureInformation } = useCompanySettingsService();
const { openToaster } = useToaster();

const azure = ref<AzureAd>();
const form = useForm({ validationSchema });
const { value: servicePrincipalId, errorMessage: servicePrincipalIdError } = useField<string>("servicePrincipalId");
const { value: servicePrincipalSecret, errorMessage: servicePrincipalSecretError } = useField<string>("servicePrincipalSecret");

const secretPlaceholder = computed(() => {
  return azure.value?.hasServicePrincipalSecret
    ? i18n.global.t("pages.admin.company_settings.azure_info.form.service_principal_secret.placeholder")
    : undefined;
});

onMounted(async () => {
  azure.value = await getAzureInformation();
  servicePrincipalId.value = azure.value.servicePrincipalId;
  servicePrincipalSecret.value = azure.value.servicePrincipalSecret;
});

const save = async () => {
  const formValidation = await form.validate();
  if (!formValidation.valid) {
    return;
  }

  const servicePrincipal: AzureAdServicePrincipal = {
    servicePrincipalId: servicePrincipalId.value,
    servicePrincipalSecret: servicePrincipalSecret.value,
  };

  await updateAzureInformation(servicePrincipal);
  openToaster("success", i18n.global.t("pages.admin.company_settings.azure_info.successfully_updated"));
};
</script>

<style lang="scss" scoped>
.nav-container {
  display: flex;
}

.btn-container {
  display: flex;
  justify-content: end;
}
</style>
