<template>
  <AppWizardPath
    :steps="steps"
    :selected-step="selectedStep"
    name-prefix="pages.admin.company_goals.add_new_goal.path"
    @step-click="stepClick" />
</template>
<script setup lang="ts">
import AppWizardPath from "@/components/AppWizardPath.vue";

interface Props {
  selectedStep: number;
}
defineProps<Props>();

const emit = defineEmits<(e: "stepClick", step: number) => void>();

const steps = [
  { number: 1, name: "overview" },
  { number: 2, name: "general_skills" },
  { number: 3, name: "language_skills" },
];
const stepClick = (step: number) => emit("stepClick", step);
</script>
