// eslint-disable-next-line
export type Filter<T = any> = {
  title: string;
  fieldName: keyof T;
  filterOptions: FilterOption[];
  selectedOption: string;
};
export type FilterOption = {
  label: string;
  value: string;
};

export const SELECT_ALL = "ALL";
