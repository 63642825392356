<template>
  <AppAutomaticModalOpener :modal-config="modalConfig">
    <ProfilePerformanceChartConfigurerWizardPath :selected-step="currentStep" @step-click="stepClick" />
    <div class="padding--m">
      <div v-if="currentStep === 1" data-cy="step-1">
        <h2>{{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.introduction") }}</h2>
        <div class="info-panel">
          <p class="margin-v--m">{{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_1") }}</p>
          <p class="margin-b--m">
            {{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_2") }}
          </p>
        </div>
        <div class="info-image">
          <img src="@/assets/images/skills.svg" alt="" />
        </div>
      </div>
      <div v-if="currentStep === 2" data-cy="step-2">
        <h2>{{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills") }}</h2>
        <div v-if="mountFinished && currentJobSkills.length > 2">
          <div class="info-panel">
            <p class="margin-v--m">
              {{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills.info") }}
            </p>
          </div>
          <AppAutocomplete
            :items="filteredCurrentJobSkillsAutocompleteItems"
            :placeholder="$t('pages-my-profile.profile_updating.performance_graph.configurer.search.placeholder')"
            :maxlength="mediumTextLength"
            data-cy="autocomplete-current-job-skills"
            @change="(searchTerm) => (currentJobSkillsSearch = searchTerm)"
            @click="currentJobSkillAutocompleteClicked" />
          <div class="grid--two-columns@medium margin-t--m" data-cy="current-job-skills">
            <AppCheckbox
              v-for="option in currentJobSkills"
              :key="`${option.id}_${currentJobSkillsSearch}_${option.checked}`"
              v-model="option.checked"
              :text="getSkillLabel(option)"
              :highlighted="isCurrentJobSkillBeingSearched(option.name)" />
          </div>
          <div class="text text--error margin-v--m">
            {{ form.errors.value.currentJobSkills }}
          </div>
        </div>
        <div v-else-if="mountFinished && currentJobSkills.length < 3" class="info-panel">
          <AppInfoPanel :title="$t('pages-my-profile.profile_updating.steps_guide.current_job.title')">
            <p class="margin-b--s text text--bold">
              {{ $t("pages-my-profile.profile_updating.performance_graph.configurer.current_job_requirements") }}
            </p>
            <AppButton
              :text="$t('pages-my-profile.profile_updating.performance_graph.configurer.go_to_current_job')"
              size="m"
              variant="primary"
              data-cy="go-to-current-experience"
              @onclick="goToMyCurrentExperience" />
          </AppInfoPanel>
        </div>
      </div>
      <div v-if="currentStep === 3" data-cy="step-3">
        <h2>{{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills") }}</h2>
        <div v-if="mountFinished && idealJobSkills.length > 2">
          <div class="info-panel">
            <p class="margin-v--m">{{ $t("pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills.info") }}</p>
          </div>
          <AppAutocomplete
            :items="filteredIdealJobSkillsAutocompleteItems"
            :placeholder="$t('pages-my-profile.profile_updating.performance_graph.configurer.search.placeholder')"
            :maxlength="mediumTextLength"
            data-cy="autocomplete-ideal-job-skills"
            @change="(searchTerm) => (idealJobSkillsSearch = searchTerm)"
            @click="idealJobSkillAutocompleteClicked" />
          <div class="grid--two-columns@medium margin-t--m" data-cy="ideal-job-skills">
            <AppCheckbox
              v-for="option in idealJobSkills"
              :key="`${option.id}_${idealJobSkillsSearch}_${option.checked}`"
              v-model="option.checked"
              :text="getSkillLabel(option)"
              :highlighted="isIdealJobSkillBeingSearched(option.name)" />
          </div>
          <div class="text text--error margin-v--m">
            {{ form.errors.value.idealJobSkills }}
          </div>
        </div>
        <div v-else-if="mountFinished && idealJobSkills.length < 3" class="info-panel">
          <AppInfoPanel :title="$t('pages-my-profile.profile_updating.steps_guide.ideal_job.title')">
            <p class="margin-b--s text text--bold">
              {{ $t("pages-my-profile.profile_updating.performance_graph.configurer.ideal_job_requirements") }}
            </p>
            <AppButton
              :text="$t('pages-my-profile.profile_updating.performance_graph.configurer.go_to_ideal_job')"
              size="m"
              variant="primary"
              data-cy="go-to-ideal-job"
              @onclick="goToMyIdealJob" />
          </AppInfoPanel>
        </div>
      </div>
    </div>
    <template #actions>
      <div class="wizard__actions">
        <div>
          <AppButton
            :text="$t('buttons.previous')"
            variant="secondary"
            size="m"
            :disabled="currentStep === 1"
            data-cy="prev"
            @onclick="prev()" />
        </div>
        <div>
          <AppButton
            v-if="currentStep !== lastStep"
            :text="$t('buttons.next')"
            variant="secondary"
            size="m"
            data-cy="next"
            @onclick="next()" />
          <AppButton
            v-if="currentStep === lastStep"
            :text="$t('buttons.save')"
            class="margin-l--m"
            variant="primary"
            size="m"
            data-cy="save"
            @onclick="save()" />
        </div>
      </div>
    </template>
  </AppAutomaticModalOpener>
</template>

<script lang="ts" setup>
import { type Ref, ref, onMounted, computed } from "vue";
import { array as yupArray, object as yupObject } from "yup";
import i18n from "@/i18n";

import { useForm } from "vee-validate";

import AppCheckbox from "@/components/AppCheckbox.vue";
import AppButton from "@/components/AppButton.vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppInfoPanel from "@/components/AppInfoPanel.vue";
import type { ModalBase } from "@/components/modal/models";
import { mediumTextLength } from "@/constants/restrictions";

import ProfilePerformanceChartConfigurerWizardPath from "@/views/app/my-profile/components/overview/performance-chart-configurer/ProfilePerformanceChartConfigurerWizardPath.vue";

import useMePerformanceGraphService from "@/services/useMePerformanceGraphService";
import type { SkillProgression } from "@/models/skillProgression";
import type { AutocompleteItem } from "@/models/autocompleteItem";
import { containsInsensitive } from "@/utils/stringUtils";
import router from "@/router/router";

const { getAvailableSkills, getPerformanceGraph, updatePerformanceGraph } = useMePerformanceGraphService();

interface Props {
  initialStep: number;
}
type WizardSkill = {
  id: number;
  name: string;
  progression: SkillProgression;
  checked: boolean;
};
const props = defineProps<Props>();

interface Emits {
  (e: "close"): void;
  (e: "updated"): void;
}
const emit = defineEmits<Emits>();

const currentStep: Ref<number> = ref<number>(props.initialStep);
const currentJobSkills: Ref<WizardSkill[]> = ref<WizardSkill[]>([]);
const currentJobSkillsSearch: Ref<string> = ref<string>("");
const currentJobSkillsAutocompleteItems: Ref<AutocompleteItem[]> = ref<AutocompleteItem[]>([]);
const idealJobSkills: Ref<WizardSkill[]> = ref<WizardSkill[]>([]);
const idealJobSkillsSearch: Ref<string> = ref<string>("");
const idealJobSkillsAutocompleteItems: Ref<AutocompleteItem[]> = ref<AutocompleteItem[]>([]);
const mountFinished = ref<boolean>(false);

onMounted(async () => {
  const currentPerformanceGraph = await getPerformanceGraph();
  const availableSkills = await getAvailableSkills();
  currentJobSkills.value = availableSkills.currentJobSkills.map((s) => ({
    ...s,
    checked: currentPerformanceGraph.currentJobProgressions.some((p) => p.skillId === s.id),
  }));
  idealJobSkills.value = availableSkills.idealJobSkills.map((s) => ({
    ...s,
    checked: currentPerformanceGraph.idealJobProgressions.some((p) => p.skillId === s.id),
  }));
  currentJobSkillsAutocompleteItems.value = availableSkills.currentJobSkills.map((s) => ({ id: s.id, text: s.name }));
  idealJobSkillsAutocompleteItems.value = availableSkills.idealJobSkills.map((s) => ({ id: s.id, text: s.name }));
  mountFinished.value = true;
});

const skillCountError = i18n.global.t("pages-my-profile.profile_updating.performance_graph.configurer.errors.skills_count");

const schema = yupObject({
  currentJobSkills: yupArray().min(3, skillCountError).max(6, skillCountError),
  idealJobSkills: yupArray().min(3, skillCountError).max(6, skillCountError),
});

const form = useForm({ validationSchema: schema });

const modalConfig: ModalBase = {
  title: "",
  variant: "large",
  closeCallback: () => emit("close"),
};

const lastStep = 3;

const filteredCurrentJobSkillsAutocompleteItems = computed(() =>
  currentJobSkillsAutocompleteItems.value?.filter((s) => containsInsensitive(s.text, currentJobSkillsSearch.value)).slice(0, 5)
);

const filteredIdealJobSkillsAutocompleteItems = computed(() =>
  idealJobSkillsAutocompleteItems.value.filter((s) => containsInsensitive(s.text, idealJobSkillsSearch.value)).slice(0, 5)
);

const isIdealJobSkillBeingSearched = (name: string): boolean =>
  idealJobSkillsSearch.value !== "" && containsInsensitive(name, idealJobSkillsSearch.value);
const isCurrentJobSkillBeingSearched = (name: string): boolean =>
  currentJobSkillsSearch.value !== "" && containsInsensitive(name, currentJobSkillsSearch.value);

const stepClick = (step: number): void => {
  currentStep.value = step;
};

const next = (): void => {
  if (currentStep.value === lastStep) {
    return;
  }
  currentStep.value += 1;
};

const prev = (): void => {
  if (currentStep.value === 1) {
    return;
  }
  currentStep.value -= 1;
};

const save = async (): Promise<void> => {
  const content = {
    currentJobSkills: currentJobSkills.value.filter((s) => s.checked),
    idealJobSkills: idealJobSkills.value.filter((s) => s.checked),
  };
  form.setValues(content);
  await form.validate();

  if (form.errors.value.currentJobSkills !== undefined) {
    currentStep.value = 2;
    return;
  }
  if (form.errors.value.idealJobSkills !== undefined) {
    currentStep.value = 3;
    return;
  }

  const request = {
    idealJobSkillIds: content.idealJobSkills.map((s) => s.id),
    currentJobSkillIds: content.currentJobSkills.map((s) => s.id),
  };

  await updatePerformanceGraph(request);
  emit("updated");
};

const getSkillLabel = (skill: WizardSkill): string => {
  const progression = i18n.global.t(`entities.skills.${skill.progression}`);
  return `${skill.name} (${progression})`;
};
const currentJobSkillAutocompleteClicked = (item: AutocompleteItem): void => {
  const skill = currentJobSkills.value?.find((s) => s.id === item.id);

  if (skill !== undefined) {
    skill.checked = !skill.checked;
  }
};

const idealJobSkillAutocompleteClicked = (item: AutocompleteItem): void => {
  const skill = idealJobSkills.value?.find((s) => s.id === item.id);

  if (skill !== undefined) {
    skill.checked = !skill.checked;
  }
};

const goToMyCurrentExperience = () => router.push({ name: "my-current-job" });

const goToMyIdealJob = () => router.push({ name: "my-ideal-job-learning" });
</script>
<style scoped lang="scss">
.wizard {
  &__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.info-panel {
  background-color: var(--color-neutral-200);
  padding: var(--space-s);
  margin: var(--space-s) 0 var(--space-s) 0;
  border-radius: var(--size-xs);
}

.info-image {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
