<template>
  <div class="carousel-card course-card" :class="{ 'course-card--admin': showAdminOptions }" data-cy="course-card">
    <CourseImage :course="course" />
    <div class="course-card__information" :class="{ 'course-card__information--large': !showAdminOptions }">
      <CourseCardHeader :course="course" @click="emit('click', course)" />
      <CourseModeIndicator :course="course" />
      <p class="text text--xs course-card__description text--line-clamp-3" v-html="toHtml(course.description)"></p>
      <div class="course-card__footer margin-t--auto">
        <p class="text text--xs course-card__duration" data-cy="course-duration">
          {{ getDurationStringFromHours(course.durationHours) }}
        </p>
        <AppTag icon="star" :main-text="!ratedCourse.averageRate ? '-' : formatNumber(ratedCourse.averageRate)" type="like" />
      </div>
      <div v-if="showAdminOptions" class="course-card__actions">
        <template v-if="!assignMode">
          <AppIconOnlyButton v-if="!noEditable" icon="pencil" :title="$t('buttons.edit')" @click="emit('edit', course)" />
          <AppIconOnlyButton icon="delete" :title="$t('buttons.delete')" @click="emit('delete', course)" />
        </template>
      </div>
      <CourseGoToTrainingButton v-else :course="(course as EmployeeCourse)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { EmployeeCourse, CourseBase, RatedCourse } from "@/models/course";
import CourseImage from "@/views/shared/learning/CourseImage.vue";
import CourseCardHeader from "@/views/shared/learning/CourseCardHeader.vue";
import CourseModeIndicator from "@/views/shared/learning/CourseModeIndicator.vue";
import CourseGoToTrainingButton from "@/views/shared/learning/CourseGoToTrainingButton.vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import { getDurationStringFromHours } from "@/utils/timeUtils";
import { formatNumber } from "@/utils/numberUtils";
import { toHtml } from "@/utils/stringUtils";
import AppTag from "@/components/AppTag.vue";
import { computed } from "vue";

interface Props {
  course: CourseBase;
  showAdminOptions?: boolean;
  noEditable?: boolean;
  assignMode?: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "click", value: CourseBase): void;
  (e: "edit", course: CourseBase): void;
  (e: "delete", course: CourseBase): void;
}>();

const ratedCourse = computed<RatedCourse>(() => props.course as RatedCourse);
</script>
<style lang="scss" scoped>
.course-card {
  position: relative;
  border-radius: var(--default-border-radius);
  overflow: hidden;
  height: 50rem;
  display: grid;
  grid-template-rows: auto 1fr;

  &__information {
    padding: var(--space-s);
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__description {
    color: var(--color-neutral-600);
  }

  &__actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: var(--space-xs);
  }

  &--admin {
    margin: 0;
  }
}
</style>
