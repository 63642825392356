<template>
  <div class="layout-base">
    <div class="menu">
      <LayoutMenu :menu-options="menuOptions" :root-url="rootUrl" />
    </div>
    <div class="content">
      <AppLoading v-if="isLoading" />
      <LayoutHeader :root-url="rootUrl" />
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppLoading from "@/components/AppLoading.vue";
import LayoutHeader from "@/layout/shared/LayoutHeader.vue";
import LayoutMenu from "@/layout/shared/LayoutMenu.vue";
import type { MenuOption } from "@/layout/models/menu-option";
import { networkStateStore } from "@/services/shared/networkStateStore";

interface Props {
  menuOptions: MenuOption[];
  rootUrl: string;
}
defineProps<Props>();

const isLoading = computed(() => networkStateStore.state.numberOfCallsActives > 0);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.layout-base {
  display: grid;
  grid-template-areas:
    "menu content"
    "menu content";
  grid-template-rows: auto 1fr;
  grid-template-columns: 28rem 1fr;
  height: 100vh;

  @media only screen and (max-width: $large-screen-breakpoint) {
    grid-template-columns: 72px 1fr;
  }

  @media only screen and (max-width: $medium-screen-breakpoint) {
    display: initial;
    padding-bottom: 64px;
    .content {
      overflow-y: scroll;
      height: calc(100vh - 64px);
    }
    .menu {
      position: absolute;
      bottom: 0;
      height: 64px;
      width: 100%;
    }
  }
}
.menu {
  grid-area: menu;
  padding: 0;
  background-color: var(--color-sidebar-menu);
  box-shadow: 0 0 12px var(--color-sidebar-menu-shadow);
}
.content {
  grid-area: content;
  overflow-y: scroll;
}
</style>
