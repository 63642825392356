<template>
  <AppSelect v-model="value" :items="availableLevels" :label="$t('pages.admin.learning.form.fields.level.label')" />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n";
import AppSelect from "@/components/AppSelect.vue";
import { CourseLevel } from "@/models/course";

interface Props {
  modelValue?: CourseLevel;
}
const props = withDefaults(defineProps<Props>(), { modelValue: CourseLevel.Beginner });

const emit = defineEmits<(e: "update:modelValue", value: CourseLevel) => void>();

const availableLevels = Object.keys(CourseLevel)
  .filter((value) => Number(value) >= 0)
  .map((courseLevel) => ({
    value: courseLevel,
    text: i18n.global.t(`entities.courses.level.${courseLevel}`),
  }));

const value = computed({
  get: () => props.modelValue.toString(),
  set: (val: string) => emit("update:modelValue", Number(val)),
});
</script>
