<template>
  <div class="learning-path__level" :class="levelClass" :data-cy="`level-${area}-${level}`">
    <span class="text text--xl text--bold"> {{ $t(textKey) }} </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { LearningPathCourseArea, LearningPathCourseLevel } from "@/views/app/my-learning/components/learning-path/learningPathModels";

interface Props {
  level: LearningPathCourseLevel;
  area: LearningPathCourseArea;
  onlySmallScreen?: boolean;
  striped?: boolean;
}
const props = defineProps<Props>();

const gridArea = computed(() => `level-${props.area}-${props.level}`);
const textKey = computed(() =>
  props.level === "additional"
    ? "pages.learning.my_learning_path.additional_learning"
    : `pages.learning.my_learning_path.levels.${props.level}`
);
const levelClass = computed(() => ({
  [`learning-path__level--only-small-screen`]: props.onlySmallScreen,
  [`learning-path__level--striped`]: props.striped,
}));
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
@import "@/styles/theme";

$stripeColor: $color-system-info-4;
$borderColor: $color-border;

.learning-path {
  &__level {
    grid-area: v-bind(gridArea);

    padding: var(--space-m);

    &--only-small-screen {
      display: none;
    }
    &--additional {
      background-color: $color-neutral-100;
    }
    &--striped {
      background-color: $stripeColor;
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }
  }
}

@media only screen and (max-width: $big-screen-breakpoint) {
  .learning-path {
    &__level {
      &--only-small-screen {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: $medium-screen-breakpoint) {
  .learning-path {
    &__level {
      border-bottom: none;
      text-align: center;
    }
  }
}
</style>
