<template>
  <CourseCarousel
    v-if="featuredCourses.length > 0"
    :courses="featuredCourses"
    data-cy="featured-courses"
    :title="title"
    @course-selected="cardClick" />
</template>

<script setup lang="ts">
import { ref, type Ref, onMounted } from "vue";
import type { EmployeeCourse } from "@/models/course";
import CourseCarousel from "@/views/shared/learning/CourseCarousel.vue";
import useMeCoursesService from "@/services/useMeCoursesService";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import useCourseRateRefresher from "@/views/shared/learning/useCourseRateRefresher";

interface Props {
  eventEmitter?: UseEventEmitterServiceReturn;
  title: string;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "courseSelected", course: EmployeeCourse) => void>();

const { getFeaturedCourses } = useMeCoursesService();
const { refreshCourseRateData } = useCourseRateRefresher();

const featuredCourses: Ref<EmployeeCourse[]> = ref<EmployeeCourse[]>([]);

onMounted(async () => {
  featuredCourses.value = await getFeaturedCourses();
  if (props.eventEmitter) {
    props.eventEmitter.on("courseSaved", refreshCourseData);
  }
});
const refreshCourseData = (courseId: number) => refreshCourseRateData(courseId, featuredCourses.value);
const cardClick = (course: EmployeeCourse) => emit("courseSelected", course);
</script>
