<template>
  <div class="profile-information">
    <div class="profile-information__avatar">
      <AppProfileAvatar :size="size" :information="employeeInformation" />
    </div>
    <div class="profile-information__details details" :class="`profile-information__details--${direction}`">
      <span class="text--m text--bold text--link margin-r--xs" @click="detailsClick">
        {{ `${employee.firstName} ${employee.lastName}` }}
      </span>
      <span v-if="employee.currentJob" class="text text--tertiary text--s text--bold">{{ employee.currentJob }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import AppProfileAvatar from "@/components/AppProfileAvatar.vue";
import type { AvatarInformation } from "@/models/employee/avatarInformation";
import type { EmployeeProfileInformation } from "@/models/employeeProfileInformation";

interface Props {
  size: "s" | "m";
  direction: "row" | "column";
  employee: EmployeeProfileInformation;
}
const props = defineProps<Props>();
const employeeInformation: AvatarInformation = { ...props.employee };

const emit = defineEmits<(e: "detailsClick", id: number) => void>();

const detailsClick = () => emit("detailsClick", props.employee.id);
</script>
<style lang="scss" scoped>
.profile-information {
  display: flex;
  gap: var(--space-s);
  align-items: center;

  &__avatar {
    min-width: inherit;
  }

  &__details {
    display: flex;
  }

  &__details--row {
    display: block;
  }

  &__details--column {
    flex-direction: column;
  }
}
</style>
