<template>
  <AppTag :main-text="courseTypeDescription" type="training" :training-type="course.courseType" :title="courseTypeDescription" />
</template>

<script lang="ts" setup>
import i18n from "@/i18n";
import type { CourseBase } from "@/models/course";
import AppTag from "@/components/AppTag.vue";
import { computed } from "vue";

interface Props {
  course: CourseBase;
}
const props = defineProps<Props>();

const courseTypeDescription = computed(() => i18n.global.t(`pages.learning.filter.type.${props.course.courseType}`));
</script>
