import type { Ref } from "vue";
import type { CompanyAd } from "@/models/response/companyAd";
import type { AzureAdServicePrincipal } from "@/models/azureAd";
import type { CompanyAdSubscription } from "@/models/response/companyAdSubscription";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/companies";
const { post, get, loading } = useHttpClient();

interface UseCompanyServiceReturn {
  getAzureAdCompany: (token: string) => Promise<CompanyAd>;
  validateServicePrincipalAndGetNumberOfUsers: (
    token: string,
    azureAdServicePrincipal: AzureAdServicePrincipal
  ) => Promise<CompanyAdSubscription>;
  signUpAzureAdCompany: (token: string, azureAdServicePrincipal: AzureAdServicePrincipal) => Promise<CompanyAdSubscription>;
  loading: Ref<boolean>;
}

export default (): UseCompanyServiceReturn => {
  const getAzureAdCompany = (token: string): Promise<CompanyAd> => get(`${BASE_URL}/azuread/${token}`);

  const validateServicePrincipalAndGetNumberOfUsers = (
    token: string,
    azureAdServicePrincipal: AzureAdServicePrincipal
  ): Promise<CompanyAdSubscription> => post(`${BASE_URL}/azuread/${token}/validate`, { ...azureAdServicePrincipal });

  const signUpAzureAdCompany = (token: string, azureAdServicePrincipal: AzureAdServicePrincipal) =>
    post<CompanyAdSubscription>(`${BASE_URL}/azuread/${token}`, { ...azureAdServicePrincipal });

  return { getAzureAdCompany, validateServicePrincipalAndGetNumberOfUsers, signUpAzureAdCompany, loading };
};
