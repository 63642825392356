<template>
  <KpiChart :chart-options="chartOptions" :chart-series="series" />
</template>

<script lang="ts" setup>
import type { ApexOptions } from "apexcharts";
import { PIE_DONUTS_COLORS } from "@/models/questionKpi";
import KpiChart from "@/views/admin/ai-assistant/components/kpis/KpiChart.vue";

interface Props {
  series: number[];
  seriesPercentage: number[];
  labels: string[];
}

const props = defineProps<Props>();

const chartOptions: ApexOptions = {
  labels: props.labels,
  chart: {
    type: "donut",
    selection: {
      enabled: false,
    },
  },
  states: {
    active: {
      filter: {
        type: "none",
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    normal: {
      filter: {
        type: "none",
      },
    },
  },
  colors: PIE_DONUTS_COLORS,
  plotOptions: {
    pie: {
      donut: {
        background: "transparent",
        labels: {
          show: false,
        },
      },
      expandOnClick: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "solid",
  },
  legend: {
    show: true,
    position: "right",
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    marker: {
      show: false,
    },
    style: {
      fontSize: "11px",
    },
    y: {
      formatter: function (_value, { seriesIndex }) {
        return props.seriesPercentage[seriesIndex] + "%";
      },
    },
  },
};
</script>
