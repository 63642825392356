<template>
  <div>
    <AppInputText
      v-model="title"
      data-cy="course-title"
      :label="$t('pages.admin.learning.form.fields.title.label')"
      :placeholder="$t('pages.admin.learning.form.fields.title.placeholder')"
      :maxlength="mediumTextLength"
      :error="titleError" />
    <AppInputTextArea
      v-model="description"
      data-cy="course-description"
      :label="$t('pages.admin.learning.form.fields.description.label')"
      :placeholder="$t('pages.admin.learning.form.fields.description.placeholder')"
      :maxlength="largeTextLength"
      :error="descriptionError"
      :rows="3" />
    <div class="grid--two-columns">
      <AppInputDate
        v-model="startDate"
        data-cy="course-startDate"
        :label="$t('pages.admin.learning.form.fields.startDate.label')"
        :error="startDateError" />
      <AppInputDate
        v-model="endDate"
        data-cy="course-endDate"
        :label="$t('pages.admin.learning.form.fields.endDate.label')"
        :error="endDateError" />
    </div>
    <div class="grid--two-columns">
      <CourseLevelSelector v-model="courseLevel" data-cy="course-level" />
      <AppInputNumber
        v-model="durationHours"
        :step="0.5"
        :min="0.5"
        data-cy="course-duration"
        :label="$t('pages.admin.learning.form.fields.duration.label')"
        :error="durationError" />
    </div>
    <AppInputText
      v-model="topics"
      data-cy="course-topics"
      :label="$t('pages.admin.learning.form.fields.topics.label')"
      :placeholder="$t('pages.admin.learning.form.fields.topics.placeholder')"
      :maxlength="800"
      :error="topicsError" />
    <div class="grid--two-columns">
      <AppSelect
        v-model="language"
        data-cy="course-language"
        :label="$t('pages.admin.learning.form.fields.language.label')"
        :items="companyLanguages" />
      <AppEducationMode v-model="mode" :in-row="true" @update:model-value="modeChanged" />
    </div>
  </div>
</template>

<script lang="ts">
import i18n from "@/i18n";
import { AnyObject, ObjectSchema, mixed as yupMixed, number as yupNumber } from "yup";
import useFormSchema from "@/services/useFormSchema";

const { startDateMinTodaySchema, endDateSchema, inputRequiredText, yupObject, stringSchema } = useFormSchema();

//script setup does not allow export const => we need this to use in the main component
export const getMainCourseDataSchema = (initialStartDate: Date | undefined): ObjectSchema<AnyObject> =>
  yupObject({
    title: stringSchema(),
    description: stringSchema({ maxLength: largeTextLength }).min(1, inputRequiredText()),
    startDate: startDateMinTodaySchema({ dateSet: initialStartDate }),
    endDate: endDateSchema({ startDateFieldName: "startDate", canBeEqualToStartDate: true, required: false }),
    level: yupMixed<CourseLevel>(),
    durationHours: yupNumber()
      .nullable()
      .transform((o, c) => (o === undefined ? null : c))
      .min(0.5, i18n.global.t("inputs.min_required", { minimum: "0,5" })),
    topics: stringSchema({ maxLength: 800 }),
    language: stringSchema(),
    mode: yupMixed<EducationMode>().oneOf(Object.values(EducationMode)),
  });
</script>
<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useField } from "vee-validate";
import AppInputText from "@/components/AppInputText.vue";
import AppInputNumber from "@/components/AppInputNumber.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppEducationMode from "@/components/AppEducationMode.vue";
import CourseLevelSelector from "@/views/admin/learning/components/CourseLevelSelector.vue";
import { mediumTextLength, largeTextLength } from "@/constants/restrictions";
import { getLanguages } from "@/utils/languageUtils";
import { convertDateOnlyToLocale } from "@/utils/dateUtils";
import { CourseLevel, type CourseBase } from "@/models/course";
import { EducationMode } from "@/models/educationMode";

interface Props {
  course?: CourseBase;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "modeChanged", newMode: EducationMode) => void>();

const companyLanguages = getLanguages().map((l) => ({
  text: l.displayName,
  value: l.displayName,
}));

onBeforeMount(() => setFormValues());

const setFormValues = (): void => {
  if (!props.course) {
    language.value = companyLanguages[0].value;
    courseLevel.value = CourseLevel.Beginner;
    mode.value = EducationMode.Online;
    modeChanged();
    return;
  }

  title.value = props.course.title;
  description.value = props.course.description;
  startDate.value = convertDateOnlyToLocale(props.course.start);
  endDate.value = props.course.end ? convertDateOnlyToLocale(props.course.end) : undefined;
  courseLevel.value = CourseLevel[props.course?.level as keyof typeof CourseLevel] ?? CourseLevel.Beginner;
  durationHours.value = props.course.durationHours;
  topics.value = props.course.topics;
  language.value = props.course.language;
  mode.value = props.course.onLine ? EducationMode.Online : EducationMode.Presence;
  modeChanged();
};

const modeChanged = () => emit("modeChanged", mode.value);

const { value: title, errorMessage: titleError } = useField<string>("title");
const { value: description, errorMessage: descriptionError } = useField<string>("description");
const { value: startDate, errorMessage: startDateError } = useField<Date>("startDate");
const { value: endDate, errorMessage: endDateError } = useField<Date | undefined>("endDate");
const { value: courseLevel } = useField<CourseLevel>("level");
const { value: durationHours, errorMessage: durationError } = useField<number | undefined>("durationHours");
const { value: topics, errorMessage: topicsError } = useField<string>("topics");
const { value: language } = useField<string>("language");
const { value: mode } = useField<EducationMode>("mode");
</script>
