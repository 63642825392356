import type { EmployeeMotivation } from "@/models/employee/employeeMotivation";
import type { HobbyCategory } from "@/models/hobbyCategory";
import type { ServiceSeriesData, GroupSeriesData, GroupHobbySeriesData } from "@/models/talent-detection/seriesData";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/company/talent-detection";
const { get, loading } = useHttpClient();

interface UseCompanyTalentDetectionServiceReturn {
  getSeriesDataByAge: () => Promise<ServiceSeriesData[]>;
  getSeriesDataByAgeAndHobby: (hobbyId: number) => Promise<ServiceSeriesData>;
  getSeriesDataByGroup: () => Promise<GroupSeriesData>;
  getSeriesDataByGroupAndHobby: (hobbyId: number) => Promise<GroupHobbySeriesData>;
  getEmployeesByAge: (hobbiesCategory?: HobbyCategory, hobbyId?: number, fromAge?: number, toAge?: number) => Promise<EmployeeMotivation[]>;
  getEmployeesByGroup: (hobbiesCategory: HobbyCategory, groupId?: number, hobbyId?: number) => Promise<EmployeeMotivation[]>;
  loading: Ref<boolean>;
}

export default (): UseCompanyTalentDetectionServiceReturn => {
  const getSeriesDataByAge = (): Promise<ServiceSeriesData[]> => get(`${BASE_URL}/series/age`);
  const getSeriesDataByAgeAndHobby = (hobbyId: number): Promise<ServiceSeriesData> => get(`${BASE_URL}/series/age/${hobbyId}`);
  const getSeriesDataByGroup = (): Promise<GroupSeriesData> => get(`${BASE_URL}/series/group`);
  const getSeriesDataByGroupAndHobby = (hobbyId: number): Promise<GroupHobbySeriesData> => get(`${BASE_URL}/series/group/${hobbyId}`);
  const getEmployeesByAge = (
    hobbiesCategory?: HobbyCategory,
    hobbyId?: number,
    fromAge?: number,
    toAge?: number
  ): Promise<EmployeeMotivation[]> =>
    get(`${BASE_URL}/employees/age?${getQueryStringEmployeesByAge(hobbiesCategory, hobbyId, fromAge, toAge)}`);

  const getEmployeesByGroup = (hobbiesCategory: HobbyCategory, groupId?: number, hobbyId?: number): Promise<EmployeeMotivation[]> =>
    get(`${BASE_URL}/employees/group?${getQueryStringEmployeesByGroup(hobbiesCategory, groupId, hobbyId)}`);

  return {
    getSeriesDataByAge,
    getSeriesDataByAgeAndHobby,
    getSeriesDataByGroup,
    getSeriesDataByGroupAndHobby,
    getEmployeesByAge,
    getEmployeesByGroup,
    loading,
  };
};

const getQueryStringEmployeesByAge = (hobbiesCategory?: HobbyCategory, hobbyId?: number, fromAge?: number, toAge?: number): string => {
  const params = new URLSearchParams();
  if (hobbiesCategory) {
    params.set("hobbiesCategory", hobbiesCategory);
  }
  if (hobbyId) {
    params.set("hobbyId", hobbyId.toString());
  }
  if (fromAge) {
    params.set("fromAge", fromAge.toString());
  }
  if (toAge) {
    params.set("toAge", toAge.toString());
  }
  return params.toString();
};

const getQueryStringEmployeesByGroup = (hobbiesCategory: HobbyCategory, groupId?: number, hobbyId?: number): string => {
  const params = new URLSearchParams();
  params.set("hobbiesCategory", hobbiesCategory);
  if (groupId) {
    params.set("groupId", groupId.toString());
  }
  if (hobbyId) {
    params.set("hobbyId", hobbyId.toString());
  }
  return params.toString();
};
