<template>
  <div class="table-filters">
    <AppTableFilter
      v-for="(filter, index) in modelValue"
      :key="filter.fieldName"
      :filter="filter"
      :show-title="modelValue.length > 1"
      @changed="(newValue) => changed(index, newValue)" />
  </div>
</template>

<script lang="ts" setup>
import type { Filter } from "@/components/table/TableFilter";
import AppTableFilter from "@/components/table/AppTableFilter.vue";

interface Props {
  modelValue: Filter[];
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", filters: Filter[]) => void>();

const changed = (ixFilter: number, newValue: string): void => {
  const filters = [...props.modelValue];
  filters[ixFilter].selectedOption = newValue;
  emit("update:modelValue", filters);
};
</script>

<style lang="scss" scoped>
.table-filters {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;
  row-gap: 1.5rem;
  align-items: center;
  padding: var(--space-s) 0;
}
</style>
