import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";
import type { CompanyGoal, CompanyGoalWithParticipation } from "@/models/companyGoal";
import type { MeGoalDetails } from "@/models/meGoalDetails";

const BASE_URL = "/api/me/goals";
const { get, post, loading, appBaseUrl } = useHttpClient();

interface UseMeGoalsServiceReturn {
  getFeaturedGoals: () => Promise<CompanyGoalWithParticipation[]>;
  getMyCurrentGoals: () => Promise<CompanyGoal[]>;
  getMyGoals: () => Promise<CompanyGoal[]>;
  getMyGoalDetails: (goalId: number) => Promise<MeGoalDetails>;
  participateInMyGoal: (goalId: number) => Promise<boolean>;
  getGoalPictureUrl: (goalId: number) => string;
  searchCompanyGoals: (searchTerm: string) => Promise<CompanyGoalWithParticipation[]>;
  loading: Ref<boolean>;
}

export default (): UseMeGoalsServiceReturn => {
  const getFeaturedGoals = (): Promise<CompanyGoalWithParticipation[]> => get(`${BASE_URL}/featured`);
  const getMyCurrentGoals = (): Promise<CompanyGoal[]> => get(`${BASE_URL}/current`);
  const getMyGoals = (): Promise<CompanyGoal[]> => get(BASE_URL);
  const getMyGoalDetails = (goalId: number): Promise<MeGoalDetails> => get(`${BASE_URL}/${goalId}`);
  const participateInMyGoal = (goalId: number) => post<boolean>(`${BASE_URL}/${goalId}/participate`);
  const getGoalPictureUrl = (goalId: number): string => `${appBaseUrl}${BASE_URL}/${goalId}/picture`;
  const searchCompanyGoals = (searchTerm: string, numberOfItems = 50): Promise<CompanyGoalWithParticipation[]> =>
    get(`${BASE_URL}/search?searchTerm=${searchTerm}&numberOfItems=${numberOfItems}`);

  return {
    getFeaturedGoals,
    getMyCurrentGoals,
    getMyGoals,
    getMyGoalDetails,
    participateInMyGoal,
    getGoalPictureUrl,
    searchCompanyGoals,
    loading,
  };
};
