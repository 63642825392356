<template>
  <img class="image" :class="{ 'image--mini': miniSize }" :src="imageSrc" :alt="goal.title" :title="goal.description" />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { CompanyGoal } from "@/models/companyGoal";
import { imageDefaultForCourses } from "@/constants/urls";
import useMeGoalsService from "@/services/useMeGoalsService";

const { getGoalPictureUrl } = useMeGoalsService();

interface Props {
  goal: CompanyGoal;
  miniSize?: boolean;
}
const props = defineProps<Props>();

const hasImage = computed<boolean>(() => props.goal.picture?.fileName !== undefined);
const imageSrc = computed<string>(() => (hasImage.value ? getGoalPictureUrl(props.goal.id) : imageDefaultForCourses));
</script>

<style lang="scss" scoped>
.image {
  height: 280px;
  width: 100%;
  object-fit: cover;
  &--mini {
    height: 252px;
  }
}
</style>
