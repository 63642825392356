<template>
  <AppAutomaticModalOpener :modal-config="modalConfig">
    <AppForm data-cy="hobbies-creator">
      <AppInputText
        v-model="hobbyName"
        :label="$t('entities.my_interests.hobbies.hobbies-creator.form.name')"
        :error="hobbyNameError"
        :maxlength="mediumTextLength"
        data-cy="hobby-creator-hobby-name" />
      <AppHobbyCategorySelector v-model="hobbyCategory" />
    </AppForm>
  </AppAutomaticModalOpener>
</template>

<script lang="ts" setup>
import { object as yupObject, mixed as yupMixed } from "yup";
import i18n from "@/i18n";
import { mediumTextLength } from "@/constants/restrictions";
import { useField, useForm } from "vee-validate";
import AppInputText from "@/components/AppInputText.vue";
import AppForm from "@/components/AppForm.vue";
import AppHobbyCategorySelector from "@/components/AppHobbyCategorySelector.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import type { ModalBase } from "@/components/modal/models";
import { HobbyCategory } from "@/models/hobbyCategory";
import useHobbiesService from "@/services/useHobbiesService";
import useFormSchema from "@/services/useFormSchema";

const emit = defineEmits<{
  (e: "hobby-created", newHobbyName?: string): void;
  (e: "closed"): void;
}>();

const { createHobby } = useHobbiesService();

const { stringSchema, inputRequiredText } = useFormSchema();

const resourceSchema = yupObject({
  hobbyName: stringSchema(),
  hobbyCategory: yupMixed<HobbyCategory>().required(inputRequiredText()),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: hobbyName, errorMessage: hobbyNameError } = useField<string | undefined>("hobbyName");
const { value: hobbyCategory } = useField<HobbyCategory>("hobbyCategory");
hobbyCategory.value = HobbyCategory.Sport;

const modalConfig: ModalBase = {
  title: i18n.global.t("entities.my_interests.hobbies.hobbies-creator.title"),
  variant: "floating",
  confirmText: i18n.global.t("entities.my_interests.hobbies.hobbies-creator.createButton"),
  showCancelToClose: true,
  confirmCallback: async () => {
    const validationResult = await form.validate();
    if (!validationResult.valid) {
      return false;
    }
    try {
      await createHobby({
        name: hobbyName.value,
        category: hobbyCategory.value,
      });
      emit("hobby-created", hobbyName.value);
    } catch (error) {
      return false;
    }
  },
  onClosed: () => emit("closed"),
};
</script>
