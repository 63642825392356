<template>
  <LayoutPage :title="$t('pages.ai_assistant.title')">
    <AppPanel :padding-content="false" class="padding-b--0">
      <template #header>
        <AppTip
          icon="document"
          :title="$t('pages.ai_assistant.header.title')"
          :content="$t('pages.ai_assistant.header.subtitle')"
          no-margin
          data-cy="ai-assistant-tip" />
      </template>
      <template #default>
        <div v-if="questionsInView.length > 0">
          <div v-for="(item, index) in questionsInView" :key="item.order" class="row" data-cy="ai-assistant-row">
            <AIAssistantQuestion :answer-enabled="true" :item="item" :order="index + 1 + (currentPage - 1) * pageSize" @edited="edited" />
          </div>
        </div>
        <div v-else class="no-content">
          <AppNoContentYet :text="$t('pages.ai_assistant.no.questions')" />
        </div>
      </template>
      <template v-if="questionsInView.length > 0 && totalPages > 1" #footer>
        <div class="navigation">
          <AppButton
            :text="$t('buttons.previous')"
            variant="secondary"
            size="m"
            :disabled="currentPage === 1"
            data-cy="ai-assistant-prev"
            @onclick="previousPage()" />
          <AppTag :main-text="`${currentPage} / ${totalPages}`" type="presence" data-cy="ai-assistant-page" :limited-size="true" />
          <AppButton
            :text="$t('buttons.next')"
            variant="secondary"
            size="m"
            :disabled="currentPage * pageSize >= totalItemsCount"
            data-cy="ai-assistant-next"
            @onclick="nextPage()" />
        </div>
      </template>
    </AppPanel>
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppTag from "@/components/AppTag.vue";
import AppTip from "@/components/AppTip.vue";
import AIAssistantQuestion from "@/views/app/ai-assistant/components/AIAssistantQuestion.vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import type { Question } from "@/models/question";
import useMeQuestionsService from "@/services/useMeQuestionsService";

const pageSize = 10;
const currentPage: Ref<number> = ref<number>(0);
const totalPages: Ref<number> = ref<number>(0);
const totalItemsCount: Ref<number> = ref<number>(0);
const questionsInView: Ref<Question[]> = ref<Question[]>([]);
const questions: Ref<Question[]> = ref<Question[]>([]);

const { getQuestions } = useMeQuestionsService();

const previousPage = (): void => {
  if (currentPage.value > 1) {
    setCurrentPage(currentPage.value - 1);
  }
};
const nextPage = (): void => {
  if (currentPage.value <= totalPages.value) {
    setCurrentPage(currentPage.value + 1);
  }
};
const setCurrentPage = (newCurrentPage: number) => {
  currentPage.value = newCurrentPage;
  refreshQuestionsInView();
};

const edited = (newQuestionState: Question): void => {
  const index: number = questions.value.findIndex((question) => question.id === newQuestionState.id);
  questions.value[index] = newQuestionState;
  refreshQuestionsInView();
};

onMounted(async () => {
  questions.value = await getQuestions();
  totalItemsCount.value = questions.value.length;
  setCurrentPage(1);
  totalPages.value = Math.ceil(totalItemsCount.value / pageSize);
});

const refreshQuestionsInView = (): void => {
  const initialItem = (currentPage.value - 1) * pageSize;
  const finalItem = initialItem + pageSize;
  questionsInView.value = questions.value.slice(initialItem, finalItem) || [];
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: var(--space-m);
  padding-bottom: 0;
  background-color: var(--color-ai-assistant-header-background);
  border-radius: var(--space-xs);

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.row {
  padding: var(--space-s) 0 var(--space-s) 0;
  border-bottom: 1px solid var(--color-border);

  &:last-child {
    border-bottom: none;
  }
}

.no-content {
  padding: 0 var(--space-m) var(--space-m) var(--space-m);
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
