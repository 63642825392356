<template>
  <div>
    <AppTip unique-name="my_profile_initial_guide" :content="$t('pages-my-profile.profile_updating.welcome_guide.tip')" />
    <h3 class="margin-b--s">{{ $t("pages-my-profile.profile_updating.welcome_guide.steps.title") }}</h3>
    <div class="steps">
      <div v-for="(icon, index) in icons" :key="icon" class="steps__item">
        <span class="icon steps__icon" :class="`icon-${icon}`"></span>
        <div>
          {{ $t(`pages-my-profile.profile_updating.welcome_guide.steps.${index + 1}`) }}
        </div>
      </div>
    </div>
    <h3 class="margin-b--s">{{ $t("pages-my-profile.profile_updating.welcome_guide.summary.title") }}</h3>
    <div>
      {{ $t("pages-my-profile.profile_updating.welcome_guide.summary.content") }}
    </div>
    <div class="margin-v--m">
      <span class="text text--link text--bold" @click="showExample">
        {{ $t("pages-my-profile.profile_updating.welcome_guide.summary.see_example") }}
      </span>
    </div>
    <ProfilePerformanceExampleChart />
  </div>
</template>

<script lang="ts" setup>
import ProfilePerformanceExampleChart from "@/views/app/my-profile/components/overview/ProfilePerformanceExampleChart.vue";
import AppTip from "@/components/AppTip.vue";

const emit = defineEmits<(e: "show-example") => void>();

const showExample = () => emit("show-example");

const icons = ["user", "chart", "list", "linkedin"];
</script>

<style lang="scss" scoped>
.steps {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-m);
    margin-bottom: var(--space-m);
  }

  &__icon {
    font-size: 40px;
    color: var(--color-primary-accent1);
  }
}
</style>
