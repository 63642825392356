import { Ref } from "vue";
import useModal from "@/components/modal/useModal";
import i18n from "@/i18n";
import { exportFilesToBase64 } from "@/utils/fileUtils";
import { FileAttachment } from "@/models/fileAttachment";

const shortFileNameLength = 100;
interface UploadDocumentProps {
  withoutModal: boolean;
}
interface UseUploadDocumentReturn {
  upload: () => void;
  clearFileSelector: () => void;
  deleteDocument: (resetFileSelector: () => void) => void;
  getFileFromInputAndClear: () => Promise<FileAttachment | undefined>;
}

const { openTextModal } = useModal();

export const useUploadDocument = (fileInput: Ref<HTMLInputElement | undefined>, props: UploadDocumentProps): UseUploadDocumentReturn => {
  const upload = () => fileInput.value?.click();

  const clearFileSelector = () => {
    if (fileInput.value) {
      fileInput.value.value = "";
    }
  };

  const deleteDocument = (resetFileSelector: () => void) => {
    if (props.withoutModal) {
      resetFileSelector();
      return;
    }

    openTextModal({
      title: i18n.global.t("inputs.upload_document.prompt.delete.title"),
      contentText: i18n.global.t("inputs.upload_document.prompt.delete.description"),
      showCancelToClose: true,
      confirmCallback: resetFileSelector,
    });
  };

  const getFileFromInputAndClear = async (): Promise<FileAttachment | undefined> => {
    const files = fileInput.value?.files;
    if (!files?.length) {
      return;
    }
    const list = await exportFilesToBase64(files);
    clearFileSelector();
    return {
      ...list[0],
      fileName: getValidLengthFileName(list[0].fileName),
    };
  };

  const getValidLengthFileName = (fileName: string): string => {
    if (fileName.length <= shortFileNameLength) {
      return fileName;
    }
    const extension = fileName.indexOf(".") > 0 ? fileName.split(".").pop() : "";
    const fileNameCropped = fileName.substring(0, shortFileNameLength - 1 - (extension?.length ?? 0));
    return `${fileNameCropped}.${extension}`;
  };

  return { upload, clearFileSelector, deleteDocument, getFileFromInputAndClear };
};
