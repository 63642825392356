<template>
  <div class="chatbot-steps">
    <div v-for="(messageKey, index) in messageKeys" :key="messageKey" class="chatbot-steps__step">
      <span class="text text--bold">{{ $t("pages.admin.company_settings.general_info.chatbot.step") }} {{ index + 1 }}</span>
      <span class="text" v-html="$t(messageKey)"></span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
interface Props {
  channelName: string;
}
const props = defineProps<Props>();

const stepsPrefix = `pages.admin.company_settings.general_info.chatbot.${props.channelName}.step`;
type messagesType = typeof i18n.global.messages.value;
const currentLocale = i18n.global.locale.value as keyof messagesType;
const currentMessages = i18n.global.messages.value[currentLocale];
const messageKeys = Object.keys(currentMessages).filter((key) => key.startsWith(stepsPrefix));
</script>
<style lang="scss" scoped>
.chatbot-steps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__step {
    display: flex;
    flex-direction: column;
  }
}
</style>
