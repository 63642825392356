<template>
  <AppUploadDocumentInline
    v-model="curriculum"
    :label="$t('pages.current_experience.form.curriculum.upload')"
    :download-url="getCurriculumUrl()"
    button-variant="secondary"
    :accept="allowedExtensions.join()"
    :error="curriculumError"
    :disable-download="!!form.errors.value.curriculum"
    :without-modal="!!form.errors.value.curriculum"
    data-cy="curriculum-uploader"
    @change="curriculumFileChanged" />
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { useField, useForm } from "vee-validate";
import AppUploadDocumentInline from "@/components/uploadDocument/AppUploadDocumentInline.vue";
import { FileAttachment } from "@/models/fileAttachment";
import { ProfileInformation } from "@/models/profileInformation";
import { allowedExtensions, maxFileSizeInMB } from "@/utils/fileUtils";
import useFormSchema from "@/services/useFormSchema";
import useMeService from "@/services/useMeService";

interface Props {
  profileInfo: ProfileInformation;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "curriculumSet", fileName: string | null) => void>();

const { attachmentSchema, yupObject } = useFormSchema();
const { getCurriculumUrl, deleteCurriculum, saveCurriculum } = useMeService();

const resourceSchema = yupObject({
  curriculum: attachmentSchema(allowedExtensions, maxFileSizeInMB),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: curriculum, errorMessage: curriculumError } = useField<FileAttachment | undefined>("curriculum");

const curriculumFileChanged = async (value: FileAttachment | undefined): Promise<void> => {
  if (value) {
    await uploadCurriculumFile(value);
    return;
  }
  await deleteCurriculumFile();
};

const uploadCurriculumFile = async (value: FileAttachment) => {
  curriculum.value = value;
  const validationResult = await form.validate();

  if (!validationResult.valid) {
    return;
  }
  await saveCurriculum(value);
  emit("curriculumSet", value.fileName);
};

const deleteCurriculumFile = async () => {
  if (curriculumIsDirty.value) {
    setFormFields();
    return;
  }
  await deleteCurriculum();
  emit("curriculumSet", null);
};

const setFormFields = () =>
  (curriculum.value = props.profileInfo.curriculumName ? { fileName: props.profileInfo.curriculumName } : undefined);
const curriculumIsDirty = computed(() => (curriculum.value?.fileName ?? "") !== (props.profileInfo.curriculumName ?? ""));

onMounted(setFormFields);
watch(() => props.profileInfo.curriculumName, setFormFields);
</script>
