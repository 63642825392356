<template>
  <div>
    <h3 class="margin-v--l">{{ $t("pages.learning.my_learning_path.introduction.title") }}</h3>
    <p class="margin-b--l">{{ $t("pages.learning.my_learning_path.introduction.explanation") }}</p>

    <MyLearningPathRecalculateToggle />

    <div v-if="learningPath" class="learning-path">
      <MyLearningPathContentHeader area="skills" icon="skill" />
      <MyLearningPathContentHeader area="languages" icon="language" />
      <MyLearningPathContentHeader area="financial" icon="financial" />

      <!-- BEGINNER -->

      <MyLearningPathContentLevel area="skills" level="beginner" :striped="true" />
      <MyLearningPathContentLevel area="languages" level="beginner" :striped="true" :only-small-screen="true" />
      <MyLearningPathContentLevel area="financial" level="beginner" :striped="true" :only-small-screen="true" />

      <MyLearningPathCourseListContainer
        area="skills"
        level="beginner"
        :striped="true"
        :courses="learningPath.recommendations.skills.beginner"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="languages"
        level="beginner"
        :striped="true"
        :courses="learningPath.recommendations.languages.beginner"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="financial"
        level="beginner"
        :striped="true"
        :courses="learningPath.recommendations.financial.beginner"
        @course-selected="selectCourse" />

      <!-- ADVANCED -->

      <MyLearningPathContentLevel area="skills" level="advanced" />
      <MyLearningPathContentLevel area="languages" level="advanced" :only-small-screen="true" />
      <MyLearningPathContentLevel area="financial" level="advanced" :only-small-screen="true" />

      <MyLearningPathCourseListContainer
        area="skills"
        level="advanced"
        :courses="learningPath.recommendations.skills.advanced"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="languages"
        level="advanced"
        :courses="learningPath.recommendations.languages.advanced"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="financial"
        level="advanced"
        :courses="learningPath.recommendations.financial.advanced"
        @course-selected="selectCourse" />

      <!-- EXPERT -->

      <MyLearningPathContentLevel area="skills" level="expert" :striped="true" />
      <MyLearningPathContentLevel area="languages" level="expert" :striped="true" :only-small-screen="true" />
      <MyLearningPathContentLevel area="financial" level="expert" :striped="true" :only-small-screen="true" />
      <MyLearningPathCourseListContainer
        area="skills"
        level="expert"
        :striped="true"
        :courses="learningPath.recommendations.skills.expert"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="languages"
        level="expert"
        :striped="true"
        :courses="learningPath.recommendations.languages.expert"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="financial"
        level="expert"
        :striped="true"
        :courses="learningPath.recommendations.financial.expert"
        @course-selected="selectCourse" />

      <!-- ADDITIONAL -->

      <MyLearningPathContentLevel area="skills" level="additional" />
      <MyLearningPathContentLevel area="languages" level="additional" :only-small-screen="true" />
      <MyLearningPathContentLevel area="financial" level="additional" :only-small-screen="true" />

      <MyLearningPathCourseListContainer
        area="skills"
        level="additional"
        :courses="learningPath.additionalRecommendations.skills"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="languages"
        level="additional"
        :courses="learningPath.additionalRecommendations.languages"
        @course-selected="selectCourse" />
      <MyLearningPathCourseListContainer
        area="financial"
        level="additional"
        :courses="learningPath.additionalRecommendations.financial"
        @course-selected="selectCourse" />
    </div>
    <CourseDetails v-if="selectedCourse" :course="selectedCourse" :allow-edit="true" @saved="load" @closed="selectedCourse = undefined" />
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import CourseDetails from "@/views/shared/learning/CourseDetails.vue";
import MyLearningPathContentHeader from "@/views/app/my-learning/components/learning-path/MyLearningPathContentHeader.vue";
import MyLearningPathContentLevel from "@/views/app/my-learning/components/learning-path/MyLearningPathContentLevel.vue";
import MyLearningPathCourseListContainer from "@/views/app/my-learning/components/learning-path/MyLearningPathCourseListContainer.vue";
import MyLearningPathRecalculateToggle from "@/views/app/my-learning/components/learning-path/MyLearningPathRecalculateToggle.vue";
import type { LearningPath } from "@/models/learningPath";
import useMeCoursesService from "@/services/useMeCoursesService";
import type { EmployeeCourse } from "@/models/course";

const { getEmployeeLearningPath } = useMeCoursesService();

const learningPath = ref<LearningPath>();
const selectedCourse = ref<EmployeeCourse>();

onMounted(() => load());

const load = async () => (learningPath.value = await getEmployeeLearningPath());

const selectCourse = (course: EmployeeCourse) => (selectedCourse.value = course);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
@import "@/styles/theme";

$borderColor: $color-border;

.learning-path {
  display: grid;
  grid-template-columns: 20rem 1fr 1fr 1fr;
  grid-template-areas:
    ".                       header-skills             header-languages              header-financial            "
    "level-skills-beginner   content-skills-beginner   content-languages-beginner    content-financial-beginner  "
    "level-skills-advanced   content-skills-advanced   content-languages-advanced    content-financial-advanced  "
    "level-skills-expert     content-skills-expert     content-languages-expert      content-financial-expert    "
    "level-skills-additional content-skills-additional content-languages-additional  content-financial-additional";
  border: 1px solid $borderColor;
  border-radius: var(--size--xs);
}

@media only screen and (max-width: $big-screen-breakpoint) {
  .learning-path {
    display: grid;
    grid-template-columns: 25rem 1fr;
    grid-template-areas:
      "header-skills              header-skills               "
      "level-skills-beginner      content-skills-beginner     "
      "level-skills-advanced      content-skills-advanced     "
      "level-skills-expert        content-skills-expert       "
      "level-skills-additional    content-skills-additional   "
      "header-languages           header-languages            "
      "level-languages-beginner   content-languages-beginner  "
      "level-languages-advanced   content-languages-advanced  "
      "level-languages-expert     content-languages-expert    "
      "level-languages-additional content-languages-additional"
      "header-financial           header-financial            "
      "level-financial-beginner   content-financial-beginner  "
      "level-financial-advanced   content-financial-advanced  "
      "level-financial-expert     content-financial-expert    "
      "level-financial-additional content-financial-additional";
  }
}
@media only screen and (max-width: $medium-screen-breakpoint) {
  .learning-path {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header-skills"
      "level-skills-beginner"
      "content-skills-beginner"
      "level-skills-advanced"
      "content-skills-advanced"
      "level-skills-expert"
      "content-skills-expert"
      "level-skills-additional"
      "content-skills-additional"
      "header-languages"
      "level-languages-beginner"
      "content-languages-beginner"
      "level-languages-advanced"
      "content-languages-advanced"
      "level-languages-expert"
      "content-languages-expert"
      "level-languages-additional"
      "content-languages-additional"
      "header-financial"
      "level-financial-beginner"
      "content-financial-beginner"
      "level-financial-advanced"
      "content-financial-advanced"
      "level-financial-expert"
      "content-financial-expert"
      "level-financial-additional"
      "content-financial-additional";
  }
}
</style>
