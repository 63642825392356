<template>
  <div class="admin-talent-detection__chart">
    <NoContentChart v-if="showNoContent" />
    <div v-else id="chart" data-cy="talent-chart-hobby-by-age">
      <apexchart :options="chartOptions" :series="chartSeriesData" @click="getSelection" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, watch, onMounted, ref } from "vue";
import { hobbiesColorMap, ageRanges } from "@/views/admin/talent-detection/utils";
import { HobbyCategory } from "@/models/hobbyCategory";
import i18n from "@/i18n";
import NoContentChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionNoContentChart.vue";
import type { AgeChartSelection } from "@/models/talent-detection/chartSelections";
import type { AgeRange } from "@/models/talent-detection/ageRange";
import type { Hobby } from "@/models/hobby";
import type { ServiceSeriesData, ChartSeriesData } from "@/models/talent-detection/seriesData";
import useCompanyTalentDetectionService from "@/services/useCompanyTalentDetectionService";

const { getSeriesDataByAgeAndHobby } = useCompanyTalentDetectionService();
const chartSeriesData = ref<ChartSeriesData[]>([]);
const requested = ref<boolean>(false);
const showNoContent = computed(() => requested.value && !chartSeriesData.value.length);
const xaxisLabels = ageRanges;
const xaxisText = i18n.global.t("entities.my_interests.hobbies.age");
const defaultSeriesColor = hobbiesColorMap[HobbyCategory.Sport];

interface Props {
  hobby?: Hobby;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "clicked", value: AgeChartSelection | undefined) => void>();

const chartOptions = computed(() => {
  const hobbyColor = hobbiesColorMap[props.hobby?.category ?? HobbyCategory.Sport];
  return {
    ...defaultChartOptions,
    ...{
      title: {
        text: props.hobby?.name ?? "",
        ...titleOptions,
      },
      colors: [hobbyColor],
    },
  };
});

watch(
  () => props.hobby,
  () => getHobbySeriesData()
);

onMounted(async () => {
  await getHobbySeriesData();
  requested.value = true;
});

const getHobbySeriesData = async () => {
  try {
    if (props.hobby) {
      chartSeriesData.value = [];
      const hobbySeriesData: ServiceSeriesData = await getSeriesDataByAgeAndHobby(props.hobby.id);
      const noData = hobbySeriesData.data.every((value) => value === 0);
      if (noData) {
        return;
      }
      chartSeriesData.value.push({ name: getSeriesLabel(hobbySeriesData.category), data: [...hobbySeriesData.data] });
    }
  } catch (error) {
    return false;
  }
};

const getSeriesLabel = (category: HobbyCategory) => i18n.global.t(`entities.my_interests.hobbies.category.${category.toLowerCase()}`);

const titleOptions = {
  align: "center",
  style: {
    fontSize: "20px",
    fontWeight: "700",
    fontFamily: undefined,
  },
};

const defaultChartOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  title: {
    ...titleOptions,
  },
  xaxis: {
    type: "category",
    categories: xaxisLabels,
  },
  yaxis: {
    max: 100,
    labels: {
      formatter: function (value: string) {
        return value + "%";
      },
    },
  },
  colors: defaultSeriesColor,
  fill: {
    opacity: 1,
  },
  legend: {
    showForSingleSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    offsetY: 30,
  },
  plotOptions: {
    bar: {
      columnWidth: "100%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    followCursor: true,
    x: {
      formatter: function (val: string) {
        return `${xaxisText} ` + val;
      },
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSelection = (_event: any, _chartContext: any, config: any): void => {
  if (config.seriesIndex < 0 || config.dataPointIndex < 0) {
    return;
  }

  const selectedDataPoint = config.globals.selectedDataPoints[0];
  if (!selectedDataPoint?.length) {
    emit("clicked", undefined);
    return;
  }

  const ageRange = getAgeRangeFromIndex(config.dataPointIndex);

  const selection: AgeChartSelection = {
    ageRange: ageRange,
    hobby: props.hobby,
  };

  emit("clicked", selection);
};

const getAgeRangeFromIndex = (index: number): AgeRange => {
  const hobbyCategories: { [index: number]: AgeRange } = {
    0: { from: undefined, to: 34 },
    1: { from: 35, to: 50 },
    2: { from: 51, to: undefined },
  };

  return hobbyCategories[index];
};
</script>
