<template>
  <div class="about-me">
    <AppNoContentYet v-if="!aboutMeRead" />
    <p v-if="aboutMeRead" class="text" :class="{ 'text--line-clamp-3': truncateText }" v-html="toHtml(aboutMeRead)"></p>
    <AppLinkButton
      v-if="formMode !== 'view'"
      :class="{ 'margin-l--xs': !aboutMeRead }"
      :text="$t('buttons.edit')"
      @click="openModal(panelName)" />

    <AppModal :name="panelName" :disable-key-enter="true">
      <AppForm>
        <AppInputTextArea
          v-model="aboutMe"
          :label="$t('pages.current_experience.form.about.me.fields.name.label')"
          :placeholder="$t('pages.current_experience.form.about.me.fields.name.placeholder')"
          :maxlength="largeTextLength"
          :error="aboutMeError" />
      </AppForm>
    </AppModal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useForm, useField } from "vee-validate";
import i18n from "@/i18n";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppModal from "@/components/modal/AppModal.vue";
import { largeTextLength } from "@/constants/restrictions";
import useModal from "@/components/modal/useModal";
import useMeService from "@/services/useMeService";
import { toHtml } from "@/utils/stringUtils";

import type { FormMode } from "@/models/formMode";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppForm from "@/components/AppForm.vue";
import useFormValidator from "@/services/useFormValidator";
import AppLinkButton from "@/components/AppLinkButton.vue";
import useFormSchema from "@/services/useFormSchema";

const { updateAboutMe } = useMeService();
const { openComponentModal } = useModal();
const { getFormCallbackHandler } = useFormValidator();
const { yupObject, stringSchema } = useFormSchema();

const panelName = "aboutMe";

interface Props {
  input?: string;
  formMode?: FormMode;
  truncateText?: boolean;
}

const props = withDefaults(defineProps<Props>(), { formMode: "edit" });

const resourceSchema = yupObject({
  aboutMe: stringSchema({ maxLength: largeTextLength }),
});
const aboutMeRead = ref<string | undefined>(props.input);

const form = useForm({ validationSchema: resourceSchema });
const { value: aboutMe, errorMessage: aboutMeError } = useField<string | undefined>("aboutMe");

onMounted(() => (aboutMe.value = props.input));

const save = async (): Promise<boolean> => {
  if (!aboutMe.value) {
    return false;
  }
  await updateAboutMe(aboutMe.value);
  aboutMeRead.value = aboutMe.value;
  return true;
};

const close = () => {
  form.resetForm();
  aboutMe.value = aboutMeRead.value;
};

const openModal = (componentId: string): void => {
  openComponentModal(componentId, {
    title: i18n.global.t("pages.current_experience.edit.about.me"),
    confirmText: i18n.global.t("buttons.save"),
    variant: "floating",
    showCancelToClose: true,
    confirmCallback: getFormCallbackHandler(form, save),
    closeCallback: close,
  });
};
</script>

<style scoped lang="scss">
.about-me {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-s);
}
</style>
