import i18n from "@/i18n";
import sanitizeHtml from "sanitize-html";

const urlRegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const toCapitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

export const toHtml = (text: string | undefined, configuration?: { keepOriginalNewLines: boolean }): string => {
  if (!text || isBlank(text)) {
    return "";
  }

  const keepOriginalNewLines = configuration?.keepOriginalNewLines === true;

  text = text.replace(/(?:\r\n|\\n|\\r|\r|\n)+$/g, ""); // Remove last new lines

  if (!keepOriginalNewLines) {
    text = text.replace(/(?:\r\n|\\n|\\r|\r|\n)/g, "<br />"); // Convert new lines to HTML
  }

  const allowedTags = keepOriginalNewLines ? [] : ["br"];
  const sanitizeOptions = {
    allowedTags: allowedTags,
    allowedAttributes: {},
    allowedIframeHostnames: [],
  };
  return sanitizeHtml(text, sanitizeOptions);
};

export const toTitleHtml = (text: string | undefined): string => toHtml(text, { keepOriginalNewLines: true });

export const isBlank = (text: string | undefined): boolean => !text || /^\s*$/.test(text);

export const containsInsensitive = (text: string | undefined, containedText: string): boolean => {
  if (!text || isBlank(text)) {
    return false;
  }
  const normalizedText = text.normalize("NFD").replace(/\p{Diacritic}+/gu, "");
  const normalizedContainedText = containedText.normalize("NFD").replace(/\p{Diacritic}+/gu, "");

  const regExp = new RegExp(normalizedContainedText, "gi");
  return regExp.test(normalizedText);
};

export const compareStrings = (string1: string, string2: string, ascending = true): number => {
  const orderModifier = ascending ? 1 : -1;
  return orderModifier * string1.localeCompare(string2);
};

export const isValidUrl = (text: string): boolean => urlRegExp.test(text);

export const concatWithAnd = (texts?: string[]): string => {
  if (!texts || !Intl.ListFormat) {
    return "";
  }
  const formatter = new Intl.ListFormat(i18n.global.locale.value, { style: "long", type: "conjunction" });
  return formatter.format(texts);
};
