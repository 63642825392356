<template>
  <div class="profile-summary-information">
    <AppProfileAvatar :size="size" :information="employeeInformation" color="alternative" />
    <div class="profile-summary-information__name-position" data-cy="profile-name">
      <span :class="fullNameClass">{{ fullName }}</span>
      <span class="text text--l">{{ profileInformation.currentJobExperience }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import AppProfileAvatar from "@/components/AppProfileAvatar.vue";
import type { ProfileSummaryInformation } from "@/models/profileSummaryInformation";
import type { AvatarInformation } from "@/models/employee/avatarInformation";

interface Props {
  profileInformation: ProfileSummaryInformation;
  size: "s" | "l" | "m";
}
const props = defineProps<Props>();

const fullName = `${props.profileInformation.firstName} ${props.profileInformation.lastName}`;
const employeeInformation: AvatarInformation = { ...props.profileInformation, id: props.profileInformation.employeeId };

const fullNameClass = computed<string>(() => {
  const cssClass = "text text--bold";
  return props.size === "l" ? cssClass + " text--xl" : cssClass + " text--l";
});
</script>

<style lang="scss">
.profile-summary-information {
  display: flex;
  gap: var(--space-m);
  align-items: center;

  &__name-position {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }
}
</style>
