<template>
  <div class="summary">
    <AppProfileSummaryInformation :profile-information="profileSummaryInformation" size="l" />
    <AppSeparator />
    <div class="summary__extra-information">
      <ProfileExtraInformation
        :title="$t('pages.my_profile.previous_experiences.title')"
        url="/app/my-current-job"
        :content="currentJobExperiences" />

      <ProfileExtraInformation :title="$t('pages.my_interests.title')" url="/app/my-interests" :content="currentInterests" />
    </div>
    <GroupsOfEmployee :groups="profileInformation.groups" />
  </div>
</template>

<script setup lang="ts">
import AppSeparator from "@/components/AppSeparator.vue";
import ProfileExtraInformation from "@/views/app/my-profile/components/dashboard/ProfileExtraInformation.vue";
import AppProfileSummaryInformation from "@/views/app/my-profile/components/shared/ProfileSummaryInformation.vue";
import GroupsOfEmployee from "@/views/shared/employee/components/GroupsOfEmployee.vue";
import type { ProfileInformation } from "@/models/profileInformation";
import type { ProfileSummaryInformation } from "@/models/profileSummaryInformation";

interface Props {
  profileInformation: ProfileInformation;
}
const props = defineProps<Props>();

const profileSummaryInformation: ProfileSummaryInformation = {
  employeeId: props.profileInformation.id,
  firstName: props.profileInformation.firstName,
  lastName: props.profileInformation.lastName,
  jobExperiences: props.profileInformation.jobExperiences.map((je) => je.title),
  currentJobExperience: props.profileInformation.currentJobExperience?.title,
  pictureName: props.profileInformation.pictureName,
};
const currentJobExperiences: string = props.profileInformation.jobExperiences.map((je) => je.title).join("; ");
const currentInterests = props.profileInformation.interests.map((int) => int.title).join("; ");
</script>

<style scoped lang="scss">
.summary {
  display: flex;
  gap: var(--space-l);
  flex-direction: column;

  &__extra-information {
    display: flex;
    gap: var(--space-l);
  }
}
</style>
