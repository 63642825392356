<template>
  <Component :is="questionComponent" class="answer" :question="item" :answer-enabled="answerEnabled" @edited="edited" />
</template>

<script setup lang="ts">
import type { Question } from "@/models/question";
import { getQuestionComponentFor } from "@/views/app/ai-assistant/utils/questionHelper";

interface Props {
  item: Question;
  answerEnabled: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const questionComponent = getQuestionComponentFor(props.item);
const edited = (newQuestionState: Question) => emit("edited", newQuestionState);
</script>
<style lang="scss" scoped>
.answer {
  display: flex;
  flex-direction: row;
  gap: var(--space-s);
  align-items: center;
  justify-content: space-between;
}
</style>
