<template>
  <ChatBotGenericChannelInstructions channel-color="rgba(123, 131, 235, 0.07)" channel-name="teams" :logo-url="logoUrl">
    <div class="download-bot">
      <span class="text text--m text--bold">{{ $t("pages.admin.company_settings.general_info.chatbot.teams.download.label") }}</span>
      <AppButton
        data-cy="download-bot"
        size="m"
        variant="primary"
        :text="$t('pages.admin.company_settings.general_info.chatbot.teams.download.text')"
        @onclick="clickHandler" />
    </div>
  </ChatBotGenericChannelInstructions>
</template>
<script lang="ts" setup>
import AppButton from "@/components/AppButton.vue";
import ChatBotGenericChannelInstructions from "@/views/admin/company-settings/components/chatbot/ChatBotGenericChannelInstructions.vue";
import useCompanySettingsService from "@/services/useCompanySettingsService";
import { redirectTo } from "@/utils/redirectUtil";
import logoUrl from "@/assets/images/chatbot/teams.svg";

const { downloadChatBotUrl } = useCompanySettingsService();
const clickHandler = () => redirectTo(downloadChatBotUrl());
</script>
<style lang="scss" scoped>
.download-bot {
  border: 1px solid var(--color-border);
  border-radius: var(--space-xs);
  padding: var(--size-s);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  place-items: center;
  justify-content: space-between;
}
</style>
