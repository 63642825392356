<template>
  <AppPanel :padding-content="false">
    <template #default>
      <div class="padding-l--s">
        <AppTableFilters v-model="filters" />
      </div>
      <AppTable
        :columns="columns"
        :items="employeesInView"
        :paginated="true"
        :detail-item-component="CompanyEmployeesWithGroupsDetail"
        :detail-item-component-props="detailProps" />
    </template>
  </AppPanel>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppTableFilters from "@/components/table/AppTableFilters.vue";
import CompanyEmployeesWithGroupsDetail, {
  type EmployeeGroupDetailCustomProps,
} from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeesWithGroupsDetail.vue";
import type { Column } from "@/components/table/Column";
import useTableHelper from "@/components/table/useTableHelper";
import { type EmployeeWithGroups, EmployeeGroupRole, type EmployeeWithGroupsEmployee } from "@/models/companyEmployeeGroup";
import type { Filter } from "@/components/table/TableFilter";
import {
  filterEmployeesByRole,
  filterEmployeesGroupsByRole,
} from "@/views/admin/company-settings/company-employee-groups/utils/companyEmployeeGroupsUtils";
import { formatNumber } from "@/utils/numberUtils";

interface Props {
  employees: EmployeeWithGroups[];
  searchText?: string;
}
const props: Props = defineProps<Props>();

const { filterByText, getTypeFilter } = useTableHelper();

const employeesFiltered = computed<EmployeeWithGroups[]>(() => filterEmployeesByRole(props.employees, detailProps.value.selectedRole));
const employeesInView = computed<EmployeeWithGroups[]>(() => filterByText(employeesFiltered.value, props.searchText, columns));

const columns: Column<EmployeeWithGroups>[] = [
  {
    id: 1,
    headerText: i18n.global.t("pages.admin.company_settings.employees-with-groups.fields.first-name"),
    cssWidth: "0.5fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "firstName",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("pages.admin.company_settings.employees-with-groups.fields.last-name"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "lastName",
    type: "long-text",
  },
  {
    id: 3,
    headerText: i18n.global.t("pages.admin.company_settings.employees-with-groups.fields.email"),
    cssWidth: "2fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "email",
    type: "long-text",
  },
  {
    id: 4,
    headerText: i18n.global.t("pages.admin.company_settings.employees-with-groups.fields.group-count"),
    cssWidth: "10rem",
    align: "left",
    sortable: true,
    searchable: true,
    type: "long-text",
    formatter: (item: EmployeeWithGroups) => formatNumber(filterEmployeesGroupsByRole(item.groups, detailProps.value.selectedRole).length),
  },
];
const roleFilter = getTypeFilter<EmployeeWithGroupsEmployee>(
  "employeeRole",
  "",
  "pages.admin.company_settings.employee_groups.role",
  Object.keys(EmployeeGroupRole)
);
const filters = ref<Filter[]>([roleFilter]);
const detailProps = computed<EmployeeGroupDetailCustomProps>(() => ({
  selectedRole: filters.value[0].selectedOption as EmployeeGroupRole,
}));
</script>
