import type { EmployeeCourse } from "@/models/course";
import type { CourseRate } from "@/models/courseRate";
import useMeCoursesService from "@/services/useMeCoursesService";

const { getCourseRate } = useMeCoursesService();

interface UseCourseRateRefresherReturn {
  refreshCourseRateData: (courseId: number, courses: EmployeeCourse[]) => Promise<void>;
}

export default (): UseCourseRateRefresherReturn => {
  const refreshCourseRateData = async (courseId: number, courses: EmployeeCourse[]): Promise<void> => {
    const course = courses.find((c) => c.id === courseId);
    if (!course) {
      return;
    }
    const info: CourseRate = await getCourseRate(courseId);
    course.averageRate = info.averageRate;
    course.done = info.done;
  };
  return { refreshCourseRateData };
};
