<template>
  <ProfilePerformanceChart :series-values="examplePerformanceGraphSeriesValues" :category-names="examplePerformanceGraphCategoryNames" />
</template>

<script lang="ts" setup>
import i18n from "@/i18n";

import ProfilePerformanceChart from "@/views/app/my-profile/components/overview/ProfilePerformanceChart.vue";
const examplePerformanceGraphSeriesValues: number[][] = [
  [4, 1, 1, 1, 1, 1, 1, 3, 3, 2, 3, 2],
  [1, 4, 4, 3, 4, 3, 4, 1, 1, 1, 1, 1],
  [4, 3, 1, 4, 2, 1, 4, 2, 1, 3, 4, 2],
];
const examplePerformanceGraphCategoryNames: string[] = [
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.1"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.2"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.3"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.4"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.5"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.6"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.7"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.8"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.9"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.10"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.11"),
  i18n.global.t("pages-my-profile.profile_updating.performance_graph.example.graph.categories.12"),
];
</script>
