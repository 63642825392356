<template>
  <LayoutBase :menu-options="menuOptions" root-url="/app" />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import type { MenuOption } from "@/layout/models/menu-option";
import LayoutBase from "@/layout/shared/LayoutBase.vue";
import { useLayout } from "@/layout/shared/useLayout";
import useAuthenticationService from "@/services/useAuthenticationService";

const { getOption } = useLayout("app");
const { isGroupManager } = useAuthenticationService();
const menuOptions = ref<MenuOption[]>([]);

onMounted(async () => {
  const isUserGroupManager = await isGroupManager();
  menuOptions.value = [
    getOption("my-profile"),
    getOption("ai-assistant"),
    getOption("current-experience", "my-current-job"),
    getOption("ideal-job", "my-ideal-job", "entities.my_ideal_job.title"),
    getOption("my-interests"),
    getOption("learning", "my-learning"),
    getOption("goals-challenges"),
    { ...getOption("group", "my-groups", "pages.my_groups.title"), hide: !isUserGroupManager },
  ];
});
</script>
