<template>
  <layout-page :title="$t('pages.admin.company_settings.title')">
    <AdminCompanySettingsNavigation v-if="company" :company="company" :current="currentTab" @tab-changed="tabChanged" />
    <Component :is="currentComponent" v-if="company" />
  </layout-page>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, type Component } from "vue";
import { useRoute } from "vue-router";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AdminCompanySettingsNavigation, {
  type AllowedSettingsTabs,
} from "@/views/admin/company-settings/components/AdminCompanySettingsNavigation.vue";
import type { Company } from "@/models/company";
import useCompanySettingsService from "@/services/useCompanySettingsService";
import AdminCompanySettingsGeneralInfo from "@/views/admin/company-settings/components/AdminCompanySettingsGeneralInfo.vue";
import AdminCompanyEmployeeGroups from "@/views/admin/company-settings/company-employee-groups/AdminCompanyEmployeeGroups.vue";
import AdminCompanyEmployeesWithGroups from "@/views/admin/company-settings/company-employee-groups/AdminCompanyEmployeesWithGroups.vue";
import AdminCompanySettingsAzureInfo from "@/views/admin/company-settings/components/AdminCompanySettingsAzureInfo.vue";
import AdminRoleUsers from "@/views/admin/company-settings/components/AdminRoleUsers.vue";
import ChatBotInstructions from "@/views/admin/company-settings/components/chatbot/ChatBotInstructions.vue";
import SkillsManagement from "@/views/admin/skills/SkillsManagement.vue";

const components: Map<AllowedSettingsTabs, Component> = new Map<AllowedSettingsTabs, Component>([
  ["GeneralInfo", AdminCompanySettingsGeneralInfo],
  ["CompanyEmployeeGroups", AdminCompanyEmployeeGroups],
  ["AzureInfo", AdminCompanySettingsAzureInfo],
  ["CompanyEmployeesWithGroups", AdminCompanyEmployeesWithGroups],
  ["RolesManagement", AdminRoleUsers],
  ["ChatBotInstructions", ChatBotInstructions],
  ["SkillsManagement", SkillsManagement],
]);

const { getCompany } = useCompanySettingsService();
const { params } = useRoute();

const company = ref<Company>();
const currentTab = ref<AllowedSettingsTabs>("GeneralInfo");
const currentComponent = computed<Component>(() => components.get(currentTab.value) ?? AdminCompanySettingsGeneralInfo);

const tabChanged = (newTab: AllowedSettingsTabs) => (currentTab.value = newTab);

onMounted(async () => {
  currentTab.value =
    params.tabId && components.has(params.tabId as AllowedSettingsTabs) ? (params.tabId as AllowedSettingsTabs) : "GeneralInfo";
  company.value = await getCompany();
});
</script>
