<template>
  <div v-if="hobbies" data-cy="hobbies-editor">
    <div class="hobbies-editor margin-b--m">
      <AppAutocomplete
        :placeholder="$t('entities.my_interests.hobbies.edit-modal.search')"
        :items="autocompleteItems"
        :maxlength="15"
        :self-filtering="true"
        @click="handleHobbySelected" />
    </div>
    <AppPanel :dark="true" :border="false">
      <div class="hobbies-editor--new-hobby">
        <span class="text text--s text--bold">{{ $t("entities.my_interests.hobbies.edit-modal.needNew") }}</span>
        <AppButton
          data-cy="create-new-hobby-button"
          size="m"
          :text="$t('entities.my_interests.hobbies.edit-modal.newButton')"
          @click="creating = true" />
      </div>
    </AppPanel>
    <div v-if="checkedHobbies" class="margin-t--m">
      <AppTag
        v-for="hobby of checkedHobbies"
        :key="hobby.name"
        class="margin-b--xs margin-r--xs"
        data-cy="hobbies-editor-tag"
        :main-text="hobby.name"
        type="skill"
        :deletable="true"
        @delete-tag="deleteHobby" />
    </div>
    <AppHobbiesCreator v-if="creating" @closed="creating = false" @hobby-created="handleHobbyCreated" />
  </div>
</template>

<script lang="ts" setup>
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppButton from "@/components/AppButton.vue";
import AppHobbiesCreator from "@/components/AppHobbiesCreator.vue";
import AppTag from "@/components/AppTag.vue";
import AppPanel from "@/components/AppPanel.vue";
import type { AutocompleteItem } from "@/models/autocompleteItem";
import type { Hobby } from "@/models/hobby";
import { computed, onMounted, ref } from "vue";
import useHobbiesService from "@/services/useHobbiesService";

const { getHobbies } = useHobbiesService();

interface Props {
  modelValue: Hobby[];
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", value: Hobby[]) => void>();

const hobbies = ref<Hobby[]>([]);
const creating = ref<boolean>(false);

const autocompleteItems = computed<AutocompleteItem[]>(() => {
  return !hobbies.value
    ? []
    : hobbies.value
        .filter((h) => !h.checked)
        .sort((h1: Hobby, h2: Hobby) => h1.name.localeCompare(h2.name))
        .map((h) => ({ id: h.id, text: h.name }));
});

const checkedHobbies = computed<Hobby[]>(() => {
  return hobbies.value.filter((h) => h.checked).sort((h1: Hobby, h2: Hobby) => h1.name.localeCompare(h2.name));
});

const handleHobbySelected = (item: AutocompleteItem): void => {
  findAndApplyFunction(searchHobbyById(item), checkHobby);
  emit("update:modelValue", checkedHobbies.value);
};

const deleteHobby = (hobbyName: string): void => {
  findAndApplyFunction(searchHobbyByName(hobbyName), uncheckHobby);
  emit("update:modelValue", checkedHobbies.value);
};

const initialize = async () => {
  hobbies.value = (await getHobbies()).map((h) => ({ ...h, checked: props.modelValue.some((i) => i.id === h.id) }));
};

onMounted(() => initialize());

const handleHobbyCreated = async (newHobbyName?: string): Promise<void> => {
  const previousSelectedHobbies = [...checkedHobbies.value];
  await initialize();
  findAndApplyFunction((hobby) => hobby.name === newHobbyName, checkHobby);
  previousSelectedHobbies.forEach((h) => {
    findAndApplyFunction(searchHobbyById(h), checkHobby);
  });
  emit("update:modelValue", checkedHobbies.value);
};

const findAndApplyFunction = (searchFunction: (hobby: Hobby) => boolean, applyFunction: (hobby: Hobby) => void): void => {
  const skillFound = hobbies.value?.find(searchFunction);
  if (skillFound) {
    applyFunction(skillFound);
  }
};

const searchHobbyById = (hobby1: Hobby | AutocompleteItem): ((hobby: Hobby) => boolean) => {
  return (hobby2: Hobby) => hobby1.id === hobby2.id;
};

const searchHobbyByName = (hobbyName: string): ((hobby: Hobby) => boolean) => {
  return (hobby2: Hobby) => hobby2.name === hobbyName;
};

const checkHobby = (hobbyToBeChecked: Hobby) => (hobbyToBeChecked.checked = true);
const uncheckHobby = (hobbyToBeUnchecked: Hobby) => (hobbyToBeUnchecked.checked = false);
</script>

<style scoped lang="scss">
.hobbies-editor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--new-hobby {
    display: flex;
    gap: var(--space-l);
    align-items: center;
    justify-content: space-between;
  }
}
</style>
