<template>
  <AppTable
    v-if="skillsInView.length > 0"
    :columns="columns"
    :items="skillsInView"
    :paginated="true"
    :filters="filters"
    :order="defaultOrder" />
  <div v-else-if="skillsInView.length === 0" class="padding--m">
    <p>{{ $t("pages.admin.skill_management.grid.no_results") }}</p>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import type { Skill } from "@/models/skill";
import { SkillStatus } from "@/models/skillStatus";
import { SkillType } from "@/models/skillType";
import type { Filter } from "@/components/table/TableFilter";
import type { Order } from "@/components/table/Order";
import useModal from "@/components/modal/useModal";
import useSkillService from "@/services/useSkillService";
import useTableHelper from "@/components/table/useTableHelper";

interface Props {
  skills: Skill[];
  searchText: string;
}
const props: Props = defineProps<Props>();

interface Emits {
  (e: "deleted"): void;
  (e: "edition-started", skill: Skill): void;
}
const emit = defineEmits<Emits>();

const { getTypeFilter, filterByText } = useTableHelper();
const { openTextModal } = useModal();
const { deleteSkill } = useSkillService();

const STATUS_COLUMN = 4;
const defaultOrder: Order = { id: STATUS_COLUMN, direction: "desc" };

const skillsInView = computed<Skill[]>(() => filterByText(props.skills, props.searchText, columns));

const typeFilter = getTypeFilter<Skill>(
  "type",
  i18n.global.t("pages.admin.skills.grid.type"),
  "pages.admin.skill_management.filters.type",
  Object.keys(SkillType)
);
const statusFilter = getTypeFilter<Skill>(
  "status",
  i18n.global.t("pages.admin.skills.grid.status"),
  "pages.admin.skill_management.filters.status",
  Object.keys(SkillStatus)
);

const filters: Filter<Skill>[] = [statusFilter, typeFilter];
const columns: Column<Skill>[] = [
  {
    id: 1,
    headerText: i18n.global.t("pages.admin.skills.grid.name"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "name",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("pages.admin.skills.grid.description"),
    cssWidth: "minmax(6rem, 2fr)",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "description",
    type: "long-text",
  },
  {
    id: 3,
    headerText: i18n.global.t("pages.admin.skills.grid.type"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "type",
    type: "text-with-ellipsis",
    formatter: (skill: Skill) => i18n.global.t(`pages.admin.skill_management.filters.type.${skill.type}`),
  },
  {
    id: STATUS_COLUMN,
    headerText: i18n.global.t("pages.admin.skills.grid.status"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "status",
    type: "text-with-ellipsis",
    formatter: (skill: Skill) => i18n.global.t(`pages.admin.skill_management.skill_status.${skill.status}`),
  },
  {
    id: 5,
    headerText: i18n.global.t("pages.admin.skill_management.skill_requester_name"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: true,
    searchable: true,
    type: "text-with-ellipsis",
    formatter: (skill: Skill) => (skill.skillRequester ? `${skill.skillRequester.firstName} ${skill.skillRequester.lastName}` : ""),
  },
  {
    id: 6,
    headerText: i18n.global.t("pages.admin.skill_management.skill_moderator_name"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: true,
    searchable: true,
    type: "text-with-ellipsis",
    formatter: (skill: Skill) => (skill.skillModerator ? `${skill.skillModerator.firstName} ${skill.skillModerator.lastName}` : ""),
  },
  {
    id: 7,
    cssWidth: "minmax(6rem, 1fr)",
    sortable: false,
    searchable: false,
    align: "right",
    type: "actions",
    actions: [
      {
        name: "Moderate",
        icon: "moderate",
        title: i18n.global.t("pages.admin.skill_management.moderate"),
        condition: (skill: Skill) => skill.status === SkillStatus.Pending,
        callback: (skill: Skill) => emit("edition-started", skill),
      },
      {
        name: "Edit",
        icon: "pencil",
        title: i18n.global.t("buttons.edit"),
        condition: (skill: Skill): boolean => skill.status === SkillStatus.Approved,
        callback: (skill: Skill) => emit("edition-started", skill),
      },
      {
        name: "Delete",
        icon: "delete",
        title: i18n.global.t("pages.admin.skill_management.delete"),
        callback: (skill: Skill): void => {
          openTextModal({
            title: i18n.global.t("pages.admin.skill_management.delete"),
            contentText: i18n.global.t("pages.admin.skill_management.confirm_to_delete"),
            showCancelToClose: true,
            confirmCallback: async () => {
              await deleteSkill(skill.id);
              emit("deleted");
            },
          });
        },
      },
    ],
  },
];
</script>
