import type { Ref } from "vue";

export const DefaultModalName = "default";
export type ModalVariant = "small" | "large" | "floating" | "adaptive";
export type ModalCallback = () => Promise<boolean | void> | void | boolean;

export interface ModalBase {
  title: string | Ref<string>;
  icon?: { content: string; style: string };
  cancelText?: string;
  confirmText?: string;
  showCancelToClose?: boolean;
  variant?: ModalVariant;
  closeCallback?: ModalCallback;
  cancelCallback?: ModalCallback;
  confirmCallback?: ModalCallback;
  onClosed?: () => void;
}

export type ComponentModal = ModalBase;
export type TextModal = ModalBase & { contentText: string };
