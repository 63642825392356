import { ref, type Ref } from "vue";
import type { ModalBase } from "@/components/modal/models";

interface UseManageModalReturn {
  isOpen: (name: string) => boolean;
  getConfig: (name: string) => ModalBase | undefined;
  close: (name: string) => void;
  openModal: (name: string, config: ModalBase) => void;
  isCurrentModalActive: (name: string) => boolean;
}
type ModalItem = {
  name: string;
  config: ModalBase;
};

const state: Ref<ModalItem[]> = ref<ModalItem[]>([]);

export default (): UseManageModalReturn => {
  const isOpen = (name: string): boolean => !!getConfig(name);
  const getConfig = (name: string): ModalBase | undefined => state.value.find((item) => item.name === name)?.config;
  const close = (name: string): void => {
    state.value = state.value.filter((item) => item.name !== name);
  };
  const isCurrentModalActive = (name: string) => state.value.length > 0 && state.value[state.value.length - 1].name === name;

  const openModal = (name: string, config: ModalBase): void => {
    if (isOpen(name)) {
      return;
    }
    state.value.push({ name, config });
  };

  return { isOpen, close, getConfig, openModal, isCurrentModalActive };
};
