<template>
  <div data-cy="multiple-selection-preview">
    <h2 class="margin-b--m text--l">{{ item.title }}</h2>
    <AppCheckbox v-for="option in item.options" :key="option.optionId" v-model="sampleOption" :text="option.title" class="margin-b--xs" />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";

import type { Question } from "@/models/question";
import AppCheckbox from "@/components/AppCheckbox.vue";

interface Props {
  item: Question;
}

defineProps<Props>();

const sampleOption = ref<boolean>(false);
</script>
