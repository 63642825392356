<template>
  <LayoutPage v-if="profileInfo">
    <Teleport to="#header-button-container">
      <AppIconButton :text="$t('shared.back')" size="l" icon="arrow-back" @onclick="back" />
    </Teleport>
    <AppProfileSummaryInformation v-if="profileSummaryInformation" size="m" :profile-information="profileSummaryInformation" />
    <div class="margin-t--s margin-b--xl">
      <EmployeeProfileNavigation :current="currentView" @tab-click="currentView = $event" />
    </div>
    <EmployeeCurrentExperience v-if="currentView === 'CurrentExperience'" :profile-information="profileInfo" />
    <EmployeeMyIdealJob v-if="currentView === 'MyIdealJob'" :employee-id="employeeId" />
    <EmployeeInterests v-if="currentView === 'Interests'" :employee-id="employeeId" />
    <EmployeeAIAssistant v-if="currentView === 'AIAssistant'" :employee-id="employeeId" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import router from "@/router/router";
import { useRoute, type RouteLocationRaw } from "vue-router";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppIconButton from "@/components/AppIconButton.vue";
import EmployeeCurrentExperience from "@/views/shared/employee/components/EmployeeCurrentExperience.vue";
import EmployeeMyIdealJob from "@/views/shared/employee/components/EmployeeMyIdealJob.vue";
import EmployeeInterests from "@/views/shared/employee/components/EmployeeInterests.vue";
import EmployeeAIAssistant from "@/views/shared/employee/components/EmployeeAIAssistant.vue";
import EmployeeProfileNavigation, { type EmployeeProfileTabIds } from "@/views/shared/employee/components/EmployeeProfileNavigation.vue";
import AppProfileSummaryInformation from "@/views/app/my-profile/components/shared/ProfileSummaryInformation.vue";
import useEmployeeService from "@/services/useEmployeeService";
import type { ProfileSummaryInformation } from "@/models/profileSummaryInformation";
import type { ProfileInformation } from "@/models/profileInformation";

interface Props {
  backRoute?: string;
  backUrl?: string;
}
const props = defineProps<Props>();

const route = useRoute();
const { getProfileInformation } = useEmployeeService();

const employeeId = ref<number>(parseInt(route.params.employeeId as string));
const profileInfo = ref<ProfileInformation>();
const profileSummaryInformation = ref<ProfileSummaryInformation>();
const currentView = ref<EmployeeProfileTabIds>("CurrentExperience");

const back = async () => {
  const { backUrl = props.backUrl, backRoute = props.backRoute ?? "default-app", ...params } = route.query;
  const targetRoute: RouteLocationRaw = backUrl ? { path: backUrl as string } : { name: backRoute as string };

  targetRoute.query = params;
  await router.push(targetRoute);
};

onMounted(async () => {
  profileInfo.value = await getProfileInformation(employeeId.value);

  profileSummaryInformation.value = {
    employeeId: profileInfo.value.id,
    firstName: profileInfo.value.firstName,
    lastName: profileInfo.value.lastName,
    currentJobExperience: profileInfo.value.currentJobExperience?.title,
    pictureName: profileInfo.value.pictureName,
  };
});
</script>
