<template>
  <GuestPage>
    <div class="user-unauthorized">
      <AppPanel>
        <div class="panel">
          <div><img class="panel__image" src="@/assets/images/logo.svg" alt="" /></div>
          <div>
            <h1 class="text text--xxl text--bold" data-cy="title">{{ $t("pages.accounts.user_unauthorized.title") }}</h1>
            <p class="margin-v--m">{{ $t("pages.accounts.user_unauthorized.description") }}</p>
          </div>
          <div class="panel__actions">
            <AppButton :text="$t('pages.accounts.user_unauthorized.logout')" data-cy="redirectToLogOut" @onclick="redirectToLogOut()" />
          </div>
        </div>
      </AppPanel>
    </div>
  </GuestPage>
</template>

<script setup lang="ts">
import AppPanel from "@/components/AppPanel.vue";
import AppButton from "@/components/AppButton.vue";
import GuestPage from "@/layout/guest/GuestPage.vue";
import useAuthenticationService from "@/services/useAuthenticationService";

const { redirectToLogOut } = useAuthenticationService();
</script>

<style scoped lang="scss">
.user-unauthorized {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.panel {
  display: grid;
  grid-column-gap: var(--space-l);
  grid-template-columns: 100px 1fr 200px;

  &__image {
    width: 100px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
