import type { Column } from "@/components/table/Column";
import type { TableItem } from "@/components/table/TableItem";
import { formatNumber } from "@/utils/numberUtils";

export const columnValueExtractor = (column: Column, item: TableItem): string => {
  if (column.formatter) {
    return column.formatter(item);
  }
  if (!column.fieldName) {
    return "";
  }
  const value = item[column.fieldName as keyof typeof item] as unknown;
  if (value instanceof Number || typeof value === "number") {
    return formatNumber(Number(value));
  }
  if (value instanceof Date) {
    return value.toLocaleDateString();
  }
  return value as string;
};
