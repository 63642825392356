<template>
  <AppCard :title="$t('pages.my_profile.dashboards.certificates_courses.label')">
    <div v-if="educations.length > 0" class="container">
      <div v-for="education in educations" :key="education.id" data-cy="certificate-and-courses-list">
        <div class="learning">
          <div class="learning__icon icon" :class="getIconClass(education)"></div>
          <div class="text text--bold learning__title">{{ education.title }}</div>
          <AppTag :main-text="getTagText(education)" type="presence" />
        </div>
        <AppSeparator class="margin-v--s" />
      </div>
    </div>
  </AppCard>
</template>
<script lang="ts" setup>
import AppCard from "@/components/AppCard.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppTag from "@/components/AppTag.vue";
import i18n from "@/i18n";
import type { Education } from "@/models/education";
import { EducationMode } from "@/models/educationMode";
import useMeEducationService from "@/services/useMeEducationService";
import { onMounted, ref, type Ref } from "vue";

const { getEducations } = useMeEducationService();
const educations: Ref<Education[]> = ref<Education[]>([]);

const getIconClass = (education: Education) => ({
  "icon-verified": education.mode === EducationMode.Online,
  "icon-learning": education.mode === EducationMode.Presence,
});

const getTagText = (education: Education): string =>
  i18n.global.t(`pages.my_profile.dashboards.certificates_courses.type_learning.${education.mode.toLocaleLowerCase()}.label`);

onMounted(async () => {
  await retrieveEducations();
});

const retrieveEducations = async (): Promise<void> => {
  educations.value = await getEducations(5);
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.learning {
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0 var(--space-m);
  gap: 1rem;

  &__icon {
    font-size: var(--size-m);
    color: var(--color-body-tertiary);
  }

  &__title {
    flex-grow: 1;
  }
}
</style>
