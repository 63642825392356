<template>
  <label class="app-input-radio">
    <input type="radio" class="app-input-radio__check" :checked="checked" :name="name" @click="click" />
    <span class="app-input-radio__text text" :title="text">{{ text }}</span>
  </label>
</template>

<script setup lang="ts">
interface Props {
  value: number | string;
  checked: boolean;
  text: string;
  name: string;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "click", value: number | string) => void>();

const click = () => emit("click", props.value);
</script>

<style scoped lang="scss">
.app-input-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-xs);

  &__check {
    margin: 0 var(--space-xxs);
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
}
</style>
