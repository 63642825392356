<template>
  <div class="question_kpi" data-cy="question_kpi">
    <AppCard :title="kpiInfo.title" :center-content="true" :one-line-title="true" :elegant="true" :space-for-icon="true">
      <AppIconOnlyButton
        class="question_kpi__remover"
        size="xl"
        :title="$t('pages.admin.ai_assistant.questions.grid.remove__from_dashboard')"
        icon="close"
        @click="remove" />
      <div class="question_kpi__content">
        <QuestionKpiData v-if="kpiInfo.answeredCount > 0" :kpi-info="kpiInfo" />
        <div v-else class="question_kpi__content">
          <AppNoContentYet data-cy="app-card-no-content" :text="$t('pages.admin.ai_assistant.questions.questions_with_no_answers')" />
        </div>
      </div>
    </AppCard>
  </div>
</template>
<script lang="ts" setup>
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import AppCard from "@/components/AppCard.vue";
import type { QuestionKpiInfo } from "@/models/questionKpi";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import QuestionKpiData from "@/views/admin/ai-assistant/components/kpis/QuestionKpiData.vue";

interface Props {
  kpiInfo: QuestionKpiInfo;
}
defineProps<Props>();

const emit = defineEmits<(e: "remove") => void>();

const remove = () => emit("remove");
</script>
<style scoped lang="scss">
.question_kpi {
  position: relative;

  &__remover {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: center;
    height: 240px;
  }
}
</style>
