<template>
  <form class="app-form" @submit.prevent>
    <slot></slot>
  </form>
</template>

<style scoped lang="scss">
.app-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &--shrink {
    height: auto;
  }
}
</style>
