<template>
  <AppTab :tabs="tabs" :tab-id="$t('pages.learning.title')" />
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import router from "@/router/router";
import AppTab, { type Tab } from "@/components/AppTab.vue";

interface Props {
  current: "Learning" | "MyLearningPath" | "MyTraining";
}
const props = defineProps<Props>();

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages.learning.learning.tab"),
    action: () => router.push({ name: "my-learning-learning" }),
    isChecked: props.current === "Learning",
    dataCy: "nav-learning",
  },
  {
    text: i18n.global.t("pages.learning.my_learning_path.tab"),
    action: () => router.push({ name: "my-learning-path" }),
    isChecked: props.current === "MyLearningPath",
    dataCy: "nav-my-learning-path",
  },
  {
    text: i18n.global.t("pages.learning.my_training.tab"),
    action: () => router.push({ name: "my-learning-training" }),
    isChecked: props.current === "MyTraining",
    dataCy: "nav-my-training",
  },
];
</script>
