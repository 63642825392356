import i18n from "@/i18n";
import useHttpClient from "@/services/shared/useHttpClient";
import type { User } from "@/models/user";
import { STORES, useStorageService } from "@/services/infrastructure/storageService";
import { UserRole } from "@/models/userRole";
import { logOutUrl } from "@/utils/urlHelper";
import { redirectTo } from "@/utils/redirectUtil";

const BASE_URL = "/api/accounts";
const { get } = useHttpClient();
const { clearKey, store } = useStorageService<string>(STORES.language_key);

interface UseAuthenticationServiceReturn {
  getUser: () => Promise<User | undefined>;
  cleanUser: () => void;
  redirectToLogOut: () => void;
  isAdministrator: () => Promise<boolean>;
  isGroupManager: () => Promise<boolean>;
}

const setLocale = (user: User) => {
  const userLocale = user.language.split("-")[0].toLocaleLowerCase();
  i18n.global.locale.value = userLocale;
  store(userLocale);
};
let isAuthenticated = false;
let currentUser: User | undefined;

export default (): UseAuthenticationServiceReturn => {
  const getUser = async (): Promise<User | undefined> => {
    if (currentUser && isAuthenticated) {
      return currentUser;
    }

    const user: User = await get(`${BASE_URL}/user`);

    if (user) {
      isAuthenticated = true;
      currentUser = user;
      setLocale(user);
      return currentUser;
    }

    isAuthenticated = false;
    currentUser = undefined;
    clearKey();
  };
  const redirectToLogOut = (): void => {
    clearKey();
    redirectTo(logOutUrl);
  };
  const checkRole = async (role: UserRole): Promise<boolean> => {
    const user = await getUser();
    return !!user && user.role === role;
  };
  const cleanUser = (): void => {
    currentUser = undefined;
    isAuthenticated = false;
  };
  const isAdministrator = async (): Promise<boolean> => checkRole(UserRole.Administrator);
  const isGroupManager = async (): Promise<boolean> => {
    const user = await getUser();
    return !!user && user.isGroupManager;
  };

  return { getUser, redirectToLogOut, isAdministrator, isGroupManager, cleanUser };
};
