<template>
  <div>
    <h3 class="margin-b--m">{{ $t("pages.admin.company_goals.detail_goals.team_skills.title") }}</h3>
    <div v-if="goal" class="advice">
      <PercentageChart
        :percentage="goal.percentageTotal"
        :height="180"
        :width="180"
        :color="getStyleValue('--color-company-goals-skills-main-chart')" />
      <p class="advice__content text--s">{{ $t("pages.admin.company_goals.detail_goals.team_skills.skills.description") }}</p>
    </div>
    <div v-if="goal" class="skills">
      <h3 class="margin-v--m">{{ $t("pages.admin.company_goals.detail_goals.team_skills.skills") }}</h3>
      <div class="grid--three-columns@large grid--two-columns@big grid--one-columns@medium">
        <div v-for="(percentageSkill, index) in goal.skillsPercentages" :key="index" class="skills__item">
          <PercentageChart shape="fullSmall" :percentage="percentageSkill.value" />
          <div class="text text--bold text--xs">{{ percentageSkill.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import PercentageChart from "@/components/AppPercentageChart.vue";
import { getStyleValue } from "@/utils/styleUtils";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";

const { getCompanyGoal } = useCompanyGoalsService();

const goal = ref<CompanyGoalWithEmployees>();

interface Props {
  goalId: number;
}
const props = defineProps<Props>();

onMounted(async () => {
  goal.value = await getCompanyGoal(props.goalId);
});
</script>

<style scoped lang="scss">
@import "@/styles/utils/responsive";

.advice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $big-screen-breakpoint) {
    flex-direction: column;
  }

  &__content {
    background-color: var(--color-neutral-200);
    padding: var(--space-s);
    border-radius: var(--size-xs);
  }
}

.skills {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
