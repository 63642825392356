<template>
  <label class="app-input-generic">
    <span v-if="label" class="text text--secondary">{{ label }}</span>
    <slot></slot>
    <AppInputError v-if="shouldShowError" :error="error" />
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppInputError from "@/components/AppInputError.vue";

interface Props {
  label?: string;
  error?: string;
  omitErrorIfNoError?: boolean;
}
const props = defineProps<Props>();

const shouldShowError = computed(() => !props.omitErrorIfNoError || !!props.error);
</script>

<style scoped lang="scss">
.app-input-generic {
  display: flex;
  flex-direction: column;

  :deep(.input) {
    padding: 9px 9px;
    margin: 10px 0 4px 0;
    border: 1px solid var(--color-input-border);

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--color-input-border--focus);
    }

    &:disabled {
      border: none;
    }
  }
}
</style>
