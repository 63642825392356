<template>
  <label class="app-toggle">
    <div class="toggle" :class="{ 'toggle--checked': modelValue }"></div>
    <span class="text" data-cy="label">{{ text }}</span>
    <input type="checkbox" :checked="modelValue" @change="change" />
  </label>
</template>

<script setup lang="ts">
interface Props {
  modelValue?: boolean;
  text: string;
}
withDefaults(defineProps<Props>(), { modelValue: false });

const emit = defineEmits<(e: "update:modelValue", checked: boolean) => void>();

const change = (event: Event) => {
  const checkbox: HTMLInputElement = event.target as HTMLInputElement;
  emit("update:modelValue", checkbox.checked);
};
</script>

<style scoped lang="scss">
.app-toggle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: fit-content;

  input {
    display: none;
  }
}

.toggle {
  --circle-size: 20px;
  position: relative;
  flex-shrink: 38;
  min-width: 38px;
  height: var(--size-s);
  text-align: right;
  background-color: var(--color-toggle-background);
  border-radius: 10px;
  display: flex;
  place-items: center;
  transition: background-color ease 0.3s;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: var(--color-toggle-label-background);
    border-color: #afafaf;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: left 0.3s, background-color 0.3s;
  }
  &--checked {
    background-color: var(--color-toggle-background--checked);

    &:before {
      left: calc(100% - var(--circle-size));
      background-color: var(--color-toggle-label-background--checked);
    }
  }
}
</style>
