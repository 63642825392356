import AdminCompanySettingsOverview from "@/views/it-manager/company-settings/ItManagerCompanySettingsOverview.vue";
import ItManagerLayout from "@/layout/it-manager/ItManagerLayout.vue";

export default [
  {
    path: "/it-manager",
    component: ItManagerLayout,
    children: [
      {
        path: "",
        name: "default-it-manager",
        redirect: "/it-manager/company-settings",
      },
      {
        path: "company-settings",
        name: "company-settings-it",
        component: AdminCompanySettingsOverview,
      },
    ],
    meta: {
      requiresAuth: true,
      requiresItManager: true,
      isAdministratorArea: true,
    },
  },
];
