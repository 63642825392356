<template>
  <AppPanel v-if="groupsInView.length > 0" :padding-content="false">
    <template #default>
      <AppTable :columns="columns" :items="groupsInView" :paginated="true" />
    </template>
  </AppPanel>
  <div v-else class="padding--m">
    <p>{{ $t("pages.admin.company_settings.employee_groups.no_results") }}</p>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import AppPanel from "@/components/AppPanel.vue";
import type { Column } from "@/components/table/Column";
import useTableHelper from "@/components/table/useTableHelper";
import type { CompanyEmployeeGroupInfo } from "@/models/companyEmployeeGroup";

interface Props {
  groups: CompanyEmployeeGroupInfo[];
  searchText?: string;
}
const props: Props = defineProps<Props>();

interface Emits {
  (e: "delete", group: CompanyEmployeeGroupInfo): void;
  (e: "edit", group: CompanyEmployeeGroupInfo): void;
  (e: "manage", group: CompanyEmployeeGroupInfo): void;
}
const emit = defineEmits<Emits>();

const { filterByText } = useTableHelper();

const groupsInView = computed<CompanyEmployeeGroupInfo[]>(() => filterByText(props.groups, props.searchText, columns));

const columns: Column<CompanyEmployeeGroupInfo>[] = [
  {
    id: 1,
    headerText: i18n.global.t("pages.admin.company_settings.employee_groups.fields.name"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "name",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("pages.admin.company_settings.employee_groups.fields.courses"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    fieldName: "courseCount",
    type: "long-text",
    formatter: (group: CompanyEmployeeGroupInfo) =>
      i18n.global.t("pages.admin.company_settings.employee_groups.fields.course-count", { n: group.courseCount }),
  },
  {
    id: 3,
    headerText: i18n.global.t("pages.admin.company_settings.employee_groups.fields.people"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    fieldName: "employeeCount",
    type: "long-text",
    formatter: (group: CompanyEmployeeGroupInfo) =>
      i18n.global.t("pages.admin.company_settings.employee_groups.fields.employee-count", { n: group.employeeCount }),
  },
  {
    id: 4,
    cssWidth: "7rem",
    sortable: false,
    searchable: false,
    align: "right",
    type: "actions",
    actions: [
      {
        name: "Edit",
        icon: "pencil",
        title: i18n.global.t("buttons.edit"),
        callback: (group: CompanyEmployeeGroupInfo) => emit("edit", group),
      },
      {
        name: "Manage",
        icon: "eye",
        title: i18n.global.t("pages.admin.company_settings.employee_groups.editor.grid.actions.manage"),
        callback: (group: CompanyEmployeeGroupInfo) => emit("manage", group),
      },
      {
        name: "Delete",
        icon: "delete",
        title: i18n.global.t("buttons.delete"),
        callback: (group: CompanyEmployeeGroupInfo) => emit("delete", group),
      },
    ],
  },
];
</script>
