<template>
  <AppInputGeneric :label="label" :error="error" :class="{ 'app-input-textarea--fixed': !rows }">
    <textarea
      v-app-focus="autofocus"
      :placeholder="placeholder"
      class="input text"
      :value="modelValue"
      :maxlength="maxlength"
      :rows="rows"
      @keyup="change"></textarea>
  </AppInputGeneric>
</template>
<script setup lang="ts">
import AppInputGeneric from "@/components/AppInputGeneric.vue";

interface Props {
  modelValue?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  maxlength?: number;
  autofocus?: boolean;
  rows?: number;
}
withDefaults(defineProps<Props>(), {
  placeholder: "",
  maxlength: 0,
  rows: 0,
});

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();

const change = (event: Event): void => {
  const input: HTMLInputElement = event.target as HTMLInputElement;
  emit("update:modelValue", input.value);
};
</script>

<style scoped lang="scss">
.app-input-textarea--fixed {
  max-height: 350px;
  height: 100%;
}
.input {
  resize: none;
  height: 100%;
}
</style>
