<template>
  <div class="table-filter">
    <span v-if="showTitle" class="text text--bold table-filter__title">{{ filter.title }}</span>
    <AppRadioButtonList
      v-model="optionSelected"
      :options="options"
      :in-row="true"
      data-cy="filter-options"
      @update:model-value="changeSelected" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import AppRadioButtonList, { type RadioButtonOption } from "@/components/AppRadioButtonList.vue";
import type { Filter } from "@/components/table/TableFilter";

interface Props {
  filter: Filter;
  showTitle?: boolean;
}
const props = withDefaults(defineProps<Props>(), { showTitle: true });

const emit = defineEmits<(e: "changed", newValue: string) => void>();

const optionSelected = ref<RadioButtonOption | undefined>();

const setOptionSelected = () => {
  const optionIndex = props.filter.filterOptions.findIndex((o) => o.value === props.filter.selectedOption);
  optionSelected.value = {
    optionId: optionIndex,
    title: props.filter.filterOptions[optionIndex].label,
  };
};
const options = computed<RadioButtonOption[]>(() =>
  props.filter.filterOptions.map((option, index) => ({ optionId: index, title: option.label }))
);

const changeSelected = (option?: RadioButtonOption): void => {
  const newValue: string = props.filter.filterOptions[option?.optionId as number].value;
  emit("changed", newValue);
};

onMounted(setOptionSelected);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.table-filter {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.2rem;
  width: auto;
  place-items: center;
  @media only screen and (max-width: $medium-screen-breakpoint) {
    display: block;
  }
}
</style>
