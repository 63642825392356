<template>
  <div class="interests-editor">
    <AppMyInterestsInfoItem v-if="formMode === 'edit' || formMode === 'view'" :title="titleRead" :description="descriptionRead" />

    <AppLinkButton
      v-if="formMode === 'create'"
      data-cy="create-interest"
      :text="` + ${$t('pages.my_interests.form.interests.create')}`"
      @click="openPanel()" />
    <div v-if="formMode === 'edit'" class="interests-editor__actions">
      <AppLinkButton :text="$t('buttons.edit')" data-cy="edit-interest" @click="setOpenPanel()" />
      <AppLinkButton :bold="false" data-cy="delete-interest" :text="$t('buttons.delete')" @click="remove()" />
    </div>

    <AppModal :name="panelName" :disable-key-enter="true">
      <AppForm>
        <AppInputText
          v-model="title"
          data-cy="title"
          :error="titleError"
          :label="$t('pages.my_interests.form.interests.fields.title.label')"
          :placeholder="$t('pages.my_interests.form.interests.fields.title.placeholder')"
          :maxlength="mediumTextLength" />
        <AppInputTextArea
          v-model="description"
          data-cy="description"
          :error="descriptionError"
          :label="$t('pages.my_interests.form.interests.fields.description.label')"
          :placeholder="$t('pages.my_interests.form.interests.fields.description.placeholder')"
          :maxlength="xLargeTextLength" />
      </AppForm>
    </AppModal>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n";
import { useField, useForm } from "vee-validate";
import AppInputText from "@/components/AppInputText.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppModal from "@/components/modal/AppModal.vue";
import AppForm from "@/components/AppForm.vue";
import AppMyInterestsInfoItem from "@/views/shared/my-interests/AppMyInterestsInfoItem.vue";
import { getRandomId } from "@/utils/randomUtils";
import { mediumTextLength, xLargeTextLength } from "@/constants/restrictions";
import type { FormMode } from "@/models/formMode";
import type { Interest } from "@/models/interest";
import useModal from "@/components/modal/useModal";
import useFormValidator from "@/services/useFormValidator";
import useFormSchema from "@/services/useFormSchema";
import useMeInterestsService from "@/services/useMeInterestsService";

const { createInterest, updateInterest, deleteInterest } = useMeInterestsService();
const { openTextModal, openComponentModal } = useModal();
const { getFormCallbackHandler } = useFormValidator();
const { yupObject, stringSchema } = useFormSchema();

interface Props {
  input?: Interest;
  formMode?: FormMode;
}

const props = withDefaults(defineProps<Props>(), { formMode: "edit" });

const emit = defineEmits<{
  (e: "created", item: Interest): void;
  (e: "deleted", item: Interest): void;
  (e: "updated", item: Interest): void;
}>();

const titleRead = ref<string | undefined>(props.input?.title);
const descriptionRead = ref<string | undefined>(props.input?.description);
const panelName: string = getRandomId();

const resourceSchema = yupObject({
  title: stringSchema(),
  description: stringSchema({ maxLength: xLargeTextLength }),
});

const form = useForm({ validationSchema: resourceSchema });
const { value: title, errorMessage: titleError } = useField<string>("title");
const { value: description, errorMessage: descriptionError } = useField<string>("description");

const setFormValues = (): void => {
  if (props.input === undefined) {
    return;
  }
  title.value = props.input.title;
  description.value = props.input.description;
};

const save = async () => {
  const interest: Interest = {
    id: props.input?.id ?? 0,
    title: title.value,
    description: description.value,
  };

  if (props.formMode === "create") {
    const created = await createInterest(interest);
    emit("created", created);
  } else {
    const updated = await updateInterest(interest);
    titleRead.value = interest.title;
    descriptionRead.value = interest.description;
    emit("updated", updated);
  }

  form.resetForm();
};

const remove = (): void => {
  openTextModal({
    title: i18n.global.t("pages.my_interests.form.interests.delete.title"),
    contentText: i18n.global.t("pages.my_interests.form.interests.delete.content"),
    showCancelToClose: true,
    confirmCallback: async () => {
      if (props.input === undefined) {
        return false;
      }
      await deleteInterest(props.input.id);
      emit("deleted", props.input);
      return true;
    },
  });
};

const setOpenPanel = (): void => {
  setFormValues();
  openPanel();
};

const close = () => {
  form.resetForm();
};

const openPanel = (): void => {
  openComponentModal(panelName, {
    title: i18n.global.t(`pages.my_interests.form.interests.${props.formMode}.title`),
    confirmText: i18n.global.t("buttons.save"),
    variant: "floating",
    showCancelToClose: true,
    confirmCallback: getFormCallbackHandler(form, save),
    closeCallback: close,
  });
};
</script>

<style scoped lang="scss">
.interests-editor {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  &__actions {
    display: flex;
    flex-direction: row;
    gap: var(--space-m);
  }
}
</style>
