const ISODateFormat = /^\d{4}-\d{2}-\d{2}(?:\.\d*)?$/;
const isISODateString = (value: unknown): boolean => !!value && typeof value === "string" && ISODateFormat.test(value);

const parseISO = (date: string): Date => {
  const dateParsed = new Date(date);
  return new Date(dateParsed.getUTCFullYear(), dateParsed.getUTCMonth(), dateParsed.getUTCDate());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const interceptIsoDates = (body: any): void => {
  if (!body || typeof body !== "object") {
    return;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isISODateString(value)) {
      body[key] = parseISO(value);
    }
    interceptIsoDates(value);
  }
};
