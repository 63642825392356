<template>
  <div class="table__header">
    <AppCheckbox v-if="allowSelect" v-model="selectedAll" @change="selectAllChangeHandle" />
    <span v-if="hasDetail"></span>
    <div
      v-for="column in columns"
      :key="column.id"
      class="text text--bold"
      :class="generateHeaderClasses(column)"
      :data-cy="`app-table-column-header-${String(column.fieldName || column.id)}`"
      @click="orderBy(column)">
      {{ column.headerText }}
      <span v-if="isColumnSorted(column)" class="icon" :class="`icon-arrow-${columnOrderSuffixClass}`"></span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import AppCheckbox from "@/components/AppCheckbox.vue";
import type { Column } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";

interface Props {
  columns: Column[];
  order: Order;
  allowSelect?: boolean;
  userSelectionChanged: number;
  hasDetail: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: "sort", column: Column): void;
  (e: "selectAllChanged", newSelectValue: boolean): void;
}
const emit = defineEmits<Emits>();

const selectedAll = ref<boolean>(false);
const columnOrderSuffixClass = computed<string>(() => (props.order.direction === "asc" ? "upward" : "downward"));

const generateHeaderClasses = (column: Column): string[] => [
  column.sortable ? "table__header-item" : "",
  `table__header-item--${column.align}`,
];

watch(
  () => props.userSelectionChanged,
  () => (selectedAll.value = false)
);

const isColumnSorted = (column: Column): boolean => props.order.id === column.id;
const orderBy = (column: Column): void => emit("sort", column);
const selectAllChangeHandle = (): void => emit("selectAllChanged", selectedAll.value);
</script>
<style lang="scss" scoped>
.table {
  &__header {
    border-bottom: var(--space-xxs) solid var(--color-border);
    &-item {
      color: var(--color-neutral-500);
      cursor: pointer;

      &:hover {
        filter: brightness(50%);
      }

      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;
        padding-right: var(--space-m);
      }
    }
  }
}
</style>
