<template>
  <div class="box">
    <div class="box__header" data-cy="box-header">{{ title }}</div>
    <div class="box__content" :class="contentClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props {
  title: string;
  fullSpace?: boolean;
}
const props = defineProps<Props>();
const contentClasses = computed(() => ({ "box__content--full": props.fullSpace }));
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.box {
  --border-radius: var(--default-border-radius);

  background-color: var(--color-card);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);

  &__header {
    padding: var(--size-s);
    font-size: var(--size-s);
    font-weight: var(--font-bold);
    color: var(--color-primary-accent1);
    background-color: var(--color-card--accent1);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--size-l);
    align-items: flex-start;
    justify-content: center;
    padding: var(--size-s);

    &--full {
      gap: 0;
      padding: 0;
    }

    :slotted(.box-content) {
      &,
      & img {
        max-width: 100%;
      }
      img {
        width: 50%;
      }

      @media only screen and (max-width: $big-screen-breakpoint) {
        padding: var(--size-xs);
        flex-direction: column;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
