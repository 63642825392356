<template>
  <div class="profile-avatar" :class="`profile-avatar--size-${props.size}`" @click="!editable ? $emit('click') : null">
    <div class="profile-avatar__avatar" :class="avatarClass" data-cy="avatar">
      <img
        v-if="hasPicture"
        data-cy="avatar-img"
        :src="pictureUrl"
        class="profile-avatar__picture"
        :class="{ 'profile-avatar__picture-default': !information.hasPicture && !information.pictureName }"
        alt=""
        @load="showPicture = true" />
      <span v-if="!hasPicture && initials" data-cy="avatar-initials" class="text text--xl initials">{{ initials }}</span>
    </div>
    <div v-if="editable" class="profile-avatar__overlay">
      <span
        class="profile-avatar__edit icon icon-pencil text text--xxl"
        :title="$t('buttons.edit')"
        data-cy="avatar-edit"
        @click="$emit('click')"></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import i18n from "@/i18n";
import useEmployeeService from "@/services/useEmployeeService";
import avatarImageUrl from "@/assets/images/avatar.svg";
import type { AvatarInformation } from "@/models/employee/avatarInformation";

type AvatarColors = "default" | "alternative";

const { getEmployeePictureUrl } = useEmployeeService();

interface Props {
  information: AvatarInformation;
  size: "s" | "l" | "m";
  color?: AvatarColors;
  editable?: boolean;
}
const props = withDefaults(defineProps<Props>(), { size: "m", color: "default" });

const pictureUrl = ref<string>("");
const showPicture = ref<boolean>(false);
const hasPicture = computed<boolean>(() => !!props.information.hasPicture || !!props.information.pictureName || !initials.value);

defineEmits<(e: "click") => void>();

onMounted(() => {
  if (!hasPicture.value) {
    return;
  }
  pictureUrl.value =
    props.information.hasPicture || props.information.pictureName ? getEmployeePictureUrl(props.information.id) : avatarImageUrl;
});

const avatarClass = computed(() => {
  const defaultAvatarImage = !props.information.pictureName && !initials.value;
  const color: AvatarColors = defaultAvatarImage ? "alternative" : props.color;
  return {
    [`profile-avatar__avatar--color-${color}`]: !showPicture.value || defaultAvatarImage,
  };
});

const initials = computed(() => {
  return i18n.global.t("avatar.initials", {
    first: props.information.firstName?.substring(0, 1),
    last: props.information.lastName?.substring(0, 1),
  });
});
</script>

<style scoped lang="scss">
.profile-avatar {
  --border-radius: var(--space-m);

  position: relative;
  max-width: 400px;
  width: var(--size);
  height: var(--size);
  & .initials {
    font-size: 1.8rem;
  }

  &--size {
    &-s {
      --size: 40px;
      --border-radius: var(--space-s);
    }

    &-m {
      --size: 64px;
    }

    &-l {
      --size: 80px;

      & .initials {
        font-size: 4rem;
      }
    }
  }

  &__avatar {
    height: 100%;
    width: 100%;
    display: flex;

    border-radius: var(--border-radius);

    &--color-alternative {
      background-color: var(--color-avatar-alternative);

      & .initials {
        color: var(--color-avatar-text-alternative);
      }
    }

    &--color-default {
      background-color: var(--color-avatar-default);
    }
  }

  &__picture {
    border-radius: var(--border-radius);
    width: 100%;
    object-fit: cover;

    &-default {
      padding: 0.8rem;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(51, 51, 51, 0.75);
    border-radius: var(--border-radius);
    transition: 0.3s ease;
    z-index: 2;
  }

  &__edit {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    color: #ffff;
    text-align: center;
    cursor: pointer;
  }

  &:hover &__overlay {
    opacity: 1;
  }
}

.text {
  margin: auto;
}
</style>
