<template>
  <AppPanel :dark="true">
    <div class="app-upload-document-inline">
      <div v-if="formMode !== 'view'" class="button" @click="upload">
        <span class="icon icon-attach-file-alt text text--link"></span>
        <span class="text text--link text--bold">{{ label }}</span>
      </div>
      <div v-else>
        <span class="text text--bold">{{ label }}</span>
      </div>
      <span class="text">|</span>
      <span v-if="!modelValue?.fileName" class="text">{{ $t("inputs.upload_document.no_document_uploaded") }}</span>
      <div v-if="modelValue?.fileName" class="container">
        <span v-if="disableDownload" class="text">{{ modelValue?.fileName }}</span>
        <a
          v-else-if="downloadUrl"
          :href="downloadUrl"
          target="_blank"
          class="text text--ellipsis text--link"
          :title="$t('buttons.download')"
          >{{ modelValue?.fileName }}</a
        >
        <AppIconOnlyButton v-if="formMode !== 'view'" icon="delete" :title="$t('buttons.delete')" @click="deleteDocumentClick" />
      </div>
      <input ref="fileInput" type="file" :accept="accept" class="not-displayed" @change="fileInputChanged" />
    </div>
    <AppInputError v-if="error" :error="error" />
  </AppPanel>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppPanel from "@/components/AppPanel.vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import AppInputError from "@/components/AppInputError.vue";
import type { FileAttachment } from "@/models/fileAttachment";
import type { FormMode } from "@/models/formMode";
import { maxFileSizeInMB } from "@/utils/fileUtils";
import { useUploadDocument } from "@/components/uploadDocument/useUploadDocument";

interface Props {
  downloadUrl?: string;
  label: string;
  modelValue?: FileAttachment;
  accept?: string;
  maximumSize?: number;
  error?: string;
  formMode?: FormMode;
  disableDownload?: boolean;
  withoutModal?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  formMode: "edit",
  maximumSize: maxFileSizeInMB,
  disableDownload: false,
  withoutModal: false,
});

const emit = defineEmits<(e: "change", document: FileAttachment | undefined) => void>();

const fileInput = ref<HTMLInputElement>();

const { upload, clearFileSelector, deleteDocument, getFileFromInputAndClear } = useUploadDocument(fileInput, props);

const resetFileSelector = () => {
  clearFileSelector();
  emit("change", undefined);
};

const deleteDocumentClick = () => deleteDocument(resetFileSelector);

const fileInputChanged = async () => {
  const file = await getFileFromInputAndClear();
  if (file) {
    emit("change", file);
  }
};
</script>

<style scoped lang="scss">
.app-upload-document-inline {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: var(--space-s);
  align-items: center;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1rem;
  column-gap: 1rem;
  justify-content: space-between;
}
.icon {
  margin-right: var(--space-xs);
  font-size: 2rem;
}
.button {
  display: flex;
}
</style>
