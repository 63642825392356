import type { FileAttachment } from "@/models/fileAttachment";
import type { SkillWithProgression } from "@/models/skillWithProgression";

export interface JobExperience {
  id: number;
  title: string;
  description: string;
  attachment?: FileAttachment;
  company: string;
  location: string;
  fromDate: Date;
  toDate?: Date;
}

export interface CurrentJobExperience extends JobExperience {
  skills: SkillWithProgression[];
}

export enum JobExperienceType {
  JobExperience = "JobExperience",
  CurrentJobExperience = "CurrentJobExperience",
}
