<template>
  <div class="app-panel" :class="panelClasses">
    <div v-if="$slots.header" class="header" :class="{ 'padding--m': paddingHeader }">
      <slot name="header"></slot>
    </div>
    <span v-if="icon" class="icon" :class="`icon-${icon}`"></span>
    <div class="content" :class="{ 'padding--m': paddingContent }">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" :class="{ 'padding--m': paddingFooter }">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  border?: boolean;
  shadow?: boolean;
  dark?: boolean;
  type?: string;
  icon?: string;
  paddingContent?: boolean;
  paddingFooter?: boolean;
  paddingHeader?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  border: true,
  paddingContent: true,
  paddingFooter: true,
  paddingHeader: true,
});

const panelClasses = computed(() => ({
  "app-panel--dark": props.dark,
  "app-panel--shadow": props.shadow,
  "app-panel--border": props.border,
  [`app-panel--alert app-panel--${props.type}`]: props.type,
}));
</script>

<style scoped lang="scss">
.app-panel {
  background-color: var(--color-card);
  border-radius: var(--default-border-radius);

  &--dark {
    background-color: var(--color-card--dark);
  }

  &--border {
    border: 1px solid var(--color-border);
  }

  &--shadow {
    box-shadow: 0 var(--space-xs) var(--space-m) 0 var(--color-sidebar-menu--hover);
  }

  &--alert {
    position: relative;

    .icon {
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
    }

    .content {
      padding-left: 60px;
    }
  }

  &--error {
    background-color: var(--color-system-error--light);
  }
}
</style>
