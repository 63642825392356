import { fleebeAppUrl } from "@/constants/urls";

export const openUrl = (url: string): void => {
  const newTab = window.open();
  if (newTab) {
    newTab.opener = null;
    newTab.location = url;
  }
};
const accountBaseUrl = `${fleebeAppUrl}/api/accounts`;
export const getSignInUrl = (redirectUrl: string): string => `${accountBaseUrl}/signin?redirectUrl=${redirectUrl}`;
export const logOutUrl = `${accountBaseUrl}/logout`;
