<template>
  <AppTag :main-text="text" type="presence" />
</template>
<script lang="ts" setup>
import AppTag from "@/components/AppTag.vue";
import i18n from "@/i18n";
import { computed } from "vue";

interface Props {
  text?: string;
}
const props = defineProps<Props>();

const text = computed(() => props.text ?? i18n.global.t("shared.app_tag.no_content_yet"));
</script>
