import { createI18n, type IntlDateTimeFormats, type IntlNumberFormats } from "vue-i18n";
import { STORES, useStorageService } from "@/services/infrastructure/storageService";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import fr from "@/locales/fr.json";

const { getFromStore } = useStorageService<string>(STORES.language_key);

const dateTimesFormatsConfig: IntlDateTimeFormats = {
  en: {
    onlyMonth: {
      month: "short",
    },
  },
  es: {
    onlyMonth: {
      month: "short",
    },
  },
  fr: {
    onlyMonth: {
      month: "short",
    },
  },
};
const numberFormats: IntlNumberFormats = {
  en: {
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  es: {
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  fr: {
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

const getLocale = (intent?: string): string => intent ?? "en";

export default createI18n({
  legacy: false,
  locale: getLocale(getFromStore() ?? import.meta.env.VITE_APP_I18N_LOCALE),
  fallbackLocale: getLocale(import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE),
  globalInjection: true,
  datetimeFormats: dateTimesFormatsConfig,
  numberFormats: numberFormats,
  messages: {
    es,
    en,
    fr,
  },
});
