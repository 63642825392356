<template>
  <div class="generic-filter" :class="{ 'generic-filter--read-only': !addButtonText }">
    <AppBasicText
      v-model="searchText"
      :placeholder="placeholder"
      :maxlength="mediumTextLength"
      icon="magnify"
      :bg-transparent="true"
      data-cy="search"
      :show-clean-option="true"
      @update:model-value="filterChanged" />
    <AppButton
      v-if="addButtonText"
      :text="addButtonText"
      size="m"
      variant="primary"
      data-cy="new-item"
      @onclick="() => emit('createNew')" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import AppBasicText from "@/components/AppBasicText.vue";
import { DEBOUNCE_SEARCH_TIMEOUT } from "@/constants/debounce";
import { mediumTextLength } from "@/constants/restrictions";
import { useDebounce } from "@/services/useDebounce";

interface Props {
  placeholder: string;
  addButtonText?: string;
}
defineProps<Props>();

interface Emits {
  (e: "createNew"): void;
  (e: "filterChanged", newFilter: string): void;
}
const emit = defineEmits<Emits>();

const { after } = useDebounce();

const searchText = ref<string>("");

const filterChanged = () => after(DEBOUNCE_SEARCH_TIMEOUT, () => emit("filterChanged", searchText.value));
</script>

<style lang="scss" scoped>
.generic-filter {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 2rem;
  align-items: center;

  &--read-only {
    grid-template-columns: 1fr;
  }
}
</style>
