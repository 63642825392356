import AppLayout from "@/layout/app/AppLayout.vue";
import AppMyProfileView from "@/views/app/my-profile/AppMyProfileView.vue";
import AppMyProfileOverviewView from "@/views/app/my-profile/AppMyProfileOverviewView.vue";
import AppMyProfileDashboardView from "@/views/app/my-profile/AppMyProfileDashboardView.vue";
import AppMyProfileDefaultView from "@/views/app/my-profile/AppMyProfileDefaultView.vue";
import AppAIAssistantView from "@/views/app/ai-assistant/AppAIAssistantView.vue";
import AppCurrentExperienceView from "@/views/app/current-experience/AppCurrentExperienceView.vue";
import AppGoalsChallengesView from "@/views/app/goals-challenges/AppGoalsChallengesView.vue";
import AppMyLearningView from "@/views/app/my-learning/AppMyLearningView.vue";
import AppMyLearningLearningView from "@/views/app/my-learning/AppMyLearningLearningView.vue";
import AppMyLearningLearningPathView from "@/views/app/my-learning/AppMyLearningLearningPathView.vue";
import AppMyLearningTrainingView from "@/views/app/my-learning/AppMyLearningTrainingView.vue";
import AppMyIdealJobView from "@/views/app/my-ideal-job/AppMyIdealJobView.vue";
import AppMyIdealJobLearningPathView from "@/views/app/my-ideal-job/AppMyIdealJobLearningPathView.vue";
import AppMyIdealJobMyTrainingView from "@/views/app/my-ideal-job/AppMyIdealJobMyTrainingView.vue";
import AppMyInterestsView from "@/views/app/my-interests/AppMyInterestsView.vue";
import AppMyGroupsView from "@/views/app/my-groups/AppMyGroupsView.vue";
import AppMyGroupsOverview from "@/views/app/my-groups/AppMyGroupsOverviewView.vue";
import EmployeeProfileView from "@/views/shared/employee/EmployeeProfileView.vue";
import AppMyGroupsQuestionsView from "@/views/app/my-groups/AppMyGroupsQuestionsView.vue";

export default [
  {
    path: "/app",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "default-app",
        redirect: "/app/my-profile",
      },
      {
        path: "my-profile",
        name: "my-profile",
        component: AppMyProfileView,
        children: [
          {
            path: "overview",
            name: "my-profile-overview",
            component: AppMyProfileOverviewView,
          },
          {
            path: "dashboard",
            name: "my-profile-dashboard",
            component: AppMyProfileDashboardView,
          },
          {
            path: "",
            name: "my-profile-default",
            component: AppMyProfileDefaultView,
          },
        ],
      },
      {
        path: "ai-assistant",
        name: "ai-assistant",
        component: AppAIAssistantView,
        strict: true,
      },
      {
        path: "my-interests",
        name: "my-interests",
        component: AppMyInterestsView,
      },
      {
        path: "my-current-job",
        name: "my-current-job",
        component: AppCurrentExperienceView,
      },
      {
        path: "goals-challenges",
        name: "goals-challenges",
        component: AppGoalsChallengesView,
      },
      {
        path: "my-learning",
        name: "my-learning",
        component: AppMyLearningView,
        children: [
          {
            path: "",
            name: "my-learning-learning",
            component: AppMyLearningLearningView,
          },
          {
            path: "my-learning-path",
            name: "my-learning-path",
            component: AppMyLearningLearningPathView,
          },
          {
            path: "my-training",
            name: "my-learning-training",
            component: AppMyLearningTrainingView,
          },
        ],
      },
      {
        path: "my-ideal-job",
        name: "my-ideal-job",
        component: AppMyIdealJobView,
        children: [
          {
            path: "",
            name: "my-ideal-job-learning",
            component: AppMyIdealJobLearningPathView,
          },
          {
            path: "my-training",
            name: "my-ideal-job-my-training",
            component: AppMyIdealJobMyTrainingView,
          },
          {
            path: "company-training",
            name: "my-ideal-job-company-training",
            component: AppMyIdealJobLearningPathView,
          },
        ],
      },
      {
        path: "my-groups",
        name: "my-groups",
        component: AppMyGroupsView,
        children: [
          {
            path: "",
            name: "my-groups-overview",
            component: AppMyGroupsOverview,
          },
          {
            path: "employees/:employeeId",
            name: "my-groups-employee",
            props: {
              backRoute: "my-groups-overview",
            },
            component: EmployeeProfileView,
          },
          {
            path: "questions",
            name: "my-groups-questions",
            component: AppMyGroupsQuestionsView,
          },
        ],
        meta: {
          requiresGroupManager: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
      isAppArea: true,
    },
  },
];
