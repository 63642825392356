<template>
  <div class="import-group-from-excel padding-b--m">
    <AppTip
      :content="$t('pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.description')"
      :title="$t('pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.title')" />
    <AppExcelParser
      :title="$t('pages.admin.company_settings.employee_groups.editor.import_group_from_excel.title')"
      @excel-parsed="handleExcelParsed" />
  </div>
</template>

<script lang="ts" setup>
import AppExcelParser from "@/components/AppExcelParser.vue";
import i18n from "@/i18n";
import useModal from "@/components/modal/useModal";
import AppTip from "@/components/AppTip.vue";
import type { ParsedExcelRow, RowWithEmail } from "@/models/parsedExcelRow";
import type { EmployeeInformation } from "@/models/employee/employeeInformation";
import type {
  CheckAllAreEmailsResult,
  SelectEmployeeInGroupResult,
} from "@/views/admin/company-settings/company-employee-groups/utils/SelectEmployeeInGroupResult";

const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props {
  employees: EmployeeInformation[];
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "employeesSelected", selectedEmployees: Set<number>) => void>();

const { openTextModal } = useModal();

const checkAllAreEmails = (rows: RowWithEmail[]): CheckAllAreEmailsResult => {
  return rows.reduce(
    (prev: CheckAllAreEmailsResult, row: RowWithEmail) => {
      if (!VALID_EMAIL_REGEX.test(row.email.toLowerCase())) {
        return { valid: false, notValidEmails: prev.notValidEmails.add(row.email) };
      }
      return prev;
    },
    { valid: true, notValidEmails: new Set<string>() } as CheckAllAreEmailsResult
  );
};
const checkThereIsAnEmailProperty = (rows: RowWithEmail[] | undefined) => {
  return rows?.some((row) => Object.keys(row).includes("email"));
};
const selectEmployees = (employees: EmployeeInformation[], rows: RowWithEmail[]): SelectEmployeeInGroupResult => {
  return rows.reduce<SelectEmployeeInGroupResult>(
    (previousValue: SelectEmployeeInGroupResult, currentValue: RowWithEmail) => {
      const employee = employees.find((employee: EmployeeInformation) => employee.email.toLowerCase() === currentValue.email.toLowerCase());
      if (employee) {
        previousValue.selectedIds.add(employee.id);
      } else {
        previousValue.notFoundEmails.add(currentValue.email);
      }
      return previousValue;
    },
    { selectedIds: new Set<number>(), notFoundEmails: new Set<string>() } as SelectEmployeeInGroupResult
  );
};

const sanitizeParsedExcel = (parsedExcel: RowWithEmail[]): RowWithEmail[] => {
  return parsedExcel.map(
    (row: RowWithEmail) => Object.fromEntries(Object.entries(row).map(([k, v]) => [k.toLowerCase(), v])) as RowWithEmail
  );
};

const showModal = (errorType: string, named: Record<string, unknown> = {}) => {
  openTextModal({
    title: i18n.global.t(`pages.admin.company_settings.employee_groups.editor.import_group_from_excel.${errorType}.title`),
    contentText: i18n.global.t(
      `pages.admin.company_settings.employee_groups.editor.import_group_from_excel.${errorType}.description`,
      named
    ),
    showCancelToClose: true,
    cancelText: i18n.global.t("shared.close"),
  });
};

const handleExcelParsed = (parsedExcel: ParsedExcelRow[]): void => {
  if (!parsedExcel) {
    return;
  }
  const sanitizedParsedExcel = sanitizeParsedExcel(parsedExcel as RowWithEmail[]);
  if (!checkThereIsAnEmailProperty(sanitizedParsedExcel)) {
    showModal("no_email_column_error");
    return;
  }
  const checkAllAreEmailsResult = checkAllAreEmails(sanitizedParsedExcel);
  if (!checkAllAreEmailsResult.valid) {
    showModal("not_all_are_emails_error", { notValidEmails: [...checkAllAreEmailsResult.notValidEmails].join("<br>") });
    return;
  }

  const selectEmployeesResult = selectEmployees(props.employees, sanitizedParsedExcel);
  if (selectEmployeesResult.notFoundEmails.size > 0) {
    showModal("emails_not_matched", { notFoundEmails: [...selectEmployeesResult.notFoundEmails].join("<br>") });
  } else {
    showModal("all_emails_matched");
  }
  emit("employeesSelected", selectEmployeesResult.selectedIds);
};
</script>
<style lang="scss">
.import-group-from-excel {
  display: grid;
  grid-template-columns: 0.5fr;
}
</style>
