<template>
  <LayoutPage :title="$t('pages.admin.dashboard.welcome')">
    <div class="admin-dashboard__overview">
      <h3>{{ $t("pages.admin.dashboard.search.introduction") }}</h3>
      <EmployeeSearch :placeholder="$t('pages.admin.dashboard.search.placeholder')" @select-item="click" />
    </div>
    <AdminDashboardOverviewShortcuts v-if="isUserAdministrator" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import router from "@/router/router";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AdminDashboardOverviewShortcuts from "@/views/admin/dashboard/components/AdminDashboardOverviewShortcuts.vue";
import useAuthenticationService from "@/services/useAuthenticationService";
import EmployeeSearch from "@/views/shared/employee/components/EmployeeSearch.vue";

const { isAdministrator } = useAuthenticationService();

const isUserAdministrator = ref<boolean>(false);

const click = (employeeId: number) => router.push({ name: "dashboard-admin-employee", params: { employeeId } });

onMounted(async () => {
  isUserAdministrator.value = await isAdministrator();
});
</script>

<style scoped lang="scss">
@import "@/styles/utils/responsive";
.admin-dashboard {
  &__overview {
    width: 50%;
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;

    @media only screen and (max-width: $medium-screen-breakpoint) {
      width: 100%;
    }
  }
}
</style>
