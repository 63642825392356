import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
import { authenticationGuard } from "@/router/guards/authenticationGuard";
import { featureGuard } from "@/router/guards/featureGuard";
import appRoutes from "@/router/routes/app.routes";
import adminRoutes from "@/router/routes/admin.routes";
import guestRoutes from "@/router/routes/guest.routes";
import itManagerRoutes from "@/router/routes/it-manager.routes";
import accountsRoutes from "@/router/routes/accounts.routes";

const routes: RouteRecordRaw[] = [
  ...appRoutes,
  ...adminRoutes,
  ...guestRoutes,
  ...itManagerRoutes,
  ...accountsRoutes,
  {
    path: "/",
    name: "default",
    redirect: "/app/",
  },
  { path: "/:pathMatch(.*)*", redirect: "/app/" },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

authenticationGuard(router);
featureGuard(router);

export default router;
