<template>
  <LayoutPage>
    <Teleport v-if="goal" to="#header-button-container">
      <AppIconButton :text="$t('shared.back')" size="l" icon="arrow-back" @onclick="back" />
    </Teleport>
    <div v-if="goal" class="admin-company-goals-details details">
      <div class="details__title">
        <h2 data-cy="goal-title">{{ goal.title }}</h2>
        <AppButton
          v-if="isEditable(goal)"
          :text="$t('pages.admin.company_goals.goal_detail.edit_goal')"
          size="m"
          data-cy="edit-goal"
          variant="secondary"
          @onclick="editing = true" />
      </div>
      <AppTag
        v-if="isApplicationPhaseOpen(goal)"
        class="margin-t--xs"
        type="status"
        :main-text="$t('pages.admin.company_goals.goal_detail.published_for_applicants')" />
      <AdminCompanyGoalDetailInformation
        :goal="goal"
        @applicants="recommendingEmployees = true"
        @delete-employee="deleteEmployee"
        @launch="launch"
        @set-result="setResult" />
      <AdminCompanyGoalsApplicants
        v-if="recommendingEmployees"
        :goal="goal"
        @close-click="recommendingEmployees = false"
        @add-click="addEmployee" />
    </div>
    <Component
      :is="goal?.isPublished ? AdminCompanyGoalsSimpleEditor : AdminCompanyGoalsFullEditor"
      v-if="editing"
      :goal="goal"
      @close="editing = false"
      @goal-edited="getGoal" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { isApplicationPhaseOpen, isEditable } from "@/services/modelServices/companyGoalService";
import { useRoute } from "vue-router";
import AdminCompanyGoalsApplicants from "@/views/admin/company-goals/components/AdminCompanyGoalsApplicants.vue";
import AdminCompanyGoalsFullEditor from "@/views/admin/company-goals/components/AdminCompanyGoalsFullEditor.vue";
import AdminCompanyGoalsSimpleEditor from "@/views/admin/company-goals/components/AdminCompanyGoalsSimpleEditor.vue";
import AdminCompanyGoalDetailInformation from "@/views/admin/company-goals/components/AdminCompanyGoalDetailInformation.vue";
import AppButton from "@/components/AppButton.vue";
import AppIconButton from "@/components/AppIconButton.vue";
import AppTag from "@/components/AppTag.vue";
import i18n from "@/i18n";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import router from "@/router/router";
import type { CompanyGoalEmployee } from "@/models/companyGoalEmployee";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";
import useModal from "@/components/modal/useModal";
import useToaster from "@/components/toaster/useToaster";

const route = useRoute();
const { openTextModal } = useModal();
const {
  getCompanyGoal,
  addEmployeeToCompanyGoal,
  deleteEmployeeFromCompanyGoal,
  launchCompanyGoal,
  setFailureCompanyGoalResult,
  setSuccessCompanyGoalResult,
} = useCompanyGoalsService();
const { openToaster } = useToaster();

const editing = ref<boolean>(false);
const goal = ref<CompanyGoalWithEmployees>();
const goalId: number = parseInt(route.params.goalId as string);
const recommendingEmployees = ref(false);

const getGoal = async () => (goal.value = await getCompanyGoal(goalId));
onMounted(getGoal);

const back = () => router.push({ name: "corporate-goals-admin-overview" });

const addEmployee = async (employee: CompanyGoalEmployee): Promise<void> => {
  if (!goal.value) {
    return;
  }
  await addEmployeeToCompanyGoal(goal.value.id, employee.employeeId);
  goal.value.assignedEmployees.push(employee);
  recommendingEmployees.value = false;
};

const deleteEmployee = async (employeeId: number): Promise<void> => {
  if (!goal.value) {
    return;
  }
  await deleteEmployeeFromCompanyGoal(goal.value.id, employeeId);
  goal.value.assignedEmployees = goal.value.assignedEmployees.filter((e) => e.employeeId !== employeeId);
};

const launch = async (): Promise<void> => {
  openTextModal({
    title: i18n.global.t("pages.admin.company_goals.goal_detail.confirm_launch.title"),
    contentText: i18n.global.t("pages.admin.company_goals.goal_detail.confirm_launch.description"),
    showCancelToClose: true,
    confirmCallback: confirmLaunch,
  });
};
const confirmLaunch = async (): Promise<void> => {
  if (!goal.value) {
    return;
  }
  await launchCompanyGoal(goal.value.id);
  openToaster("success", i18n.global.t("pages.admin.company_goals.goal_detail.successfully_launched"));
  goal.value.isLaunched = true;
};
const setResult = async (isSuccessful: boolean): Promise<void> => {
  const result = isSuccessful
    ? i18n.global.t("pages.admin.company_goals.goal_detail.result_succeeded").toLocaleLowerCase()
    : i18n.global.t("pages.admin.company_goals.goal_detail.result_failed").toLocaleLowerCase();

  openTextModal({
    title: i18n.global.t("pages.admin.company_goals.goal_detail.confirm_set_result.title", { result }),
    contentText: i18n.global.t("pages.admin.company_goals.goal_detail.confirm_set_result.description"),
    showCancelToClose: true,
    confirmCallback: async () => {
      await confirmResult(isSuccessful);
    },
  });
};
const confirmResult = async (isSuccessful: boolean): Promise<void> => {
  if (!goal.value) {
    return;
  }
  if (isSuccessful) {
    await setSuccessCompanyGoalResult(goalId);
  } else {
    await setFailureCompanyGoalResult(goalId);
  }
  goal.value.isSuccessful = isSuccessful;
  openToaster("success", i18n.global.t("pages.admin.company_goals.goal_detail.successfully_result_set"));
};
</script>

<style scoped lang="scss">
.details {
  &__title {
    align-items: center;
    display: flex;
    gap: var(--space-m);
  }
}
</style>
