import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";
import type { PerformanceGraph } from "@/models/performanceGraph";
import type { SkillProgression } from "@/models/skillProgression";

const BASE_URL = "/api/me/performance-graph";
const { get, post, loading } = useHttpClient();

interface UseMePerformanceGraphServiceReturn {
  getPerformanceGraph: () => Promise<PerformanceGraph>;
  updatePerformanceGraph: (request: UpdatePerformanceGraphRequest) => Promise<void>;
  getAvailableSkills: () => Promise<GetAvailableSkillsResponse>;
  loading: Ref<boolean>;
}

interface GetAvailableSkillsResponse {
  currentJobSkills: { id: number; name: string; progression: SkillProgression }[];
  idealJobSkills: { id: number; name: string; progression: SkillProgression }[];
}

interface UpdatePerformanceGraphRequest {
  idealJobSkillIds: number[];
  currentJobSkillIds: number[];
}

export default (): UseMePerformanceGraphServiceReturn => {
  const getPerformanceGraph = (): Promise<PerformanceGraph> => get(BASE_URL);
  const updatePerformanceGraph = (request: UpdatePerformanceGraphRequest): Promise<void> => post(BASE_URL, request);
  const getAvailableSkills = (): Promise<GetAvailableSkillsResponse> => get(`${BASE_URL}/available-skills`);

  return { getPerformanceGraph, updatePerformanceGraph, getAvailableSkills, loading };
};
