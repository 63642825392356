<template>
  <div class="employee-goal">
    <div>
      <AppProfileAvatar :size="size" :information="employeeInformation" />
    </div>
    <div class="employee-goal__info">
      <span class="text--l text--bold text--link" @click="viewEmployee">{{
        $t("pages.admin.company_goals.goal_detail.employee_full_name", { firstName: employee.firstName, lastName: employee.lastName })
      }}</span>
      <span class="text--s text--bold">{{ currentJob ?? $t("shared.not_available") }}</span>
    </div>
    <div class="employee-goal__progress">
      <AppProgressBar :value="employee.goalTargetFit" />
    </div>
    <span class="text text--s text--bold">{{ $n(employee.goalTargetFit / 100, "percent") }}</span>
    <div v-if="displayDelete" class="employee-goal__delete" data-cy="delete-employee" @click="deleteEmployee">
      <span class="text text--link icon icon-close"></span>
    </div>
    <div v-if="displayAdd" class="employee-goal__add" data-cy="add-employee" @click="addEmployee">
      <span class="text text--link icon icon-plus"></span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import AppProfileAvatar from "@/components/AppProfileAvatar.vue";
import AppProgressBar from "@/components/AppProgressBar.vue";
import type { CompanyGoalEmployee } from "@/models/companyGoalEmployee";
import type { AvatarInformation } from "@/models/employee/avatarInformation";

interface Props {
  employee: CompanyGoalEmployee;
  size?: "s" | "m";
  displayDelete?: boolean;
  displayAdd?: boolean;
}
const props = withDefaults(defineProps<Props>(), { displayJob: true, size: "m" });

const currentJob = props.employee.currentPosition?.length > 0 ? props.employee.currentPosition[0] : undefined;
const employeeInformation: AvatarInformation = { ...props.employee, id: props.employee.employeeId };

interface Emits {
  (e: "addClick", employee: CompanyGoalEmployee): void;
  (e: "deleteClick", id: number): void;
  (e: "detailsClick", id: number): void;
}
const emit = defineEmits<Emits>();

const addEmployee = () => emit("addClick", props.employee);
const deleteEmployee = () => emit("deleteClick", props.employee.employeeId);
const viewEmployee = () => emit("detailsClick", props.employee.employeeId);
</script>

<style lang="scss">
.employee-goal {
  display: flex;
  align-items: center;
  column-gap: var(--space-s);

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1 100%;

    &--wrapper-m {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      padding-top: 4px;
    }

    &--wrapper-s {
      display: flex;
      gap: var(--space-xxs);
      padding-top: 4px;
    }
  }
  &__progress {
    width: 70%;
  }
  &__delete {
    cursor: pointer;
  }
}
</style>
