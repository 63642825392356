<template>
  <AppLinkButton data-cy="ai-assistant-answer-open-action" :bold="false" :text="$t(titleKey)" @click="clicked" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppLinkButton from "@/components/AppLinkButton.vue";

interface Props {
  isNotAnswerYet: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "click") => void>();

const titleKey = computed(() => (props.isNotAnswerYet ? "buttons.answer" : "buttons.edit"));

const clicked = () => emit("click");
</script>
