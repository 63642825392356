<template>
  <div>
    <Teleport to="#generic-sub-header-buttons-container">
      <AppButton
        v-if="searching"
        :text="$t('shared.back')"
        size="m"
        variant="secondary"
        data-cy="back-training"
        @onclick="() => (searching = false)" />
      <AppButton
        v-if="!searching"
        :text="$t('pages.admin.company_settings.employee_groups.manage.learning.select_training')"
        size="m"
        variant="secondary"
        data-cy="select-training"
        @onclick="() => (searching = true)" />
      <AppButton
        v-if="!searching"
        :text="$t('pages.admin.company_settings.employee_groups.manage.learning.add_training')"
        size="m"
        variant="primary"
        data-cy="add-training"
        @onclick="() => (editing = true)" />
    </Teleport>
    <div v-show="searching">
      <CoursesListSearcher
        :event-emitter="eventEmitter"
        :admin-mode="true"
        :placeholder="$t('pages.admin.company_settings.employee_groups.manage.learning.search.placeholder')"
        @course-selected="cardClick" />
    </div>
    <div v-if="!searching" class="company-employee-group-learning" data-cy="company-employee-group-learning">
      <div
        v-if="courses.length"
        class="company-employee-group-learning__container grid--four-columns@large grid--three-columns@medium grid--two-columns">
        <CourseCard
          v-for="course in courses"
          :key="course.id"
          :course="course"
          :show-admin-options="true"
          :no-editable="course.courseType !== CourseType.CourseCompany"
          @click="cardClick"
          @edit="editClick"
          @delete="unassignCourse" />
      </div>
      <AppNoContentYet v-else :text="$t('pages.admin.company_settings.employee_groups.manage.learning.no_content')" />
      <CourseEditor v-if="editing" :course="selectedCourse" :event-emitter="eventEmitter" @closed="editorCloseHandler" />
    </div>
    <CourseDetails
      v-if="selectedCourse && !editing"
      :course="selectedCourse"
      :allow-assign="searching && !courses.some(({ id }) => id === selectedCourse?.id)"
      @closed="selectedCourse = undefined"
      @assign="assignCourse" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import { CourseType, type CourseBase, type CourseModificationStatus } from "@/models/course";
import CourseDetails from "@/views/shared/learning/CourseDetails.vue";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import CourseEditor from "@/views/admin/learning/components/CourseEditor.vue";
import useEventEmitterService from "@/services/useEventEmitterService";
import CourseCard from "@/views/shared/learning/CourseCard.vue";
import CoursesListSearcher from "@/views/app/my-learning/components/CoursesListSearcher.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";

interface Props {
  groupId: number;
}
const props: Props = defineProps<Props>();

const { getCourses, setCourse, deleteCourse } = useCompanyEmployeeGroupsService();
const eventEmitter = useEventEmitterService();

const courses = ref<CourseBase[]>([]);
const selectedCourse = ref<CourseBase | undefined>();
const editing = ref<boolean>(false);
const searching = ref<boolean>(false);

const cardClick = (course: CourseBase): void => {
  selectedCourse.value = course;
};
const editClick = (course: CourseBase): void => {
  editing.value = true;
  selectedCourse.value = course;
};
const editorCloseHandler = (): void => {
  editing.value = false;
  selectedCourse.value = undefined;
};

const fetchCourses = async () => (courses.value = await getCourses(props.groupId));

const onCourseEdited = async ({ course, added }: CourseModificationStatus): Promise<void> => {
  if (added) {
    return assignCourse(course);
  }
  await fetchCourses();
};

const assignCourse = async (course: CourseBase) => {
  await setCourse(props.groupId, course.id);
  await fetchCourses();
};

const unassignCourse = async (course: CourseBase) => {
  await deleteCourse(props.groupId, course.id);
  await fetchCourses();
};

onMounted(async () => {
  await fetchCourses();
  eventEmitter.on("courseEdited", onCourseEdited);
});
</script>
