<template>
  <div class="course-grid">
    <div class="grid--four-columns@large grid--three-columns@medium grid--one-column">
      <CourseCard
        v-for="course in courses.items"
        :key="course.id"
        :course="course"
        :show-admin-options="true"
        :no-editable="true"
        :assign-mode="true"
        @click="selectCourse" />
    </div>
    <PaginationFooter :number-of-items="courses.total" :event-emitter="eventEmitter" @page-click="pageClick" />
  </div>
</template>
<script setup lang="ts">
import PaginationFooter from "@/components/paginator/PaginationFooter.vue";
import CourseCard from "@/views/shared/learning/CourseCard.vue";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import type { CourseBase, CourseBaseList } from "@/models/course";

interface Props {
  courses: CourseBaseList;
  eventEmitter: UseEventEmitterServiceReturn;
}
defineProps<Props>();

interface Emits {
  (e: "courseSelected", course: CourseBase): void;
  (e: "updateList", pageNumber: number): void;
}
const emit = defineEmits<Emits>();

const selectCourse = (course: CourseBase) => emit("courseSelected", course);
const pageClick = (value: number) => emit("updateList", value);
</script>

<style scope lang="scss">
.course-grid {
  display: flex;
  flex-direction: column;
  gap: var(--size-s);
  padding-top: var(--size-s);
}
</style>
