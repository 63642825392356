<template>
  <div class="question-kpi-data">
    <span class="text text--bold" :title="kpiInfo.answeredCount.toLocaleString()">
      {{ `${$t("pages.admin.ai_assistant.questions.employee_answered")}: ${kpiInfo.answeredPercentage.toLocaleString()}%` }}
    </span>
    <Component :is="kpiComponent" :series="series" :series-percentage="seriesPercentage" :labels="labels" />
  </div>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import type { QuestionKpiInfo } from "@/models/questionKpi";
import { type Component, computed } from "vue";
import { getQuestionKPIComponentFor } from "@/views/app/ai-assistant/utils/questionHelper";

interface Props {
  kpiInfo: QuestionKpiInfo;
}
const props = defineProps<Props>();
const kpiComponent = computed<Component>(() => getQuestionKPIComponentFor(props.kpiInfo.type));

const series = props.kpiInfo.options.map((option) => option.answeredCount);
const seriesPercentage = props.kpiInfo.options.map((option) => option.answeredPercentage);
const labels = props.kpiInfo.options.map((option) =>
  option.isOthersSummary ? i18n.global.t("pages.admin.ai_assistant.questions.othersSummary") : option.title
);
</script>
<style scoped lang="scss">
.question-kpi-data {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
}
</style>
