import GuestApp from "@/GuestApp.vue";
import AccountsAdminRequiredView from "@/views/accounts/admin-required/AccountsAdminRequiredView.vue";
import AccountsLogoutView from "@/views/accounts/logout/AccountsLogoutView.vue";
import AccountsTenantUnauthorizedView from "@/views/accounts/tenant-unauthorized/AccountsTenantUnauthorizedView.vue";
import AccountsUserUnauthorizedView from "@/views/accounts/user-unauthorized/AccountsUserUnauthorizedView.vue";

export default [
  {
    path: "/accounts",
    component: GuestApp,
    children: [
      {
        path: "logout",
        name: "accounts-logout",
        component: AccountsLogoutView,
      },
      {
        path: "admin-required",
        name: "accounts-admin-required",
        component: AccountsAdminRequiredView,
      },
      {
        path: "tenant-unauthorized",
        name: "accounts-tenant-unauthorized",
        component: AccountsTenantUnauthorizedView,
      },
      {
        path: "user-unauthorized",
        name: "accounts-user-unauthorized",
        component: AccountsUserUnauthorizedView,
      },
    ],
  },
];
