<template>
  <LayoutPage :title="$t('pages.my_profile.title')">
    <MyProfileNavigation current="Dashboard" />
    <div v-if="!loading && profileInfo" class="grid--two-columns@large">
      <div class="summary">
        <AppTip
          v-if="!hasPerformanceGraphConfigured"
          :content="$t('pages-my_profile.dashboards.tips.introduction')"
          unique-name="my-profile-dashboard-introduction" />
        <ProfileSummary v-if="profileInfo" class="summary__profile" :profile-information="profileInfo" />
        <AppSeparator class="margin-v--m" />
        <AppTip
          :content="$t('pages-my_profile.dashboards.tips.no-content')"
          unique-name="my-profile-dashboard-performance-graph-no-content" />

        <div v-if="hasPerformanceGraphWellConfigured">
          <ProfilePerformanceChart
            :series-values="performanceGraphSeriesValues"
            :category-names="performanceGraphCategoryNames"
            data-cy="performance-graph" />
          <AppTip :content="$t('pages-my_profile.dashboards.tips.content')" unique-name="my-profile-dashboard-performance-graph" />
        </div>
        <div v-else class="summary__performance-graph-content">
          <AppNoContentYet :text="$t('pages-my_profile.dashboards.performance_graph.no_content_yet')" />
          <img src="@/assets/images/empty-performance-graph.svg" alt="" data-cy="no-performance-graph" />
        </div>
        <div v-if="hasPerformanceGraphConfigured" class="summary__performance-graph-content">
          <AppLinkButton
            data-cy="edit-graph"
            :text="$t('pages-my_profile.dashboards.performance_graph.edit')"
            @click="editPerformanceGraph" />
        </div>
      </div>
      <div>
        <div class="grid--two-columns@medium">
          <SuccessFullSkills data-cy="successful-skills" />
          <CertificatedAndCourses class="dashboard__certificate-and-courses" data-cy="certificate-and-courses" />
        </div>
      </div>
    </div>
    <ProfilePerformanceChartConfigurerWizard
      v-if="configuringPerformanceGraph"
      :initial-step="1"
      @updated="performanceGraphUpdated"
      @close="configuringPerformanceGraph = false" />
  </LayoutPage>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { onMounted, ref } from "vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import CertificatedAndCourses from "@/views/app/my-profile/components/dashboard/CertificatedAndCourses.vue";
import ProfileSummary from "@/views/app/my-profile/components/dashboard/ProfileSummary.vue";
import SuccessFullSkills from "@/views/app/my-profile/components/dashboard/SuccessFullSkills.vue";
import ProfilePerformanceChart from "@/views/app/my-profile/components/overview/ProfilePerformanceChart.vue";
import MyProfileNavigation from "@/views/app/my-profile/components/shared/MyProfileNavigation.vue";
import useMeService from "@/services/useMeService";

import useMePerformanceGraphService from "@/services/useMePerformanceGraphService";

import type { ProfileInformation } from "@/models/profileInformation";
import type { SkillProgression } from "@/models/skillProgression";
import AppTip from "@/components/AppTip.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import ProfilePerformanceChartConfigurerWizard from "@/views/app/my-profile/components/overview/performance-chart-configurer/ProfilePerformanceChartConfigurerWizard.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";

const { getBasicProfile } = useMeService();
const { getPerformanceGraph } = useMePerformanceGraphService();

const profileInfo: Ref<ProfileInformation | undefined> = ref<ProfileInformation | undefined>();
const loading: Ref<boolean> = ref<boolean>(true);
const configuringPerformanceGraph: Ref<boolean> = ref<boolean>(false);

const performanceGraphSeriesValues: Ref<number[][]> = ref<number[][]>([]);
const performanceGraphCategoryNames: Ref<string[]> = ref<string[]>([]);
const hasPerformanceGraphConfigured: Ref<boolean> = ref<boolean>(false);
const hasPerformanceGraphWellConfigured: Ref<boolean> = ref<boolean>(false);

const progressionToNumber: Record<SkillProgression, number> = {
  High: 4,
  Medium: 3,
  Low: 2,
};

onMounted(async () => {
  profileInfo.value = await getBasicProfile();
  await initPerformanceGraph();
  loading.value = false;
});

const initPerformanceGraph = async (): Promise<void> => {
  const performanceGraph = await getPerformanceGraph();

  performanceGraphCategoryNames.value = performanceGraph.skillNames.map((s) => s.name);
  performanceGraphSeriesValues.value = [
    performanceGraph.currentJobProgressions.map((s) => (s.progression === undefined ? 1 : progressionToNumber[s.progression])),
    performanceGraph.idealJobProgressions.map((s) => (s.progression === undefined ? 1 : progressionToNumber[s.progression])),
    performanceGraph.currentExperienceProgressions.map((s) => (s.progression === undefined ? 1 : progressionToNumber[s.progression])),
  ];
  hasPerformanceGraphConfigured.value = performanceGraph.skillNames.length > 0;
  hasPerformanceGraphWellConfigured.value =
    hasPerformanceGraphConfigured.value &&
    performanceGraph.currentExperienceProgressions.length > 2 &&
    performanceGraph.idealJobProgressions.length > 2;
};

const editPerformanceGraph = (): void => {
  configuringPerformanceGraph.value = true;
};
const performanceGraphUpdated = async (): Promise<void> => {
  configuringPerformanceGraph.value = false;
  loading.value = true;
  await initPerformanceGraph();
  loading.value = false;
};
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.summary {
  padding-top: var(--space-l);

  &__profile {
    padding-top: var(--space-l);
  }

  &__skills-description {
    margin-bottom: var(--space-l);
  }

  &__general-skills {
    display: flex;
    flex-direction: column;
  }

  &__general-skills-title {
    width: 100%;
  }

  &__performance-graph-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-l);
  }
}
</style>
