<template>
  <AppPanel :dark="true" :border="false">
    <div v-if="isInProgress(props.goal) || isFinished(props.goal)">
      <div class="progress progress--progression" data-cy="in-progress">
        <p>{{ $t("pages.admin.company_goals.goal_detail.progress.label") }}</p>
        <p class="text--bold text--l">{{ `${formatNumber(goal.progression ?? 0)} %` }}</p>
      </div>
      <AppProgressBar :value="goal.progression" />
    </div>
    <div v-else class="progress progress--no-progression" data-cy="no-progress">
      <span class="icon margin-r--s" :class="iconClass"></span>
      {{ noProgressionText }}
    </div>
  </AppPanel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";
import AppPanel from "@/components/AppPanel.vue";
import AppProgressBar from "@/components/AppProgressBar.vue";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import {
  isApplicationPhaseOpen,
  isDraft,
  isFinished,
  isInProgress,
  isPublished,
  isTeamBuilt,
} from "@/services/modelServices/companyGoalService";
import { formatNumber } from "@/utils/numberUtils";

interface Props {
  goal: CompanyGoalWithEmployees;
}

const props = defineProps<Props>();

const iconClass = computed(() => ({
  "icon-warning": isDraft(props.goal),
  "icon-calendar": isApplicationPhaseOpen(props.goal),
  "icon-team": isPublished(props.goal) && !isApplicationPhaseOpen(props.goal),
}));

const noProgressionText = computed(() => {
  const textMap = {
    draft: i18n.global.t("pages.admin.company_goals.goal_detail.progress.no_progression.draft"),
    applicationPhaseOpen: i18n.global.t("pages.admin.company_goals.goal_detail.progress.no_progression.application_open"),
    teamBuilt: i18n.global.t("pages.admin.company_goals.goal_detail.progress.no_progression.team_built"),
    teamNotBuilt: i18n.global.t("pages.admin.company_goals.goal_detail.progress.no_progression.team_not_built"),
  };
  if (isDraft(props.goal)) {
    return textMap.draft;
  }
  if (isApplicationPhaseOpen(props.goal)) {
    return textMap.applicationPhaseOpen;
  }

  return isTeamBuilt(props.goal) ? textMap.teamBuilt : textMap.teamNotBuilt;
});
</script>

<style scoped lang="scss">
.progress {
  display: flex;

  &--progression {
    justify-content: space-between;
  }

  &--no-progression {
    align-items: center;
  }
}

.icon {
  font-size: 2.5rem;
}
</style>
