<template>
  <AppButton size="m" :variant="participateButtonVariant()" :text="participateButtonText()" @onclick="switchParticipationHandler" />
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import AppButton from "@/components/AppButton.vue";
import useMeGoalsService from "@/services/useMeGoalsService";

interface Props {
  goalId: number;
  variant?: "shortReject" | "largeReject";
  modelValue?: boolean;
}
const props = withDefaults(defineProps<Props>(), { variant: "shortReject" });

const emit = defineEmits<(e: "update:modelValue", participating: boolean) => void>();

const { participateInMyGoal } = useMeGoalsService();

const switchParticipationHandler = async (): Promise<void> => {
  const success = await participateInMyGoal(props.goalId);
  if (success) {
    emit("update:modelValue", !props.modelValue);
  }
};

const participateButtonVariant = (): "primary" | "secondary" => (!props.modelValue ? "primary" : "secondary");

const participateButtonText = (): string => {
  if (!props.modelValue) {
    return i18n.global.t("pages.goals_challenges.details.participate");
  }
  return props.variant === "shortReject"
    ? i18n.global.t("pages.goals_challenges.details.reject")
    : i18n.global.t("pages.goals_challenges.details.reject_application");
};
</script>
