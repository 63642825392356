import type { Question } from "@/models/question";
import type { Ref } from "vue";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me/questions";
const { get, loading } = useHttpClient();

interface UseMeQuestionsServiceReturn {
  getQuestions: () => Promise<Question[]>;
  loading: Ref<boolean>;
}

export default (): UseMeQuestionsServiceReturn => {
  const getQuestions = (): Promise<Question[]> => get(BASE_URL);

  return { getQuestions, loading };
};
