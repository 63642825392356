<template>
  <div class="pagination-numbers">
    <div
      v-for="pageNumber in numbersToRender"
      :key="pageNumber"
      class="number"
      :class="{ 'number--selected': indexPage === pageNumber - 1 }"
      :data-cy="`page-${pageNumber}`"
      @click="pageNumberClick(pageNumber)">
      <span class="text--bold">{{ pageNumber }}</span>
      <span class="number__underline"></span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { createArray } from "@/utils/numberUtils";

interface Props {
  indexPage: number;
  renderNumbers: number[] | number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "numberClick", value: number) => void>();

const pageNumberClick = (pageNumber: number) => {
  if (pageNumber === props.indexPage + 1) {
    return;
  }
  emit("numberClick", pageNumber);
};
const numbersToRender = computed(() => (Array.isArray(props.renderNumbers) ? props.renderNumbers : createArray(props.renderNumbers)));
</script>
<style scope lang="scss">
.pagination-numbers {
  display: flex;
  flex-direction: row;
  gap: var(--size-s);
}
.number {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &__underline {
    min-width: 20px;
    width: 100%;
    height: 2px;
  }

  &:hover {
    color: var(--color-primary-accent1);
  }

  &--selected {
    color: var(--color-primary-accent1);

    > span:nth-child(2) {
      background-color: var(--color-primary-accent1);
    }

    &:hover {
      color: var(--color-neutral-600);

      > span:nth-child(2) {
        background-color: var(--color-neutral-600);
      }
    }
  }
}
</style>
