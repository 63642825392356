{
  "buttons.next": "Siguiente",
  "buttons.previous": "Anterior",
  "buttons.edit": "Editar",
  "buttons.answer": "Responder",
  "buttons.save": "Guardar",
  "buttons.delete": "Eliminar",
  "buttons.add": "Agregar",
  "buttons.create": "Nuevo",
  "buttons.cancel": "Cancelar",
  "buttons.continue": "Continuar",
  "buttons.save_draft": "Guardar como borrador",
  "buttons.publish": "Publicar",
  "buttons.download": "Descargar",
  "actions.saved": "Información guardada correctamente",
  "avatar.initials": "{first}{last}",
  "skill_range.skill_names.low": "Bajo",
  "skill_range.skill_names.medium": "Medio",
  "skill_range.skill_names.high": "Alto",
  "roles.user": "Empleado",
  "roles.groupmanager": "Mánager de grupo",
  "roles.administrator": "Administrador",
  "roles.itmanager": "IT Mánager",
  "errors.DateShouldBeGreaterThanOrEqualToToday": "La fecha debe ser hoy o más tarde",
  "errors.BirthDateOutOfRange": "Fecha no válida",
  "errors.CompanyEmployeeGroupCourseAlreadyAssigned": "El curso ya ha sido asignado a este grupo",
  "errors.CompanyEmployeeGroupEmployeeNotFound": "No se encontró al empleado. Por favor, actualice la página",
  "errors.CompanyEmployeeGroupExistsWithName": "Ya existe un grupo con ese nombre.",
  "errors.CompanyEmployeeGroupNotFound": "No se encontró el grupo de empleados. Por favor, actualice la página",
  "errors.CompanyEmployeeGroupsNotFound": "No se encontraron varios grupos de empleados",
  "errors.CourseNotFound": "Curso no encontrado",
  "errors.CompanyNotFound": "Compañía no encontrada",
  "errors.EmployeeAlreadyAssigned": "El empleado seleccionado ya está asignado al objetivo",
  "errors.EmployeesCannotBeAddedToAnEndedGoal": "Los empleados no pueden ser asignados a un objetivo finalizado",
  "errors.EmployeesCannotBeRemovedFromAnEndedGoal": "No se puede desasignar a los empleados de un objetivo finalizado",
  "errors.ErrorWhileConnectingToAzure": "No se pudo realizar la conexión a Azure con los datos proporcionados. Confirme que el ID de cliente y el secreto son correctos.",
  "errors.ExpectedLinkedinFilesWereNotFound": "No se encontraron los archivos esperados de LinkedIn. Por favor, asegúrese de que existen archivos de 'Educación', 'Idiomas', 'Posiciones', 'Perfil' o 'Habilidades'",
  "errors.FieldRequired": "Campo obligatorio",
  "errors.FileExtensionNotSupported": "La extensión de archivo no es compatible",
  "errors.FileSizeExceeded": "Tamaño de archivo excedido",
  "errors.GoalCannotBeLaunchedAfterItStarted": "Por favor, actualice la 'Fecha de inicio del objetivo' con una fecha igual o futura que la actual",
  "errors.GoalCannotBeLaunchedWhileApplicationIsOpen": "La fase de aplicación sigue abierta. Por favor, espere a que finalice la fase de solicitud antes de lanzar el objetivo",
  "errors.GoalCannotBeLaunchedWithFewerPeopleThanNeeded": "El número de personas asignadas es menor de lo necesario. Por favor, asigne más personas antes de lanzar el objetivo",
  "errors.GoalPeopleCannotBeGreaterThanTheNumberOfPeopleNeeded": "No se puede superar el número de personas necesarias",
  "errors.GoalResultCannotBeSetUntilItEnds": "El resultado del objetivo no se puede establecer hasta que finalice",
  "errors.GoalResultCannotBeUpdated": "El resultado del objetivo ya se ha establecido y no se puede actualizar",
  "errors.GoalsFinishedCannotBeUpdated": "Los objetivos finalizados no pueden actualizarse",
  "errors.GoalStartDateNotValid": "Fecha de inicio no válida",
  "errors.GoalEndDateNotValid": "Fecha de fin no válida",
  "errors.HobbyAlreadyExistWithName": "Ya existe un hobby con ese nombre.",
  "errors.IdealLearningAlreadyExistsWithTitle": "Ya existe una formación con ese título",
  "errors.InterestAlreadyExistsWithTitle": "Ya existe un interés con ese título",
  "errors.LearningStartDateNotValid": "La fecha de inicio no es válida",
  "errors.QuestionIsAlreadyPublished": "La pregunta ya está publicada",
  "errors.QuestionNotFound": "No se encontró la pregunta",
  "errors.QuestionNotFoundCannotBeAnswered": "No se encontró la pregunta. Por favor, actualice la página",
  "errors.QuestionsWithAnswersCannotBeUpdated": "Las preguntas con respuestas no se pueden actualizar",
  "errors.QuestionsNotFound": "No se encontraron algunas preguntas",
  "errors.RequestNotFound": "No se encontró la habilidad solicitada. Por favor, actualice la página",
  "errors.SkillAlreadyBeingCreatedWithName": "Se está creando una habilidad con ese nombre.",
  "errors.SkillAlreadyExistWithName": "Ya existe una habilidad con ese nombre.",
  "errors.UnexpectedFileFormat": "Se encontró un formato de archivo inesperado",
  "errors.UnknownError": "Error desconocido",
  "errors.UsersInAzureAdExceedsPurchasedUsers": "El número de usuarios encontrados en Azure Active Directory supera el número de usuarios comprados",
  "errors.EmployeeNotFound": "No se encontró al empleado",
  "inputs.a_date_has_to_be_after_another_date": "la fecha '{first}' debe ser posterior a la fecha '{second}'",
  "inputs.date_less_than_or_equal_to_today": "La fecha debe ser anterior o igual a hoy",
  "inputs.date_today_or_later": "La fecha debe ser hoy o más tarde",
  "inputs.DateNotValid": "Fecha no válida",
  "inputs.educationMode.label": "Modo",
  "inputs.educationMode.online.label": "En línea",
  "inputs.educationMode.presence.label": "In situ",
  "inputs.end_date": "Fin",
  "inputs.max_length_exceeded": "El campo debe tener como máximo {maxLength} caracteres",
  "inputs.max_number_items_exceeded": "El campo debe ser como máximo {maxItems} elementos",
  "inputs.max_required": "El campo debe ser como máximo {maximum}",
  "inputs.min_required": "El campo debe ser como mínimo {minimum}",
  "inputs.options.required": "Se requiere la opción {option}",
  "inputs.required": "Campo obligatorio",
  "inputs.start_date": "Inicio",
  "inputs.tags.placeholder": "Escriba aquí para agregar...",
  "inputs.to_date_has_to_be_after_from_date": "La fecha 'hasta' debe ser posterior a la fecha 'desde'",
  "inputs.upload_document.invalid_extension": "El archivo cargado tiene una extensión no válida",
  "inputs.upload_document.maximum_size_MB": "{size} MB tamaño máximo",
  "inputs.upload_document.no_document_uploaded": "Todavía no se ha cargado ningún documento",
  "inputs.upload_document.prompt.delete.description": "Vas a eliminar este archivo. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "inputs.upload_document.prompt.delete.title": "Eliminar archivo",
  "inputs.upload_document.prompt.file_to_big.title": "Archivo demasido grande",
  "inputs.upload_document.prompt.file_to_big.description": "El archivo que intentas cargar es demasiado grande.",
  "inputs.upload_document.supported_types": "Tipos admitidos: {types}",
  "inputs.url_not_valid": "El campo debe ser una URL válida",
  "pages.ai_assistant.title": "Asistente IA",
  "pages.ai_assistant.header.title": "Preguntas a los empleados",
  "pages.ai_assistant.header.subtitle": "Bienvenido a tu asistente de IA. Tu asistente de IA te hará preguntas a través del chatbot, tus respuestas te darán información para brindarte la mejor ruta de aprendizaje y experiencia en Fleebe. Si necesitas modificar tus respuestas, puedes hacerlo aquí en cualquier momento.",
  "pages.ai_assistant.question.level": "Amplía tu respuesta o comparte tus comentarios (ya sean positivos o constructivos)",
  "pages.ai_assistant.question.tags": "Escribe tus opciones (separadas por comas o enter)",
  "pages.ai_assistant.no.questions": "Su administrador aún no ha configurado las preguntas",
  "pages.header.role": "Rol",
  "pages.header.goToAdministratorArea": "Ir al área de administrador",
  "pages.header.goToEmployeeArea": "Ir al área de empleados",
  "pages.header.logout": "Cerrar sesión",
  "pages.header.edit": "Editar",
  "pages.header.picture-uploader.label": "Elige una imagen para tu avatar",
  "pages.header.profile-editor.personal-information.title": "Información personal",
  "pages.header.profile-editor.personal-information.birthDate": "Fecha de nacimiento",
  "pages.header.profile-editor.app-settings.title": "Configuración de la aplicación",
  "pages.header.profile-editor.app-settings.languages.title": "Idioma",
  "pages.header.profile-editor.app-settings.languages.english": "English",
  "pages.header.profile-editor.app-settings.languages.en": "English",
  "pages.header.profile-editor.app-settings.languages.spanish": "Español",
  "pages.header.profile-editor.app-settings.languages.es": "Español",
  "pages.header.profile-editor.app-settings.languages.french": "Français",
  "pages.header.profile-editor.app-settings.languages.fr": "Français",
  "pages.header.profile-editor.app-settings.languages.german": "Deutsch",
  "pages.header.profile-editor.app-settings.languages.italian": "Italiano",
  "pages.header.profile-editor.app-settings.languages.portuguese": "Português",
  "pages.goals_challenges.title": "Objetivos y desafíos",
  "pages.goals_challenges.search.placeholder": "Objetivos y desafíos de búsqueda",
  "pages.goals_challenges.featured.title": "Objetivos y desafíos destacados",
  "pages.goals_challenges.featured.vacant_positions": "Solo {vacantPositions} puestos vacantes",
  "pages.goals_challenges.featured.days_left": "{days} días restantes",
  "pages.goals_challenges.featured.dates": "De {start} a {end}",
  "pages.goals_challenges.my.title": "Mis metas y desafíos",
  "pages.goals_challenges.my.progress": "Progreso",
  "pages.goals_challenges.my.progress_amount": "{progress}%",
  "pages.goals_challenges.no.goals": "Tu administración aún no ha configurado metas",
  "pages.learning.title": "Mi aprendizaje",
  "pages.learning.learning.tab": "Aprendizaje",
  "pages.learning.attachment.label": "Elige un certificado para cargar",
  "pages.learning.search": "Buscar",
  "pages.learning.search.no_results": "No hay cursos con este filtro",
  "pages.learning.search.no_copy": "No copiamos ™tus búsquedas. Esta es un área privada.",
  "pages.learning.search.title": "Resultados de búsqueda para {searchTerm}",
  "pages.learning.featured.title": "Aprendizaje destacado para ti",
  "pages.learning.filter.type.title": "Tipo",
  "pages.learning.filter.type.all": "Todos",
  "pages.learning.filter.type.CourseCoursera": "Coursera",
  "pages.learning.filter.type.CourseEdX": "edX",
  "pages.learning.filter.type.CourseLinkedInLearning": "LinkedIn",
  "pages.learning.filter.type.CourseIntefi": "Intefi",
  "pages.learning.filter.type.CourseCompany": "Formación interna",
  "pages.learning.mode.online": "en línea",
  "pages.learning.mode.onsite": "in situ",
  "pages.learning.rateTitle": "Califica esta formación",
  "pages.learning.rateObservations.placeHolder": "Es ™muy importante para nosotros obtener tus comentarios para personalizar nuestra plataforma de aprendizaje con los mejores cursos adaptados a usted.",
  "pages.learning.sendTraining": "Enviar formación",
  "pages.learning.trainingComplete": "Formación completada",
  "pages.learning.confirmSetTrainingAsComplete.title": "Establecer entrenamiento como completado",
  "pages.learning.confirmSetTrainingAsComplete.text": "Vas a establecer un entrenamiento como completado. Esta acción no se puede deshacer, así que asegúrate de que sea correcta.",
  "pages.learning.goToTheTraining": "Ir a la formación",
  "pages.learning.certificateReminder": "Si tienes algún certificado o diploma que acredite una capacitación terminada, recuerda agregarlo a tu experiencia actual.",
  "pages.learning.siteInformation.title": "Información de la formación in situ",
  "pages.learning.tip.content": "Cuanta más información proporciones a Fleebe Corporate: CV, interés, trabajo ideal ... , más precisa será la recomendación de tu asistente de IA para guiarte hacia tus aspiraciones futuras.",
  "pages.learning.my_learning_path.title": "Mi ruta de aprendizaje",
  "pages.learning.my_learning_path.tab": "Mi ruta de aprendizaje",
  "pages.learning.my_learning_path.introduction.title": " Capacitación de IA Camino a mi trabajo ideal",
  "pages.learning.my_learning_path.introduction.explanation": "Siguiendo los parámetros establecidos en la configuración de tu posición ideal, nuestra IA ha creado un itinerario de entrenamiento que puede ayudarte a conseguir tu objetivo. Recuerde que esta ayuda es solo para orientación y que, en última instancia, tú decides qué es lo mejor para ti",
  "pages.learning.my_learning_path.areas.skills": "Habilidades",
  "pages.learning.my_learning_path.areas.languages": "Idiomas",
  "pages.learning.my_learning_path.areas.financial": "Educación financiera",
  "pages.learning.my_learning_path.levels.beginner": "Principiante",
  "pages.learning.my_learning_path.levels.advanced": "Avanzado",
  "pages.learning.my_learning_path.levels.expert": "Experto",
  "pages.learning.my_learning_path.additional_learning": "Aprendizaje adicional",
  "pages.learning.my_learning_path.no_suggestions": "No hay sugerencias en esta área/nivel",
  "pages.learning.my_learning_path.recalculate.title": "Deseo que se recalcule mi ruta de aprendizaje",
  "pages.learning.my_learning_path.recalculate.activate_explanation": "El recálculo se realizará de forma desatendida durante la noche",
  "pages.learning.my_training.title": "Mi entrenamiento",
  "pages.learning.my_training.tab": "Mi entrenamiento",
  "pages.learning.my_training.tip.content": "Tu camino de aprendizaje no cambiará si no cambias tu trabajo ideal.",
  "pages.learning.my_training.no_completed": "Todavía no tienes ningún entrenamiento completado",
  "pages.my_interests.form.idealLearning.create": "Agregar nueva capacitación",
  "pages.my_interests.form.idealLearning.create.title": "Nueva formación",
  "pages.my_interests.form.idealLearning.delete.content": "Vas a eliminar el entrenamiento seleccionado. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.my_interests.form.idealLearning.delete.title": "Eliminar formación",
  "pages.my_interests.form.idealLearning.edit.title": "Editar entrenamiento",
  "pages.my_interests.form.idealLearning.fields.description.label": "Descripción de la formación",
  "pages.my_interests.form.idealLearning.fields.description.placeholder": "Introduzca una breve descripción",
  "pages.my_interests.form.idealLearning.fields.title.label": "Título de la formación",
  "pages.my_interests.form.idealLearning.fields.title.placeholder": "Introduce un título",
  "pages.my_interests.form.interests.create": "Añade un nuevo interés",
  "pages.my_interests.form.interests.create.title": "Nuevo interés",
  "pages.my_interests.form.interests.delete.content": "Vas a eliminar el interés seleccionado. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.my_interests.form.interests.delete.title": "Eliminar interés",
  "pages.my_interests.form.interests.edit.title": "Editar interés",
  "pages.my_interests.form.interests.fields.description.label": "Descripción del interés",
  "pages.my_interests.form.interests.fields.description.placeholder": "Introduce una breve descripción",
  "pages.my_interests.form.interests.fields.title.label": "Título del interés",
  "pages.my_interests.form.interests.fields.title.placeholder": "Introduce un título",
  "pages.my_interests.idealLearning": "Aprendizaje",
  "pages.my_interests.interests": "Intereses",
  "pages.my_interests.tip.content": "En esta sección te invitamos a completar tus intereses y aficiones. También los aprendizajes que has hecho y que te parecen interesantes para reflexionar.",
  "pages.my_interests.title": "Mis intereses",
  "pages.my_profile.dashboards.successful_skills.title": "Habilidades actuales",
  "pages.my_profile.dashboards.certificates_courses.label": "Últimos 5 certificados y cursos",
  "pages.my_profile.dashboards.certificates_courses.type_learning.online.label": "En línea",
  "pages.my_profile.dashboards.certificates_courses.type_learning.presence.label": "Presencia",
  "pages.my_profile.dashboards.company_goals.last_update.subtitle": "Última actualización, {date}",
  "pages.my_profile.dashboards.company_goals.graph": "Objetivos alcanzados",
  "pages.my_profile.dashboards.successful_skills.general": "General",
  "pages.my_profile.dashboards.successful_skills.language": "Idiomas",
  "pages-my_profile.dashboards.general_skills.title": "Sus habilidades generales",
  "pages-my_profile.dashboards.tips.introduction": "No te preocupes si sigues sin ver nada aquí. Al completar su perfil y con el flujo de trabajo diario aparecerá mucha información.",
  "pages-my_profile.dashboards.tips.no-content": "Completa tu trabajo actual e ideal para completar la tabla a continuación que muestra las habilidades que son relevantes para ti.",
  "pages-my_profile.dashboards.tips.content": "Para agregar una nueva habilidad o modificar sus niveles, visite las secciones Mi trabajo actual o Mi trabajo ideal. Si desea reemplazar una habilidad por otra, puede hacerlo haciendo clic en el botón Editar a continuación.",
  "pages-my_profile.dashboards.performance_graph.no_content_yet": "No tenemos suficiente información para crear el gráfico",
  "pages-my_profile.dashboards.performance_graph.edit": "Editar gráfico de rendimiento",
  "pages-my-profile.profile_updating.tab": "Actualización de perfil",
  "pages-my-profile.profile_updating.welcome_guide.title": "Bienvenido a Fleebe",
  "pages-my-profile.profile_updating.welcome_guide.subtitle": "Tu nueva herramienta de desarrollo profesional",
  "pages-my-profile.profile_updating.welcome_guide.tip": "Los comienzos son difíciles. Así que sigue los siguientes pasos para completar tu perfil de una manera rápida y amigable.",
  "pages-my-profile.profile_updating.welcome_guide.steps.title": "En primer lugar...",
  "pages-my-profile.profile_updating.welcome_guide.steps.1": "Completa tu perfil personal. Ya sabes, nombre, edad, foto personal, trabajo actual, etc. Solo tienes que pasar el cursor sobre el icono del avatar en la esquina superior derecha de la aplicación.",
  "pages-my-profile.profile_updating.welcome_guide.steps.2": "Somos bastante atrevidos y queremos visualizar en un solo gráfico no solo tu grado de adaptación a tu posición actual sino también a una posición ideal creada por ti. ¿Nos puedes ayudar? Sigue los enlaces en las tarjetas en el lado derecho.",
  "pages-my-profile.profile_updating.welcome_guide.steps.3": "Para esto necesitamos dos cosas: primero, enumera las habilidades que mejor describen tu trabajo actual; Y segundo, haz lo mismo pero con las habilidades de tu trabajo ideal, el que te gustaría tener en el futuro. Fácil, ¿verdad?",
  "pages-my-profile.profile_updating.welcome_guide.steps.4": "Si importas tu perfil desde Linkedin, la mayoría de esas habilidades se cargarán automáticamente y solo tendrás que elegir cuál de ellas encaja mejor en los trabajos descritos por ti. Pero, si lo prefieres, puedes hacerlo manualmente.",
  "pages-my-profile.profile_updating.welcome_guide.summary.title": "Y eso es todo",
  "pages-my-profile.profile_updating.welcome_guide.summary.content": "Cuando termines, deberías ver algo como esto. Una forma bastante intuitiva de visualizar tanto su ajuste a su trabajo actual como al ideal.",
  "pages-my-profile.profile_updating.welcome_guide.summary.see_example": "Ver un ejemplo",
  "pages-my-profile.profile_updating.steps_guide.title_1": "Comienza aquí creando tu perfil completo de habilidades",
  "pages-my-profile.profile_updating.steps_guide.title_2": "o completarlo en partes",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.title": "Perfil de habilidades",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.content": "Selecciona las 12 habilidades más relevantes entre todas las habilidades que tienes o desearías.",
  "pages-my-profile.profile_updating.steps_guide.skills_profile.select": "Seleccionar habilidades",
  "pages-my-profile.profile_updating.steps_guide.current_job.title": "Trabajo actual",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_1": "Seleccione las 6 habilidades más relevantes en tu experiencia profesional actual.",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_2": "Selecciona de entre todas las habilidades que definen tu posición actual, las 6 más relevantes para ti.",
  "pages-my-profile.profile_updating.steps_guide.current_job.content_3": "Y si quieres que sea más rápido...",
  "pages-my-profile.profile_updating.steps_guide.current_job.select_current_job_skills": "Seleccionar habilidades laborales actuales",
  "pages-my-profile.profile_updating.steps_guide.current_job.get_linkedin_profile": "Obtén tu perfil de LinkedIn",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.title": "Trabajo ideal",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.content_1": "Selecciona las 6 habilidades que te gustaría desarrollar para tu trabajo ideal.",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.content_2": "Define tu posición ideal y añade todas las habilidades que creas necesarias para realizarla. Luego puedes seleccionar los más importantes.",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.select_ideal_job_skills": " Seleccione tus habilidades laborales ideales",
  "pages-my-profile.profile_updating.steps_guide.ideal_job.go_to_my_ideal_job": "Ir a mi trabajo ideal",
  "pages-my-profile.profile_updating.performance_graph.example.title": "Ejemplo de perfil de marketing",
  "pages-my-profile.profile_updating.performance_graph.example.subtitle": "Aquí puedes ver las habilidades de Megan.",
  "pages-my-profile.profile_updating.performance_graph.example.steps.1": "Las 6 habilidades que coinciden con tu trabajo actual (púrpura)",
  "pages-my-profile.profile_updating.performance_graph.example.steps.2": "Las 6 habilidades que te gustaría desarrollar (azul)",
  "pages-my-profile.profile_updating.performance_graph.example.steps.3": "Las 12 habilidades que coinciden con tu experiencia actual (naranja)",
  "pages-my-profile.profile_updating.performance_graph.example.content_1": "Ya sea que las importes desde Linkedin o las crees manualmente, puede seleccionar las 6 habilidades que mejor definan tu posición actual y deseada para el futuro.",
  "pages-my-profile.profile_updating.performance_graph.example.content_2": "Con este gráfico puedes, de un vistazo, comparar tu talento actual y futuro para gestionar tu formación y plan de carrera en consecuencia. Esto también ayuda a los responsables de Recursos Humanos a ofrecerte las condiciones de trabajo que mejor se adaptan a ti o a detectar, por ejemplo, empleados infravalorados o insatisfechos.",
  "pages-my-profile.profile_updating.performance_graph.example.content_3": "Ayúdanos a ayudarte.",
  "pages-my-profile.profile_updating.performance_graph.example.profile.first_name": "Megan",
  "pages-my-profile.profile_updating.performance_graph.example.profile.last_name": "Barney",
  "pages-my-profile.profile_updating.performance_graph.example.profile.experience": "Gerente de marketing digital",
  "pages-my-profile.profile_updating.performance_graph.example.close": "Entendido",
  "pages-my-profile.profile_updating.performance_graph.graph.series.1": "Habilidades laborales actuales",
  "pages-my-profile.profile_updating.performance_graph.graph.series.2": "Habilidades laborales ideales",
  "pages-my-profile.profile_updating.performance_graph.graph.series.3": "Habilidades de experiencia actual",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.0": "Ninguno",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.1": "Bajo",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.2": "Medio",
  "pages-my-profile.profile_updating.performance_graph.graph.labels.3": "Alto",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.1": "Tomar la iniciativa",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.2": "Idiomas",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.3": "Analítico",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.4": "Creativo",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.5": "Resolución de problemas",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.6": "Actitud positiva",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.7": "Supporting",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.8": "Empatía",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.9": "Social Media",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.10": "Dirección",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.11": "Marketing",
  "pages-my-profile.profile_updating.performance_graph.example.graph.categories.12": "Aprendizaje",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction": "Introducción",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_1": "Para completar tu tabla de perfil, debes completar las 2 secciones principales sobre ti: Experiencia actual y Trabajo ideal. Una vez completadas estas secciones, tendrás a tu disposición todas las habilidades que necesitas para calificar y construir tu visualización de arañas.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.introduction.info_2": "* Recuerda que siempre podrás editar tus habilidades y revisarlas durante todo tu proceso de crecimiento y aprendizaje.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills": "Seleccionar mis habilidades laborales actuales",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.current_job_skills.info": "Selecciona un mínimo de 3 habilidades y un máximo de 6 habilidades más importantes en mi trabajo actual.",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills": "Selecciona mis habilidades laborales ideales",
  "pages-my-profile.profile_updating.performance_graph.configurer.step.ideal_job_skills.info": "Selecciona un mínimo de 3 habilidades y un máximo de 6 habilidades que te gustaría desarrollar para tu trabajo ideal.",
  "pages-my-profile.profile_updating.performance_graph.configurer.errors.skills_count": "Es necesario que elijas entre 3 y 6 habilidades para construir tdu gráfico de rendimiento.",
  "pages-my-profile.profile_updating.performance_graph.configurer.search.placeholder": "Buscar una habilidad",
  "pages-my-profile.profile_updating.performance_graph.configurer.current_job_requirements": "Debe tener un trabajo actual con al menos 3 habilidades para poder crear este gráfico.",
  "pages-my-profile.profile_updating.performance_graph.configurer.go_to_current_job": "Ir a Mi trabajo actual",
  "pages-my-profile.profile_updating.performance_graph.configurer.ideal_job_requirements": "Debe tener un trabajo ideal con al menos 3 habilidades para poder crear este gráfico.",
  "pages-my-profile.profile_updating.performance_graph.configurer.go_to_ideal_job": "Ir a Mi trabajo ideal",
  "pages-my-profile.dashboard.tab": "Tablero",
  "pages.my_profile.title": "Mi perfil",
  "pages.my_profile.employee_groups.title": "Grupos",
  "pages.my_profile.previous_experiences.title": "Experiencias previas",
  "pages.current_experience.title": "Mi trabajo actual",
  "pages.current_experience.about_me": "Acerca de mí",
  "pages.current_experience.experience": "Otra experiencia",
  "pages.current_experience.education": "Educación",
  "pages.current_experience.languages": "Idiomas",
  "pages.current_experience.languages.edit": "Editar idiomas",
  "pages.current_experience.current_experience.title": "Posición actual",
  "pages.current_experience.current_experience.general_skills": "Habilidades generales",
  "pages.current_experience.current_experience.language_skills": "Habilidades lingüísticas ",
  "pages.current_experience.goals_challenges.title": "Mis objetivos y desafíos",
  "pages.current_experience.linkedin.import": "Importar mi perfil de LinkedIn",
  "pages.current_experience.linkedin.title": "Importa tu perfil de LinkedIn",
  "pages.current_experience.linkedin.content.instructions.title": "Siga las instrucciones a continuación para importar tu perfil de LinkedIn.",
  "pages.current_experience.linkedin.content.instructions.step.1": "<span class=\"text text--bold\">Haga clic en este enlace</span> para acceder a tu perfil de LinkedIn (inicie sesión si es necesario): ",
  "pages.current_experience.linkedin.content.instructions.step.2": "<span class=\"text text--bold\">Configure el contenido que desea importar</span>. LinkedIn te ofrece algunas alternativas.",
  "pages.current_experience.linkedin.content.instructions.step.3": "Haga clic en el botón <span class=\"text text--bold\">Request file</span> (la descarga estará lista en aproximadamente <span class=\"text text--bold\">24 horas</span>).",
  "pages.current_experience.linkedin.content.instructions.step.4": "Dos <span class=\"text text--bold\">emails</span> te enviará las instrucciones que debes seguir. El <span class=\"text text--bold\">first one</span> contiene la información que necesitas cargar aquí. El segundo es sobre tu actividad en LinkedIn y el historial de tu cuenta, y no lo necesitamos.",
  "pages.current_experience.linkedin.content.instructions.step.5": "<span class=\"text text--bold\">Download</span> el archivo.",
  "pages.current_experience.linkedin.content.instructions.step.6": "<span class=\"text text--bold\">Haga clic en el botón de abajo</span> y seleccione el archivo .zip descargado de su computadora.",
  "pages.current_experience.linkedin.content.instructions.step.7": "<span class=\"text text--bold\">And that's all</span>. Si todo va bien, todos tus datos se colocarán aquí. Comprueba si tu experiencia actual está bien. Si no, puedes editarlo en LinkedIn y repetir la operación (recomendado) o editarlo dentro de esta aplicación haciendo clic en el botón Editar, junto a cada experiencia.",
  "pages.current_experience.linkedin.content.upload": "Navegar para subir el perfil de LinkedIn",
  "pages.current_experience.linkedin.successfully_imported_profile": "Perfil importado correctamente",
  "pages.current_experience.linkedin.reminder.content": "Verifique las fechas de tus posiciones e introduce el nivel de tus habilidades, y por último define si tu educación ha sido en línea o en el sitio.",
  "pages.current_experience.tip.content": "En esta sección es importante que subas tu currículum, la experiencia será más personalizada si conocemos el punto de partida. Por otro lado, debes diferenciar entre las habilidades que has generado durante tu vida profesional y las habilidades aprendidas en tu puesto actual al rellenar los campos.",
  "pages.current_experience.skills": "Habilidades",
  "pages.current_experience.skills.management": "Habilidades directivas",
  "pages.current_experience.skills.general": "General",
  "pages.current_experience.skills.edit": "Editar habilidades generales",
  "pages.current_experience.language.edit": "Editar habilidades lingüísticas",
  "pages.current_experience.confirm-delete-on-graph.title": "Eliminar habilidad que se está utilizando en el gráfico de rendimiento",
  "pages.current_experience.confirm-delete-on-graph.message": "Está intentando eliminar una habilidad que se está utilizando en el gráfico de rendimiento. Si después de eliminar esta habilidad quedan menos de 3 habilidades no se creará el gráfico.",
  "pages.current_experience.present": "Presente",
  "pages.current_experience.edit.about.me": "Cuéntame algo sobre ti",
  "pages.current_experience.form.about.me.fields.name.label": "Acerca de ti",
  "pages.current_experience.form.about.me.fields.name.placeholder": "Cuéntame algo sobre ti",
  "pages.current_experience.form.curriculum.upload": "Sube tu currículum",
  "pages.current_experience.form.curriculum.delete.prompt.title": "Eliminar currículo",
  "pages.current_experience.form.curriculum.delete.prompt.content": "Va a eliminar el currículo. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.current_experience.form.experience.edit_current": "Edita tu posición actual",
  "pages.current_experience.form.experience.edit_other": "Edita tu puesto de trabajo",
  "pages.current_experience.form.experience.create_current": "Agregar trabajo actual",
  "pages.current_experience.form.experience.create_other": "Agregar nueva experiencia",
  "pages.current_experience.form.experience.fields.title.label": "Título",
  "pages.current_experience.form.experience.fields.title.placeholder": "Introduce el puesto de trabajo",
  "pages.current_experience.form.experience.fields.description.label": "Descripción del trabajo",
  "pages.current_experience.form.experience.fields.description.placeholder": "Introduce la descripción del trabajo",
  "pages.current_experience.form.experience.fields.company.label": "Empresa",
  "pages.current_experience.form.experience.fields.company.placeholder": "Introduce la empresa",
  "pages.current_experience.form.experience.fields.location.label": "Ubicación",
  "pages.current_experience.form.experience.fields.location.placeholder": "Introduce la ubicación del trabajo",
  "pages.current_experience.form.experience.fields.fromDate.label": "Iniciar",
  "pages.current_experience.form.experience.fields.toDate.label": "Fin",
  "pages.current_experience.form.experience.fields.currentJob.label": "Trabajo actual",
  "pages.current_experience.form.experience.delete.title": "Eliminar experiencia",
  "pages.current_experience.form.experience.delete.content": "Vas a eliminar la experiencia seleccionada. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.current_experience.form.education.edit": "Edita tu educación",
  "pages.current_experience.form.education.create": "Agregar nueva educación",
  "pages.current_experience.form.education.fields.title.label": "Título educativo",
  "pages.current_experience.form.education.fields.title.placeholder": "Introduce un título",
  "pages.current_experience.form.education.fields.institution.label": "Institución",
  "pages.current_experience.form.education.fields.institution.placeholder": "Introduce una institución",
  "pages.current_experience.form.education.fields.description.label": "Descripción",
  "pages.current_experience.form.education.fields.description.placeholder": "Introduce una descripción",
  "pages.current_experience.form.education.fields.location.label": "Ubicación",
  "pages.current_experience.form.education.fields.location.placeholder": "Introduce una ubicación",
  "pages.current_experience.form.education.fields.fromDate.label": "De",
  "pages.current_experience.form.education.fields.toDate.label": "Para",
  "pages.current_experience.form.education.fields.inProgressEducation.label": "En curso",
  "pages.current_experience.form.education.delete.title": "Eliminar educación",
  "pages.current_experience.form.education.delete.content": "Vas a eliminar la educación seleccionada. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.guest.create_company_from_azure_ad.information.title": "Guía de registro desde Azure Active Directory (Ad)",
  "pages.guest.create_company_from_azure_ad.information.content": "<h3>Para generar la entidad de servicio, vaya a Azure AD y siga los pasos siguientes:</h3><ol class=\"margin-l--m\" style=\"list-style:inside;list-style-position:outside\"><li class=\"margin-v--xs\">Vaya a Azure Active Directory y crea una nueva aplicación en Registro de aplicaciones.</li><li class=\"margin-v--xs\">Escribe un nombre en el campo 'Nombre'.\n\n</li><li class=\"margin-v--xs\">En Tipos de cuenta compatibles, seleccione la opción 'Inquilino único' y haga clic en el botón 'Registrar'.</li><li class=\"margin-v--xs\">Cuando tu aplicación ya esté creada, vaya a la sección 'Permisos de API' y agrega una nueva de tipo 'User.Read.All'.</li><li class=\"margin-v--xs\">Ahora necesitas agregar un secreto de cliente,  Recomendamos 24 meses de fecha de vencimiento. Ahora copie el secreto de cliente.</li></ol><h3>Pasos para conectarse con Fleebe:</h3><ol class=\"margin-l--m\" style=\"list-style:inside;list-style-position:outside\"><li class=\"margin-v--xs\">Copie el 'ID de cliente de aplicación' del paso anterior en el campo 'Id. de cliente de entidad de servicio'.</li><li class=\"margin-v--xs\">Copie el 'secreto de cliente' en el campo 'Secreto de cliente de entidad de servicio'.</li> <li class=\"margin-v--xs\">Haga clic en el botón 'Validar' y debería ver el número de usuarios de su AD.</li></ol>",
  "pages.guest.create_company_from_azure_ad.form.fields.tenant_id.label": "ID. de inquilino",
  "pages.guest.create_company_from_azure_ad.form.fields.purchaseSubscriptionId.label": "ID. de suscripción de compra",
  "pages.guest.create_company_from_azure_ad.form.fields.client_id.label": "ID. de cliente principal de servicio",
  "pages.guest.create_company_from_azure_ad.form.fields.client_id.placeholder": "Escriba el identificador de entidad de servicio",
  "pages.guest.create_company_from_azure_ad.form.fields.client_secret.label": "Secreto de cliente principal de servicio",
  "pages.guest.create_company_from_azure_ad.form.fields.client_secret.placeholder": "Escriba el secreto de entidad de servicio",
  "pages.guest.create_company_from_azure_ad.form.fields.total_users.label": "La siguiente cantidad de usuarios se importará desde Azure Active Directory después del proceso de registro",
  "pages.guest.create_company_from_azure_ad.form.buttons.validate": "Validar",
  "pages.guest.create_company_from_azure_ad.form.buttons.signup": "Iniciar sesión",
  "pages.guest.create_company_from_azure_ad.error_modal.title": "La subscripción no se ha completado",
  "pages.guest.create_company_from_azure_ad.error_modal.description": "Lo sentimos, no se ha podido completar la subscripción debido a que la cantidad de usuarios comprados ({purchasedUsers}) no coincide con la cantidad de usuarios encontrados ({totalUsers}) en Azure Active Directory. Por favor, reinicie el proceso de compra.",
  "pages.guest.create_company_from_azure_ad.error_modal.button": "Reiniciar compra en Microsoft AppSource",
  "pages.accounts.logout.title": "Su sesión finalizó",
  "pages.accounts.logout.description": "Has finalizado tu sesión. Para volver de nuevo, haga clic en el botón.",
  "pages.accounts.logout.signin": "Iniciar sesión en Fleebe Corporate",
  "pages.accounts.admin_required.title": "No hay suficientes permisos",
  "pages.accounts.admin_required.description": "Debe ser administrador para ingresar a la página solicitada. Para continuar usando el sitio, haga clic en el botón.",
  "pages.accounts.admin_required.continue": "Vuelve a Fleebe Corporate",
  "pages.accounts.tenant_unauthorized.title": "Arrendatario no autorizado",
  "pages.accounts.tenant_unauthorized.description": "La cuenta que está utilizando para iniciar sesión no está registrada en la aplicación. Por favor, confirme que esté registrado para usar Fleebe Corporate y continúe.",
  "pages.accounts.tenant_unauthorized.continue": "Vuelve a Fleebe Corportae",
  "pages.accounts.user_unauthorized.title": "Usuario no autorizado",
  "pages.accounts.user_unauthorized.description": "La cuenta que está utilizando para iniciar sesión no está permitida en la aplicación. Confirme que esté registrado para usar Fleebe Corporate y continúe. Si tiene varias cuentas, intente cerrar sesión e iniciar sesión de nuevo.",
  "pages.accounts.user_unauthorized.logout": "Cerrar sesión",
  "pages.admin.dashboard.current_job.title": "Trabajo actual",
  "pages.admin.dashboard.ideal_job.title": "Trabajo ideal",
  "pages.admin.dashboard.title": "Dashboard",
  "pages.admin.dashboard.percentage_employee_answered": "% de empleados respondidos",
  "pages.admin.dashboard.welcome": "Bienvenido a Fleebe Corporate",
  "pages.admin.dashboard.welcome.tip.title": "Empieza donde quieras",
  "pages.admin.dashboard.welcome.tip.content": "Comienza la experiencia con tu asistente de IA cuando lo desees. Completa las cuatro tarjetas generales debajo de estas infobox.",
  "pages.admin.dashboard.welcome.shortcuts.profile.title": "Completa el perfil de tu organización",
  "pages.admin.dashboard.welcome.shortcuts.profile.content": "Rellena todos los datos corporativos de tu empresa con sus principales características: ¿Qué tan grande es tu organización? ¿Dónde se encuentra?",
  "pages.admin.dashboard.welcome.shortcuts.profile.button": "Perfil completo de la empresa",
  "pages.admin.dashboard.welcome.shortcuts.skill.title": "Crea las habilidades que tu organización necesita",
  "pages.admin.dashboard.welcome.shortcuts.skill.content": "Tu organización es diferente y única, por lo tanto, tiene necesidades específicas que bien sabe el administrador. Créalos para empezar a buscar talento",
  "pages.admin.dashboard.welcome.shortcuts.skill.button": "Crear habilidades",
  "pages.admin.dashboard.welcome.shortcuts.question.title": "Crear un formulario para obtener comentarios de los empleados",
  "pages.admin.dashboard.welcome.shortcuts.question.content": "Tus empleados son talentosos, por lo tanto, su opinión es valiosa. Cree aquí un formulario para obtener sus comentarios.",
  "pages.admin.dashboard.welcome.shortcuts.question.button": "Crear formulario",
  "pages.admin.dashboard.welcome.shortcuts.goal.title": "Crea objetivos y desafíos para su organización",
  "pages.admin.dashboard.welcome.shortcuts.goal.content": "Mantén motivados a tus equipos y empleados, crea objetivos y metas comunes que aporten valor a la empresa.",
  "pages.admin.dashboard.welcome.shortcuts.goal.button": "Crear objetivos y desafíos",
  "pages.admin.dashboard.search.introduction": "La nueva asistencia de IA para ayudar a tus empleados a aprender la ruta hacia sus propias preferencias y estilo de vida",
  "pages.admin.dashboard.search.placeholder": "Buscar empleados...",
  "pages.admin.dashboard.current_experience.title": "Experiencia actual",
  "pages.admin.dashboard.current_experience.about_me.curriculum": "Curriculum",
  "pages.admin.dashboard.current_experience.about_me.no_content_yet": "El empleado aún no ha proporcionado información",
  "pages.admin.dashboard.current_experience.experience.no_content_yet": "El empleado aún no ha proporcionado información sobre la experiencia",
  "pages.admin.dashboard.current_experience.education.no_content_yet": "El empleado aún no ha proporcionado información educativa",
  "pages.admin.dashboard.current_experience.languages.no_content_yet": "El empleado aún no ha proporcionado idiomas",
  "pages.admin.dashboard.current_experience.general_skills.no_content_yet": "El empleado aún no ha proporcionado habilidades generales",
  "pages.admin.dashboard.my_ideal_job.no_content_yet": "El empleado aún no ha proporcionado la información de trabajo ideal",
  "pages.admin.dashboard.ai_assistant.title": "Asistente de IA",
  "pages.admin.dashboard.ai_assistant.description": "Estas son las respuestas proporcionadas por el empleado",
  "pages.admin.dashboard.interests.title": "Intereses",
  "pages.admin.dashboard.interests.interests.no_content_yet": "El empleado aún no ha proporcionado información de interés",
  "pages.admin.dashboard.interests.idealLearning.no_content_yet": "El empleado aún no ha proporcionado información de aprendizaje",
  "pages.admin.dashboard.interests.hobbies.no_content_yet": "El empleado aún no ha proporcionado pasatiempos",
  "pages.admin.ai_assistant.title": "Asistente de IA",
  "pages.admin.ai_assistant.create_new_survey_form.title": "Crear nuevo formulario de encuesta",
  "pages.admin.ai_assistant.create_new_survey_form.description": "Configura las preguntas para tu empresa y elija cuáles de ellas deben ser visibles para tus empleados",
  "pages.admin.ai_assistant.create_new_survey_form.new_question": "Agregar nueva pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.title": "Nivel",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.description": "Utilice este componente para que el empleado seleccione un nivel de educación, experiencia o talento, etc. rápidamente.",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.guide": "Trate de hacer la declaración lo más simple posible para que las respuestas no sean ambiguas y la información proporcionada realmente agregue valor. Establece niveles claros y bien contrastados para que no haya dudas en la respuesta.",
  "pages.admin.ai_assistant.create_new_survey_form.question.level.input.title": "Introduzca la declaración de la pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.title": "Selección única",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.description": "Utiliza este componente para seleccionar una sola opción entre varias disponibles.",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.guide": "Trata de hacer la declaración lo más simple posible para que las respuestas no sean ambiguas y la información proporcionada realmente agregue valor. Establece opciones claras y bien contrastadas para que no haya dudas en la respuesta.",
  "pages.admin.ai_assistant.create_new_survey_form.question.singleselection.input.title": "Introduce la declaración de la pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.title": "Selección múltiple",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.description": "Utilice este componente para seleccionar varias opciones correctas.",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.guide": "Trata de hacer la declaración lo más simple posible para que las respuestas no sean ambiguas y la información proporcionada realmente agregue valor. Establece opciones claras y bien contrastadas para que no haya dudas en la respuesta.",
  "pages.admin.ai_assistant.create_new_survey_form.question.multipleselection.input.title": "Introduzca la declaración de la pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.title": "Respuesta abierta larga",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.description": "Utiliza este tipo de componentes para respuestas abiertas donde el empleado puede hacer descripciones más largas.",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.guide": "Trata de hacer la declaración lo más clara y simple posible para que las respuestas no sean ambiguas y la información proporcionada realmente agregue valor.",
  "pages.admin.ai_assistant.create_new_survey_form.question.longtext.input.title": "Introduce la declaración de la pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.title": "Respuesta abierta corta",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.description": "Usar este componente para respuestas cortas.",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.guide": "Trata de hacer la declaración lo más clara y simple posible para que las respuestas no sean ambiguas y la información proporcionada realmente agregue valor.",
  "pages.admin.ai_assistant.create_new_survey_form.question.shorttext.input.title": "Introduce la declaración de la pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.title": "Etiquetas",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.description": "Utiliza este componente cuando el empleado tenga que introducir respuestas en forma de etiquetas.",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.guide": "Trata de hacer la declaración lo más simple posible para que las respuestas no sean ambiguas y la información proporcionada realmente agregue valor. Establece opciones claras y bien contrastadas para que no haya dudas en la respuesta.",
  "pages.admin.ai_assistant.create_new_survey_form.question.tags.input.title": "Introduce la declaración de la pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.select_question_type": "Seleccionar tipo de pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.configure_question": "Configurar pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question.path.save_question": "Guardar pregunta",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.options.title": "Introduce las opciones de respuesta",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.options.description": "Necesitas al menos dos opciones, pero puede agregar todas las que necesites.",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.option.title": "Option {optionIndex}",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.option.placeholder": "Título de la opción",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.title": "Introduzce los niveles de respuesta",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.low_level": "Nivel bajo",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.medium_level": "Nivel medio",
  "pages.admin.ai_assistant.create_new_survey_form.question_configure.levels.high_level": "Nivel alto",
  "pages.admin.ai_assistant.create_new_survey_form.preview.title": "Vista previa",
  "pages.admin.ai_assistant.create_new_survey_form.preview.description": "La pregunta tiene errores en la configuración. Vaya al paso anterior y configúralo correctamente.",
  "pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample1": "tag 1",
  "pages.admin.ai_assistant.create_new_survey_form.preview.tag_samples.sample2": "tag 2",
  "pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample1": "opción 1",
  "pages.admin.ai_assistant.create_new_survey_form.preview.option_samples.sample2": "opción 2",
  "pages.admin.ai_assistant.kpi_tip.title": "Crea KPIs",
  "pages.admin.ai_assistant.kpi_tip.subtitle": "Puedes crear KPIs de los cuestionarios enviados para visualizar el rango de respuestas. Selecciona el ícono del gráfico para ver los resultados de cada pregunta.",
  "pages.admin.ai_assistant.questions.title": "Preguntas de encuesta",
  "pages.admin.ai_assistant.questions.seeAllQuestions": "Ver todas las preguntas",
  "pages.admin.ai_assistant.questions.search": "Buscar en preguntas",
  "pages.admin.ai_assistant.questions.created_questions": "Preguntas creadas",
  "pages.admin.ai_assistant.questions.sent_questions": "Preguntas enviadas",
  "pages.admin.ai_assistant.questions.send_questions": "Enviar preguntas",
  "pages.admin.ai_assistant.questions.do_not_send_questions": "No enviar preguntas",
  "pages.admin.ai_assistant.questions.add_new_question": "Agregar nueva pregunta",
  "pages.admin.ai_assistant.questions.preview": "Vista previa de la pregunta",
  "pages.admin.ai_assistant.questions.grid.number": "#",
  "pages.admin.ai_assistant.questions.grid.answeredPercentage": "Participación(%)",
  "pages.admin.ai_assistant.questions.grid.groups": "Grupos",
  "pages.admin.ai_assistant.questions.grid.groups.tooltip.more_groups": "{n} más",
  "pages.admin.ai_assistant.questions.grid.groups.tooltip.groups": "{n} grupo | {n} grupos",
  "pages.admin.ai_assistant.questions.grid.question": "Pregunta",
  "pages.admin.ai_assistant.questions.grid.date": "Fecha",
  "pages.admin.ai_assistant.questions.grid.no_results": "No hay preguntas con este filtro",
  "pages.admin.ai_assistant.questions.grid.delete.title": "Eliminar pregunta",
  "pages.admin.ai_assistant.questions.grid.delete.description": "Vas a eliminar la pregunta '{question}'. ¿Estás seguro?",
  "pages.admin.ai_assistant.questions.grid.show_in_dashboard": "Mostrar en el panel",
  "pages.admin.ai_assistant.questions.grid.remove__from_dashboard": "Eliminar del panel",
  "pages.admin.ai_assistant.questions.grid.descend": "Descenso",
  "pages.admin.ai_assistant.questions.grid.ascend": "Ascender",
  "pages.admin.ai_assistant.questions.questions_with_no_answers": "Esta pregunta aún no ha sido respondida",
  "pages.admin.ai_assistant.questions.employee_answered": "Participación",
  "pages.admin.ai_assistant.questions.othersSummary": "Otros",
  "pages.admin.ai_assistant.questions.noContentYet": "Aún no hay contenido",
  "pages.admin.ai_assistant.questions.allQuestionsPublished": "Todas las preguntas definidas son públicas",
  "pages.admin.company_goals.application_closed": "Aplicaciones cerradas",
  "pages.admin.company_goals.draft": "Borrador",
  "pages.admin.company_goals.application_open": "Abierto para aplicación",
  "pages.admin.company_goals.title": "Objetivos corporativos",
  "pages.admin.company_goals.create.public": "Crear un objetivo público para los solicitantes",
  "pages.admin.company_goals.create.public.description": "Lanzamos este nuevo objetivo con el objetivo de desarrollar todo el potencial de cada uno de nuestros empleados mientras nos enfocamos en sus necesidades y habilidades. Sé quien elabore el plan de transformación de acuerdo con tus objetivos personales y profesionales",
  "pages.admin.company_goals.create.new_public_goal": "Crear un objetivo público",
  "pages.admin.company_goals.create.private": "Crear un objetivo privado y seleccionar un equipo",
  "pages.admin.company_goals.create.private.description": "Hemos identificado las habilidades que necesitas para avanzar en tu carrera. Esta meta propuesta te permitirá desarrollarlas o fortalecerlas. La transformación continua es esencial para la evolución de tu carrera profesional.",
  "pages.admin.company_goals.create.new_private_goal": "Crear un objetivo privado",
  "pages.admin.company_goals.search.introduction": "Nuevos objetivos y desafíos",
  "pages.admin.company_goals.search.placeholder": "Buscar objetivos para el equipo",
  "pages.admin.company_goals.search.options.view_all": "Todos",
  "pages.admin.company_goals.search.options.view_visibility": "Visibilidad",
  "pages.admin.company_goals.search.options.view_public": "Público",
  "pages.admin.company_goals.search.options.view_private": "Privado",
  "pages.admin.company_goals.search.options.view_application_phase": "Fase de aplicación",
  "pages.admin.company_goals.search.options.view_application_phase_open": "Abierto",
  "pages.admin.company_goals.search.options.view_application_phase_closed": "Cerrado",
  "pages.admin.company_goals.search.options.view_finished": "Terminado",
  "pages.admin.company_goals.search.options.view_draft": "Borrador",
  "pages.admin.company_goals.search.options.view_launched": "Lanzado",
  "pages.admin.company_goals.search.options.view_published": "Publicado",
  "pages.admin.company_goals.search.options.view_status": "Estado",
  "pages.admin.company_goals.search.order.start_date_asc": "Fecha de inicio (asc)",
  "pages.admin.company_goals.search.order.start_date_desc": "Fecha de inicio (desc)",
  "pages.admin.company_goals.search.order.end_date_asc": "Fecha de finalización (asc)",
  "pages.admin.company_goals.search.order.end_date_desc": "Fecha de finalización (desc)",
  "pages.admin.company_goals.search.order.title_asc": "Título (asc)",
  "pages.admin.company_goals.search.order.title_desc": "Título (desc)",
  "pages.admin.company_goals.new_goal": "Agregar nuevo objetivo",
  "pages.admin.company_goals.last_updated": "Última actualización, {date}",
  "pages.admin.company_goals.goal_dates": "Fechas de gol: {start} - {end}",
  "pages.admin.company_goals.goal": "Equipo de gol",
  "pages.admin.company_goals.save": "Salvar equipo y gol",
  "pages.admin.company_goals.skills": "{number} habilidades",
  "pages.admin.company_goals.education": "{number} educación",
  "pages.admin.company_goals.languages": "{number} lenguajes",
  "pages.admin.company_goals.delete_goal.title": "Eliminar objetivo: {name}",
  "pages.admin.company_goals.delete_goal.description": "Va a eliminar el objetivo seleccionado <span class=\"text text--bold\">\"{name}\"</span>. Todo el progreso y la información sobre este desaparecerá y esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.admin.company_goals.delete_goal.successfully_deleted": "El objetivo se eliminó correctamente",
  "pages.admin.company_goals.add_new_goal.title": "Nuevo objetivo",
  "pages.admin.company_goals.add_new_goal.fields.title.label": "Título",
  "pages.admin.company_goals.add_new_goal.fields.title.placeholder": "Introduce el título del objetivo o desafío",
  "pages.admin.company_goals.add_new_goal.fields.description.label": "Descripción del objetivo",
  "pages.admin.company_goals.add_new_goal.fields.description.placeholder": "Escriba aquí una breve descripción",
  "pages.admin.company_goals.add_new_goal.fields.link.label": "Enlace con más información",
  "pages.admin.company_goals.add_new_goal.fields.link.placeholder": "Agregar un enlace con más información",
  "pages.admin.company_goals.add_new_goal.fields.number_of_people_needed.label": "Número de personas necesarias",
  "pages.admin.company_goals.add_new_goal.fields.country.label": "País",
  "pages.admin.company_goals.add_new_goal.fields.country.placeholder": "Introduce el país",
  "pages.admin.company_goals.add_new_goal.fields.city.label": "Ciudad",
  "pages.admin.company_goals.add_new_goal.fields.city.placeholder": "Introducir ciudad",
  "pages.admin.company_goals.add_new_goal.fields.application_closing.label": "Cierre de la aplicación",
  "pages.admin.company_goals.add_new_goal.fields.is_open.label": "Objetivo abierto para aplicaciones",
  "pages.admin.company_goals.add_new_goal.fields.start.label": "Iniciar",
  "pages.admin.company_goals.add_new_goal.fields.end.label": "fin",
  "pages.admin.company_goals.add_new_goal.fields.status.label": "Objetivo abierto para aplicaciones",
  "pages.admin.company_goals.add_new_goal.fields.picture.label": "Elige una imagen para la portada",
  "pages.admin.company_goals.add_new_goal.fields.picture.upload": "Subir imagen",
  "pages.admin.company_goals.add_new_goal.fields.skills_needed": "Habilidades necesarias",
  "pages.admin.company_goals.add_new_goal.path.overview": "Información general",
  "pages.admin.company_goals.add_new_goal.path.general_skills": "Habilidades generales",
  "pages.admin.company_goals.add_new_goal.path.general_skills.description": "Elige las habilidades generales requeridas para el objetivo",
  "pages.admin.company_goals.add_new_goal.path.language_skills": "Habilidades lingüísticas",
  "pages.admin.company_goals.add_new_goal.path.language_skills.description": "Por favor, elige las habilidades lingüísticas necesarias para el objetivo",
  "pages.admin.company_goals.add_new_goal.save": "Publicar",
  "pages.admin.company_goals.add_new_goal.successfully_created": "El objetivo se ha creado correctamente",
  "pages.admin.company_goals.add_new_goal.successfully_updated": "El objetivo se ha actualizado correctamente",
  "pages.admin.company_goals.add_new_goal.confirm_publish.title": "Confirmar publicación",
  "pages.admin.company_goals.add_new_goal.confirm_publish.description": "Vas a publicar el objetivo. Después de este punto, no podrá editar algunos de los campos. Por favor, continúe solo si está seguro de que el objetivo está listo.",
  "pages.admin.company_goals.edit_goal.fields.title.label": "Título",
  "pages.admin.company_goals.edit_goal.fields.title.placeholder": "Introduce el título del objetivo o desafío",
  "pages.admin.company_goals.edit_goal.fields.description.label": "Descripción del objetivo",
  "pages.admin.company_goals.edit_goal.fields.description.placeholder": "Escribe aquí una breve descripción",
  "pages.admin.company_goals.edit_goal.fields.number_of_people_needed.label": "Número de personas necesarias",
  "pages.admin.company_goals.edit_goal.fields.country.label": "País",
  "pages.admin.company_goals.edit_goal.fields.city.label": "Ciudad",
  "pages.admin.company_goals.edit_goal.fields.link.label": "Enlace con más información",
  "pages.admin.company_goals.edit_goal.fields.link.placeholder": "Agregar un enlace con más información",
  "pages.admin.company_goals.edit_goal.fields.application_closing.label": "Cierre de la aplicación",
  "pages.admin.company_goals.edit_goal.fields.is_open.label": "El objetivo está abierto para aplicaciones",
  "pages.admin.company_goals.edit_goal.fields.is_not_open.label": "El objetivo no está abierto para aplicaciones",
  "pages.admin.company_goals.edit_goal.fields.start.label": "Iniciar",
  "pages.admin.company_goals.edit_goal.fields.end.label": "Fin",
  "pages.admin.company_goals.edit_goal.save": "Guardar",
  "pages.admin.company_goals.edit_goal.successfully_edited": "El objetivo se ha editado correctamente",
  "pages.admin.company_goals.detail_goals.more_information": "Más información",
  "pages.admin.company_goals.detail_goals.progress": "Progreso del objetivo del equipo",
  "pages.admin.company_goals.detail_goals.team_skills.title": "Porcentaje de adaptación al objetivo",
  "pages.admin.company_goals.detail_goals.team_skills.skills": "Habilidades",
  "pages.admin.company_goals.detail_goals.team_skills.skills.description": "Más del 80% de las habilidades se considera un buen ratio para alcanzar los objetivos propuestos. Por debajo del 50% es aconsejable reclutar o reemplazar miembros con otros con las habilidades específicas necesarias.",
  "pages.admin.company_goals.goal_detail.add_team_member": "Agregar un miembro del equipo de la lista de solicitantes",
  "pages.admin.company_goals.goal_detail.ai_selected_candidate": "La IA ha elegido un equipo automáticamente, pero puedes editarlo manualmente. El objetivo no comenzará hasta que valides al equipo.",
  "pages.admin.company_goals.goal_detail.ai_will_filter_candidates": "Recién finalizada la fecha de solicitud, el motor de IA filtrará a los solicitantes automáticamente para seleccionar los que sean la mejor opción. Entonces, solo verás el número de solicitantes necesarios para crear el equipo para el objetivo.",
  "pages.admin.company_goals.goal_detail.application_phase.tag.closed": "Cerrado",
  "pages.admin.company_goals.goal_detail.application_phase.tag.draft": "Borrador",
  "pages.admin.company_goals.goal_detail.application_phase.tag.open": "Permanece abierto",
  "pages.admin.company_goals.goal_detail.city": "Ciudad",
  "pages.admin.company_goals.goal_detail.confirm_launch.description": "Vas a lanzar el objetivo. Por favor, continúa solo si está seguro de que el objetivo está listo para ser lanzado.",
  "pages.admin.company_goals.goal_detail.confirm_launch.title": "Confirmar lanzamiento",
  "pages.admin.company_goals.goal_detail.confirm_set_result.description": "Vas a establecer el resultado del objetivo. Por favor, continúa solo si está seguro de que el resultado es el correcto.",
  "pages.admin.company_goals.goal_detail.confirm_set_result.title": "Confirmar {result} resultado",
  "pages.admin.company_goals.goal_detail.country": "País",
  "pages.admin.company_goals.goal_detail.dates_candidates_application": "Fechas para la solicitud de candidatos",
  "pages.admin.company_goals.goal_detail.dates.application": "Aplicar",
  "pages.admin.company_goals.goal_detail.dates.end": "Objetivo {title} fecha final",
  "pages.admin.company_goals.goal_detail.dates.start": "Objetivo {title} fecha de inicio",
  "pages.admin.company_goals.goal_detail.dates.tag.closed": "Cerrado",
  "pages.admin.company_goals.goal_detail.dates.tag.draft": "Borrador",
  "pages.admin.company_goals.goal_detail.dates.tag.in_progress": "En curso",
  "pages.admin.company_goals.goal_detail.dates.tag.stand_by": "Stand by",
  "pages.admin.company_goals.goal_detail.dates.tag.waiting_candidates": "Esperando candidatos",
  "pages.admin.company_goals.goal_detail.dates": "Fechas para el logro del objetivo",
  "pages.admin.company_goals.goal_detail.description": "Descripción",
  "pages.admin.company_goals.goal_detail.edit_goal": "Editar objetivo",
  "pages.admin.company_goals.goal_detail.employee_full_name": "{firstName} {lastName}",
  "pages.admin.company_goals.goal_detail.employees_applying": "Candidatos que están solicitando",
  "pages.admin.company_goals.goal_detail.employees_modal.applicants": "{number} candidatos",
  "pages.admin.company_goals.goal_detail.employees_modal.list_title.employee": "Empleado",
  "pages.admin.company_goals.goal_detail.employees_modal.list_title.match_with_goal": "Coincidiendo con el objetivo",
  "pages.admin.company_goals.goal_detail.employees_modal.search.placeholder": "Buscar objetivos y desafíos",
  "pages.admin.company_goals.goal_detail.employees_modal.title": "Encuentra personas para el objetivo",
  "pages.admin.company_goals.goal_detail.employees_on_charge": "Empleados a cargo de este objetivo",
  "pages.admin.company_goals.goal_detail.employees_selected": "Empleados seleccionados",
  "pages.admin.company_goals.goal_detail.employees.delete.content": "Vas a eliminar a este empleado del equipo del objetivo. Puede seleccionarlo para otro objetivo o agregarlo nuevamente más tarde. ¿Estás seguro de que quieres continuar?",
  "pages.admin.company_goals.goal_detail.employees.delete.title": "Eliminar empleado",
  "pages.admin.company_goals.goal_detail.employees": "Empleados",
  "pages.admin.company_goals.goal_detail.launch_goal": "Objetivo de lanzamiento",
  "pages.admin.company_goals.goal_detail.launched_goal": "Lanzado",
  "pages.admin.company_goals.goal_detail.link": "Más información",
  "pages.admin.company_goals.goal_detail.no_assigned_yet": "No hay empleados asignados todavía",
  "pages.admin.company_goals.goal_detail.open_for_applications": "Objetivo abierto para aplicaciones",
  "pages.admin.company_goals.goal_detail.people_needed": "Número de personas necesarias",
  "pages.admin.company_goals.goal_detail.progress.label": "Progreso del objetivo del equipo",
  "pages.admin.company_goals.goal_detail.progress.no_progression.application_open": "Sigue abierta la fase de solicitud de candidatos",
  "pages.admin.company_goals.goal_detail.progress.no_progression.draft": "El objetivo todavía está en fase de borrador",
  "pages.admin.company_goals.goal_detail.progress.no_progression.team_built": "El equipo está creado pero la meta aún no ha comenzado",
  "pages.admin.company_goals.goal_detail.progress.no_progression.team_not_built": "El equipo todavía no se ha creado",
  "pages.admin.company_goals.goal_detail.progress.title": "Progreso",
  "pages.admin.company_goals.goal_detail.published_for_applicants": "Publicado para candidatos",
  "pages.admin.company_goals.goal_detail.result_failed": "El objetivo fue un fracaso",
  "pages.admin.company_goals.goal_detail.result_not_set": "El objetivo ha finalizado. Seleccione la evaluación global que mejor se ajuste al resultado final del objetivo.",
  "pages.admin.company_goals.goal_detail.result_succeeded": "El objetivo fue un éxito",
  "pages.admin.company_goals.goal_detail.skills_needed.no_content_yet": "Todavía no has proporcionado información",
  "pages.admin.company_goals.goal_detail.skills_needed": "Habilidades necesarias",
  "pages.admin.company_goals.goal_detail.successfully_launched": "El objetovo se ha lanzado correctamente",
  "pages.admin.company_goals.goal_detail.successfully_result_set": "El resultado del objetivo se ha establecido correctamente",
  "pages.admin.company_goals.goal_detail.team_skills": "Habilidades de equipo",
  "pages.admin.internal_promotions.title": "Promociones internas",
  "pages.admin.company_settings.title": "Configuración de la empresa",
  "pages.admin.company_settings.save": "Guardar perfil",
  "pages.admin.company_settings.general_info.title": "Información general",
  "pages.admin.company_settings.general_info.form.name": "Nombre",
  "pages.admin.company_settings.general_info.form.description": "Descripción",
  "pages.admin.company_settings.general_info.form.feedbackEmail": "Email de feedback",
  "pages.admin.company_settings.general_info.successfully_updated": "Información general de la empresa actualizada correctamente",
  "pages.admin.company_settings.general_info.chatbot.step": "Paso",
  "pages.admin.company_settings.general_info.chatbot.teams.title": "Mr. Fleebe en Teams",
  "pages.admin.company_settings.general_info.chatbot.teams.download.label": "Mr. Chatbot",
  "pages.admin.company_settings.general_info.chatbot.teams.download.text": "Descargar",
  "pages.admin.company_settings.general_info.chatbot.teams.step.1": "Ir a <a href='https://admin.teams.microsoft.com' class='text--link' target='blank'>Administración de Teams</a>",
  "pages.admin.company_settings.general_info.chatbot.teams.step.2": "Subir la aplicación descargada",
  "pages.admin.company_settings.general_info.chatbot.teams.step.3": "Asignar permisos",
  "pages.admin.company_settings.general_info.chatbot.teams.step.4": "Tómalo con calma... El chatbot necesita tiempo para refrescar y estar disponible.",
  "pages.admin.company_settings.general_info.chatbot.slack.title": "Mr. Fleebe en Slack",
  "pages.admin.company_settings.general_info.chatbot.slack.add": "Añadir a Slack",
  "pages.admin.company_settings.general_info.chatbot.slack.step.1": "Hacer click en el botón 'Añadir a Slack'",
  "pages.admin.company_settings.general_info.chatbot.slack.step.2": "Si se muestra el desplegable de espacio de trabajo de slack, seleccionar el deseado",
  "pages.admin.company_settings.general_info.chatbot.slack.step.3": "Aceptar las condiciones",
  "pages.admin.company_settings.azure_info.title": "Información de Azure",
  "pages.admin.company_settings.azure_info.form.service_principal_id": "Id. de entidad de servicio",
  "pages.admin.company_settings.azure_info.form.service_principal_secret": "Secreto de entidad de servicio",
  "pages.admin.company_settings.azure_info.form.service_principal_secret.placeholder": "*****************",
  "pages.admin.company_settings.azure_info.form.tenant_id": "Id. de inquilino",
  "pages.admin.company_settings.azure_info.form.purchase_subscription_id": "ID de suscripción de compra",
  "pages.admin.company_settings.azure_info.successfully_updated": "Información de la compañía de Azure actualizada correctamente",
  "pages.admin.skills.grid.name": "Nombre",
  "pages.admin.skills.grid.description": "Descripción",
  "pages.admin.skills.grid.status": "Estado",
  "pages.admin.skills.grid.type": "Tipo",
  "pages.admin.skill_management.search_placeholder": "Habilidades de búsqueda",
  "pages.admin.skill_management.new_skill": "Agregar nueva habilidad",
  "pages.admin.skill_management.filters.status.all": "Todos",
  "pages.admin.skill_management.filters.status.Pending": "Moderación pendiente",
  "pages.admin.skill_management.filters.status.Approved": "Aprobado",
  "pages.admin.skill_management.filters.type.all": "Todos",
  "pages.admin.skill_management.filters.type.General": "General",
  "pages.admin.skill_management.filters.type.Language": "Idioma",
  "pages.admin.skill_management.search_show_all_skills": "Todos",
  "pages.admin.skill_management.skill_requester_name": "Solicitante",
  "pages.admin.skill_management.skill_moderator_name": "Moderador",
  "pages.admin.skill_management.grid.no_results": "No hay habilidades con este filtro",
  "pages.admin.skill_management.skill_status.Pending": "Pendiente de moderación",
  "pages.admin.skill_management.skill_status.Approved": "Aprobado",
  "pages.admin.skill_management.edit_skill": "Editar habilidad",
  "pages.admin.skill_management.delete": "Eliminar habilidad",
  "pages.admin.skill_management.moderate": "Moderar",
  "pages.admin.skill_management.confirm_to_delete": "Si elimina la habilidad, se eliminará la habilidad y la solicitud del empleado. ¿Quieres continuar?",
  "pages.admin.skill_management.validated_effects.title": "Habilidad validada",
  "pages.admin.skill_management.validated_effects.text": "La habilidad ha sido validada. A partir de ahora, los empleados pueden seleccionarlo para completar su currículum",
  "pages.admin.skill_management.noContentYet": "Aún no hay contenido",
  "pages.admin.talent_detection.chart.no_content": "No tenemos suficiente información para crear el gráfico",
  "pages.admin.talent_detection.chart.by_age": "Por edad",
  "pages.admin.talent_detection.chart.by_group": "Por grupo",
  "pages.admin.talent_detection.employee_motivation.search.placeholder": "Búsqueda",
  "pages.admin.talent_detection.employee_motivation.title.employee": "Empleado",
  "pages.admin.talent_detection.employee_motivation.title.motivation": "Motivación",
  "pages.admin.talent_detection.employee_motivation.title": "Rango de motivación de los empleados",
  "pages.admin.talent_detection.employees.no_content": "Todavía no hay contenido seleccionado",
  "pages.admin.talent_detection.employees_group.title": "{n} empleado {group} en {category} | {n} empleados {group} en {category}",
  "pages.admin.talent_detection.employees_age.title.age_from": "{n} empleado en {category} (desde {from} años) | {n} empleados en {category} (desde {from} años)",
  "pages.admin.talent_detection.employees_age.title.age_to": "{n} empleado en {category} (desde {to} años) | {n} empleados en {category} (desde {to} años)",
  "pages.admin.talent_detection.employees_age.title.age_range": "{n} empleado en {category} (desde {from} a {to} años) | {n} empleados en {category} (desde {from} a {to} años)",
  "pages.admin.talent_detection.employees_age.title.age_all": "{n} empleado en {category} | {n} empleados en {category}",
  "pages.admin.talent_detection.search": "Busca un hobby",
  "pages.admin.talent_detection.tip.content": "Cuando finalmente se construya el gráfico, haga clic en las áreas coloreadas para ver cómo los empleados se relacionan con los hobbies seleccionados.",
  "pages.admin.talent_detection.tip.title": "Principales hobbies ocultos",
  "pages.admin.talent_detection.title": "Detección de talento",
  "pages.admin.talent_detection.view_motivation": "Ver todos los empleados",
  "pages.admin.learning.title": "Aprendizaje en la empresa",
  "pages.admin.learning.title.short": "Aprendizaje",
  "pages.admin.learning.search_placeholder": "Buscar formaciones",
  "pages.admin.learning.create_new_learning_form.title": "Cree la formación que su empresa necesita",
  "pages.admin.learning.create_new_learning_form.description": "Si es necesario, también puede crear cursos que se adapten a las necesidades de su empresa.",
  "pages.admin.learning.create_new_learning_form.new_learning": "Añadir formación",
  "pages.admin.learning.create_new_learning_form.successfully_created": "La formación se ha creado correctamente",
  "pages.admin.learning.form.create": "Agregar nueva formación",
  "pages.admin.learning.form.edit": "Editar formación",
  "pages.admin.learning.form.fields.title.label": "Título",
  "pages.admin.learning.form.fields.title.placeholder": "Título de la formación",
  "pages.admin.learning.form.fields.description.label": "Descripción",
  "pages.admin.learning.form.fields.description.placeholder": "Escriba aquí",
  "pages.admin.learning.form.fields.startDate.label": "Fecha de inicio",
  "pages.admin.learning.form.fields.endDate.label": "Fecha de finalización",
  "pages.admin.learning.form.fields.level.label": "Nivel",
  "pages.admin.learning.form.fields.duration.label": "Duración (horas)",
  "pages.admin.learning.form.fields.topics.label": "Temas (separados por comas)",
  "pages.admin.learning.form.fields.topics.placeholder": "Escriba temas",
  "pages.admin.learning.form.fields.onsiteInformation.label": "Información in situ",
  "pages.admin.learning.form.fields.onsiteCountry.label": "País",
  "pages.admin.learning.form.fields.onsiteCountry.placeholder": "Introduzca el país",
  "pages.admin.learning.form.fields.onsiteCity.label": "Ciudad",
  "pages.admin.learning.form.fields.onsiteCity.placeholder": "Introducir ciudad",
  "pages.admin.learning.form.fields.onsiteAddress.label": "Dirección",
  "pages.admin.learning.form.fields.onsiteAddress.placeholder": "Introduzca la dirección",
  "pages.admin.learning.form.fields.onsiteFloor.label": "Piso",
  "pages.admin.learning.form.fields.onsiteFloor.placeholder": "Introducir piso",
  "pages.admin.learning.form.fields.onsiteRoom.label": "Sala",
  "pages.admin.learning.form.fields.onsiteRoom.placeholder": "Entrar en la sala",
  "pages.admin.learning.form.fields.onsiteSchedule.label": "Programación",
  "pages.admin.learning.form.fields.onsiteSchedule.placeholder": "Introducir programación",
  "pages.admin.learning.form.fields.onsiteStartTime.label": "Hora inicio",
  "pages.admin.learning.form.fields.onsiteEndTime.label": "Hora fin",
  "pages.admin.learning.form.fields.attachment.label": "Adjuntar portada de imagen",
  "pages.admin.learning.form.fields.courseUrl.title": "Escriba aquí la URL del curso",
  "pages.admin.learning.form.fields.courseUrl.description": "Póngase en contacto con el proveedor para obtener un enlace válido para el curso.",
  "pages.admin.learning.form.fields.courseUrl.label": "URL de la formación",
  "pages.admin.learning.form.fields.courseUrl.placeholder": "Por ejemplo, https://www.training.com/course/name-of-the-course/",
  "pages.admin.learning.form.save": "Crear formación",
  "pages.admin.learning.form.fields.language.label": "Idioma",
  "pages.admin.learning.form.languages.english": "Inglés",
  "pages.admin.learning.form.languages.spanish": "Español",
  "pages.admin.learning.prompt.delete.title": "Eliminar curso",
  "pages.admin.learning.prompt.delete.description": "Vas a eliminar este curso. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "shared.close": "Cerrar",
  "shared.languages.english": "Inglés",
  "shared.languages.en": "Inglés",
  "shared.languages.spanish": "Español",
  "shared.languages.es": "Español",
  "shared.languages.french": "Francés",
  "shared.languages.fr": "Francés",
  "shared.languages.german": "Alemán",
  "shared.languages.italian": "Italiano",
  "shared.languages.portuguese": "Portugués",
  "shared.app_tag.no_content_yet": "Aún no ha proporcionado información",
  "shared.today": "Hoy",
  "shared.yesterday": "Ayer",
  "shared.lastUpdate": "Última actualización",
  "shared.not_available": "N/A",
  "shared.yes": "Sí",
  "shared.no": "No",
  "shared.back": "Atrás",
  "shared.results": "{n} resultado | {n} resultados",
  "shared.resultsFor": "{n} Resultado para '{name}' | {n} Resultados para '{name}'",
  "shared.applicants": "{n} solicitante | {n} solicitantes",
  "shared.assigned": "{n} empleado asignado | {n} empleados asignados",
  "shared.attachment.label": "Elija un archivo para cargar",
  "shared.time.minutes": "{time} min",
  "shared.time.hours": "{time} h",
  "shared.are_you_sure": "¿Estás seguro de querer continuar?",
  "pages.admin.employee_roles.search_placeholder": "Buscar por Nombre, Apellido y Email",
  "pages.admin.admin_roles.roles.grid.email": "Correo electrónico",
  "pages.admin.admin_roles.roles.grid.name": "Nombre",
  "pages.admin.admin_roles.roles.grid.surname": "Apellido",
  "pages.admin.admin_roles.roles.grid.role": "Rol",
  "pages.admin.admin_roles.roles.grid.role.Administrator": "Administrador",
  "pages.admin.admin_roles.roles.grid.role.ITManager": "IT Mánager",
  "pages.admin.admin_roles.roles.grid.role.User": "Usuario",
  "pages.admin.admin_roles.roles.grid.role.all": "Todos",
  "pages.goals_challenges.details.is_open.label": "Objetivo abierto para aplicaciones",
  "pages.goals_challenges.details.people_needed.label": "Se necesita gente",
  "pages.goals_challenges.details.country.label": "País",
  "pages.goals_challenges.details.city.label": "Ciudad",
  "pages.goals_challenges.details.start_date.label": "Fecha de inicio del objetivo",
  "pages.goals_challenges.details.end_date.label": "Fecha de finalización del objetivo",
  "pages.goals_challenges.details.my_progress.label": "Progreso de objetivos",
  "pages.goals_challenges.details.coworkers_with_this_goal.label": "Coworker con este objetivo | Coworkers con este objetivo",
  "pages.goals_challenges.details.participate": "Participar",
  "pages.goals_challenges.details.reject": "Reject",
  "pages.goals_challenges.details.reject_application": "Rechazar mi solicitud",
  "pages.goals_challenges.details.colleagues_registered": "{n} colega registrado | {n} colegas registrados",
  "pages.my_groups.title": "Mis Grupos",
  "pages.my_groups.search.placeholder": "Buscar empleados...",
  "pages.my_groups.grid.actions.view_profile": "Ver perfil",
  "pages.my_groups.view_questions": "Ver preguntas",
  "shared.company_groups_questions.title": "Preguntas",
  "shared.company_groups_questions.subtitle": "Preguntas del formulario",
  "shared.company_groups_questions.created_questions": "Preguntas creadas",
  "shared.company_groups_questions.sent_questions": "Preguntas enviadas",
  "shared.company_groups_questions.search.placeholder": "Buscar preguntas",
  "shared.company_groups_questions.add_new_question": "Agregar nueva pregunta",
  "shared.company_groups_questions.remove": "Borrar preguntas",
  "shared.company_groups_questions.send": "Enviar preguntas",
  "shared.company_groups_questions.tip.title": "Nuevas preguntas",
  "shared.company_groups_questions.tip.content": "Tus empleados tienen talento por lo que su opinión es valiosa. Crea desde aquí un formulario para recibir sus comentarios.",
  "shared.company_groups_questions.grid.field.question": "Pregunta",
  "shared.company_groups_questions.grid.field.answeredPercentage": "Participación (%)",
  "shared.company_groups_questions.grid.field.send_date": "Enviada",
  "shared.company_groups_questions.view_kpis": "Ver KPIs",
  "shared.company_groups_questions.view_questions": "Ver Preguntas",
  "shared.company_groups_questions.grid.actions.remove": "Eliminar pregunta del grupo",
  "shared.company_groups_questions.grid.actions.preview": "Vista previa de la pregunta",
  "shared.company_groups_questions.grid.actions.edit": "Editar pregunta",
  "shared.company_groups_questions.send_to_groups.title": "Enviar preguntas a grupos",
  "shared.company_groups_questions.send_to_groups.content": "Selecciona los grupos de destinatarios a los que deseas enviar la encuesta. Solo un administrador puede crear un grupo de destinatarios. Si no puedes encontrar el grupo que buscas, por favor ponte en contacto con tu administrador de Recursos Humanos.",
  "shared.company_groups_questions.send_to_groups.search.placeholder": "Buscar",
  "shared.company_groups_questions.send_to_groups.buttons.send": "Enviar",
  "shared.company_groups_questions.send_to_groups.fields.group_name": "Nombre del grupo",
  "shared.company_groups_questions.send_to_groups.fields.people": "Miembros",
  "shared.question_answers.title": "Respuestas a '{text}'",
  "shared.question_answers.search.placeholder": "Buscar",
  "shared.question_answers.no_answer": "Sin respuesta",
  "shared.question_answers.action.show": "Mostrar respuestas",
  "shared.question_answers.statistics.metric": "{count} de {total}",
  "shared.question_answers.statistics.copy": "Personas han respondido",
  "shared.question_kpi.pin": "Anclar esta gráfica para verla en el panel",
  "shared.question_kpi.unpin": "Desanclar esta gráfica del panel",
  "shared.question_kpi.action.show": "Ver KPI",
  "shared.question_kpi.no_answers": "Esta pregunta aún no ha sido respondida",
  "shared.employee.full_name": "{firstName} {lastName}",
  "shared.company-groups.grid.full_name": "Nombre",
  "shared.company-groups.grid.email": "Email",
  "shared.agree_share_information": "Acepto compartir mi información con la empresa",
  "shared.skillEditor.newSkill.skill.title": "¿No encuentras la habilidad?",
  "shared.skillEditor.newSkill.skill.addNewButton": "Agregar nueva habilidad",
  "shared.skillEditor.newSkill.language.title": "¿No encuentras el idioma?",
  "shared.skillEditor.newSkill.language.addNewButton": "Agregar nuevo idioma",
  "shared.skillEditor.skill.search": "Buscar una habilidad",
  "shared.skillEditor.language.search": "Buscar un idioma",
  "shared.skillEditor.skill.pendingArea": "Pendiente de habilidades de aprobación:",
  "shared.skillEditor.language.pendingArea": "Idiomas pendientes de aprobación:",
  "shared.skillCreator.skill.title": "Crear nueva habilidad",
  "shared.skillCreator.language.title": "Crear nuevo idioma",
  "shared.skillCreator.skill.hrMessage": "La nueva habilidad debe ser validada por el administrador de recursos humanos.",
  "shared.skillCreator.language.hrMessage": "El nuevo idioma debe ser validado por el administrador de recursos humanos.",
  "shared.skillCreator.form.skill_type_label": "Tipo de habilidad",
  "shared.skillCreator.form.description": "Descripción",
  "shared.skillCreator.form.name": "Nombre",
  "shared.skillCreator.form.skill.createButton": "Crear habilidad",
  "shared.skillCreator.form.language.createButton": "Crear idioma",
  "shared.table.rows_per_page": "Filas por página",
  "shared.table.go_to_first_page": "Ir a la primera página",
  "shared.table.go_to_previous_page": "Ir a la página anterior",
  "shared.table.go_to_next_page": "Ir a la página siguiente",
  "shared.table.go_to_last_page": "Ir a la última página",
  "shared.tips.title": "Consejo",
  "shared.tips.dismiss": "Entendido",
  "shared.reminder.title": "Amable recordatorio",
  "shared.feedback-sender.feedback-button": "Feedback",
  "shared.feedback-sender.bug-button": "Bug",
  "shared.feedback-sender.feedback-message": "Por favor, escriba aquí el feedback. Muchas gracias.",
  "shared.feedback-sender.bug-message": "Por favor, especifique como reproducir este bug o incluya un pantallazo.",
  "entities.goals_challenges.has_not_started": "El objetivo aún no ha comenzado",
  "entities.goals_challenges.has_finished": "El objetivo está terminado",
  "entities.goals_challenges.no_goals_yet": "Todavía no tienes ningún objetivo",
  "entities.goals_challenges.employee_no_goals_yet": "El empleado aún no tiene ningún objetivo",
  "entities.learning.language": "Idioma",
  "entities.learning.duration": "Duración",
  "entities.learning.level": "Nivel",
  "entities.learning.topics": "Temas",
  "entities.learning.more_information": "Más información",
  "entities.my_ideal_job.title": "Mi trabajo ideal",
  "entities.my_ideal_job.tab.learning_path": "Ruta de aprendizaje",
  "entities.my_ideal_job.tab.my_training": "Mi entrenamiento",
  "entities.my_ideal_job.tab.company_training": "Entrenamiento de la compañía",
  "entities.my_ideal_job.general_skills": "Habilidades",
  "entities.my_ideal_job.languages": "Idiomas",
  "entities.my_ideal_job.tip.content": "¿Cuál sería tu trabajo ideal en este momento? ¡Complétalo aquí para ser guiado en el logro de tus metas!",
  "entities.my_ideal_job.add_job": "Agregar trabajo ideal",
  "entities.my_ideal_job.update_job": "Actualiza tu trabajo ideal",
  "entities.my_ideal_job.fields.title": "Título",
  "entities.my_ideal_job.fields.title.placeholder": "Añade un título para tu trabajo ideal",
  "entities.my_ideal_job.fields.description": "Descripción",
  "entities.my_ideal_job.fields.description.placeholder": "Añade una descripción para tu trabajo ideal",
  "entities.my_ideal_job.recommended_learning": "Aprendizaje recomendado",
  "entities.my_interests.hobbies": "Hobbies",
  "entities.my_interests.add-hobbies": "Editar pasatiempos",
  "entities.my_interests.hobbies.category": "Categoría",
  "entities.my_interests.hobbies.edit-modal.title": "Hobbies",
  "entities.my_interests.hobbies.edit-modal.search": "Busca un hobby",
  "entities.my_interests.hobbies.edit-modal.needNew": "¿No encuentras el hobby?",
  "entities.my_interests.hobbies.edit-modal.newButton": "Añadir nuevo hobby",
  "entities.my_interests.hobbies.hobbies-creator.title": "Crear nuevo hobby",
  "entities.my_interests.hobbies.hobbies-creator.createButton": "Crear hobby",
  "entities.my_interests.hobbies.hobbies-creator.form.name": "Nombre",
  "entities.my_interests.hobbies.category.sport": "Deportes",
  "entities.my_interests.hobbies.category.music": "Música",
  "entities.my_interests.hobbies.category.culture": "Cultura",
  "entities.my_interests.hobbies.category.technology": "Tecnología",
  "entities.my_interests.hobbies.category.others": "Otros",
  "entities.my_interests.hobbies.no_hobbies": "No hay hobbies definidos",
  "entities.my_interests.hobbies.age": "Edad",
  "entities.skills.Low": "Bajo",
  "entities.skills.Medium": "Medio",
  "entities.skills.High": "Alto",
  "entities.courses.level.empty": "--",
  "entities.courses.level.1": "Principiante",
  "entities.courses.level.2": "Avanzado",
  "entities.courses.level.3": "Experto",
  "level_KPI.x.title": "Votos",
  "pages.admin.company_settings.employee_groups.title": "Grupos de empleados",
  "pages.admin.company_settings.role_management.title": "Roles",
  "pages.admin.company_settings.skill_management.title": "Competencias",
  "pages.admin.company_settings.chatbot.title": "Mr. Fleebe",
  "pages.admin.company_settings.employee_groups.search_placeholder": "Buscar grupos de empleados",
  "pages.admin.company_settings.employee_groups.new_group": "Añadir nuevo grupo",
  "pages.admin.company_settings.employee_groups.fields.name": "Nombre",
  "pages.admin.company_settings.employee_groups.fields.description": "Descripción",
  "pages.admin.company_settings.employee_groups.fields.people": "Miembros",
  "pages.admin.company_settings.employee_groups.fields.courses": "Cursos",
  "pages.admin.company_settings.employee_groups.fields.employee-count": "sin personas | {n} persona | {n} personas",
  "pages.admin.company_settings.employee_groups.fields.course-count": "sin cursos | {n} curso | {n} cursos",
  "pages.admin.company_settings.employee_groups.no_results": "No hay grupos con estos filtros",
  "pages.admin.company_settings.employee_groups.delete.title": "Eliminar grupo de empleados",
  "pages.admin.company_settings.employee_groups.delete.description": "Vas a eliminar este grupo de empleados. Esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar?",
  "pages.admin.company_settings.employee_groups.editor.title": "Editar grupo de empleados",
  "pages.admin.company_settings.employee_groups.editor.new_group": "Nuevo grupo",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.title": "Importar lista de empleados",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.title": "Cómo importar un grupo.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.tip.description": "Importa un fichero con un formato válido. La primera columna debe denominarse 'Email' y contener los emails de los empleados que quieres añadir al grupo.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.no_email_column_error.title": "No existe la columna 'Email'.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.no_email_column_error.description": "Debe exisitir una columna denominada 'Email'.<br>Para nombrar la columna, el texto de la primera fila de la columna debe ser 'Email'. Por favor, importa un nuevo fichero que incluya esta columna.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.not_all_are_emails_error.title": "Ninguno de los valores es un email válido.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.not_all_are_emails_error.description": "No todos los valores de la columna 'Email' son emails válidos: <br><br> {notValidEmails} <br><br>Por favor, revisa el formato.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.emails_not_matched.title": "No se han encontrado los emails.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.emails_not_matched.description": "Los siguientes emails no se han encontrado entre los empleados: <br><br> {notFoundEmails} <br><br>El resto de emails han sido añadidos al grupo.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.all_emails_matched.title": "Empleados añadidos al grupo.",
  "pages.admin.company_settings.employee_groups.editor.import_group_from_excel.all_emails_matched.description": "Todos los empleados han sido añadidos al grupo.",
  "pages.admin.company_settings.employee_groups.editor.search_placeholder": "Buscar empleados",
  "pages.admin.company_settings.employee_groups.editor.selected_employees": "Seleccionados {n} empleados de {count}",
  "pages.admin.company_settings.employee_groups.editor.grid.actions.manage": "Gestionar",
  "pages.admin.company_settings.employee_groups.role.Manager": "Mánager",
  "pages.admin.company_settings.employee_groups.role.Viewer": "Usuario",
  "pages.admin.company_settings.employee_groups.role.all": "Todos",
  "pages.admin.company_settings.employee_groups.tabs.employees": "Empleados",
  "pages.admin.company_settings.employee_groups.tabs.questions": "Preguntas",
  "pages.admin.company_settings.employee_groups.tabs.learning": "Cursos",
  "pages.admin.company_settings.employee_groups.manage.title.Employees": "Empleados y roles",
  "pages.admin.company_settings.employee_groups.manage.title.Questions": "Gestión de preguntas",
  "pages.admin.company_settings.employee_groups.manage.title.Learning": "Cursos asignados a este grupo",
  "pages.admin.company_settings.employee_groups.manage.learning.select_training": "Seleccionar curso",
  "pages.admin.company_settings.employee_groups.manage.learning.add_training": "Añadir nuevo curso",
  "pages.admin.company_settings.employee_groups.manage.learning.no_content": "Sin conctenido de momento",
  "pages.admin.company_settings.employee_groups.manage.learning.search.placeholder": "Buscar cursos para este grupo",
  "pages.admin.company_settings.employees-with-groups.title": "Gestión de Permisos",
  "pages.admin.company_settings.employees-with-groups.search_placeholder": "Buscar empleados",
  "pages.admin.company_settings.employees-with-groups.fields.first-name": "Nombre",
  "pages.admin.company_settings.employees-with-groups.fields.last-name": "Apellidos",
  "pages.admin.company_settings.employees-with-groups.fields.email": "Email",
  "pages.admin.company_settings.employees-with-groups.fields.group-count": "Grupos",
  "pages.admin.company_settings.employees-with-groups.group-count": "{n} grupo | {n} grupos",
  "pages.admin.company_settings.employees-with-groups.actions.edit": "Editar grupo",
  "pages.admin.company_settings.employees-with-groups.actions.manage": "Gestionar grupo"
}