<template>
  <span :title="title">{{ (item.employeeGroups ?? []).length }}</span>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n";
import type { Question } from "@/models/question";
import type { QuestionGroupsColumnCustomProps } from "@/views/admin/ai-assistant/components/grid/QuestionGroupsColumnCustomProps";
import { concatWithAnd } from "@/utils/stringUtils";

interface Props {
  item: Question;
  customProps?: QuestionGroupsColumnCustomProps;
}
const props = defineProps<Props>();

const title = computed(() => {
  let groups = props.item.employeeGroups;

  if (props.item.employeeGroups?.length && props.customProps && props.customProps.allowedGroupNames?.length) {
    groups = props.item.employeeGroups?.filter((eg) => props.customProps?.allowedGroupNames?.includes(eg));
    const difference = (props.item.employeeGroups?.length || 0) - (groups?.length || 0);
    if (!groups?.length) {
      groups?.push(
        i18n.global.t("pages.admin.ai_assistant.questions.grid.groups.tooltip.groups", { n: props.item.employeeGroups?.length })
      );
    } else if (difference > 0) {
      groups?.push(i18n.global.t("pages.admin.ai_assistant.questions.grid.groups.tooltip.more_groups", { n: difference }));
    }
  }

  return concatWithAnd(groups);
});
</script>
