import useHttpClient from "@/services/shared/useHttpClient";
import type { LearningCourse } from "@/models/learningCourse";
import type { CourseBase } from "@/models/course";
import type { Ref } from "vue";

const BASE_URL = "/api/company/course";
const { post, put, httpDelete, get, appBaseUrl, loading } = useHttpClient();

interface UseCompanyCoursesServiceReturn {
  createLearningCourse: (learningCourse: LearningCourse) => Promise<number>;
  getLearningImageCoverUrl: (learningCourseId: number, imageName: string) => string;
  updateLearningCourse: (learningCourse: LearningCourse) => Promise<void>;
  deleteLearningCourse: (learningCourseId: number) => Promise<void>;
  getCourses: (searchTerm: string) => Promise<CourseBase[]>;
  loading: Ref<boolean>;
}

export default (): UseCompanyCoursesServiceReturn => {
  const createLearningCourse = (learningCourse: LearningCourse): Promise<number> => post(BASE_URL, learningCourse);
  const getLearningImageCoverUrl = (learningCourseId: number, imageName: string): string =>
    `${appBaseUrl}${BASE_URL}/${learningCourseId}/imageCover/?n=${imageName}`;
  const getCourses = (searchTerm: string): Promise<CourseBase[]> => get<CourseBase[]>(`${BASE_URL}/${searchTerm}`);
  const deleteLearningCourse = (learningCourseId: number): Promise<void> => httpDelete(`${BASE_URL}/${learningCourseId}`);
  const updateLearningCourse = (course: LearningCourse): Promise<void> => put(`${BASE_URL}/${course.id}`, course);

  return { createLearningCourse, getLearningImageCoverUrl, updateLearningCourse, deleteLearningCourse, getCourses, loading };
};
