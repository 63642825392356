<template>
  <div>
    <AIAssistantGenericTextAnswer :text="question.selectedOption?.title" />
    <AIAssistantAnswerActions v-if="answerEnabled" :question="question" :editor="AIAssistantAnswerSingleSelectionEditor" @edited="edited" />
  </div>
</template>

<script setup lang="ts">
import AIAssistantAnswerActions from "@/views/app/ai-assistant/components/AIAssistantAnswerActions.vue";
import AIAssistantAnswerSingleSelectionEditor from "@/views/app/ai-assistant/components/AIAssistantAnswerSingleSelectionEditor.vue";
import AIAssistantGenericTextAnswer from "@/views/app/ai-assistant/components/AIAssistantGenericTextAnswer.vue";
import type { Question } from "@/models/question";

interface Props {
  question: Question;
  answerEnabled: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const edited = (newQuestionState: Question): void => emit("edited", newQuestionState);
</script>
