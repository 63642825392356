<template>
  <div class="question">
    <div class="question__order text text--bold">{{ order }}</div>
    <div class="question__title text text--bold">{{ item.title }}</div>
    <div class="question__answer">
      <AIAssistantAnswer :item="item" :answer-enabled="answerEnabled" @edited="edited" />
    </div>
  </div>
</template>

<script setup lang="ts">
import AIAssistantAnswer from "@/views/app/ai-assistant/components/AIAssistantAnswer.vue";
import type { Question } from "@/models/question";

interface Props {
  item: Question;
  order: number;
  answerEnabled: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const edited = (newQuestionState: Question): void => emit("edited", newQuestionState);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.question {
  display: flex;
  flex-direction: row;
  gap: var(--space-s);
  align-items: center;
  padding: 0 var(--space-m);
  flex-wrap: wrap;

  &__order {
    width: 24px;
  }

  &__title {
    flex: 1;

    @media only screen and (min-width: $medium-screen-breakpoint) {
      width: 30%;
    }
  }

  &__answer {
    width: 100%;

    @media only screen and (min-width: $medium-screen-breakpoint) {
      flex-grow: 1;
      width: 30%;
    }
  }
}
</style>
