<template>
  <TransitionGroup name="fade">
    <QuestionKpi v-for="kpi in kpis" :key="kpi.id" :kpi-info="kpi" @remove="removeKpi(kpi)" />
  </TransitionGroup>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import QuestionKpi from "@/views/admin/ai-assistant/components/kpis/QuestionKpi.vue";
import type { QuestionKpiInfo } from "@/models/questionKpi";
import useQuestionService from "@/services/useQuestionService";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import type { Question } from "@/models/question";

interface Props {
  eventEmitter: UseEventEmitterServiceReturn;
}
const props = defineProps<Props>();

interface Emits {
  (e: "loaded", hasKpis: boolean): void;
  (e: "kpiRemoved", questionKpi: QuestionKpiInfo): void;
}
const emit = defineEmits<Emits>();

const { getKpis, toggleQuestionInDashboard } = useQuestionService();

const kpis = ref<QuestionKpiInfo[]>([]);
const loadKpis = async () => {
  kpis.value = await getKpis();
  emitLoaded();
};

const removeKpi = async (kpiInfo: QuestionKpiInfo): Promise<void> => {
  await toggleQuestionInDashboard(kpiInfo.id, false);
  removeKpiById(kpiInfo.id);
  emit("kpiRemoved", kpiInfo);
};
const emitLoaded = () => emit("loaded", kpis.value.length > 0);
const removeKpiById = (kpiId: number) => {
  const kpiIndex = kpis.value.findIndex((kpi) => kpi.id === kpiId);
  kpis.value.splice(kpiIndex, 1);
  emitLoaded();
};

onMounted(async () => {
  await loadKpis();
  props.eventEmitter.on("questionKpiToggled", async (question: Question) => {
    if (question.showInDashboard) {
      await loadKpis();
      return;
    }
    removeKpiById(question.id);
  });
  props.eventEmitter.on("questionDeleted", async (question: Question) => {
    if (question.showInDashboard) {
      removeKpiById(question.id);
    }
  });
});
</script>
