<template>
  <div class="app-input-range">
    <div v-if="label" class="label-container">
      <label :for="componentId" class="text text--secondary text--bold">{{ label }}</label>
    </div>
    <div class="input-container">
      <AppInputRange :id="componentId" v-model="rangeValue" :min="1" :max="3" :available-values="levels" @change="valueChange" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n";
import AppInputRange from "@/components/AppInputRange.vue";
import { SkillProgression } from "@/models/skillProgression";
import { getRandomId } from "@/utils/randomUtils";

interface Props {
  modelValue?: SkillProgression;
  label?: string;
  levels?: string[];
}
const props = withDefaults(defineProps<Props>(), {
  required: false,
  modelValue: SkillProgression.Low,
  levels: () => [
    i18n.global.t("skill_range.skill_names.low"),
    i18n.global.t("skill_range.skill_names.medium"),
    i18n.global.t("skill_range.skill_names.high"),
  ],
});

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();

const componentId: string = getRandomId();

const rangeValue = ref(0);

const valueChange = (): void => {
  const value: SkillProgression = convertToSkillProgression(rangeValue.value);
  emit("update:modelValue", value);
};

const convertFromSkillProgression = (value: SkillProgression): number => {
  switch (value) {
    case SkillProgression.High:
      return 3;
    case SkillProgression.Medium:
      return 2;
    default:
      return 1;
  }
};

const convertToSkillProgression = (value: number): SkillProgression => {
  switch (value) {
    case 3:
      return SkillProgression.High;
    case 2:
      return SkillProgression.Medium;
    default:
      return SkillProgression.Low;
  }
};

rangeValue.value = convertFromSkillProgression(props.modelValue);
</script>

<style scoped lang="scss">
.label-container {
  flex: 1;
  margin-top: var(--space-s);
}

.input-container {
  flex: 4;
  padding-top: var(--space-s);
  padding-bottom: var(--space-xs);

  &__input {
    width: 100%;
    border: 1px solid var(--color-input-border);

    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--color-input-border--focus);
    }
  }
}
</style>
