<template>
  <div class="grid--two-thirds-one-third-columns@medium margin-t--l">
    <div class="details__information">
      <img v-if="goal.picture" class="details__information-picture" :src="getGoalPictureUrl(goal.id)" :alt="goal.title" />
      <div class="details__information-row">
        <div class="details__information__row--item">
          <h3>{{ $t("pages.admin.company_goals.goal_detail.open_for_applications") }}</h3>
          <p>{{ booleanToYesNoString(goal.isPublic) }}</p>
        </div>
        <div class="details__information-row--item">
          <h3>{{ $t("pages.admin.company_goals.goal_detail.people_needed") }}</h3>
          <p>{{ goal.numberOfPeopleNeeded }}</p>
        </div>
        <div class="details__information-row--item">
          <h3>{{ $t("pages.admin.company_goals.goal_detail.country") }}</h3>
          <p>{{ goal.country }}</p>
        </div>
        <div class="details__information-row--item">
          <h3>{{ $t("pages.admin.company_goals.goal_detail.city") }}</h3>
          <p>{{ goal.city }}</p>
        </div>
      </div>
      <div v-if="isPublic(goal)" class="details__information-dates">
        <div class="details__information-dates-title">
          <h3>{{ $t("pages.admin.company_goals.goal_detail.dates_candidates_application") }}</h3>
          <AppTag type="status" :main-text="applicationDatesTagText" />
        </div>
        <AdminCompanyGoalsDates v-if="goal.applicationClosing" :goal="goal" type="application" data-cy="dates-for-application" />
      </div>
      <div class="details__information-dates">
        <div class="details__information-dates-title">
          <h3>{{ $t("pages.admin.company_goals.goal_detail.dates") }}</h3>
          <AppTag type="status" :main-text="goalDatesTagText" />
        </div>
        <AdminCompanyGoalsDates :goal="goal" type="goal" />
      </div>
      <div>
        <h3 class="margin-b--s">{{ $t("pages.admin.company_goals.goal_detail.description") }}</h3>
        <p v-html="toHtml(goal.description)"></p>
      </div>
      <div v-if="goal.link">
        <h3 class="margin-b--s">{{ $t("pages.admin.company_goals.goal_detail.link") }}</h3>
        <a :href="goal.link" class="text text--link" target="_blank">{{ goal.link }}</a>
      </div>
      <div>
        <h3 class="margin-b--s">{{ $t("pages.admin.company_goals.goal_detail.progress.title") }}</h3>
        <AdminCompanyGoalsProgress :goal="goal" />
      </div>
      <div>
        <h3 class="margin-b--s">{{ $t("pages.admin.company_goals.goal_detail.skills_needed") }}</h3>
        <div v-if="goal.skillsNeeded && goal.skillsNeeded.length > 0">
          <AppTag
            v-for="skill of goal.skillsNeeded"
            :key="skill.id"
            class="margin-b--xs margin-r--xs"
            :main-text="skill.name"
            :secondary-text="skill.progression.toLocaleUpperCase()"
            icon="check"
            type="skill" />
        </div>
        <AppNoContentYet v-else class="margin-b--m" :text="$t('pages.admin.company_goals.goal_detail.skills_needed.no_content_yet')" />
      </div>
      <div v-if="isFinished(goal)">
        <AdminCompanyGoalsResult :goal="goal" />
        <div v-if="!hasResult(goal)">
          <div class="details__information-result">
            <AppButton
              size="m"
              variant="secondary"
              :text="$t('pages.admin.company_goals.goal_detail.result_failed')"
              class="details__information-result-button"
              @onclick="setResult(false)" />
            <AppButton
              size="m"
              variant="secondary"
              :text="$t('pages.admin.company_goals.goal_detail.result_succeeded')"
              class="details__information-result-button"
              @onclick="setResult(true)" />
          </div>
        </div>
      </div>
    </div>
    <AdminCompanyGoalsActions
      v-if="isPublished(goal)"
      :goal="goal"
      @applicants-click="applicants"
      @delete-click="deleteEmployee"
      @launch-click="launch" />
  </div>
</template>

<script setup lang="ts">
import { booleanToYesNoString } from "@/utils/booleanUtils";
import { computed } from "vue";
import {
  isApplicationPhaseOpen,
  isPublic,
  isFinished,
  hasResult,
  isPublished,
  isDraft,
  isInProgress,
  isPrivate,
} from "@/services/modelServices/companyGoalService";
import { toHtml } from "@/utils/stringUtils";
import AdminCompanyGoalsActions from "@/views/admin/company-goals/components/AdminCompanyGoalsActions.vue";
import AdminCompanyGoalsDates from "@/views/admin/company-goals/components/AdminCompanyGoalsDates.vue";
import AdminCompanyGoalsProgress from "@/views/admin/company-goals/components/AdminCompanyGoalsProgress.vue";
import AdminCompanyGoalsResult from "@/views/admin/company-goals/components/AdminCompanyGoalsResult.vue";
import AppButton from "@/components/AppButton.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppTag from "@/components/AppTag.vue";
import i18n from "@/i18n";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";

interface Props {
  goal: CompanyGoalWithEmployees;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "applicants"): void;
  (e: "deleteEmployee", employeeId: number): void;
  (e: "setResult", result: boolean): void;
  (e: "launch"): void;
}>();

const { getGoalPictureUrl } = useCompanyGoalsService();

const applicationDatesTagText = computed(() => {
  if (!props.goal || isPrivate(props.goal)) {
    return "";
  }

  const textMap = {
    draft: i18n.global.t("pages.admin.company_goals.goal_detail.application_phase.tag.draft"),
    open: i18n.global.t("pages.admin.company_goals.goal_detail.application_phase.tag.open"),
    closed: i18n.global.t("pages.admin.company_goals.goal_detail.application_phase.tag.closed"),
  };
  if (isDraft(props.goal)) {
    return textMap.draft;
  }

  return isApplicationPhaseOpen(props.goal) ? textMap.open : textMap.closed;
});

const goalDatesTagText = computed(() => {
  if (!props.goal) {
    return "";
  }

  const textMap = {
    draft: i18n.global.t("pages.admin.company_goals.goal_detail.dates.tag.draft"),
    waitingCandidates: i18n.global.t("pages.admin.company_goals.goal_detail.dates.tag.waiting_candidates"),
    inProgress: i18n.global.t("pages.admin.company_goals.goal_detail.dates.tag.in_progress"),
    closed: i18n.global.t("pages.admin.company_goals.goal_detail.dates.tag.closed"),
    standBy: i18n.global.t("pages.admin.company_goals.goal_detail.dates.tag.stand_by"),
  };
  if (isDraft(props.goal)) {
    return textMap.draft;
  }
  if (isApplicationPhaseOpen(props.goal)) {
    return textMap.waitingCandidates;
  }
  if (isInProgress(props.goal)) {
    return textMap.inProgress;
  }
  return isFinished(props.goal) ? textMap.closed : textMap.standBy;
});

const deleteEmployee = (employeeId: number) => emit("deleteEmployee", employeeId);
const launch = () => emit("launch");
const applicants = () => emit("applicants");
const setResult = (isSuccessful: boolean) => emit("setResult", isSuccessful);
</script>

<style scoped lang="scss">
.details__information {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-m);

  &-picture {
    object-fit: cover;
    width: 100%;
  }

  &-row {
    display: flex;
    gap: var(--space-s);
    flex-wrap: wrap;

    &--item {
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);
    }
  }

  &-dates {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
    width: 100%;
  }

  &-dates-title {
    align-items: center;
    display: flex;
    gap: var(--space-xs);
  }

  &-result {
    display: flex;
    gap: var(--size-xs);
    justify-content: space-evenly;
    padding-top: var(--size-m);
    &-button {
      flex-grow: 1;
    }
  }
}
</style>
