const get = <T>(key: string): T | undefined => {
  const content = localStorage.getItem(key);
  if (content === null) {
    return undefined;
  }
  return JSON.parse(content) as T;
};

const store = <T>(key: string, value: T): void => {
  if (value === null || value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
export const STORES = {
  language_key: "LANGUAGE",
  tips_key: "TIPS_HIDDEN",
};

const partialStore = <T>(key: string): ((value: T) => void) => {
  return (value: T) => {
    store<T>(key, value);
  };
};

interface ReturnUseStorageService<T> {
  store: (value: T) => void;
  getFromStore: () => T | undefined;
  clearKey: () => void;
}
export const useStorageService = <T>(key: string): ReturnUseStorageService<T> => {
  const typedGet = get<T>;
  return {
    store: partialStore<T>(key),
    getFromStore: typedGet.bind(null, key),
    clearKey: store.bind(null, key, null),
  };
};
