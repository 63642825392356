<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" data-cy="question-wizard" :disable-key-enter="true" :state="currentStep.toString()">
    <div class="question-wizard__content">
      <div class="padding-b--xl">
        <QuestionWizardPath :selected-step="currentStep" @step-click="stepClick" />
      </div>
      <div v-if="currentStep === 1" class="question-wizard__content" data-cy="step-1">
        <div class="grid--two-columns grid--three-columns@large grid--narrow-xy-axis">
          <QuestionWizardTypeItemOption
            v-for="questionTypeItem in questionTypeItems"
            :key="questionTypeItem.resultingType"
            :selected="questionEditable.type === questionTypeItem.resultingType"
            :disabled="mode === 'edit'"
            :item="questionTypeItem"
            @click="typeSelect(questionTypeItem.resultingType)" />
        </div>
      </div>
      <div v-if="currentStep === 2" class="question-wizard__content" data-cy="step-2">
        <QuestionWizardConfigurer
          :question="questionEditable"
          :mode="mode"
          :show-all-errors="showAllErrors"
          @question-updated="editedQuestionUpdated" />
      </div>
      <div v-if="currentStep === 3" class="question-wizard__content" data-cy="step-3">
        <QuestionWizardPreviewer :question="questionEditable" :is-question-valid="isQuestionValid" />
      </div>
    </div>
    <template #actions>
      <div class="question-wizard__actions">
        <AppButton
          :text="$t('buttons.previous')"
          variant="secondary"
          size="m"
          :disabled="currentStep === 1"
          data-cy="prev"
          @onclick="prev()" />
        <AppButton
          v-if="currentStep !== 3"
          :text="$t('buttons.next')"
          variant="secondary"
          size="m"
          :disabled="currentStep === 3"
          data-cy="next"
          @onclick="next()" />
        <AppButton
          v-if="currentStep === 3 && isQuestionValid"
          :text="$t('buttons.save')"
          variant="primary"
          size="m"
          data-cy="save"
          @onclick="save()" />
      </div>
    </template>
  </AppAutomaticModalOpener>
</template>
<script setup lang="ts">
import i18n from "@/i18n";
import { onMounted, ref } from "vue";
import { QuestionType } from "@/models/questionType";
import type { QuestionTypeItem } from "@/views/admin/ai-assistant/models/questionTypeItem";
import AppButton from "@/components/AppButton.vue";
import QuestionWizardConfigurer from "@/views/admin/ai-assistant/components/wizard/QuestionWizardConfigurer.vue";
import QuestionWizardPath from "@/views/admin/ai-assistant/components/wizard/QuestionWizardPath.vue";
import QuestionWizardPreviewer from "@/views/admin/ai-assistant/components/wizard/QuestionWizardPreviewer.vue";
import QuestionWizardTypeItemOption from "@/views/admin/ai-assistant/components/wizard/QuestionWizardTypeItemOption.vue";
import type { FormMode } from "@/models/formMode";
import type { Question } from "@/models/question";
import useQuestionService from "@/services/useQuestionService";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import type { ModalBase } from "@/components/modal/models";

const { createQuestion, updateQuestion } = useQuestionService();

interface Props {
  question?: Question;
  mode: FormMode;
}

const props = withDefaults(defineProps<Props>(), {
  question: () =>
    ({
      id: 0,
      order: 0,
      title: "",
      type: QuestionType.Level,
      showInDashboard: false,
    } as Question),
});

interface Emits {
  (e: "close"): void;
  (e: "saved", question: Question): void;
}
const emit = defineEmits<Emits>();

const questionTypes: QuestionType[] = [
  QuestionType.Level,
  QuestionType.SingleSelection,
  QuestionType.MultipleSelection,
  QuestionType.LongText,
  QuestionType.ShortText,
  QuestionType.Tags,
];

const questionTypeItems: QuestionTypeItem[] = questionTypes.map((t) => ({
  resultingType: t,
  title: i18n.global.t(`pages.admin.ai_assistant.create_new_survey_form.question.${t.toLowerCase()}.title`),
  description: i18n.global.t(`pages.admin.ai_assistant.create_new_survey_form.question.${t.toLowerCase()}.description`),
}));

const questionEditable = ref<Question>({ ...props.question });
const currentStep = ref<number>(1);
const showAllErrors = ref<boolean>(false);
const isQuestionValid = ref<boolean>(false);

const next = async (): Promise<void> => {
  if (currentStep.value < 3) {
    currentStep.value++;
  }
  if (currentStep.value === 3) {
    showAllErrors.value = true;
  }
};
const prev = (): void => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};
const editedQuestionUpdated = (newState: Question, isValid: boolean) => {
  questionEditable.value = newState;
  isQuestionValid.value = isValid;
};
const save = async (): Promise<void> => {
  const response: Question = await updateOrCreateQuestion();
  emit("saved", response);
  emit("close");
};
const updateOrCreateQuestion = (): Promise<Question> => {
  if (props.mode === "edit") {
    return updateQuestion(questionEditable.value);
  }
  return createQuestion(questionEditable.value);
};

const stepClick = (step: number): void => {
  currentStep.value = step;
};

const typeSelect = (value: QuestionType): void => {
  if (props.mode === "edit") {
    return;
  }
  questionEditable.value = { ...props.question, type: value };
  showAllErrors.value = false;
};

const advanceStepIsEditMode = (): void => {
  if (props.mode === "edit") {
    currentStep.value++;
  }
};

const modalConfig: ModalBase = {
  title: i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.title"),
  variant: "large",
  closeCallback: () => emit("close"),
};

onMounted(() => advanceStepIsEditMode());
</script>
<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.question-wizard {
  &__content {
    padding: 0 1rem;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
