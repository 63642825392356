<template>
  <LayoutPage :title="$t('pages.admin.ai_assistant.title')">
    <div v-if="loaded" class="questions-overview" :class="{ 'questions-overview--with-kpis': hasKpis }">
      <div class="questions">
        <div class="questions__header text--bold" data-cy="questions-header">
          {{ $t("pages.admin.ai_assistant.questions.title") }}
        </div>
        <div class="questions__subheader">
          <div class="questions__navigation">
            <AppTab :tabs="tabs" :tab-id="$t('pages.admin.dashboard.welcome')" />
          </div>
          <div class="padding-h--l">
            <router-link to="/admin/ai-assistant/questions">
              <span class="text text--bold text--link" data-cy="questions-footer">
                {{ $t("pages.admin.ai_assistant.questions.seeAllQuestions") }}
              </span>
            </router-link>
          </div>
        </div>
        <div v-if="questions.length > 0" class="questions__list">
          <QuestionsTable
            :questions="questions"
            :allow-add-to-dashboard="questionStatus === QuestionStatus.Published"
            :question-status="questionStatus"
            @question-dashboard-toggle="questionToggledFromTable"
            @question-edited="fetchData"
            @question-deleted="questionDeleted" />
        </div>
        <div v-else>
          <AppSeparator class="margin-v--s" />
          <NoQuestionsContent :question-status="questionStatus" />
        </div>
      </div>
      <QuestionsOnBoarding v-if="!hasKpis" @question-created="fetchData" />
      <QuestionsKpis :event-emitter="eventEmitter" @loaded="(kpisDefined:boolean) => hasKpis = kpisDefined" @kpi-removed="kpiRemoved" />
    </div>
    <AppModal name="question-preview">
      <QuestionPreview v-if="selectedQuestion" :item="selectedQuestion" />
    </AppModal>
  </LayoutPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import i18n from "@/i18n";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppModal from "@/components/modal/AppModal.vue";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import QuestionPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionPreview.vue";
import QuestionsKpis from "@/views/admin/ai-assistant/components/kpis/QuestionsKpis.vue";
import QuestionsTable from "@/views/admin/ai-assistant/components/grid/QuestionsTable.vue";
import QuestionsOnBoarding from "@/views/admin/ai-assistant/components/QuestionsOnBoarding.vue";
import NoQuestionsContent from "@/views/admin/ai-assistant/components/NoQuestionsContent.vue";
import type { Question } from "@/models/question";
import { QuestionStatus } from "@/models/questionStatus";
import useQuestionService from "@/services/useQuestionService";
import useEventEmitterService from "@/services/useEventEmitterService";
import type { QuestionKpiInfo } from "@/models/questionKpi";

const { getPublishedQuestions, getUnpublishedQuestions } = useQuestionService();
const eventEmitter = useEventEmitterService();

const questionStatus = ref<QuestionStatus>(QuestionStatus.Unpublished);
const questions = ref<Question[]>([]);
const selectedQuestion = ref<Question | undefined>();
const hasKpis = ref<boolean>(true);
const loaded = ref<boolean>(false);

const pageSize = 8;

const fetchData = async (): Promise<void> => {
  const caller = questionStatus.value === QuestionStatus.Published ? getPublishedQuestions : getUnpublishedQuestions;
  questions.value = await caller(pageSize);
};
const questionDeleted = async (question: Question): Promise<void> => {
  await eventEmitter.trigger("questionDeleted", question);
  await fetchData();
};
const questionToggledFromTable = (question: Question) => eventEmitter.trigger("questionKpiToggled", question);
const kpiRemoved = (kpiRemoved: QuestionKpiInfo) => {
  const question = questions.value.find((q) => q.id === kpiRemoved.id);
  if (question) {
    question.showInDashboard = false;
  }
};

onMounted(async () => {
  await fetchData();
  loaded.value = true;
});

const loadQuestions = async (newStatus: QuestionStatus) => {
  questionStatus.value = newStatus;
  await fetchData();
};

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages.admin.ai_assistant.questions.created_questions"),
    action: () => loadQuestions(QuestionStatus.Unpublished),
    dataCy: "created",
  },
  {
    text: i18n.global.t("pages.admin.ai_assistant.questions.sent_questions"),
    action: () => loadQuestions(QuestionStatus.Published),
    dataCy: "sent",
  },
];
</script>

<style scoped lang="scss">
@import "@/styles/utils/responsive";
.questions-overview {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--space-s);

  @media only screen and (min-width: $medium-screen-breakpoint) {
    & {
      grid-column-gap: var(--space-s);
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "questions ."
        "questions .";
      grid-row-gap: 0;
    }
  }
  &--with-kpis {
    grid-row-gap: var(--space-s);
  }
}
.questions {
  background-color: var(--color-card);
  border: 1px solid var(--color-border);
  border-radius: var(--default-border-radius);
  @media only screen and (min-width: $medium-screen-breakpoint) {
    & {
      grid-area: questions;
    }
  }

  &__header {
    padding: var(--size-s);
    color: var(--color-primary-accent1);
  }
  &__navigation {
    padding: var(--size-xs) var(--size-s);
  }
  &__list {
    padding: var(--size-s) 0;
  }
  &__subheader {
    padding-bottom: 0;
    color: var(--color-primary-accent1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
