import type { TableItem, TableItemSelectable } from "@/components/table/TableItem";
import type { QuestionSelectable } from "@/models/question";

export interface CompanyEmployeeGroupBase extends TableItemSelectable {
  id: number;
  name: string;
  description?: string;
  employeeCount: number;
}

export interface CompanyEmployeeGroupInfo extends CompanyEmployeeGroupBase {
  courseCount: number;
}

export interface CompanyEmployeeGroup extends CompanyEmployeeGroupBase {
  employeeIds: number[];
}

export enum EmployeeGroupRole {
  Viewer = "Viewer",
  Manager = "Manager",
}

export interface EmployeeGroupEmployee extends TableItem {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  employeeRole: EmployeeGroupRole;
}

export interface EmployeeWithGroups extends TableItem {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  groups: EmployeeWithGroupsEmployee[];
}

export interface EmployeeWithGroupsEmployee extends TableItem {
  id: number;
  employeeId: number;
  employeeRole: EmployeeGroupRole;
  companyEmployeeGroupId: number;
  groupName: string;
}

export interface EmployeeGroupInEmployee extends TableItem {
  employeeRole: EmployeeGroupRole;
  companyEmployeeGroupId: number;
  groupName: string;
}

export interface CompanyEmployeeGroupWithEmployees extends CompanyEmployeeGroupBase {
  employees: EmployeeGroupEmployee[];
}

export interface EmployeeGroupQuestion extends TableItemSelectable {
  id: number;
  title: string;
  answeredPercentage: number;
  sendDate: string;
}

export interface CompanyEmployeeGroupWithQuestions extends CompanyEmployeeGroupBase {
  questions: QuestionSelectable[];
}
