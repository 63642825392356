<template>
  <layout-page :title="$t('pages.admin.company_settings.title')">
    <ItManagerCompanySettingsNavigation v-if="company" :company="company" :current="currentTab" @tab-changed="tabChanged" />
    <Component :is="currentComponent" v-if="company" />
  </layout-page>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, type Component } from "vue";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import ItManagerCompanySettingsNavigation, {
  type AllowedSettingsTabs,
} from "@/views/it-manager/company-settings/components/ItManagerCompanySettingsNavigation.vue";
import type { Company } from "@/models/company";
import useCompanySettingsService from "@/services/useCompanySettingsService";
import AdminCompanySettingsGeneralInfo from "@/views/admin/company-settings/components/AdminCompanySettingsGeneralInfo.vue";
import AdminCompanySettingsAzureInfo from "@/views/admin/company-settings/components/AdminCompanySettingsAzureInfo.vue";
import AdminRoleUsers from "@/views/admin/company-settings/components/AdminRoleUsers.vue";
import ChatBotInstructions from "@/views/admin/company-settings/components/chatbot/ChatBotInstructions.vue";

const components: Map<AllowedSettingsTabs, Component> = new Map<AllowedSettingsTabs, Component>([
  ["GeneralInfo", AdminCompanySettingsGeneralInfo],
  ["AzureInfo", AdminCompanySettingsAzureInfo],
  ["RolesManagement", AdminRoleUsers],
  ["ChatBotInstructions", ChatBotInstructions],
]);

const { getCompany } = useCompanySettingsService();

const company = ref<Company>();
const currentTab = ref<AllowedSettingsTabs>("GeneralInfo");
const currentComponent = computed<Component>(() => components.get(currentTab.value) ?? AdminRoleUsers);

const tabChanged = (newTab: AllowedSettingsTabs) => (currentTab.value = newTab);

onMounted(async () => (company.value = await getCompany()));
</script>
