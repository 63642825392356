<template>
  <div class="course-carousel">
    <h2 v-if="title">{{ title }}</h2>
    <AppCarousel v-if="courses.length > 0" :number-of-items="courses.length" data-cy="carousel-courses">
      <template v-for="(course, index) in courses" :key="course.id" #[`item_${index+1}`]>
        <CourseCard
          :course="course"
          :show-admin-options="showAdminOptions"
          @click="selectCourse(course)"
          @edit="editCourse(course)"
          @delete="deleteCourse(course)" />
      </template>
    </AppCarousel>
  </div>
</template>

<script setup lang="ts">
import AppCarousel from "@/components/carousel/AppCarousel.vue";
import CourseCard from "@/views/shared/learning/CourseCard.vue";
import type { EmployeeCourse } from "@/models/course";

interface Props {
  courses: EmployeeCourse[];
  title?: string;
  showAdminOptions?: boolean;
}
defineProps<Props>();

interface Emits {
  (e: "courseSelected", course: EmployeeCourse): void;
  (e: "edit", course: EmployeeCourse): void;
  (e: "delete", course: EmployeeCourse): void;
}
const emit = defineEmits<Emits>();

const selectCourse = (course: EmployeeCourse) => emit("courseSelected", course);
const editCourse = (course: EmployeeCourse) => emit("edit", course);
const deleteCourse = (course: EmployeeCourse) => emit("delete", course);
</script>

<style scope lang="scss">
.course-carousel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}
</style>
