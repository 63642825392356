import type { Ref } from "vue";
import { ref } from "vue";
import type { AxiosInstance, AxiosResponse } from "axios";
import axios from "axios";
import { fleebeAppUrl } from "@/constants/urls";
import { interceptIsoDates } from "@/services/shared/dateConverterInterceptor";
import { interceptIsoDateTimes } from "@/services/shared/dateTimeConverterInterceptor";
import { showError, showErrorIfNeeded } from "@/utils/apiErrorsUtil";
import useLoading from "@/services/shared/useLoading";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HttpClientRequest {}
interface UseHttpClientReturn {
  loading: Ref<boolean>;
  get: <T>(url: string, params?: { [key: string]: unknown }) => Promise<T>;
  post: <T>(url: string, data?: HttpClientRequest) => Promise<T>;
  put: <T>(url: string, data?: HttpClientRequest) => Promise<T>;
  patch: <T>(url: string, data: HttpClientRequest) => Promise<T>;
  httpDelete: <T>(url: string, data?: HttpClientRequest) => Promise<T>;
  appBaseUrl: string | undefined;
}

export default (): UseHttpClientReturn => {
  const loading = ref<boolean>(false);

  const api: AxiosInstance = axios.create({
    baseURL: fleebeAppUrl,
    withCredentials: true,
    headers: {
      "X-CSRF": "1",
    },
  });

  api.interceptors.response.use((originalResponse: AxiosResponse) => {
    interceptIsoDateTimes(originalResponse.data);
    interceptIsoDates(originalResponse.data);
    return originalResponse;
  });

  const get = <T>(url: string, params?: { [key: string]: unknown }) => invokeApi(() => api.get<T>(url, { params }), true);
  const post = (url: string, data?: HttpClientRequest) => invokeApi(() => api.post(url, data));
  const put = (url: string, data?: HttpClientRequest) => invokeApi(() => api.put(url, data));
  const patch = (url: string, data: HttpClientRequest) => invokeApi(() => api.patch(url, data));
  const httpDelete = (url: string, data?: HttpClientRequest) => invokeApi(() => api.delete(url, { data }));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const invokeApi = async <T>(caller: () => Promise<AxiosResponse<T>>, delayLoading = false): Promise<T> => {
    const { startLoading } = useLoading();
    const endLoading = startLoading(delayLoading, loading);
    try {
      const response = await caller();
      showErrorIfNeeded(response);
      return response.data;
    } catch (exception) {
      showError(exception);
      throw exception;
    } finally {
      endLoading();
    }
  };

  const { baseURL: appBaseUrl } = api.defaults;

  return { loading, appBaseUrl, get, post, put, patch, httpDelete };
};
