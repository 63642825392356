<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :disable-key-enter="true">
    <div class="admin-company-goals-applicants" data-cy="employees-recommender">
      <AppInputText
        v-model="searchText"
        :placeholder="$t('pages.admin.company_goals.goal_detail.employees_modal.search.placeholder')"
        :maxlength="200"
        :bg-transparent="true"
        class="search" />
      <div class="text--bold margin-b--s">
        {{ $t(`shared.applicants`, searchedEmployees.length) }}
      </div>
      <div v-if="employeesInView.length > 0" class="employee-list">
        <div class="employee-list__title margin-r--xl">
          <span>{{ $t("pages.admin.company_goals.goal_detail.employees_modal.list_title.employee") }}</span>
          <span>{{ $t("pages.admin.company_goals.goal_detail.employees_modal.list_title.match_with_goal") }}</span>
        </div>
        <div>
          <AppSeparator />
        </div>
        <div class="employee-list__content margin-b--s">
          <AppEmployeeGoalProfile
            v-for="employee in employeesInView"
            :key="employee.employeeId"
            :employee="employee"
            class="employee-list__item"
            size="s"
            display-add
            @details-click="viewEmployee"
            @add-click="addEmployee" />
        </div>
        <div class="employee-list__actions">
          <AppButton :text="$t('buttons.previous')" variant="secondary" size="m" :disabled="currentPage === 1" @onclick="previousPage" />
          <AppTag :main-text="`${currentPage} / ${totalPages}`" type="presence" :limited-size="true" />
          <AppButton
            :text="$t('buttons.next')"
            variant="secondary"
            size="m"
            :disabled="currentPage * pageSize >= totalEmployees"
            @onclick="nextPage" />
        </div>
      </div>
    </div>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { containsInsensitive } from "@/utils/stringUtils";
import AppButton from "@/components/AppButton.vue";
import AppEmployeeGoalProfile from "@/components/AppEmployeeGoalProfile.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppTag from "@/components/AppTag.vue";
import i18n from "@/i18n";
import router from "@/router/router";
import type { CompanyGoalEmployee } from "@/models/companyGoalEmployee";
import type { CompanyGoalWithEmployees } from "@/models/companyGoal";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import type { ModalBase } from "@/components/modal/models";

interface Props {
  goal: CompanyGoalWithEmployees;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "close-click"): void;
  (e: "add-click", employee: CompanyGoalEmployee): void;
}>();

const { getCompanyGoalRecommendedEmployees } = useCompanyGoalsService();
const employees = ref<CompanyGoalEmployee[]>([]);
const employeesInView = ref<CompanyGoalEmployee[]>([]);
const searchText = ref<string>("");

const pageSize = 10;
const currentPage = ref<number>(1);
const totalEmployees = computed(() => searchedEmployees.value.length || 0);
const totalPages = computed(() => (totalEmployees.value > 0 ? Math.ceil(totalEmployees.value / pageSize) : 1));

const searchedEmployees = computed(() =>
  employees.value
    .filter((e) => containsInsensitive(`${e.firstName} ${e.lastName}`, searchText.value))
    .sort((a, b) => (a.goalTargetFit - b.goalTargetFit) * -1)
);

watch(currentPage, () => refreshEmployeesInView());
watch(searchedEmployees, () => refreshEmployeesInView(true));

onMounted(() => getEmployees());

const modalConfig: ModalBase = {
  title: i18n.global.t("pages.admin.company_goals.goal_detail.employees_modal.title"),
  variant: "floating",
  closeCallback: () => emit("close-click"),
};

const getEmployees = async (): Promise<void> => {
  const allEmployees = props.goal.isPublic ? props.goal.queuedEmployees : await getCompanyGoalRecommendedEmployees(props.goal.id);

  employees.value = allEmployees.filter(
    (employee) =>
      !props.goal.assignedEmployees.find((assigned) => {
        return assigned.employeeId === employee.employeeId;
      })
  );
};

const previousPage = (): void => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = (): void => {
  if (currentPage.value <= totalPages.value) {
    currentPage.value++;
  }
};

const viewEmployee = (employeeId: number) =>
  router.push({
    name: "dashboard-admin-employee",
    params: { employeeId },
    query: { backUrl: `/admin/corporate-goals/goal/${props.goal.id}` },
  });

const addEmployee = (employee: CompanyGoalEmployee): void => emit("add-click", employee);

const refreshEmployeesInView = (isSearchingByText = false): void => (isSearchingByText ? refreshPageOne() : refreshSelectedPage());

const refreshPageOne = (): void => {
  employeesInView.value = searchedEmployees.value.slice(0, pageSize);
  currentPage.value = 1;
};

const refreshSelectedPage = (): void => {
  const initialItem = (currentPage.value - 1) * pageSize;
  const finalItem = initialItem + pageSize;
  employeesInView.value = searchedEmployees.value.slice(initialItem, finalItem) || [];
};
</script>

<style scoped lang="scss">
.admin-company-goals-applicants {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.employee-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: var(--size-xs);
    overflow-y: auto;
  }

  &__item {
    padding: 6px;

    &:hover {
      background-color: var(--color-company-goals-employee-recommender-background--hover);
      border-radius: 4px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
}
</style>
