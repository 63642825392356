import { reactive, readonly } from "vue";

interface Toaster {
  name: string;
  content: string;
}

interface UseToasterReturn {
  state: Readonly<{ readonly toasters: readonly Toaster[] }>;
  isOpen: (name: string) => boolean;
  getContent: (name: string) => string | undefined;
  closeToaster: (name: string) => void;
  openToaster: (name: "default" | "error" | "success", content: string) => void;
}
const state = reactive<{ toasters: Toaster[] }>({
  toasters: [],
});

export default (): UseToasterReturn => {
  const isOpen = (name: string): boolean => state.toasters.some((t: Toaster) => t.name === name);

  const getContent = (name: string): string | undefined => state.toasters.find((t: Toaster) => t.name === name)?.content;

  const closeToaster = (name: string): void => {
    state.toasters = state.toasters.filter((t: Toaster) => t.name !== name);
  };

  const openToaster = (name: "default" | "error" | "success", content: string): void => {
    if (isOpen(name)) {
      return;
    }
    state.toasters.push({ name, content });
  };

  return {
    state: readonly(state),
    isOpen,
    getContent,
    closeToaster,
    openToaster,
  };
};
