<template>
  <div class="app-input-text">
    <AppBasicText
      :autofocus="autofocus"
      :model-value="modelValue"
      :type="type"
      :icon="icon"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :label="label"
      :keep-margin="keepMargin"
      :disabled="disabled"
      :bg-transparent="bgTransparent"
      @update:model-value="updateModelValue" />
    <AppInputError :error="error" />
  </div>
</template>

<script setup lang="ts">
import AppBasicText from "@/components/AppBasicText.vue";
import AppInputError from "@/components/AppInputError.vue";

interface Props {
  modelValue?: string;
  label?: string;
  placeholder?: string;
  type?: "text" | "password";
  error?: string;
  maxlength?: number;
  disabled?: boolean;
  autofocus?: boolean;
  icon?: "magnify";
  keepMargin?: boolean;
  bgTransparent?: boolean;
}
withDefaults(defineProps<Props>(), { keepMargin: true });

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();
const updateModelValue = (newValue: string) => emit("update:modelValue", newValue);
</script>

<style scoped lang="scss">
.app-input-text {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  padding-bottom: 0.6rem;
}
</style>
