<template>
  <div class="admin-talent-detection__employee-list employee-list">
    <div v-if="employees.length" class="employee-list__content">
      <div class="employee-list__content-title">
        <span class="employee-list__content-title-category" :style="{ 'background-color': categoryColor }"></span>
        <span class="text text--bold text--primary-accent1">{{ title }}</span>
      </div>
      <div class="employee-list__content-profiles">
        <div
          v-for="(employee, index) in employees"
          :key="employee.id"
          class="employee-list__content-profiles-item item"
          data-cy="employee-list">
          <span class="text text--bold text--tertiary">{{ index + 1 }}</span>
          <ProfileInformation size="s" :employee="employee" direction="column" @details-click="goToProfile" />
          <div v-if="showProgress" class="item__progression">
            <AppProgressBar :value="employee.motivation" />
            <span class="text text--s text--bold">{{ $n(employee.motivation / 100, "percent") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="employee-list__no-content" data-cy="no-talent-employee-list">
      <AppNoContentYet :text="$t('pages.admin.talent_detection.employees.no_content')" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { hobbiesColorMap } from "@/views/admin/talent-detection/utils";
import { HobbyCategory } from "@/models/hobbyCategory";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppProgressBar from "@/components/AppProgressBar.vue";
import ProfileInformation from "@/components/AppProfileInformation.vue";
import router from "@/router/router";
import type { EmployeeMotivation } from "@/models/employee/employeeMotivation";

interface Props {
  employees: EmployeeMotivation[];
  category?: HobbyCategory;
  title?: string;
}
const props = defineProps<Props>();

const showProgress = false;
const categoryColor = computed(() => (props.category ? hobbiesColorMap[props.category] : hobbiesColorMap[HobbyCategory.Sport]));

const goToProfile = (employeeId: number) =>
  router.push({
    name: "dashboard-admin-employee",
    params: { employeeId },
    query: { backRoute: "talent-detection-admin" },
  });
</script>
<style lang="scss" scoped>
.employee-list {
  background-color: var(--color-card);
  border-radius: var(--default-border-radius);
  box-shadow: 0 var(--space-xs) var(--space-m) rgba(26, 26, 26, 0.04);
  height: 100%;
  min-height: 60rem;
  min-width: 40rem;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content-title {
    padding: var(--space-m) var(--space-l) var(--space-s);
  }

  &__content-title-category {
    border-radius: var(--size-xxs);
    display: inline-block;
    height: 20px;
    margin-right: 12px;
    vertical-align: bottom;
    width: 20px;
  }

  &__content-profiles {
    display: flex;
    flex-direction: column;
    max-height: 80rem;
    overflow: auto;
    border-radius: 0 0 6px 6px;
  }

  &__no-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

.item {
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  display: grid;
  gap: var(--space-s);
  grid-template-columns: 2rem 1fr;
  min-height: 80px;
  padding: var(--space-xs) var(--space-l);
  text-align: left;

  &__progression {
    display: flex;
    align-items: center;
    gap: var(--space-s);
  }
}
</style>
