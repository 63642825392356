<template>
  <EmployeeList :employees="employees" :title="title" :category="selection?.hobbiesCategory" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import EmployeeList from "@/views/admin/talent-detection/components/employee-lists/AdminTalentDetectionEmployeeList.vue";
import i18n from "@/i18n";
import type { GroupChartSelection } from "@/models/talent-detection/chartSelections";
import type { EmployeeMotivation } from "@/models/employee/employeeMotivation";
import useCompanyTalentDetectionService from "@/services/useCompanyTalentDetectionService";

const { getEmployeesByGroup } = useCompanyTalentDetectionService();

interface Props {
  selection?: GroupChartSelection;
}

const props = defineProps<Props>();
const employees = ref<EmployeeMotivation[]>([]);

const title = computed(() => {
  if (!props.selection) {
    return;
  }
  return i18n.global.t("pages.admin.talent_detection.employees_group.title", {
    n: employees.value.length,
    group: props.selection.group?.name,
    category: props.selection?.hobby?.name ?? props.selection?.hobbiesCategory,
  });
});

watch(
  () => props.selection,
  () => refreshEmployees()
);

const refreshEmployees = async () => {
  if (!props.selection?.hobbiesCategory) {
    employees.value = [];
    return;
  }
  employees.value = await getEmployeesByGroup(props.selection.hobbiesCategory, props.selection.group?.id, props.selection.hobby?.id);
};
</script>
