<template>
  <div class="hobbies">
    <AppNoContentYet v-if="!loading && !readyToShowHobbies.length" :text="$t(noContentMessage())" />
    <div v-else class="hobbies__container">
      <AppHobbyCategoryFrame
        data-cy="hobby-sport-frame"
        class="include-separator"
        :category="HobbyCategory.Sport"
        :hobbies="readyToShowHobbies" />
      <AppHobbyCategoryFrame
        data-cy="hobby-music-frame"
        class="include-separator"
        :category="HobbyCategory.Music"
        :hobbies="readyToShowHobbies" />
      <AppHobbyCategoryFrame data-cy="hobby-culture-frame" :category="HobbyCategory.Culture" :hobbies="readyToShowHobbies" />
      <AppHobbyCategoryFrame
        data-cy="hobby-technology-frame"
        class="include-separator"
        :category="HobbyCategory.Technology"
        :hobbies="readyToShowHobbies" />
      <AppHobbyCategoryFrame data-cy="hobby-others-frame" :category="HobbyCategory.Others" :hobbies="readyToShowHobbies" />
    </div>

    <AppLinkButton
      v-if="formMode !== 'view'"
      data-cy="hobbies-panel-open-edit"
      :text="$t('entities.my_interests.add-hobbies')"
      @click="openModal(panelName)" />

    <AppModal :name="panelName">
      <AppHobbiesEditor v-model="hobbies" />
    </AppModal>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import i18n from "@/i18n";
import AppModal from "@/components/modal/AppModal.vue";
import AppHobbiesEditor from "@/components/AppHobbiesEditor.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppHobbyCategoryFrame from "@/components/AppHobbyCategoryFrame.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import useModal from "@/components/modal/useModal";
import type { FormMode } from "@/models/formMode";
import type { Hobby } from "@/models/hobby";
import { HobbyCategory } from "@/models/hobbyCategory";
import useEmployeeService from "@/services/useEmployeeService";
import { compareStrings } from "@/utils/stringUtils";
import useMeHobbiesService from "@/services/useMeHobbiesService";

const { getEmployeeHobbies, updateEmployeeHobbies, loading } = useMeHobbiesService();
const { openComponentModal } = useModal();
const { getEmployeeHobbies: getImpersonatedEmployeeHobbies } = useEmployeeService();
const panelName = "HobbiesPanel";

interface Props {
  formMode?: FormMode;
  impersonatedAs?: number;
}
const props = withDefaults(defineProps<Props>(), { formMode: "edit", impersonatedAs: 0 });
const hobbies = ref<Hobby[]>([]);
const readyToShowHobbies = ref<Hobby[]>([]);

onMounted(() => initialize());

const noContentMessage = () =>
  props.impersonatedAs > 0 ? "pages.admin.dashboard.interests.hobbies.no_content_yet" : "shared.app_tag.no_content_yet";

const initialize = async (): Promise<void> => {
  const functionToBeApplied =
    props.impersonatedAs > 0 ? getImpersonatedEmployeeHobbies.bind(null, props.impersonatedAs) : getEmployeeHobbies;
  const employeeHobbies = await functionToBeApplied();
  hobbies.value = [...employeeHobbies].sort((hobby1: Hobby, hobby2: Hobby) => compareStrings(hobby1.name, hobby2.name));
  readyToShowHobbies.value = hobbies.value;
};

const save = async () => {
  await updateEmployeeHobbies(hobbies.value);
  await initialize();
};

const close = () => {
  hobbies.value = readyToShowHobbies.value;
};

const openModal = (componentId: string): void => {
  openComponentModal(componentId, {
    title: i18n.global.t("entities.my_interests.hobbies.edit-modal.title"),
    variant: "floating",
    confirmText: i18n.global.t("buttons.save"),
    showCancelToClose: true,
    confirmCallback: save,
    closeCallback: close,
  });
};
</script>

<style lang="scss" scoped>
.hobbies {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 6.4rem;
    row-gap: 3.2rem;
  }
}
.include-separator {
  border-right: 1px solid var(--color-divider);
  padding-right: var(--space-l);
}
</style>
