import type { EmployeeBasicInformation } from "@/models/employeeBasicInformation";
import type { ProfileInformation } from "@/models/profileInformation";
import type { FileAttachment } from "@/models/fileAttachment";
import type { Ref } from "vue";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me";
const { get, put, post, httpDelete, loading, appBaseUrl } = useHttpClient();

interface UseMeServiceReturn {
  deleteCurriculum: () => Promise<ProfileInformation>;
  getBasicInformation: () => Promise<EmployeeBasicInformation>;
  getBasicProfile: () => Promise<ProfileInformation>;
  getCurriculumUrl: () => string;
  getProfileInformation: () => Promise<ProfileInformation>;
  importProfile: (profile: FileAttachment) => Promise<void>;
  saveCurriculum: (curriculum: FileAttachment) => Promise<void>;
  updateAboutMe: (aboutMe: string) => Promise<void>;
  updateBirthDate: (birthDate: Date) => Promise<void>;
  updateCalculateLearningPath: (calculate: boolean) => Promise<void>;
  getFeedbackEmail: () => Promise<string>;
  loading: Ref<boolean>;
}

export default (): UseMeServiceReturn => {
  const deleteCurriculum = (): Promise<ProfileInformation> => httpDelete(`${BASE_URL}/curriculum`);

  const getBasicInformation = () => get<EmployeeBasicInformation>(`${BASE_URL}/basic-information`);
  const getBasicProfile = () => get<ProfileInformation>(`${BASE_URL}/basic-profile`);
  const getProfileInformation = () => get<ProfileInformation>(BASE_URL);
  const importProfile = (profile: FileAttachment) => post<void>(`${BASE_URL}/import-profile/`, profile);
  const saveCurriculum = (curriculum: FileAttachment) => post<void>(`${BASE_URL}/curriculum/`, curriculum);
  const updateAboutMe = (aboutMe: string) => put<void>(`${BASE_URL}/about-me`, { aboutMe });
  const updateBirthDate = (birthDate: Date) => put<void>(`${BASE_URL}/birth-date`, { birthDate });
  const updateCalculateLearningPath = (calculate: boolean) => put<void>(`${BASE_URL}/calculate-learning-path`, { calculate });
  const getCurriculumUrl = () => `${appBaseUrl}${BASE_URL}/curriculum`;
  const getFeedbackEmail = () => get<string>(`${BASE_URL}/feedbackEmail`);

  return {
    deleteCurriculum,
    getBasicInformation,
    getBasicProfile,
    getCurriculumUrl,
    getProfileInformation,
    importProfile,
    saveCurriculum,
    updateAboutMe,
    updateBirthDate,
    getFeedbackEmail,
    updateCalculateLearningPath,
    loading,
  };
};
