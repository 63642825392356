import type { Skill } from "@/models/skill";
import { SkillType } from "@/models/skillType";
import type { SkillWithProgression } from "@/models/skillWithProgression";

export const getGeneralSkills = function <Type extends Skill>(skills: Type[]): Type[] {
  return skills.filter((skill: Skill) => skill.type === SkillType.General);
};

export const getLanguageSkills = (skills: SkillWithProgression[]): SkillWithProgression[] => {
  return skills.filter((skill: Skill) => skill.type === SkillType.Language);
};

export type PartiallyRequired<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;
