import type { ModalCallback } from "@/components/modal/models";
import type { FormValidator } from "@/models/formValidator";
import { getCurrentInstance, type RendererNode } from "vue";

interface UseFormValidatorReturn {
  validateFormAndFocusError: (form: FormValidator) => Promise<boolean>;
  getFormCallbackHandler: (form: FormValidator, saveHandler: ModalCallback) => ModalCallback;
  getObjectFromForm: <T>(form: FormValidator) => Partial<T>;
}

const setFocusOnErrorInput = (node: RendererNode | null) => {
  if (!node) {
    return;
  }
  const errorElement = node.querySelector(".text--error:not(.hidden)");
  const firstFocusableElement = getFirstFocusableOnError(errorElement);
  if (firstFocusableElement) {
    firstFocusableElement.focus();
  }
};
const getFirstFocusableOnError = (errorElement?: HTMLElement): HTMLElement | undefined => {
  if (!errorElement) {
    return;
  }
  if (!errorElement.previousElementSibling) {
    return;
  }
  const previousSibling = errorElement.previousElementSibling as HTMLElement;
  if (previousSibling.tabIndex >= 0) {
    return previousSibling;
  }
  return previousSibling.querySelector("button, [href], input, select, textarea") as HTMLElement;
};

export default (): UseFormValidatorReturn => {
  const currentInstance = getCurrentInstance();
  const validateFormAndFocusError = async (form: FormValidator) => {
    const validationResult = await form.validate();
    if (!validationResult.valid && currentInstance) {
      setFocusOnErrorInput(currentInstance.vnode.el);
    }
    return validationResult.valid;
  };
  const getFormCallbackHandler = (form: FormValidator, saveHandler: ModalCallback): ModalCallback => {
    return async () => {
      const validationResult = await validateFormAndFocusError(form);

      if (!validationResult) {
        return false;
      }
      return saveHandler();
    };
  };
  const getObjectFromForm = <T>(form: FormValidator): Partial<T> => {
    const result = form.values as Partial<T>;
    type keysOfT = keyof T;
    for (const key of Object.keys(form.values)) {
      const keyT = key as keysOfT;
      if (typeof result[keyT] === "string" && result[keyT] === "") {
        result[keyT] = undefined;
      }
    }
    return result;
  };

  return { validateFormAndFocusError, getFormCallbackHandler, getObjectFromForm };
};
