<template>
  <div class="configurer-option">
    <AppInputText
      v-model="title"
      class="configurer-option__input"
      :label="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.option.title', { optionIndex: index })"
      :placeholder="$t('pages.admin.ai_assistant.create_new_survey_form.question_configure.option.placeholder')"
      :error="titleError"
      :maxlength="mediumTextLength"
      @update:model-value="emitUpdate" />
    <AppIconOnlyButton
      icon="minus-circle"
      data-cy="delete-option"
      size="xxl"
      :as-link="canBeDeleted"
      :disabled="!canBeDeleted"
      @click="deleteOption" />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { useField } from "vee-validate";
import AppInputText from "@/components/AppInputText.vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import { mediumTextLength } from "@/constants/restrictions";
import type { QuestionOption } from "@/models/questionOption";

interface Props {
  option: QuestionOption;
  index: number;
  canBeDeleted: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "optionUpdated", value: QuestionOption): void;
  (e: "optionDeleted", index: number): void;
}>();

const { value: title, errorMessage: titleError } = useField<string>(`options[${props.index}].title`);

const emitUpdate = () => {
  const newOptionState: QuestionOption = { ...props.option, title: title.value };
  emit("optionUpdated", newOptionState);
};
const deleteOption = () => emit("optionDeleted", props.index);

onMounted(() => (title.value = props.option.title));
</script>
<style scoped lang="scss">
.configurer-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-m);

  &__input {
    flex-grow: 1;
  }
}
</style>
