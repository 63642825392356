<template>
  <div class="avatar-container">
    <AppProfileAvatar size="s" :information="profileInfo" data-cy="avatar-header" />
    <div class="avatar-container__menu" :class="{ 'avatar-container__menu--show': showMenu }" tabindex="0">
      <div class="avatar-container__info">
        <AppProfileAvatar size="m" editable :information="profileInfo" @click="updatingAvatar = !updatingAvatar" />
        <div>
          <div class="margin-b--xs text text--bold">{{ profileInfo.firstName }} {{ profileInfo.lastName }}</div>
          <div class="margin-b--xs">{{ profileInfo.email }}</div>
          <div v-if="profileInfo.role" class="margin-b--xs">
            <span class="text text--bold">{{ $t("pages.header.role") }}:</span> {{ $t(`roles.${profileInfo.role.toLowerCase()}`) }}
          </div>
        </div>
      </div>
      <AppProfileAvatarEditor
        v-if="updatingAvatar"
        :employee-id="profileInfo.id"
        :picture-name="profileInfo.pictureName"
        @close="updatingAvatar = false" />
      <AppProfileEditor :birth-date="profileInfo.birthDate" @updated-birth-date="updatedBirthDate" />
      <AppFeedbackSender v-if="feedbackEmail" :feedback-email="feedbackEmail" />
      <AppButton
        v-if="currentRoute.meta.isAppArea && (profileInfo.role === UserRole.Administrator || profileInfo.role === UserRole.ITManager)"
        :text="$t(`pages.header.goToAdministratorArea`)"
        class="margin-b--xs"
        size="full-width"
        variant="secondary"
        @onclick="goToAdministratorArea()" />
      <AppButton
        v-if="currentRoute.meta.isAdministratorArea"
        :text="$t('pages.header.goToEmployeeArea')"
        class="margin-b--xs"
        size="full-width"
        variant="secondary"
        @onclick="goToEmployeeArea()" />
      <AppButton :text="$t('pages.header.logout')" variant="primary" size="full-width" data-cy="log-out" @onclick="redirectToLogOut()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import router from "@/router/router";
import AppButton from "@/components/AppButton.vue";
import AppProfileAvatarEditor from "@/components/AppProfileAvatarEditor.vue";
import AppProfileEditor from "@/components/AppProfileEditor.vue";
import AppFeedbackSender from "@/components/AppFeedbackSender.vue";
import AppProfileAvatar from "@/components/AppProfileAvatar.vue";
import useAuthenticationService from "@/services/useAuthenticationService";
import { UserRole } from "@/models/userRole";
import type { EmployeeBasicInformation } from "@/models/employeeBasicInformation";
import useMeService from "@/services/useMeService";

interface Props {
  profileInfo: EmployeeBasicInformation;
  showMenu: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "updatedBirthDate", newDate: Date) => void>();

const { redirectToLogOut, cleanUser } = useAuthenticationService();

const currentRoute = router.currentRoute;

const updatingAvatar = ref<boolean>(false);

const goToAdministratorArea = async () => {
  if (props.profileInfo.role === UserRole.Administrator) {
    await router.push({ name: "default-admin" });
    return;
  }
  await router.push({ name: "default-it-manager" });
};
const goToEmployeeArea = async () => {
  cleanUser();
  await router.push({ name: "default" });
};
const updatedBirthDate = (newBirthDate: Date) => emit("updatedBirthDate", newBirthDate);

const { getFeedbackEmail } = useMeService();
const feedbackEmail = ref<string | undefined>(undefined);
onBeforeMount(async () => (feedbackEmail.value = await getFeedbackEmail()));
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.avatar-container {
  cursor: pointer;
  position: relative;

  &__info {
    display: flex;
    flex-direction: row;
    gap: var(--space-m);
  }

  &__menu {
    cursor: auto;
    background: var(--color-neutral-100);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 1.5s ease;
    right: 0;
    padding: var(--space-m);
    max-width: min(80vw, 40rem);
    margin-top: var(--space-xs);
    border-radius: var(--default-border-radius);
    box-shadow: 0 8px 24px rgba(26, 26, 26, 0.04);
    z-index: var(--z-index-toaster);
    &--show {
      visibility: visible;
      opacity: 1;
      display: block;
      transition: all 0.3s ease;
    }
  }
}
</style>
