<template>
  <AppIconOnlyButton
    :icon="iconName"
    class="star"
    size="xxl"
    :as-link="false"
    @click="handleClick"
    @mouseover="hover"
    @mouseleave="leave" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";

interface Props {
  valueRequired: number;
  value: number;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "click", value: number): void;
  (e: "starHover", value: number): void;
  (e: "starLeave"): void;
}>();

const iconName = computed<string>(() => (props.value >= props.valueRequired ? "star" : "star-outline"));

const handleClick = () => emit("click", props.valueRequired);
const hover = () => emit("starHover", props.valueRequired);
const leave = () => emit("starLeave");
</script>

<style scoped lang="scss">
.star {
  color: var(--color-system-warning);
  cursor: pointer;
}
</style>
