<template>
  <div>
    <h3>{{ $t("pages.admin.ai_assistant.create_new_survey_form.question_configure.options.title") }}</h3>
    <p class="margin-t--s margin-b--m">
      {{ $t("pages.admin.ai_assistant.create_new_survey_form.question_configure.options.description") }}
    </p>
    <div class="container">
      <div :key="options.length" class="options margin-t--s">
        <div v-for="(option, index) in options" :key="option.optionId" class="options__item">
          <QuestionCreationWizardConfigurerOption
            :option="option"
            :index="index"
            :can-be-deleted="removeAllowed"
            @option-deleted="deleteOption"
            @option-updated="updateOption" />
        </div>
      </div>
      <div class="actions">
        <AppIconOnlyButton v-if="addAllowed" icon="plus-circle" data-cy="add-option" size="xxl" @click="addOption" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from "vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import QuestionCreationWizardConfigurerOption from "@/views/admin/ai-assistant/components/wizard/configurer/QuestionCreationWizardConfigurerOption.vue";
import { maximumNumberOfOptionsInQuestion } from "@/constants/restrictions";
import type { QuestionOption } from "@/models/questionOption";
import { compareNumbers } from "@/utils/numberUtils";

interface Props {
  options: QuestionOption[];
  numberOfOptionsRequired: number;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "optionsUpdated", value: QuestionOption[]) => void>();

const addAllowed = computed(() => props.options.length < maximumNumberOfOptionsInQuestion);
const removeAllowed = computed(() => props.numberOfOptionsRequired < props.options.length);
const emitUpdated = (newOptions: QuestionOption[]) => emit("optionsUpdated", newOptions);

const updateOption = (option: QuestionOption) => {
  const optionIndex = props.options.findIndex((opt) => opt.optionId === option.optionId);
  if (optionIndex < 0) {
    return;
  }
  const newOptions = [...props.options];
  newOptions[optionIndex] = option;
  emitUpdated(newOptions);
};
const deleteOption = (index: number): void => {
  if (!removeAllowed.value) {
    return;
  }
  const newOptions = [...props.options];
  newOptions.splice(index, 1);
  emitUpdated(newOptions);
};
const addOption = (): void => {
  const newOptions = [...props.options];
  const nextId = newOptions.map((opt) => opt.optionId).sort((a, b) => compareNumbers(a, b, false))[0] + 1;
  newOptions.push({ optionId: nextId, title: "" });
  emitUpdated(newOptions);
};

onMounted(() => {
  if (props.options.length > 0) {
    return;
  }
  const newOptions = [
    {
      optionId: 0,
      title: "",
    },
    {
      optionId: 1,
      title: "",
    },
  ];
  emitUpdated(newOptions);
});
</script>
<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  gap: var(--space-m);
  justify-content: space-between;
}

.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-m);

  &__item {
    flex: 0 0 30%;
  }
}
.actions {
  flex: 0 0 26px;
  margin-bottom: 36px;
  align-self: end;
}
</style>
