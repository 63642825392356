<template>
  <KpiChart :chart-options="chartOptions" :chart-series="superSeries" />
</template>

<script lang="ts" setup>
import type { ApexOptions } from "apexcharts";
import i18n from "@/i18n";
import KpiChart from "@/views/admin/ai-assistant/components/kpis/KpiChart.vue";
import { formatNumber } from "@/utils/numberUtils";

interface Props {
  series: number[];
  seriesPercentage: number[];
}

const props = defineProps<Props>();
const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const data = props.series.map((value: number, index: number) => {
  return {
    x: labels[index],
    y: value,
  };
});
const superSeries = [{ data }];
const chartColors = ["#1E9AC6"];
const chartOptions: ApexOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
    selection: {
      enabled: false,
    },
  },
  grid: {
    show: true,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      horizontal: true,
      distributed: true,
    },
  },
  states: {
    active: {
      filter: {
        type: "none",
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    normal: {
      filter: {
        type: "none",
      },
    },
  },
  colors: chartColors,
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 0.8,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    marker: {
      show: false,
    },
    style: {
      fontSize: "11px",
    },
    y: {
      title: {
        formatter(): string {
          return "";
        },
      },
      formatter: (_value, { dataPointIndex }) => `${formatNumber(props.seriesPercentage[dataPointIndex])} %`,
    },
  },
  xaxis: {
    categories: labels,
    title: {
      text: i18n.global.t("level_KPI.x.title"),
    },
  },
};
</script>
