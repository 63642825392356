<template>
  <div class="question-order-field" :class="{ 'question-order-field--with-order': customProps.allowSort }">
    <div>
      <AppIconOnlyButton
        v-if="customProps.allowSort"
        v-show="customProps.canDescend(item)"
        icon="chevron-down"
        data-cy="descend-question"
        :title="$t('pages.admin.ai_assistant.questions.grid.descend')"
        @click="descend" />
    </div>
    <span :class="`question-order-field__order-number${customProps.allowSort ? '--sort' : ''}`">{{ item.order }}</span>
    <AppIconOnlyButton
      v-if="customProps.allowSort"
      v-show="customProps.canAscend(item)"
      icon="chevron-up"
      data-cy="ascend-question"
      :title="$t('pages.admin.ai_assistant.questions.grid.ascend')"
      @click="ascend" />
  </div>
</template>

<script lang="ts" setup>
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import type { Question } from "@/models/question";
import type { QuestionOrderCustomProps } from "@/views/admin/ai-assistant/components/grid/QuestionOrderCustomProps";

interface Props {
  item: Question;
  customProps: QuestionOrderCustomProps;
}
const props = defineProps<Props>();

const ascend = () => props.customProps.ascend(props.item);
const descend = () => props.customProps.descend(props.item);
</script>

<style lang="scss">
.question-order-field {
  display: grid;
  justify-content: right;
  grid-template-columns: 2.8rem;
  padding-right: 1rem;
  &--with-order {
    grid-template-columns: 1.6rem 2.8rem 1.6rem;
  }
  &__order-number {
    text-align: right;
    &--sort {
      text-align: center;
    }
  }
}
</style>
