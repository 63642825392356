<template>
  <div class="app-autocomplete">
    <AppBasicText
      v-model="searchTerm"
      :autofocus="autofocus"
      :placeholder="placeholder"
      :maxlength="maxlength"
      icon="magnify"
      :show-clean-option="true"
      :bg-transparent="true"
      data-cy="autocomplete"
      @changed="inputChanged"
      @click="inputFocusIn"
      @focusin="inputFocusIn"
      @blur="removeFocus"
      @keydown.up="focusPreviousItem"
      @keydown.down="focusNextItem"
      @keydown.enter="chooseCurrentItem"
      @cleaned="cleaned" />

    <div v-if="availableItems.length > 0 && hasInputFocus" class="app-autocomplete__results" data-cy="autocomplete-results">
      <div
        v-for="(item, index) in availableItems"
        :key="item.id"
        v-app-scroll-to="index === selectedItemIndex"
        type="button"
        class="app-autocomplete__results__item text"
        :class="{ 'app-autocomplete__results__item--focused': index === selectedItemIndex }"
        @mousedown="itemClick(item)">
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AutocompleteItem } from "@/models/autocompleteItem";
import { computed, ref } from "vue";
import AppBasicText from "@/components/AppBasicText.vue";

interface Props {
  items: AutocompleteItem[];
  label?: string;
  placeholder?: string;
  maxlength?: number;
  autofocus?: boolean;
  selfFiltering?: boolean;
  keepSelection?: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "change", value: string): void;
  (e: "click", value: AutocompleteItem): void;
  (e: "cleaned"): void;
}>();

const searchTerm = ref<string>("");
const selectedItemIndex = ref<number | undefined>();
const hasInputFocus = ref<boolean>(false);

const availableItems = computed<AutocompleteItem[]>(() => {
  if (props.selfFiltering && searchTerm.value) {
    return props.items.filter((i) => i.text.toLowerCase().includes(searchTerm.value.toLowerCase()));
  }
  return props.items;
});

const removeFocus = (): void => {
  selectedItemIndex.value = undefined;
  hasInputFocus.value = false;
};
const inputChanged = (): void => emit("change", searchTerm.value);
const inputFocusIn = (): void => {
  hasInputFocus.value = true;
  inputChanged();
};

const cleaned = () => emit("cleaned");

const focusNextItem = (): void => {
  if (selectedItemIndex.value === undefined) {
    selectedItemIndex.value = 0;
    hasInputFocus.value = true;
    return;
  }
  if (selectedItemIndex.value >= availableItems.value.length - 1) {
    selectedItemIndex.value = availableItems.value.length - 1;
    return;
  }
  selectedItemIndex.value = selectedItemIndex.value + 1;
};
const focusPreviousItem = (): void => {
  if (selectedItemIndex.value === undefined || selectedItemIndex.value === 0) {
    return;
  }
  selectedItemIndex.value = selectedItemIndex.value - 1;
};
const chooseCurrentItem = (args: KeyboardEvent): void => {
  args.stopImmediatePropagation();
  if (selectedItemIndex.value === undefined) {
    return;
  }
  itemClick(availableItems.value[selectedItemIndex.value]);
};
const itemClick = (item: AutocompleteItem): void => {
  searchTerm.value = props.keepSelection ? item.text : "";
  emit("click", item);
  removeFocus();
};
</script>

<style scoped lang="scss">
.app-autocomplete {
  position: relative;
  width: 100%;

  &__results {
    border: 1px solid var(--color-input-border);
    position: absolute;
    width: 100%;
    top: 100%;
    max-height: 30rem;
    overflow-y: auto;
    z-index: var(--z-index-modal);

    &__item {
      padding: var(--space-xs);
      opacity: 1;
      background-color: var(--color-neutral-100);
      cursor: pointer;

      &--focused,
      &:hover {
        opacity: 1;
        background-color: var(--color-primary-accent1);
        color: var(--color-neutral-100);
      }
    }
  }
}
</style>
