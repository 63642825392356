import type { Hobby } from "@/models/hobby";
import type { Ref } from "vue";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me/hobbies";
const { loading, get, put } = useHttpClient();

interface UseMeHobbiesServiceReturn {
  getEmployeeHobbies: () => Promise<Hobby[]>;
  updateEmployeeHobbies: (hobbies: Partial<Hobby>[]) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeHobbiesServiceReturn => {
  const getEmployeeHobbies = (): Promise<Hobby[]> => get(BASE_URL);
  const updateEmployeeHobbies = (hobbies: Partial<Hobby>[]): Promise<void> => put(BASE_URL, hobbies);

  return { getEmployeeHobbies, updateEmployeeHobbies, loading };
};
