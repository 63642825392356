<template>
  <div data-cy="app-tag" class="app-tag" :class="tagClasses">
    <span class="main-text text" :class="mainTextClasses" :title="title">{{ mainText }}</span>
    <span v-if="secondaryText" class="text--secondary text">{{ secondaryText }}</span>
    <span v-if="icon" class="icon" :class="`icon-${icon}`"></span>
    <span v-if="deletable" class="close-button icon-close margin-l--xxs" data-cy="remove-tag" @click="deleteTag"></span>
  </div>
</template>

<script setup lang="ts">
import type { CourseType } from "@/models/course";
import { computed } from "vue";

type TagTrainingType = CourseType | "access-mode";

interface Props {
  mainText: string;
  secondaryText?: string;
  icon?: string;
  type: "presence" | "skill" | "text" | "value" | "status" | "danger" | "training" | "like" | "employee" | "accordion";
  title?: string;
  limitedSize?: boolean;
  trainingType?: TagTrainingType;
  statusType?: "neutral" | "success";
  deletable?: boolean;
  accessModeInline?: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "delete-tag", tagName: string) => void>();

const tagClasses = computed(() => ({
  [`app-tag--${props.type}`]: true,
  [`app-tag--${props.type}-${props.trainingType}`]: props.type === "training" && props.trainingType,
  [`app-tag--${props.type}-${props.statusType ?? "neutral"}`]: props.type === "status",
  [`app-tag--${props.type}-${props.trainingType}-inline`]: props.trainingType === "access-mode" && props.accessModeInline,
}));
const mainTextClasses = computed(() => ({
  "text--ellipsis": props.limitedSize,
  [`main-text--${props.type}`]: true,
  [`main-text--${props.trainingType}`]: props.type === "training" && props.trainingType,
  [`main-text--${props.type}-${props.statusType ?? "neutral"}`]: props.type === "status",
}));

const deleteTag = (): void => emit("delete-tag", props.mainText);
</script>

<style scoped lang="scss">
.app-tag {
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  border-radius: var(--size-s);

  &--presence {
    min-height: var(--size-m);
    padding: var(--space-xs) var(--space-s);
    background-color: var(--color-tags-presence-background-primary);
  }

  &--like {
    height: var(--size-m);
    padding: var(--space-xxs) var(--space-xs);
    background-color: var(--color-tags-presence-background-primary);
    flex-direction: row-reverse;
  }

  &--value {
    background-color: var(--color-tags-value-background-primary);
  }

  &--skill {
    background-color: var(--color-tags-skills-background-primary);
  }

  &--status-neutral {
    background-color: var(--color-tags-status-background-primary);
  }
  &--status-success {
    background-color: var(--color-system-success);
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  &--danger {
    padding: 3px 12px;
    background-color: var(--color-tags-danger-background-primary);
  }

  &--text {
    padding: 3px 12px;
    background-color: var(--color-tags-text-background-primary);
  }

  &--employee {
    padding: calc(var(--space-xs) / 2) var(--space-xs);
    background-color: var(--color-tags-text-background-primary);
  }

  &--accordion {
    background-color: var(--color-tags-presence-background-primary);
    padding: 5px 8px;
  }

  &--training {
    height: var(--size-m);
    padding: var(--space-xs);

    &-access-mode {
      height: var(--size-l);
      border-radius: 3px;
      background-color: var(--color-neutral-100);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      &-inline {
        height: var(--size-m);
        border: 1px solid var(--color-border);
        border-radius: var(--size-s);
        box-shadow: none;
      }
    }
    &-CourseCoursera {
      background-color: var(--color-tags-training-coursera-background-primary);
    }
    &-CourseEdX,
    &-CourseIntefi {
      background-color: var(--color-tags-training-edx-background-primary);
    }
    &-CourseCompany,
    &-CourseLinkedInLearning {
      background-color: var(--color-tags-training-linkedin-background-primary);
    }
  }
}
.main-text {
  font-weight: var(--font-bold);
  &--presence {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--color-tags-presence-primary);
  }
  &--value {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--color-tags-value-primary);
  }
  &--skill {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--color-tags-skills-primary);
  }
  &--status {
    font-size: 1.4rem;
    line-height: 1.9rem;

    &-neutral {
      color: var(--color-tags-status-primary);
    }
    &-success {
      color: var(--color-neutral-100);
    }
  }
  &--danger {
    font-size: 1.1rem;
    line-height: 1.9rem;
    font-weight: var(--font-normal);
    color: var(--color-tags-danger-primary);
  }
  &--text {
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: var(--font-normal);
    color: var(--color-tags-text-primary);
  }
  &--employee {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: var(--font-bold);
    color: var(--color-tags-text-primary);
  }
  &--like {
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: var(--font-normal);
    color: var(--color-tags-text-primary);
  }
  &--training {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  &--access-mode {
    text-transform: uppercase;
  }
  &--accordion {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--color-tags-skills-primary);
  }
}
.icon {
  font-size: 1rem;
}
.close-button {
  cursor: pointer;
}
</style>
