<template>
  <div>
    <AppTable
      :no-border="true"
      :columns="columns"
      :items="questions"
      :paginated="true"
      :allow-select="true"
      :default-order="defaultOrder"
      :full-width="true"
      @has-any-item-selected="disableActions" />
    <AppAutomaticModalOpener v-if="previewedQuestion" :modal-config="modalConfig">
      <QuestionPreview :item="previewedQuestion" />
    </AppAutomaticModalOpener>
    <QuestionWizard
      v-if="selectedQuestion"
      mode="edit"
      :question="selectedQuestion"
      @close="selectedQuestion = undefined"
      @saved="questionEdited" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import QuestionWizard from "@/views/admin/ai-assistant/components/wizard/QuestionWizard.vue";
import QuestionPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionPreview.vue";
import type { Column } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";
import type { Question } from "@/models/question";
import type { ModalBase } from "@/components/modal/models";
import { QuestionType } from "@/models/questionType";

interface Props {
  questions: Question[];
}
defineProps<Props>();

interface Emits {
  (e: "removeQuestion", id: number): void;
  (e: "updatedQuestion", question: Question): void;
  (e: "showQuestionAnswers", question: Question): void;
  (e: "showQuestionKpi", question: Question): void;
}
const emit = defineEmits<Emits>();

const showActionButtons = ref<boolean>(true);
const selectedQuestion = ref<Question>();
const previewedQuestion = ref<Question>();

const editQuestion = (question: Question) => {
  selectedQuestion.value = { ...question };
};
const previewQuestion = (question: Question) => {
  previewedQuestion.value = question;
};
const questionEdited = (question: Question) => {
  selectedQuestion.value = undefined;
  emit("updatedQuestion", question);
};
const removeQuestion = ({ id }: Question) => emit("removeQuestion", id);
const showQuestionAnswers = (question: Question) => emit("showQuestionAnswers", question);

const disableActions = (anySelected: boolean) => (showActionButtons.value = !anySelected);

const questionTypesForKpi = [QuestionType.Level, QuestionType.MultipleSelection, QuestionType.SingleSelection, QuestionType.Tags];
const isKpiQuestion = (question: Question): boolean => questionTypesForKpi.includes(question.type);
const showQuestionKpi = (question: Question) => emit("showQuestionKpi", question);

const defaultOrder: Order = { direction: "asc", id: 1 };

const modalConfig: ModalBase = {
  title: i18n.global.t("shared.company_groups_questions.grid.actions.preview"),
  closeCallback: () => (previewedQuestion.value = undefined),
};

const columns: Column<Question>[] = [
  {
    id: 1,
    headerText: i18n.global.t("shared.company_groups_questions.grid.field.question"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    fieldName: "title",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("shared.company_groups_questions.grid.field.answeredPercentage"),
    cssWidth: "13rem",
    align: "center",
    sortable: true,
    searchable: false,
    type: "text-with-ellipsis",
    fieldName: "answeredPercentage",
  },
  {
    id: 3,
    headerText: i18n.global.t("shared.company_groups_questions.grid.field.send_date"),
    cssWidth: "8rem",
    align: "left",
    sortable: true,
    searchable: false,
    fieldName: "sendDate",
    type: "text-with-ellipsis",
  },
  {
    id: 4,
    cssWidth: "11rem",
    align: "left",
    sortable: false,
    searchable: false,
    type: "actions",
    actions: [
      {
        callback: editQuestion,
        icon: "pencil",
        title: i18n.global.t("shared.company_groups_questions.grid.actions.edit"),
        name: "edit",
        condition: ({ editable }) => showActionButtons.value && !!editable,
      },
      {
        callback: previewQuestion,
        icon: "eye",
        title: i18n.global.t("shared.company_groups_questions.grid.actions.preview"),
        name: "preview",
        condition: () => showActionButtons.value,
      },
      {
        callback: removeQuestion,
        icon: "close",
        title: i18n.global.t("shared.company_groups_questions.grid.actions.remove"),
        name: "remove",
        condition: () => showActionButtons.value,
      },
      {
        callback: showQuestionKpi,
        icon: "kpi",
        title: i18n.global.t("shared.question_kpi.action.show"),
        name: "question-as-kpi",
        condition: (item: Question) => showActionButtons.value && isKpiQuestion(item),
        customClass: (item: Question) => (item.showInDashboard ? "question-as-kpi--pinned" : "question-as-kpi"),
      },
      {
        callback: showQuestionAnswers,
        icon: "answers",
        title: i18n.global.t("shared.question_answers.action.show"),
        name: "answers",
        condition: (item: Question) => showActionButtons.value && !isKpiQuestion(item),
        disabled: (item: Question) => item.answeredPercentage === 0,
      },
    ],
  },
];
</script>
