<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :disable-key-enter="true">
    <AppForm class="course-editor">
      <CourseEditorMainData :course="course" @mode-changed="modeChanged" />
      <CourseEditorOnSiteData v-if="selectedMode === EducationMode.Presence" :course="course" />
      <AppUploadDocument
        v-model="attachment"
        data-cy="attachment"
        :label="$t('pages.admin.learning.form.fields.attachment.label')"
        :download-url="undefined"
        :name="course?.companyCourseInformation?.imageCoverName"
        :accept="basicAllowedImageExtensions.join()"
        :maximum-size="maxFileSizeInMB"
        :error="attachmentError" />
      <div class="course-url">
        <span class="text--xxl text--bold icon-learning"></span>
        <div class="course-url__content">
          <p class="text--s text--bold">{{ $t("pages.admin.learning.form.fields.courseUrl.title") }}</p>
          <p>{{ $t("pages.admin.learning.form.fields.courseUrl.description") }}</p>
          <AppInputText
            v-model="courseUrl"
            data-cy="courseUrl"
            :label="$t('pages.admin.learning.form.fields.courseUrl.label')"
            :placeholder="$t('pages.admin.learning.form.fields.courseUrl.placeholder')"
            :maxlength="mediumTextLength"
            :error="courseUrlError" />
        </div>
      </div>
    </AppForm>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import i18n from "@/i18n";
import { useField, useForm } from "vee-validate";
import AppForm from "@/components/AppForm.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import CourseEditorOnSiteData, { onSiteCourseDataSchema } from "@/views/admin/learning/components/CourseEditorOnSiteData.vue";
import CourseEditorMainData, { getMainCourseDataSchema } from "@/views/admin/learning/components/CourseEditorMainData.vue";
import { mediumTextLength } from "@/constants/restrictions";
import type { FileAttachment } from "@/models/fileAttachment";
import type { LearningCourse } from "@/models/learningCourse";
import { CourseLevel, type CourseBase } from "@/models/course";
import { EducationMode } from "@/models/educationMode";
import type { ModalBase } from "@/components/modal/models";
import { basicAllowedImageExtensions, maxFileSizeInMB } from "@/utils/fileUtils";
import useToaster from "@/components/toaster/useToaster";
import useCompanyCoursesService from "@/services/useCompanyCoursesService";
import useFormSchema from "@/services/useFormSchema";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import useFormValidator from "@/services/useFormValidator";

const { createLearningCourse, updateLearningCourse } = useCompanyCoursesService();
const { attachmentSchema, inputRequiredText, yupObject, stringUrlSchema } = useFormSchema();
const { openToaster } = useToaster();
const { getFormCallbackHandler } = useFormValidator();

interface Props {
  course?: CourseBase;
  eventEmitter?: UseEventEmitterServiceReturn;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "closed") => void>();

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (!props.course) {
    return;
  }
  courseUrl.value = props.course.url;
};

const resourceSchema = yupObject({
  attachment: attachmentSchema(basicAllowedImageExtensions, maxFileSizeInMB),
  courseUrl: stringUrlSchema({ required: false }).when("mode", {
    is: EducationMode.Online,
    then: (schema) => schema.required(inputRequiredText()),
  }),
});
const fullSchema = resourceSchema.concat(onSiteCourseDataSchema).concat(getMainCourseDataSchema(props.course?.start));
const form = useForm({ validationSchema: fullSchema });
const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");
const { value: courseUrl, errorMessage: courseUrlError } = useField<string | undefined>("courseUrl");

const selectedMode = ref<EducationMode>();

const modeChanged = (newMode: EducationMode) => (selectedMode.value = newMode);

const save = async (): Promise<void> => {
  const courseData = form.values;

  const learningCourseModel: LearningCourse = {
    id: props.course?.id ?? 0,
    title: courseData.title,
    description: courseData.description,
    startDate: courseData.startDate,
    endDate: courseData.endDate,
    durationHours: courseData.durationHours,
    topics: courseData.topics,
    language: courseData.language,
    mode: courseData.mode,
    level: CourseLevel[courseData.level],
    country: courseData.onsiteCountry,
    city: courseData.onsiteCity,
    address: courseData.onsiteAddress,
    floor: courseData.onsiteFloor,
    room: courseData.onsiteRoom,
    schedule: courseData.onsiteSchedule,
    startTime: courseData.onsiteStartTime,
    endTime: courseData.onsiteEndTime,
    attachment: courseData.attachment,
    courseUrl: courseData.courseUrl,
  };

  if (props.course) {
    await updateLearningCourse(learningCourseModel);
  } else {
    learningCourseModel.id = await createLearningCourse(learningCourseModel);
    openToaster("success", i18n.global.t("pages.admin.learning.create_new_learning_form.successfully_created"));
  }
  await props.eventEmitter?.trigger("courseEdited", { course: learningCourseModel, added: !props.course });
};

const modalConfig: ModalBase = {
  title: i18n.global.t(`pages.admin.learning.form.${props.course ? "edit" : "create"}`),
  showCancelToClose: true,
  confirmText: props.course ? i18n.global.t("buttons.save") : i18n.global.t("pages.admin.learning.form.save"),
  variant: "floating",
  confirmCallback: getFormCallbackHandler(form, save),
  onClosed: () => emit("closed"),
};
</script>

<style scoped lang="scss">
.course-editor {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}
.course-url {
  padding: var(--size-s) var(--size-m);
  display: flex;
  gap: var(--size-s);
  background-color: var(--color-company-course-url-background);
  border-radius: var(--default-border-radius);

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--size-s);
  }
}
</style>
