<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :disable-key-enter="true">
    <AppForm>
      <AppPanel v-if="!goal.isPublic" dark :border="false" class="margin-b--m">
        <AppLabel :label="$t('pages.admin.company_goals.edit_goal.fields.is_not_open.label')" />
      </AppPanel>
      <AppPanel v-else-if="isApplicationPhaseOpen(goal)" dark :border="false" class="margin-b--m">
        <AppLabel :label="$t('pages.admin.company_goals.edit_goal.fields.is_open.label')" />
      </AppPanel>
      <AppInputText
        v-model="title"
        :label="$t('pages.admin.company_goals.edit_goal.fields.title.label')"
        :placeholder="$t('pages.admin.company_goals.edit_goal.fields.title.placeholder')"
        :maxlength="mediumTextLength"
        :error="titleError"
        data-cy="title" />
      <AppInputTextArea
        v-model="description"
        :label="$t('pages.admin.company_goals.edit_goal.fields.description.label')"
        :placeholder="$t('pages.admin.company_goals.edit_goal.fields.description.placeholder')"
        :maxlength="largeTextLength"
        :error="descriptionError"
        data-cy="description" />
      <AppInputText
        v-model="link"
        :label="$t('pages.admin.company_goals.edit_goal.fields.link.label')"
        :placeholder="$t('pages.admin.company_goals.edit_goal.fields.link.placeholder')"
        :maxlength="mediumTextLength"
        :error="linkError"
        data-cy="link" />
      <div class="grid grid--two-columns">
        <AppLabel
          class="margin-b--m"
          :label="$t('pages.admin.company_goals.edit_goal.fields.number_of_people_needed.label')"
          :value="goal.numberOfPeopleNeeded"
          compress />
        <AppInputDate
          v-if="isApplicationClosingDateEditable(goal)"
          v-model="applicationClosing"
          :label="$t('pages.admin.company_goals.edit_goal.fields.application_closing.label')"
          :error="applicationClosingError"
          data-cy="applicationClosing" />
        <AppLabel
          v-if="goal.isPublic && !isApplicationClosingDateEditable(goal)"
          class="margin-b--m"
          :label="$t('pages.admin.company_goals.edit_goal.fields.application_closing.label')"
          :value="goal.applicationClosing"
          compress />
      </div>
      <div class="grid grid--two-columns margin-b--m">
        <AppLabel :label="$t('pages.admin.company_goals.edit_goal.fields.country.label')" :value="goal.country" compress />
        <AppLabel :label="$t('pages.admin.company_goals.edit_goal.fields.city.label')" :value="goal.city" compress />
      </div>
      <div class="grid grid--two-columns">
        <AppInputDate
          v-if="isStartDateEditable(goal)"
          v-model="start"
          :label="$t('pages.admin.company_goals.edit_goal.fields.start.label')"
          :error="startError"
          data-cy="start" />
        <AppLabel v-else :label="$t('pages.admin.company_goals.edit_goal.fields.start.label')" :value="goal.start" />
        <AppInputDate v-model="end" :label="$t('pages.admin.company_goals.edit_goal.fields.end.label')" :error="endError" data-cy="end" />
      </div>
    </AppForm>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { onMounted } from "vue";
import { useField, useForm } from "vee-validate";
import { object as yupObject, number as yupNumber } from "yup";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppForm from "@/components/AppForm.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppLabel from "@/components/AppLabel.vue";
import AppPanel from "@/components/AppPanel.vue";
import { convertDateOnlyToLocale } from "@/utils/dateUtils";
import { isStartDateEditable, isApplicationClosingDateEditable, isApplicationPhaseOpen } from "@/services/modelServices/companyGoalService";
import { largeTextLength, mediumTextLength } from "@/constants/restrictions";
import type { CompanyGoal } from "@/models/companyGoal";
import type { ModalBase } from "@/components/modal/models";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";
import useToaster from "@/components/toaster/useToaster";
import useFormValidator from "@/services/useFormValidator";
import useFormSchema from "@/services/useFormSchema";

const { openToaster } = useToaster();
const { updateCompanyGoal } = useCompanyGoalsService();
const { getFormCallbackHandler, getObjectFromForm } = useFormValidator();
const { dateSchema, stringSchema, inputRequiredText, stringUrlSchema, startDateMinTodaySchema, endDateSchema } = useFormSchema();

interface Props {
  goal: CompanyGoal;
}
const props = defineProps<Props>();

interface Emits {
  (e: "close"): void;
  (e: "goal-edited", goal: CompanyGoal): void;
}
const emit = defineEmits<Emits>();
const getApplicationClosingSchema = () => {
  if (!isApplicationClosingDateEditable(props.goal)) {
    return dateSchema(false);
  }
  return startDateMinTodaySchema({ dateSet: props.goal.applicationClosing }).test({
    name: "max",
    exclusive: false,
    params: {},
    message: i18n.global.t("inputs.a_date_has_to_be_after_another_date", {
      first: i18n.global.t("pages.admin.company_goals.add_new_goal.fields.start.label"),
      second: i18n.global.t("pages.admin.company_goals.add_new_goal.fields.application_closing.label"),
    }),
    test: (value, context) => value === undefined || value < context.parent.start,
  });
};

const editionSchema = yupObject({
  id: yupNumber(),
  title: stringSchema(),
  description: stringSchema({ maxLength: largeTextLength }).min(1, inputRequiredText()),
  link: stringUrlSchema({ required: false }),
  applicationClosing: getApplicationClosingSchema(),
  start: isStartDateEditable(props.goal) ? startDateMinTodaySchema({ dateSet: props.goal.start }) : dateSchema(false),
  end: endDateSchema({ startDateFieldName: "start", canBeEqualToStartDate: false }),
});

const form = useForm({ validationSchema: editionSchema });
const { value: title, errorMessage: titleError } = useField<string>("title");
const { value: description, errorMessage: descriptionError } = useField<string>("description");
const { value: link, errorMessage: linkError } = useField<string | undefined>("link");
const { value: applicationClosing, errorMessage: applicationClosingError } = useField<Date | undefined>("applicationClosing");
const { value: start, errorMessage: startError } = useField<Date>("start");
const { value: end, errorMessage: endError } = useField<Date>("end");

onMounted(() => {
  title.value = props.goal.title;
  description.value = props.goal.description;
  link.value = props.goal.link;
  applicationClosing.value = props.goal.applicationClosing ? convertDateOnlyToLocale(props.goal.applicationClosing) : undefined;
  start.value = convertDateOnlyToLocale(props.goal.start);
  end.value = convertDateOnlyToLocale(props.goal.end);
});

const editGoal = async () => {
  const goalFormForm = getObjectFromForm<CompanyGoal>(form);
  const goal: Partial<CompanyGoal> = {
    ...goalFormForm,
    id: props.goal.id,
    applicationClosing: props.goal.isPublic ? applicationClosing.value : undefined,
    isPublic: props.goal.isPublic,
    isPublished: props.goal.isPublished,
    country: props.goal.country,
    city: props.goal.city,
    numberOfPeopleNeeded: props.goal.numberOfPeopleNeeded,
  };
  await updateCompanyGoal(goal);
  form.resetForm();
  openToaster("success", i18n.global.t("pages.admin.company_goals.edit_goal.successfully_edited"));

  const updatedGoal: CompanyGoal = { ...props.goal, ...goal };
  emit("goal-edited", updatedGoal);
};

const modalConfig: ModalBase = {
  title: title,
  confirmText: i18n.global.t("pages.admin.company_goals.edit_goal.save"),
  variant: "floating",
  showCancelToClose: true,
  confirmCallback: getFormCallbackHandler(form, editGoal),
  onClosed: () => emit("close"),
};
</script>
