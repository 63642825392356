import type { FileAttachment } from "@/models/fileAttachment";
import type { Ref } from "vue";

import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me/picture";
const { post, httpDelete, loading } = useHttpClient();

interface UseMePictureServiceReturn {
  savePicture: (picture: FileAttachment) => Promise<void>;
  deletePicture: () => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMePictureServiceReturn => {
  const savePicture = (picture: FileAttachment): Promise<void> => post(BASE_URL, picture);
  const deletePicture = (): Promise<void> => httpDelete(BASE_URL);

  return { savePicture, deletePicture, loading };
};
