<template>
  <div class="admin-talent-detection__chart">
    <NoContentChart v-if="showNoContent" />
    <div v-else id="chart" data-cy="talent-chart-category-by-group">
      <apexchart :options="options" :series="series" @click="getSelection" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { getHobbyCategoryFromIndex, getSeriesLabel, hobbiesColorMap } from "@/views/admin/talent-detection/utils";
import { computed, onMounted, ref } from "vue";
import NoContentChart from "@/views/admin/talent-detection/components/charts/AdminTalentDetectionNoContentChart.vue";
import type { ChartSeriesData } from "@/models/talent-detection/seriesData";
import type { Group } from "@/models/talent-detection/group";
import type { GroupChartSelection } from "@/models/talent-detection/chartSelections";
import useCompanyTalentDetectionService from "@/services/useCompanyTalentDetectionService";

const emit = defineEmits<(e: "clicked", value: GroupChartSelection | undefined) => void>();

const { getSeriesDataByGroup } = useCompanyTalentDetectionService();
const groups = ref<Group[]>([]);
const series = ref<ChartSeriesData[]>([]);
const requested = ref<boolean>(false);
const showNoContent = computed(() => requested.value === true && !series.value.length);
const options = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    },
  },
  colors: Object.values(hobbiesColorMap),
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    categories: [""],
    labels: {
      formatter: (percentage: string) => `${percentage}%`,
    },
  },
  tooltip: {
    y: {
      formatter: (employeesCount: string) => employeesCount,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    offsetY: 30,
    itemMargin: {
      horizontal: 20,
    },
  },
  dataLabels: {
    enabled: false,
  },
};

onMounted(async () => {
  await fetchData();
  requested.value = true;
});

const fetchData = async (): Promise<void> => {
  const response = await getSeriesDataByGroup();
  response.data.forEach((serie) => {
    series.value.push({ name: getSeriesLabel(serie.category), data: [...serie.data] });
  });

  groups.value = [...response.groups];
  options.xaxis.categories.splice(0, 1, ...groups.value.map((g) => g.name));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSelection = (_event: any, _chartContext: any, config: any): void => {
  if (config.seriesIndex < 0 || config.dataPointIndex < 0) {
    return;
  }

  const selectedDataPointsLength = config.globals.selectedDataPoints.length;
  const selectedDataPoint = config.globals.selectedDataPoints[selectedDataPointsLength - 1];

  if (!selectedDataPoint?.length) {
    emit("clicked", undefined);
    return;
  }

  const groupName = options.xaxis.categories[config.dataPointIndex];
  const group = groups.value.find((g) => g.name === groupName);
  if (group === undefined) {
    return;
  }

  const category = getHobbyCategoryFromIndex(config.seriesIndex);
  emit("clicked", { group, hobbiesCategory: category });
};
</script>
