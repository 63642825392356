<template>
  <AppPanel :padding-content="false">
    <template #default>
      <div v-if="questionsInView.length > 0">
        <div v-for="(item, index) in questionsInView" :key="item.order" class="row" data-cy="ai-assistant-row">
          <AIAssistantQuestion :item="item" :order="index + 1 + (currentPage - 1) * 10" :answer-enabled="false" />
        </div>
      </div>
      <div v-else class="no-content">
        <AppNoContentYet :text="$t('pages.ai_assistant.no.questions')" />
      </div>
    </template>
    <template v-if="questionsInView.length > 0 && totalPages > 1" #footer>
      <div class="navigation">
        <AppButton
          :text="$t('buttons.previous')"
          variant="secondary"
          size="m"
          :disabled="currentPage === 1"
          data-cy="ai-assistant-prev"
          @onclick="previousPage()" />
        <AppTag :main-text="`${currentPage} / ${totalPages}`" type="presence" data-cy="ai-assistant-page" :limited-size="true" />
        <AppButton
          :text="$t('buttons.next')"
          variant="secondary"
          size="m"
          :disabled="currentPage * pageSize >= totalItemsCount"
          data-cy="ai-assistant-next"
          @onclick="nextPage()" />
      </div>
    </template>
  </AppPanel>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import AppButton from "@/components/AppButton.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppTag from "@/components/AppTag.vue";
import AIAssistantQuestion from "@/views/app/ai-assistant/components/AIAssistantQuestion.vue";
import AppPanel from "@/components/AppPanel.vue";
import type { Question } from "@/models/question";
import useEmployeeService from "@/services/useEmployeeService";

interface Props {
  employeeId: number;
}
const props = defineProps<Props>();

const { getQuestions } = useEmployeeService();

const pageSize = 10;

const currentPage = ref<number>(0);
const totalPages = ref<number>(0);
const totalItemsCount = ref<number>(0);
const questionsInView = ref<Question[]>([]);
const questions = ref<Question[]>([]);

const previousPage = (): void => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = (): void => {
  if (currentPage.value <= totalPages.value) {
    currentPage.value++;
  }
};

const refreshQuestionsInView = (): void => {
  const initialItem = (currentPage.value - 1) * pageSize;
  const finalItem = initialItem + pageSize;
  questionsInView.value = questions.value.slice(initialItem, finalItem) || [];
};

watch(currentPage, () => refreshQuestionsInView());

onMounted(async () => {
  questions.value = await getQuestions(props.employeeId);
  totalItemsCount.value = questions.value.length || 0;
  currentPage.value = 1;
  totalPages.value = Math.ceil(totalItemsCount.value / pageSize);
});
</script>

<style scoped lang="scss">
.header--questions {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: var(--space-m);
  background-color: var(--color-ai-assistant-header-background);
  border-radius: var(--space-xs);

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.row {
  padding: var(--space-s) 0 var(--space-s) 0;
  border-bottom: 1px solid var(--color-border);

  &:last-child {
    border-bottom: none;
  }
}

.no-content {
  padding: 0 var(--space-m) var(--space-m) var(--space-m);
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
