<template>
  <div v-if="groups.length > 0" class="employee-groups" data-cy="groups-of-employee">
    <div class="employee-groups__title">
      <span class="icon icon-group text--xxl"></span>
      <span class="text text--l">{{ $t("pages.my_profile.employee_groups.title") }}</span>
    </div>
    <AppTable :columns="columns" :items="groups" :hide-header="true" :stretch="true" />
  </div>
</template>
<script setup lang="ts">
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import { EmployeeGroupRole, type EmployeeGroupInEmployee } from "@/models/companyEmployeeGroup";
import useTableHelper from "@/components/table/useTableHelper";

interface Props {
  groups: EmployeeGroupInEmployee[];
}
defineProps<Props>();

const { getSelectOption } = useTableHelper();

const columns: Column<EmployeeGroupInEmployee>[] = [
  {
    id: 1,
    headerText: i18n.global.t("pages.admin.company_settings.employee_groups.fields.name"),
    cssWidth: "minmax(6rem, 1fr)",
    align: "left",
    sortable: false,
    searchable: false,
    fieldName: "groupName",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.role"),
    cssWidth: "0.3fr",
    align: "right",
    sortable: false,
    searchable: false,
    fieldName: "employeeRole",
    type: "long-text",
    formatter: (group: EmployeeGroupInEmployee) =>
      getSelectOption(EmployeeGroupRole, group.employeeRole, "pages.admin.company_settings.employee_groups.role").text,
  },
];
</script>
<style scoped lang="scss">
.employee-groups {
  display: flex;
  gap: var(--space-xs);
  flex-direction: column;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-s);
  }
}
</style>
