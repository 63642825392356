import type { Ref } from "vue";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/me/agreements";
const { put, loading } = useHttpClient();

interface UseMeAgreementsServiceReturn {
  updateAgreeShareExperience: (agree: boolean) => Promise<void>;
  updateAgreeShareIdealJob: (agree: boolean) => Promise<void>;
  updateAgreeShareInterests: (agree: boolean) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeAgreementsServiceReturn => {
  const updateAgreeShareExperience = (agree: boolean): Promise<void> => put(`${BASE_URL}/experience`, { agree });
  const updateAgreeShareIdealJob = (agree: boolean): Promise<void> => put(`${BASE_URL}/ideal-job`, { agree });
  const updateAgreeShareInterests = (agree: boolean): Promise<void> => put(`${BASE_URL}/interests`, { agree });

  return {
    updateAgreeShareExperience,
    updateAgreeShareIdealJob,
    updateAgreeShareInterests,
    loading,
  };
};
