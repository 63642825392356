import { onUnmounted } from "vue";

interface UseDebounceReturn {
  after: (milliseconds: number, action: () => unknown) => void;
}

export const useDebounce = (): UseDebounceReturn => {
  let timeoutId: number | undefined;

  const after = (milliseconds: number, action: () => unknown): void => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(action, milliseconds);
  };

  onUnmounted(() => window.clearTimeout(timeoutId));

  return { after };
};
