<template>
  <div class="question-wizard-configurer" data-cy="question-wizard-configurer">
    <h2 class="margin-t--0 margin-v--s">
      {{ $t(`pages.admin.ai_assistant.create_new_survey_form.question.${modelValueType}.title`) }}
    </h2>
    <p class="question-wizard-configurer__guide">
      {{ $t(`pages.admin.ai_assistant.create_new_survey_form.question.${modelValueType}.guide`) }}
    </p>
    <h3 class="margin-t--l margin-b--m">
      {{ $t(`pages.admin.ai_assistant.create_new_survey_form.question.${modelValueType}.input.title`) }}
    </h3>
    <AppInputText
      v-model="title"
      :autofocus="true"
      :placeholder="$t(`pages.admin.ai_assistant.create_new_survey_form.question.${modelValueType}.input.title`)"
      :maxlength="mediumTextLength"
      :error="titleError"
      @update:model-value="titleUpdate" />
    <QuestionCreationWizardConfigurerOptions
      v-if="hasOptions"
      :options="options ?? []"
      :number-of-options-required="2"
      :show-all-errors="showAllErrors"
      data-cy="question-options"
      @options-updated="optionsUpdated" />
    <QuestionCreationWizardConfigurerLevel
      v-if="question.type === QuestionType.Level"
      :levels="levels"
      data-cy="question-levels"
      @levels-updated="levelsUpdated" />
  </div>
</template>
<script setup lang="ts">
import { useField } from "vee-validate";
import { onMounted, computed, ref } from "vue";
import AppInputText from "@/components/AppInputText.vue";
import { mediumTextLength } from "@/constants/restrictions";
import type { FormMode } from "@/models/formMode";
import type { QuestionLevel } from "@/models/questionLevel";
import type { QuestionOption } from "@/models/questionOption";
import { QuestionType } from "@/models/questionType";
import QuestionCreationWizardConfigurerLevel from "@/views/admin/ai-assistant/components/wizard/configurer/QuestionCreationWizardConfigurerLevel.vue";
import QuestionCreationWizardConfigurerOptions from "@/views/admin/ai-assistant/components/wizard/configurer/QuestionCreationWizardConfigurerOptions.vue";
import useQuestionFormSchema from "@/views/admin/ai-assistant/services/useQuestionFormSchema";
import type { Question } from "@/models/question";

interface Props {
  question: Question;
  mode: FormMode;
  showAllErrors: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "questionUpdated", value: Question, isValid: boolean) => void>();

const modelValueType = computed<string>(() => props.question.type.toLowerCase());

const { getQuestionForm } = useQuestionFormSchema();
const form = getQuestionForm(props.question);

const hasOptions = computed<boolean>(
  () => props.question.type === QuestionType.SingleSelection || props.question.type === QuestionType.MultipleSelection
);

const { value: title, errorMessage: titleError } = useField<string>("title");
const options = ref<QuestionOption[] | undefined>(props.question.options);
const levels = ref<QuestionLevel | undefined>(props.question.levels);

onMounted(async () => {
  title.value = props.question.title;

  const validateWhenMounted: boolean = props.showAllErrors || props.mode === "edit";
  if (!validateWhenMounted) {
    form.resetForm();
    return;
  }
  await emitUpdate(props.question);
});

const titleUpdate = () => refreshState({ title: title.value });
const optionsUpdated = (newOptions: QuestionOption[]) => refreshState({ options: newOptions });
const levelsUpdated = (newLevels: QuestionLevel) => refreshState({ levels: newLevels });
const refreshState = async (questionChanged: Partial<Question>): Promise<void> => {
  const question = {
    ...props.question,
    ...questionChanged,
  } as Question;
  options.value = question.options;
  levels.value = question.levels;
  await emitUpdate(question);
};
const emitUpdate = async (question: Question) => {
  const resultValidation = await form.validate();
  emit("questionUpdated", question, resultValidation.valid);
};
</script>
<style lang="scss" scoped>
.question-wizard-configurer {
  background-color: var(--color-card);
  &__guide {
    background: var(--color-neutral-200);
    padding: var(--space-m);
    border-radius: var(--default-border-radius);
  }
}
</style>
