<template>
  <div :key="language">
    <AppToaster name="default" />
    <AppToaster name="error" type="error" />
    <AppToaster name="success" type="success" />
    <AppModal :name="DefaultModalName" />
    <router-view />
  </div>
</template>

<script setup lang="ts">
import AppToaster from "@/components/toaster/AppToaster.vue";
import AppModal from "@/components/modal/AppModal.vue";
import { DefaultModalName } from "@/components/modal/models";
import i18n from "@/i18n";
import { computed, onMounted } from "vue";
import useSpaConfigurationService from "@/services/useSpaConfigurationService";

const language = computed<string>(() => i18n.global.locale.value);
onMounted(async () => {
  const { getSpaConfiguration } = useSpaConfigurationService();
  await getSpaConfiguration();
});
</script>
