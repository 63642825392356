<template>
  <ul class="wizard-path" data-cy="wizard-path">
    <li
      v-for="(step, index) in steps"
      :key="step.number"
      class="wizard-path__item"
      :class="{ 'wizard-path__item--done': selectedStep >= step.number, 'wizard-path__item-next--done': selectedStep > step.number }"
      :data-cy="`wizard-path-${step.number}`"
      @click="stepClick(step.number)">
      <div class="wizard-path__item-number">{{ index + 1 }}</div>
      <div class="wizard-path__item-text">{{ $t(`${namePrefix}.${step.name}`) }}</div>
    </li>
  </ul>
</template>
<script setup lang="ts">
export interface WizardPathStep {
  number: number;
  name: string;
}

interface Props {
  selectedStep: number;
  steps: WizardPathStep[];
  namePrefix: string;
}
defineProps<Props>();

const emit = defineEmits<(e: "stepClick", step: number) => void>();

const stepClick = (step: number): void => emit("stepClick", step);
</script>
<style lang="scss" scoped>
.wizard-path {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--size-xs);

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    color: var(--color-neutral-700);
    border-color: var(--color-neutral-700);
    cursor: pointer;

    &::after,
    &::before {
      position: absolute;
      top: 16px;
      display: block;
      width: 50%;
      height: 1px;
      content: "";
      background-color: var(--color-neutral-400);
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }

    &:first-child::before,
    &:last-child::after {
      display: none;
    }

    &:not(:first-child, :last-child) {
      flex-grow: 2;
    }

    &--done {
      color: var(--color-system-success);
      border-color: var(--color-system-success);

      &::before {
        background-color: var(--color-system-success);
      }
    }
    &-next--done::after {
      background-color: var(--color-system-success);
    }
  }

  &__item-number {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size-l);
    height: var(--size-l);
    background-color: var(--color-neutral-100);
    border: 1px solid var(--color-neutral-400);
    border-radius: var(--size-l);
  }

  &__item--done &__item-number {
    border-color: var(--color-system-success);
  }

  &__item-text {
    font-size: 1.3rem;
    font-weight: var(--font-bold);
    text-align: center;
    max-width: 20rem;
    padding: 0 0.5rem;
  }
}
</style>
