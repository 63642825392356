<template>
  <hr class="app-separator" />
</template>

<style lang="scss" scoped>
.app-separator {
  border-width: 1px 0 0;
  border-top-color: var(--color-divider);
  width: 100%;
}
</style>
