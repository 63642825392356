<template>
  <div>
    <AIAssistantGenericTextAnswer :text="question.text" />
    <AIAssistantAnswerActions v-if="answerEnabled" :question="question" :editor="AIAssistantAnswerShortTextEditor" @edited="edited" />
  </div>
</template>

<script setup lang="ts">
import type { Question } from "@/models/question";
import AIAssistantAnswerActions from "@/views/app/ai-assistant/components/AIAssistantAnswerActions.vue";
import AIAssistantAnswerShortTextEditor from "@/views/app/ai-assistant/components/AIAssistantAnswerShortTextEditor.vue";
import AIAssistantGenericTextAnswer from "@/views/app/ai-assistant/components/AIAssistantGenericTextAnswer.vue";

interface Props {
  question: Question;
  answerEnabled: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const edited = (newQuestionState: Question): void => emit("edited", newQuestionState);
</script>
