<template>
  <div class="app-input-rating">
    <label v-if="label" class="text text--secondary" :class="{ 'text--bold': bold }">{{ label }}</label>
    <div class="app-input-rating__stars">
      <AppRatingStar
        v-for="rateValue in ratings"
        :key="rateValue"
        :value-required="rateValue"
        :value="hoverValue || modelValue || 0"
        @click="clickHandler"
        @star-hover="starHover"
        @star-leave="starLeave" />
      <AppInputError :error="error" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppRatingStar from "@/components/rateInput/AppRatingStar.vue";
import AppInputError from "@/components/AppInputError.vue";
import { createArray } from "@/utils/numberUtils";

interface Props {
  label?: string;
  modelValue?: number;
  error?: string;
  bold?: boolean;
}
defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", value: number) => void>();

const hoverValue = ref<number>();
const ratings = createArray(5);

const clickHandler = (newValue: number) => emit("update:modelValue", newValue);
const starHover = (newValue: number) => (hoverValue.value = newValue);
const starLeave = () => (hoverValue.value = 0);
</script>

<style scoped lang="scss">
.app-input-rating {
  display: grid;
  grid-auto-flow: row;
  row-gap: 0.3rem;
  justify-items: left;
  align-items: center;

  &__stars {
    display: flex;
    flex-direction: row;
  }
}
</style>
