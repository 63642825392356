<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :overflow="true">
    <div class="question-answers">
      <AppGenericFilter
        class="margin-b--m"
        :placeholder="$t('shared.question_answers.search.placeholder')"
        @filter-changed="filterChanged" />
      <QuestionAnswersStatistics :data="data" class="margin-b--m" />
      <AppPanel :border="false" :padding-content="false" class="question-answers__container">
        <template v-for="item of filteredItems" :key="item.firstName + item.lastName">
          <AppAccordionPanel :title="getFullName(item)" :disabled="!item.text">
            <div>{{ item.text }}</div>
            <template #disabled-info>
              <AppTag :main-text="$t('shared.question_answers.no_answer')" type="accordion" />
            </template>
          </AppAccordionPanel>
        </template>
      </AppPanel>
    </div>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import i18n from "@/i18n";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppAccordionPanel from "@/components/accordion/AppAccordionPanel.vue";
import AppTag from "@/components/AppTag.vue";
import QuestionAnswersStatistics from "@/views/shared/questions/QuestionAnswersStatistics.vue";
import type { ModalBase } from "@/components/modal/models";
import { QuestionEmployeeAnswer, QuestionWithAnswers } from "@/models/questionAnswers";
import { containsInsensitive } from "@/utils/stringUtils";

const emit = defineEmits<(e: "closed") => void>();

interface Props {
  title: string;
  data: QuestionWithAnswers;
}
const props = defineProps<Props>();

const searchText = ref<string>("");
const filteredItems = computed(() =>
  props.data.answers.filter((item) =>
    searchText.value ? containsInsensitive(getFullName(item), searchText.value) || containsInsensitive(item.text, searchText.value) : item
  )
);

const close = () => emit("closed");

const filterChanged = (newSearch: string) => (searchText.value = newSearch);

const getFullName = ({ firstName, lastName }: QuestionEmployeeAnswer) =>
  i18n.global.t("shared.employee.full_name", { firstName, lastName });

const modalConfig: ModalBase = {
  title: i18n.global.t("shared.question_answers.title", { text: props.title }),
  variant: "large",
  showCancelToClose: true,
  cancelText: i18n.global.t("shared.close"),
  onClosed: close,
};
</script>

<style lang="scss" scoped>
.question-answers {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    overflow-y: auto;
  }
}
</style>
