<template>
  <div class="container">
    <div class="logo">
      <router-link :to="rootUrl">
        <img src="@/assets/images/logo.svg" alt="" data-cy="logo-responsive" />
      </router-link>
    </div>
    <div id="header-button-container"></div>
    <AvatarContainer
      v-if="profileInfo"
      v-click-outside="() => (showMenu = false)"
      :show-menu="showMenu"
      :profile-info="profileInfo"
      @updated-birth-date="updatedBirthDate"
      @click="showMenu = true" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import AvatarContainer from "@/layout/shared/AvatarContainer.vue";
import useMeService from "@/services/useMeService";
import type { EmployeeBasicInformation } from "@/models/employeeBasicInformation";

interface Props {
  rootUrl: string;
}
defineProps<Props>();

const { getBasicInformation } = useMeService();

const profileInfo = ref<EmployeeBasicInformation>();
const showMenu = ref<boolean>(false);

const updatedBirthDate = (newDate: Date) => {
  if (profileInfo.value) {
    profileInfo.value.birthDate = newDate;
  }
};

onBeforeMount(async () => (profileInfo.value = await getBasicInformation()));
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--space-m) var(--space-xxl);
  @media only screen and (max-width: $medium-screen-breakpoint) {
    padding: var(--space-s) var(--space-m);
    position: sticky;
    top: 0;
    background: var(--color-background);
    z-index: 1;
    box-shadow: 0 0 6px 2px var(--color-background);
  }
}
.logo {
  display: none;
  width: 200px;
  @media only screen and (max-width: $medium-screen-breakpoint) {
    display: block;
  }
}
</style>
