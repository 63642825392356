<template>
  <AppAutocomplete
    :items="items"
    :placeholder="$t('pages.admin.dashboard.search.placeholder')"
    :maxlength="mediumTextLength"
    @change="searchChanged"
    @click="click" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import type { AutocompleteItem } from "@/models/autocompleteItem";
import useEmployeeService from "@/services/useEmployeeService";
import { useDebounce } from "@/services/useDebounce";
import { MAX_AUTOCOMPLETE_ITEMS_TO_SHOW, mediumTextLength } from "@/constants/restrictions";
import { DEBOUNCE_SEARCH_TIMEOUT } from "@/constants/debounce";

interface Props {
  placeholder: string;
}
defineProps<Props>();

const emit = defineEmits<(e: "select-item", value: number) => void>();

const { search } = useEmployeeService();
const { after } = useDebounce();

const items = ref<AutocompleteItem[]>([]);

const click = (item: AutocompleteItem) => emit("select-item", item.id);
const searchChanged = (searchTerm: string) => after(DEBOUNCE_SEARCH_TIMEOUT, () => updateSearch(searchTerm));

const updateSearch = async (searchTerm: string) => {
  const employees = await search(searchTerm, MAX_AUTOCOMPLETE_ITEMS_TO_SHOW);
  items.value = employees.map((e) => ({
    id: e.id,
    text: `${e.firstName} ${e.lastName}`,
  }));
};
</script>
