<template>
  <AppAutomaticModalOpener :disable-key-enter="disableKeyEnter" :disable-key-scape="disableKeyScape" :modal-config="modalConfig">
    <AppForm :class="{ 'form--shrink': autoHeight }">
      <slot></slot>
    </AppForm>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import type { ModalBase } from "@/components/modal/models";
import type { FormValidator } from "@/models/formValidator";
import i18n from "@/i18n";
import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import useQuestionService from "@/services/useQuestionService";
import AppForm from "@/components/AppForm.vue";
import useFormValidator from "@/services/useFormValidator";

interface Props {
  question: Question;
  form?: FormValidator;
  autoHeight?: boolean;
  disableKeyScape?: boolean;
  disableKeyEnter?: boolean;
  getCurrentAnswer: () => Answer;
}
const props = defineProps<Props>();

interface Emits {
  (e: "edited", newQuestionState: Question): void;
  (e: "closed"): void;
}
const emit = defineEmits<Emits>();

const { saveQuestionAnswer } = useQuestionService();
const { validateFormAndFocusError } = useFormValidator();

const save = async (): Promise<boolean> => {
  const formInvalid = await isFormInvalid();
  if (formInvalid) {
    return false;
  }
  const answer: Answer = props.getCurrentAnswer();
  await saveQuestionAnswer(props.question.id, answer);
  const newQuestion: Question = getNewQuestionState(answer);
  emit("edited", newQuestion);
  return true;
};
const isFormInvalid = async (): Promise<boolean> => {
  if (!props.form) {
    return false;
  }
  const isValid = await validateFormAndFocusError(props.form);
  return !isValid;
};

const modalConfig: ModalBase = {
  title: props.question.title,
  confirmText: i18n.global.t("buttons.save"),
  variant: "floating",
  showCancelToClose: true,
  confirmCallback: save,
  closeCallback: () => emit("closed"),
};
const getNewQuestionState = (answer: Answer): Question => {
  return {
    ...props.question,
    ...{
      text: answer.text,
      value: answer.value,
      selectedOption: props.question.options?.find((o) => answer.selectedOption && answer.selectedOption === o.optionId),
      selectedOptions: props.question.options?.filter((o) => answer.selectedOptions && answer.selectedOptions.indexOf(o.optionId) > -1),
      tags: answer.tags ? [...answer.tags] : undefined,
    },
  };
};
</script>
