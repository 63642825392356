<template>
  <div class="date-company-goal-detail" :class="backgroundModifierClass">
    <h3 class="margin-b--xs" :class="titleColorModifierClass">{{ props.title }}</h3>
    <p :class="dateColorModifierClass">{{ parseDateToMonthLongDate(props.date) }}</p>
  </div>
</template>

<script setup lang="ts">
import { parseDateToMonthLongDate } from "@/utils/dateUtils";

interface Props {
  date: Date;
  title?: string;
  type: "start" | "end" | "disabled";
}

const props = defineProps<Props>();
const backgroundModifierClass = `date-company-goal-detail--${props.type}`;
const titleColorModifierClass = `title--${props.type}`;
const dateColorModifierClass = `date--${props.type}`;
</script>

<style scoped lang="scss">
.date-company-goal-detail {
  border-radius: var(--default-border-radius);
  padding: var(--space-m);
  width: 100%;

  &--start {
    background-color: var(--color-company-goals-start-background);
  }

  &--end {
    background-color: var(--color-company-goals-end-background);
  }

  &--disabled {
    background-color: var(--color-neutral-100);
  }
}

.title {
  &--start,
  &--end {
    color: var(--color-neutral-700);
  }

  &--disabled {
    color: var(--color-neutral-500);
  }
}

.date {
  &--start,
  &--end {
    color: var(--color-neutral-600);
  }

  &--disabled {
    color: var(--color-neutral-500);
  }
}
</style>
