import { EmployeeGroupRole, type EmployeeWithGroups, type EmployeeWithGroupsEmployee } from "@/models/companyEmployeeGroup";
import router from "@/router/router";
import type { AllowedSettingsTabs } from "@/views/admin/company-settings/components/AdminCompanySettingsNavigation.vue";

const settingsOverviewRoute = "company-settings-overview";
const editorRoute = "company-settings-company-employee-groups-editor";
const manageRoute = "company-settings-company-employee-groups-group-manage";

export const createNewGroup = async (tabId: AllowedSettingsTabs): Promise<void> => {
  await router.push({ name: editorRoute, params: { backTabId: tabId } });
};
export const editGroup = async (groupId: number, tabId: AllowedSettingsTabs): Promise<void> => {
  await router.push({ name: editorRoute, params: { backTabId: tabId, groupId } });
};
export const manageGroup = async (groupId: number, tabId: AllowedSettingsTabs): Promise<void> => {
  await router.push({ name: manageRoute, params: { backTabId: tabId, groupId } });
};
export const backToList = async (): Promise<void> => {
  const tabId: AllowedSettingsTabs = (router.currentRoute.value.params.backTabId as AllowedSettingsTabs) || "GeneralInfo";
  await router.push({ name: settingsOverviewRoute, params: { tabId } });
};
export const filterEmployeesByRole = (employees: EmployeeWithGroups[], role?: EmployeeGroupRole): EmployeeWithGroups[] => {
  if (!role || !(role in EmployeeGroupRole)) {
    return employees;
  }
  return employees.filter((e) => e.groups.some((g) => g.employeeRole === role));
};
export const filterEmployeesGroupsByRole = (
  groups: EmployeeWithGroupsEmployee[],
  role?: EmployeeGroupRole
): EmployeeWithGroupsEmployee[] => {
  if (!role || !(role in EmployeeGroupRole)) {
    return groups;
  }
  return groups.filter((g) => g.employeeRole === role);
};
