<template>
  <div
    class="table"
    :class="{ 'table--no-border': noBorder, 'table--full-width': fullWidth, 'table--stretch': stretch }"
    data-cy="app-table">
    <div v-if="internalFilters?.length" class="padding-l--s">
      <AppTableFilters v-model="internalFilters" />
    </div>
    <AppTableHeader
      v-if="!hideHeader"
      :columns="visibleColumns"
      :allow-select="allowSelect"
      :order="order"
      :user-selection-changed="userSelectionChanged"
      :has-detail="!!detailItemComponent"
      @sort="orderBy"
      @select-all-changed="selectedAllChanged" />
    <div
      v-for="item in itemsInView"
      :key="item.id"
      :class="{ 'table__row-container--with-border': !noRowSeparator }"
      data-cy="app-table-row-container">
      <AppTableRow
        :allow-select="allowSelect"
        :allow-expand="!!detailItemComponent"
        :columns="visibleColumns"
        :item="item"
        :expanded="itemIsExpanded(item)"
        @toggle-select-item="toggleSelectItem"
        @toggle-expanded-item="toggleExpandedItem" />
      <Component
        :is="detailItemComponent"
        v-if="detailItemComponent && itemIsExpanded(item)"
        :item="item"
        :custom-props="detailItemComponentProps"
        data-cy="table-row-detail" />
    </div>
    <AppTablePagination v-if="items && props.paginated" v-model="paginationProps" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, type Component } from "vue";
import AppTablePagination from "@/components/table/AppTablePagination.vue";
import AppTableFilters from "@/components/table/AppTableFilters.vue";
import AppTableHeader from "@/components/table/AppTableHeader.vue";
import AppTableRow from "@/components/table/AppTableRow.vue";
import type { Column } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";
import type { TableItem, TableItemSelectable } from "@/components/table/TableItem";
import type { PaginationProperties } from "@/components/table/PaginationProperties";
import type { Filter } from "@/components/table/TableFilter";
import useTableHelper from "@/components/table/useTableHelper";

const { filter, paginate, sort } = useTableHelper();

interface Props {
  columns: Column[];
  items?: TableItem[];
  defaultOrder?: Order;
  paginated?: boolean;
  filters?: Filter[];
  allowSelect?: boolean;
  selectColumnWidth?: string;
  noBorder?: boolean;
  noRowSeparator?: boolean;
  hideHeader?: boolean;
  detailItemComponent?: Component;
  detailItemComponentProps?: unknown;
  fullWidth?: boolean;
  stretch?: boolean;
}
const props = withDefaults(defineProps<Props>(), { selectColumnWidth: "5rem" });

interface Emits {
  (e: "sort", order: Order): void;
  (e: "hasAnyItemSelected", response: boolean): void;
  (e: "selectItem", item: TableItemSelectable): void;
  (e: "selectAll", selected: boolean): void;
}
const emit = defineEmits<Emits>();

const order = ref<Order>(props.defaultOrder ?? { direction: "asc", id: -1 });
const itemsExpanded = ref<Map<number, boolean>>(new Map<number, boolean>());
const itemIsExpanded = (item: TableItem) => !!itemsExpanded.value.get(item.id);
const toggleExpandedItem = (item: TableItem) => itemsExpanded.value.set(item.id, !itemIsExpanded(item));

const paginationProps = ref<PaginationProperties>({
  pageSize: 10,
  currentPage: 1,
  totalElements: props.items?.length ?? 0,
});

const internalFilters = ref<Filter<TableItem>[] | undefined>(props.filters);

const filteredItems = computed<TableItem[]>(() => filter(props.items, internalFilters.value));

const itemsInView = computed<TableItem[]>(() => {
  const sortedItems = sort(order.value, props.columns, filteredItems.value);
  return props.paginated ? paginate(sortedItems, paginationProps.value) : sortedItems;
});

const visibleColumns = computed(() => props.columns.filter((column) => !column.hidden));
const cssGridTemplateColumn = computed<string>(() =>
  [
    props.allowSelect ? props.selectColumnWidth : "",
    props.detailItemComponent ? "2rem" : "",
    ...visibleColumns.value.map((c) => c.cssWidth),
  ].join(" ")
);
const anySelected = computed<boolean>(() => !!props.items && props.items.some((item: TableItem) => (item as TableItemSelectable).selected));
const userSelectionChanged = ref<number>(0);
const toggleSelectItem = (item: TableItemSelectable): void => {
  item.selected = !item.selected;
  emit("selectItem", item);
  userSelectionChanged.value = new Date().getTime();
};
const selectedAllChanged = (selected: boolean): void => {
  if (!props.items) {
    return;
  }
  (props.items as TableItemSelectable[]).forEach((item) => (item.selected = selected));
  emit("selectAll", selected);
};
const orderBy = (column: Column): void => {
  if (!column.sortable) {
    return;
  }
  const direction = getNewOrderDirection(column);
  orderByColumn(column.id, direction);
};
const getNewOrderDirection = (columnClicked: Column): "asc" | "desc" => {
  if (order.value.id !== columnClicked.id) {
    return "asc";
  }
  return order.value.direction === "asc" ? "desc" : "asc";
};
const orderByColumn = (id: number, direction: "asc" | "desc"): void => {
  order.value = {
    direction: direction,
    id: id,
  };
  emit("sort", {
    id: order.value.id,
    direction: order.value.direction,
  });
};

watch(
  () => anySelected.value,
  () => emit("hasAnyItemSelected", anySelected.value)
);
watch(
  () => props.defaultOrder,
  () => {
    if (props.defaultOrder) {
      orderByColumn(props.defaultOrder.id, props.defaultOrder.direction);
    }
  }
);
watch(filteredItems, (newValue: TableItem[]) => {
  userSelectionChanged.value = new Date().getTime();
  if (props.paginated) {
    paginationProps.value.currentPage = 1;
    paginationProps.value.totalElements = newValue ? newValue.length : 0;
  }
});
watch(
  () => props.items,
  () => (userSelectionChanged.value = new Date().getTime())
);
</script>

<style lang="scss" scoped>
.table {
  border-top: 1px solid var(--color-border);
  &__row-container {
    &--with-border {
      border-bottom: 1px solid var(--color-sidebar-menu--pressed);
    }
  }
  &__row,
  &__header {
    display: grid;
    grid-template-columns: v-bind("cssGridTemplateColumn");
    column-gap: 1rem;
    padding: var(--space-xs) var(--space-xs) var(--space-xs) var(--space-s);
    overflow: hidden;
    min-height: var(--size-xxl);
    align-items: center;
  }

  &--no-border {
    border: none;
  }

  &--full-width {
    width: 100%;
  }

  &--stretch &__row,
  &--stretch &__header {
    padding-left: 1px;
    padding-right: 1px;
  }
}
</style>
