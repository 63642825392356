<template>
  <div>
    <AppTable
      :columns="columns"
      :items="questions"
      :allow-select="allowSelect"
      :default-order="order"
      :paginated="paginated"
      :no-border="noBorder"
      @sort="sortHandler"
      @has-any-item-selected="disableActions" />
    <AppAutomaticModalOpener v-if="previewedQuestion" :modal-config="modalConfig">
      <QuestionPreview :item="previewedQuestion" />
    </AppAutomaticModalOpener>
    <QuestionWizard
      v-if="selectedQuestion"
      mode="edit"
      :question="selectedQuestion"
      @close="selectedQuestion = undefined"
      @saved="questionEdited" />
    <QuestionAnswers
      v-if="currentQuestion && currentQuestionAnswers"
      :title="currentQuestion.title"
      :data="currentQuestionAnswers"
      @closed="closeQuestionAnswers" />
    <QuestionKpis
      v-if="currentQuestion && currentQuestionKPI"
      :question="currentQuestion"
      :data="currentQuestionKPI"
      @update-kpi-status="toggleInDashboard"
      @closed="closeQuestionKPI" />
  </div>
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import { ref, computed } from "vue";
import AppTable from "@/components/table/AppTable.vue";
import QuestionPreview from "@/views/admin/ai-assistant/components/wizard/previews/QuestionPreview.vue";
import QuestionOrder from "@/views/admin/ai-assistant/components/grid/QuestionOrder.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import QuestionWizard from "@/views/admin/ai-assistant/components/wizard/QuestionWizard.vue";
import QuestionGroupsColumn from "@/views/admin/ai-assistant/components/grid/QuestionGroupsColumn.vue";
import type { ColumnAction, Column } from "@/components/table/Column";
import useModal from "@/components/modal/useModal";
import type { Question } from "@/models/question";
import { QuestionStatus } from "@/models/questionStatus";
import useQuestionService from "@/services/useQuestionService";
import type { QuestionOrderCustomProps } from "@/views/admin/ai-assistant/components/grid/QuestionOrderCustomProps";
import type { ModalBase } from "@/components/modal/models";
import type { Order } from "@/components/table/Order";
import { QuestionType } from "@/models/questionType";
import { QuestionWithAnswers } from "@/models/questionAnswers";
import QuestionAnswers from "@/views/shared/questions/QuestionAnswers.vue";
import QuestionKpis from "@/views/shared/questions/QuestionKpis.vue";
import { QuestionKpiInfo } from "@/models/questionKpi";

interface Props {
  questions: Question[];
  questionStatus: QuestionStatus;
  allowSort?: boolean;
  allowAddToDashboard?: boolean;
  allowSelect?: boolean;
  minimumOrder?: number;
  maximumOrder?: number;
  order?: Order;
  paginated?: boolean;
  noBorder?: boolean;
  allowedGroupNames?: string[];
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "questionAscended", question: Question): void;
  (e: "questionDescended", question: Question): void;
  (e: "questionDeleted", question: Question): void;
  (e: "questionEdited", question: Question): void;
  (e: "questionDashboardToggle", question: Question): void;
}>();

const { deleteQuestion, descendQuestionOrder, ascendQuestionOrder, toggleQuestionInDashboard, getAnswers, getQuestionKpi } =
  useQuestionService();
const { openTextModal } = useModal();

const selectedQuestion = ref<Question | undefined>();
const previewedQuestion = ref<Question | undefined>();
const currentQuestion = ref<Question | undefined>();
const currentQuestionAnswers = ref<QuestionWithAnswers>();
const currentQuestionKPI = ref<QuestionKpiInfo>();
const canSetOrder = ref<boolean>(true);
const showActionButtons = ref<boolean>(true);

const getDashboardActions = (): ColumnAction<Question>[] =>
  props.allowAddToDashboard && showActionButtons.value
    ? [
        {
          callback: showQuestionKPI,
          condition: (item: Question) => shouldShowKpiAction(item),
          icon: "kpi",
          title: i18n.global.t("shared.question_kpi.action.show"),
          name: "question-as-kpi",
          customClass: (item: Question) => (item.showInDashboard ? "question-as-kpi--pinned" : "question-as-kpi"),
        },
        {
          callback: showQuestionAnswers,
          condition: (item: Question) => !shouldShowKpiAction(item),
          disabled: (item: Question) => item.answeredPercentage === 0,
          icon: "answers",
          title: i18n.global.t("shared.question_answers.action.show"),
          name: "question-answers",
        },
      ]
    : [];

const columns = computed<Column<Question>[]>(() => {
  const dashboardActions = getDashboardActions();
  const columnsQuestions: Column<Question>[] = [
    {
      id: 2,
      headerText: i18n.global.t("pages.admin.ai_assistant.questions.grid.question"),
      cssWidth: "1fr",
      align: "left",
      sortable: !!props.allowSort,
      searchable: false,
      fieldName: "title",
      type: "text-with-ellipsis",
    },
    {
      id: 3,
      headerText: i18n.global.t("pages.admin.ai_assistant.questions.grid.groups"),
      cssWidth: "6rem",
      align: "left",
      sortable: !!props.allowSort,
      searchable: false,
      type: "text-with-ellipsis",
      customRenderer: QuestionGroupsColumn,
      customProperties: {
        allowedGroupNames: props.allowedGroupNames,
      },
    },
    {
      id: 5,
      headerText: i18n.global.t("pages.admin.ai_assistant.questions.grid.date"),
      cssWidth: "8rem",
      align: "left",
      sortable: !!props.allowSort,
      searchable: false,
      fieldName: "edited",
      type: "text-with-ellipsis",
    },
    {
      id: 6,
      headerText: "",
      cssWidth: dashboardActions.length > 0 ? "11rem" : "8rem",
      align: "left",
      sortable: !!props.allowSort,
      searchable: false,
      type: "actions",
      actions: [
        {
          callback: editQuestion,
          condition: (item: Question) => !!item.editable && showActionButtons.value,
          icon: "pencil",
          title: i18n.global.t("buttons.edit"),
          name: "edit",
        },
        {
          callback: previewQuestion,
          condition: () => showActionButtons.value,
          icon: "eye",
          title: i18n.global.t("pages.admin.ai_assistant.create_new_survey_form.preview.title"),
          name: "preview",
        },
        {
          callback: tryDeleteQuestion,
          condition: () => showActionButtons.value,
          icon: "delete",
          title: i18n.global.t("buttons.delete"),
          name: "delete",
        },
        ...dashboardActions,
      ],
    },
  ];
  if (props.questionStatus === QuestionStatus.Published) {
    const customProps: QuestionOrderCustomProps = {
      allowSort: !!props.allowSort,
      ascend: ascendQuestion,
      descend: descendQuestion,
      canAscend: (item: Question): boolean => canSetOrder.value && props.minimumOrder !== undefined && item.order !== props.minimumOrder,
      canDescend: (item: Question): boolean => canSetOrder.value && props.maximumOrder !== undefined && item.order !== props.maximumOrder,
    };
    columnsQuestions.unshift({
      id: 1,
      headerText: i18n.global.t("pages.admin.ai_assistant.questions.grid.number"),
      cssWidth: props.allowSort ? "6rem" : "3rem",
      align: "right",
      sortable: !!props.allowSort,
      searchable: false,
      fieldName: "order",
      customRenderer: QuestionOrder,
      type: "text-with-ellipsis",
      customProperties: customProps,
    });
    columnsQuestions.splice(3, 0, {
      id: 4,
      headerText: i18n.global.t("pages.admin.ai_assistant.questions.grid.answeredPercentage"),
      cssWidth: "12rem",
      align: "left",
      sortable: !!props.allowSort,
      searchable: false,
      fieldName: "answeredPercentage",
      type: "text-with-ellipsis",
    });
  }
  return columnsQuestions;
});

const sortHandler = (order: Order): void => {
  canSetOrder.value = order.id === 1 && order.direction === "asc";
};
const disableActions = (anySelected: boolean): void => {
  showActionButtons.value = !anySelected;
};
const ascendQuestion = async (question: Question): Promise<void> => {
  await ascendQuestionOrder(question);
  emit("questionAscended", question);
};
const descendQuestion = async (question: Question): Promise<void> => {
  await descendQuestionOrder(question);
  emit("questionDescended", question);
};
const tryDeleteQuestion = (question: Question): void => {
  openTextModal({
    title: i18n.global.t("pages.admin.ai_assistant.questions.grid.delete.title"),
    contentText: i18n.global.t("pages.admin.ai_assistant.questions.grid.delete.description", { question: question.title }),
    showCancelToClose: true,
    confirmCallback: async () => {
      await deleteQuestion(question.id);
      emit("questionDeleted", question);
    },
  });
};
const editQuestion = (question: Question): void => {
  selectedQuestion.value = { ...question };
};
const previewQuestion = (question: Question): void => {
  previewedQuestion.value = question;
};
const questionEdited = (question: Question): void => {
  selectedQuestion.value = undefined;
  emit("questionEdited", question);
};
const toggleInDashboard = async (question: Question): Promise<void> => {
  question.showInDashboard = !question.showInDashboard;
  await toggleQuestionInDashboard(question.id, question.showInDashboard);
  emit("questionDashboardToggle", question);
  closeQuestionKPI();
};
const questionTypesForKpi = [QuestionType.Level, QuestionType.MultipleSelection, QuestionType.SingleSelection, QuestionType.Tags];
const isKpiQuestion = (question: Question): boolean => questionTypesForKpi.includes(question.type);
const shouldShowKpiAction = (question: Question): boolean => props.questionStatus === QuestionStatus.Published && isKpiQuestion(question);

const showQuestionAnswers = async (question: Question): Promise<void> => {
  currentQuestion.value = question;
  const response = await getAnswers(question.id);

  if (response) {
    currentQuestionAnswers.value = response;
  }
};
const closeQuestionAnswers = () => {
  currentQuestion.value = undefined;
  currentQuestionAnswers.value = undefined;
};

const showQuestionKPI = async (question: Question): Promise<void> => {
  currentQuestion.value = question;
  const response = await getQuestionKpi(question.id);

  if (response) {
    currentQuestionKPI.value = response;
  }
};
const closeQuestionKPI = () => {
  currentQuestion.value = undefined;
  currentQuestionKPI.value = undefined;
};

const modalConfig: ModalBase = {
  title: i18n.global.t("pages.admin.ai_assistant.questions.preview"),
  closeCallback: () => (previewedQuestion.value = undefined),
};
</script>
<style lang="scss">
.question-as-kpi {
  &--pinned {
    padding: 0 0.5rem;
    background-color: var(--color-button-primary-background);
    &::before {
      color: white;
    }
  }
}
</style>
