<template>
  <div class="onsite" data-cy="course-onsite">
    <p class="text text--bold margin-b--s">{{ $t("pages.admin.learning.form.fields.onsiteInformation.label") }}</p>
    <div class="grid grid--two-columns">
      <AppInputText
        v-model="onsiteCountry"
        :label="$t('pages.admin.learning.form.fields.onsiteCountry.label')"
        :placeholder="$t('pages.admin.learning.form.fields.onsiteCountry.placeholder')"
        :maxlength="mediumTextLength"
        :error="onsiteCountryError"
        data-cy="course-country" />
      <AppInputText
        v-model="onsiteCity"
        :label="$t('pages.admin.learning.form.fields.onsiteCity.label')"
        :placeholder="$t('pages.admin.learning.form.fields.onsiteCity.placeholder')"
        :maxlength="mediumTextLength"
        :error="onsiteCityError"
        data-cy="course-city" />
    </div>
    <AppInputText
      v-model="onsiteAddress"
      :label="$t('pages.admin.learning.form.fields.onsiteAddress.label')"
      :placeholder="$t('pages.admin.learning.form.fields.onsiteAddress.placeholder')"
      :maxlength="mediumTextLength"
      :error="onsiteAddressError"
      data-cy="course-address" />
    <div class="grid grid--three-columns">
      <AppInputText
        v-model="onsiteFloor"
        :label="$t('pages.admin.learning.form.fields.onsiteFloor.label')"
        :placeholder="$t('pages.admin.learning.form.fields.onsiteFloor.placeholder')"
        :maxlength="smallTextLength"
        :error="onsiteFloorError"
        data-cy="course-floor" />
      <AppInputText
        v-model="onsiteRoom"
        :label="$t('pages.admin.learning.form.fields.onsiteRoom.label')"
        :placeholder="$t('pages.admin.learning.form.fields.onsiteRoom.placeholder')"
        :maxlength="smallTextLength"
        :error="onsiteRoomError"
        data-cy="course-room" />
      <AppInputText
        v-model="onsiteSchedule"
        :label="$t('pages.admin.learning.form.fields.onsiteSchedule.label')"
        :placeholder="$t('pages.admin.learning.form.fields.onsiteSchedule.placeholder')"
        :maxlength="mediumTextLength"
        :error="onsiteScheduleError"
        data-cy="course-schedule" />
    </div>
    <div class="grid grid--three-columns">
      <AppInputTime
        v-model="onsiteStartTime"
        :label="$t('pages.admin.learning.form.fields.onsiteStartTime.label')"
        :error="onsiteStartTimeError"
        data-cy="course-startTime" />
      <AppInputTime
        v-model="onsiteEndTime"
        :label="$t('pages.admin.learning.form.fields.onsiteEndTime.label')"
        :error="onsiteEndTimeError"
        data-cy="course-endTime" />
    </div>
  </div>
</template>

<script lang="ts">
import { Schema as yupSchema } from "yup";
import useFormSchema from "@/services/useFormSchema";

const { inputRequiredText, stringSchema, yupObject } = useFormSchema();
const schemaRequired = (schema: yupSchema) => schema.required(inputRequiredText());

//script setup does not allow export const => we need this to use in the main component
export const onSiteCourseDataSchema = yupObject({
  onsiteCountry: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteCity: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteAddress: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteFloor: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteRoom: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteSchedule: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteStartTime: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
  onsiteEndTime: stringSchema({ required: false }).when("mode", {
    is: EducationMode.Presence,
    then: schemaRequired,
  }),
});
</script>

<script setup lang="ts">
import { onMounted } from "vue";
import { useField } from "vee-validate";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTime from "@/components/AppInputTime.vue";
import { smallTextLength, mediumTextLength } from "@/constants/restrictions";
import { type CourseBase } from "@/models/course";
import { EducationMode } from "@/models/educationMode";

interface Props {
  course?: CourseBase;
}
const props = defineProps<Props>();

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (!props.course?.companyCourseInformation) {
    return;
  }
  onsiteCountry.value = props.course.companyCourseInformation.country;
  onsiteCity.value = props.course.companyCourseInformation.city;
  onsiteAddress.value = props.course.companyCourseInformation.address;
  onsiteFloor.value = props.course.companyCourseInformation.floor;
  onsiteRoom.value = props.course.companyCourseInformation.room;
  onsiteSchedule.value = props.course.companyCourseInformation.schedule;
  onsiteStartTime.value = props.course.companyCourseInformation.startTime;
  onsiteEndTime.value = props.course.companyCourseInformation.endTime;
};
const getField = (fieldName: string) =>
  useField<string | undefined>(fieldName, undefined, {
    keepValueOnUnmount: true,
  });

const { value: onsiteCountry, errorMessage: onsiteCountryError } = getField("onsiteCountry");
const { value: onsiteCity, errorMessage: onsiteCityError } = getField("onsiteCity");
const { value: onsiteAddress, errorMessage: onsiteAddressError } = getField("onsiteAddress");
const { value: onsiteFloor, errorMessage: onsiteFloorError } = getField("onsiteFloor");
const { value: onsiteRoom, errorMessage: onsiteRoomError } = getField("onsiteRoom");
const { value: onsiteSchedule, errorMessage: onsiteScheduleError } = getField("onsiteSchedule");
const { value: onsiteStartTime, errorMessage: onsiteStartTimeError } = getField("onsiteStartTime");
const { value: onsiteEndTime, errorMessage: onsiteEndTimeError } = getField("onsiteEndTime");
</script>

<style scoped lang="scss">
.onsite {
  padding: var(--size-m);
  background-color: var(--color-system-info--light);
  border-radius: var(--default-border-radius);
}
</style>
