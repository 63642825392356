<template>
  <div v-if="show" data-cy="toaster" class="toaster padding--m" :class="`toaster--${type}`" @click="closeToaster(name)">
    <div><span :class="`icon icon-${type} text--${type}`"></span></div>
    <div data-cy="toaster-text" class="text" :class="`text--${type}`">
      {{ getContent(name) }}
    </div>
    <div class="margin-l--m">
      <span class="icon icon-close" :class="`text--${type}`"></span>
    </div>
  </div>
</template>
<script setup lang="ts">
import useToaster from "@/components/toaster/useToaster";
import { computed, watch } from "vue";

interface Props {
  name: string;
  type?: "success" | "error" | "warning" | "info";
}

const props = withDefaults(defineProps<Props>(), { type: "info" });

const { closeToaster, isOpen, getContent } = useToaster();

const show = computed<boolean>(() => isOpen(props.name));

watch(show, (value: boolean) => {
  if (value) {
    setTimeout(() => closeToaster(props.name), 5 * 1000);
  }
});
</script>
<style lang="scss" scoped>
.toaster {
  position: fixed;
  top: 1vh;
  right: 1vh;
  z-index: var(--z-index-toaster);
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  max-height: 94vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 8px 8px rgba(26, 26, 26, 0.15);

  &--success {
    background-color: var(--color-system-success);
  }

  &--error {
    background-color: var(--color-system-error);
  }

  &--warning {
    background-color: var(--color-system-warning);
  }

  &--info {
    background-color: var(--color-system-info);
  }
}

.text {
  &--success,
  &--error,
  &--warning,
  &--info {
    color: #ffffff;
  }
}
</style>
