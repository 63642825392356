import type { Hobby } from "@/models/hobby";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/hobbies";
const { loading, get, post } = useHttpClient();

interface UseHobbiesServiceReturn {
  getHobbies: () => Promise<Hobby[]>;
  createHobby: (hobby: Partial<Hobby>) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseHobbiesServiceReturn => {
  const getHobbies = (): Promise<Hobby[]> => get(BASE_URL);
  const createHobby = (hobby: Partial<Hobby>): Promise<void> => post(BASE_URL, hobby);

  return { getHobbies, createHobby, loading };
};
