<template>
  <AIAssistantEditorBase :question="question" :form="form" :get-current-answer="getAnswer" @closed="closed" @edited="edited">
    <AppRadioButtonList v-model="selectedOption" :options="question.options" :error="selectedOptionError" />
  </AIAssistantEditorBase>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useField, useForm } from "vee-validate";
import AppRadioButtonList from "@/components/AppRadioButtonList.vue";
import AIAssistantEditorBase from "@/views/app/ai-assistant/components/AIAssistantEditorBase.vue";
import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import type { QuestionOption } from "@/models/questionOption";
import useFormSchema from "@/services/useFormSchema";

interface Props {
  question: Question;
}
const props = defineProps<Props>();

interface Emits {
  (e: "edited", newQuestionState: Question): void;
  (e: "closed"): void;
}
const emit = defineEmits<Emits>();

const { yupObject, inputRequiredText } = useFormSchema();

const resourceSchema = yupObject({
  selectedOption: yupObject({}).required(inputRequiredText()),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: selectedOption, errorMessage: selectedOptionError } = useField<QuestionOption | undefined>("selectedOption");
onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (props.question.selectedOption) {
    selectedOption.value = props.question.selectedOption;
  } else {
    form.resetForm();
  }
};

const getAnswer = (): Answer => ({ selectedOption: selectedOption.value?.optionId });
const closed = () => emit("closed");
const edited = (newQuestionState: Question) => emit("edited", newQuestionState);
</script>
