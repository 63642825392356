<template>
  <div class="courses-list-searcher">
    <div class="input-search" data-cy="input-search">
      <AppGenericFilter :placeholder="placeholder || $t('pages.learning.search')" @filter-changed="searchTermChanged" />
      <div v-if="!adminMode" class="input-search__private margin-l--s">
        <span class="icon-eye-off text--xl margin-r--xs"></span>
        <span>{{ $t("pages.learning.search.no_copy") }}</span>
      </div>
    </div>
    <CoursesListSearcherResultsIndicator v-if="displaySearchResults" :search-term="searchTermUsed" :result-count="searchedCourses.total" />
    <AppTableFilters v-show="displaySearchResults" v-model="filters" @update:model-value="newSearch" />
    <transition name="fade">
      <template v-if="adminMode">
        <CourseGrid
          v-if="searchTerm && searchedCourses.items.length"
          :courses="searchedCourses"
          :event-emitter="eventEmitter"
          data-cy="searched-grid-courses"
          @course-selected="cardClick"
          @update-list="(pageNumber: number) => search(pageNumber)" />
      </template>
      <template v-else>
        <CourseList
          v-if="searchTerm && searchedCourses.items.length"
          :courses="searchedCourses"
          :event-emitter="eventEmitter"
          data-cy="searched-list-courses"
          @course-selected="cardClick"
          @update-list="(pageNumber: number) => search(pageNumber)" />
      </template>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, type Ref, computed } from "vue";
import i18n from "@/i18n";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppTableFilters from "@/components/table/AppTableFilters.vue";
import CourseList from "@/views/shared/learning/CourseList.vue";
import CourseGrid from "@/views/shared/learning/CourseGrid.vue";
import CoursesListSearcherResultsIndicator from "@/views/app/my-learning/components/CoursesListSearcherResultsIndicator.vue";
import type { Filter } from "@/components/table/TableFilter";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import useCourseRateRefresher from "@/views/shared/learning/useCourseRateRefresher";
import useMeCoursesService from "@/services/useMeCoursesService";
import useTableHelper from "@/components/table/useTableHelper";
import { CourseType, type CourseBase, type EmployeeCourse, type EmployeeCourseList } from "@/models/course";
import { isBlank } from "@/utils/stringUtils";

interface Props {
  eventEmitter: UseEventEmitterServiceReturn;
  adminMode?: boolean;
  placeholder?: string;
}
const props = defineProps<Props>();

interface Emits {
  (e: "courseSelected", course: CourseBase): void;
  (e: "sourceChanged"): void;
}
const emit = defineEmits<Emits>();

const { refreshCourseRateData } = useCourseRateRefresher();
const { searchCourses } = useMeCoursesService();
const { getTypeFilter } = useTableHelper();

const emptyResponse = {
  count: 0,
  total: 0,
  pageIndex: 0,
  items: [],
};
const searchedCourses: Ref<EmployeeCourseList> = ref<EmployeeCourseList>(emptyResponse);

const searchTerm: Ref<string> = ref<string>("");
const searchTermUsed: Ref<string> = ref<string>("");
const searchCompleted: Ref<boolean> = ref<boolean>(false);
const displaySearchResults = computed(() => searchTerm.value && searchCompleted.value);

const filters: Ref<Filter<EmployeeCourse>[]> = ref<Filter<EmployeeCourse>[]>([
  getTypeFilter<EmployeeCourse>(
    "courseType",
    i18n.global.t("pages.learning.filter.type.title"),
    "pages.learning.filter.type",
    Object.keys(CourseType)
  ),
]);

const getCourseTypeSelected = () => {
  const selectedOption = filters.value[0].selectedOption;
  return selectedOption in CourseType ? (selectedOption as CourseType) : undefined;
};

const searchTermChanged = async (newSearchTerm: string) => {
  searchTerm.value = newSearchTerm;
  await newSearch();
  searchTermUsed.value = searchTerm.value;
};
const newSearch = async () => {
  if (isBlank(searchTerm.value)) {
    searchedCourses.value = emptyResponse;
    searchCompleted.value = false;
    return;
  }
  await search();
  await props.eventEmitter.trigger("sourceChanged", {});
  searchCompleted.value = true;
};

const search = async (pageIndex = 0) => {
  if (isBlank(searchTerm.value)) {
    return;
  }
  searchedCourses.value = await searchCourses(searchTerm.value, pageIndex, getCourseTypeSelected());
};

const cardClick = (course: CourseBase) => emit("courseSelected", course);

const refreshCourseData = (courseId: number) => refreshCourseRateData(courseId, searchedCourses.value.items);

onMounted(() => {
  if (props.eventEmitter) {
    props.eventEmitter.on("courseSaved", refreshCourseData);
    props.eventEmitter.on("courseEdited", search);
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
.input-search {
  display: flex;

  > div {
    flex-grow: 1;
  }

  &__private {
    display: flex;
    align-items: center;

    color: var(--color-body-tertiary);
  }

  @media only screen and (max-width: $medium-screen-breakpoint) {
    flex-direction: column;
  }
}
.error {
  color: var(--color-body-error);
}
</style>
