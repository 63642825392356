<template>
  <div class="app-loading">
    <img class="spinner" src="@/assets/images/loading.gif" alt="" />
  </div>
</template>

<style lang="scss" scoped>
.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(var(--z-index-loading) - 1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.85);
}

.spinner {
  z-index: var(--z-index-loading);
  width: 64px;
  height: 64px;
  margin: var(--space-m) 0;
}
</style>
