<template>
  <h3 data-cy="results-indicator" class="margin-t--m" :class="{ error: !resultCount }">{{ resultText }}</h3>
</template>

<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";

interface Props {
  resultCount: number;
  searchTerm: string;
}
const props = defineProps<Props>();

const resultText = computed(() => i18n.global.t("shared.resultsFor", { n: props.resultCount, name: props.searchTerm }));
</script>
