<template>
  <AppAutomaticModalOpener :modal-config="modalConfig">
    <AppForm data-cy="skill-creator">
      <AppSelect
        v-if="!props.skillType"
        v-model="selectedSkillType"
        :items="skillTypes"
        :label="$t('shared.skillCreator.form.skill_type_label')"
        data-cy="skill-creator-skill-type" />
      <AppInformationMessage v-if="props.skillType" :message="$t(calculateHRMessage())" class="margin-b--m" />
      <AppInputText
        v-model="skillName"
        :label="$t('shared.skillCreator.form.name')"
        :error="skillNameError"
        :maxlength="mediumTextLength"
        data-cy="skill-creator-skill-name" />
      <AppInputTextArea
        v-model="skillDescription"
        :label="$t('shared.skillCreator.form.description')"
        :error="skillDescriptionError"
        :maxlength="largeTextLength"
        data-cy="skill-creator-skill-description" />
    </AppForm>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useField, useForm } from "vee-validate";
import i18n from "@/i18n";
import AppForm from "@/components/AppForm.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppInformationMessage from "@/components/AppInformationMessage.vue";
import AppSelect from "@/components/AppSelect.vue";
import { largeTextLength, mediumTextLength } from "@/constants/restrictions";
import { SkillType } from "@/models/skillType";
import type { ModalBase } from "@/components/modal/models";
import useSkillService from "@/services/useSkillService";
import useFormSchema from "@/services/useFormSchema";
import useFormValidator from "@/services/useFormValidator";

const { createSkill } = useSkillService();
const { yupObject, stringSchema } = useFormSchema();
const { getFormCallbackHandler } = useFormValidator();

interface Props {
  skillType?: SkillType;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "skill-created", skillName?: string): void;
  (e: "closed"): void;
}>();

const skillTypes = Object.keys(SkillType).map((skillType) => ({
  value: skillType,
  text: i18n.global.t(`pages.admin.skill_management.filters.type.${skillType}`),
}));

const selectedSkillType = ref<SkillType>(props.skillType ?? SkillType[skillTypes[0].value as keyof typeof SkillType]);

const resourceSchema = yupObject({
  skillName: stringSchema(),
  skillDescription: stringSchema({ maxLength: largeTextLength }),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: skillName, errorMessage: skillNameError } = useField<string | undefined>("skillName");
const { value: skillDescription, errorMessage: skillDescriptionError } = useField<string | undefined>("skillDescription");

const calculateHRMessage = (): string =>
  props.skillType !== SkillType.Language ? "shared.skillCreator.skill.hrMessage" : "shared.skillCreator.language.hrMessage";

const skillTypeText = computed<string>(() => (props.skillType === SkillType.Language ? "language" : "skill"));
const saveSkill = async () => {
  await createSkill({
    name: skillName.value,
    description: skillDescription.value,
    type: selectedSkillType.value,
  });
  emit("skill-created", skillName.value);
};
const modalConfig: ModalBase = {
  title: i18n.global.t(`shared.skillCreator.${skillTypeText.value}.title`),
  variant: "floating",
  confirmText: i18n.global.t(`shared.skillCreator.form.${skillTypeText.value}.createButton`),
  showCancelToClose: true,
  confirmCallback: getFormCallbackHandler(form, saveSkill),
  onClosed: () => emit("closed"),
};
</script>
