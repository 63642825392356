<template>
  <div>
    <h3 data-cy="company-employee-groups-title" class="margin-b--l">{{ $t("pages.admin.company_settings.employee_groups.title") }}</h3>
    <AppGenericFilter
      :add-button-text="$t('pages.admin.company_settings.employee_groups.new_group')"
      :placeholder="$t('pages.admin.company_settings.employee_groups.search_placeholder')"
      class="margin-b--xs"
      @create-new="createNewGroup('CompanyEmployeeGroups')"
      @filter-changed="filterChanged" />
    <CompanyEmployeeGroupsTable
      :search-text="searchTerm"
      :groups="groups"
      @edit="(group: CompanyEmployeeGroupInfo)=> editGroup(group.id, 'CompanyEmployeeGroups')"
      @delete="handleDelete"
      @manage="(group: CompanyEmployeeGroupInfo) => manageGroup(group.id, 'CompanyEmployeeGroups')" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import i18n from "@/i18n";
import CompanyEmployeeGroupsTable from "@/views/admin/company-settings/company-employee-groups/components/CompanyEmployeeGroupsTable.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import type { CompanyEmployeeGroupInfo } from "@/models/companyEmployeeGroup";
import useModal from "@/components/modal/useModal";
import {
  editGroup,
  manageGroup,
  createNewGroup,
} from "@/views/admin/company-settings/company-employee-groups/utils/companyEmployeeGroupsUtils";

const { getCompanyEmployeeGroups, deleteCompanyEmployeeGroup } = useCompanyEmployeeGroupsService();
const { openTextModal } = useModal();

const groups = ref<CompanyEmployeeGroupInfo[]>([]);
const searchTerm = ref<string>();

const deleteGroup = async (group: CompanyEmployeeGroupInfo): Promise<void> => {
  await deleteCompanyEmployeeGroup(group.id);
  const groupIndex = groups.value.findIndex((g) => g.id === group.id);
  groups.value.splice(groupIndex, 1);
};
const handleDelete = (group: CompanyEmployeeGroupInfo): void => {
  openTextModal({
    title: i18n.global.t("pages.admin.company_settings.employee_groups.delete.title"),
    contentText: i18n.global.t("pages.admin.company_settings.employee_groups.delete.description"),
    showCancelToClose: true,
    confirmCallback: () => deleteGroup(group),
  });
};

const filterChanged = (newSearchTerm: string) => (searchTerm.value = newSearchTerm);

onMounted(async () => (groups.value = await getCompanyEmployeeGroups()));
</script>

<style scoped lang="scss">
.nav-container {
  display: flex;
}
</style>
