<template>
  <div>
    <div class="answer__actions">
      <AIAssistantAnswerOpenAction :is-not-answer-yet="isNotAnsweredYet" @click="edit()" />
    </div>
    <Component :is="editor" v-if="editing" :question="question" @edited="edited" @closed="closed" />
  </div>
</template>
-
<script setup lang="ts">
import type { Question } from "@/models/question";
import { isBlank } from "@/utils/stringUtils";
import AIAssistantAnswerOpenAction from "@/views/app/ai-assistant/components/AIAssistantAnswerOpenAction.vue";
import { computed, ref, type Component, type Ref } from "vue";

interface Props {
  question: Question;
  editor: Component;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "edited", newQuestionState: Question) => void>();

const editing: Ref<boolean> = ref<boolean>(false);

const isNotAnsweredYet = computed(
  () =>
    (props.question.value === undefined || props.question.value === 0) &&
    isBlank(props.question.text) &&
    !props.question.selectedOption &&
    (!props.question.selectedOptions || props.question.selectedOptions.length === 0) &&
    (!props.question.tags || props.question.tags.length === 0)
);

const edit = (): void => {
  editing.value = true;
};
const closed = (): void => {
  editing.value = false;
};
const edited = (newQuestionState: Question): void => {
  emit("edited", newQuestionState);
  editing.value = false;
};
</script>

<style lang="scss" scoped>
.answer__actions {
  flex-shrink: 0;
  text-align: right;
}
</style>
