<template>
  <LayoutPage :title="$t('shared.company_groups_questions.title')">
    <Teleport v-if="loaded" to="#header-button-container">
      <AppIconButton :text="$t('shared.back')" size="l" icon="arrow-back" @onclick="back" />
    </Teleport>
    <AppGenericFilter
      class="margin-b--m"
      :placeholder="$t('shared.company_groups_questions.search.placeholder')"
      :add-button-text="$t('shared.company_groups_questions.add_new_question')"
      @filter-changed="(newSearch) => (searchText = newSearch)"
      @create-new="openCreateQuestionDialog" />
    <AppTab :tabs="tabs" :tab-id="`group-${currentGroup?.id || 0}`" data-cy="group-list-nav" />
    <AppTip
      class="margin-t--m"
      :title="$t('shared.company_groups_questions.tip.title')"
      :content="$t('shared.company_groups_questions.tip.content')"
      :no-icon="true"
      unique-name="company_groups_questions" />
    <GroupQuestionsPanel
      v-if="currentGroup"
      :group="currentGroup"
      :filter="searchText"
      @remove-questions="removeQuestionsFromGroup"
      @updated-question="updateQuestionData"
      @toggle-in-group-dashboard="toggleInGroupDashboard" />
    <AvailableQuestionsPanel
      v-else
      :key="allowedGroupNames.length"
      :questions="questions"
      :filter="searchText"
      :allowed-group-names="allowedGroupNames"
      @send-questions="sendQuestions"
      @remove-questions="removeQuestions"
      @updated-question="updateQuestionData" />
    <GroupSelector
      v-if="showSendQuestionsDialog"
      :groups="groups"
      @closed="closeSendQuestionsDialog"
      @send-to-groups="sendQuestionsToGroups" />
    <QuestionWizard v-if="showCreateQuestionDialog" mode="create" @close="closeCreateQuestionDialog" @saved="addNewQuestion" />
  </LayoutPage>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import i18n from "@/i18n";
import router from "@/router/router";
import LayoutPage from "@/layout/shared/LayoutPage.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import AppIconButton from "@/components/AppIconButton.vue";
import AppTip from "@/components/AppTip.vue";
import QuestionWizard from "@/views/admin/ai-assistant/components/wizard/QuestionWizard.vue";
import AvailableQuestionsPanel from "@/views/shared/company-groups-questions/components/AvailableQuestionsPanel.vue";
import GroupQuestionsPanel from "@/views/shared/company-groups-questions/components/GroupQuestionsPanel.vue";
import GroupSelector from "@/views/shared/company-groups-questions/components/GroupSelector.vue";
import { tryUpdateQuestionData } from "@/views/shared/company-groups-questions/utils/questionsHelper";
import type { CompanyEmployeeGroupWithQuestions } from "@/models/companyEmployeeGroup";
import type { Question, QuestionSelectable } from "@/models/question";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import useQuestionService from "@/services/useQuestionService";

const {
  getCompanyGroupsQuestions,
  deleteCompanyGroupQuestions,
  toggleQuestionInGroupDashboard,
  updateCompanyGroupsQuestions,
  updateCompanyGroupQuestions,
} = useCompanyEmployeeGroupsService();
const { getUnpublishedQuestions, deleteQuestions } = useQuestionService();

const loaded = ref<boolean>(false);
const tabs = ref<Tab[]>([]);
const groups = ref<CompanyEmployeeGroupWithQuestions[]>([]);
const currentGroup = ref<CompanyEmployeeGroupWithQuestions>();
const questions = ref<QuestionSelectable[]>([]);
const selectedQuestionsIds = ref<number[]>([]);
const showSendQuestionsDialog = ref<boolean>(false);
const showCreateQuestionDialog = ref<boolean>(false);
const searchText = ref<string>("");
const allowedGroupNames = ref<string[]>([]);

const back = () => router.push({ name: "my-groups-overview" });

const updateQuestionData = (question: QuestionSelectable) => {
  const [updatedQuestions, updatedSource] = tryUpdateQuestionData(question, questions.value, groups.value);

  questions.value = updatedQuestions;
  groups.value = updatedSource as CompanyEmployeeGroupWithQuestions[];
};

const removeQuestionsFromGroup = async (groupId: number, questionIds: number[]) => {
  if (currentGroup.value) {
    currentGroup.value.questions = currentGroup.value.questions.filter((q) => !questionIds.includes(q.id));
  }
  await deleteCompanyGroupQuestions(groupId, questionIds);
  await fetchQuestionsData();
};

const removeQuestions = async (questionIds: number[]) => {
  if (questions.value) {
    questions.value = questions.value.filter((q) => !questionIds.includes(q.id));
  }

  if (groups.value) {
    groups.value.forEach((g) => {
      g.questions = g.questions.filter((q) => !questionIds.includes(q.id));
    });
  }

  await deleteQuestions(questionIds);
  await fetchQuestionsData();
};

const openCreateQuestionDialog = () => (showCreateQuestionDialog.value = true);
const closeCreateQuestionDialog = () => (showCreateQuestionDialog.value = false);

const openSendQuestionsDialog = () => (showSendQuestionsDialog.value = true);
const closeSendQuestionsDialog = () => (showSendQuestionsDialog.value = false);

const sendQuestions = (questionIds: number[]): void => {
  selectedQuestionsIds.value = questionIds;
  openSendQuestionsDialog();
};

const sendQuestionsToGroups = async (groupIds: number[]): Promise<void> => {
  await updateCompanyGroupsQuestions(groupIds, selectedQuestionsIds.value);
  selectedQuestionsIds.value = [];
  closeSendQuestionsDialog();
  await fetchData();
};

const selectGroup = (group: CompanyEmployeeGroupWithQuestions) => (currentGroup.value = group);

const createTabs = (source: CompanyEmployeeGroupWithQuestions[]): void => {
  allowedGroupNames.value = groups.value.map(({ name }) => name);
  tabs.value = source.map((group) => ({
    text: group.name,
    action: () => selectGroup(group),
    isChecked: currentGroup.value?.id === group.id,
    dataCy: `group-${group.id}`,
  }));

  tabs.value.unshift({
    text: i18n.global.t("shared.company_groups_questions.created_questions"),
    action: () => (currentGroup.value = undefined),
    isChecked: true,
    dataCy: "created-questions",
  });
};

const updateCurrentGroup = () => {
  if (currentGroup.value) {
    const updatedGroup = groups.value.find((group) => group.id === currentGroup.value?.id);
    if (updatedGroup) {
      selectGroup(updatedGroup);
    }
  }
};

const fetchGroupsData = async () => {
  groups.value = await getCompanyGroupsQuestions();

  if (groups.value.length) {
    createTabs(groups.value);
    updateCurrentGroup();
  }
};

const addNewQuestion = async (question: Question) => {
  if (currentGroup?.value) {
    await updateCompanyGroupQuestions(currentGroup.value.id, [question.id]);
  }

  await fetchData();
};

const fetchQuestionsData = async () => (questions.value = (await getUnpublishedQuestions()) as QuestionSelectable[]);

const fetchData = async () => {
  const tasks = [fetchGroupsData(), fetchQuestionsData()];
  await Promise.all(tasks);
  loaded.value = true;
};

onMounted(fetchData);

const changeShowInDashboardInMemory = (groupId: number, questionId: number, showInDashboard: boolean) => {
  const group = groups.value.find((g) => g.id === groupId);
  const question = group?.questions.find((q) => q.id === questionId);
  if (question) {
    question.showInDashboard = showInDashboard;
  }
};

const toggleInGroupDashboard = async (groupId: number, questionId: number, showInDashboard: boolean) => {
  await toggleQuestionInGroupDashboard(groupId, questionId, showInDashboard);
  changeShowInDashboardInMemory(groupId, questionId, showInDashboard);
};
</script>
