import type { Ref } from "vue";
import type { AzureAd, AzureAdServicePrincipal } from "@/models/azureAd";
import type { Company } from "@/models/company";
import { AddToSlackInfo } from "@/models/slackInformation";
import useHttpClient from "@/services/shared/useHttpClient";

const BASE_URL = "/api/company/settings";
const { put, get, appBaseUrl, loading } = useHttpClient();

interface UseCompanySettingsReturn {
  getCompany: () => Promise<Company>;
  downloadChatBotUrl: () => string;
  updateCompany: (name: string, language: string, description: string | undefined, feedbackEmail: string | undefined) => Promise<void>;
  getAzureInformation: () => Promise<AzureAd>;
  getSlackInformation: () => Promise<AddToSlackInfo>;
  updateAzureInformation: (azureServicePrincipal: AzureAdServicePrincipal) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseCompanySettingsReturn => {
  const getCompany = (): Promise<Company> => get(BASE_URL);
  const updateCompany = (name: string, language: string, description: string | undefined, feedbackEmail: string | undefined) =>
    put<void>(BASE_URL, { name, language, description, feedbackEmail });
  const getAzureInformation = (): Promise<AzureAd> => get(`${BASE_URL}/azure`);
  const getSlackInformation = (): Promise<AddToSlackInfo> => get(`${BASE_URL}/slack`);
  const updateAzureInformation = (azureServicePrincipal: AzureAdServicePrincipal) => put<void>(`${BASE_URL}/azure`, azureServicePrincipal);
  const downloadChatBotUrl = (): string => `${appBaseUrl}${BASE_URL}/chatbot`;

  return { getCompany, updateCompany, getAzureInformation, updateAzureInformation, downloadChatBotUrl, getSlackInformation, loading };
};
