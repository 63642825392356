<template>
  <div class="learning-path__content" :class="contentClass" :data-cy="`level-content-${area}-${level}`">
    <MyLearningPathCourseList
      v-if="level !== 'additional'"
      :courses="courses"
      :course-information-direction="coursesOrder"
      @course-selected="selectCourse" />
    <MyLearningPathCourseCarouselList
      v-else
      :courses="courses"
      :course-information-direction="coursesOrder"
      @course-selected="selectCourse" />
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import MyLearningPathCourseList from "@/views/app/my-learning/components/MyLearningPathCourseList.vue";
import MyLearningPathCourseCarouselList from "@/views/app/my-learning/components/MyLearningPathCourseCarouselList.vue";
import { EmployeeCourse } from "@/models/course";
import { LearningPathCourseArea, LearningPathCourseLevel } from "@/views/app/my-learning/components/learning-path/learningPathModels";

interface Props {
  level: LearningPathCourseLevel;
  area: LearningPathCourseArea;
  courses: EmployeeCourse[];
  striped?: boolean;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "course-selected", course: EmployeeCourse) => void>();

const coursesOrder = computed<"asc" | "desc">(() => (props.level === "beginner" ? "desc" : "asc"));
const gridArea = computed(() => `content-${props.area}-${props.level}`);
const contentClass = computed(() => ({
  [`learning-path__content--striped`]: props.striped,
}));
const selectCourse = (course: EmployeeCourse) => emit("course-selected", course);
</script>

<style lang="scss" scoped>
@import "@/styles/utils/responsive";
@import "@/styles/theme";

$stripeColor: $color-system-info-4;
$borderColor: $color-border;

.learning-path {
  &__content {
    grid-area: v-bind(gridArea);
    padding: var(--space-m);
    &--striped {
      background-color: $stripeColor;
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }
  }
}

@media only screen and (max-width: $medium-screen-breakpoint) {
  .learning-path {
    &__content {
      border-top: none;
    }
  }
}
</style>
