import GuestApp from "@/GuestApp.vue";
import GuestCreateCompanyFromAzureAdView from "@/views/guest/create-company-from-azuread/GuestCreateCompanyFromAzureAdView.vue";

export default [
  {
    path: "/guest",
    component: GuestApp,
    children: [
      {
        path: "create-company-from-azure-ad",
        name: "guest-create-company-from-azure-ad",
        component: GuestCreateCompanyFromAzureAdView,
      },
    ],
    meta: {
      requiresAuth: false,
    },
  },
];
