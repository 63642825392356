<template>
  <div v-if="courses.length > 0" class="company-courses" data-cy="company-courses">
    <AppGenericFilter
      data-cy="input-search"
      :add-button-text="$t('pages.admin.learning.create_new_learning_form.new_learning')"
      :placeholder="$t('pages.admin.learning.search_placeholder')"
      class="margin-b--xs"
      @create-new="emit('creating')"
      @filter-changed="(newSearch) => (searchTerm = newSearch)" />
    <transition name="fade">
      <div
        v-if="filteredCourses.length"
        class="company-courses__container grid--four-columns@large grid--two-columns@medium grid--one-column">
        <CourseCard
          v-for="course in filteredCourses"
          :key="course.id"
          :course="course"
          :show-admin-options="true"
          @click="cardClick"
          @edit="editClick"
          @delete="deleteCourseHandle" />
      </div>
    </transition>
    <AppPanel v-if="searchTerm && !filteredCourses.length" :padding-content="false" class="margin-t--xs">
      <p class="padding--m">{{ $t("pages.learning.search.no_results") }}</p>
    </AppPanel>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import i18n from "@/i18n";
import AppPanel from "@/components/AppPanel.vue";
import CourseCard from "@/views/shared/learning/CourseCard.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import { containsInsensitive, isBlank } from "@/utils/stringUtils";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import useCompanyCoursesService from "@/services/useCompanyCoursesService";
import useModal from "@/components/modal/useModal";
import type { CourseBase } from "@/models/course";

interface Props {
  eventEmitter?: UseEventEmitterServiceReturn;
}
const props = defineProps<Props>();

interface Emits {
  (e: "courseSelected", course: CourseBase): void;
  (e: "editCourse", course: CourseBase): void;
  (e: "coursesLoaded", hasCoursesDefined: boolean): void;
  (e: "creating"): void;
}
const emit = defineEmits<Emits>();

const { deleteLearningCourse, getCourses } = useCompanyCoursesService();
const { openTextModal } = useModal();

const courses = ref<CourseBase[]>([]);
const searchTerm = ref<string>("");

const filteredCourses = computed<CourseBase[]>(() => {
  if (isBlank(searchTerm.value)) {
    return courses.value;
  }
  return courses.value.filter(
    (course) => containsInsensitive(course.title, searchTerm.value) || containsInsensitive(course.description, searchTerm.value)
  );
});

onMounted(async () => {
  if (props.eventEmitter) {
    props.eventEmitter.on("courseEdited", search);
  }
  await search();
});

const search = async (): Promise<void> => {
  courses.value = await getCourses(searchTerm.value);
  emit("coursesLoaded", courses.value.length > 0);
};

const deleteCourseHandle = (course: CourseBase): void => {
  openTextModal({
    title: i18n.global.t("pages.admin.learning.prompt.delete.title"),
    contentText: i18n.global.t("pages.admin.learning.prompt.delete.description"),
    showCancelToClose: true,
    confirmCallback: async () => {
      await deleteLearningCourse(course.id);
      await search();
    },
  });
};

const cardClick = (course: CourseBase): void => emit("courseSelected", course);
const editClick = (course: CourseBase): void => emit("editCourse", course);
</script>

<style lang="scss" scoped>
.company-courses {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
