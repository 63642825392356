<template>
  <div class="company-employee-groups-detail">
    <p>{{ $t("pages.admin.company_settings.employees-with-groups.group-count", groups.length) }}</p>
    <AppTable v-if="groups.length > 0" :columns="columns" :items="groups" :hide-header="true" :no-border="true" :no-row-separator="true" />
  </div>
</template>
<script setup lang="ts">
import i18n from "@/i18n";
import { computed } from "vue";
import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import useTableHelper from "@/components/table/useTableHelper";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import { EmployeeGroupRole, type EmployeeWithGroupsEmployee, type EmployeeWithGroups } from "@/models/companyEmployeeGroup";
import {
  editGroup,
  manageGroup,
  filterEmployeesGroupsByRole,
} from "@/views/admin/company-settings/company-employee-groups/utils/companyEmployeeGroupsUtils";

export interface EmployeeGroupDetailCustomProps {
  selectedRole: EmployeeGroupRole;
}

interface Props {
  item: EmployeeWithGroups;
  customProps: EmployeeGroupDetailCustomProps;
}
const props = defineProps<Props>();

const { getSelectOptions } = useTableHelper();
const { setEmployeeRole } = useCompanyEmployeeGroupsService();
const groups = computed<EmployeeWithGroupsEmployee[]>(() => filterEmployeesGroupsByRole(props.item.groups, props.customProps.selectedRole));

const columns: Column<EmployeeWithGroupsEmployee>[] = [
  {
    id: 1,
    cssWidth: "1fr",
    align: "left",
    sortable: false,
    searchable: true,
    fieldName: "groupName",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    cssWidth: "20rem",
    align: "left",
    sortable: false,
    searchable: false,
    fieldName: "employeeRole",
    type: "select",
    select: {
      options: getSelectOptions(EmployeeGroupRole, "pages.admin.company_settings.employee_groups.role"),
      onUpdate: (group: EmployeeWithGroupsEmployee) => setEmployeeRole(group.companyEmployeeGroupId, group.employeeId, group.employeeRole),
    },
  },
  {
    id: 3,
    cssWidth: "6rem",
    align: "left",
    sortable: false,
    searchable: false,
    type: "actions",
    actions: [
      {
        name: "Edit",
        icon: "pencil",
        title: i18n.global.t("pages.admin.company_settings.employees-with-groups.actions.edit"),
        callback: (group: EmployeeWithGroupsEmployee) => editGroup(group.id, "CompanyEmployeesWithGroups"),
      },
      {
        name: "Manage",
        icon: "eye",
        title: i18n.global.t("pages.admin.company_settings.employees-with-groups.actions.manage"),
        callback: (group: EmployeeWithGroupsEmployee) => manageGroup(group.id, "CompanyEmployeesWithGroups"),
      },
    ],
  },
];
</script>
<style scoped lang="scss">
.company-employee-groups-detail {
  padding: var(--space-s) var(--space-s) var(--space-s) calc(var(--space-m) + 3rem);
  display: grid;
  column-gap: 2rem;
  grid-template-columns: minmax(auto, 1.5fr) minmax(40rem, 3fr);
  background-color: var(--color-sidebar-menu--hover);
}
</style>
