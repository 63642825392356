<template>
  <AIAssistantEditorBase
    :disable-key-enter="true"
    :question="question"
    :get-current-answer="getAnswer"
    :auto-height="true"
    @closed="closed"
    @edited="edited">
    <AppCheckbox
      v-for="option in availableOptions"
      :key="option.optionId"
      v-model="option.checked"
      :text="option.title"
      class="margin-b--xs" />
  </AIAssistantEditorBase>
</template>

<script setup lang="ts">
import AppCheckbox from "@/components/AppCheckbox.vue";
import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import AIAssistantEditorBase from "@/views/app/ai-assistant/components/AIAssistantEditorBase.vue";
import { onMounted, ref, type Ref } from "vue";

interface Props {
  question: Question;
}
const props = defineProps<Props>();

interface Emits {
  (e: "edited", newQuestionState: Question): void;
  (e: "closed"): void;
}
const emit = defineEmits<Emits>();

type OptionStatus = {
  checked: boolean;
  optionId: number;
  title: string;
};

const availableOptions: Ref<OptionStatus[]> = ref<OptionStatus[]>([]);

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (props.question.options) {
    availableOptions.value = props.question.options.map((option) => ({
      optionId: option.optionId,
      title: option.title,
      checked: isOptionSelected(option.optionId),
    }));
  }
};

const isOptionSelected = (optionId: number): boolean =>
  !!props.question.selectedOptions && props.question.selectedOptions.some((o) => o.optionId === optionId);

const getAnswer = (): Answer => {
  const selectedOptionIds: number[] = availableOptions.value.filter((o) => o.checked).map((o) => o.optionId);
  const questionAnswer: Answer = { selectedOptions: selectedOptionIds };
  return questionAnswer;
};
const closed = () => emit("closed");
const edited = (newQuestionState: Question) => emit("edited", newQuestionState);
</script>
