<template>
  <div>
    <AppGenericFilter
      :placeholder="$t('pages.admin.company_settings.employee_groups.editor.search_placeholder')"
      class="margin-b--xs"
      @filter-changed="(newSearch) => (searchText = newSearch)" />
    <AppPanel :border="true">
      <template #default>
        <AppTable :columns="columns" :items="employeesInView" :paginated="true" :allow-select="false" :default-order="defaultOrder" />
      </template>
    </AppPanel>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppPanel from "@/components/AppPanel.vue";
import type { Column } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";
import { type EmployeeGroupEmployee, EmployeeGroupRole } from "@/models/companyEmployeeGroup";
import useTableHelper from "@/components/table/useTableHelper";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";

interface Props {
  groupId: number;
}
const props: Props = defineProps<Props>();

const employees = ref<EmployeeGroupEmployee[]>([]);
const { getCompanyGroupEmployees, setEmployeeRole } = useCompanyEmployeeGroupsService();
const { filterByText, getSelectOptions } = useTableHelper();
const searchText = ref<string>("");
const employeesInView = computed<EmployeeGroupEmployee[]>(() => filterByText(employees.value, searchText.value, columns));

const defaultOrder: Order = { direction: "asc", id: 1 };
const columns: Column<EmployeeGroupEmployee>[] = [
  {
    id: 1,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.name"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "firstName",
    type: "text-with-ellipsis",
  },
  {
    id: 2,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.surname"),
    cssWidth: "0.5fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "lastName",
    type: "text-with-ellipsis",
  },
  {
    id: 3,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.email"),
    cssWidth: "2fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "email",
    type: "text-with-ellipsis",
  },
  {
    id: 4,
    headerText: i18n.global.t("pages.admin.admin_roles.roles.grid.role"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    type: "select",
    fieldName: "employeeRole",
    select: {
      options: getSelectOptions(EmployeeGroupRole, "pages.admin.company_settings.employee_groups.role"),
      onUpdate: (employee: EmployeeGroupEmployee) => setEmployeeRole(props.groupId, employee.id, employee.employeeRole),
    },
  },
];

onMounted(async () => (employees.value = await getCompanyGroupEmployees(props.groupId)));
</script>
