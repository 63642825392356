<template>
  <div class="date-items">
    <AdminCompanyGoalsDatesDate
      v-if="start"
      :title="$t('pages.admin.company_goals.goal_detail.dates.start', { title })"
      :date="start"
      :type="applyColors ? 'start' : 'disabled'" />
    <AdminCompanyGoalsDatesDate
      v-if="end"
      :title="$t('pages.admin.company_goals.goal_detail.dates.end', { title })"
      :date="end"
      :type="applyColors ? 'end' : 'disabled'" />
  </div>
</template>
<script setup lang="ts">
import { isDraft, isInProgress, isApplicationPhaseOpen } from "@/services/modelServices/companyGoalService";
import { computed } from "vue";
import AdminCompanyGoalsDatesDate from "@/views/admin/company-goals/components/AdminCompanyGoalsDatesDate.vue";
import type { CompanyGoal } from "@/models/companyGoal";
import i18n from "@/i18n";

interface Props {
  goal: CompanyGoal;
  type: "application" | "goal";
}

const props = defineProps<Props>();
const isApplicationDate: boolean = props.type === "application";
const title: string | undefined = isApplicationDate ? i18n.global.t("pages.admin.company_goals.goal_detail.dates.application") : undefined;
const start = computed(() => (isApplicationDate ? props.goal.created : props.goal.start));
const end = computed(() => (isApplicationDate ? props.goal.applicationClosing : props.goal.end));
const applyColors = computed(
  () =>
    !isDraft(props.goal) && ((isApplicationDate && isApplicationPhaseOpen(props.goal)) || (!isApplicationDate && isInProgress(props.goal)))
);
</script>
<style scoped lang="scss">
.date-items {
  display: flex;
  gap: var(--space-m);
}
</style>
