<template>
  <AppTab :tabs="tabs" :tab-id="currentTab" />
</template>

<script setup lang="ts">
import AppTab, { type Tab } from "@/components/AppTab.vue";
import i18n from "@/i18n";
import { ref } from "vue";

export type EmployeeProfileTabIds = "CurrentExperience" | "MyIdealJob" | "Interests" | "AIAssistant";

interface Props {
  current: EmployeeProfileTabIds;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "tab-click", tabId: EmployeeProfileTabIds) => void>();

const currentTab = ref<EmployeeProfileTabIds>(props.current);

const setCurrentTab = (tabId: EmployeeProfileTabIds) => {
  if (currentTab.value !== tabId) {
    currentTab.value = tabId;
    emit("tab-click", tabId);
  }
};

const tabs: Tab[] = [
  {
    text: i18n.global.t("pages.admin.dashboard.current_job.title"),
    action: () => setCurrentTab("CurrentExperience"),
    isChecked: props.current === "CurrentExperience",
    dataCy: "ex-current-experience",
  },
  {
    text: i18n.global.t("pages.admin.dashboard.ideal_job.title"),
    action: () => setCurrentTab("MyIdealJob"),
    isChecked: props.current === "MyIdealJob",
    dataCy: "ex-my-ideal-job",
  },
  {
    text: i18n.global.t("pages.admin.dashboard.interests.title"),
    action: () => setCurrentTab("Interests"),
    isChecked: props.current === "Interests",
    dataCy: "ex-interests",
  },
  {
    text: i18n.global.t("pages.admin.dashboard.ai_assistant.title"),
    action: () => setCurrentTab("AIAssistant"),
    isChecked: props.current === "AIAssistant",
    dataCy: "ex-ai-assistant",
  },
];
</script>
