import type { Education } from "@/models/education";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/me/education";
const { get, put, post, httpDelete, loading } = useHttpClient();

interface UseMeEducationServiceReturn {
  getEducations: (offset: number) => Promise<Education[]>;
  createEducation: (education: Education) => Promise<Education>;
  updateEducation: (education: Education) => Promise<Education>;
  deleteEducation: (educationId: number) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeEducationServiceReturn => {
  const getEducations = (offset: number): Promise<Education[]> => get(`${BASE_URL}?offset=${offset}`);
  const createEducation = (education: Education): Promise<Education> => post(BASE_URL, education);
  const updateEducation = (education: Education): Promise<Education> => put(`${BASE_URL}/${education.id}`, education);
  const deleteEducation = (educationId: number): Promise<void> => httpDelete(`${BASE_URL}/${educationId}`);

  return { getEducations, createEducation, updateEducation, deleteEducation, loading };
};
