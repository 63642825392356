<template>
  <div class="course-card-header">
    <span
      class="text text--s text--link text--line-clamp-2 text--bold course-card__title"
      data-cy="card-title"
      :title="course.title"
      @click="handleClick">
      {{ course.title }}
    </span>
    <CourseTypeIndicator :course="course" />
  </div>
</template>

<script lang="ts" setup>
import type { CourseBase } from "@/models/course";
import CourseTypeIndicator from "@/views/shared/learning/CourseTypeIndicator.vue";

interface Props {
  course: CourseBase;
}
defineProps<Props>();

const emit = defineEmits<(e: "click") => void>();

const handleClick = () => emit("click");
</script>
<style lang="scss" scoped>
.course-card-header {
  display: grid;
  grid-template-columns: minmax(5rem, 1fr) auto;
  column-gap: 0.1rem;
}
</style>
