import { DefaultModalName, type ComponentModal, type TextModal } from "@/components/modal/models";
import useManageModal from "@/components/modal/useManageModal";

interface UseModalReturn {
  openComponentModal: (name: string, config: ComponentModal) => void;
  openTextModal: (config: TextModal) => void;
}

export default (): UseModalReturn => {
  const { openModal } = useManageModal();
  const openComponentModal = (name: string, config: ComponentModal): void => openModal(name, config);
  const openTextModal = (config: TextModal): void => openModal(DefaultModalName, config);

  return {
    openComponentModal,
    openTextModal,
  };
};
