<template>
  <div class="company-employee-group-questions" data-cy="company-employee-group-questions">
    <AppGenericFilter
      class="margin-b--m"
      :placeholder="$t('shared.company_groups_questions.search.placeholder')"
      :add-button-text="$t('shared.company_groups_questions.add_new_question')"
      @filter-changed="(newSearch) => (searchText = newSearch)"
      @create-new="openCreateQuestionDialog" />
    <Teleport v-if="loaded" :to="`#${currentTab}-question-list-navigation`">
      <AppTab :tabs="tabs" :tab-id="`group-${currentGroup?.id || 0}`" data-cy="group-list-nav" class="margin-t--s" />
    </Teleport>
    <GroupQuestionsPanel
      v-if="currentGroup"
      v-show="currentTab === 'group'"
      :group="currentGroup"
      :filter="searchText"
      @remove-questions="removeQuestionsFromGroup"
      @updated-question="updateQuestionData"
      @toggle-in-group-dashboard="toggleInGroupDashboard" />
    <AvailableQuestionsPanel
      v-show="currentTab === 'available'"
      :questions="availableQuestions"
      :filter="searchText"
      @send-questions="sendQuestionsToGroup"
      @remove-questions="removeQuestions"
      @updated-question="updateQuestionData" />
    <QuestionWizard v-if="showCreateQuestionDialog" mode="create" @close="closeCreateQuestionDialog" @saved="addNewQuestion" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import i18n from "@/i18n";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AvailableQuestionsPanel from "@/views/shared/company-groups-questions/components/AvailableQuestionsPanel.vue";
import GroupQuestionsPanel from "@/views/shared/company-groups-questions/components/GroupQuestionsPanel.vue";
import QuestionWizard from "@/views/admin/ai-assistant/components/wizard/QuestionWizard.vue";
import AppTab, { type Tab } from "@/components/AppTab.vue";
import { tryUpdateQuestionData } from "@/views/shared/company-groups-questions/utils/questionsHelper";
import type { CompanyEmployeeGroupWithQuestions } from "@/models/companyEmployeeGroup";
import type { Question, QuestionSelectable } from "@/models/question";
import useCompanyEmployeeGroupsService from "@/services/useCompanyEmployeeGroupsService";
import useQuestionService from "@/services/useQuestionService";

type QuestionTab = "available" | "group";

interface Props {
  groupId: number;
}
const props: Props = defineProps<Props>();

const { getCompanyGroupQuestions, deleteCompanyGroupQuestions, updateCompanyGroupQuestions, toggleQuestionInGroupDashboard } =
  useCompanyEmployeeGroupsService();
const { getUnpublishedQuestions, deleteQuestions } = useQuestionService();

const loaded = ref<boolean>(false);
const tabs = ref<Tab[]>([]);
const currentTab = ref<QuestionTab>("available");
const currentGroup = ref<CompanyEmployeeGroupWithQuestions>();
const questions = ref<QuestionSelectable[]>([]);
const showCreateQuestionDialog = ref<boolean>(false);
const searchText = ref<string>("");
const availableQuestions = computed<QuestionSelectable[]>(() =>
  questions.value.filter(({ id }) => !currentGroup.value?.questions.find((q) => q.id === id))
);

const updateQuestionData = (question: QuestionSelectable) => {
  if (currentGroup.value) {
    const [updatedQuestions, updatedSource] = tryUpdateQuestionData(question, questions.value, currentGroup.value);

    questions.value = updatedQuestions;
    currentGroup.value = updatedSource as CompanyEmployeeGroupWithQuestions;
  }
};

const removeLocalQuestionsFromGroup = (questionIds: number[]) => {
  if (currentGroup.value) {
    currentGroup.value.questions = currentGroup.value.questions.filter((q) => !questionIds.includes(q.id));
  }
};

const removeQuestionsFromGroup = async (groupId: number, questionIds: number[]) => {
  removeLocalQuestionsFromGroup(questionIds);
  await deleteCompanyGroupQuestions(groupId, questionIds);
  await fetchQuestionsData();
};

const removeQuestions = async (questionIds: number[]) => {
  if (questions.value) {
    questions.value = questions.value.filter((q) => !questionIds.includes(q.id));
  }
  removeLocalQuestionsFromGroup(questionIds);
  await deleteQuestions(questionIds);
};

const openCreateQuestionDialog = () => (showCreateQuestionDialog.value = true);
const closeCreateQuestionDialog = () => (showCreateQuestionDialog.value = false);

const sendQuestionsToGroup = async (questionIds: number[]): Promise<void> => {
  if (!currentGroup.value) {
    return;
  }
  await updateCompanyGroupQuestions(currentGroup.value.id, questionIds);
  await fetchData();
};

const addNewQuestion = async (question: Question) => {
  if (currentGroup?.value) {
    await updateCompanyGroupQuestions(currentGroup.value.id, [question.id]);
  }
  await fetchData();
};

const changeShowInDashboardInMemory = (_: number, questionId: number, showInDashboard: boolean) => {
  const question = currentGroup.value?.questions.find((q) => q.id === questionId);
  if (question) {
    question.showInDashboard = showInDashboard;
  }
};

const toggleInGroupDashboard = async (groupId: number, questionId: number, showInDashboard: boolean) => {
  await toggleQuestionInGroupDashboard(groupId, questionId, showInDashboard);
  changeShowInDashboardInMemory(groupId, questionId, showInDashboard);
};

const createTabs = (group: CompanyEmployeeGroupWithQuestions): void => {
  tabs.value = [
    {
      text: i18n.global.t("shared.company_groups_questions.created_questions"),
      action: () => (currentTab.value = "available"),
      isChecked: true,
      dataCy: `created-questions`,
    },
    {
      text: i18n.global.t("shared.company_groups_questions.sent_questions"),
      action: () => (currentTab.value = "group"),
      dataCy: `group-${group.id}`,
    },
  ];
};

const fetchGroupData = async () => {
  currentGroup.value = await getCompanyGroupQuestions(props.groupId);

  if (currentGroup.value) {
    createTabs(currentGroup.value);
  }
};

const fetchQuestionsData = async () => (questions.value = (await getUnpublishedQuestions()) as QuestionSelectable[]);

const fetchData = async () => {
  const tasks = [fetchGroupData(), fetchQuestionsData()];
  await Promise.all(tasks);
  loaded.value = true;
};

onMounted(fetchData);
</script>
