<template>
  <div class="app-radio-button-list" :class="{ 'app-radio-button-list--row': inRow, 'app-radio-button-list--no-gap': noGap }">
    <AppRadioButton
      v-for="option in options"
      :key="option.optionId"
      :checked="option.optionId === modelValue?.optionId"
      :value="option.optionId"
      :text="option.title"
      :class="{ 'margin-b--xs': !inRow }"
      :name="componentId"
      :data-cy="`${'radio_' + option.optionId}`"
      @click="click" />
    <AppInputError v-if="error" :error="error" />
  </div>
</template>

<script setup lang="ts">
import AppRadioButton from "@/components/AppRadioButton.vue";
import { getRandomId } from "@/utils/randomUtils";
import AppInputError from "@/components/AppInputError.vue";

export interface RadioButtonOption {
  optionId: number;
  title: string;
}

interface Props {
  modelValue?: RadioButtonOption;
  options?: RadioButtonOption[];
  error?: string;
  inRow?: boolean;
  noGap?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  options: () => [],
});

const emit = defineEmits<(e: "update:modelValue", option: RadioButtonOption | undefined) => void>();

const componentId: string = getRandomId();

const click = (value: number | string): void => {
  const selected = props.options.find((o) => o.optionId === value);
  emit("update:modelValue", selected);
};
</script>

<style scoped lang="scss">
.app-radio-button-list {
  display: flex;
  flex-direction: column;
  gap: var(--size-m);
  flex-wrap: wrap;
  &--row {
    flex-direction: row;
  }
  &--no-gap {
    gap: unset;
  }
}
</style>
