<template>
  <AppWizardPath
    :steps="steps"
    :selected-step="selectedStep"
    name-prefix="pages.admin.ai_assistant.create_new_survey_form.question.path"
    @step-click="stepClick" />
</template>
<script setup lang="ts">
import AppWizardPath from "@/components/AppWizardPath.vue";

interface Props {
  selectedStep: number;
}
defineProps<Props>();

const emit = defineEmits<(e: "stepClick", step: number) => void>();

const steps = [
  { number: 1, name: "select_question_type" },
  { number: 2, name: "configure_question" },
  { number: 3, name: "save_question" },
];

const stepClick = (step: number) => emit("stepClick", step);
</script>
