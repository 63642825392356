<template>
  <div>
    <AppGenericFilter
      :add-button-text="$t('pages.admin.skill_management.new_skill')"
      :placeholder="$t('pages.admin.skill_management.search_placeholder')"
      class="margin-b--xs"
      @create-new="creating = true"
      @filter-changed="(newSearch) => (searchText = newSearch)" />
    <AppPanel v-if="skills.length > 0" :padding-content="false">
      <template #default>
        <SkillTable :skills="skills" :search-text="searchText" @deleted="fetchData" @edition-started="handleEditionStarted" />
      </template>
    </AppPanel>
    <AppNoContentYet v-else :text="$t('pages.admin.skill_management.noContentYet')" />
    <AppSkillCreator v-if="creating" @skill-created="fetchData" @closed="creating = false" />
    <SkillEditor v-if="editing && selectedSkill" :skill="selectedSkill" @closed="handleClosed" @saved="handleSaved" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppGenericFilter from "@/components/AppGenericFilter.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppSkillCreator from "@/components/AppSkillCreator.vue";
import SkillEditor from "@/views/admin/skills/SkillEditor.vue";
import SkillTable from "@/views/admin/skills/SkillTable.vue";
import type { Skill } from "@/models/skill";
import useSkillService from "@/services/useSkillService";

const { getSkills } = useSkillService();

const editing = ref<boolean>(false);
const creating = ref<boolean>(false);
const selectedSkill = ref<Skill | undefined>(undefined);
const skills = ref<Skill[]>([]);
const searchText = ref<string>("");

const fetchData = async () => (skills.value = await getSkills());

const handleSaved = async (): Promise<void> => {
  editing.value = false;
  await fetchData();
};
const handleClosed = (): void => {
  editing.value = false;
};
const handleEditionStarted = (skill: Skill): void => {
  selectedSkill.value = skill;
  editing.value = true;
};

onMounted(fetchData);
</script>
