<template>
  <KpiChart :chart-options="chartOptions" :chart-series="superSeries" />
</template>

<script lang="ts" setup>
import KpiChart from "@/views/admin/ai-assistant/components/kpis/KpiChart.vue";
import type { ApexOptions } from "apexcharts";
import { formatNumber } from "@/utils/numberUtils";

interface Props {
  series: number[];
  seriesPercentage: number[];
  labels: string[];
}

const props = defineProps<Props>();

const data = props.series.map((value: number, index: number) => {
  return {
    x: props.labels[index],
    y: value,
  };
});
const superSeries = [{ data }];
const chartColors = [
  "#1E9AC6",
  "#272728",
  "#3B3B3C",
  "#4E4F50",
  "#626264",
  "#767678",
  "#898A8C",
  "#9D9DA0",
  "#B0B1B4",
  "#C4C5C8",
  "#D7D8DC",
];
const chartOptions: ApexOptions = {
  labels: props.labels,
  chart: {
    type: "treemap",
    toolbar: {
      show: false,
    },
    selection: {
      enabled: false,
    },
  },
  stroke: {
    colors: ["#fff"],
  },
  plotOptions: {
    treemap: {
      distributed: true,
      enableShades: false,
    },
  },
  states: {
    active: {
      filter: {
        type: "none",
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    normal: {
      filter: {
        type: "none",
      },
    },
  },
  colors: chartColors,
  dataLabels: {
    enabled: true,
  },
  fill: {
    opacity: 0.8,
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    marker: {
      show: false,
    },
    style: {
      fontSize: "11px",
    },
    y: {
      formatter: (_value, { dataPointIndex }) => `${formatNumber(props.seriesPercentage[dataPointIndex])} %`,
    },
  },
};
</script>
