<template>
  <AppWizardPath
    :steps="steps"
    :selected-step="selectedStep"
    name-prefix="pages-my-profile.profile_updating.performance_graph.configurer.step"
    @step-click="stepClick" />
</template>
<script setup lang="ts">
import AppWizardPath from "@/components/AppWizardPath.vue";

interface Props {
  selectedStep: number;
}
defineProps<Props>();

const emit = defineEmits<(e: "stepClick", step: number) => void>();

const steps = [
  { number: 1, name: "introduction" },
  { number: 2, name: "current_job_skills" },
  { number: 3, name: "ideal_job_skills" },
];
const stepClick = (step: number): void => emit("stepClick", step);
</script>
