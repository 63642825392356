import { AppFocus } from "@/directives/AppFocus";
import { AppScrollTop } from "@/directives/AppScrollTop";
import { AppScrollTo } from "@/directives/AppScrollTo";
import type { App } from "vue";
import { clickOutside } from "@/directives/ClickOutside";

export const loadDirectives = (app: App): void => {
  app.directive("app-focus", AppFocus);
  app.directive("app-scroll-top", AppScrollTop);
  app.directive("app-scroll-to", AppScrollTo);
  app.directive("click-outside", clickOutside);
};
