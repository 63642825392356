<template>
  <div class="course-list">
    <CourseListItem v-for="course in courses.items" :key="course.id" :course="course" @click="selectCourse" />
    <PaginationFooter :number-of-items="courses.total" :event-emitter="eventEmitter" @page-click="pageClick" />
  </div>
</template>
<script setup lang="ts">
import type { EmployeeCourse, EmployeeCourseList } from "@/models/course";
import type { UseEventEmitterServiceReturn } from "@/services/useEventEmitterService";
import CourseListItem from "@/views/shared/learning/CourseListItem.vue";
import PaginationFooter from "@/components/paginator/PaginationFooter.vue";

interface Props {
  courses: EmployeeCourseList;
  eventEmitter: UseEventEmitterServiceReturn;
}

defineProps<Props>();

interface Emits {
  (e: "courseSelected", course: EmployeeCourse): void;
  (e: "updateList", pageNumber: number): void;
}
const emit = defineEmits<Emits>();

const selectCourse = (course: EmployeeCourse) => emit("courseSelected", course);
const pageClick = (value: number) => emit("updateList", value);
</script>

<style scope lang="scss">
.course-list {
  display: flex;
  flex-direction: column;
  gap: var(--size-s);
  padding-top: var(--size-s);
}
</style>
