import type {
  CompanyEmployeeGroup,
  CompanyEmployeeGroupInfo,
  CompanyEmployeeGroupWithQuestions,
  EmployeeGroupEmployee,
  EmployeeGroupRole,
  EmployeeWithGroups,
} from "@/models/companyEmployeeGroup";
import type { CourseBase } from "@/models/course";
import { QuestionWithAnswers } from "@/models/questionAnswers";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";
import { QuestionKpiInfo } from "@/models/questionKpi";

const BASE_URL = "/api/company/employee-groups";
const { get, put, post, httpDelete, loading } = useHttpClient();

interface UseCompanyEmployeeGroupsServiceReturn {
  getCompanyEmployeeGroups: () => Promise<CompanyEmployeeGroupInfo[]>;
  getCompanyEmployeesWithGroups: () => Promise<EmployeeWithGroups[]>;
  getCompanyEmployeeGroup: (groupId: number) => Promise<CompanyEmployeeGroup>;
  getCompanyGroupEmployees: (groupId: number) => Promise<EmployeeGroupEmployee[]>;
  setEmployeeRole: (groupId: number, employeeId: number, role: EmployeeGroupRole) => Promise<void>;
  updateCompanyEmployeeGroup: (group: CompanyEmployeeGroup) => Promise<void>;
  createCompanyEmployeeGroup: (group: CompanyEmployeeGroup) => Promise<void>;
  deleteCompanyEmployeeGroup: (groupId: number) => Promise<void>;
  getCourses: (groupId: number) => Promise<CourseBase[]>;
  setCourse: (groupId: number, courseId: number) => Promise<void>;
  deleteCourse: (groupId: number, courseId: number) => Promise<void>;

  getCompanyGroupQuestions: (groupId: number) => Promise<CompanyEmployeeGroupWithQuestions>;
  getCompanyGroupsQuestions: () => Promise<CompanyEmployeeGroupWithQuestions[]>;
  deleteCompanyGroupQuestions: (groupId: number, questionsIds: number[]) => Promise<void>;
  updateCompanyGroupsQuestions: (groupIds: number[], questionIds: number[]) => Promise<void>;
  updateCompanyGroupQuestions: (groupId: number, questionIds: number[]) => Promise<void>;
  toggleQuestionInGroupDashboard: (groupId: number, questionId: number, showInDashboard: boolean) => Promise<void>;
  getGroupKpis: (groupId: number) => Promise<QuestionKpiInfo[]>;
  geCompanyGroupQuestionAnswers: (groupId: number, questionId: number) => Promise<QuestionWithAnswers>;
  geCompanyGroupQuestionKpi: (groupId: number, questionId: number) => Promise<QuestionKpiInfo>;

  loading: Ref<boolean>;
}

export default (): UseCompanyEmployeeGroupsServiceReturn => {
  const getCompanyEmployeeGroups = (): Promise<CompanyEmployeeGroupInfo[]> => get(BASE_URL);
  const getCompanyEmployeesWithGroups = (): Promise<EmployeeWithGroups[]> => get(`${BASE_URL}/employees-with-groups`);
  const getCompanyEmployeeGroup = (groupId: number): Promise<CompanyEmployeeGroup> => get(`${BASE_URL}/${groupId}`);
  const getCompanyGroupEmployees = (groupId: number): Promise<EmployeeGroupEmployee[]> => get(`${BASE_URL}/${groupId}/employees`);
  const updateCompanyEmployeeGroup = async (group: CompanyEmployeeGroup): Promise<void> => {
    const postData: Partial<CompanyEmployeeGroup> = { ...group };
    postData.id = undefined;
    await put(`${BASE_URL}/${group.id}`, postData);
  };
  const createCompanyEmployeeGroup = async (group: CompanyEmployeeGroup): Promise<void> => {
    const postData: Partial<CompanyEmployeeGroup> = { ...group };
    postData.id = undefined;
    await post(`${BASE_URL}/`, postData);
  };
  const deleteCompanyEmployeeGroup = (groupId: number): Promise<void> => httpDelete(`${BASE_URL}/${groupId}`);
  const setEmployeeRole = (groupId: number, employeeId: number, role: EmployeeGroupRole) => {
    const postData = { employeeId, role };
    return put<void>(`${BASE_URL}/${groupId}/employee-role`, postData);
  };
  const getCourses = (groupId: number): Promise<CourseBase[]> => get(`${BASE_URL}/${groupId}/courses`);
  const setCourse = (groupId: number, courseId: number): Promise<void> => put(`${BASE_URL}/${groupId}/courses/${courseId}`);
  const deleteCourse = (groupId: number, courseId: number): Promise<void> => httpDelete(`${BASE_URL}/${groupId}/courses/${courseId}`);

  const getCompanyGroupQuestions = (groupId: number): Promise<CompanyEmployeeGroupWithQuestions> => get(`${BASE_URL}/${groupId}/questions`);
  const getCompanyGroupsQuestions = (): Promise<CompanyEmployeeGroupWithQuestions[]> => get(`${BASE_URL}/questions`);
  const deleteCompanyGroupQuestions = (groupId: number, questionIds: number[]): Promise<void> =>
    httpDelete(`${BASE_URL}/${groupId}/questions`, questionIds);
  const updateCompanyGroupsQuestions = (groupIds: number[], questionIds: number[]): Promise<void> =>
    put(`${BASE_URL}/questions`, { companyEmployeeGroupIds: groupIds, questionIds });
  const updateCompanyGroupQuestions = (groupId: number, questionIds: number[]): Promise<void> =>
    put(`${BASE_URL}/${groupId}/questions`, questionIds);
  const geCompanyGroupQuestionAnswers = (groupId: number, questionId: number): Promise<QuestionWithAnswers> =>
    get(`${BASE_URL}/${groupId}/questions/${questionId}/answers`);
  const geCompanyGroupQuestionKpi = (groupId: number, questionId: number): Promise<QuestionKpiInfo> =>
    get(`${BASE_URL}/${groupId}/questions/${questionId}/kpi`);
  const toggleQuestionInGroupDashboard = (groupId: number, questionId: number, showInDashboard: boolean): Promise<void> =>
    post(`${BASE_URL}/${groupId}/questions/${questionId}/dashboard/${showInDashboard}`, { questionId, showInDashboard });
  const getGroupKpis = async (groupId: number): Promise<QuestionKpiInfo[]> => get<QuestionKpiInfo[]>(`${BASE_URL}/${groupId}/kpis`);

  return {
    getCompanyEmployeeGroups,
    getCompanyEmployeesWithGroups,
    getCompanyEmployeeGroup,
    getCompanyGroupEmployees,
    updateCompanyEmployeeGroup,
    deleteCompanyEmployeeGroup,
    createCompanyEmployeeGroup,
    setEmployeeRole,
    getCourses,
    setCourse,
    deleteCourse,
    getCompanyGroupsQuestions,
    getCompanyGroupQuestions,
    deleteCompanyGroupQuestions,
    updateCompanyGroupsQuestions,
    updateCompanyGroupQuestions,
    toggleQuestionInGroupDashboard,
    getGroupKpis,
    geCompanyGroupQuestionAnswers,
    geCompanyGroupQuestionKpi,
    loading,
  };
};
