<template>
  <AppButton
    v-if="!course.done"
    :text="$t('pages.learning.goToTheTraining')"
    size="m"
    :disabled="!course.url"
    data-cy="goToTraining-Button"
    @onclick="openTrainingUrl" />
  <AppTag v-else type="status" status-type="success" class="training-complete-button" :main-text="$t('pages.learning.trainingComplete')" />
</template>

<script lang="ts" setup>
import type { EmployeeCourse } from "@/models/course";
import AppTag from "@/components/AppTag.vue";
import AppButton from "@/components/AppButton.vue";
import { openUrl } from "@/utils/urlHelper";

interface Props {
  course: EmployeeCourse;
}
const props = defineProps<Props>();

const openTrainingUrl = () => openUrl(props.course.url);
</script>
<style lang="scss" scoped>
.training-complete-button {
  padding: 10px 13px;
  border: 1px solid var(--color-neutral-100);
}
</style>
