import i18n from "@/i18n";
import { formatNumber } from "@/utils/numberUtils";

export const getDurationStringFromHours = (duration?: number): string => {
  if (!duration) {
    return i18n.global.t("shared.time.hours", { time: formatNumber(0) });
  }

  const minutesPart = duration - Math.floor(duration);
  const hours = Math.round(duration - minutesPart);
  const minutes = Math.round(minutesPart * 60);
  const minutesText = i18n.global.t("shared.time.minutes", { time: formatNumber(minutes) });

  if (hours > 0) {
    const hoursText = i18n.global.t("shared.time.hours", { time: formatNumber(hours) });
    return hoursText + (minutes > 0 ? " " + minutesText : "");
  }

  return minutesText;
};
