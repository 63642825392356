<template>
  <AppTable :no-border="true" :columns="columns" :items="employees" :paginated="true" :default-order="defaultOrder" :full-width="true" />
</template>

<script setup lang="ts">
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";
import type { EmployeeGroupEmployee } from "@/models/companyEmployeeGroup";

const emit = defineEmits<(e: "selectItem", id: number) => void>();

interface Props {
  employees: EmployeeGroupEmployee[];
}
defineProps<Props>();

const selectItem = ({ id }: EmployeeGroupEmployee) => emit("selectItem", id);

const defaultOrder: Order = { direction: "asc", id: 1 };

const columns: Column<EmployeeGroupEmployee>[] = [
  {
    id: 1,
    headerText: i18n.global.t("shared.company-groups.grid.full_name"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    type: "text-with-ellipsis",
    formatter: ({ firstName, lastName }: EmployeeGroupEmployee) => i18n.global.t("shared.employee.full_name", { firstName, lastName }),
  },
  {
    id: 2,
    headerText: i18n.global.t("shared.company-groups.grid.email"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: false,
    fieldName: "email",
    type: "text-with-ellipsis",
  },
  {
    id: 3,
    cssWidth: "2.5rem",
    align: "left",
    sortable: false,
    searchable: false,
    type: "actions",
    actions: [
      {
        callback: selectItem,
        icon: "eye",
        title: i18n.global.t("pages.my_groups.grid.actions.view_profile"),
        name: "profile",
      },
    ],
  },
];
</script>
