<template>
  <AppSelect
    v-model="value"
    class="category-selector"
    data-cy="hobbies-category-selector"
    :items="availableCategories"
    :label="$t('entities.my_interests.hobbies.category')" />
</template>

<script lang="ts" setup>
import i18n from "@/i18n";
import AppSelect from "@/components/AppSelect.vue";
import { computed } from "vue";
import { HobbyCategory } from "@/models/hobbyCategory";

interface Props {
  modelValue?: HobbyCategory;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", value: HobbyCategory) => void>();

const availableCategories = Object.keys(HobbyCategory).map((category) => ({
  value: category,
  text: i18n.global.t(`entities.my_interests.hobbies.category.${category.toLowerCase()}`),
}));

const value = computed({
  get: () => props.modelValue ?? HobbyCategory.Sport,
  set: (val: HobbyCategory) => emit("update:modelValue", val),
});
</script>
