import type { Column, SelectOption } from "@/components/table/Column";
import type { Order } from "@/components/table/Order";
import type { TableItem } from "@/components/table/TableItem";
import { type Filter, SELECT_ALL, type FilterOption } from "@/components/table/TableFilter";
import type { PaginationProperties } from "@/components/table/PaginationProperties";
import { compareNumbers } from "@/utils/numberUtils";
import { compareDates } from "@/utils/dateUtils";
import { compareStrings, containsInsensitive, isBlank } from "@/utils/stringUtils";
import i18n from "@/i18n";

interface UseTableHelperResponse {
  filter: <T extends TableItem>(items: T[] | undefined, filters: Filter<T>[] | undefined) => T[];
  filterByText: <T extends TableItem>(source: T[], searchText: string | undefined, columns: Column<T>[]) => T[];
  paginate: (items: TableItem[], paginationProps: PaginationProperties) => TableItem[];
  sort: (order: Order, columns: Column<TableItem>[], items: TableItem[]) => TableItem[];
  getTypeFilter: <T extends TableItem>(fieldName: keyof T, title: string, i18Prefix: string, values: string[]) => Filter<T>;
  getSelectOptions: <Enum extends { [name: string]: string }>(E: Enum, translatePrefix: string) => SelectOption[];
  getSelectOption: <Enum extends { [name: string]: string }>(E: Enum, enumKey: keyof Enum, translatePrefix: string) => SelectOption;
}

export default (): UseTableHelperResponse => ({ sort, filter, filterByText, paginate, getTypeFilter, getSelectOptions, getSelectOption });

const paginate = (source: TableItem[], paginationProps: PaginationProperties): TableItem[] => {
  if (!source) {
    return source;
  }
  const startIndex = paginationProps.currentPage === 1 ? 0 : paginationProps.pageSize * (paginationProps.currentPage - 1);
  const endIndex = paginationProps.currentPage === 1 ? paginationProps.pageSize : paginationProps.pageSize * paginationProps.currentPage;
  return source.slice(startIndex, endIndex);
};
const filter = <T extends TableItem>(source: T[] | undefined, filters: Filter<T>[] | undefined): T[] => {
  if (!source) {
    return [];
  }
  if (!filters) {
    return source;
  }
  return filters.reduce((prev: T[], currentFilter: Filter<T>): T[] => {
    if (currentFilter.selectedOption === SELECT_ALL) {
      return prev;
    }
    return prev.filter((currentItem: T) => currentItem[currentFilter.fieldName] === currentFilter.selectedOption);
  }, source);
};
const filterByText = <T extends TableItem>(source: T[], searchText: string | undefined, columns: Column<T>[]): T[] => {
  if (!searchText || isBlank(searchText)) {
    return source;
  }
  const searchableColumns: Column<T>[] = columns.filter((column) => column.searchable && (column.fieldName || column.formatter));
  return source.filter((item: T) => searchableColumns.some((column) => filterByField(column, item, searchText)));
};
const filterByField = <T extends TableItem>(column: Column<T>, item: T, searchText: string): boolean => {
  const fieldValue: unknown = getValue(item, column);
  return !!fieldValue && containsInsensitive(fieldValue.toString(), searchText);
};
const sort = (order: Order, columns: Column<TableItem>[], items: TableItem[]): TableItem[] => {
  if (!items) {
    return items;
  }
  const column = columns.find((col) => col.id === order.id);
  return [...items].sort((item1: TableItem, item2: TableItem) => {
    const comparatorResult = compareItems(item1, item2, column);
    return order.direction === "asc" ? comparatorResult : -comparatorResult;
  });
};
const compareItems = (skill1: TableItem, skill2: TableItem, column?: Column<TableItem>): number => {
  const fieldValue1: unknown = getValue(skill1, column);
  const fieldValue2: unknown = getValue(skill2, column);
  if (fieldValue1 === fieldValue2) {
    return 0;
  }
  if (!fieldValue2) {
    return 1;
  }
  if (!fieldValue1) {
    return -1;
  }
  if (fieldValue1 instanceof Date && fieldValue2 instanceof Date) {
    return compareDates(fieldValue1, fieldValue2, true);
  }
  if (typeof fieldValue1 === "number" && typeof fieldValue2 === "number") {
    return compareNumbers(fieldValue1, fieldValue2, true);
  }
  return compareStrings(fieldValue1 as string, fieldValue2 as string, true);
};
const getValue = <T extends TableItem>(item: T, column?: Column<T>): unknown => {
  if (!column) {
    return "";
  }
  if (column.formatter) {
    return column.formatter(item);
  }
  if (column.fieldName) {
    return item[column.fieldName];
  }
  return "";
};
const getTypeFilter = <T extends TableItem>(fieldName: keyof T, title: string, i18Prefix: string, values: string[]): Filter<T> => {
  const typeFilters: FilterOption[] = values.map((type: string) => ({
    label: i18n.global.t(`${i18Prefix}.${type}`),
    value: type,
  }));

  const result: Filter<T> = {
    title: title,
    fieldName: fieldName,
    selectedOption: SELECT_ALL,
    filterOptions: [
      {
        label: i18n.global.t(`${i18Prefix}.all`),
        value: SELECT_ALL,
      },
      ...typeFilters,
    ],
  };
  return result;
};
const getSelectOptions = <Enum extends { [name: string]: string }>(E: Enum, translatePrefix: string): SelectOption[] => {
  return Object.keys(E).map((key) => getSelectOption(E, key as keyof Enum, translatePrefix));
};
const getSelectOption = <Enum extends { [name: string]: string }>(E: Enum, enumKey: keyof Enum, translatePrefix: string): SelectOption => {
  return {
    text: i18n.global.t(`${translatePrefix}.${enumKey as string}`),
    value: E[enumKey] as string,
  };
};
