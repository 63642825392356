<template>
  <EmployeeList :employees="employees" :title="title" :category="selection?.hobbiesCategory" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import EmployeeList from "@/views/admin/talent-detection/components/employee-lists/AdminTalentDetectionEmployeeList.vue";
import i18n from "@/i18n";
import type { AgeChartSelection } from "@/models/talent-detection/chartSelections";
import type { EmployeeMotivation } from "@/models/employee/employeeMotivation";
import useCompanyTalentDetectionService from "@/services/useCompanyTalentDetectionService";

const { getEmployeesByAge } = useCompanyTalentDetectionService();

interface Props {
  selection?: AgeChartSelection;
}

const props = defineProps<Props>();
const employees = ref<EmployeeMotivation[]>([]);

const calculateSuffix = (from: number | undefined, to: number | undefined) => {
  if (!from && !to) {
    return "all";
  }
  if (from && to) {
    return "range";
  }
  return to ? "to" : "from";
};

const title = computed(() => {
  if (!props.selection) {
    return;
  }

  const results = employees.value.length;
  const category = props.selection?.hobby?.name ?? props.selection?.hobbiesCategory;
  const from = props.selection.ageRange?.from;
  const to = props.selection.ageRange?.to;
  const suffix = calculateSuffix(from, to);
  return i18n.global.t(`pages.admin.talent_detection.employees_age.title.age_${suffix}`, {
    n: results,
    category: category,
    from: from,
    to: to,
  });
});

watch(
  () => props.selection,
  async () => refreshEmployees()
);

const refreshEmployees = async () => {
  if (!props.selection) {
    employees.value = [];
    return;
  }

  employees.value = await getEmployeesByAge(
    props.selection.hobbiesCategory,
    props.selection.hobby?.id,
    props.selection.ageRange?.from,
    props.selection.ageRange?.to
  );
};
</script>
