<template>
  <AppSelect
    v-model="$i18n.locale"
    :items="availableLocales"
    :label="$t('pages.header.profile-editor.app-settings.languages.title')"
    @change="handleLanguageChanged" />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n";
import AppSelect from "@/components/AppSelect.vue";
import useEmployeeService from "@/services/useEmployeeService";
import { STORES, useStorageService } from "@/services/infrastructure/storageService";

const { putEmployeeLanguage } = useEmployeeService();
const { store } = useStorageService<string>(STORES.language_key);

const availableLocales = computed<{ value: string; text: string }[]>(() => {
  return i18n.global.availableLocales
    .map((l) => ({ value: l, text: i18n.global.t(`pages.header.profile-editor.app-settings.languages.${l}`) }))
    .sort((a, b): number => a.text.localeCompare(b.text));
});

const handleLanguageChanged = async () => {
  await putEmployeeLanguage(i18n.global.locale.value);
  store(i18n.global.locale.value);
};
</script>
