<template>
  <AppTable
    :no-border="true"
    :columns="columns"
    :items="employeesInView"
    :paginated="true"
    :allow-select="true"
    :default-order="defaultOrder"
    select-column-width="3rem"
    @select-item="selectItem"
    @select-all="selectAll" />
</template>
<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/i18n";
import AppTable from "@/components/table/AppTable.vue";
import type { Column } from "@/components/table/Column";
import useTableHelper from "@/components/table/useTableHelper";
import type { EmployeeInformation } from "@/models/employee/employeeInformation";
import type { TableItemSelectable } from "@/components/table/TableItem";
import type { Order } from "@/components/table/Order";
import EmployeeAvatar from "@/views/admin/company-settings/company-employee-groups/components/EmployeeAvatar.vue";

interface Props {
  employees: EmployeeInformation[];
  searchText?: string;
}
const props: Props = defineProps<Props>();

interface Emits {
  (e: "selectItem", item: TableItemSelectable): void;
  (e: "selectAll", selected: boolean, filtered: boolean, filteredIds: number[]): void;
}
const emit = defineEmits<Emits>();

const { filterByText } = useTableHelper();

const employeesInView = computed<EmployeeInformation[]>(() => filterByText(props.employees, props.searchText, columns));

const selectItem = (item: TableItemSelectable) => emit("selectItem", item);

const selectAll = (selected: boolean) => {
  const isFiltered = employeesInView.value.length !== props.employees.length;
  const filteredIds = isFiltered ? employeesInView.value.map(({ id }) => id) : [];

  emit("selectAll", selected, isFiltered, filteredIds);
};

const defaultOrder: Order = { direction: "asc", id: 2 };

const columns: Column<EmployeeInformation>[] = [
  {
    id: 1,
    cssWidth: "5rem",
    align: "left",
    sortable: false,
    searchable: false,
    customRenderer: EmployeeAvatar,
  },
  {
    id: 2,
    headerText: i18n.global.t("shared.company-groups.grid.full_name"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: true,
    type: "text-with-ellipsis",
    formatter: ({ firstName, lastName }: EmployeeInformation) => i18n.global.t("shared.employee.full_name", { firstName, lastName }),
  },
  {
    id: 3,
    headerText: i18n.global.t("shared.company-groups.grid.email"),
    cssWidth: "1fr",
    align: "left",
    sortable: true,
    searchable: true,
    fieldName: "email",
    type: "text-with-ellipsis",
  },
];
</script>
