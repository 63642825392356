<template>
  <AppUploadDocument
    v-model="attachment"
    :accept="basicAllowedImageExtensions.join()"
    :download-url="getEmployeePictureUrl(props.employeeId)"
    :label="$t('pages.header.picture-uploader.label')"
    :maximum-size="fileSize1MB"
    :error="attachmentError"
    :without-modal="!validAvatar"
    button-variant="primary"
    class="margin-b--s"
    close-button
    data-cy="avatar-upload-document"
    @update:model-value="updated"
    @close="$emit('close')" />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useField, useForm } from "vee-validate";
import { object as yupObject } from "yup";

import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";

import type { FileAttachment } from "@/models/fileAttachment";
import useFormSchema from "@/services/useFormSchema";
import useMePictureService from "@/services/useMePictureService";
import useEmployeeService from "@/services/useEmployeeService";
import { fileSize1MB, basicAllowedImageExtensions } from "@/utils/fileUtils";

interface Props {
  employeeId: number;
  pictureName?: string;
}
const props = defineProps<Props>();

defineEmits<(e: "close") => void>();

const { savePicture, deletePicture } = useMePictureService();
const { getEmployeePictureUrl } = useEmployeeService();
const { attachmentSchema } = useFormSchema();

const validAvatar = ref<boolean>(true);

const resourceSchema = yupObject({
  attachment: attachmentSchema(basicAllowedImageExtensions, fileSize1MB),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");

onMounted(() => setAttachmentName(props.pictureName));

const setAttachmentName = (name?: string): void => {
  if (!name) {
    return;
  }
  attachment.value = { fileName: name };
};
const updated = async (avatarAttachment: FileAttachment | undefined) => {
  if (!avatarAttachment && !validAvatar.value) {
    return;
  }

  const formValidation = await form.validate();
  if (!formValidation.valid) {
    validAvatar.value = false;
    return;
  }

  if (!avatarAttachment) {
    await deletePicture();
  } else {
    try {
      await save(avatarAttachment);
    } catch (error) {
      /* empty */
    }
  }

  refreshPage();
};

const save = async (attachment: FileAttachment) => savePicture(attachment);

const refreshPage = () => window.history.go(0);
</script>
