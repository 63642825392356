<template>
  <div
    class="modal"
    :class="{
      'modal--visible': show,
      'modal--upper-z-index': isModalContentText,
    }"
    data-cy="modal">
    <div v-if="config" class="panel" :class="`panel--${effectiveVariant}`">
      <div class="panel__close">
        <span class="icon icon-close text--link" data-cy="close-modal" @click="close()"></span>
      </div>
      <h2 class="panel__title">
        <span v-if="config.icon" class="icon text--xxl" :class="`icon-${config.icon.content} text--${config.icon.style}`"></span>
        <span>{{ config.title }}</span>
      </h2>
      <div v-app-scroll-top="state" class="panel__content" :class="{ 'panel__content--without-overflow': !overflow }">
        <div v-if="!hasCustomComponent && isModalContentText" class="text" v-html="(config as TextModal).contentText"></div>
        <slot v-if="hasCustomComponent"></slot>
      </div>
      <div v-if="showActions" class="panel__actions">
        <slot v-if="hasCustomActions" name="actions"></slot>
        <AppButton
          v-if="!hasCustomActions && (config.cancelCallback || config.showCancelToClose)"
          :disabled="executingAction"
          :text="config.cancelText || $t('buttons.cancel')"
          variant="secondary"
          size="m"
          data-cy="cancel-modal"
          @onclick="cancel()" />
        <AppButton
          v-if="!hasCustomActions && config.confirmCallback"
          :disabled="executingAction"
          :text="config.confirmText || $t('buttons.continue')"
          variant="primary"
          size="m"
          data-cy="confirm-modal"
          @onclick="confirm()" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import AppButton from "@/components/AppButton.vue";
import type { ModalBase, ModalCallback, ModalVariant, TextModal } from "@/components/modal/models";
import useManageModal from "@/components/modal/useManageModal";
import { useKeyEvent } from "@/services/useKeyEvent";
import { computed, getCurrentInstance, onUnmounted, ref } from "vue";

interface Props {
  name: string;
  disableKeyScape?: boolean;
  disableKeyEnter?: boolean;
  state?: string;
  overflow?: boolean;
}
const props = withDefaults(defineProps<Props>(), { overflow: true });

const { close: closeModal, isOpen, getConfig, isCurrentModalActive } = useManageModal();
const currentInstance = getCurrentInstance();

const effectiveVariant = computed<ModalVariant>(() => {
  if (config.value?.variant !== "floating") {
    return config.value?.variant ?? "small";
  }
  return getIsInNonFloatingModal() ? "large" : config.value.variant;
});

const executingAction = ref<boolean>(false);
const show = computed<boolean>(() => isOpen(props.name));
const config = computed<ModalBase | undefined>(() => getConfig(props.name));
const hasCustomComponent = computed<boolean>(() => !!currentInstance?.slots.default);
const hasCustomActions = computed<boolean>(() => !!currentInstance?.slots["actions"]);
const showActions = computed<boolean>(
  () => !!config.value?.cancelCallback || config.value?.showCancelToClose || !!config.value?.confirmCallback || hasCustomActions.value
);
const isModalContentText = computed<boolean>(() => !!config.value && !!(config.value as TextModal).contentText);

const confirm = () => action(config.value?.confirmCallback);
const cancel = () => action(config.value?.cancelCallback ?? config.value?.closeCallback);
const close = () => action(config.value?.closeCallback);

const action = async (callback?: ModalCallback): Promise<void> => {
  if (executingAction.value) {
    return;
  }
  executingAction.value = true;
  try {
    const omitClose = await callbackAvoidClose(callback);
    if (omitClose) {
      return;
    }
  } finally {
    executingAction.value = false;
  }
  const closedHandler = config.value?.onClosed;
  closeModal(props.name);
  if (closedHandler) {
    closedHandler();
  }
};
const callbackAvoidClose = async (callback?: ModalCallback) => {
  if (!callback) {
    return false;
  }
  const result = await callback();
  return result === false;
};

onUnmounted(() => closeModal(props.name));

const getIsInNonFloatingModal = (): boolean => {
  const parentPanelModal: HTMLObjectElement | undefined = currentInstance
    ? currentInstance.vnode.el?.closest(".modal > .panel")
    : undefined;
  return !!parentPanelModal && !parentPanelModal.classList.contains("panel--floating");
};

const closeIfCurrent = async () => {
  if (!props.disableKeyScape && isCurrentModalActive(props.name)) {
    await close();
  }
};
const confirmIfCurrent = async () => {
  if (!props.disableKeyEnter && isCurrentModalActive(props.name)) {
    await confirm();
  }
};

useKeyEvent({
  keyEvent: "keydown",
  keyBinds: [
    {
      keyCode: "Escape",
      success: closeIfCurrent,
    },
    {
      keyCode: "Enter",
      success: confirmIfCurrent,
    },
  ],
  isActive: show,
});
</script>
<style lang="scss" scoped>
@import "@/styles/utils/responsive";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: calc(var(--z-index-modal) - 1);
  display: grid;
  place-items: center;
  width: 0;
  height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);

  & & {
    background-color: transparent;
  }

  &--visible {
    width: 100vw;
    height: 100vh;
  }

  &--upper-z-index {
    z-index: var(--z-index-modal);
  }
}

.panel {
  --panel-padding-horizontal: var(--space-l);

  display: grid;
  grid-template-rows: auto auto 1fr auto;
  z-index: var(--z-index-modal);
  width: 100%;
  height: 100%;
  background-color: var(--color-card);
  overflow: hidden;

  @media only screen and (min-width: $medium-screen-breakpoint) {
    border-radius: 8px;

    &--small {
      height: auto;
      max-height: 50%;
      width: 60rem;
    }

    &--large {
      width: min(70%, 102.4rem);
      height: min(90%, 89.6rem);
    }

    &--floating {
      position: absolute;
      top: 0;
      right: 0;
      left: unset;
      bottom: 0;
      width: max(33%, 50rem);
      border-radius: 0;
    }

    &--adaptive {
      height: auto;
      width: 60rem;
      max-height: min(90%, 89.6rem);
    }
  }

  &__close {
    padding: 0.9rem;
    text-align: right;
    font-size: 3.5rem;
  }

  &__title {
    font-size: 2rem;
    padding: 0 var(--panel-padding-horizontal) var(--size-l);
    margin: 0;
    display: flex;
    align-items: center;
    gap: var(--space-xs);
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
    padding: 0 var(--panel-padding-horizontal) 3rem;

    &--without-overflow {
      overflow: hidden;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--space-s);
    padding: 1rem var(--panel-padding-horizontal) 2rem;
  }

  &--adaptive &__title {
    padding: 0 var(--panel-padding-horizontal);
  }

  &--adaptive &__content {
    padding: 0;
  }
}
</style>
