<template>
  <span class="text text--link" :class="{ 'text--bold': bold }" :disabled="disabled" @click="handleClick">{{ text }}</span>
</template>

<script setup lang="ts">
interface Props {
  disabled?: boolean;
  text: string;
  bold?: boolean;
}
const props = withDefaults(defineProps<Props>(), { bold: true });

const emit = defineEmits<(e: "click") => void>();

const handleClick = () => {
  if (!props.disabled) {
    emit("click");
  }
};
</script>
