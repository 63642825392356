<template>
  <progress :class="progressColor" :value="modelValueString" max="100"></progress>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  value?: number;
}
const props = withDefaults(defineProps<Props>(), { value: 0 });

const modelValueString = computed<string>(() => props.value.toString());

const progressColor = computed<string>(() => {
  if (props.value <= 30) {
    return "progress--minor30";
  }
  if (props.value <= 60) {
    return "progress--minor60";
  }
  return "progress--major60";
});
</script>

<style scoped lang="scss">
@mixin progress {
  height: var(--space-xs);
  width: 100%;
  border-radius: var(--default-border-radius);

  &::-webkit-progress-value {
    border-radius: var(--default-border-radius);
  }
  &::-webkit-progress-bar {
    background: var(--color-progress-bar-background);
    border-radius: var(--default-border-radius);
  }

  &::-moz-progress-bar {
    border-radius: var(--default-border-radius);
  }
}

.progress--major60 {
  @include progress;

  &::-webkit-progress-value {
    background: var(--color-progress-bar-major60);
  }
}

.progress--minor60 {
  @include progress;

  &::-webkit-progress-value {
    background: var(--color-progress-bar-minor60);
  }
}

.progress--minor30 {
  @include progress;

  &::-webkit-progress-value {
    background: var(--color-progress-bar-minor30);
  }
}
</style>
