<template>
  <AppPanel
    class="question-list"
    :padding-header="false"
    :padding-content="false"
    :padding-footer="false"
    data-cy="available-question-list-container">
    <template #header>
      <div class="question-list__header">
        <span class="text--bold">{{ $t("shared.company_groups_questions.subtitle") }}</span>
        <div id="available-question-list-navigation"></div>
      </div>
    </template>
    <QuestionsTable
      :questions="filteredQuestions"
      :paginated="true"
      :allow-select="true"
      :allow-sort="true"
      :no-border="true"
      :order="defaultOrder"
      :question-status="QuestionStatus.Unpublished"
      :allowed-group-names="allowedGroupNames"
      @question-deleted="deleteQuestion"
      @question-edited="editQuestion" />
    <template #footer>
      <div class="question-list__footer">
        <AppButton
          :text="$t('shared.company_groups_questions.remove')"
          variant="secondary"
          size="m"
          :disabled="!selectedIds.length"
          data-cy="question-list-remove"
          @onclick="tryDeleteSelectedQuestions" />
        <AppButton
          :text="$t('shared.company_groups_questions.send')"
          variant="secondary"
          size="m"
          :disabled="!selectedIds.length"
          data-cy="question-list-send"
          @onclick="sendSelectedQuestions" />
      </div>
    </template>
  </AppPanel>
</template>

<script setup lang="ts">
import { watch, ref, computed } from "vue";
import i18n from "@/i18n";
import AppPanel from "@/components/AppPanel.vue";
import AppButton from "@/components/AppButton.vue";
import QuestionsTable from "@/views/admin/ai-assistant/components/grid/QuestionsTable.vue";
import type { Question, QuestionSelectable } from "@/models/question";
import { QuestionStatus } from "@/models/questionStatus";
import { containsInsensitive } from "@/utils/stringUtils";
import useModal from "@/components/modal/useModal";
import type { Order } from "@/components/table/Order";

interface Props {
  questions: QuestionSelectable[];
  filter: string;
  allowedGroupNames?: string[];
}
const props = defineProps<Props>();

interface Emits {
  (e: "updatedQuestion", question: QuestionSelectable): void;
  (e: "removeQuestions", questionIds: number[]): void;
  (e: "sendQuestions", questionIds: number[]): void;
}
const emit = defineEmits<Emits>();

const { openTextModal } = useModal();

const selectedIds = ref<number[]>([]);
const filteredQuestions = computed(() => props.questions.filter((q) => containsInsensitive(q.title, props.filter)));

const defaultOrder: Order = { direction: "asc", id: 2 };

const editQuestion = (question: Question): void => emit("updatedQuestion", question as QuestionSelectable);
const deleteQuestion = (question: Question): void => emit("removeQuestions", [question.id]);

const tryDeleteSelectedQuestions = (): void => {
  if (!selectedIds.value.length) {
    return;
  }

  openTextModal({
    title: i18n.global.t("shared.are_you_sure"),
    contentText: "",
    showCancelToClose: true,
    confirmCallback: removeSelectedQuestions,
  });
};

const sendSelectedQuestions = () => {
  if (!selectedIds.value.length) {
    return;
  }
  emit("sendQuestions", selectedIds.value);
};

const removeSelectedQuestions = async () => emit("removeQuestions", selectedIds.value);

watch(
  () => props.questions,
  (value) => (selectedIds.value = value?.filter((question) => question.selected).map((question) => question.id) || []),
  { deep: true, immediate: true }
);
</script>

<style lang="scss" scoped>
.question-list {
  margin-top: var(--space-m);

  &__header {
    padding: var(--space-s);
    border-bottom: 1px solid var(--color-border);
    color: var(--color-primary-accent1);
  }

  &__footer {
    padding: var(--space-s);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--color-border);
    gap: var(--space-s);
  }
}
</style>
