<template>
  <AppInputGeneric :label="label" :error="error">
    <input
      v-model="modelValueString"
      type="date"
      class="input text"
      :disabled="disabled"
      :min="getIsoDate(minDate)"
      :max="getIsoDate(maxDate)"
      @input="handleInput" />
  </AppInputGeneric>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import AppInputGeneric from "@/components/AppInputGeneric.vue";
import { convertDateOnlyToLocale, toIsoDate } from "@/utils/dateUtils";

interface Props {
  modelValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  disabled?: boolean;
  error?: string;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "update:modelValue", value: Date | undefined) => void>();

const modelValueString = ref<string | undefined>();
const modelChangeHandled = ref<boolean>(false);

const getIsoDate = (date?: Date) => (date ? toIsoDate(date) : undefined);
const handleInput = (event: Event): void => {
  const input: HTMLInputElement = event.target as HTMLInputElement;
  const value = input.valueAsDate ?? undefined;
  modelChangeHandled.value = true;
  emit("update:modelValue", value);
};
const setModelValueFromProp = () =>
  (modelValueString.value = props.modelValue ? toIsoDate(convertDateOnlyToLocale(props.modelValue)) : undefined);

onMounted(setModelValueFromProp);
watch(
  () => props.modelValue,
  () => {
    if (!modelChangeHandled.value) {
      setModelValueFromProp();
    }
    modelChangeHandled.value = false;
  }
);
</script>
