<template>
  <AppAutomaticModalOpener :modal-config="modalConfig">
    <div class="app-current-experience-profile-importer">
      <AppPanel :dark="true" :border="false">
        <div class="instructions">
          <div class="icon-container">
            <span class="icon icon-linkedin margin-r--s"></span>
          </div>
          {{ $t("pages.current_experience.linkedin.content.instructions.title") }}
        </div>
      </AppPanel>
      <ol class="steps">
        <li class="steps__item">
          <span v-html="$t('pages.current_experience.linkedin.content.instructions.step.1')"></span>
          <a class="text text--link" href="https://www.linkedin.com/mypreferences/d/download-my-data" target="_blank">
            https://www.linkedin.com/mypreferences/d/download-my-data
          </a>
        </li>
        <li class="steps__item" v-html="$t('pages.current_experience.linkedin.content.instructions.step.2')"></li>
        <li class="steps__item" v-html="$t('pages.current_experience.linkedin.content.instructions.step.3')"></li>
        <li class="steps__item" v-html="$t('pages.current_experience.linkedin.content.instructions.step.4')"></li>
        <li class="steps__item" v-html="$t('pages.current_experience.linkedin.content.instructions.step.5')"></li>
        <li class="steps__item" v-html="$t('pages.current_experience.linkedin.content.instructions.step.6')"></li>
        <li class="steps__item" v-html="$t('pages.current_experience.linkedin.content.instructions.step.7')"></li>
      </ol>
      <AppUploadDocument
        v-model="attachment"
        :label="$t('shared.attachment.label')"
        :accept="acceptedExtensions.join()"
        :maximum-size="maxFileSizeInMB"
        :error="attachmentError" />
      <AppTip :title="$t('shared.reminder.title')" :content="$t('pages.current_experience.linkedin.reminder.content')" />
    </div>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { useField, useForm } from "vee-validate";
import i18n from "@/i18n";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import AppPanel from "@/components/AppPanel.vue";
import AppTip from "@/components/AppTip.vue";
import AppUploadDocument from "@/components/uploadDocument/AppUploadDocument.vue";
import type { FileAttachment } from "@/models/fileAttachment";
import type { ModalBase } from "@/components/modal/models";
import useFormSchema from "@/services/useFormSchema";
import useMeService from "@/services/useMeService";
import useToaster from "@/components/toaster/useToaster";
import { maxFileSizeInMB } from "@/utils/fileUtils";
import useFormValidator from "@/services/useFormValidator";

const acceptedExtensions: string[] = [".zip"];

const { importProfile } = useMeService();
const { openToaster } = useToaster();
const { attachmentSchema, yupObject } = useFormSchema();
const { getFormCallbackHandler } = useFormValidator();

const resourceSchema = yupObject({
  attachment: attachmentSchema(acceptedExtensions, maxFileSizeInMB),
});
const form = useForm({ validationSchema: resourceSchema });
const { value: attachment, errorMessage: attachmentError } = useField<FileAttachment | undefined>("attachment");

const emit = defineEmits<{
  (e: "closed"): void;
  (e: "imported"): void;
}>();

const save = async (): Promise<boolean> => {
  if (!attachment.value) {
    return false;
  }

  try {
    await importProfile(attachment.value);
    openToaster("success", i18n.global.t("pages.current_experience.linkedin.successfully_imported_profile"));
    emit("imported");
  } catch {
    emit("closed");
  }
  return true;
};

const modalConfig: ModalBase = {
  title: i18n.global.t("pages.current_experience.linkedin.title"),
  variant: "floating",
  confirmText: i18n.global.t("buttons.save"),
  showCancelToClose: true,
  confirmCallback: getFormCallbackHandler(form, save),
  closeCallback: () => emit("closed"),
};
</script>

<style scoped lang="scss">
.app-current-experience-profile-importer {
  display: flex;
  flex-direction: column;
  gap: var(--size-m);
}

.instructions {
  display: flex;
  align-items: center;
}

.steps {
  list-style: auto;
  padding-left: var(--size-s);

  &__item {
    padding-left: var(--size-s);
  }

  &__item:not(:last-child) {
    margin-bottom: var(--size-s);
  }
}

.icon-container {
  font-size: var(--size-m);
}
</style>
