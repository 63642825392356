import type { QuestionType } from "@/models/questionType";

export interface QuestionKpiOption {
  title: string;
  answeredCount: number;
  answeredPercentage: number;
  isOthersSummary: boolean;
}

export interface QuestionKpiInfo {
  id: number;
  title: string;
  type: QuestionType;
  answeredCount: number;
  answeredPercentage: number;
  options: QuestionKpiOption[];
}

export const PIE_DONUTS_COLORS = [
  "#5AC3E6",
  "#8D83DB",
  "#E7AD6C",
  "#DF7BB2",
  "#B2AEF2",
  "#87E39F",
  "#73A9E8",
  "#9F7EC5",
  "#E1CF5E",
  "#D69F65",
];
