<template>
  <LayoutBase :menu-options="menuOptions" root-url="/admin" />
</template>

<script setup lang="ts">
import LayoutBase from "@/layout/shared/LayoutBase.vue";
import { useLayout } from "@/layout/shared/useLayout";
import type { MenuOption } from "@/layout/models/menu-option";

const { getOption } = useLayout("admin");

const menuOptions: MenuOption[] = [
  getOption("dashboard"),
  getOption("ai-assistant"),
  getOption("goals", "corporate-goals", "pages.admin.company_goals.title"),
  getOption("talent", "talent-detection", "pages.admin.talent_detection.title"),
  getOption("learning", undefined, "pages.admin.learning.title.short"),
  getOption("settings", "company-settings", "pages.admin.company_settings.title"),
];
</script>
