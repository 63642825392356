<template>
  <div class="margin-t--m margin-b--m">
    <AppPanel :dark="true" :border="false">
      <AppToggle
        v-model="recalculate"
        :text="$t('pages.learning.my_learning_path.recalculate.title')"
        data-cy="learning-path-recalculate"
        @update:model-value="update" />
    </AppPanel>
  </div>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import i18n from "@/i18n";
import AppPanel from "@/components/AppPanel.vue";
import AppToggle from "@/components/AppToggle.vue";
import useToaster from "@/components/toaster/useToaster";
import useMeService from "@/services/useMeService";
import useAuthenticationService from "@/services/useAuthenticationService";

const { openToaster } = useToaster();
const { updateCalculateLearningPath } = useMeService();
const { getUser } = useAuthenticationService();

const recalculate = ref<boolean>(false);

const update = async () => {
  await updateCalculateLearningPath(recalculate.value);
  if (recalculate.value) {
    openToaster("success", i18n.global.t("pages.learning.my_learning_path.recalculate.activate_explanation"));
  }
};

onBeforeMount(async () => {
  const user = await getUser();
  recalculate.value = user?.calculateLearningPath ?? false;
});
</script>
