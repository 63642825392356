export interface UseEventEmitterServiceReturn {
  on: <T>(eventName: string, fn: (arg: T) => void | Promise<void>) => void;
  off: <T>(eventName: string, fn: (arg: T) => void | Promise<void>) => void;
  trigger: <T>(eventName: string, arg: T) => Promise<void>;
}

type genericHandler = (arg: unknown) => void | Promise<void>;

export default (): UseEventEmitterServiceReturn => {
  const events = new Map<string, genericHandler[]>();

  const on = <T>(eventName: string, fn: (arg: T) => void): void => {
    if (!events.has(eventName)) {
      events.set(eventName, []);
    }
    events.get(eventName)?.push(fn as genericHandler);
  };
  const off = <T>(eventName: string, fn: (arg: T) => void) => {
    if (!events.has(eventName)) {
      return;
    }
    const eventHandlers = events.get(eventName);
    const handlerIndex = eventHandlers?.findIndex(fn as genericHandler);
    if (handlerIndex !== undefined && handlerIndex >= 0) {
      eventHandlers?.splice(handlerIndex, 1);
    }
  };
  const trigger = async <T>(eventName: string, arg: T) => {
    if (!events.has(eventName)) {
      return;
    }
    const handlers = events.get(eventName);
    if (!handlers) {
      return;
    }
    const promises = handlers.map((handler) => handler(arg));

    await Promise.resolve(promises);
  };
  return { on, off, trigger };
};
