<template>
  <AppTab :tabs="tabs" :tab-id="$t('entities.my_ideal_job.title')" />
</template>

<script setup lang="ts">
import AppTab, { type Tab } from "@/components/AppTab.vue";
import i18n from "@/i18n";
import router from "@/router/router";

interface Props {
  current: "LearningPath" | "MyTraining";
}

const props = defineProps<Props>();

const tabs: Tab[] = [
  {
    text: i18n.global.t("entities.my_ideal_job.tab.learning_path"),
    action: () => router.push({ name: "my-ideal-job-learning" }),
    isChecked: props.current === "LearningPath",
    dataCy: "nav-learning-path",
  },
  {
    text: i18n.global.t("entities.my_ideal_job.tab.my_training"),
    action: () => router.push({ name: "my-ideal-job-my-training" }),
    isChecked: props.current === "MyTraining",
    dataCy: "nav-my-training",
  },
];
</script>
