<template>
  <Component :is="previewerComponent" class="question-previewer" :item="item" />
</template>
<script setup lang="ts">
import { computed } from "vue";
import type { Question } from "@/models/question";
import { getPreviewerComponentFor } from "@/views/app/ai-assistant/utils/questionHelper";

interface Props {
  item: Question;
}
const props = defineProps<Props>();

const previewerComponent = computed(() => getPreviewerComponentFor(props.item.type));
</script>
<style lang="scss" scoped>
.question-previewer {
  padding: var(--size-m);
  pointer-events: none;
  background-color: var(--color-card);
  border: 1px solid var(--color-border);
  border-radius: var(--default-border-radius);
}
</style>
