import type { Interest } from "@/models/interest";
import useHttpClient from "@/services/shared/useHttpClient";
import type { Ref } from "vue";

const BASE_URL = "/api/me/interests";
const { get, put, post, httpDelete, loading } = useHttpClient();

interface UseMeInterestsServiceReturn {
  getInterests: () => Promise<Interest[]>;
  createInterest: (interest: Interest) => Promise<Interest>;
  updateInterest: (interest: Interest) => Promise<Interest>;
  deleteInterest: (interestId: number) => Promise<void>;
  loading: Ref<boolean>;
}

export default (): UseMeInterestsServiceReturn => {
  const getInterests = (): Promise<Interest[]> => get(BASE_URL);
  const createInterest = (interest: Interest): Promise<Interest> => post(BASE_URL, interest);
  const updateInterest = (interest: Interest): Promise<Interest> => put(`${BASE_URL}/${interest.id}`, interest);
  const deleteInterest = (interestId: number): Promise<void> => httpDelete(`${BASE_URL}/${interestId}`);

  return { getInterests, createInterest, updateInterest, deleteInterest, loading };
};
