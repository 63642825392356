import type { PaginatedResult } from "@/models/response/paginatedResult";

export enum CourseType {
  CourseCoursera = "CourseCoursera",
  CourseEdX = "CourseEdX",
  CourseLinkedInLearning = "CourseLinkedInLearning",
  CourseIntefi = "CourseIntefi",
  CourseCompany = "CourseCompany",
}
export enum CourseLevel {
  Beginner = 1,
  Advanced = 2,
  Expert = 3,
}
export interface CompanyCourseInformation {
  startTime: string;
  endTime: string;
  country: string;
  city: string;
  address: string;
  floor: string;
  room: string;
  schedule: string;
  imageCoverName?: string;
}
export interface CourseBase {
  id: number;
  title: string;
  description: string;
  level: string;
  topics: string;
  durationHours?: number;
  url: string;
  language: string;
  imageUrl?: string;
  courseType: CourseType;
  onLine: boolean;
  start: Date;
  end?: Date;
  companyCourseInformation?: CompanyCourseInformation;
}

export interface RatedCourse extends CourseBase {
  averageRate: number;
}

export interface EmployeeCourse extends RatedCourse {
  done: boolean;
}

export type CourseBaseList = PaginatedResult<CourseBase>;
export type EmployeeCourseList = PaginatedResult<EmployeeCourse>;

export interface CourseModificationStatus {
  course: CourseBase;
  added: boolean;
}
