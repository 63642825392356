<template>
  <AppAutomaticModalOpener :modal-config="modalConfig" :overflow="false">
    <QuestionKpiData v-if="data.answeredCount > 0" :kpi-info="data" class="question-kpi" />
    <AppNoContentYet v-else :text="$t('shared.question_kpi.no_answers')" class="question-kpi-empty" />
    <template #actions>
      <AppButton :text="$t('shared.close')" variant="secondary" size="m" data-cy="cancel" @onclick="close" />
      <AppButton :text="buttonText" :icon="buttonIcon" variant="primary" size="m" data-cy="update-kpi-status" @onclick="updateKpiStatus" />
    </template>
  </AppAutomaticModalOpener>
</template>

<script setup lang="ts">
import { ref } from "vue";
import i18n from "@/i18n";
import AppAutomaticModalOpener from "@/components/modal/AppAutomaticModalOpener.vue";
import QuestionKpiData from "@/views/admin/ai-assistant/components/kpis/QuestionKpiData.vue";
import AppButton from "@/components/AppButton.vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import { Question } from "@/models/question";
import { QuestionKpiInfo } from "@/models/questionKpi";
import type { ModalBase } from "@/components/modal/models";

interface Emits {
  (e: "closed"): void;
  (e: "updateKpiStatus", question: Question): void;
}
const emit = defineEmits<Emits>();

interface Props {
  question: Question;
  data: QuestionKpiInfo;
}
const props = defineProps<Props>();

const buttonText = ref<string>(i18n.global.t(props.question.showInDashboard ? "shared.question_kpi.unpin" : "shared.question_kpi.pin"));
const buttonIcon = ref<string | undefined>(props.question.showInDashboard ? undefined : "pin-off");

const close = () => emit("closed");
const updateKpiStatus = () => emit("updateKpiStatus", props.question);

const modalConfig: ModalBase = {
  title: i18n.global.t("shared.question_answers.title", { text: props.data.title }),
  variant: "adaptive",
  onClosed: close,
};
</script>

<style lang="scss" scoped>
.question-kpi {
  padding: 3rem 0 2rem;
}
.question-kpi-empty {
  display: flex;
  margin: 6rem 10rem 6rem;
}
</style>
