import { reactive, readonly } from "vue";

export type NetworkState = {
  numberOfCallsActives: number;
};

const state = reactive<NetworkState>({
  numberOfCallsActives: 0,
});

const incrementCallCount = (): void => {
  state.numberOfCallsActives = state.numberOfCallsActives + 1;
};
const decrementCallCount = (): void => {
  state.numberOfCallsActives = state.numberOfCallsActives - 1;
};

export const networkStateStore = { state: readonly(state), incrementCallCount, decrementCallCount };
