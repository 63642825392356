<template>
  <div v-if="availableSkills">
    <div class="skills-editor margin-b--m">
      <AppAutocomplete
        :placeholder="$t(`shared.skillEditor.${skillTypeText}.search`)"
        :items="autoCompleteItems"
        :maxlength="15"
        :self-filtering="true"
        @click="handleSkillSelected" />
    </div>
    <AppPanel :dark="true" :border="false">
      <div class="skills-editor--new-skill">
        <span class="text text--s text--bold">{{ $t(`shared.skillEditor.newSkill.${skillTypeText}.title`) }}</span>
        <AppButton
          data-cy="create-new-skill-button"
          size="m"
          :text="$t(`shared.skillEditor.newSkill.${skillTypeText}.addNewButton`)"
          @click="creating = true" />
      </div>
    </AppPanel>
    <div v-if="checkedSkills.length" class="padding-v--s" data-cy="skill-editor-skills">
      <div v-for="skill in checkedSkills" :key="skill.name">
        <span data-cy="remove-skill" class="icon icon-close skills-editor--delete-skill" @click="handleRemoveSkill(skill.id)"></span>
        <AppSkillRange v-model="skill.progression" data-cy="appSkillRange" :label="skill.name" />
        <AppSeparator />
      </div>
    </div>
    <AppSkillCreator v-if="creating" :skill-type="skillsType" @skill-created="handleSkillCreated" @closed="creating = false" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppButton from "@/components/AppButton.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import AppSkillCreator from "@/components/AppSkillCreator.vue";
import AppSkillRange from "@/components/AppSkillRange.vue";
import AppPanel from "@/components/AppPanel.vue";
import type { AutocompleteItem } from "@/models/autocompleteItem";
import type { Skill } from "@/models/skill";
import { SkillProgression } from "@/models/skillProgression";
import { SkillType } from "@/models/skillType";
import type { CheckableSkill, SkillWithProgression } from "@/models/skillWithProgression";
import useMeSkillsService from "@/services/useMeSkillsService";

const { getAvailableSkills } = useMeSkillsService();

interface Props {
  input: SkillWithProgression[];
  skillsType: SkillType;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "updated", skills: SkillWithProgression[] | undefined, needsToNotify: boolean) => void>();

const availableSkills = ref<CheckableSkill[]>([]);
const creating = ref<boolean>(false);
const needsToNotify = ref<boolean>(false);
const skillTypeText = computed<string>(() => (props.skillsType === SkillType.Language ? "language" : "skill"));
const checkedSkills = computed(() => availableSkills.value.filter((l) => l.checked));

onMounted(() => setFormValues());

const setFormValues = async (checkedSkills?: SkillWithProgression[]): Promise<void> => {
  const input = checkedSkills ?? props.input;
  const skills = await getAvailableSkills(props.skillsType);
  availableSkills.value = skills.map((skill) => ({
    ...skill,
    checked: input.some((l) => l.id === skill.id),
    progression: input.find((l) => l.id === skill.id)?.progression ?? SkillProgression.Low,
  }));
};

const searchSkillById = (skill1: Skill | AutocompleteItem): ((skill: Skill) => boolean) => {
  return (skill2: Skill) => skill1.id === skill2.id;
};

const checkSkill = (skillToBeChecked: CheckableSkill): void => {
  skillToBeChecked.checked = true;
};

const checkSkillAndCopyProgression = (skill: CheckableSkill): ((skill: CheckableSkill) => void) => {
  return (skill2: CheckableSkill) => {
    checkSkill(skill2);
    skill2.progression = skill.progression;
  };
};

const handleSkillCreated = async (skillName?: string): Promise<void> => {
  const previousSelectedSkills = [...checkedSkills.value];
  await setFormValues(checkedSkills.value);
  checkSkillSelected((skill) => skill.name === skillName, checkSkill);
  previousSelectedSkills.forEach((s) => {
    checkSkillSelected(searchSkillById(s), checkSkillAndCopyProgression(s));
  });
};

const handleSkillSelected = (item: AutocompleteItem): void => {
  checkSkillSelected(searchSkillById(item), checkSkill);
};

const checkSkillSelected = (searchFunction: (skill: Skill) => boolean, applyFunction: (skill: CheckableSkill) => void): void => {
  const skillFound = availableSkills.value?.find(searchFunction);
  if (skillFound) {
    applyFunction(skillFound);
  }
};

const autoCompleteItems = computed<AutocompleteItem[]>(() => {
  return !availableSkills.value
    ? []
    : availableSkills.value
        .filter((s) => !s.checked)
        .map((skill) => ({
          id: skill.id,
          text: skill.name,
        }));
});

const handleRemoveSkill = (skillId: number): void => {
  const foundSkill = availableSkills.value?.find((skill: Skill) => skill.id === skillId);
  if (foundSkill) {
    foundSkill.checked = false;
    const skillFromSelection = props.input.find((skill: Skill) => skill.id === skillId);
    if (skillFromSelection?.usedOnPerformanceGraph) {
      needsToNotify.value = true;
    }
  }
};

watch([checkedSkills], () => {
  emit("updated", [...checkedSkills.value], needsToNotify.value);
});
</script>

<style scoped lang="scss">
.skills-editor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--new-skill {
    display: flex;
    gap: var(--space-l);
    align-items: center;
    justify-content: space-between;
  }
  &--delete-skill {
    float: right;
  }
}
</style>
