<template>
  <div class="card" :class="className">
    <div class="content">
      <p class="text text--l text--bold text--ellipsis text--link margin-v--s content__title" @click="click">{{ goal.title }}</p>
      <p class="text margin-b--s text--line-clamp-3" v-html="toHtml(goal.description)"></p>
      <p class="text text--link" @click="click">{{ $t("pages.admin.company_goals.detail_goals.more_information") }}</p>
    </div>
    <div class="progress" :class="{ 'progress--success': isSuccessful(goal), 'progress--failure': isFailure(goal) }">
      <div v-if="isInProgress(goal)">
        <div class="progress__in-progress">
          <div class="text text--xs">{{ $t("pages.goals_challenges.my.progress") }}</div>
          <div class="text text--xs text--bold">
            {{ $t("pages.goals_challenges.my.progress_amount", { progress: Math.round(goal.progression ?? 0) }) }}
          </div>
        </div>
        <div>
          <AppProgressBar :value="goal.progression" />
        </div>
      </div>
      <div v-if="isNotStarted(goal)" class="progress__not-in-progress">
        <span class="icon icon-calendar text text--xl"></span> {{ $t("entities.goals_challenges.has_not_started") }}
      </div>
      <div v-if="isFinished(goal)" class="progress__not-in-progress">
        <div v-if="isSuccessful(goal)">
          {{ $t("pages.admin.company_goals.goal_detail.result_succeeded") }}
        </div>
        <div v-else-if="isFailure(goal)">
          {{ $t("pages.admin.company_goals.goal_detail.result_failed") }}
        </div>
        <div v-else>
          {{ $t("entities.goals_challenges.has_finished") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CompanyGoal } from "@/models/companyGoal";
import { isInProgress, isNotStarted, isFinished, isSuccessful, isFailure } from "@/services/modelServices/companyGoalService";
import AppProgressBar from "@/components/AppProgressBar.vue";
import { toHtml } from "@/utils/stringUtils";

interface Props {
  goal: CompanyGoal;
  className?: string;
}
const props = defineProps<Props>();

const emit = defineEmits<(e: "click", value: CompanyGoal) => void>();

const click = () => emit("click", props.goal);
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 240px;
  margin: var(--size-xs) 0 var(--size-s) var(--size-s);
  box-shadow: var(--size-xs) var(--size-xs) var(--size-s) rgb(26 26 26 / 0.06);
}

.content {
  background-color: var(--color-neutral-100);
  border-top: var(--space-xxs) solid var(--color-primary-accent1);
  border-bottom: 0;
  border-radius: var(--size-xs) var(--size-xs) 0 0;
  padding: var(--space-s);
  min-height: 170px;

  &__title {
    color: var(--color-primary-accent1);
  }
}

.progress {
  flex: 0 0 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--space-s);
  background-color: var(--color-neutral-200);
  border-top: 0;
  border-radius: 0 0 12px 12px;

  &__in-progress {
    display: flex;
    justify-content: space-between;
  }

  &__not-in-progress {
    display: flex;
    align-items: center;
    gap: var(--space-s);
  }

  &--success {
    background-color: var(--color-system-success);
    color: var(--color-neutral-100);
  }

  &--failure {
    background-color: var(--color-system-error);
    color: var(--color-neutral-100);
  }
}
</style>
