import type { Directive, DirectiveBinding } from "vue";

const scrollTo = (el: HTMLElement, bindingValue: boolean | undefined): void => {
  if (bindingValue !== false && !isVisible(el)) {
    el.scrollIntoView(false);
  }
};
const isVisible = (el: HTMLElement): boolean => {
  const position = el.getBoundingClientRect();
  return !el.parentElement || (position.top >= 0 && position.bottom <= el.parentElement.clientHeight);
};

export const AppScrollTo: Directive = {
  mounted: (el: HTMLElement, binding: DirectiveBinding, _vnode): void => {
    scrollTo(el, binding.value);
  },
  updated: (el: HTMLElement, binding: DirectiveBinding): void => {
    scrollTo(el, binding.value);
  },
};
