<template>
  <div class="goal-company-goals" :class="{ 'goal-company-goals--draft': !goal.isPublished }" data-cy="goal-company-goals">
    <div class="body">
      <div class="body__title">
        <div class="text text--l text--bold" data-cy="goal-title">
          {{ goal.title }}
        </div>
        <div class="body__actions">
          <AppIconOnlyButton
            v-if="isEditable(goal)"
            size="xl"
            icon="pencil"
            data-cy="edit-goal"
            :title="$t('buttons.edit')"
            @click="editGoal" />
          <AppIconOnlyButton
            v-if="isDeletable(goal)"
            size="xl"
            icon="delete"
            data-cy="delete-goal"
            :title="$t('buttons.delete')"
            @click="deleteGoal" />
        </div>
      </div>
      <div v-if="goal.isPublished">
        <AppTag v-if="isApplicationPhaseClosed(goal)" :main-text="$t('pages.admin.company_goals.application_closed')" type="danger" />
        <AppTag v-if="isApplicationPhaseOpen(goal)" :main-text="$t('pages.admin.company_goals.application_open')" type="text" />
      </div>
      <div v-else>
        <AppTag :main-text="$t('pages.admin.company_goals.draft')" type="text" />
      </div>
      <div class="margin-v--xs">{{ $t("pages.admin.company_goals.last_updated", { date: parseDateToString(goal.edited) }) }}</div>
      <div class="text text--bold">
        {{
          $t("pages.admin.company_goals.goal_dates", {
            start: parseDateToString(goal.start),
            end: parseDateToString(goal.end),
          })
        }}
      </div>
      <div class="text--line-clamp-3 margin-v--xs">
        <p v-html="toHtml(goal.description)"></p>
      </div>
      <AppLinkButton
        data-cy="more-information"
        :bold="false"
        :text="$t('pages.admin.company_goals.detail_goals.more_information')"
        @click="goToGoalDetail" />
    </div>
    <div v-if="isInProgress(goal)" class="footer">
      <div class="footer__percentage">
        <div class="footer__bar">{{ $t("pages.admin.company_goals.detail_goals.progress") }}</div>
        <div class="text--bold text--l">{{ $n((goal.progression ?? 0) / 100, "percent") }}</div>
      </div>
      <div class="footer__progress-bar">
        <AppProgressBar :value="goal.progression" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppProgressBar from "@/components/AppProgressBar.vue";
import AppTag from "@/components/AppTag.vue";
import AppIconOnlyButton from "@/components/AppIconOnlyButton.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import useModal from "@/components/modal/useModal";
import useToaster from "@/components/toaster/useToaster";
import i18n from "@/i18n";
import type { CompanyGoal } from "@/models/companyGoal";
import router from "@/router/router";
import useCompanyGoalsService from "@/services/useCompanyGoalsService";
import {
  isApplicationPhaseClosed,
  isApplicationPhaseOpen,
  isEditable,
  isDeletable,
  isInProgress,
} from "@/services/modelServices/companyGoalService";
import { parseDateToString } from "@/utils/dateUtils";
import { toHtml } from "@/utils/stringUtils";

const { openTextModal } = useModal();
const { openToaster } = useToaster();
const { deleteCompanyGoal } = useCompanyGoalsService();

interface Props {
  goal: CompanyGoal;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "goal-deleted", id: number): void;
  (e: "goal-editing", id: number): void;
}>();

const deleteGoal = async (): Promise<void> => {
  openTextModal({
    title: i18n.global.t("pages.admin.company_goals.delete_goal.title", { name: props.goal.title }),
    contentText: i18n.global.t("pages.admin.company_goals.delete_goal.description", {
      name: props.goal.title,
    }),
    icon: { content: "warning", style: "error" },
    showCancelToClose: true,
    confirmCallback: async () => {
      await deleteCompanyGoal(props.goal.id);
      openToaster("success", i18n.global.t("pages.admin.company_goals.delete_goal.successfully_deleted"));
      emit("goal-deleted", props.goal.id);
    },
  });
};

const goToGoalDetail = () => router.push({ name: "corporate-goals-admin-goal", params: { goalId: props.goal.id } });
const editGoal = (): void => emit("goal-editing", props.goal.id);
</script>

<style scoped lang="scss">
.goal-company-goals {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 8px 24px var(--color-company-goals-border);
  background-color: var(--color-company-goals-goal-body);
  border-radius: var(--default-border-radius);

  &--draft {
    background-color: var(--color-company-goals-goal-body--draft);
  }
}

.body {
  padding: var(--space-xs) var(--space-s) var(--space-s) var(--space-s);
  width: 100%;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--space-xs);
  }
}

.footer {
  width: 100%;
  border-radius: 0 0 6px 6px;
  background-color: var(--color-company-goals-goal-footer);
  padding: var(--space-s);
  min-height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--empty {
    background-color: var(--color-company-goals-goal-body);
  }

  &__percentage {
    display: flex;
    justify-content: space-between;
  }

  &__progress-bar {
    width: 100%;
  }
}
</style>
