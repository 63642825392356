<template>
  <div class="card" :class="{ 'card--elegant': elegant }">
    <span class="card__title text--bold text" :class="classes" :title="title"> {{ title }}</span>
    <div v-if="subtitle" class="card__subtitle text text--secondary">{{ subtitle }}</div>
    <AppSeparator class="margin-v--s" />
    <div class="card__content" :class="{ 'card__content--center': centerContent }">
      <slot>
        <NoContentYet data-cy="app-card-no-content" />
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AppSeparator from "@/components/AppSeparator.vue";
import NoContentYet from "@/components/AppNoContentYet.vue";
import { computed } from "vue";

interface Props {
  title: string;
  subtitle?: string;
  centerContent?: boolean;
  oneLineTitle?: boolean;
  elegant?: boolean;
  spaceForIcon?: boolean;
}
const props = withDefaults(defineProps<Props>(), { centerContent: false });

const classes = computed(() => ({
  "text--ellipsis": props.oneLineTitle,
  "card__title--space-for-icon": props.spaceForIcon,
}));
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--space-s);
  background-color: #ffffff;

  &--elegant {
    border-radius: var(--default-border-radius);
    box-shadow: 0 8px 24px rgba(26, 26, 26, 0.04);
  }

  &__title {
    padding: 0 var(--space-s);
    padding-top: var(--space-s);
    color: var(--color-primary-accent1);

    &--space-for-icon {
      max-width: 95%;
    }
  }

  &__subtitle {
    padding: 0 var(--space-m);
    padding-top: var(--space-xs);
    color: var(--color-body-tertiary);
  }

  &__content {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  &__content--center {
    align-items: center;
  }
}
</style>
