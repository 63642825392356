<template>
  <div class="grid--two-columns@medium">
    <div v-if="idealJob && idealJob.title && idealJob.description">
      <h3 class="margin-b--m" data-cy="job-title">{{ idealJob.title }}</h3>
      <div class="margin-b--m" data-cy="job-description" v-html="toHtml(idealJob.description)"></div>
    </div>
    <div v-else>
      <AppNoContentYet class="margin-b--m" :text="$t('pages.admin.dashboard.my_ideal_job.no_content_yet')" />
    </div>
    <div v-if="idealJob && idealJob.title && idealJob.description">
      <h3 class="margin-b--m">{{ $t("entities.my_ideal_job.general_skills") }}</h3>
      <AppNoContentYet
        v-if="skills.length === 0"
        class="margin-b--s"
        :text="$t('pages.admin.dashboard.current_experience.general_skills.no_content_yet')" />
      <FloatingPanelSkillEditor
        :input="skills"
        :skills-type="SkillType.General"
        :skill-location="SkillLocationType.IdealJob"
        form-mode="view"
        data-cy="job-general-skills" />
      <h3 class="margin-v--m">{{ $t("entities.my_ideal_job.languages") }}</h3>
      <AppNoContentYet
        v-if="languages.length === 0"
        class="margin-b--s"
        :text="$t('pages.admin.dashboard.current_experience.languages.no_content_yet')" />
      <FloatingPanelSkillEditor
        :input="languages"
        :skills-type="SkillType.Language"
        :skill-location="SkillLocationType.IdealJob"
        form-mode="view"
        data-cy="job-language-skills" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AppNoContentYet from "@/components/AppNoContentYet.vue";
import FloatingPanelSkillEditor from "@/views/shared/current-experience/FloatingPanelSkillEditor.vue";
import useEmployeeService from "@/services/useEmployeeService";
import type { IdealJob } from "@/models/idealJob";
import { emptyIdealJob } from "@/services/modelServices/employeeIdealJobService";
import { toHtml } from "@/utils/stringUtils";
import { getGeneralSkills, getLanguageSkills } from "@/utils/models";
import type { SkillWithProgression } from "@/models/skillWithProgression";
import { SkillLocationType, SkillType } from "@/models/skillType";

interface Props {
  employeeId: number;
}
const props = defineProps<Props>();

const { getIdealJob } = useEmployeeService();

const idealJob = ref<IdealJob>();
const skills = ref<SkillWithProgression[]>([]);
const languages = ref<SkillWithProgression[]>([]);
const loading = ref<boolean>(true);

onMounted(async () => {
  const job = await getIdealJob(props.employeeId);
  idealJob.value = job || emptyIdealJob();
  skills.value = getGeneralSkills(idealJob.value.skills);
  languages.value = getLanguageSkills(idealJob.value.skills);
  loading.value = false;
});
</script>
