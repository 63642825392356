<template>
  <div class="course">
    <div class="course__title">
      <span class="text text--bold text--link">{{ course.title }}</span>
      <div
        class="course__card"
        :class="{
          'course__card--downward': informationDirection === 'desc',
          'course__card--upward': informationDirection === 'asc',
        }">
        <CourseCard :course="course" @click="selectCourse" />
      </div>
    </div>
    <div class="course__information">
      <span>{{ getDurationStringFromHours(course.durationHours) }}</span>
      <AppTag icon="star" :main-text="!course.averageRate ? '-' : formatNumber(course.averageRate)" type="like" />
    </div>
  </div>
</template>
<script setup lang="ts">
import AppTag from "@/components/AppTag.vue";
import CourseCard from "@/views/shared/learning/CourseCard.vue";
import { formatNumber } from "@/utils/numberUtils";
import { getDurationStringFromHours } from "@/utils/timeUtils";
import type { EmployeeCourse } from "@/models/course";

interface Props {
  course: EmployeeCourse;
  informationDirection?: "asc" | "desc";
}
const props = withDefaults(defineProps<Props>(), { informationDirection: "desc" });

const emit = defineEmits<(e: "course-selected", course: EmployeeCourse) => void>();

const selectCourse = () => emit("course-selected", props.course);
</script>

<style lang="scss" scoped>
.course {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--space-s);

  &__card {
    position: absolute;

    width: 40rem;
    z-index: var(--z-index-floating-panel);
    display: none;

    &--downward {
      top: 1rem;
      left: 1rem;
    }

    &--upward {
      bottom: 1rem;
      left: 1rem;
    }
  }

  &__title:hover .course__card {
    display: block;
  }

  &__information {
    display: flex;
    flex-direction: row;
    gap: var(--space-s);
  }
}
</style>
