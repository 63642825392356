<template>
  <AIAssistantEditorBase :question="question" :form="form" :get-current-answer="getAnswer" @closed="closed" @edited="edited">
    <AppInputTextArea v-model="text" :label="$t('pages.ai_assistant.question.level')" :maxlength="largeTextLength" :error="textError" />
    <AppInputRange v-model="value" :min="minLevelAnswer" :max="maxLevelAnswer" :error="valueError" :available-values="availableValues" />
  </AIAssistantEditorBase>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { number as yupNumber } from "yup";
import AppInputRange from "@/components/AppInputRange.vue";
import AppInputTextArea from "@/components/AppInputTextArea.vue";
import AIAssistantEditorBase from "@/views/app/ai-assistant/components/AIAssistantEditorBase.vue";
import { largeTextLength, maxLevelAnswer, minLevelAnswer } from "@/constants/restrictions";
import type { Answer } from "@/models/answer";
import type { Question } from "@/models/question";
import type { QuestionLevel } from "@/models/questionLevel";
import { useField, useForm } from "vee-validate";
import useFormSchema from "@/services/useFormSchema";

interface Props {
  question: Question;
}
const props = defineProps<Props>();

interface Emits {
  (e: "edited", newQuestionState: Question): void;
  (e: "closed"): void;
}
const emit = defineEmits<Emits>();

const { yupObject, stringSchema, inputRequiredText } = useFormSchema();

const levels: QuestionLevel = props.question.levels ?? ({} as QuestionLevel);
const availableValues = [levels.lowLevelText, levels.mediumLevelText, levels.highLevelText];

const resourceSchema = yupObject({
  text: stringSchema({ maxLength: largeTextLength }),
  value: yupNumber().required(inputRequiredText()),
});

const form = useForm({ validationSchema: resourceSchema });
const { value: text, errorMessage: textError } = useField<string>("text");
const { value: value, errorMessage: valueError } = useField<number>("value");

onMounted(() => setFormValues());

const setFormValues = (): void => {
  if (props.question.value) {
    text.value = props.question.text ?? "";
    value.value = props.question.value || minLevelAnswer;
  } else {
    form.resetForm();
    value.value = minLevelAnswer;
  }
};

const getAnswer = (): Answer => ({ text: text.value, value: value.value });
const closed = () => emit("closed");
const edited = (newQuestionState: Question) => emit("edited", newQuestionState);
</script>
